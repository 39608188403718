import { AppBar, Toolbar, Tooltip, Box } from '@mui/material';
import {
  RotateLeft,
  RotateRight,
  ZoomIn,
  ZoomOut,
  HighlightOff,
  CheckCircleOutline,
} from '@mui/icons-material';
import IconButtonContainer from '../../components/common/IconButtonContainer';

function DocumentDisplayHeader(props) {
  const {
    documentName,
    zoomIn,
    zoomOut,
    documentZoom,
    rotateClockwise,
    rotateCounterclockwise,
    pageNumber,
    duplicatesLength,
    similarPageIsDuplicate,
    selected,
    isDuplicate,
    onChange,
  } = props;

  const getBackgroundColor = () => {
    if (duplicatesLength === undefined) {
      return 'primary';
    }
    if (selected === true) {
      return '#6EE4E4';
    }
    if (isDuplicate === true) {
      return 'themeDuplicates.main';
    }
    return 'themeNotes.light';
  };

  return (
    <AppBar
      position="static"
      sx={{
        maxWidth: '100%',
        backgroundColor: getBackgroundColor(),
        color: '#000000DD',
        boxShadow: 'none',
        borderRadius: 0,
      }}
    >
      <Toolbar
        variant="dense"
        onClick={onChange}
        sx={{
          display: 'flex',
          maxWidth: '100%',
          borderRadius: 'none',
          cursor: duplicatesLength !== undefined && 'pointer',
          paddingLeft:
            similarPageIsDuplicate !== null && similarPageIsDuplicate !== undefined
              ? '0px'
              : '.5rem',
        }}
      >
        {similarPageIsDuplicate !== null &&
          similarPageIsDuplicate !== undefined &&
          (similarPageIsDuplicate === true ? (
            <Tooltip title="Confirmed As Duplicate">
              <CheckCircleOutline style={{ fontSize: '1.25rem', color: 'secondary', flex: 10 }} />
            </Tooltip>
          ) : (
            <Tooltip title="Confirmed As Not Duplicate">
              <HighlightOff style={{ flex: 10, fontSize: '1.25rem', color: 'red' }} />
            </Tooltip>
          ))}
        <Box
          sx={{
            flex: 70,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            ml: duplicatesLength !== undefined ? '0.5rem' : '',
          }}
        >
          <div
            style={{
              fontWeight: 700,
              width: '100%',
              fontSize: '0.675rem',
              color: duplicatesLength === undefined && '#FFFFFF',
            }}
          >
            Page: {pageNumber}
          </div>
          <Box
            sx={{
              fontSize: '0.75rem',
              display: 'flex',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: duplicatesLength === undefined && '#FFFFFF',
              overflow: 'hidden',
            }}
          >
            {documentName}
          </Box>
        </Box>

        <IconButtonContainer
          customStyles={{ flex: 10 }}
          onClick={(e) => {
            e.stopPropagation();
            zoomOut();
          }}
          color={duplicatesLength === undefined && '#FFFFFF'}
          icon={<ZoomOut sx={{ color: duplicatesLength === undefined && '#FFFFFF' }} />}
          tooltipText="Zoom Out"
          disableClick={documentZoom === 0.8}
        />
        <IconButtonContainer
          customStyles={{ flex: 10 }}
          onClick={(e) => {
            e.stopPropagation();
            zoomIn();
          }}
          icon={<ZoomIn sx={{ color: duplicatesLength === undefined && '#FFFFFF' }} />}
          tooltipText="Zoom In"
          disableClick={documentZoom >= 4.0}
        />
        <IconButtonContainer
          customStyles={{ flex: 10 }}
          onClick={(e) => {
            e.stopPropagation();
            rotateCounterclockwise();
          }}
          tooltipText="Rotate Counterclockwise"
          inputColor={duplicatesLength === undefined && 'true'}
          icon={<RotateLeft sx={{ color: duplicatesLength === undefined && '#FFFFFF' }} />}
        />
        <IconButtonContainer
          customStyles={{ flex: 10 }}
          onClick={(e) => {
            e.stopPropagation();
            rotateClockwise();
          }}
          tooltipText="Rotate Clockwise"
          icon={<RotateRight sx={{ color: duplicatesLength === undefined && '#FFFFFF' }} />}
        />
      </Toolbar>
    </AppBar>
  );
}

export default DocumentDisplayHeader;
