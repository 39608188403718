import { NotesListOrderEnum } from './types/noteTypes';

export function orderNotesByFirstTag(notes, noteTags) {
  let notesRenderObj = {};
  for (const tagID of Object.keys(noteTags)) {
    notesRenderObj[noteTags[tagID].title] = [];
  }

  notesRenderObj.Untagged = [];

  for (const note of notes) {
    if (note.tags.length === 0) {
      notesRenderObj.Untagged.push(note);
    } else {
      notesRenderObj[note.tags[0].title] ??= [];
      notesRenderObj[note.tags[0].title].push(note);
    }
  }

  notesRenderObj = Object.keys(notesRenderObj)
    .filter((key) => notesRenderObj[key].length > 0)
    .reduce((obj, key) => {
      obj[key] = notesRenderObj[key];
      return obj;
    }, {});

  return notesRenderObj;
}

export function orderNotesByPhysician(notes) {
  const notesRenderObj = {};
  notes.forEach((note) => {
    if (notesRenderObj[note.physician]) {
      notesRenderObj[note.physician].push(note);
    } else if (!note.physician && notesRenderObj['No Physician']) {
      notesRenderObj['No Physician'].push(note);
    } else if (!note.physician && !notesRenderObj['No Physician']) {
      notesRenderObj['No Physician'] = [note];
    } else {
      notesRenderObj[note.physician] = [note];
    }
  });
  return notesRenderObj;
}

export function orderNotesByDocument(notes, documentNames) {
  const notesRenderObj = {};
  if (!documentNames) {
    return notesRenderObj;
  }
  for (const documentID of Object.keys(documentNames)) {
    const noteArr = [];
    for (const note of notes) {
      if (note.documentID === documentID) {
        noteArr.push(note);
      }
    }
    if (noteArr.length > 0) {
      notesRenderObj[documentNames[documentID]] = noteArr;
    }
  }
  return notesRenderObj;
}

export function filterNotesByDocumentPage(documentID, pageNum, notes) {
  let filteredNotes = [];
  filteredNotes = notes.filter((note) => note.documentID === documentID);
  if (pageNum) {
    filteredNotes = filteredNotes.filter((note) => note?.pageNumber === pageNum);
  }
  return filteredNotes;
}

export function orderNotesByDate(notes, notesDate, datesOrder) {
  const notesRenderObj = {};
  if (datesOrder === NotesListOrderEnum.DateAsc) {
    notesDate.sort(orderDatesAscending);
  }
  if (datesOrder === NotesListOrderEnum.DateDesc) {
    notesDate.sort(orderDatesDescending);
  }
  for (const date of notesDate) {
    const noteArr = [];
    for (const note of notes) {
      if (note.date === date) {
        noteArr.push(note);
      }
    }
    if (noteArr.length > 0) {
      if (date === '0000-00-00' || !date) {
        if (notesRenderObj['No Date']) {
          notesRenderObj['No Date'] = notesRenderObj['No Date'].concat(noteArr);
        } else {
          notesRenderObj['No Date'] = noteArr;
        }
      } else {
        notesRenderObj[date] = noteArr;
      }
    }
  }
  return notesRenderObj;
}

export function getNotesDateWithoutDuplicates(notes) {
  const notesDate = [];
  for (const note of notes) {
    notesDate.push(note.date);
  }
  return [...new Set(notesDate)];
}

function orderDatesDescending(a, b) {
  return (new Date(b).getTime() || -Infinity) - (new Date(a).getTime() || -Infinity);
}
function orderDatesAscending(a, b) {
  return (new Date(a).getTime() || Infinity) - (new Date(b).getTime() || Infinity);
}
