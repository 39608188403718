import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  Paper,
  Popper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Add, CloseOutlined, KeyboardArrowDown } from '@mui/icons-material';
import { gql, useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';
import Pencil from '../../../components/icons/Pencil';
import Loading from '../../../components/common/Loading';
import useBulkUpdatePageTags from '../gql/useBulkUpdatePageTags';
import { useUserGroup } from '../../MyCases/useCases';

function AutoCompleteContentTypes(props) {
  const { type, autoCompleteOptions, currentlySelectedOptions, isList, handleChangeLabel } = props;

  const handleChange = (e) => {
    e.stopPropagation();
  };
  return (
    <>
      <Autocomplete
        disablePortal
        options={autoCompleteOptions.sort((a, b) =>
          b.label.toLowerCase() < a.label.toLowerCase() ? 1 : -1,
        )}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box
            key={option.id}
            sx={{
              mx: '0.2rem',
              my: '0.1rem',
              display: 'inline-block',
            }}
          >
            <Chip
              label={option.label}
              onClick={() => handleChangeLabel(option.id, true)}
              variant="outlined"
              disabled={Boolean(currentlySelectedOptions?.find((item) => item.id === option.id))}
              classes="small-page-card"
              className="unmask"
            />
          </Box>
        )}
        popupIcon={<KeyboardArrowDown />}
        freeSolo
        forcePopupIcon
        onChange={handleChange}
        ListboxProps={{
          sx: {
            maxHeight: '22rem',
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="content-search"
            onClick={(e) => {
              e.stopPropagation();
            }}
            placeholder="Search or select labels to add"
          />
        )}
        sx={{
          ...editContentMenuStyles.autocomplete,
          width: isList && '21.2rem',
        }}
      />

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '27rem',
        }}
      >
        {currentlySelectedOptions?.map((item) => (
          <Box key={item.id} sx={{ mx: '0.2rem', my: '0.1rem' }}>
            <Tooltip title={item.label}>
              <Chip
                label={item.label}
                deleteIcon={<CloseOutlined sx={editContentMenuStyles.deleteContentIcon} />}
                onDelete={type === 'Content' && (() => handleChangeLabel(item.id, false))}
                variant="outlined"
                sx={{ cursor: 'pointer', maxWidth: '18rem' }}
                classes="small-page-card"
                className="unmask"
              />
            </Tooltip>
          </Box>
        ))}
      </Box>
    </>
  );
}

function EditContentButton(props) {
  const {
    type,
    autoCompleteOptions = [],
    currentlySelectedOptions = [],
    isList,
    handleChangeLabel,
    page,
    timelineEntryTags,
  } = props;
  const client = useApolloClient();
  const isFileProcessor = useIsFileProcessor();
  const { data: userGroup } = useUserGroup();
  const isLabeller = userGroup === 'Labeller';

  const timelineEntry = client.readFragment({
    id: client.cache.identify({
      id: page.entryID,
      __typename: 'TimelineEntryObject',
    }),
    fragment: gql`
      fragment TimelineEntryWithTaggedPages on TimelineEntryObject {
        id
        pages {
          id
          tags {
            id
            name
            type
            parent_tag_id
            origin
          }
        }
      }
    `,
  });

  const [bulkUpdatePageTags] = useBulkUpdatePageTags();
  const location = useLocation();
  const timelineView = location.pathname.indexOf('/timeline') > -1;

  let isLoading;
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = React.useRef(null);

  //Toggle the menu open/closed
  const handleToggle = (e) => {
    e.preventDefault();
    setMenuOpen((prevOpen) => !prevOpen);
  };

  //Close the menu and drop the location ref
  const handleClose = () => {
    setMenuOpen(false);
  };

  const clearAllTags = () => {
    const entryTags = [timelineEntryTags.documentTypeId, timelineEntryTags.specialityId].filter(
      Boolean,
    );
    bulkUpdatePageTags({
      where: { timeline_entry_id: timelineEntry.id },
      data: {
        tagsIds: entryTags,
      },
      newPagesState: timelineEntry.pages.map((page) => ({
        __typename: 'PageObject',
        id: page.id,
        tags: [],
      })),
      onCompleted: () => {
        toast.info('Content labels cleared.');
      },
      onError: () => {
        toast.error('Error clearing content labels.');
      },
    });
  };

  if (isList) {
    return (
      <ClickAwayListener onClickAway={handleClose}>
        <AutoCompleteContentTypes
          type={type}
          isList={true}
          autoCompleteOptions={autoCompleteOptions}
          currentlySelectedOptions={currentlySelectedOptions}
          handleChangeLabel={handleChangeLabel}
        />
      </ClickAwayListener>
    );
  }

  return (
    <>
      <div
        ref={anchorRef}
        aria-controls={menuOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        style={{ display: 'inline', marginTop: -3, marginLeft: '1.7rem' }}
      >
        {!currentlySelectedOptions || currentlySelectedOptions.length < 1 ? (
          <Button
            variant="outlined"
            onClick={handleToggle}
            sx={{ fontSize: '0.65rem', height: '1.4rem' }}
          >
            <Add sx={{ fontSize: '0.7rem', mr: '0.2rem' }} />{' '}
            {isFileProcessor ? 'Additional content' : 'Add Label'}
          </Button>
        ) : (
          <IconButton onClick={handleToggle} size="small">
            <Pencil width="1rem" height="1rem" />
          </IconButton>
        )}
      </div>

      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        sx={{ zIndex: 2000 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper variant="outlined">
              <ClickAwayListener onClickAway={handleClose}>
                {isLoading ? (
                  <Loading />
                ) : (
                  <Box sx={{ p: '12px' }}>
                    <Typography sx={{ fontSize: '14px', pb: '8px' }}>
                      {`Edit ${type} Labels`}
                    </Typography>
                    <Divider />
                    <AutoCompleteContentTypes
                      type={type}
                      autoCompleteOptions={autoCompleteOptions}
                      currentlySelectedOptions={currentlySelectedOptions}
                      handleChangeLabel={handleChangeLabel}
                    />
                    <Stack
                      direction="row"
                      spacing={3}
                      sx={{ width: '100%', justifyContent: 'right', mt: 1 }}
                    >
                      {(isFileProcessor || isLabeller) && timelineView && (
                        <>
                          <Typography
                            onClick={() => clearAllTags()}
                            variant="h5"
                            color="primary.light"
                            sx={{ cursor: 'pointer' }}
                          >
                            Clear All
                          </Typography>
                        </>
                      )}
                    </Stack>
                  </Box>
                )}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default EditContentButton;

const editContentMenuStyles = {
  autocomplete: {
    py: '8px',
    '& .MuiInputBase-root': {
      fontSize: '0.8rem',
      margin: '0.6rem 0 0 0',
      marginBottom: 0,
      padding: 0,
      paddingLeft: 0.7,
    },
    '& .MuiAutocomplete-endAdornment svg': {
      color: '#667085',
    },
    '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
      fontSize: '12px',
    },
  },
  deleteContentIcon: {
    '&&': {
      color: '#667085 !important',
      fontSize: '0.75rem !important',
      marginLeft: '-0.2rem',
    },
  },
};
