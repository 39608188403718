import { Box, Typography, Stack, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../../library/contexts/AuthContext';
import CaseContext from '../Case/CaseContext';
import CaseHeader from '../Timeline/CaseHeader';
import Export from '../../components/icons/Export';
import useTimelineExports from './useTimelineExports';
import { formatDisplayDate } from '../../library/utilities/useDates';
import apiUrl from '../../library/utilities/apiUrl';
import Theme from '../../theme';
import Loading from '../../components/common/Loading';
import ExportsStyled from '../../components/icons/ExportsStyled';
import useDisplayStore from '../Timeline/useDisplayStore';
import { useTimelineList } from '../Timeline/useTimeline';
import { useActivityLog } from '../../components/ActivityTracker/ActivityTracker';

function Exports() {
  const { caseInstance } = useContext(CaseContext);
  const { caseID } = useParams();
  const { user } = useUser();
  const logUserActivity = useActivityLog();

  const windowSize = useDisplayStore((state) => state.windowSize);
  const [exports, setExports] = useState([]);
  const [users, setUsers] = useState([]);

  const { loading: exportsLoading, data: exportedFiles } = useTimelineExports({ caseID });

  const { data: timelineList } = useTimelineList(caseID);
  const timelineID = timelineList?.find((timeline) => timeline.isDefault).id;

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      sortable: true,
      renderCell: (params) => {
        if (params.row.status === 'in-progress') {
          return (
            <Typography sx={{ color: Theme.palette.mediumGrey.light, fontSize: '0.8rem' }}>
              Export File In Progress...
            </Typography>
          );
        }
        return params.row.name;
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        if (params.row.status === 'in-progress') {
          return (
            <Typography sx={{ color: Theme.palette.mediumGrey.light, fontSize: '0.8rem' }}>
              {params.row.type}
            </Typography>
          );
        }
        return params.row.type;
      },
    },
    {
      field: 'user',
      headerName: 'Exported by',
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        if (params.row.status === 'in-progress') {
          return (
            <Typography sx={{ color: Theme.palette.mediumGrey.light, fontSize: '0.8rem' }}>
              {params.row.user}
            </Typography>
          );
        }
        return params.row.user;
      },
    },
    {
      field: 'dateWithTime',
      headerName: 'Export Date',
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        if (params.row.status === 'in-progress') {
          return (
            <Typography sx={{ color: Theme.palette.mediumGrey.light, fontSize: '0.8rem' }}>
              {params.row.date}
            </Typography>
          );
        }
        return params.row.date;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        if (params.row.status === 'fulfilled') {
          return (
            <Export
              cursor="pointer"
              width={19}
              height={19}
              color="#002147"
              onClick={(e) => {
                e.stopPropagation();
                handleDownloadExport(params.row.url);
              }}
            />
          );
        }
        return (
          <CircularProgress
            style={{ color: Theme.palette.themeDuplicates.dark, marginRight: '1rem' }}
            size={19}
          />
        );
      },
    },
  ];

  const handleDownloadExport = async (url) => {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const areExportsLoading = useMemo(() => {
    return exportsLoading || users.length === 0;
  }, [exportsLoading, users]);

  const findUsername = (file) => {
    if (users.length === 0 || file.user_id === undefined || file.status === 'in-progress') {
      return '';
    }
    const index = users.findIndex((u) => u.username === file.user_id);
    if (index >= 0) {
      return `${users[index].given_name} ${users[index].family_name}`;
    }
    return 'Deactivated User';
  };

  useEffect(() => {
    if (!exportsLoading && exportedFiles) {
      const exportedRows = exportedFiles.map((file) => {
        return {
          id: file.id,
          status: file.status,
          name: file.name,
          type: file.type === 'timeline' ? 'Timeline' : 'Index',
          date: formatDisplayDate(file.date),
          dateWithTime: file.date,
          user: findUsername(file),
          url: file.result_url,
        };
      });
      setExports(exportedRows);
    }
  }, [exportedFiles, users]);

  useEffect(() => {
    (async () => {
      try {
        const usersList = await axios.post(`${apiUrl}getUsersForCase`, {
          userID: user.username,
          caseID,
        });
        setUsers(usersList.data);
      } catch (error) {
        console.log('get users error');
      }
    })();
  }, []);

  useEffect(() => {
    if (caseID) {
      logUserActivity({
        activity: 'case:exports',
        case_id: caseID,
      });
    }
  }, [caseID]);

  return (
    <>
      <CaseHeader caseInstance={caseInstance} timelineID={timelineID} />
      <Box
        sx={{
          height: 'calc(100% - 70px)',
          width: '100%',
          borderRadius: 0,
          justifyContent: 'center',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          backgroundColor: 'white',
        }}
      >
        <Stack
          direction="row"
          spacing={4}
          sx={{
            paddingTop: '1.2rem',
            paddingBottom: '1.2rem',
            width: '100%',
          }}
        >
          <Typography fontSize="1.2rem" fontWeight={600} sx={{ display: 'inline' }}>
            Exports
          </Typography>
        </Stack>
        {areExportsLoading ? (
          <Loading text="Loading Exports..." />
        ) : (
          <Box
            sx={{
              width: '100%',
              maxHeight: 'calc(100% - 200px)',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '1em',
              },
              '&::-webkit-scrollbar-thumb': {
                padding: '0 4px',
                border: '4px solid transparent',
                backgroundClip: 'padding-box',
                borderRadius: '100px',
              },
            }}
          >
            {exports?.length < 1 ? (
              <Box
                sx={{
                  textAlign: 'center',
                  justifyContent: 'center',
                  marginTop: '5rem',
                  marginBottom: '5rem',
                  width: '100%',
                  padding: '2rem',
                }}
              >
                <ExportsStyled />
                <Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>No Exports Found</Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    opacity: '50%',
                    fontSize: '0.8rem',
                  }}
                >
                  Any data exports you create will appear here
                </Typography>
              </Box>
            ) : (
              <DataGrid
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'dateWithTime', sort: 'desc' }],
                  },
                }}
                columns={columns}
                rows={exports}
                autoHeight
                disableColumnMenu
                hideFooter
                disableRowSelectionOnClick
                sx={{
                  width: windowSize.width ? `${windowSize.width - 150}px` : '100%',
                  border: '1px solid #00214733',
                  borderRadius: '8px',
                  fontSize: '0.8rem',
                  boxShadow: 'none',
                  minHeight: exports.length > 0 ? '0px' : '400px',
                  '& .MuiDataGrid-cell:focus-within': {
                    outline: ' none',
                  },
                }}
              />
            )}
          </Box>
        )}
      </Box>
    </>
  );
}

export default Exports;
