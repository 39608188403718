import { DocumentEdge } from '../../../api';

export const preferML = (a: DocumentEdge, b: DocumentEdge) => {
  if (a.created === 'ML' && b.created !== 'ML') {
    return -1;
  }
  if (a.created !== 'ML' && b.created === 'ML') {
    return 1;
  }
  return 0;
};

export const preferNonML = (a: DocumentEdge, b: DocumentEdge) => {
  if (a.created !== 'ML' && b.created === 'ML') {
    return -1;
  }
  if (a.created === 'ML' && b.created !== 'ML') {
    return 1;
  }
  return 0;
};
