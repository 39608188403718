import { useCallback, useMemo } from 'react';
import { ApolloError, useApolloClient } from '@apollo/client';
import { useQueryClient } from '@tanstack/react-query';
import {
  GetCaseContentFiltersDocument,
  BulkUpdatePageTagsMutationVariables,
  PageWithTagsFragmentFragment,
  useBulkUpdatePageTagsMutation,
} from '../../../__generated__/graphql';

const useBulkUpdatePageTags = () => {
  const apolloClient = useApolloClient();
  const queryClient = useQueryClient();

  const [mutation, state] = useBulkUpdatePageTagsMutation();
  const wrappedMutation = useCallback(
    async (
      params: BulkUpdatePageTagsMutationVariables & {
        newPagesState?: PageWithTagsFragmentFragment[];
        onCompleted?: <TData>(data: TData | {}) => void;
        onError?: (error: ApolloError) => void;
        caseID?: string;
        imagesUpdated?: boolean;
      },
    ) => {
      const resp = await mutation({
        variables: {
          where: params.where,
          data: params.data,
        },
        ...(params.newPagesState && {
          optimisticResponse: { bulkUpdatePageTags: params.newPagesState },
        }),
        onCompleted: params.onCompleted,
        onError: params.onError,
      });
      apolloClient.refetchQueries({
        include: [GetCaseContentFiltersDocument],
      });
      if (params.where?.pageIds?.length && params.caseID && params.imagesUpdated) {
        queryClient.invalidateQueries(['getImagePages', params.caseID, params.caseFileID]);
      }
      return resp;
    },
    [mutation],
  );
  return useMemo(() => [wrappedMutation, state], [wrappedMutation, state]);
};

export default useBulkUpdatePageTags;
