import { useMemo } from 'react';
import { NotesDocument, useGetCustomTagsForNotesQuery } from '../../../__generated__/graphql';
import { convertObjectToWhereInput } from './gqlUtils';

const useGetCustomTagsForNotes = (getNotesArgs: {
  id?: string;
  case_id?: string;
  document_id?: string;
  page_id?: string;
  owner_id?: string;
}) => {
  // does this conversion need to happen?
  const whereInput = useMemo(() => convertObjectToWhereInput(getNotesArgs), [getNotesArgs]);
  const { data, ...rest } = useGetCustomTagsForNotesQuery({
    variables: { where: whereInput },
  });

  return useMemo(
    () => ({
      notes: getUniqueCustomTagsFromNotes(data?.notes ?? []),
      ...rest,
    }),
    [data],
  );
};

const getUniqueCustomTagsFromNotes = (notes: Partial<NotesDocument>[]) => {
  const uniqueMap = Object.fromEntries(
    notes.flatMap((note) => note.note_tags).map((tag) => [tag?.tagID, tag?.customTag]),
  );

  return Object.values(uniqueMap);
};

export default useGetCustomTagsForNotes;
