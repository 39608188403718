export function pageIndexFromListByPageID(pages, pageID = -1) {
  return pages.findIndex((page) => page?.id === pageID);
}

export function pageFromListByPageID(pages, pageID = -1) {
  const pageIndex = pageIndexFromListByPageID(pages, pageID);

  if (pageIndex < 0) {
    return null;
  }

  return pages[pageIndex];
}
