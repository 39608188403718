import React from 'react';
import Theme from '../../theme';

interface ProcessingFilesProps {
  style?: React.CSSProperties;
}

export default function ProcessingFiles({ style = {} }: ProcessingFilesProps) {
  return (
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      cursor="pointer"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <rect x="1.5" y="1" width="24" height="24" rx="12" fill={Theme.palette.themeOrange.light} />
      <rect
        x="1.5"
        y="1"
        width="24"
        height="24"
        rx="12"
        stroke={Theme.palette.themeOrange.main}
        strokeWidth="2"
      />
      <path
        d="M13.4999 11.5001V13.5001M13.4999 15.5001H13.5049M12.8075 8.94598L8.69509 16.0493C8.46699 16.4433 8.35294 16.6403 8.3698 16.802C8.3845 16.943 8.45838 17.0711 8.57306 17.1545C8.70454 17.2501 8.93217 17.2501 9.38744 17.2501H17.6123C18.0676 17.2501 18.2952 17.2501 18.4267 17.1545C18.5414 17.0711 18.6153 16.943 18.63 16.802C18.6468 16.6403 18.5328 16.4433 18.3047 16.0493L14.1922 8.94598C13.9649 8.55339 13.8513 8.3571 13.703 8.29118C13.5737 8.23367 13.4261 8.23367 13.2967 8.29118C13.1485 8.3571 13.0348 8.55339 12.8075 8.94598Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
