import React from 'react';
import {
  IconButton,
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Close } from '@mui/icons-material';

type DialogModalProps = {
  header?: React.ReactNode;
  content?: React.ReactNode;
  contentSx?: React.CSSProperties;
  actions?: React.ReactNode;
  MainIcon?: React.ElementType;
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  paperSx?: React.CSSProperties;
  sx?: React.CSSProperties;
};

export default function DialogModal({
  header = '',
  content = <></>,
  contentSx = {},
  actions = <></>,
  MainIcon = undefined,
  open = false,
  loading = false,
  onClose = () => undefined,
  paperSx = {},
  sx = {},
}: DialogModalProps) {
  return (
    <MuiDialog open={open} sx={sx} onClose={onClose} PaperProps={{ sx: paperSx }}>
      {MainIcon && (
        <MainIcon
          sx={{
            color: 'secondary.main',
            marginTop: 10,
            fontSize: 40,
          }}
        />
      )}
      <DialogTitle component="div" sx={{ marginBottom: '-10px' }}>
        {onClose && (
          <IconButton
            aria-label="close"
            disabled={loading}
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        )}
        {header}
      </DialogTitle>
      <DialogContent sx={contentSx}>{content}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </MuiDialog>
  );
}
