import { useState } from 'react';
import { Button, TextField, Alert } from '@mui/material';

export default function RecoverPassword(props) {
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [error, setError] = useState('');
  const { getUser, setAction, tryAgain } = props;

  const clearError = () => setError('');

  function validatePassword() {
    if (password.length < 8) {
      setError('Password must have at least 8 characters.');
      return false;
    }
    let hasNumber = false;
    let uppercase = false;
    let lowercase = false;
    for (let i = 0; i < password.length; i++) {
      const character = password.charAt(i);
      if (!isNaN(character)) {
        hasNumber = true;
      } else if (character === character.toUpperCase() && character !== character.toLowerCase()) {
        uppercase = true;
      } else if (character === character.toLowerCase() && character !== character.toUpperCase()) {
        lowercase = true;
      }
    }
    if (!uppercase || !lowercase) {
      setError('Password must include both uppercase and lowercase letters.');
      return false;
    }
    if (!hasNumber) {
      setError('Password must include numbers.');
      return false;
    }
    if (password !== confirmedPassword) {
      setError('Passwords must match.');
      return false;
    }
    return true;
  }

  function resetPassword(event) {
    event.preventDefault();
    if (!validatePassword()) {
      return;
    }
    getUser().confirmPassword(code, password, {
      onSuccess: () => {
        setAction('COMPLETE');
      },
      onFailure: () => {
        setError('The verification code is incorrect.');
      },
    });
  }

  return (
    <>
      <form
        onSubmit={resetPassword}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div style={{ textAlign: 'center' }}>Please check your email for a recovery code.</div>

        <TextField
          variant="standard"
          value={code}
          onChange={(event) => setCode(event.target.value)}
          label="Recovery Code"
          style={{ marginTop: 4 }}
          autoComplete="one-time-code"
          className="fs-exclude"
        />
        <TextField
          variant="standard"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          type="password"
          label="New Password"
          autoComplete="off"
          className="fs-exclude"
        />
        <TextField
          variant="standard"
          value={confirmedPassword}
          type="password"
          onChange={(event) => setConfirmedPassword(event.target.value)}
          label="Confirm Password"
          className="fs-exclude"
        />
        <Button
          style={{ marginTop: 12 }}
          size="large"
          variant="contained"
          type="submit"
          disabled={password === '' || code === '' || confirmedPassword === ''}
        >
          Change Password
        </Button>
      </form>
      <div
        style={{
          cursor: 'pointer',
          marginTop: '1rem',
          width: 'fit-content',
          margin: '1rem auto 0px auto',
          fontSize: '12px',
          display: 'flex',
        }}
      >
        <div>Didn't receive a code?</div>
        <div style={{ color: '#2B60DE', marginLeft: '2px' }} role="presentation" onClick={tryAgain}>
          Try Again
        </div>
      </div>
      {error !== '' && (
        <Alert severity="error" onClose={clearError}>
          {error}
        </Alert>
      )}
    </>
  );
}
