import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { useSearchParams } from 'react-router-dom';
import OverflowText from '../../../components/common/OverflowText';
import LinkWithQuery from '../../Timeline/LinkWithQuery';
import Caution from '../../../components/icons/Caution';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';

export default function DocumentCard(props: Props) {
  const { documentID, docFileName, numberOfPages, firstPageID, documentStatus } = props;
  const [searchParams] = useSearchParams();
  const timelineID: any = searchParams.get('timelineID') ?? null;

  const isFileProcessor = useIsFileProcessor();
  const caution = documentStatus !== 'COMPLETE' && !isFileProcessor;
  return (
    <Card
      id={`document-card-${documentID}`}
      sx={{
        maxWidth: '100%',
        overflow: 'hidden',
        backgroundColor: 'inherit',
        marginBottom: '1rem',
        width: '100%',
        height: '100%',
        border: 'none',
        '&:hover': {
          border: 'none',
        },
      }}
    >
      <CardContent
        sx={{
          paddingTop: '4px',
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingBottom: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '4px',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <LinkWithQuery
              timelineID={timelineID}
              to={`../${documentID}/${firstPageID ?? ''}`}
              style={{
                color: '#344054',
                display: 'flex',
                justifyContent: 'space-between',
                textDecoration: 'none',
              }}
            >
              <OverflowText
                tooltipTitle={
                  caution
                    ? 'These files will not appear in the Timeline until the processing is complete.'
                    : ''
                }
                sx={{
                  fontSize: '0.85rem',
                  fontWeight: 500,
                  marginBottom: '0.25rem',
                  verticalAlign: 'top',
                  maxWidth: '75%',
                  display: 'inline-block',
                }}
              >
                {caution && <Caution style={{ margin: '0.25rem' }} />}
                {docFileName}
              </OverflowText>
              <Typography
                color="#98A2B3"
                sx={{
                  fontSize: '0.75rem',
                  fontWeight: 500,
                  verticalAlign: 'top',
                  display: 'inline-block',
                }}
              >
                {numberOfPages} {numberOfPages === 1 ? 'Page' : 'Pages'}
              </Typography>
            </LinkWithQuery>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export function DocumentCardSkeleton() {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" height={50} mx="1rem">
      <Box flex="80%" display="flex" alignItems="center" px="6px">
        <Skeleton variant="text" width={200} height={28} />
      </Box>
      <Box flex="20%" display="flex" alignItems="center">
        <Skeleton variant="text" width={70} height={28} />
      </Box>
    </Box>
  );
}

type Props = {
  documentID: string;
  docFileName: string;
  numberOfPages: number;
  firstPageID: string;
  documentStatus: string;
};
