import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  createDocumentSimilarity,
  getDocumentComparison,
  markDocumentAsDuplicateOf,
  markDocumentAsNotDuplicateOf,
} from '../../../../api';
import { useAsync } from '../../../../hooks/useAsync';

export function useCompareDocuments(
  caseVersion: number,
  firstDocumentId: string,
  secondDocumentId: string,
  setDocumentIds: (ids: { firstDocumentID?: string; secondDocumentID?: string }) => void,
  firstDocumentSectionID: string,
  secondDocumentSectionID: string,
  updateDocumentStatusV1: (entryID: bigint, sectionId: string, isDuplicate: boolean) => void,

  isFirstDocumentDuplicateV1: boolean, // used for v1 duplicates only
  isSecondDocumentDuplicateV1: boolean, // used for v1 duplicates only
  updateIndexRowCache: (sectionId: string, rowId: string, updatedValues: any) => void,
) {
  const { caseID } = useParams();
  const [documentSimilarity, setDocumentSimilarity] = useState<any | null>(null);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);

  const [existingDocumentSimilarity, refreshDocumentSimilarity] = useAsync(
    () => fetchDocumentSimilarityIfNeeded(),
    [caseID, firstDocumentId, secondDocumentId],
  );

  useEffect(() => {
    // handle error state
    if (existingDocumentSimilarity.status === 'resolved') {
      if (existingDocumentSimilarity?.data?.document_similarity) {
        const stringifiedDocumentSimilarity = {
          ...existingDocumentSimilarity.data?.document_similarity,
          document_id: String(existingDocumentSimilarity.data?.document_similarity.document_id),
          duplicate_of: String(existingDocumentSimilarity.data?.document_similarity.duplicate_of),
        };
        setDocumentSimilarity(stringifiedDocumentSimilarity);
      }
    } else {
      setDocumentSimilarity(null);
    }
  }, [existingDocumentSimilarity]);

  const indexSectionIDByDocumentID = useMemo(() => {
    return {
      [String(firstDocumentId)]: firstDocumentSectionID,
      [String(secondDocumentId)]: secondDocumentSectionID,
    };
  }, [firstDocumentId, secondDocumentId, firstDocumentSectionID, secondDocumentSectionID]);

  const fetchDocumentSimilarityIfNeeded = async () => {
    if (!caseID || !firstDocumentId || !secondDocumentId || caseVersion === 1) {
      return null;
    }
    const res = await getDocumentComparison(
      caseID,
      String(firstDocumentId),
      String(secondDocumentId),
    );
    return res.data ?? null;
  };

  const updateIndexData = useCallback(
    async (entryID: bigint, isDuplicate: boolean) => {
      // ensure other row is toggled to not duplicate
      updateIndexRowCache(String(indexSectionIDByDocumentID[String(entryID)]), String(entryID), {
        all_pages_marked_duplicate: isDuplicate,
      });

      if (isDuplicate) {
        const otherEntryId =
          entryID === BigInt(firstDocumentId) ? BigInt(secondDocumentId) : BigInt(firstDocumentId);

        updateIndexRowCache(
          String(indexSectionIDByDocumentID[String(otherEntryId)]),
          String(otherEntryId),
          { all_pages_marked_duplicate: false },
        );
      }
    },
    [firstDocumentId, secondDocumentId, indexSectionIDByDocumentID, updateIndexRowCache],
  );

  const updateDocumentToDuplicateDocumentSimilarities = useCallback(
    async (document_id: string) => {
      if (!caseID) {
        throw new Error('Case ID is required');
      }

      if (documentSimilarity == null) {
        const originalDocumentID =
          document_id === firstDocumentId ? secondDocumentId : firstDocumentId;

        const res = await createDocumentSimilarity(caseID, document_id, originalDocumentID);
        setDocumentSimilarity({
          ...res.data,
          document_id: String(res.data.document_id),
          duplicate_of: String(res.data.duplicate_of),
        });
        updateIndexData(BigInt(document_id), true);
        toast.success('Document marked as duplicate', { autoClose: 500 });
        return;
      }

      const originalDocumentID =
        document_id === documentSimilarity.document_id
          ? documentSimilarity.duplicate_of
          : documentSimilarity.document_id;

      await markDocumentAsDuplicateOf(caseID, document_id, originalDocumentID);
      await refreshDocumentSimilarity();
      toast.success('Document marked as duplicate', { autoClose: 500 });
      updateIndexData(BigInt(document_id), true);
    },
    [caseID, documentSimilarity, firstDocumentId, secondDocumentId, updateIndexData],
  );

  const updateDocumentToNotDuplicateDocumentSimilarities = useCallback(
    async (document_id: string) => {
      if (!caseID) {
        throw new Error('Case ID is required');
      }

      if (documentSimilarity == null) {
        return;
      }

      const originalDocumentID =
        document_id === documentSimilarity.document_id
          ? documentSimilarity.duplicate_of
          : documentSimilarity.document_id;

      await markDocumentAsNotDuplicateOf(caseID, document_id, originalDocumentID);
      await refreshDocumentSimilarity();
      updateIndexData(BigInt(document_id), false);
      toast.success('Document marked as not duplicate', { autoClose: 500 });
    },
    [caseID, documentSimilarity, updateIndexData],
  );

  const setNewOriginal = useCallback(
    async (original: string, documentID: string) => {
      if (!caseID) {
        throw new Error('Case ID is required');
      }
      if (documentSimilarity == null) {
        const res = await createDocumentSimilarity(caseID, documentID, original);

        setDocumentSimilarity({
          ...res.data,
          document_id: String(res.data.document_id),
          duplicate_of: String(res.data.duplicate_of),
        });
        updateIndexData(BigInt(documentID), true);
        toast.success('Document marked as duplicate', { autoClose: 500 });
        setDocumentIds({ firstDocumentID: original, secondDocumentID: documentID });
        await refreshDocumentSimilarity();
        return;
      }
      await markDocumentAsDuplicateOf(caseID, original, documentID);
      setDocumentIds({ firstDocumentID: original, secondDocumentID: documentID });
      updateIndexData(BigInt(original), true);
      await refreshDocumentSimilarity();
      toast.success('Document marked as original', { autoClose: 500 });
    },
    [caseID, documentSimilarity, updateIndexData],
  );

  // hook returns
  const isFirstDocumentDuplicate = useMemo(() => {
    if (caseVersion === 1) {
      return isFirstDocumentDuplicateV1;
    }

    if (
      documentSimilarity?.status === 'Duplicate' &&
      String(documentSimilarity?.document_id) === firstDocumentId
    ) {
      return true;
    }
    return false;
  }, [caseVersion, isFirstDocumentDuplicateV1, firstDocumentId, documentSimilarity]);

  const isSecondDocumentDuplicate = useMemo(() => {
    if (caseVersion === 1) {
      return isSecondDocumentDuplicateV1;
    }

    if (
      documentSimilarity?.status === 'Duplicate' &&
      String(documentSimilarity?.document_id) === secondDocumentId
    ) {
      return true;
    }
    return false;
  }, [caseVersion, isSecondDocumentDuplicateV1, secondDocumentId, documentSimilarity]);

  const updateDocumentToDuplicate = useCallback(
    async (document_id: string) => {
      if (!caseID) {
        throw new Error('Case ID is required');
      }
      setIsUpdateInProgress(true);
      if (caseVersion === 1) {
        await updateDocumentStatusV1(
          BigInt(document_id),
          indexSectionIDByDocumentID[document_id],
          true,
        );
      } else {
        await updateDocumentToDuplicateDocumentSimilarities(document_id);
      }
      setIsUpdateInProgress(false);
    },
    [
      caseID,
      caseVersion,
      documentSimilarity,
      firstDocumentSectionID,
      updateDocumentStatusV1,
      indexSectionIDByDocumentID,
    ],
  );

  const updateDocumentToNonDuplicate = useCallback(
    async (document_id: string) => {
      if (!caseID) {
        throw new Error('Case ID is required');
      }
      setIsUpdateInProgress(true);

      if (caseVersion === 1) {
        await updateDocumentStatusV1(
          BigInt(document_id),
          indexSectionIDByDocumentID[document_id],
          false,
        );
      } else {
        await updateDocumentToNotDuplicateDocumentSimilarities(document_id);
      }
      setIsUpdateInProgress(false);
    },
    [
      caseID,
      caseVersion,
      firstDocumentSectionID,
      documentSimilarity,
      updateDocumentStatusV1,
      indexSectionIDByDocumentID,
    ],
  );

  return {
    isFirstDocumentDuplicate,
    isSecondDocumentDuplicate,
    updateDocumentToDuplicate,
    updateDocumentToNonDuplicate,
    isUpdateInProgress,
    setNewOriginal,
  };
}
