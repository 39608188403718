import { useMemo } from 'react';
import useFeatureFlags from '../../config/useFeatureFlags';
import { PageType } from '../Page/types/pageTypes';
import { TimelineEntry as TimelineEntryType, Tag } from './types/timelineTypes';

type MissingInfoCheckerProps = {
  entry: TimelineEntryType;
  pages: PageType[];
};

type MissingInfoResult = {
  count: number;
  fields: string[];
};

const MDocRules = ({ entry, pages }: MissingInfoCheckerProps): MissingInfoResult => {
  const { data: featureFlagsData } = useFeatureFlags();
  const isAuthorExtractionEnabled = featureFlagsData?.AuthorExtractionEnabled ?? false;

  const { count, fields } = useMemo(() => {
    let missingInfoCount = 0;
    const missingFields: string[] = [];

    if (!entry?.author_id && isAuthorExtractionEnabled) {
      missingInfoCount++;
      missingFields.push('No author specified for this document.');
    }

    if (!entry?.org_id && isAuthorExtractionEnabled) {
      missingInfoCount++;
      missingFields.push('No organization specified for this document.');
    }

    const coverSheetTag =
      pages.length === 1 &&
      pages[0].tags.find(
        (tag: { id: number; name: string; type: string; origin: string }) =>
          tag.id === 142 ||
          (tag.name === 'COVER_SHEET' && tag.type === 'content_type' && tag.origin === 'siftmed'),
      );

    const hasOtherContentTag =
      pages.length === 1 &&
      pages[0].tags.some(
        (tag: { id: number; name: string; type: string; origin: string }) =>
          tag.id !== 142 &&
          !(tag.name === 'COVER_SHEET' && tag.type === 'content_type' && tag.origin === 'siftmed'),
      );

    if (coverSheetTag && !hasOtherContentTag) {
      missingInfoCount++;
      missingFields.push('The document contains only a cover sheet with no other content.');
    }

    if (pages.length > 1) {
      const allTags: Tag[][] = pages.map((page) => page.tags);
      const commonTags = allTags[0].filter((tag) =>
        allTags.every((tags) => tags.some((pageTag) => pageTag.id === tag.id)),
      );

      if (commonTags.length === 0) {
        missingInfoCount++;
        missingFields.push('All pages must have at least one common tag.');
      }
    }

    // Check for OCF tags and if any OCF tag is selected that is not applied to all pages
    const allOCFTags = pages.flatMap((page) =>
      page.tags.filter((tag) => tag.parent_tag_id === 124 && tag.type === 'content_type'),
    );

    const uniqueOCFTags = Array.from(new Set(allOCFTags.map((tag) => tag.id)));
    const ocfTagAppliedToAllPages = uniqueOCFTags.length === 1 ? uniqueOCFTags[0] : null;

    // Check if the OCF tag is applied to all pages
    const isOCFAppliedToAllPages = ocfTagAppliedToAllPages
      ? pages.every((page) => page.tags.some((tag) => tag.id === ocfTagAppliedToAllPages))
      : false;

    // Check for other document types when an OCF tag is applied
    const hasOtherDocumentTypes = pages.some((page) =>
      page.tags.some((tag) => tag.parent_tag_id !== 124),
    );
    const hasOtherSpecialityTypes = pages.some((page) =>
      page.tags.some((tag) => tag.parent_tag_id !== 123 && tag.type === 'speciality'),
    );

    // Check for any different OCF tag that is not applied to all pages
    if (isOCFAppliedToAllPages) {
      const hasDifferentOCFTag = pages.some((page) =>
        page.tags.some(
          (tag) =>
            tag.parent_tag_id === 124 &&
            tag.type === 'content_type' &&
            tag.id !== ocfTagAppliedToAllPages,
        ),
      );

      if (hasDifferentOCFTag) {
        missingInfoCount++;
        missingFields.push('There is an OCF tag selected that is not a document type.');
      }

      if (hasOtherDocumentTypes || hasOtherSpecialityTypes) {
        missingInfoCount++;
        missingFields.push('Cannot have other document types when OCF is selected.');
      }
    } else if (uniqueOCFTags.length > 1) {
      missingInfoCount++;
      missingFields.push('Cannot select more than one OCF-tag.');
    } else if (uniqueOCFTags.length === 1) {
      // Check for other document types when an OCF tag is applied to a single page (not to all pages)

      if (hasOtherDocumentTypes || hasOtherSpecialityTypes) {
        missingInfoCount++;
        missingFields.push('Cannot have other tags when an OCF is selected.');
      }
    }

    return { count: missingInfoCount, fields: missingFields };
  }, [entry, pages, isAuthorExtractionEnabled]);

  return { count, fields };
};

export default MDocRules;
export type { MissingInfoResult };
