import { gql } from '@apollo/client';

export const TAG_FRAGMENT = gql`
  fragment TagFragment on TagObject {
    id
    name
    type
  }
`;

export const PAGE_WITH_CONTENT_TYPES_FRAGMENT = gql`
  fragment PageWithContentTypesFragment on PageObject {
    content_types {
      id
      name
      type
    }
  }
`;

export const PAGE_WITH_SPECIALITIES_FRAGMENT = gql`
  fragment PageWithSpecialitiesFragment on PageObject {
    specialities {
      id
      name
      type
    }
  }
`;

export const PAGE_WITH_CONTENT_TYPES_AND_SPECIALITIES_FRAGMENT = gql`
  fragment PageWithContentTypesAndSpecialitiesFragment on PageObject {
    ...PageWithContentTypesFragment
    ...PageWithSpecialitiesFragment
  }
`;
