import { Button, Container, Typography } from '@mui/material';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { Link } from 'react-router-dom';

export default function NoCaseFound() {
  return (
    <Container maxWidth="lg" style={{ marginTop: '2rem' }}>
      <div style={{ maxWidth: '500px', margin: 'auto', marginTop: '2rem' }}>
        <div style={{ textAlign: 'center' }}>
          <AssignmentLateIcon style={{ fontSize: '6rem', color: '#2F497B' }} />
        </div>
        <Typography variant="h5">We couldn't find that case.</Typography>
        <p style={{ marginTop: '1rem' }}>
          If you did not create this case, please confirm that you were granted access to it.
        </p>
        <p>If you think this is an error, please contact us.</p>
        <div style={{ width: '100%', margin: 'auto', textAlign: 'center' }}>
          <Link to="/cases">
            <Button variant="contained" color="primary" style={{ margin: 'auto', width: '100%' }}>
              Back to my cases
            </Button>
          </Link>
        </div>
      </div>
    </Container>
  );
}
