import { useQuery } from '@tanstack/react-query';
import { authenticatedRequest } from '../../api';

async function fetchFileChunk({ caseID, documentID, pageNumber }) {
  const res = await authenticatedRequest({
    method: 'GET',
    url: `/documents/getDocumentChunk?caseID=${caseID}&documentID=${documentID}&documentPageNumber=${pageNumber}`,
  });
  return res.data.urls;
}

export default function useFileChunks({ caseID, documentID, pageNumber }) {
  const chunkNumber = Math.floor((pageNumber - 1) / 50);
  return useQuery(
    ['fileChunk', caseID, documentID, chunkNumber],
    () => fetchFileChunk({ caseID, documentID, pageNumber }),
    {
      staleTime: 45 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
    },
  );
}
