import { documentRotationValues } from '../Case/usePDFViewerStore';
import { BoundingBoxType } from './types';

export function calculateClickPositionRelativeToParent(
  clickEvent: MouseEvent,
  captureAreaRef: any,
) {
  const { x, y } = captureAreaRef.current.getBoundingClientRect();
  const posX = clickEvent.clientX - x;
  const posY = clickEvent.clientY - y;
  return [posX, posY];
}

export function computeBorderWidth(
  boundingBox: BoundingBoxType,
  parentWidth: number,
  parentHeight: number,
) {
  const { sx, sy, ex, ey } = getAbsoluteBoundingBoxInPx(boundingBox);
  return `${sy}px ${parentWidth - ex}px ${parentHeight - ey}px ${sx}px`;
}

export function getAbsoluteBoundingBoxInPx({
  sx: startX,
  sy: startY,
  ex: endX,
  ey: endY,
}: BoundingBoxType) {
  const sx = Math.min(startX, endX);
  const sy = Math.min(startY, endY);
  const ex = Math.max(endX, startX);
  const ey = Math.max(endY, startY);
  return { sx, sy, ex, ey };
}

/**
 *
 * This function returns the bounding box coordinates as a percentage of the parent container.
 */
export function getCaptureAreaAsPercentageOfParent(
  boundingBox: BoundingBoxType,
  width: number,
  height: number,
): BoundingBoxType {
  const { sx, sy, ex, ey } = getAbsoluteBoundingBoxInPx(boundingBox);
  const percentageStartX = +(sx / width);
  const percentageStartY = +(sy / height);
  const percentageEndX = +(ex / width);
  const percentageEndY = +(ey / height);

  return convertBoundingBoxValuesToFixedDecimal({
    sx: percentageStartX,
    sy: percentageStartY,
    ex: percentageEndX,
    ey: percentageEndY,
  });
}

export function getCaptureAreaRelativeToRotation(
  { sx, sy, ex, ey }: BoundingBoxType,
  pageRotation: documentRotationValues,
) {
  let startX = sx;
  let startY = sy;
  let endX = ex;
  let endY = ey;
  if (pageRotation === 90) {
    startX = sy;
    startY = 1 - ex;
    endX = ey;
    endY = 1 - sx;
  } else if (pageRotation === 180) {
    startY = 1 - ey;
    endY = 1 - sy;
    startX = 1 - ex;
    endX = 1 - sx;
  } else if (pageRotation === 270) {
    startX = 1 - ey;
    startY = sx;
    endX = 1 - sy;
    endY = ex;
  }
  return convertBoundingBoxValuesToFixedDecimal({
    sx: startX,
    sy: startY,
    ex: endX,
    ey: endY,
  });
}

function convertBoundingBoxValuesToFixedDecimal({
  sx,
  sy,
  ex,
  ey,
}: BoundingBoxType): BoundingBoxType {
  return {
    sx: +sx.toFixed(2),
    sy: +sy.toFixed(2),
    ex: +ex.toFixed(2),
    ey: +ey.toFixed(2),
  };
}
