import { useMemo } from 'react';
import { TagType, useGetPageTagsQuery } from '../../../__generated__/graphql';
import { selectors } from '../useSourceContent';

const useSources = () => {
  const query = useGetPageTagsQuery({
    variables: {
      where: {
        type: {
          equals: TagType.Source,
        },
      },
    },
  });

  const sourceMap = useMemo(() => selectors.asMap(query.data?.tags ?? []), [query.data]);
  return useMemo(
    () => ({
      ...query,
      data: query.data?.tags,
      sourceMap,
    }),
    [query],
  );
};

export default useSources;
