/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import Box from '@mui/material/Box';
import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import { VariableSizeList as List, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { ChangeTimelineEntryDetailsInput } from '../../../__generated__/graphql';
import config from '../../../config';
import TimelineViewCard from '../TimelineViewCard';
import {
  TimelineEntryDescriptor,
  TimelineEntry as TimelineEntryType,
  TimelineKeywordSearchResults,
} from '../types/timelineTypes';
import useDisplayStore from '../useDisplayStore';
import TimelineEntrySkeleton from './TimelineEntrySkeleton';
import useToggleFavouriteMutation from '../../Page/gql/useUpdatePageFavourite';
import useUpdatePageViewed from '../../Page/gql/updatePageViewed';
import { PageControls } from '../../Page/types/pageTypes';
import useNotes from '../../Notes/gql/useNotes';
import { maxPagesBeforeScroll } from '../Components/PageListView';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';

export default function TimelineEntryList({
  listKey,
  initialScrollOffset,
  onScroll,
  height,
  outerRef,
  // pagination callbacks
  onLoadMoreItems,
  onMergePreviousEntry,
  onBreakApartEntries,
  // data
  count,
  items,
  itemDescriptors,
  caseID,
  handleIsSegmentDownloading,
  setShowTimelineUpdated,
  updateTimelineEntry,
  showMergeButton,
  searchResultCounts,
  currentDocumentID,
  showMergeSplitAction,
}: TimelineListEntryProps) {
  const listRef = useRef<List | null>();
  const handleIsItemLoaded = useCallback((index: number) => Boolean(items?.[index]), [items]);
  const [showThumbnails, getThumbnailGridListHeight, thumbnailSize, timelineWidth] =
    useDisplayStore((state) => [
      state.showThumbnails,
      state.getThumbnailGridListHeight,
      state.thumbnailSize,
      state.timelineWidth,
    ]);

  const [changeFavouriteMark] = useToggleFavouriteMutation();
  const [updatePageToViewed] = useUpdatePageViewed();
  const { data: notes } = useNotes({ case_id: caseID });
  const isFileProcessor = useIsFileProcessor();

  const calculateItemSize = useCallback(
    (index: number) =>
      getHeight({
        index,
        items: itemDescriptors,
        searchResultCounts,
        showThumbnails,
        getThumbnailGridListHeight,
      }),
    [itemDescriptors, searchResultCounts, showThumbnails, getThumbnailGridListHeight],
  );

  useEffect(() => {
    // recalculate the cached sizes
    // ref: https://react-window.vercel.app/#/api/VariableSizeList
    listRef.current?.resetAfterIndex(0, true);

    // adding to this list has huge performance implications.
  }, [thumbnailSize]);

  const pageControls: PageControls = useMemo(() => {
    return {
      handleToggleViewed: updatePageToViewed,
      handleToggleFavourite: changeFavouriteMark,
    };
  }, []);

  const renderEntry = useCallback(
    ({ index, style }: ListChildComponentProps) => {
      const entry = items[index];
      const descriptor = itemDescriptors[index];
      if (!entry) {
        return (
          <TimelineEntrySkeleton
            style={style}
            index={index}
            entryID={descriptor.id}
            countOfPages={descriptor.pages.length}
          />
        );
      }

      return (
        <Box id={`${entry.id}`} style={style}>
          <TimelineViewCard
            searchResultsCounts={searchResultCounts}
            entry={entry}
            caseID={caseID}
            handleIsSegmentDownloading={handleIsSegmentDownloading}
            setShowTimelineUpdated={setShowTimelineUpdated}
            updateTimelineEntry={updateTimelineEntry}
            showMergeButton={showMergeButton}
            onMergePreviousEntry={onMergePreviousEntry}
            onBreakApartEntries={onBreakApartEntries}
            pageControls={pageControls}
            notes={notes}
            currentDocumentID={currentDocumentID}
          />
        </Box>
      );
    },
    [
      items,
      itemDescriptors,
      searchResultCounts,
      caseID,
      handleIsSegmentDownloading,
      setShowTimelineUpdated,
      updateTimelineEntry,
      showMergeButton,
      onMergePreviousEntry,
      onBreakApartEntries,
      pageControls,
      notes,
      currentDocumentID,
    ],
  );

  return (
    /* @ts-ignore */
    <InfiniteLoader
      itemCount={count}
      isItemLoaded={handleIsItemLoaded}
      loadMoreItems={onLoadMoreItems}
      threshold={10}
      minimumBatchSize={config.timeline.gql.defaultTake}
    >
      {({ onItemsRendered, ref }) => (
        /*  @ts-ignore */
        <List
          key={showThumbnails ? `${listKey}-with-thumbnails` : `${listKey}-without-thumbnails`}
          className="List side-nav-list"
          initialScrollOffset={initialScrollOffset}
          onScroll={onScroll}
          height={height}
          itemCount={count}
          itemSize={calculateItemSize}
          ref={(r) => {
            ref(r);
            listRef.current = r;
          }}
          onItemsRendered={onItemsRendered}
          outerRef={outerRef}
          style={{ paddingBottom: showMergeSplitAction || isFileProcessor ? '6rem' : 0 }}
          overscanCount={1}
          width={timelineWidth}
        >
          {renderEntry}
        </List>
      )}
    </InfiniteLoader>
  );
}

type getHeightProps = {
  items: TimelineEntryDescriptor[];
  index: number;
  searchResultCounts: TimelineKeywordSearchResults;
  showThumbnails: boolean;
  getThumbnailGridListHeight: (numberOfPages: number) => number;
};

export const getHeight = ({
  items,
  index,
  searchResultCounts,
  showThumbnails,
  getThumbnailGridListHeight,
}: getHeightProps) => {
  const currentEntry = items[index];
  // Add space for search counter if search is active
  const addedSpaceForSearchCounter = currentEntry?.id in (searchResultCounts || {}) ? 25 : 0;

  const numberOfPages = currentEntry?.pages.length;
  // if extend pages, use page list height
  const pageListHeight = showThumbnails
    ? getThumbnailGridListHeight(numberOfPages)
    : Math.min(numberOfPages * 26, maxPagesBeforeScroll * 26);

  return 75 + pageListHeight + addedSpaceForSearchCounter;
};

type TimelineListEntryProps = {
  listKey: string;
  initialScrollOffset: number;
  onScroll: ({ scrollOffset }: { scrollOffset: number }) => void;
  height: number;
  outerRef: React.Ref<any>;
  onLoadMoreItems: (from: number, to: number) => void;
  onMergePreviousEntry: (entryID: number) => void;
  onBreakApartEntries: (entryID: number) => void;
  items: TimelineEntryType[];
  itemDescriptors: TimelineEntryDescriptor[];
  count: number;
  caseID: string;
  handleIsSegmentDownloading: Function;
  updateTimelineEntry: (params: ChangeTimelineEntryDetailsInput) => Promise<unknown>;
  setShowTimelineUpdated: (show: boolean) => void;
  showMergeButton: boolean;
  searchResultCounts: TimelineKeywordSearchResults;
  currentDocumentID?: string;
  showMergeSplitAction: boolean;
};
