import { Box, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

export default function OverflowText({
  sx = {},
  children,
  tooltipTitle = '',
  placement = 'top',
  className = '',
}) {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef();
  const title = tooltipTitle ?? children;

  useEffect(() => {
    setIsOverflow(textElementRef.current.scrollWidth >= textElementRef.current.clientWidth);
  }, []);

  return (
    <Tooltip title={title} placement={placement} disableHoverListener={!isOverflowed}>
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...sx,
        }}
        className={className}
        ref={textElementRef}
      >
        {children}
      </Box>
    </Tooltip>
  );
}
