export default function PageCardFlag(props) {
  const { important, ...restProps } = props;

  return (
    <svg
      {...restProps}
      viewBox="0 0 13 16"
      fill={important ? '#667085' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.46313 10.0694C1.46313 10.0694 2.13443 9.39809 4.14832 9.39809C6.16221 9.39809 7.5048 10.7407 9.51869 10.7407C11.5326 10.7407 12.2039 10.0694 12.2039 10.0694V2.68512C12.2039 2.68512 11.5326 3.35642 9.51869 3.35642C7.5048 3.35642 6.16221 2.01383 4.14832 2.01383C2.13443 2.01383 1.46313 2.68512 1.46313 2.68512M1.46314 14.7685L1.46313 1.34253"
        stroke="currentColor"
        strokeWidth="1.34259"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
