import { Stack, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import useCase from '../Case/useCase';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import CaseProgressDialog from './CaseProgess';
import useCaseFilesStatus from '../Files/useCaseFilesStatus';

export default function PagesRead({ caseID }) {
  const { loading, error, data: currentCase } = useCase({ caseID });
  const isFileProcessor = useIsFileProcessor();
  const { data: files } = useCaseFilesStatus(caseID);
  const [open, setOpen] = useState(false);
  const dialogRef = useRef();
  if (error || (!currentCase && !loading)) {
    return (
      <Typography sx={{ display: 'inline' }} variant="pageCardDocName">
        Error displaying pages viewed.
      </Typography>
    );
  }

  return loading ? (
    <Typography sx={{ display: 'inline' }} variant="pageCardDocName">
      Loading Pages Viewed...
    </Typography>
  ) : (
    <Stack direction="row" mt={1}>
      <Typography sx={{ fontWeight: 400 }} variant="pageCardDocName">
        {currentCase?.pageCount} pages
      </Typography>
      <Typography mt={-0.4} mx={0.5} sx={{ fontSize: '1rem' }} variant="pageCardDocName">
        |
      </Typography>
      {isFileProcessor ? (
        <>
          <Typography
            ref={dialogRef}
            variant="pageCardDocName"
            sx={{
              color: 'caseStatus.processorActionButtonText',
              '&:hover': { fontWeight: 600 },
              cursor: 'pointer',
            }}
            mr={0.5}
            onClick={() => setOpen(true)}
          >
            {currentCase &&
              currentCase.pageCount &&
              Math.min(Math.floor(((files?.complete ?? 0) / (files?.total ?? 0)) * 100), 100)}
            % Completed
          </Typography>
          <CaseProgressDialog
            open={open}
            setOpen={setOpen}
            position={{
              top: (dialogRef?.current?.offsetTop ?? 40) + 20,
              left: (dialogRef?.current?.offsetLeft ?? 110) + 50,
            }}
            files={files}
          />
        </>
      ) : (
        <>
          <Typography variant="pageCardDocName" sx={{ fontWeight: 600 }} mr={0.5}>
            {currentCase &&
              currentCase.pageCount &&
              Math.min(
                Math.floor(((currentCase.pagesViewed ?? 0) / currentCase.pageCount) * 100),
                100,
              )}
            %
          </Typography>

          <Typography variant="pageCardDocName">Viewed</Typography>
        </>
      )}
    </Stack>
  );
}
