import { forwardRef } from 'react';
import { IconButton, Badge } from '@mui/material';
import PropTypes from 'prop-types';
import FilterOutlined from '../icons/FilterOutlined';

const FilterButton = forwardRef(function FilterButton({ filterCount, onClick, dot, ...props }) {
  return (
    <Badge
      badgeContent={filterCount}
      variant={dot ? 'dot' : ''}
      sx={{
        '& .MuiBadge-badge': {
          color: '#1D2939',
          backgroundColor: '#A6F4C5',
          height: dot ? '0.8rem' : '1.2rem',
          width: dot ? '0.8rem' : '1.2rem',
          borderRadius: 20,
          right: dot && '0.1rem',
          top: dot && '0.4rem',
        },
        ...props,
      }}
    >
      <IconButton
        variant="contained"
        onClick={(e) => onClick(e)}
        sx={{
          borderRadius: '8px',
          backgroundColor: 'primary.light',
          height: '2.5rem',
          width: '2.5rem',
          marginLeft: '0.5rem',
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
        }}
      >
        <FilterOutlined />
      </IconButton>
    </Badge>
  );
});

FilterButton.propTypes = {
  filterCount: PropTypes.number,
  onClick: PropTypes.func,
  dot: PropTypes.bool,
};

FilterButton.defaultProps = {
  filterCount: 0,
  onClick: undefined,
  dot: false,
};

export default FilterButton;
