import React from 'react';

export default function UpArrowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      style={{
        width: '20px',
        height: '20px',
        ...props.style,
      }}
      viewBox="0 0 15 16"
      fill={props.color ?? '#344054'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon points="7.5,5 11,8.5 4,8.5" />
    </svg>
  );
}
