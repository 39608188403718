import React from 'react';
import CloseIcon from '../../../icons/Close';
import './chip.css';

type Props = {
  label: string;
  onClick: (event) => void;
  style?: React.CSSProperties;
  chipIcon?: any;
};

export default function Chip({ label, onClick, style, chipIcon }: Props) {
  return (
    <div style={{ padding: '0.15rem' }}>
      <span className="chip" style={{ ...style }}>
        {label}
        <div className="chip-button" onClick={onClick}>
          {chipIcon ?? <CloseIcon size="15px" />}
        </div>
      </span>
    </div>
  );
}
