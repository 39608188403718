import { useQuery } from '@tanstack/react-query';
import { getCaseFileDocumentIDs } from '../../../api';

export function useGetDocumentIDs(
  caseID?: string,
  fileID?: string,
): {
  data: string[] | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { data, isLoading, isError } = useQuery(
    ['getCaseFileDocumentIDs', fileID],
    () => getCaseFileDocumentIDs(fileID as string, caseID as string),
    {
      enabled: !!fileID,
    },
  );

  return { data: data?.data, isLoading, isError };
}
