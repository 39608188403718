import { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Stack,
  Box,
  Button,
  Typography,
  CircularProgress,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
} from '@mui/material';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { useParams, useNavigate } from 'react-router-dom';
import CaseContext from '../Case/CaseContext';
import CaseHeader from '../Timeline/CaseHeader';
import AddFilesDialog from '../FileUpload/AddFilesDialog';
import Upload from '../../components/icons/Upload';
import '../../resources/styles/App.css';
import { deleteDocument, renameDocument } from '../Timeline/api';
import Loading from '../../components/common/Loading';
import Delete from '../../components/icons/Delete';
import Pencil from '../../components/icons/Pencil';
import ReportsIcon from '../../components/icons/ReportsIcon';
import DeleteDialog from '../../components/common/DeleteDialog';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import { useTimelineList } from '../Timeline/useTimeline';
import { FileStatus, checkFileStatus } from '../../api';
import { useActivityLog } from '../../components/ActivityTracker/ActivityTracker';
import DialogModal from '../../components/common/DialogModal';
import Theme from '../../theme';
import useDisplayStore from '../Timeline/useDisplayStore';
import { useSetCaseStatus } from '../MyCases/useCases';
import useCaseFiles, { useUpdateFileStatus } from './useCaseFiles';
import FilesTableNameCell from '../../components/FilesTab/FilesTableNameCell';
import CopyFilesDialog from './CopyFilesDialog';
import FeedbackPopup from '../../components/common/FeedbackPopup';
import { useGroupingLock } from '../DocumentGrouping/api-hooks/useGroupingLock';

function FilesTab() {
  const { caseInstance } = useContext(CaseContext);
  const logUserActivity = useActivityLog();
  const isFileProcessor = useIsFileProcessor();
  const [files, setFiles] = useState([]);
  const [copyingFile, setCopyingFile] = useState({ sourceFile: '', targetFile: '' });
  const [filesLoading, setFilesLoading] = useState(true);
  const { caseID } = useParams();
  const {
    isLoading: caseFilesLoading,
    data: caseFiles,
    refetch: refetchDocuments,
  } = useCaseFiles(caseID);

  const queryClient = useQueryClient();

  const { mutate: updateFileStatus } = useUpdateFileStatus();

  const windowSize = useDisplayStore((state) => state.windowSize);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedFileName, setEditedFileName] = useState('');

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const { data: timelineList } = useTimelineList(caseID);
  const timelineID = useMemo(
    () => timelineList?.find((timeline) => timeline.isDefault)?.id,
    [timelineList],
  );
  const { data: lockStatuses, insertGroupingLock } = useGroupingLock(caseID);

  const toggleEditFileName = (row) => {
    if (editMode && selectedFile?.id === row.id) {
      setSelectedFile('');
      setEditMode(false);
      setEditedFileName('');
    } else {
      setSelectedFile(row);
      setEditMode(true);
      setEditedFileName(row.name);
    }
  };

  const checkCopyingFileStatus = useCallback(
    async (fileId) => {
      const fileStatusResponse = await checkFileStatus({ caseID, fileId });
      return fileStatusResponse?.data[0]?.file_status;
    },
    [files, updateFileStatus],
  );

  const setNewFileStatus = (fileID, newStatus) => {
    const updatedFiles = caseFiles.map((file) => {
      if (file.documentID === fileID) {
        return {
          ...file,
          fileStatus: newStatus,
        };
      }
      return file;
    });
    queryClient.setQueryData(['files', caseID], updatedFiles);
  };

  useEffect(() => {
    let timeoutId;
    let retryCount = 0;

    const checkStatusAndScheduleNextCheck = async () => {
      const fileStatus = await checkCopyingFileStatus(copyingFile.sourceFile);
      let delay;
      switch (fileStatus) {
        case 'QA_REQUIRED':
          toast.success('Successfully copied file');
          setCopyingFile({ sourceFile: '', targetFile: '' });
          setNewFileStatus(copyingFile.targetFile, fileStatus);
          break;
        case 'COPYING':
          retryCount++;
          delay = 2 ** retryCount * 1000;
          timeoutId = setTimeout(checkStatusAndScheduleNextCheck, delay);
          break;
        default:
          break;
      }
    };

    if (copyingFile.sourceFile) {
      checkStatusAndScheduleNextCheck();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [copyingFile]);

  useEffect(() => {
    if (!isFileProcessor || !caseFiles) {
      return;
    }
    const documents = caseFiles;
    const authorNotReady = documents.some((doc) => doc.authorStatus !== 'READY');

    // set interval to refetch documents every 1 minute if author is not ready
    if (authorNotReady) {
      const interval = setInterval(() => {
        refetchDocuments({
          variables: {
            caseID: caseID,
          },
        });
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [isFileProcessor, caseFiles]);

  useEffect(() => {
    if (caseID) {
      logUserActivity({
        activity: 'case:files',
        case_id: caseID,
      });
    }
  }, [caseID]);

  const columns = useMemo(() => {
    const fileColumns = [
      ...(isFileProcessor
        ? [
            {
              field: 'sequence',
              headerName: '#',
              flex: 0.2,
              headerClassName: 'files-table-header-row',
              renderCell: (params) => (
                <Typography sx={{ fontSize: '12.8px' }}>{params.value}</Typography>
              ),
            },
          ]
        : []),
      {
        field: 'name',
        headerName: 'Name',
        flex: 2,
        headerClassName: 'files-table-header-row',
        renderCell: (params) => {
          const isEditing = editMode && selectedFile?.id === params.row.id;
          const foundLock = lockStatuses?.find((lock) => lock.fileID === params.row.id);
          return (
            <FilesTableNameCell
              isEditing={isEditing}
              editedFileName={editedFileName}
              setEditedFileName={setEditedFileName}
              handleRenameFile={handleRenameFile}
              fileStatus={params.row.fileStatus}
              isFileProcessor={isFileProcessor}
              fileName={params.row.name}
              fileID={params.row.id}
              timelineID={timelineID}
              handleFileStatusChange={handleFileStatusChange}
              selectedFile={selectedFile}
              groupingTabLock={foundLock}
              insertGroupingLock={insertGroupingLock}
            />
          );
        },
      },
      {
        field: 'type',
        headerName: 'File Type',
        flex: 0.75,
        sortable: false,
        headerClassName: 'files-table-header-row',
      },
      {
        field: 'uploadDate',
        headerName: isFileProcessor ? 'Time to SLA' : 'Upload Date',
        flex: 0.75,
        headerClassName: 'files-table-header-row',
      },
      {
        field: 'numOfPages',
        headerName: 'Pages',
        flex: 0.75,
        headerClassName: 'files-table-header-row',
      },
      {
        field: 'fileStatus',
        hide: true,
        headerName: 'Status',
        flex: 1,
        headerClassName: 'files-table-header-row',
        renderCell: (params) => {
          return (
            <FileStatusItem fileStatus={params.row.fileStatus} isFileProcessor={isFileProcessor} />
          );
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        headerAlign: 'center',
        align: 'right',
        sortable: false,
        flex: isFileProcessor ? 1.75 : 0.5,
        headerClassName: 'files-table-header-row',
        renderCell: (params) => (
          <FileActionsComponent
            toggleEditFileName={toggleEditFileName}
            authorStatus={params.row.authorStatus}
            isFileProcessor={isFileProcessor}
            fileStatus={params.row.fileStatus}
            setDeleteModalOpen={setDeleteModalOpen}
            handleFileStatusChange={handleFileStatusChange}
            setSelectedFile={setSelectedFile}
            setSourceDocument={setSourceDocument}
            canCopyFile={
              files.filter((file) => file.numOfPages === params.row.numOfPages).length > 1 &&
              (params.row.fileStatus === 'QA_REQUIRED' || params.row.fileStatus === 'APPROVED')
            }
            params={params}
          />
        ),
      },
    ];
    return fileColumns;
  }, [editMode, selectedFile, isFileProcessor, timelineID, files]);

  useEffect(() => {
    if (!caseFilesLoading && caseFiles) {
      setFilesLoading(true);
      let extractedFiles = caseFiles.map((file) => {
        const lastDotIndex = file.docFileName.lastIndexOf('.');

        const timeRemaining = (() => {
          const timeDifference =
            new Date(new Date(file.uploadDate).getTime() + 24 * 60 * 60 * 1000) - new Date();
          const hours = Math.floor(timeDifference / (1000 * 60 * 60));
          const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
          return `${hours}h ${minutes}m`;
        })();

        return {
          id: file.documentID,
          name: file.docFileName.substring(0, lastDotIndex),
          type: file.docFileName.substring(lastDotIndex + 1),
          uploadDate: isFileProcessor
            ? timeRemaining
            : new Date(file.uploadDate).toLocaleDateString('en-GB'),
          numOfPages: file.numberOfPages,
          fileStatus: file.isReady === 0 && isFileProcessor ? 'UPLOADING' : file.fileStatus,
          authorStatus: file.authorStatus,
          refID: file.refID,
          uploadTime: new Date(file.uploadDate).getTime(),
        };
      });
      // we want to hide completed files from the file processor so that
      // there is no change for completed work to be re-done accidentally
      extractedFiles.sort((a, b) => a.uploadTime - b.uploadTime || a.id - b.id);
      extractedFiles = extractedFiles.map((file, index) => ({ ...file, sequence: index + 1 }));

      if (isFileProcessor) {
        extractedFiles = extractedFiles.filter((file) => file.fileStatus !== FileStatus.COMPLETE);
      }
      setFiles(extractedFiles);
      setFilesLoading(false);
    }
  }, [caseFiles, caseFilesLoading]);

  const handleDeleteFile = () => {
    if (selectedFile) {
      setFiles(files.filter((file) => file.id !== selectedFile.id));
      deleteDocument(caseID, selectedFile.id)
        .then(() => {
          refetchDocuments({ caseID });
          toast.success('File deleted');
        })
        .catch((err) => {
          toast.error('Error deleting file');
          console.log(err);
        });
    }
    setDeleteModalOpen(false);
    setSelectedFile(null);
  };

  const handleRenameFile = (file, newFileNameInput) => {
    const newFileName = `${newFileNameInput}.${file.type}`;

    const prevFiles = [...files];
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.map((f) =>
        f.id === file.id ? { ...f, name: newFileNameInput } : f,
      );
      return updatedFiles;
    });
    renameDocument(caseID, file.id, newFileName)
      .then(() => {
        refetchDocuments({ caseID }).then(() => {
          toast.success('File renamed successfully');
          setEditMode(false);
          setSelectedFile(null);
        });
      })
      .catch((err) => {
        toast.error('Error renaming file');
        console.log(err);
        // Revert the state if the API call fails
        setFiles(prevFiles);
      });
  };

  const [sourceDocument, setSourceDocument] = useState(null);

  const handleFileStatusChange = async (id, currentStatus) => {
    const nextStatusByCurrentStatus = {
      [FileStatus.PENDING]: FileStatus.GROUPING,
      [FileStatus.GROUPING]: FileStatus.TAGGING,
      [FileStatus.TAGGING]: FileStatus.QA_REQUIRED,
      [FileStatus.QA_REQUIRED]: FileStatus.APPROVED,
    };

    if (!Object.keys(nextStatusByCurrentStatus).includes(currentStatus)) {
      return;
    }

    updateFileStatus({ caseID, fileID: id, status: nextStatusByCurrentStatus[currentStatus] });
  };

  const areAllFilesMarkedAsComplete = useMemo(() => {
    if (!caseFiles) {
      return false;
    }
    return caseFiles.every((document) => document.fileStatus === FileStatus.APPROVED);
  }, [caseFiles]);

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setSelectedFile(null);
  };

  const getNoRowsOverlay = () => {
    return <NoRowsOverlay isFileProcessor={isFileProcessor} />;
  };

  const isProcessing = caseInstance?.caseStatus === 'REQUIRES_PROCESSOR';
  const newFilesInPipeline = caseInstance?.caseStatus === 'PROCESSING';

  const { mutateAsync: setCaseStatus } = useSetCaseStatus();

  const navigate = useNavigate();

  const [checked, setChecked] = useState({
    dates: false,
    sources: false,
    content: false,
    grouping: false,
    duplicates: false,
  });

  const [disabled, setDisabled] = useState(false);
  const [readyDialogOpen, setReadyDialogOpen] = useState(false);

  const handleChange = (key) => {
    setChecked({
      ...checked,
      [key.id]: key.checked,
    });
  };

  const markAsReadyOnClick = () => {
    setReadyDialogOpen(true);
  };
  const handleMarkAsReady = async () => {
    try {
      await setCaseStatus({
        caseID,
        caseStatus: 'READY',
        sendReadyEmail: true,
      });
      toast.success('Case successfully marked as ready.', {
        toastId: 'caseStatusSuccess',
      });
      setReadyDialogOpen(false);
      navigate('/cases');
    } catch (e) {
      // error handling is done in the setCaseStatus function
    }
  };

  useEffect(() => {
    if (
      Object.values(checked).filter((check) => check !== true).length === 0 &&
      areAllFilesMarkedAsComplete
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [checked]);

  useEffect(() => {
    if (!areAllFilesMarkedAsComplete) {
      setDisabled(true);
    }
  }, [areAllFilesMarkedAsComplete]);

  let processorFileButtonText;
  if (isProcessing) {
    processorFileButtonText = 'Mark Case As Ready';
  } else if (newFilesInPipeline) {
    processorFileButtonText = 'New Files in Pipeline';
  } else {
    processorFileButtonText = 'Processing Complete';
  }

  return (
    <div style={{ backgroundColor: 'white' }}>
      <CaseHeader caseInstance={caseInstance} timelineID={timelineID} />
      <Box
        sx={{
          width: '100%',
          minHeight: 'calc(100vh - 64px)',
          borderRadius: 0,
          justifyContent: 'center',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          backgroundColor: 'white',
          paddingBottom: '2rem',
        }}
      >
        <Stack
          direction="row"
          spacing={4}
          sx={{
            paddingTop: '1.2rem',
            paddingBottom: '1.2rem',
            width: '100%',
          }}
        >
          <Typography fontSize="1.2rem" fontWeight={600} sx={{ display: 'inline' }}>
            Files
          </Typography>
          <CopyFilesDialog
            caseID={caseID}
            files={files || []}
            setCopyingFile={setCopyingFile}
            sourceDocument={sourceDocument}
            onClose={() => setSourceDocument(null)}
          />
          {isFileProcessor ? (
            <Button
              color="newSuccess"
              variant="contained"
              disabled={!isProcessing || newFilesInPipeline}
              onClick={markAsReadyOnClick}
              sx={{
                marginLeft: '1.7rem',
                fontWeight: 700,
                fontSize: '0.8rem',
              }}
            >
              {processorFileButtonText}
            </Button>
          ) : (
            <FileUploadMenuItem />
          )}
        </Stack>
        {caseFilesLoading || filesLoading ? (
          <Loading />
        ) : (
          <Box
            sx={{
              width: '100%',
              maxHeight: 'calc(100% - 200px)',
              '&::-webkit-scrollbar': {
                width: '1em',
              },
              '&::-webkit-scrollbar-thumb': {
                padding: '0 4px',
                border: '4px solid transparent',
                backgroundClip: 'padding-box',
                borderRadius: '100px',
              },
            }}
          >
            <DataGrid
              columns={columns}
              rows={files || []}
              autoHeight
              disableColumnMenu
              disableRowSelectionOnClick
              pageSize={pageSize}
              pagination
              paginationMode="client"
              rowCount={files?.length || 0}
              page={page}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              pageSizeOptions={[5, 20, 50, 100]}
              sx={{
                width: windowSize.width ? `${windowSize.width - 150}px` : '100%',
                border: '1px solid #00214733',
                borderRadius: '8px',
                fontSize: '0.8rem',
                boxShadow: 'none',
                minHeight: files?.length > 0 ? '0px' : '400px',
                '& .MuiDataGrid-cell:focus-within': {
                  outline: ' none',
                },
              }}
              components={{
                NoRowsOverlay: getNoRowsOverlay,
              }}
            />
            <DeleteDialog
              open={deleteModalOpen}
              title="Delete File"
              contentText="Are you sure you want to delete this file?"
              onClose={handleCloseDeleteModal}
              onConfirm={handleDeleteFile}
            />
          </Box>
        )}
      </Box>
      <DeleteDialog
        open={readyDialogOpen}
        onClose={() => {
          setChecked({
            dates: false,
            sources: false,
            content: false,
            grouping: false,
            duplicates: false,
          });
          setReadyDialogOpen(false);
        }}
        onConfirm={handleMarkAsReady}
        title="Mark Case As Ready"
        disabledTooltip={
          !areAllFilesMarkedAsComplete
            ? 'Please mark all files as complete in the Files tab before marking the case as ready.'
            : 'Please confirm all QA steps as complete before marking the case as ready.'
        }
        cancelText="Cancel"
        confirmText="Confirm as Ready"
        confirmDisabled={disabled}
        sx={{
          backgroundColor: Theme.palette.secondary.main,
          color: '#000000',
          borderColor: Theme.palette.secondary.main,
        }}
        contentText="After marking the case as ready, you will no longer be able to edit the case and it will be sent to the client for review."
        footerComponent={
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="confirm"
                  onChange={(e) => {
                    setDisabled(!e.target.checked);
                  }}
                  sx={{ color: 'red' }}
                />
              }
              label="Manually override file status lock."
            />
          </FormGroup>
        }
        content={
          <FormGroup>
            <FormLabel>Please confirm that all of the following are complete:</FormLabel>
            <FormControlLabel
              required
              control={<Checkbox id="dates" onChange={(e) => handleChange(e.target)} />}
              label="All dates have been checked"
            />
            <FormControlLabel
              required
              control={<Checkbox id="sources" onChange={(e) => handleChange(e.target)} />}
              label="All sources have been checked"
            />
            <FormControlLabel
              required
              control={<Checkbox id="content" onChange={(e) => handleChange(e.target)} />}
              label="All content labels have been checked"
            />
            <FormControlLabel
              required
              control={<Checkbox id="grouping" onChange={(e) => handleChange(e.target)} />}
              label="All grouping has been checked"
            />
            <FormControlLabel
              required
              control={<Checkbox id="duplicates" onChange={(e) => handleChange(e.target)} />}
              label="All duplicates have been resolved"
            />
          </FormGroup>
        }
      />
      <FeedbackPopup
        text={
          <div>
            <div>Copying File...</div>
            <div style={{ fontSize: '12px' }}>Please do not leave this page</div>
          </div>
        }
        severity="info"
        icon={<CircularProgress size={24} sx={{ color: 'white', margin: 'auto 15px auto 0px' }} />}
        verticalLocation="bottom"
        horizontalLocation="center"
        open={copyingFile.sourceFile}
      />
    </div>
  );
}

export default FilesTab;

function FileUploadMenuItem() {
  const { caseID } = useParams();
  const isFileProcessor = useIsFileProcessor();
  const { caseInstance } = useContext(CaseContext);
  const isCaseReady =
    caseInstance?.caseStatus === 'READY' || caseInstance?.caseStatus === 'REQUIRES_PROCESSOR';

  const uploadedDocuments = caseInstance?.caseStatus !== 'NEW';
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    if (!uploadedDocuments) {
      setModalOpen(true);
    }
  }, [uploadedDocuments]);

  return (
    <>
      <Tooltip
        title="Adding files to a case is disabled until uploading is complete."
        disableHoverListener={isCaseReady || isFileProcessor || !uploadedDocuments}
      >
        <span>
          <Button
            disabled={!isCaseReady && uploadedDocuments}
            variant="contained"
            classes="primary"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <Upload sx={{ fontSize: '1rem', marginRight: '0.5rem' }} />
            Add Files
          </Button>
        </span>
      </Tooltip>
      <AddFilesDialog open={modalOpen} setOpen={setModalOpen} caseID={caseID} />
    </>
  );
}

function NoRowsOverlay({ isFileProcessor }) {
  const noRowText = isFileProcessor
    ? 'There are no files that require processing.'
    : 'To upload a new file, click the "Add Files" button above.';
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '5rem',
        marginBottom: '5rem',
        width: '100%',
      }}
    >
      <ReportsIcon />
      <Typography sx={{ fontWeight: 600, fontSize: '0.9rem' }}>No Files Found</Typography>
      <Typography
        sx={{
          fontWeight: 600,
          opacity: '50%',
          fontSize: '0.8rem',
        }}
      >
        {noRowText}
      </Typography>
    </Box>
  );
}

function FileStatusItem({ fileStatus, isFileProcessor }) {
  const fileStatusLabelsByValue = {
    GROUPING: isFileProcessor ? 'Grouping' : 'Processing',
    TAGGING: isFileProcessor ? 'Tagging' : 'Processing',
    QA_REQUIRED: isFileProcessor ? 'QA Required' : 'Processing',
    APPROVED: isFileProcessor ? 'Approved' : 'Processing',
    COMPLETE: isFileProcessor ? 'Complete' : 'Processed',
    PENDING: isFileProcessor ? 'Not Started' : 'Processing',
    ERROR: 'Error',
    CLOSED: 'Closed',
    UPLOADING: 'Uploading',
  };
  const colourByStatus = {
    GROUPING: isFileProcessor ? 'status.warning' : 'themeOrange.light',
    TAGGING: isFileProcessor ? 'status.warning' : 'themeOrange.light',
    QA_REQUIRED: isFileProcessor ? 'status.success' : 'themeOrange.light',
    APPROVED: isFileProcessor ? 'status.success' : 'themeOrange.light',
    COMPLETE: 'status.success',
    PENDING: isFileProcessor ? 'status.error' : 'themeOrange.light',
    ERROR: 'status.error',
    CLOSED: 'lightgrey',
    UPLOADING: 'themeOrange.main',
  };

  return (
    <Typography sx={{ color: colourByStatus[fileStatus], fontSize: '0.8rem' }}>
      {fileStatusLabelsByValue[fileStatus]}
    </Typography>
  );
}

function FileActionsComponent({
  isFileProcessor,
  fileStatus,
  authorStatus,
  params,
  handleFileStatusChange,
  toggleEditFileName,
  setDeleteModalOpen,
  setSelectedFile,
  setSourceDocument,
  canCopyFile,
}) {
  if (!isFileProcessor) {
    return (
      <Stack direction="row" spacing={1}>
        <Pencil
          cursor="pointer"
          width={19}
          height={19}
          color={Theme.palette.text.color}
          onClick={() => toggleEditFileName(params.row)}
          sx={{ opacity: 0, transition: 'opacity 0.2s ease-in-out' }}
        />
        {/* {fileStatus === 'COMPLETE' && (
          <Delete
            cursor="pointer"
            width={19}
            height={19}
            onClick={() => {
              setSelectedFile(params.row);
              setDeleteModalOpen(true);
            }}
          />
        )} */}
      </Stack>
    );
  }

  const onButtonClick = () => {
    try {
      handleFileStatusChange(params.row.id, fileStatus);
    } catch (e) {
      toast.error('Error updating file status.');
    }
  };

  return (
    <Stack direction="row" spacing={2}>
      {authorStatus !== 'READY' && (
        <Tooltip title="Author extraction step is not complete">
          <Box position="relative" display="inline-flex">
            <PersonOffIcon style={{ color: 'orange', fontSize: '28px' }} />
            {/* Adjust the fontSize as needed */}
            <Box
              position="absolute"
              bottom={8}
              right={0}
              sx={{
                width: '12px', // Size of the CircularProgress
                height: '12px', // Size of the CircularProgress
              }}
            >
              <CircularProgress size={12} sx={{ color: 'primary.main' }} />
            </Box>
          </Box>
        </Tooltip>
      )}
      {canCopyFile && (
        <Button
          variant="contained"
          onClick={() => setSourceDocument(params.row)}
          sx={{
            fontWeight: 700,
            fontSize: '0.8rem',
          }}
        >
          Copy
        </Button>
      )}
      <FileProcessorNextStepButton
        fileStatus={fileStatus}
        id={params.row.id}
        onClick={onButtonClick}
      />
    </Stack>
  );
}

export function FileProcessorNextStepButton({ fileStatus, onClick, sx }) {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const buttonTextByStatus = {
    GROUPING: 'Set Grouping Done',
    TAGGING: 'Set Tagging Done',
    QA_REQUIRED: 'Approve',
  };

  if (
    fileStatus !== FileStatus.GROUPING &&
    fileStatus !== FileStatus.TAGGING &&
    fileStatus !== FileStatus.QA_REQUIRED
  ) {
    return null;
  }

  const onClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const onConfirm = () => {
    setIsConfirmationDialogOpen(false);
    onClick();
  };

  return (
    <>
      <StepConfirmationDialog
        open={isConfirmationDialogOpen}
        onClose={onClose}
        onConfirm={onConfirm}
        fileStatus={fileStatus}
      />
      <Button
        disabled={
          fileStatus !== FileStatus.QA_REQUIRED &&
          fileStatus !== FileStatus.GROUPING &&
          fileStatus !== FileStatus.TAGGING
        }
        onClick={() => setIsConfirmationDialogOpen(true)}
        variant="outlined"
        sx={{
          minWidth: '120px',
          fontSize: '0.8rem',
          fontWeight: 400,
          backgroundColor: 'caseStatus.processorActionButtonBackground',
          color: 'caseStatus.processorActionButtonText',
          ...sx,
        }}
      >
        {buttonTextByStatus[fileStatus]}
      </Button>
    </>
  );
}

function StepConfirmationDialog({ open, onClose, onConfirm, fileStatus }) {
  const dialogTextOptions = {
    [FileStatus.GROUPING]: {
      title: 'Mark as Ready for Tagging',
      text: 'Are you sure you want to mark this file as ready for tagging? This action cannot be undone.',
    },
    [FileStatus.TAGGING]: {
      title: 'Mark as Ready for QA',
      text: 'Are you sure you want to mark this file as ready for QA to review? This action cannot be undone.',
    },
    [FileStatus.QA_REQUIRED]: {
      title: 'Mark as Approved',
      text: 'Are you sure you want to mark this file as approved? This action cannot be undone.',
    },
  };

  return (
    <DialogModal
      open={open}
      header={dialogTextOptions[fileStatus]?.title}
      content={dialogTextOptions[fileStatus]?.text}
      onClose={onClose}
      onConfirm={onConfirm}
      actions={
        <Button variant="contained" onClick={onConfirm}>
          Confirm
        </Button>
      }
    />
  );
}
