import Box from '@mui/material/Box';
import NewSearchbar from '../../../components/Searchbar/NewSearchBar';
import ConfigurableViewFilter from '../Components/ConfigurableViewFilter';
import { ListPagesSortByEnum } from '../types/timelineTypes';

export default function SearchAndFilterBar({
  searchStr,
  debounceInputHandler,
  searchResultCounts,
  searchingInProgress,
  handleFiltersChange,
  isFileProcessor,
}: SearchAndFilterBarProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        padding: '.5rem',
        paddingBottom: '1rem',
      }}
    >
      <NewSearchbar
        label="Search Timeline"
        totalSearchOccurances={searchResultCounts?.totalCount}
        searchingInProgress={searchStr.length > 0 && searchingInProgress}
        searchStr={debounceInputHandler.value}
        handleChange={debounceInputHandler.onChange}
      />
      {!isFileProcessor && (
        <ConfigurableViewFilter
          onSubmit={handleFiltersChange}
          sortingOptions={[
            {
              value: ListPagesSortByEnum.DateAscending,
              label: 'Date Ascending',
            },
            {
              value: ListPagesSortByEnum.DateDescending,
              label: 'Date Descending',
            },
            { value: ListPagesSortByEnum.Document, label: 'Document' },
          ]}
          defaultSortOrder={ListPagesSortByEnum.DateAscending}
        />
      )}
    </Box>
  );
}
type SearchAndFilterBarProps = {
  searchStr: string;
  debounceInputHandler: {
    value: string;
    onChange: (value: string) => void;
  };
  searchResultCounts: {
    totalCount?: number;
  };
  searchingInProgress: boolean;
  handleFiltersChange: (filters: any) => void;
  isFileProcessor: boolean;
};
