const colors = {
  green: '#28A978',
  pink: '#FF62B7',
  purple: '#925FB0',
  blue: '#234E9E',
  orange: '#FF8F28',
  red: '#F05353',
  yellow: '#EDAC4B',
};

export default colors;
