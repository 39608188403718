import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import SmallPageCard from './SmallPageCard';
import config from '../../../config';
import useTimelineStore from '../useTimelineStore';

export const maxPagesBeforeScroll = 25;
export const PAGE_LIST_ITEM_HEIGHT = 25;

const getPageItemSize = () => 25;

function PageListView(props) {
  const {
    pages,
    count,
    searchMatches,
    currentPageID,
    isCurrentlyFocused,
    isInTimelineView = false,
    onLoadMore,
    pageControls,
    notes,
    currentDocumentID,
    isFileProcessor,
    sources,
  } = props;

  const listOuterRef = useRef(null);
  const pageIndex = useMemo(() => {
    if (currentPageID) {
      return pages?.findIndex((page) => page?.id === currentPageID) ?? -1;
    }
    return -1;
  }, [pages, currentPageID]);

  const listHeight = useMemo(
    () =>
      Math.min(pages.length * PAGE_LIST_ITEM_HEIGHT, maxPagesBeforeScroll * PAGE_LIST_ITEM_HEIGHT),
    [pages],
  );
  const scrollOffset = useRef(
    isCurrentlyFocused && count > maxPagesBeforeScroll ? pageIndex * getPageItemSize() : 0,
  );

  useEffect(() => {
    if (!listOuterRef.current || !isCurrentlyFocused || count <= maxPagesBeforeScroll) {
      return;
    }
    const scrollPosition = pageIndex * getPageItemSize();
    scrollOffset.current = scrollPosition;
    listOuterRef.current.scrollTo({
      top: scrollPosition,
      behavior: 'smooth',
    });
  }, [pageIndex, currentPageID]);

  const handleScroll = useCallback((scroll) => {
    scrollOffset.current = scroll.scrollOffset;
  }, []);

  const isPageLoaded = useCallback((index) => Boolean(pages?.[index]), [pages]);

  const listItemData = useMemo(
    () => ({
      pages,
      isInTimelineView,
      searchMatches,
      currentPageID,
      pageControls,
      notes,
      currentDocumentID,
      isFileProcessor,
      sources,
    }),
    [
      pages,
      isInTimelineView,
      searchMatches,
      currentPageID,
      notes,
      currentDocumentID,
      pageControls,
      isFileProcessor,
      sources,
    ],
  );
  const listKey = useMemo(() => JSON.stringify(listItemData), [listItemData]);
  return (
    <InfiniteLoader
      isItemLoaded={isPageLoaded}
      loadMoreItems={onLoadMore}
      itemCount={count}
      minimumBatchSize={config.documents.gql.defaultTake}
      threshold={20}
    >
      {({ onItemsRendered, ref }) => (
        <List
          key={listKey}
          className="List"
          height={listHeight}
          itemCount={count}
          itemSize={getPageItemSize}
          initialScrollOffset={scrollOffset.current}
          onScroll={handleScroll}
          itemData={listItemData}
          outerRef={listOuterRef}
          ref={ref}
          onItemsRendered={onItemsRendered}
          currentDocumentID={currentDocumentID}
        >
          {ListPage}
        </List>
      )}
    </InfiniteLoader>
  );
}

export default memo(PageListView);

const ListPage = memo(
  ({
    index,
    style,
    data: {
      pages,
      isInTimelineView,
      searchMatches,
      currentPageID,
      pageControls,
      notes,
      currentDocumentID,
      isFileProcessor,
      sources,
    },
  }) => {
    const page = pages[index];
    const isCurrentPage = useMemo(() => page?.id === Number(currentPageID), [page, currentPageID]);
    const notesInPage = useMemo(
      () =>
        notes?.filter(
          (note) => note.pageNumber === page?.pageNumber && note.documentID === page?.documentID,
        ) ?? [],
      [],
    );
    const isPageSelected = useTimelineStore((state) => state.isPageSelected(page.id));
    const areMaximumPagesForSplitSelected = useTimelineStore(
      (state) => pages && state.areMaximumPagesForSplitSelected(pages),
    );
    if (!page) {
      return null;
    }

    return (
      <SmallPageCard
        key={page.id}
        index={index}
        customStyle={style}
        isInTimelineView={isInTimelineView}
        page={page}
        pages={pages}
        searchMatches={searchMatches?.[page.pageNumber.toString()]}
        pageControls={pageControls}
        isCurrentPage={isCurrentPage}
        noteCount={notesInPage.length}
        currentDocumentID={currentDocumentID}
        isFileProcessor={isFileProcessor}
        sources={sources}
        isPageSelected={isPageSelected}
        areMaximumPagesForSplitSelected={areMaximumPagesForSplitSelected}
      />
    );
  },
);
