import { Drawer, Box, Backdrop } from '@mui/material';
import {
  DocumentEditorContainerComponent,
  SfdtExport,
  Toolbar,
  WordExport,
} from '@syncfusion/ej2-react-documenteditor';
import { ArrowForwardIos } from '@mui/icons-material';
import {
  PdfBitmap,
  PdfDocument,
  PdfPageOrientation,
  PdfPageSettings,
  SizeF,
} from '@syncfusion/ej2-pdf-export';
import { useContext, useMemo, useRef, useState, useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { useParams } from 'react-router-dom';
import Loading from '../../components/common/Loading';
import DocumentEditor from '../../components/DocumentEditor';
import useReports from './useReports';
import NotesView from '../Timeline/NotesView';
import { AppContext } from '../../library/contexts/AppContext';
import InsertTemplate from './InsertTemplate';
import useReportsStore from './useReportsStore';
import CaseContext from '../Case/CaseContext';
import ReportsTable from './ReportsTable';
import CaseHeader from '../Timeline/CaseHeader';
import useNotesStore from '../Notes/useNotesStore';
import LinearProgressBarWithValueLabel from '../../components/common/LabelledLinearProgressBar';
import IndexReportTable from './IndexReports/IndexReportTable';
import useTimelineReports from './gql/useTimelineReports';
import { useUser } from '../../library/contexts/AuthContext';
import { useTimelineList } from '../Timeline/useTimeline';
import { useActivityLog } from '../../components/ActivityTracker/ActivityTracker';
import ReportsLock from './ReportsLock';

DocumentEditorContainerComponent.Inject(Toolbar, SfdtExport, WordExport);

function ReportEditor() {
  const { setCurrentReport, currentReport } = useReportsStore(
    (state) => ({
      setCurrentReport: state.setCurrentReport,
      currentReport: state.currentReport,
    }),
    shallow,
  );

  const logUserActivity = useActivityLog();
  const { caseInstance } = useContext(CaseContext);
  const { templatePopupOpen, setTemplatePopupOpen } = useContext(AppContext);
  const { caseID } = useParams();
  const { user } = useUser();
  const editorcontainer = useRef(null);
  const [loading, setLoading] = useState(true);
  const { data: reports } = useReports(caseID);

  const { notesSidebarOpen, setNotesSidebarOpen } = useContext(AppContext);
  const notesInsertLoading = useNotesStore((state) => state.notesInsertLoading);
  const totalNotesCount = useNotesStore((state) => state.totalNotesToInsert);

  const { data: timelineReports } = useTimelineReports({
    case_id: caseID,
  });
  const { data: timelineList } = useTimelineList(caseID);
  const timelineID = timelineList?.find((timeline) => timeline.isDefault).id;

  const uploadObject = useMemo(
    () => ({
      fileName: currentReport?.reportName,
      caseName: caseID,
      caseID: caseID,
      id: currentReport?.id,
    }),
    [currentReport, caseID, user.username],
  );

  useEffect(() => {
    setLoading(true);
  }, [currentReport]);

  useEffect(() => {
    if (
      !reports?.find((report) => report.id === currentReport?.id) &&
      !timelineReports?.find((report) => report.id === currentReport?.id)
    ) {
      setCurrentReport(null);
    }
  }, []);

  useEffect(() => {
    if (caseID && currentReport?.id && currentReport?.reportType === 'WRITTEN_REPORT') {
      logUserActivity({
        activity: 'case:report',
        case_id: caseID,
        report_id: currentReport?.id,
      });
    }
  }, [caseID, currentReport?.id]);

  async function exportPDF() {
    const editor = editorcontainer.current.documentEditor;
    const count = editor.pageCount;
    const pdfdocument = new PdfDocument();
    editor.documentEditorSettings.printDevicePixelRatio = 2;
    let loadedPage = 0;

    function exportPdf(page, pageCount) {
      const format = 'image/jpeg';
      // Getting pages as image
      const image = editor.exportAsImage(page, format);
      image.onload = function image_onload() {
        const imageHeight = parseInt(image.style.height.toString().replace('px', ''), 10);
        const imageWidth = parseInt(image.style.width.toString().replace('px', ''), 10);
        const section = pdfdocument.sections.add();
        const settings = new PdfPageSettings(0);
        if (imageWidth > imageHeight) {
          settings.orientation = PdfPageOrientation.Landscape;
        }
        settings.size = new SizeF(imageWidth, imageHeight);
        section.setPageSettings(settings);
        const page = section.pages.add();
        const { graphics } = page;
        const imageStr = image.src.replace('data:image/jpeg;base64,', '');
        const pdfImage = new PdfBitmap(imageStr);
        graphics.drawImage(pdfImage, 0, 0, imageWidth, imageHeight);
        loadedPage++;
        // Exporting the document as pdf
        if (loadedPage === pageCount) {
          pdfdocument.save(`${currentReport?.reportName}.pdf`);
        }
      };
    }

    for (let i = 1; i <= count; i++) {
      setTimeout(exportPdf(i, count), 500);
    }
  }

  async function exportWord() {
    editorcontainer.current.documentEditor.enableWordExport = true;
    editorcontainer.current.documentEditor.save(currentReport?.reportName, 'Docx');
  }

  const toolbarClick = (args) => {
    switch (args.item.id) {
      case '_exportPDF':
        exportPDF();
        break;
      case '_exportDocx':
        exportWord();
        break;
      case '_note':
        window.localStorage.setItem('notesSidebarOpen', !notesSidebarOpen);
        setNotesSidebarOpen(!notesSidebarOpen);
        break;
      case '_template':
        window.localStorage.setItem('templatePopupOpen', !templatePopupOpen);
        setTemplatePopupOpen(!templatePopupOpen);
        break;
      default:
        break;
    }
  };

  const shouldShowBackdrop = notesInsertLoading && !(totalNotesCount === 0);

  return (
    <>
      <CaseHeader caseInstance={caseInstance} timelineID={timelineID} />
      <Backdrop
        open={shouldShowBackdrop}
        style={{
          zIndex: '100000000',
          position: 'absolute',
          opacity: 0.99,
          backdropFilter: 'blur(4px)',
        }}
      >
        <NoteInsertionProgressBar />
      </Backdrop>
      <Box
        anchor="right"
        variant="persistent"
        id="scrollable-report-container"
        sx={{
          width: '100%',
          alignItems: 'end',
          borderRadius: 0,
          backgroundColor: 'white',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        {currentReport ? (
          <>
            <CurrentReportNavBar
              notesSidebarOpen={
                currentReport?.reportType === 'WRITTEN_REPORT' ? notesSidebarOpen : false
              }
              setCurrentReport={setCurrentReport}
              currentReport={currentReport}
            />
            {currentReport?.reportType === 'TIMELINE_REPORT' ? (
              <IndexReportTable currentReport={currentReport} />
            ) : (
              <ReportsLock
                caseID={caseID}
                reportID={currentReport.id}
                setCurrentReport={setCurrentReport}
              >
                <WrittenReportView
                  editorcontainer={editorcontainer}
                  loading={loading}
                  setLoading={setLoading}
                  uploadObject={uploadObject}
                  toolbarClick={toolbarClick}
                  currentReport={currentReport}
                  notesSidebarOpen={notesSidebarOpen}
                />
              </ReportsLock>
            )}
          </>
        ) : (
          <ReportsTable />
        )}
      </Box>
    </>
  );
}

export default ReportEditor;

function NoteInsertionProgressBar() {
  const totalNotesCount = useNotesStore((state) => state.totalNotesToInsert);
  const notesInsertedCount = useNotesStore((state) => state.currentlyInsertedNotes);

  return (
    <LinearProgressBarWithValueLabel
      value={(notesInsertedCount / totalNotesCount) * 100}
      label={`${notesInsertedCount}/${totalNotesCount}`}
      progressBarStyleProps={{
        color: 'secondary',
      }}
      labelStyleProps={{
        color: 'text.secondary',
        fontWeight: 900,
      }}
    />
  );
}

function CurrentReportNavBar({ notesSidebarOpen, setCurrentReport, currentReport }) {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '40px',
        width: '97.5%',
        padding: '0.5rem',
        paddingLeft: notesSidebarOpen ? '311px' : '0',
      }}
    >
      <Box
        onClick={() => setCurrentReport(null)}
        sx={{
          fontSize: '0.9rem',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        Reports
      </Box>
      <ArrowForwardIos
        sx={{
          fontSize: '0.9rem',
          opacity: '40%',
          marginTop: '0.2rem',
          marginLeft: '1rem',
          marginRight: '1rem',
        }}
      />
      <Box
        sx={{
          color: 'primary.light',
          fontSize: '0.9rem',
          fontWeight: 600,
        }}
      >
        {currentReport.reportName}
      </Box>
    </Box>
  );
}

function WrittenReportView({
  editorcontainer,
  loading,
  setLoading,
  uploadObject,
  toolbarClick,
  currentReport,
  notesSidebarOpen,
}) {
  return (
    <div
      style={{
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      {loading && (
        <Loading text="Loading the word processor..." style={{ marginTop: '4rem!important' }} />
      )}
      <div
        id="notes-drawer-container"
        style={{
          display: 'flex',
          flex: 1,
          width: '100%',
          height: 'calc(100vh - 40px)',
        }}
      >
        <Drawer
          anchor="left"
          variant="persistent"
          BackdropProps={{ style: { position: 'absolute' } }}
          ModalProps={{
            container: document.getElementById('app-container'),
            style: { position: 'absolute' },
          }}
          PaperProps={{
            style: {
              width: 300 + 1,
              alignItems: 'end',
              height: '100%',
              position: 'absolute',
              overflow: 'hidden',
            },
          }}
          open={notesSidebarOpen}
        >
          <div
            id="notes-report-view"
            style={{
              width: '100%',
              height: '100%',
              overflowY: 'hidden',
            }}
          >
            <NotesView showInsertIntoReportIcon={true} />
          </div>
        </Drawer>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <DocumentEditor
            ref={editorcontainer}
            loading={loading}
            setLoading={setLoading}
            uploadObject={uploadObject}
            isTemplate={false}
            toolbarClick={toolbarClick}
            reportOpen={currentReport && true}
            noteSidebarOpen={notesSidebarOpen}
          />
        </div>
        <InsertTemplate editorcontainer={editorcontainer} />
      </div>
    </div>
  );
}
