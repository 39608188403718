import { Divider, Box, Tooltip, CircularProgress, IconButton } from '@mui/material';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import { DocumentSimilarityRecord } from '../../api';
import Theme from '../../theme';
import NavigationButton from '../common/PdfNavigation/Components/NavigationButton';
import { useDuplicatesByDocument } from '../../containers/DuplicatesByDocument/DuplicatesByDocument';
import { DuplicatesListChip } from './DuplicatesListChip';
import Loading from '../common/Loading';
import IconRefresh from '../icons/IconRefresh';

type DuplicatesListProps = {
  documentSimilaritiesList: DocumentSimilarityRecord[];
  navigateTo: (original: string, status: string) => void;
  currentSimilarity: DocumentSimilarityRecord | null;
  showResolved: () => void;
  showUnresolved: () => void;
  count: number | null;
  currentPage: number;
  nextPage: () => void;
  previousPage: () => void;
  hideResolved: boolean;
  createAllDocumentSimilarities: () => void;
  isRequestInProgress: boolean;
  duplicatesProcessing: boolean;
  isFileProcessor: boolean;
};

export default function DuplicatesList({
  documentSimilaritiesList,
  navigateTo,
  currentSimilarity,
  count,
  nextPage,
  previousPage,
  currentPage,
  showResolved,
  showUnresolved,
  hideResolved,
  createAllDocumentSimilarities,
  isRequestInProgress,
  duplicatesProcessing,
  isFileProcessor,
}: DuplicatesListProps) {
  const { pageSize } = useDuplicatesByDocument();

  return (
    <div style={{ backgroundColor: Theme.palette.selectedGrey.main, height: '100%' }}>
      <div
        style={{
          display: 'inline-block',
          fontWeight: 600,
          fontSize: '20px',
          padding: '5px',
          paddingLeft: '10px',
        }}
      >
        Duplicates
        {duplicatesProcessing && isFileProcessor && (
          <Tooltip title="Duplicate identification in progress">
            <CircularProgress size={14} sx={{ mx: 1 }} />
          </Tooltip>
        )}
      </div>
      {isFileProcessor && (
        <Tooltip
          title={!isRequestInProgress ? 'Generate all available document similarities.' : ''}
        >
          <span>
            <IconButton
              sx={{
                ml: '3rem',
                ...(isRequestInProgress && {
                  opacity: 0.25,
                }),
              }}
              disabled={isRequestInProgress}
              onClick={createAllDocumentSimilarities}
            >
              <IconRefresh />
            </IconButton>
          </span>
        </Tooltip>
      )}
      <Divider />
      <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        {isFileProcessor && (
          <div style={{ display: 'flex', flexDirection: 'row', padding: '5px', height: '5%' }}>
            <Box
              onClick={showUnresolved}
              style={{
                cursor: 'pointer',
                width: hideResolved ? '65%' : '35%',
                borderBottom: '3px solid',
                borderColor: hideResolved
                  ? Theme.palette.duplicatesByDocumentBlue.main
                  : Theme.palette.selectedGrey.main,
                fontSize: hideResolved ? '14px' : '13px',
                fontWeight: hideResolved ? 'bold' : '',
                color: hideResolved ? Theme.palette.duplicatesByDocumentBlue.main : '',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                textAlign: 'center',
              }}
            >
              <span
                style={{
                  flex: 1,
                  minWidth: 0,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                Unresolved {hideResolved && count !== null && `(${count})`}
              </span>
            </Box>

            <Box
              onClick={showResolved}
              style={{
                cursor: 'pointer',
                width: !hideResolved ? '65%' : '35%',
                borderBottom: '3px solid',
                borderColor: !hideResolved
                  ? Theme.palette.duplicatesByDocumentBlue.main
                  : Theme.palette.selectedGrey.main,
                fontSize: !hideResolved ? '14px' : '13px',
                fontWeight: !hideResolved ? 'bold' : '',
                color: !hideResolved ? Theme.palette.duplicatesByDocumentBlue.main : '',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                textAlign: 'center',
                marginLeft: '2px',
              }}
            >
              <span
                style={{
                  flex: 1,
                  minWidth: 0,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                Resolved {!hideResolved && count !== null && `(${count})`}
              </span>
            </Box>
          </div>
        )}
        {count !== null ? (
          <div
            id="scrollable"
            style={{
              padding: '10px',
              paddingRight: '5px',
              paddingLeft: '5px',
              display: 'flex',
              flexDirection: 'column',
              height: '75%',
              overflowY: 'auto',
            }}
          >
            {documentSimilaritiesList
              .filter((similarity) => isFileProcessor || similarity.status !== 'NotDuplicate')
              .map((similarity) => (
                <DuplicatesListChip
                  selectedSimilarity={currentSimilarity}
                  similarity={similarity}
                  navigateTo={navigateTo}
                />
              ))}
          </div>
        ) : (
          <div style={{ height: '100%' }}>
            <Loading customStyles={{ marginTop: '90%' }} />
          </div>
        )}
        <div
          style={{
            height: '20%',
            backgroundColor: Theme.palette.selectedGrey.main,
            width: '100%',
            display: 'inline-block',
            textAlign: 'center',
            paddingTop: isFileProcessor ? '6px' : '10%',
          }}
        >
          {count !== null && (
            <>
              <NavigationButton
                onClick={previousPage}
                icon={<KeyboardArrowLeft sx={{ fontSize: '1rem' }} />}
                disabled={currentPage === 1}
                sx={{ marginLeft: '.5rem', marginRight: '.5rem' }}
              />
              {`${currentPage ?? ''} / ${
                count === 0 ? '1' : Math.ceil((count ?? 0) / pageSize) ?? ''
              }`}
              <NavigationButton
                onClick={nextPage}
                icon={<KeyboardArrowRight sx={{ fontSize: '1rem' }} />}
                disabled={currentPage - 1 === Math.floor((count ?? 0) / pageSize)}
                sx={{ marginLeft: '.5rem', marginRight: '1rem' }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
