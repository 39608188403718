import { useCallback } from 'react';
import {
  CreateCustomTagMutationVariables,
  CustomTagFragmentFragmentDoc,
  useCreateCustomTagMutation,
} from '../../../__generated__/graphql';

const useCreateCustomTag = () => {
  const [createCustomTagMutation, mutationStateInfo] = useCreateCustomTagMutation();

  const createCustomTagCallback = useCallback(
    async ({ variables }: { variables: CreateCustomTagMutationVariables }) => {
      try {
        return await createCustomTagMutation({
          variables,
          update: (cache, { data }) => {
            cache.modify({
              fields: {
                custom_tags(existingTags = []) {
                  const newTagRef = cache.writeFragment({
                    data: data?.createCustomTag,
                    fragment: CustomTagFragmentFragmentDoc,
                  });
                  return [...existingTags, newTagRef];
                },
              },
            });
          },
        });
      } catch (err) {
        console.log('error in create custom', err);
        throw new Error();
      }
    },
    [createCustomTagMutation, mutationStateInfo],
  );

  return [createCustomTagCallback, mutationStateInfo];
};

export default useCreateCustomTag;
