import { useMemo } from 'react';
import { Tag } from '../types/timelineTypes';
import useContentTypes from './useContentTypes';
import useSpecialities from './useSpecialities';

// @TODO: create a hook that completely works with API
const useContentTypesAndSpecialities = () => {
  const contentTypesQuery = useContentTypes();
  const specialitiesQuery = useSpecialities();

  return useMemo((): {
    list: Tag[];
    map: Record<string, Tag>;
    content: Tag[];
    specialities: Tag[];
    deprecated: Tag[];
  } => {
    const contentTypesData = contentTypesQuery?.data ?? [];
    const specialitiesData = specialitiesQuery?.data ?? [];

const processTags = (items: Tag[] = []) => {
  const nonDeprecated = [];
  const deprecated = [];
  const map = {};

  items.forEach(item => {
    map[item.id] = item;

    if (item.origin === 'deprecated') {
      deprecated.push(item);
    } else {
      nonDeprecated.push(item);
    }
  });

  return [nonDeprecated, deprecated, map];
};

const [nonDeprecatedContentTypes, deprecatedContentTypes, contentTypesMap] = processTags(contentTypesData);
const [nonDeprecatedSpecialities, deprecatedSpecialities, specialitiesMap] = processTags(specialitiesData);

    const map = {
      ...contentTypesMap,
      ...specialitiesMap,
    };

    return {
      list: Object.values(map),
      map,
      content: Object.values(nonDeprecatedContentTypes),
      specialities: Object.values(nonDeprecatedSpecialities),
      deprecated: [...deprecatedContentTypes, ...deprecatedSpecialities],
    };
  }, [contentTypesQuery, specialitiesQuery]);
};

export default useContentTypesAndSpecialities;
