import PageScroller from './components/PageScroller';
import { useSyncDocumentsScroll } from './useSyncDocumentsScroll';

type Props = {
  firstDocumentID: bigint;
  secondDocumentID: bigint;
  firstDocumentStyle?: any;
  secondDocumentStyle?: any;
  zoom: number;
  setFirstDocumentPageCount: (count: number) => void;
  setSecondDocumentPageCount: (count: number) => void;
};

export default function DocumentComparer({
  firstDocumentID,
  secondDocumentID,
  firstDocumentStyle,
  secondDocumentStyle,
  setFirstDocumentPageCount,
  setSecondDocumentPageCount,
  zoom,
}: Props) {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        backgroundColor: 'white',
      }}
    >
      <span
        style={{
          margin: 'auto',
          width: '50%',
        }}
      >
        <PageScroller
          documentId={firstDocumentID}
          containerId="first-document-scroller"
          style={firstDocumentStyle ?? {}}
          pageHeader={true}
          sharedZoom={zoom}
          setDocumentPageCount={setFirstDocumentPageCount}
        />
      </span>
      <span style={{ width: '50%', alignContent: 'center' }}>
        <PageScroller
          documentId={secondDocumentID}
          containerId="second-document-scroller"
          style={secondDocumentStyle ?? {}}
          pageHeader={true}
          sharedZoom={zoom}
          setDocumentPageCount={setSecondDocumentPageCount}
        />
      </span>
    </div>
  );
}
