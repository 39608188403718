export const normalizeTimelineReport = (report: any) => ({
  id: report.id,
  caseID: report.case_id,
  reportName: report.name,
  reportType: report.report_type,
  ownerID: report.owner_id,
  creationDate: report.creation_date,
  lastModifiedDate: report.last_modified_date,
  isAutoGenerated: report.is_auto_generated,
  timelineID: report?.timeline_id,
  sections: report?.timeline_report_sections?.map((section: any) => ({
    id: section.id,
    name: section.name,
    allowedTagIds: section.allowed_tag_ids,
    sources: section.sources,
    contentTypes: section.content_types,
    subContentTypes: section.sub_content_types,
    timelineReportID: section.timeline_report_id,
  })),
});
