import { Box, Button, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { WarningRounded } from '@mui/icons-material';
import Export from '../../../components/icons/Export';
import TimelineDocumentViewSelector from '../Components/TimelineDocumentViewSelector';
import TimelineSettings from '../TimelineSettings';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';
import TimelinePageRange from './TimelinePageRange';
import { FileProcessorNextStepButton } from '../../Files/FilesTab';
import { FileStatus } from '../../../api';
import useCaseFiles, { useUpdateFileStatus } from '../../Files/useCaseFiles';
import MergeAll from '../MergeAllEntries';
import BulkApplyAll from '../BulkApplyEntries';
import IconButtonContainer from '../../../components/common/IconButtonContainer';

export default function TimelineHeader({
  exportFilteredSortedTimeline,
  caseID,
  mergeDialogOpen,
  handleMergeAllEntries,
  setMergeDialogOpen,
  currentTimelineEntry,
  timelineEntries,
  bulkDialog,
  setbulkDialog,
  entryID,
}: TimelineHeaderProps) {
  const isFileProcessor = useIsFileProcessor();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const currentDocumentID = searchParams.get('documentID') ?? null;

  const { data: caseFiles } = useCaseFiles(caseID);

  const { mutate: updateFileStatus } = useUpdateFileStatus();

  const currentDocument = useMemo(() => {
    if (!caseFiles) {
      return null;
    }
    return caseFiles.find((doc) => doc.documentID === currentDocumentID);
  }, [caseFiles, currentDocumentID]);

  const handleFileStatusChange = async (id: string, currentStatus: any) => {
    const nextStatusByCurrentStatus = {
      [FileStatus.PENDING]: FileStatus.GROUPING,
      [FileStatus.GROUPING]: FileStatus.TAGGING,
      [FileStatus.TAGGING]: FileStatus.QA_REQUIRED,
      [FileStatus.QA_REQUIRED]: FileStatus.APPROVED,
    };

    if (!Object.keys(nextStatusByCurrentStatus).includes(currentStatus)) {
      return;
    }
    updateFileStatus({ caseID, fileID: id, status: nextStatusByCurrentStatus[currentStatus] });

    navigate(`/case/${caseID}/files`);
  };

  const onButtonClick = () => {
    if (currentDocument) {
      handleFileStatusChange(currentDocument.documentID, currentDocument.fileStatus);
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isDisabled = timelineEntries.length > 100;
  const tooltipTitle = isDisabled
    ? 'Bulk apply is disabled for files with more than 100 entries'
    : '';

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack direction="row" paddingX=".5rem" sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flex: 1 }}>
          <TimelineDocumentViewSelector />
          <Tooltip title="Export Timeline">
            <span>
              <Button
                variant="outlined"
                onClick={exportFilteredSortedTimeline}
                sx={{
                  ml: 1,
                  width: 36,
                  height: 36,
                  minWidth: 36,
                  padding: 1,
                  marginTop: 0.3,
                  bgcolor: '#FFFFFF',
                }}
                disabled={isFileProcessor}
              >
                <Export fill="none" />
              </Button>
            </span>
          </Tooltip>
        </Box>
        {isFileProcessor ? (
          <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <TimelineSettings />
            <div>
              <IconButton onClick={handleClick}>
                <WarningRounded fontSize="medium" />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    setMergeDialogOpen(true);
                    handleClose();
                  }}
                >
                  Merge All
                </MenuItem>
                <Tooltip title={tooltipTitle} placement="top">
                  <span>
                    <MenuItem
                      onClick={() => {
                        setbulkDialog(true);
                        handleClose();
                      }}
                      disabled={isDisabled}
                    >
                      Bulk Apply All
                    </MenuItem>
                  </span>
                </Tooltip>
              </Menu>
            </div>
          </Stack>
        ) : (
          <TimelineSettings />
        )}
      </Stack>
      {isFileProcessor && currentDocumentID && (
        <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TimelinePageRange documentID={currentDocumentID} />
          <FileProcessorNextStepButton
            fileStatus={currentDocument?.fileStatus as FileStatus}
            sx={{
              fontSize: '0.65rem',
              mt: '0.35rem',
              mb: '-0.5rem',
              height: '1.5rem',
              minWidth: '5rem',
            }}
            onClick={onButtonClick}
          />
          {currentDocument?.fileStatus === FileStatus.APPROVED && (
            <Typography variant="caption" sx={{ mt: '0.5rem', color: 'newSuccess.main' }}>
              Approved
            </Typography>
          )}
        </Stack>
      )}
      {mergeDialogOpen && (
        <MergeAll
          mergeDialogOpen={mergeDialogOpen}
          handleMergeAllEntries={handleMergeAllEntries}
          setMergeDialogOpen={setMergeDialogOpen}
        />
      )}
      {!isDisabled && bulkDialog && (
        <BulkApplyAll
          currentTimelineEntry={currentTimelineEntry}
          timelineEntries={timelineEntries}
          bulkDialog={bulkDialog}
          setbulkDialog={setbulkDialog}
        />
      )}
    </>
  );
}

interface TimelineHeaderProps {
  exportFilteredSortedTimeline: () => void;
  caseID: string;
  mergeDialogOpen: boolean;
  setMergeDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleMergeAllEntries: () => void;
  currentTimelineEntry: any;
  timelineEntries: any[];
  entryID: number;
  bulkDialog: boolean;
  setbulkDialog: React.Dispatch<React.SetStateAction<boolean>>;
}
