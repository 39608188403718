import { useState } from 'react';
import { Button, Popover, Typography, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export default function UserDetails({ setSettingSelected, removeMFAFromAccount, user }) {
  const attributes = user.attributes ?? user.signInUserSession.idToken.payload;
  const { given_name, family_name, email, email_verified } = attributes;
  const { preferredMFA } = user;
  const mfaEnabled = preferredMFA !== 'NOMFA';
  return (
    <Table>
      <TableBody>
        <DetailRow label="Name" value={`${given_name} ${family_name}`} />
        <DetailRow
          label="Email"
          value={email}
          action={
            <div style={{ color: '#228B22', flex: '90%' }}>
              {email_verified ? 'Verified' : 'Not Verified'}
            </div>
          }
        />
        <DetailRow
          label="Password"
          value="*************"
          action={
            <ActionButton
              onClick={() => setSettingSelected('Change Password')}
              text="Change Password"
            />
          }
        />

        <DetailRow
          label="MFA"
          value={
            mfaEnabled
              ? preferredMFA === 'SMS' || preferredMFA === 'SMS_MFA'
                ? 'SMS'
                : 'Authenticator'
              : '-'
          }
          action={
            mfaEnabled ? (
              <div style={{ color: '#228B22', flex: '90%' }}>Enabled</div>
            ) : (
              <ActionButton onClick={() => setSettingSelected('MFA')} text="Enable MFA" />
            )
          }
          onDelete={mfaEnabled ? removeMFAFromAccount : null}
        />
      </TableBody>
    </Table>
  );
}

function DetailRow(props) {
  const { label, value, action, onEdit, onDelete } = props;
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeMFA = async () => {
    setLoading(true);
    await onDelete();
    setAnchorEl(null);
    setLoading(false);
  };

  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      <TableCell width="40%">{value}</TableCell>
      <TableCell style={{ paddingLeft: '12px' }}>
        <div style={{ fontSize: '0.875rem' }}>{action}</div>
      </TableCell>
      <TableCell>
        {onEdit && (
          <EditIcon style={{ color: 'rgba(0,0,0,0.54)', cursor: 'pointer' }} onClick={onEdit} />
        )}
        {onDelete && (
          <>
            <DeleteIcon
              aria-describedby={id}
              style={{ color: 'rgba(0,0,0,0.54)', cursor: 'pointer' }}
              onClick={handleClick}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <div style={{ width: '20rem', height: '7rem', textAlign: 'center' }}>
                {loading ? (
                  <div style={{ textAlign: 'center', paddingTop: '2rem' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    <Typography sx={{ p: 2, fontSize: '14px' }}>
                      This may restrict access to your account.
                    </Typography>
                    <div style={{ textAlign: 'center', marginBottom: '4px' }}>
                      <Button variant="contained" color="error" onClick={removeMFA}>
                        Remove MFA
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </Popover>
          </>
        )}
      </TableCell>
    </TableRow>
  );
}

function ActionButton({ onClick, text }) {
  return (
    <Button
      size="small"
      className="confirm-delete"
      sx={{ fontSize: '0.8rem', width: '100%' }}
      variant="outlined"
      onClick={onClick}
    >
      {text}
    </Button>
  );
}
