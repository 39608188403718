import {
  Paper,
  IconButton,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Popper,
  Grow,
} from '@mui/material';
import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AppContext } from '../../library/contexts/AppContext';
import LinkWithQuery from '../Timeline/LinkWithQuery';
import { isTimelineView } from '../../utils/other';
import { useGetPageByIdQuery } from '../../__generated__/graphql';
import { useUser } from '../../library/contexts/AuthContext';

/**
 * Dropdown of actions for notes. Edit, find, delete.
 *
 */
function NoteActions(
  props = {
    noteID: -1,
    editNote: null,
    callDeleteNote: null,
    caseID: 0,
    documentID: null,
    // pageID,  // this is not defined and would throw error if this was ever reached.
  },
) {
  const { editNote, noteID, handleDelete, caseID, ownerID, pageID } = props;
  const { setReportEditorOpen } = useContext(AppContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const { userId } = useUser();
  const { timelineID } = useParams();

  //Toggle the menu open/closed
  const handleToggle = () => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const deleteNote = () => {
    setMenuOpen(false);
    handleDelete();
  };

  //Close the menu and drop the location ref
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setMenuOpen(false);
  };
  const handleEdit = () => {
    editNote(noteID);
    setMenuOpen(false);
  };

  const handleGoToSource = () => {
    setMenuOpen(false);
    setReportEditorOpen(false);
  };

  const { data: pageObject } = useGetPageByIdQuery({
    variables: {
      id: pageID,
    },
    skip: !pageID,
  });

  const targetPage = pageObject?.page ?? {};

  const sourceUrl = isTimelineView()
    ? targetPage
      ? `/case/${caseID}/timeline/${timelineID}/${targetPage?.timelineEntryID}/${pageID}`
      : `/case/${caseID}/timeline/${timelineID}`
    : `/case/${caseID}/documents/${targetPage?.documentID}/${pageID}`;

  return (
    <>
      <IconButton
        size="small"
        ref={anchorRef}
        aria-controls={menuOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <MoreVertIcon sx={{ fontSize: '1.4rem' }} />
      </IconButton>

      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={menuOpen} id="menu-list-grow">
                  <MenuItem onClick={handleEdit}>Edit</MenuItem>
                  <LinkWithQuery
                    timelineID={timelineID}
                    documentID={props.documentID}
                    to={sourceUrl}
                    style={{ color: 'inherit', textDecoration: 'inherit' }}
                  >
                    <MenuItem onClick={handleGoToSource}>Go To Source</MenuItem>
                  </LinkWithQuery>
                  {ownerID === userId && <MenuItem onClick={deleteNote}>Delete</MenuItem>}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default NoteActions;
