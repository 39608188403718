import {
  Box,
  Divider,
  Alert,
  InputLabel,
  Typography,
  Stack,
  Button,
  IconButton,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { Close } from '@mui/icons-material';
import OrderFilter from './OrderFilter';
import DateFilter from './DateFilter';
import TagFilter from './TagFilter';
import DocumentFilter from './DocumentFilter';
import PhysicianFilter from './PhysicianFilter';
import PageFilter from './PageFilter';
import OwnerFilter from './OwnerFilter';
import useNotesStore from '../../../Notes/useNotesStore';
import { FilterInputLabel } from '../ConfigurableViewFilter';

const inputLabelStyles = {
  fontWeight: 500,
  fontSize: '0.7rem',
  marginTop: 1.5,
  marginBottom: -2.7,
  marginLeft: 1,
};

export default function NoteFilters({ onClose }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const clearAllFilters = () => {
    searchParams.delete('notes__pageId');
    searchParams.delete('notes__docId');
    setSearchParams(searchParams);
    clearFilters();
  };

  const { isDateRangeValid, clearFilters } = useNotesStore(
    (state) => ({
      isDateRangeValid: state.isDateRangeValid,
      clearFilters: state.clearFilters,
    }),
    shallow,
  );

  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          position: 'absolute',
          right: 12,
          top: 12,
        }}
      >
        <Button variant="text" size="small" onClick={() => clearAllFilters()}>
          Reset
        </Button>
        <IconButton sx={{ ml: '0.5rem' }} onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>
      <Stack gap={0.5}>
        <Typography variant="filterMenuHead">Sorting & Filtering</Typography>
        <Typography variant="filterMenuSubHead">Select filters below</Typography>
      </Stack>
      <Divider sx={{ mt: 1, mb: 1 }} />
      <Box sx={{ display: 'flex', flexDirection: 'row', id: 'upper-filter-box' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            flex: '50%',
            id: 'order-box',
          }}
        >
          <OrderFilter />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            alignItems: 'flex-start',
          }}
        >
          <DateFilter />
        </Box>
      </Box>
      {!isDateRangeValid && (
        <Alert severity="error" sx={{ width: '100%', marginBottom: 2 }}>
          The date range is not valid!
        </Alert>
      )}
      <Box
        component="span"
        sx={{
          width: '100%',
          fontSize: '0.875rem',
          fontWeight: 600,
          marginTop: '1rem',
          id: 'down-filter-box',
        }}
      >
        <Divider sx={{ mb: 1.6, mx: 1 }} />
        <Box ml={1}>
          <FilterInputLabel>Filter By</FilterInputLabel>
        </Box>
      </Box>
      <InputLabel sx={{ ...inputLabelStyles }}>Tags</InputLabel>
      <TagFilter />
      <InputLabel sx={{ ...inputLabelStyles, marginBottom: -2.7 }}>Physician</InputLabel>
      <PhysicianFilter />
      <InputLabel sx={{ ...inputLabelStyles, marginBottom: -1.7 }}>Author</InputLabel>
      <OwnerFilter />
      <InputLabel sx={{ ...inputLabelStyles, marginBottom: -2.7 }}>Document</InputLabel>
      <DocumentFilter />
      <PageFilter />
    </Box>
  );
}
