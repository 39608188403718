import { SvgIcon } from '@mui/material';

export default function InsertNote(props, { color }) {
  return (
    <SvgIcon {...props} viewBox="0 0 25 23" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.27104 12.7743C9.27104 12.593 9.34309 12.419 9.47134 12.2908C9.59959 12.1625 9.77353 12.0905 9.9549 12.0905H15.4258V1.83256C15.4258 1.46982 15.2817 1.12194 15.0252 0.86544C14.7687 0.608942 14.4208 0.464844 14.0581 0.464844H5.07898L0.380859 5.15612V18.2452C0.380859 18.6079 0.524958 18.9558 0.781455 19.2123C1.03795 19.4688 1.38584 19.6129 1.74858 19.6129H14.0581C14.4208 19.6129 14.7687 19.4688 15.0252 19.2123C15.2817 18.9558 15.4258 18.6079 15.4258 18.2452V13.4582H9.9549C9.77353 13.4582 9.59959 13.3861 9.47134 13.2579C9.34309 13.1296 9.27104 12.9557 9.27104 12.7743ZM5.85174 5.93572H1.74858V5.71689L5.63974 1.83256H5.85174V5.93572Z"
        fill={color}
      />
      <path
        d="M11.9721 15.6901C11.9721 15.5082 12.0443 15.3338 12.1729 15.2052C12.3015 15.0766 12.4759 15.0044 12.6578 15.0044H18.1433V4.71904C18.1433 4.35532 17.9988 4.00651 17.7416 3.74932C17.4844 3.49214 17.1356 3.34766 16.7719 3.34766H7.7688L3.05811 8.05149V21.1756C3.05811 21.5393 3.20259 21.8881 3.45977 22.1453C3.71696 22.4025 4.06577 22.547 4.42949 22.547H16.7719C17.1356 22.547 17.4844 22.4025 17.7416 22.1453C17.9988 21.8881 18.1433 21.5393 18.1433 21.1756V13.2316L12.6578 16.3758C12.4759 16.3758 12.3015 16.3035 12.1729 16.1749C12.0443 16.0463 11.9721 15.8719 11.9721 15.6901ZM8.54363 8.83318H4.42949V8.61376L8.33106 4.71904H8.54363V8.83318Z"
        fill={color}
      />
      <path
        d="M20.4196 10.4461C20.2884 10.3338 20.1197 10.2751 19.9471 10.2818C19.7745 10.2884 19.6108 10.36 19.4887 10.4821C19.3666 10.6042 19.295 10.7679 19.2884 10.9405C19.2817 11.113 19.3404 11.2818 19.4527 11.413L21.6812 13.6346H18.1431V15.006H21.7018L19.4527 17.255C19.381 17.3165 19.3227 17.3921 19.2815 17.4772C19.2403 17.5623 19.2172 17.6549 19.2136 17.7494C19.2099 17.8438 19.2258 17.938 19.2603 18.026C19.2948 18.114 19.3471 18.1939 19.4139 18.2607C19.4807 18.3275 19.5606 18.3798 19.6486 18.4143C19.7366 18.4488 19.8308 18.4647 19.9252 18.461C20.0196 18.4574 20.1123 18.4343 20.1974 18.3931C20.2824 18.3519 20.3581 18.2936 20.4196 18.2219L24.3143 14.3203L20.4196 10.4461Z"
        fill={color}
      />
    </SvgIcon>
  );
}
