import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { authenticatedRequest } from '../../../api';

async function updateDocumentHiddenStatus(entryID: bigint, isHidden: boolean): Promise<void> {
  const res = await authenticatedRequest({
    method: 'POST',
    url: `/timeline-entry/${entryID}/updateHiddenStatus`,
    data: { hidden: isHidden },
  });
  if (res.status === 201) {
    toast.success('Document hidden status updated successfully');
  } else {
    toast.error('Failed to update document hidden status');
  }
}

export function useUpdateDocumentHiddenStatus(): (
  entryID: bigint,
  isHidden: boolean,
) => Promise<void> {
  const queryClient = useQueryClient();
  return async (entryID, isHidden) => {
    await updateDocumentHiddenStatus(entryID, isHidden);
    queryClient.invalidateQueries(['getTimelineEntriesForReportSection']);
  };
}
