import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/styles';
import Link from '@mui/material/Link';
import { darken } from '@mui/material/styles';
import FillAndCenter from '../../components/common/Base/FillAndCenter';
import { ReactComponent as ErrorIcon } from './icons/error-icon.svg';
import { ThemeType } from '../../theme';
import ErrorScreenOutlinedButton from './components/ErrorScreenOutlinedButton';
import ErrorScreenContainedButton from './components/ErrorScreenContainedButton';

function ErrorScreen() {
  const {
    typography: { pxToRem },
    palette,
  }: ThemeType = useTheme();

  const handleRefreshClick = () => {
    window.location.replace('/');
  };

  const handleLogOutClick = () => {
    window.sessionStorage.clear();
    window.localStorage.clear();

    window.location.replace('/');
  };

  const mailHref = `mailto:support.siftmed.ca?subject=${encodeURIComponent('Application crash')}`;

  return (
    <FillAndCenter direction="column" bgcolor="white">
      <Stack width={pxToRem(490)} alignItems="center" fontFamily="Inter">
        <Box mb={5}>
          <ErrorIcon />
        </Box>

        <Typography
          fontFamily="inherit"
          fontSize={pxToRem(20)}
          fontWeight={600}
          lineHeight={pxToRem(30)}
          color="headerError.main"
          mb={1}
        >
          Oops!
        </Typography>

        <Typography fontSize={pxToRem(16)} lineHeight={pxToRem(24)} fontFamily="inherit">
          Looks like we ran into some trouble. Try refreshing the page.
          <Typography color="primaryError.main" mt={3} fontFamily="inherit">
            If this issue persists, please email our support team at{' '}
            <Link
              component="a"
              href={mailHref}
              color="secondaryError.main"
              fontFamily="inherit"
              underline="hover"
              sx={{
                '&:hover': { color: darken(palette.secondaryError.main, 0.1) },
              }}
            >
              support.siftmed.ca
            </Link>
          </Typography>
        </Typography>

        <Stack direction="row" gap={2} alignItems="center" mt={5}>
          <ErrorScreenOutlinedButton onClick={handleLogOutClick}>Log Out</ErrorScreenOutlinedButton>
          <ErrorScreenContainedButton onClick={handleRefreshClick}>
            Refresh
          </ErrorScreenContainedButton>
        </Stack>
      </Stack>
    </FillAndCenter>
  );
}

export default ErrorScreen;
