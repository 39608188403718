import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useApolloClient, FetchResult, MutationResult } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';
import {
  MergeSplitTimelineEntriesMutationVariables,
  MergeSplitTimelineEntriesMutation,
  useMergeSplitTimelineEntriesMutation,
  GetTimelineEntriesDocument,
  GetTimelineEntriesDescriptorsDocument,
  GetCaseSourceFiltersDocument,
  GetCaseContentFiltersDocument,
} from '../../../__generated__/graphql';

const useMergeSplitTimelineEntry = (): [
  (
    params: MergeSplitTimelineEntriesMutationVariables,
  ) => Promise<FetchResult<MergeSplitTimelineEntriesMutation>>,
  MutationResult<MergeSplitTimelineEntriesMutation>,
] => {
  const [mutation, state] = useMergeSplitTimelineEntriesMutation();
  const client = useApolloClient();
  const { caseID } = useParams();

  const wrappedMutation = useCallback(
    (params: MergeSplitTimelineEntriesMutationVariables) => {
      try {
        return mutation({
          variables: {
            data: params.data,
          },
          update: (cache, { data }) => {
            if (data) {
              client.refetchQueries({
                include: [
                  GetTimelineEntriesDocument,
                  GetTimelineEntriesDescriptorsDocument,
                  GetCaseSourceFiltersDocument,
                  GetCaseContentFiltersDocument,
                ],
              });
            }
          },
          onCompleted: (data) => {
            if (data?.mergeSplitTimelineEntries) {
              return data?.mergeSplitTimelineEntries?.newEntry;
            }
          },
        });
      } catch (error) {
        toast.error(
          'A problem occurred while updating timeline entries. If issues persist, please try refreshing the page.',
        );
        Sentry.captureException(error);
        throw error;
      }
    },
    [mutation, client, caseID],
  );

  return useMemo(() => [wrappedMutation, state], [wrappedMutation, state]);
};

export default useMergeSplitTimelineEntry;
