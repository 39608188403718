import { useCallback, useMemo } from 'react';
import { useUser } from '../../../library/contexts/AuthContext';
import { createGetTimelineDocumentsInputFromFilters } from '../../Timeline/gql/utils/timelineGqlUtils';
import { UseTimelineDocumentsParams } from '../../Timeline/types/timelineTypes';
import { DocumentDescriptorObject } from './types/documentTypes';
import { useDocumentListDescriptorQuery } from '../../../__generated__/graphql';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';

const useDocumentDescriptors = (params: UseTimelineDocumentsParams) => {
  const { userId } = useUser();
  const isFileProcessor = useIsFileProcessor();

  const whereInput = useMemo(
    () => createGetTimelineDocumentsInputFromFilters({ ...params, userId }, isFileProcessor),
    [params, userId],
  );

  const documentDescriptorQuery = useDocumentListDescriptorQuery({ variables: whereInput });

  const documentListDescriptor = documentDescriptorQuery.data?.documentListDescriptor ?? [];
  const flatDocumentList = useMemo(
    () => flattenDocumentList(documentListDescriptor),
    [documentListDescriptor],
  );

  const refetch = useCallback(
    (refetchParams: UseTimelineDocumentsParams) => {
      documentDescriptorQuery.refetch({
        ...createGetTimelineDocumentsInputFromFilters(
          { ...refetchParams, userId },
          isFileProcessor,
        ),
      });
    },
    [documentDescriptorQuery, userId],
  );

  return useMemo(() => {
    return {
      ...documentDescriptorQuery,
      refetch,
      flatDocumentList,
    };
  }, [documentDescriptorQuery, documentDescriptorQuery.data, refetch]);
};

export default useDocumentDescriptors;

/**
 *
 * @param DocumentDescriptor
 * @returns Flat list of documents and pages for rendering in the UI
 */
function flattenDocumentList(documents: DocumentDescriptorObject[]): Array<number | string> {
  const result: (string | number)[] = [];

  documents.forEach((document) => {
    result.push(document.documentID);
    result.push(...document.pages.map((page) => page.id));
  });

  return result;
}
