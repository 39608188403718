import React, { useState } from 'react';
import dayjs from 'dayjs';
import Modal from '../../../components/common/HTML_components/Modal/Modal';
import Theme from '../../../theme';
import CloseIcon from '../../../components/icons/Close';
import MultiOptionButton from '../../../components/common/HTML_components/MultiOptionButton/MultiOptionButton';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  currentlySelectedDate: string;
  handleChangeSelectedDate: (value: string | undefined) => void;
};

export default function UploadDateFilterModal({
  isOpen = false,
  onClose,
  currentlySelectedDate,
  handleChangeSelectedDate,
}: Props) {
  const [selectedDateInputValue, setSelectedDateInputValue] = useState(currentlySelectedDate ?? '');

  const resetAppliedDate = () => {
    handleChangeSelectedDate(undefined);
    setSelectedDateInputValue('');
  };

  const submitUpdatedFilterDate = () => {
    handleChangeSelectedDate(selectedDateInputValue);
    onClose();
  };

  const onCloseModal = () => {
    setSelectedDateInputValue(currentlySelectedDate ?? '');
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal}>
      <UploadDateFilterModalContent
        onClose={onCloseModal}
        submitUpdatedFilterDate={submitUpdatedFilterDate}
        selectedDateInputValue={selectedDateInputValue}
        setSelectedDateInputValue={setSelectedDateInputValue}
        resetAppliedDate={resetAppliedDate}
        style={{
          width: 'fit-content',
        }}
      />
    </Modal>
  );
}

type UploadDateFilterModalContentProps = {
  onClose: () => void;
  submitUpdatedFilterDate: () => void;
  selectedDateInputValue: string;
  setSelectedDateInputValue: (value: string) => void;
  resetAppliedDate: () => void;
};

function UploadDateFilterModalContent({
  onClose,
  submitUpdatedFilterDate,
  selectedDateInputValue,
  setSelectedDateInputValue,
  resetAppliedDate,
}: UploadDateFilterModalContentProps) {
  const handleReset = () => {
    resetAppliedDate();
    onClose();
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '30rem',
        padding: '1rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <UploadDateFilterModalHeader />
        <span>
          <button
            type="button"
            className="sm-button"
            style={{
              border: 'none',
              backgroundColor: 'white',
              marginRight: '0.25rem',
              color: Theme.palette.primary.main,
              fontSize: '0.9rem',
            }}
            tabIndex={-1}
            onClick={handleReset}
          >
            Reset
          </button>
          <CloseIcon onClick={onClose} />
        </span>
      </div>
      <hr className="sm-horizontal-divider" style={{ marginTop: '0.5rem' }} />
      <UploadDateFilterModalBody
        handleChange={setSelectedDateInputValue}
        selectedDate={selectedDateInputValue}
      />
      <hr
        className="sm-horizontal-divider"
        style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
      />
      <UploadDateFilterModalFooter onClose={onClose} onSubmit={submitUpdatedFilterDate} />
    </div>
  );
}

function UploadDateFilterModalHeader() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h4>Report Filters</h4>
      <p
        style={{
          color: Theme.palette.subHeading.main,
        }}
      >
        Apply filters to all report sections
      </p>
    </div>
  );
}

type UploadDateFilterModalBodyProps = {
  selectedDate: string;
  handleChange: (value: string) => void;
};

function UploadDateFilterModalBody({ selectedDate, handleChange }: UploadDateFilterModalBodyProps) {
  const currentDateString = dayjs();
  const sevenDaysAgo = currentDateString.subtract(7, 'day').format('YYYY-MM-DD');
  const thirtyDaysAgo = currentDateString.subtract(30, 'day').format('YYYY-MM-DD');
  const ninetyDaysAgo = currentDateString.subtract(90, 'day').format('YYYY-MM-DD');

  const datePickerOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e.target.value);
  };
  return (
    <>
      <span
        style={{
          fontSize: '1rem',
          fontWeight: 500,
        }}
      >
        Upload Date
      </span>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <p
          style={{
            fontSize: '0.8rem',
            marginBottom: '0.5rem',
            color: Theme.palette.subHeading.main,
          }}
        >
          Select an Upload Date to only retrieve data from files uploaded on or after the specified
          date.
        </p>
        <MultiOptionButton
          onClick={handleChange}
          style={{
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
          }}
          options={[
            { label: '7 Days Ago', value: sevenDaysAgo },
            { label: '30 Days Ago', value: thirtyDaysAgo },
            { label: '90 Days Ago', value: ninetyDaysAgo },
          ]}
          selectedValue={selectedDate}
        />

        <label
          htmlFor="date-picker"
          style={{
            fontSize: '0.8rem',
            display: 'flex',
            flexDirection: 'column',

            color: Theme.palette.subHeading.main,
          }}
        >
          <p
            style={{
              marginBottom: '0',
              marginLeft: '0.25rem',
            }}
          >
            {' '}
            Upload Date
          </p>
          <input
            id="date-picker"
            type="date"
            value={selectedDate}
            onChange={datePickerOnChange}
            style={{
              borderRadius: 8,
              border: `1px solid ${Theme.palette.borderGrey.main}`,
              width: 'fit-content',
              padding: '0.5rem',
              cursor: 'text',
            }}
          />
        </label>
      </div>
    </>
  );
}

type UploadDateFilterModalFooterProps = {
  onClose: () => void;
  onSubmit: () => void;
};

function UploadDateFilterModalFooter({ onClose, onSubmit }: UploadDateFilterModalFooterProps) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <button
        type="button"
        onClick={onClose}
        style={{
          marginRight: '0.5rem',
        }}
        className="sm-button"
      >
        Cancel
      </button>
      <button
        type="button"
        className="sm-button"
        style={{
          backgroundColor: 'var(--color-primary-main)',
          color: 'white',
        }}
        onClick={onSubmit}
      >
        Apply
      </button>
    </div>
  );
}
