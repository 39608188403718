import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Auth } from '@aws-amplify/auth';
import QRCode from 'react-qr-code';
import { CircularProgress } from '@mui/material';
import * as Sentry from '@sentry/react';
import MFAVerificationSuccessful from './MFAVerificationSuccessful';
import BackButton from '../../../components/common/BackButton';
import EnterVerificationCode from './EnterVerificationCode';

export default function SetupMFAUsingTOTP({ back, user, onComplete, nextText, email }) {
  const [page, setPage] = useState('ScanQRCode');
  const [errorMessage, setErrorMessage] = useState('');

  const onAlertClose = () => {
    setErrorMessage('');
  };

  const submitVerificationCode = (code) => {
    Auth.verifyTotpToken(user, code)
      .then(() => {
        Auth.setPreferredMFA(user, 'TOTP');
        setPage('verifyTOTPSuccess');
      })
      .catch((error) => {
        setErrorMessage('There was an error verifying your code. Please try again.');
        Sentry.captureException(error);
      });
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ flex: '80%' }}>
        {page === 'ScanQRCode' && (
          <ScanQRCode user={user} next={() => setPage('EnterQRCode')} email={email} back={back} />
        )}
        {page === 'EnterQRCode' && (
          <EnterVerificationCode
            onSubmit={submitVerificationCode}
            back={() => setPage('ScanQRCode')}
            title="Enter the code on your authenticator app below"
          />
        )}
        {page === 'verifyTOTPSuccess' && (
          <MFAVerificationSuccessful next={onComplete} nextText={nextText} />
        )}
      </div>

      {errorMessage !== '' && (
        <Alert severity="error" onClose={onAlertClose} style={{ marginTop: '.5rem' }}>
          {errorMessage}
        </Alert>
      )}
    </div>
  );
}

function ScanQRCode({ back, user, next, email }) {
  const [secret, setSecret] = useState('');

  useEffect(() => {
    Auth.setupTOTP(user).then((code) => {
      const str = `otpauth://totp/${
        user?.attributes?.email || email
      }?secret=${code}&issuer=SiftMed`;
      setSecret(str);
    });
  }, []);

  return (
    <div style={{ height: '100%' }}>
      {secret !== '' ? (
        <>
          <BackButton
            onClick={back}
            style={{
              float: 'left',
              marginTop: '-2px',
            }}
          />

          <div
            style={{
              fontSize: '16px',
              textAlign: 'center',
              marginBottom: '2rem',
            }}
          >
            Scan the following QR code with your authenticator app.
          </div>
          <div style={{ textAlign: 'center' }}>
            <QRCode value={secret} size={96} />
          </div>
          <br />
          <br />
          <p
            style={{
              fontSize: '0.875rem',
              textAlign: 'center',
              color: 'rgba(0,0,0,0.75)',
            }}
          >
            Tap next when you have completed the setup.
          </p>
          <Button fullWidth variant="contained" color="primary" onClick={next}>
            Next
          </Button>
        </>
      ) : (
        <Box sx={{ textAlign: 'center', paddingTop: '2rem' }}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
