import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import {
  GetCaseSourceFiltersDocument,
  ChangeTimelineEntryDetailsInput,
  useUpdateTimelineEntryMutation,
} from '../../../__generated__/graphql';

const useUpdateTimelineEntry = () => {
  const [updateTimelineEntryMutation, updateEntryLoading] = useUpdateTimelineEntryMutation();
  const client = useApolloClient();
  const queryClient = useQueryClient();

  const updateTimelineEntry = useCallback(
    async (params: ChangeTimelineEntryDetailsInput) => {
      try {
        return await updateTimelineEntryMutation({
          variables: {
            changeTimelineEntryDetailsInput: params,
            pagesViewedWhere: {},
          },
        });
      } catch (error) {
        console.log({ error });
        toast.error(
          'A problem occurred while updating timeline entry. If issues persist, please try refreshing the page.',
        );
        Sentry.captureException(error);
        throw error;
      } finally {
        client.refetchQueries({
          include: [GetCaseSourceFiltersDocument],
        });
        queryClient.invalidateQueries(['getTimelineEntriesForReportSection']);
      }
    },
    [updateTimelineEntryMutation],
  );
  return <[typeof updateTimelineEntry, typeof updateEntryLoading]>[
    updateTimelineEntry,
    updateEntryLoading,
  ];
};

export default useUpdateTimelineEntry;
