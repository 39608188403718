import { gql } from '@apollo/client';

const PageFragment = gql`
  fragment pageCardDetails on PageObject {
    id
    entryID: timeline_entry_id
    documentID: document_id
    hasDuplicates: has_duplicates
    isDuplicate: is_duplicate
    isFavourite: is_favourite
    pageDate: page_date
    rotationAngle: rotation_angle
    correctedDate: corrected_page_date
    pageNumber: page_number
    order
    pages_viewed(where: $pagesViewedWhere) {
      user_id
      viewed_at
      page_id
    }
    tags {
      id
      label
      name
      type
      parent_tag_id
      origin
    }
    contentTypes: content_types {
      id
      name
      type
      parent_tag_id
      origin
    }
    specialities {
      id
      name
      type
    }
    source {
      id
      name
      type
      confidence
    }
  }
`;

export default PageFragment;
