import { useCallback, useEffect, useState, useRef } from 'react';
import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { DocumentPreviewer } from '../../../containers/ReportEditor/IndexReports/DocumetPreviewer/DocumentPreviewer';
import { useUpdatePageRotationMutation } from '../../../__generated__/graphql';
import useEntryPages from '../../../containers/Timeline/useEntryPages';

type Props = {
  documentId: bigint;
  containerId: string;
  style: any;
  pageHeader: any;
  sharedZoom: number;
  setDocumentPageCount?: (count: number) => void;
};

type PageRotation = { id: string; rotation_angle: number };

export default function PageScroller({
  documentId,
  containerId,
  style,
  pageHeader,
  sharedZoom,
  setDocumentPageCount = () => {},
}: Props) {
  const DEFAULT_WIDTH = 550;
  const queryClient = useQueryClient();

  const { isLoading: isLoadingRotations, data: entryPages } = useEntryPages({
    entryID: documentId,
  });
  const [pageRotationMutation] = useUpdatePageRotationMutation();

  useEffect(() => {
    if (entryPages?.length) {
      setDocumentPageCount(entryPages.length);
    }
  }, [entryPages, setDocumentPageCount]);

  const saveRotation = async (pageID: string, newRotation: number) => {
    try {
      pageRotationMutation({
        variables: {
          data: { pageID, rotation_angle: newRotation },
        },
      });
      queryClient.cancelQueries(['pages', documentId]);
      const previousPages = queryClient.getQueryData(['pages', documentId]);
      const newPagesArr = previousPages as Array<PageRotation>;
      const pageIndex = newPagesArr.findIndex((p) => +p.id === +pageID);
      newPagesArr[pageIndex] = {
        ...newPagesArr[pageIndex],
        rotation_angle: newRotation,
      };
      queryClient.setQueryData(['pages', documentId], newPagesArr);
      toast.success('Page rotation saved');
    } catch (error) {
      toast.error('Failed to save page rotation');
      Sentry.captureException(error);
    }
  };

  const getVisiblePageId = useCallback(() => {
    const images = Array.from(document.querySelectorAll('.image')) as HTMLImageElement[];

    const container = document.getElementById(containerId)!;
    const containerRect = container.getBoundingClientRect();
    const containerArea = containerRect.width * containerRect.height;
    let maxVisibleArea = 0;
    let mostVisiblePage: HTMLImageElement | null = null;
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      const rect = image.getBoundingClientRect();
      const visibleArea =
        ((Math.min(rect.right, containerRect.right) - Math.max(rect.left, containerRect.left)) *
          (Math.min(rect.bottom, containerRect.bottom) - Math.max(rect.top, containerRect.top))) /
        containerArea;
      if (visibleArea > maxVisibleArea) {
        maxVisibleArea = visibleArea;
        mostVisiblePage = image;
      }
    }
    return mostVisiblePage?.id;
  }, []);

  const rotateClockwise = () => {
    const pageID = getVisiblePageId();
    const currentRotation =
      (entryPages as Array<PageRotation>)?.find((p) => String(p.id) === pageID)?.rotation_angle ??
      0;
    if (pageID) {
      saveRotation(pageID, currentRotation === 270 ? 0 : currentRotation + 90);
    }
  };

  const rotateCounterClockwise = () => {
    const pageID = getVisiblePageId();
    const currentRotation =
      (entryPages as Array<PageRotation>)?.find((p) => String(p.id) === pageID)?.rotation_angle ??
      0;
    if (pageID) {
      saveRotation(pageID, currentRotation === 0 ? 270 : currentRotation - 90);
    }
  };

  const [currentPageID, setCurrentPageID] = useState<string | null>(
    entryPages?.length > 0 ? entryPages[0].id : null,
  );
  const scrollRef = useRef<HTMLDivElement>(null);

  const getCurrentPageID = useCallback(
    () => setCurrentPageID(getVisiblePageId() ?? null),
    [getVisiblePageId],
  );

  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }
    scrollRef.current.addEventListener('scrollend', getCurrentPageID);
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scrollend', getCurrentPageID);
      }
    };
  }, [scrollRef, entryPages]);

  return (
    <div style={{ display: 'flex' }} id={containerId}>
      <div
        id="scrollable-viewer"
        className="scrollable-viewer"
        ref={scrollRef}
        style={{
          ...style,
          overflowX: 'scroll',
          // the pages have some padding, to avoid x scroll by default
          width: DEFAULT_WIDTH + 35,
        }}
      >
        {documentId && (
          <DocumentPreviewer
            zoom={sharedZoom}
            documentId={documentId}
            currentPageID={currentPageID}
            isLoadingRotations={isLoadingRotations}
            hideBlanks={true}
            pageHeader={pageHeader}
            rotateCounterClockwise={rotateCounterClockwise}
            rotateClockwise={rotateClockwise}
          />
        )}
      </div>
    </div>
  );
}
