import { AddCircleOutline } from '@mui/icons-material';
import { Box, MenuItem, Typography } from '@mui/material';

type Props = {
  option: {
    id: string;
    value: string;
    isNewValue?: boolean;
    label?: string;
  };
  onClick?: () => void;
  selected?: boolean;
};

export default function AutoCompleteRenderOption({ option, ...rest }: Props) {
  return (
    <MenuItem
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      key={option?.id ?? option?.value ?? option}
      sx={{
        fontSize: '14px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        backgroundColor: rest.selected ? 'lightgrey' : 'white',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={{
            flex: '1 1 auto',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {option?.label ?? option?.value ?? option ?? ''}
        </Box>
        {option.isNewValue && (
          <Box sx={{ display: 'flex', alignItems: 'center', flex: '0 0 auto' }}>
            <AddCircleOutline sx={{ color: 'primary.dark', mr: '0.25rem' }} />
            <Typography
              sx={{
                fontSize: '0.9rem',
                color: 'primary.dark',
                fontWeight: 600,
              }}
            >
              Add
            </Typography>
          </Box>
        )}
      </Box>
    </MenuItem>
  );
}
