/* eslint-disable react/require-default-props */
import React, { ReactElement } from 'react';
import { Typography, Divider, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DialogModal from '../common/DialogModal';

function DialogDivider() {
  return (
    <Divider
      sx={{
        marginLeft: '-1.5rem', // Hacky way to make the divider span the entire width of the dialog part 1
        width: 'calc(100% + 3rem)', // Hacky way to make the divider span the entire width of the dialog part 2
      }}
    />
  );
}

type DialogButtonProps = {
  buttonSx?: React.CSSProperties;
  disabled: boolean;
  text: string;
  backgroundColor: string;
  fontColor: string;
  onClick: () => void;
};

function DialogButton({
  buttonSx = {},
  text,
  disabled,
  backgroundColor,
  fontColor,
  onClick,
}: DialogButtonProps) {
  return (
    <Button
      variant="contained"
      disabled={disabled}
      sx={{
        width: '45%',
        height: '2.5rem',
        color: fontColor,
        backgroundColor: backgroundColor,
        alignContent: 'center',
        ...buttonSx,
      }}
      onClick={onClick}
    >
      <Typography variant="h5" color={fontColor}>
        {text}
      </Typography>
    </Button>
  );
}

type TemplatePageProps = {
  isOpen: boolean;
  onModalClose: () => void;
  title: string;
  subtitle: string;
  leftButtonText: string;
  rightButtonText: string;
  Content: ReactElement<any, any>;
  contentSx?: React.CSSProperties;
  paperSx?: React.CSSProperties;
  rightButtonDisabled: boolean;
  rightOnButtonClick: () => void;
};

export default function TemplatePage({
  isOpen,
  onModalClose,
  title,
  subtitle,
  leftButtonText,
  rightButtonText,
  Content,
  contentSx = {},
  paperSx = {},
  rightButtonDisabled = true,
  rightOnButtonClick,
}: TemplatePageProps) {
  const theme = useTheme();
  return (
    <DialogModal
      open={isOpen}
      onClose={onModalClose}
      paperSx={paperSx}
      contentSx={{ ...contentSx, paddingBottom: '0rem' }}
      header={
        <Typography variant="h3" style={{ marginTop: '0.25rem' }}>
          {title}
        </Typography>
      }
      content={
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <Typography variant="subtitle">{subtitle}</Typography>
          <DialogDivider />
          {Content}
          <DialogDivider />
        </div>
      }
      actions={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
            alignContent: 'center',
            padding: '0.75rem',
            paddingBottom: '0.75rem',
          }}
        >
          <DialogButton
            text={leftButtonText}
            backgroundColor={theme.palette.secondary.contrastText}
            fontColor={theme.palette.primary.main}
            disabled={false}
            buttonSx={{
              color: theme.palette.navigationGrey.main,
              border: 'solid',
              borderWidth: '0.1rem',
              '&:hover': {
                color: theme.palette.mediumGrey.main,
                backgroundColor: theme.palette.secondary.contrastText,
              },
              '&:focus': {
                color: theme.palette.mediumGrey.main,
                backgroundColor: theme.palette.secondary.contrastText,
                border: 'solid !important',
              },
            }}
            onClick={onModalClose}
          />
          <DialogButton
            text={rightButtonText}
            backgroundColor={theme.palette.primary.light}
            fontColor={theme.palette.primary.contrastText}
            disabled={rightButtonDisabled}
            buttonSx={{
              '&:disabled': {
                backgroundColor: theme.palette.navigationGrey.main,
              },
            }}
            onClick={rightOnButtonClick}
          />
        </div>
      }
    />
  );
}
