export default function Preview(props: any) {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6668 8.75033V5.66699C14.6668 4.26686 14.6668 3.5668 14.3943 3.03202C14.1547 2.56161 13.7722 2.17916 13.3018 1.93948C12.767 1.66699 12.067 1.66699 10.6668 1.66699H5.3335C3.93336 1.66699 3.2333 1.66699 2.69852 1.93948C2.22811 2.17916 1.84566 2.56161 1.60598 3.03202C1.3335 3.5668 1.3335 4.26686 1.3335 5.66699V14.3337C1.3335 15.7338 1.3335 16.4339 1.60598 16.9686C1.84566 17.439 2.22811 17.8215 2.69852 18.0612C3.2333 18.3337 3.93336 18.3337 5.3335 18.3337H7.5835M16.3335 18.3337L15.0835 17.0837M15.9168 15.0003C15.9168 16.6112 14.611 17.917 13.0002 17.917C11.3893 17.917 10.0835 16.6112 10.0835 15.0003C10.0835 13.3895 11.3893 12.0837 13.0002 12.0837C14.611 12.0837 15.9168 13.3895 15.9168 15.0003Z"
        stroke={props?.style?.color ?? 'var(--color-icon-grey)'}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
