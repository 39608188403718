import { Card, CardHeader, Typography, Popover } from '@mui/material';
import React, { useState } from 'react';
import FilterButton from '../../../components/common/FilterButton';

export default function FilterPopoverCard(props) {
  const {
    title = '',
    children,
    width = 500,
    height = 'auto',
    overflowY,
    filtersApplied = false,
    anchorOrigin = {
      vertical: 'top',
      horizontal: 'right',
    },
    transformOrigin = {
      vertical: 'top',
      horizontal: 'left',
    },
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setIsMenuOpen(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  return (
    <>
      <FilterButton onClick={handlePopoverOpen} filterCount={filtersApplied ? 1 : 0} dot={true} />
      <Popover
        id="filter-popover"
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        sx={{
          '&:hover': {
            border: 'none',
          },
          '& .MuiPaper-root': {
            borderRadius: 3,
            border: 'none',
            padding: 0.5,
          },
        }}
      >
        <Card
          variant="outlined"
          sx={{
            width,
            height,
            '&:hover': {
              border: 'none',
            },
            overflowY,
          }}
        >
          {title !== '' && (
            <CardHeader
              title={
                <Typography sx={{ color: 'themeDuplicates.dark' }} variant="subtitle1">
                  {title}
                </Typography>
              }
            />
          )}
          {React.cloneElement(children, { onClose: handlePopoverClose })}
        </Card>
      </Popover>
    </>
  );
}
