import { Box, Typography } from '@mui/material';

function NoteInfo({ displayedName, pageNumber }) {
  return (
    <Box
      sx={{
        fontSize: '0.65rem',
        p: '0px',
        pt: '4px',
        flex: 33,
        mx: '0.7rem',
        display: 'flex',
        justifyContent: 'end',
        textAlign: 'right',
      }}
    >
      <Typography
        sx={{
          fontSize: '0.65rem',
          p: 0,
          flexGrow: 1,
          minWidth: 'max-content',
          maxHeight: 1,
          marginRight: '0.2rem',
          fontWeight: 400,
          opacity: '60%',
        }}
        color="text.secondary"
        component="span"
      >
        {`Page ${pageNumber} of`}
      </Typography>
      <Typography
        sx={{
          fontSize: '0.65rem',
          p: 0,
          overflow: 'hidden',
          fontWeight: 600,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        component="span"
      >
        {displayedName}
      </Typography>
    </Box>
  );
}

export default NoteInfo;
