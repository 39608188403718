import React from 'react';
import Theme from '../../theme';

interface CautionProps {
  style?: React.CSSProperties;
}

export default function Caution({ style = {} }: CautionProps) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <path
        d="M9.45059 7.11882V9.95215M9.45059 12.7855H9.45767M8.46977 3.50045L2.64381 13.5635C2.32067 14.1216 2.1591 14.4007 2.18298 14.6298C2.20381 14.8295 2.30848 15.0111 2.47094 15.1292C2.6572 15.2646 2.97968 15.2646 3.62463 15.2646H15.2766C15.9215 15.2646 16.244 15.2646 16.4302 15.1292C16.5927 15.0111 16.6974 14.8295 16.7182 14.6298C16.7421 14.4007 16.5805 14.1216 16.2574 13.5635L10.4314 3.50045C10.1094 2.94429 9.94843 2.66622 9.73839 2.57282C9.55517 2.49135 9.34601 2.49135 9.1628 2.57282C8.95275 2.66622 8.79176 2.94429 8.46977 3.50045Z"
        stroke={Theme.palette.themeOrange.main}
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
