import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getTimeToNow, formatDisplayDate } from '../../library/utilities/useDates';
import TemplateSetting from './TemplateSetting';

/**
 * Component to render the notes in a table view.
 */
function TemplateTableDisplay({ templates = [], currentUser, refetchTemplates }) {
  function displayTableDocuments() {
    const docArr = [];
    templates.forEach((doc) => {
      docArr.push(
        <TableDocument
          document={doc}
          currentUser={currentUser}
          refetchTemplates={refetchTemplates}
          key={doc.templateId}
        />,
      );
    });
    return docArr;
  }
  return (
    <TableContainer component={Paper} sx={{ width: '100%' }}>
      <Table
        sx={{
          minWidth: 550,
          overflow: 'hidden',
          width: '100%',
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell width="34%" align="left">
              File Name
            </TableCell>
            <TableCell width="16%" align="left">
              Last Edited
            </TableCell>
            <TableCell width="16%" align="left">
              Date Created
            </TableCell>
            <TableCell width="17%" align="left">
              Owner
            </TableCell>
            <TableCell width="5%" align="left">
              Visibility
            </TableCell>
            <TableCell width="5%" align="left" />
          </TableRow>
        </TableHead>
        <TableBody>{templates?.length > 0 && displayTableDocuments()}</TableBody>
      </Table>
    </TableContainer>
  );
}

/**
 * The table row component for each note.
 *
 */
function TableDocument(
  props = {
    document: {
      reportName: '',
      templateName: '',
      templateId: '',
      createDate: '',
      lastEdit: '',
      familyName: '',
      givenName: '',
      private: '',
      username: '',
    },
    // refetchTemplates, // this is not defined and would throw error if this was ever reached.
  },
) {
  const navigate = useNavigate();
  const { document, currentUser, refetchTemplates } = props;
  const {
    templateName,
    templateId,
    createDate,
    lastEdit,
    familyName,
    givenName,
    private: isPrivate,
    username,
  } = document;
  const toolTipText = `This template is ${isPrivate === 1 ? 'private' : 'shared'}`;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  if (isPrivate === 0 || username === currentUser) {
    return (
      <TableRow
        key={templateId}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: isMenuOpen ? '#FFFFFF' : '#b3d2ff',
          },
        }}
        onClick={() => navigate(`${templateId}/${templateName}`)}
      >
        <TableCell
          component="th"
          scope="row"
          align="left"
          sx={{
            maxWidth: 20,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {templateName}
        </TableCell>
        <TableCell align="left">{getTimeToNow(lastEdit)}</TableCell>
        <TableCell align="left">{formatDisplayDate(createDate)}</TableCell>
        <TableCell align="left">{`${givenName} ${familyName}`}</TableCell>
        <TableCell align="left">
          <Tooltip title={toolTipText}>
            {isPrivate === 1 ? <VisibilityOff /> : <Visibility />}
          </Tooltip>
        </TableCell>
        <TableCell
          align="right"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {currentUser === username && (
            <TemplateSetting
              template={document}
              refetchTemplates={refetchTemplates}
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
            />
          )}
        </TableCell>
      </TableRow>
    );
  }
}

TemplateTableDisplay.propTypes = {
  templates: PropTypes.array,
  currentUser: PropTypes.string,
};

export default TemplateTableDisplay;
