import CloseIcon from '@mui/icons-material/Close';
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box } from '@mui/system';
import React from 'react';

const exampleNote = {
  title: 'Example Note',
  physician: 'Dr. John Doe',
  date: 'Jan 01, 1970',
  document: 'example.pdf',
  page: '1',
  note: 'This is an example note',
  notesource: 'Go To Source',
};

export default function NewTableBuilder(props) {
  const {
    open,
    onClose,
    notes,
    onSubmit,
    columns = ['Title', 'Name', 'Date', 'Document', 'Page', 'Note', 'Note Source'],
  } = props;
  const [selectedColumns, setSelectedColumns] = React.useState(columns);

  const onRemoveSelectedColumn = (column) =>
    setSelectedColumns((prev) => prev.filter((i) => i !== column));

  const onAddSelectedColumn = (column) => setSelectedColumns((prev) => [...prev, column]);

  const availableColumns = columns.filter((i) => !selectedColumns.includes(i));
  return (
    <Dialog onClose={onClose} aria-labelledby="newTable-builder-dialog" open={open} maxWidth="lg">
      <DialogTitle
        id="newTable-builder-title"
        onClose={onClose}
        sx={{ fontSize: '1.2rem', padding: '16px', ml: '16px' }}
      >
        <Typography>Create Table</Typography>
        <FormHelperText>
          Click the “+” button to add titles to “Your Table”. Click and drag titles to re-arrage
          them in your table.
        </FormHelperText>
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 32,
            top: 16,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        style={{
          padding: '2rem',
          display: 'flex',
          flexDirection: 'row',
          mr: '1rem',
          minHeight: '416px',
          minWidth: '1030px',
        }}
      >
        <Box sx={{ borderRight: 'thin solid black', pr: '2rem' }}>
          <Typography color="primary.light" variant="caption" sx={{ mb: '1rem' }}>
            Available Columns
          </Typography>
          <Box mt="16px">
            {availableColumns.length > 0 ? (
              availableColumns.map((i) => (
                <SelectionBox
                  text={i}
                  key={i}
                  sx={{ mt: '8px' }}
                  isAdding
                  onClick={onAddSelectedColumn}
                />
              ))
            ) : (
              <Box sx={{ fontSize: '12px', textAlign: 'center' }}>All columns in use</Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            ml: '2rem',
          }}
        >
          <Box mb="16px">
            <Typography color="primary.light" variant="caption">
              Table Column Order
            </Typography>
            <Box display="flex" mt="16px">
              {selectedColumns.map((i) => (
                <SelectionBox
                  text={i}
                  key={i}
                  sx={{ mr: '8px' }}
                  onClick={onRemoveSelectedColumn}
                  empty
                />
              ))}
              {availableColumns.map((i) => (
                <EmptyBox id={i} key={i} />
              ))}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" mb="8px">
            <Typography color="primary.light" variant="caption">
              Preview
            </Typography>
            <Box sx={{ height: '100%', overflow: 'hidden' }}>
              <table style={{ marginTop: '16px' }}>
                <th>
                  {selectedColumns.map((i) => (
                    <td
                      key={i}
                      style={{
                        border: 'thin solid black',
                        padding: '12px',
                        width: '126px',
                        fontSize: '14px ',
                      }}
                    >
                      {i}
                    </td>
                  ))}
                  <tr>
                    {selectedColumns?.map((i) => (
                      <td
                        key={i}
                        style={{
                          border: 'thin solid black',
                          padding: '12px',
                          maxWidth: '126px',
                          fontSize: '12px',
                          fontWeight: '500',
                          overflowWrap: 'break-word',
                        }}
                      >
                        {i === 'Name'
                          ? exampleNote.physician
                          : exampleNote[i.toLowerCase().replace(' ', '')]}
                      </td>
                    ))}
                  </tr>
                </th>
              </table>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSubmit({ notes, columns: selectedColumns })}
        >
          Insert
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function SelectionBox(props) {
  const { text, onClick, sx, isAdding } = props;

  return (
    <Box key={text} sx={{ ...sx }}>
      <Badge
        color="primary"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          padding: '0',
          '& > .MuiBadge-badge': {
            padding: '1px',
            marginLeft: '-4px',
          },
        }}
        badgeContent={
          <Box
            sx={{
              cursor: 'pointer',
              width: '100%',
              height: '100%',
              padding: '0',
            }}
            onClick={() => onClick && onClick(text)}
          >
            {isAdding ? (
              <AddIcon sx={{ fontSize: '18px', padding: '0' }} />
            ) : (
              <RemoveIcon sx={{ fontSize: '18px', padding: '0' }} />
            )}
          </Box>
        }
      >
        <Box
          sx={{
            border: 'thin solid black',
            width: '120px',
            height: '45px',
            borderRadius: '18px',
            textAlign: 'center',
            marginRight: '-4px',
            marginLeft: '4px',
          }}
        >
          <Typography sx={{ fontSize: '14px', lineHeight: '45px' }}>{text}</Typography>
        </Box>
      </Badge>
    </Box>
  );
}

function EmptyBox(props) {
  const { id } = props;
  return (
    <Box
      key={`empty-${id}`}
      sx={{
        border: 'thin dashed black',
        width: '120px',
        height: '45px',
        borderRadius: '18px',
        textAlign: 'center',
        mr: '8px',
        marginLeft: '4px',
      }}
    />
  );
}
