import { Theme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { SetStateAction, Dispatch } from 'react';
import HelpIcon from './HelpIcon';

type FileSelectProps = {
  setHovering: Dispatch<SetStateAction<boolean>>;
  hovering: boolean;
  setDragging: Dispatch<SetStateAction<boolean>>;
  dragging: boolean;
  handleDrop: (files: FileList | null) => void;
  clickUpload: () => void;
  acceptedFormatsString: string;
  theme: Theme;
};

export default function FileSelect({
  setHovering,
  hovering,
  setDragging,
  dragging,
  handleDrop,
  clickUpload,
  acceptedFormatsString,
  theme,
}: FileSelectProps) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0.75rem',
        display: 'inline-flex',
        backgroundColor: dragging ? '#DCE4FE' : 'inherit',
      }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onDragEnter={(event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(true);
      }}
      onDragOver={(event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(true);
      }}
      onDragLeave={(event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
      }}
      onDrop={(event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
        handleDrop(event.dataTransfer.files);
        event.dataTransfer.clearData();
      }}
    >
      <svg
        width="1.66667rem"
        height="1.66667rem"
        viewBox="0 0 20 20"
        fill={dragging ? theme.palette.primary.main : 'none'}
        onClick={clickUpload}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.66675 13.3333L10.0001 10M10.0001 10L13.3334 13.3333M10.0001 10V17.5M16.6667 13.9524C17.6847 13.1117 18.3334 11.8399 18.3334 10.4167C18.3334 7.88536 16.2814 5.83333 13.7501 5.83333C13.568 5.83333 13.3976 5.73833 13.3052 5.58145C12.2185 3.73736 10.2121 2.5 7.91675 2.5C4.46497 2.5 1.66675 5.29822 1.66675 8.75C1.66675 10.4718 2.36295 12.0309 3.48921 13.1613"
          stroke={dragging ? theme.palette.primary.main : theme.palette.subHeading.main}
          strokeWidth="1.75px"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div
        style={{
          alignSelf: 'stretch',
          height: '3.25rem',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '0.25rem',
          display: 'flex',
        }}
      >
        <div
          style={{
            alignSelf: 'stretch',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '0.25rem',
            display: 'inline-flex',
          }}
        >
          <input
            id="click-file-upload"
            type="file"
            style={{ display: 'none' }}
            onChange={(event) => handleDrop(event.target.files)}
            multiple
          />
          <Typography
            variant="h6"
            color={hovering ? theme.palette.primary.main : theme.palette.primary.dark}
            sx={{
              fontWeight: '700',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={clickUpload}
          >
            Click to upload
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: `${theme.palette.subHeading.main}`,
            }}
          >
            or drag and drop
          </Typography>
        </div>
        <Typography variant="subtitle">
          Accepted formats
          <HelpIcon helpText={acceptedFormatsString} />
        </Typography>
      </div>
    </Box>
  );
}
