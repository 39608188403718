import { FormControl, MenuItem, Select } from '@mui/material';
import useNotesStore from '../../../Notes/useNotesStore';
import FilterControl from '../FilterControl';

export default function OwnerFilter() {
  const showOnlyMyNotes = useNotesStore((state) => state.showOnlyMyNotes);
  const setShowOnlyMyNotes = useNotesStore((state) => state.setShowOnlyMyNotes);

  const handleOwnedByFilterChange = (event) => {
    if (event.target.value.includes('showOnlyMyNotes')) {
      setShowOnlyMyNotes(true);
    } else {
      setShowOnlyMyNotes(false);
    }
  };
  return (
    <FilterControl
      control={
        <FormControl variant="standard" sx={{ width: '100%', maxWidth: '420px', marginTop: 1 }}>
          <Select
            variant="outlined"
            value={showOnlyMyNotes ? 'showOnlyMyNotes' : 'allNotes'}
            onChange={handleOwnedByFilterChange}
            sx={{ width: '97%' }}
          >
            <MenuItem key="showOnlyMyNotes" value="showOnlyMyNotes" style={{ height: 36 }}>
              <div style={{ fontSize: '0.875rem' }}>My Notes</div>
            </MenuItem>
            <MenuItem key="allNotes" value="allNotes" style={{ height: 36 }}>
              <div style={{ fontSize: '0.875rem' }}>All Shared Notes</div>
            </MenuItem>
          </Select>
        </FormControl>
      }
    />
  );
}
