import React, { useCallback } from 'react';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import PageCardFlag from '../../../components/icons/PageCardFlag';
import { UpdatePageFavouriteMutationVariables } from '../../../__generated__/graphql';

function PageFlagControl({ pageID, isFavourite, onClick }: Props) {
  const handleChangeFavouriteMark = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      onClick({
        data: {
          pageID: pageID,
          isFavourite: isFavourite ? 0 : 1,
        },
      });
    },
    [onClick, pageID, isFavourite],
  );

  return (
    <Tooltip title={isFavourite ? 'Unflag' : 'Flag as Important'} placement="top">
      <Box sx={{ color: 'modernGrey.default', height: '14px', display: 'flex' }}>
        <PageCardFlag
          important={isFavourite}
          width="0.9rem"
          height="0.9rem"
          style={{ cursor: 'pointer', marginLeft: '0.3rem' }}
          onClick={handleChangeFavouriteMark}
        />
      </Box>
    </Tooltip>
  );
}

type Props = {
  pageID: number;
  isFavourite: boolean;
  onClick: (params: UpdatePageFavouriteMutationVariables) => void;
  sx?: SxProps;
};

export default React.memo(PageFlagControl);
