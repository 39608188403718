import { useState } from 'react';
import useDuplicateSets from './useDuplicateSets';

function useCompareSimilarPages() {
  const { currentDuplicateSet } = useDuplicateSets();
  const [selectedPages, setSelectedPages] = useState([]);

  const clearSelection = () => setSelectedPages([]);
  const selectFirst = () => {
    const unresolved = currentDuplicateSet?.similarPages?.filter(
      (page) => page.isDuplicate === null,
    );
    if (unresolved?.length > 0) {
      setSelectedPages([unresolved[0].pageID]);
    } else if (currentDuplicateSet?.similarPages?.length > 0) {
      setSelectedPages([currentDuplicateSet?.similarPages[0].pageID]);
    } else {
      setSelectedPages([]);
    }
  };
  const selectAll = () =>
    setSelectedPages(currentDuplicateSet?.similarPages.map((page) => page.pageID));

  return {
    originalPage: currentDuplicateSet?.originalPage,
    similarPages: currentDuplicateSet?.similarPages,
    selectedPages,
    clearSelection,
    selectFirst,
    selectAll,
    togglePageSelection: (pageID) => {
      setSelectedPages((prev) => {
        const pageIndex = prev.indexOf(pageID);
        if (pageIndex > -1) {
          prev.splice(pageIndex, 1);
          return [...prev];
        }
        return [...prev, pageID];
      });
    },
  };
}

export default useCompareSimilarPages;
