import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const useZoomStore = create(
  persist(
    (set) => ({
      allZoom: 1,
      allPanX: 0,
      allPanY: 0,
      height: Infinity,
      width: Infinity,

      setAllZoom: (newZoom) => {
        set({ allZoom: newZoom });
      },

      setAllPanX: (newPan) => {
        set({ allPanX: newPan });
      },
      setAllPanY: (newPan) => {
        set({ allPanY: newPan });
      },

      setHeight: (height) => {
        set({ height });
      },

      setWidth: (width) => {
        set({ width });
      },
    }),
    {
      name: 'case-zoom-storage',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export default useZoomStore;
