import { useCallback, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { gql, useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import {
  UpdateEntryDateInput,
  useUpdateEntryDateMutation,
  useUpdateEntrySourceMutation,
  TagDisplayObject,
  GetCaseSourceFiltersDocument,
} from '../../../__generated__/graphql';

export default function useUpdateEntryDetails() {
  const [dateMutation, dateState] = useUpdateEntryDateMutation();
  const [sourceMutation, sourceState] = useUpdateEntrySourceMutation();
  const client = useApolloClient();
  const queryClient = useQueryClient();
  const updateEntryDate = useCallback(
    (params: UpdateEntryDateInput) => {
      const { entryID, date } = params;

      try {
        return dateMutation({
          variables: {
            data: params,
          },
          update: (cache) => {
            const entry: { id: number; entry_date: Date; pages: { id: number }[] } | null =
              cache.readFragment({
                id: cache.identify({
                  __typename: 'TimelineEntryObject',
                  id: entryID,
                }),
                fragment: entryUpdateFragment,
              });

            if (!entry) {
              return;
            }
            cache.modify({
              id: cache.identify({
                __typename: 'TimelineEntryObject',
                id: entryID,
              }),
              fields: {
                entry_date() {
                  return date;
                },
              },
            });

            // Update all pages associated with the entry
            entry.pages.forEach((page) => {
              cache.modify({
                id: cache.identify({
                  __typename: 'PageObject',
                  id: page.id,
                }),
                fields: {
                  corrected_page_date() {
                    return date;
                  },
                },
              });
            });
          },
        });
      } catch (error) {
        toast.error(
          'A problem occured while updating Entry date. If issues persist, please try refreshing the page.',
        );
        Sentry.captureException(error);
        throw error;
      }
    },
    [dateMutation, client],
  );

  const updateEntrySource = useCallback(
    ({
      entryID,
      caseID,
      source,
    }: {
      entryID: number;
      caseID: string;
      source: TagDisplayObject;
    }) => {
      try {
        return sourceMutation({
          variables: {
            data: { entryID, sourceID: source.id },
          },

          update: (cache) => {
            const entry: { id: number; entry_date: Date; pages: { id: number }[] } | null =
              cache.readFragment({
                id: cache.identify({
                  __typename: 'TimelineEntryObject',
                  id: entryID,
                }),
                fragment: entryUpdateFragment,
              });

            if (!entry) {
              return;
            }

            // Case source filter contains the source id
            const sourceID = source.id;
            const caseSources: { sourceTypesForFilter: TagDisplayObject[] } | null | null =
              cache.readQuery({
                query: GetCaseSourceFiltersDocument,
                variables: { caseID },
              });
            if (!caseSources?.sourceTypesForFilter) {
              client.refetchQueries({
                include: [GetCaseSourceFiltersDocument],
              });
            } else if (
              !caseSources.sourceTypesForFilter.some((source: any) => source.id === sourceID)
            ) {
              cache.modify({
                fields: {
                  sourceTypesForFilter(existingTags = []) {
                    const newTagRef = cache.writeFragment({
                      data: source,
                      fragment: gql`
                        fragment NewTag on TagDisplayObject {
                          id
                          label
                          name
                          origin
                          type
                        }
                      `,
                    });
                    return [...existingTags, newTagRef];
                  },
                },
              });
            }

            // Update all pages associated with the entry
            entry.pages.forEach((page) => {
              cache.modify({
                id: cache.identify({
                  __typename: 'PageObject',
                  id: page.id,
                }),
                fields: {
                  source() {
                    return {
                      id: sourceID,
                      name: source.name,
                      type: 'source',
                      confidence: 0,
                    };
                  },
                },
              });
            });
          },
        });
      } catch (error) {
        toast.error(
          'A problem occured while updating Entry Source. If issues persist, please try refreshing the page.',
        );
        Sentry.captureException(error);
        throw error;
      }
    },
    [sourceMutation, client],
  );

  return useMemo(() => {
    return { updateEntryDate, updateEntrySource, sourceState, dateState };
  }, [updateEntryDate, updateEntrySource, sourceState, dateState]);
}

const entryUpdateFragment = gql`
  fragment EntryDate on TimelineEntryObject {
    id
    entry_date
    pages {
      id
    }
  }
`;
