/* eslint-disable consistent-return */
import * as Sentry from '@sentry/react';
import { DocumentEditorContainerComponent } from '@syncfusion/ej2-react-documenteditor';
import '@syncfusion/ej2-icons/styles/fluent.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import useDocumentNames from '../../containers/Case/gql/useDocumentNames';
import useNotesStore from '../../containers/Notes/useNotesStore';
import { AuthContext } from '../../library/contexts/AuthContext';
import apiUrl from '../../library/utilities/apiUrl';
import convertRemToPixels from '../../library/utilities/convertRemToPx';
import { formatDisplayDate } from '../../library/utilities/useDates';
import MatchHeaderFieldsModal from '../../containers/ReportEditor/MatchHeaderFieldsModal';
import NewTableBuilder from '../../containers/ReportEditor/NewTableBuilder';
import './index.css';
import { processInChunks } from '../../utils/arrayUtils';
// Note that ej2 does not work well with React component importation as a child of its components.
// As such, Css importation is a much cleaner method.

const defaultColumns = ['Title', 'Name', 'Date', 'Document', 'Page', 'Note', 'Note Source'];

/**
 * Actual document editor component. Used to open and edit templates and final reports.
 */
const DocumentEditor = forwardRef(
  //(props, ref)
  (
    // This is the props object
    { uploadObject, toolbarClick, loading, setLoading, reportOpen, noteSidebarOpen, isTemplate },
    // This is the second parameter
    ref,
  ) => {
    //Ref to keep track of document content whenever it changes
    const documentContent = useRef(null);
    const presignedS3Url = useRef(null);
    const { givenName, familyName } = useContext(AuthContext);
    const authKey = axios.defaults.headers.common.Authorization;
    const { caseID } = useParams();
    const documentNames = useDocumentNames(caseID);

    const [problemMatchingDocuments, setProblemMatchingDocuments] = useState(null);
    const [newTableCreationOpen, setNewTableCreationOpen] = useState(false);

    const setNotesInsertLoading = useNotesStore((state) => state.setNotesInsertLoading);
    const setTotalNotesToInsert = useNotesStore((state) => state.setTotalNotesToInsert);
    const resetCurrentlyInsertedNotes = useNotesStore((state) => state.resetCurrentlyInsertedNotes);
    const incrementCurrentlyInsertedNotes = useNotesStore(
      (state) => state.incrementCurrentlyInsertedNotes,
    );

    useEffect(() => {
      function resize() {
        ref.current.documentEditor.resize();
      }
      if (!loading && ref.current) {
        resize();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportOpen, ref, loading, noteSidebarOpen]);

    const insertFormattedText = (text, formatting) => {
      const defaultFormat = {
        bold: false,
        italic: false,
        underline: false,
        fontSize: 11,
      };
      const { startOffset } = ref.current.documentEditor.selection;
      ref.current.documentEditor.editor.insertText(text);
      const { endOffset } = ref.current.documentEditor.selection;

      ref.current.documentEditor.selection.select(startOffset, endOffset);

      for (const key of Object.keys(formatting)) {
        ref.current.documentEditor.selection.characterFormat[key] = formatting[key];
      }
      ref.current.documentEditor.selection.select(endOffset, endOffset);
      for (const key of Object.keys(formatting)) {
        ref.current.documentEditor.selection.characterFormat[key] = defaultFormat[key];
      }
    };

    const getTableColumnNames = () => {
      const currentLocation = ref.current.documentEditor.selection.startOffset;
      const values = currentLocation.split(';');
      const firstRow = [values[0], values[1], 0, 0, 0, 0].join(';');
      ref.current.documentEditor.selection.select(firstRow, firstRow);
      ref.current.documentEditor.selection.selectRow();
      const rowText = ref.current.documentEditor.selection.text;

      const columnNames = rowText.split('\r').filter((name) => name !== '');

      ref.current.documentEditor.selection.select(currentLocation, currentLocation);
      return columnNames;
    };

    const insertTableHeader = (columns) => {
      columns.forEach((column) => {
        insertFormattedText(column, { bold: true, fontSize: 12 });
        moveCursorToNextCell();
      });
    };

    const insertNoteAsTableRow = (
      { title, physician, date, pageID, body, documentName, documentID, pageNumber },
      tableHeaders,
    ) => {
      moveCursorToNextRow();
      incrementCurrentlyInsertedNotes();
      const currentUrl = window.location.href;
      const pageUrl = currentUrl.replace('reports', `documents/${documentID}/${pageID}`);
      tableHeaders.forEach((header) => {
        switch (header.toLowerCase().trim().replace(' ', '')) {
          case 'title':
            ref.current.documentEditor.editor.insertText(title);
            break;
          case 'name':
            ref.current.documentEditor.editor.insertText(physician);
            break;
          case 'date':
            if (date && date !== '0000-00-00') {
              ref.current.documentEditor.editor.insertText(formatDisplayDate(date));
            }
            break;
          case 'document':
            ref.current.documentEditor.editor.insertText(documentName);
            break;
          case 'page':
            ref.current.documentEditor.editor.insertText(pageNumber?.toString() ?? '');
            break;
          case 'note':
            ref.current.documentEditor.editor.insertText(body);
            break;
          case 'notesource':
            ref.current.documentEditor.editor.insertHyperlink(pageUrl, 'Go To Source');
            break;
          default:
            break;
        }
        moveCursorToNextCell();
      });
    };

    const insertNotesIntoExistingTable = async (notes, customHeaders = undefined) => {
      if (!ref.current) {
        return toast.error('Problem loading document editor', {
          toastId: 'doc-editor',
        });
      }
      if (ref.current.documentEditor.selection.contextType.indexOf('TableText') === -1) {
        toast.info('Please place your cursor inside an existing table to insert notes');
        return;
      }

      try {
        // eslint-disable-next-line no-param-reassign
        ref.current.documentEditor.enableLocalPaste = true;
        let headers;
        if (!customHeaders) {
          headers = getTableColumnNames();
          if (
            headers.filter(
              (header) =>
                !defaultColumns.some((col) => col.toLowerCase() === header.toLowerCase().trim()),
            ).length > 0
          ) {
            setProblemMatchingDocuments({
              headers,
              notes,
            });
            return;
          }
        } else {
          headers = customHeaders;
        }
        setNotesInsertLoading(true);
        const allNotes = Object.keys(notes).map((note) => notes[note].length);
        const noteCount = allNotes.reduce((a, b) => a + b, 0);
        const currentPosition = ref.current.documentEditor.selection.startOffset;
        ref.current.documentEditor.editor.insertRow(undefined, noteCount);
        ref.current.documentEditor.selection.select(currentPosition, currentPosition);
        // Insert inital empty table
        ref.current.documentEditor.selection.characterFormat.fontSize = 10;

        const flattenedNotes = Object.values(notes).flat();
        await processInChunks(
          flattenedNotes,
          (note) => {
            insertNoteAsTableRow(
              {
                ...note,
                documentName: documentNames[note.documentID],
              },
              headers,
            );
          },
          1,
        );
        setNotesInsertLoading(false);
        // here and in other places, setTimeout with 0.1 second
        // delay is used to ensure that the loading state is set
        // and loading bars are unmounted before resetting the
        // currently inserted notes counts to 0, avoiding 0/0 display
        setTimeout(() => {
          resetCurrentlyInsertedNotes();
          setTotalNotesToInsert(0);
        }, 200);
      } catch (e) {
        console.log(e);
        Sentry.captureException(e);
        return e;
      }
    };
    //Functions to insert table of notes into report
    const insertNotesTable = async ({ notes, columns }) => {
      if (!ref.current) {
        return toast.error('Problem loading document editor', {
          toastId: 'doc-editor',
        });
      }

      if (ref.current.documentEditor.selection.contextType.indexOf('TableText') !== -1) {
        toast.info('Tables cannot be inserted inside of existing tables.');
        return;
      }
      setNotesInsertLoading(true);
      setNewTableCreationOpen(false);
      //Use setTimeout to wait to insert to ensure loading state is set first
      try {
        // eslint-disable-next-line no-param-reassign
        ref.current.documentEditor.enableLocalPaste = true;
        ref.current.documentEditor.editor.clearFormatting();
        ref.current.documentEditor.editor.insertText('\n');

        const allNotes = Object.keys(notes).map((note) => notes[note].length);
        const noteCount = allNotes.reduce((a, b) => a + b, 0);

        // Insert initial empty table
        ref.current.documentEditor.editor.insertTable(noteCount + 1, columns.length);
        ref.current.documentEditor.selection.characterFormat.fontSize = 10;
        insertTableHeader(columns);

        const flattenedNotes = Object.values(notes).flat();
        await processInChunks(
          flattenedNotes,
          (note) => {
            insertNoteAsTableRow(
              {
                ...note,
                documentName: documentNames[note.documentID],
              },
              columns,
            );
          },
          1,
        );
        setNotesInsertLoading(false);
        setTimeout(() => {
          resetCurrentlyInsertedNotes();
          setTotalNotesToInsert(0);
        }, 200);

        ref.current.documentEditor.editor.insertText('\n');
      } catch (e) {
        console.log(e);
        Sentry.captureException(e);
      }
    };

    const moveCursorToNextCell = () => {
      const { startOffset } = ref.current.documentEditor.selection;
      const values = startOffset.split(';');
      values[3] = parseInt(values[3], 10) + 1;
      const nextCell = [values[0], values[1], values[2], values[3], 0, 0].join(';');
      ref.current.documentEditor.selection.select(nextCell, nextCell);
    };

    const moveCursorToNextRow = () => {
      const { startOffset } = ref.current.documentEditor.selection;
      const values = startOffset.split(';');
      const startOfNextRow = [values[0], values[1], Number(values[2]) + 1, 0, 0, 0].join(';');
      ref.current.documentEditor.selection.select(startOfNextRow, startOfNextRow);
    };

    const insertNotesAsList = async (notes) => {
      if (ref.current.documentEditor.selection.contextType.indexOf('TableText') !== -1) {
        toast.info(
          'Lists cannot be inserted inside of tables. To insert notes as table rows, change your insertion method.',
        );
        return;
      }
      setNotesInsertLoading(true);

      const flattenedNotes = Object.values(notes).flat();
      await processInChunks(
        flattenedNotes,
        (note) => {
          const info = `${documentNames[note.documentID]} | Page ${note.pageNumber}`;

          insertNote(note.title, note.physician, info, note.date, note.body, '');
        },
        1,
      );
      setNotesInsertLoading(false);
      setTimeout(() => {
        resetCurrentlyInsertedNotes();
        setTotalNotesToInsert(0);
      }, 200);
    };

    //Function to insert notes into the report
    const insertNote = (noteTitle, notePhysician, noteInfo, noteDate, noteText, noteImage) => {
      if (!ref.current) {
        return toast.error('Problem loading document editor', {
          toastId: 'doc-editor',
        });
      }
      try {
        incrementCurrentlyInsertedNotes();
        ref.current.documentEditor.enableLocalPaste = true;
        ref.current.documentEditor.editor.clearFormatting();
        ref.current.documentEditor.editor.insertText('\n');

        // insert the note title
        if (noteTitle !== '') {
          insertFormattedText(noteTitle, { bold: true, fontSize: 18 });
          ref.current.documentEditor.editor.insertText('\n');
        }

        // insert the note physician
        if (notePhysician !== '') {
          insertFormattedText(notePhysician, { bold: true, fontSize: 13 });
          ref.current.documentEditor.editor.insertText('\n');
        }

        // insert the note information
        insertFormattedText(noteInfo, { fontSize: 10 });
        ref.current.documentEditor.editor.insertText('\n');

        // insert the note date
        if (!isNaN(new Date(noteDate).getTime())) {
          let date;
          if (noteDate && noteDate !== '0000-00-00') {
            date = formatDisplayDate(noteDate);
          } else {
            date = '';
          }
          ref.current.documentEditor.editor.insertText(date);
          ref.current.documentEditor.editor.insertText('\n');
        }
        // insert the note body
        if (noteText !== '') {
          ref.current.documentEditor.editor.insertText('\n');
          ref.current.documentEditor.editor.insertText(noteText);
          ref.current.documentEditor.editor.insertText('\n');
        }
        // insert the note image
        if (noteImage !== '') {
          const image = new Image();
          image.src = noteImage;
          ref.current.documentEditor.editor.insertText('\n');
          ref.current.documentEditor.editor.insertImage(noteImage, image.width, image.height);
          ref.current.documentEditor.editor.insertText('\n');
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    };

    //Drag and drop functionality for notes
    const [_collectedProps, drop] = useDrop(() => ({
      accept: 'card',
      drop: (item) =>
        insertNote(
          item.text.title,
          item.text.physician,
          item.text.info,
          item.text.date,
          item.text.text,
          item.text.image,
        ),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      hover: (item, monitor) => {
        const clientOffset = monitor.getClientOffset();
        const bounds = document.getElementById('editor-drop').getBoundingClientRect();
        const sx = clientOffset.x - bounds.x;
        const sy = clientOffset.y - 80;
        ref.current.documentEditor.selection.select({
          x: sx,
          y: sy,
        });
      },
    }));

    useEffect(
      () =>
        useNotesStore.subscribe(
          (state) => ({
            notesToInsert: state.notesToInsert,
            singleNoteToInsert: state.singleNoteToInsert,
            notesInsertionOption: state.notesInsertionOption,
            setNotesToInsert: state.setNotesToInsert,
            setSingleNoteToInsert: state.setSingleNoteToInsert,
          }),
          (state) => {
            const {
              singleNoteToInsert,
              notesToInsert = {},
              setSingleNoteToInsert,
              setNotesToInsert,
              notesInsertionOption,
            } = state;
            if (singleNoteToInsert) {
              if (ref.current.documentEditor.selection.contextType.indexOf('TableText') !== -1) {
                insertNotesIntoExistingTable({ notes: [singleNoteToInsert] });
              } else {
                insertNote(
                  singleNoteToInsert.title,
                  singleNoteToInsert.physician,
                  singleNoteToInsert.info,
                  singleNoteToInsert.date,
                  singleNoteToInsert.body,
                  singleNoteToInsert.image,
                );
              }
              setSingleNoteToInsert(null);
            }

            if (notesToInsert) {
              if (notesInsertionOption === 'List') {
                insertNotesAsList(notesToInsert);
              } else if (notesInsertionOption === 'Table') {
                if (ref.current.documentEditor.selection.contextType.indexOf('TableText') !== -1) {
                  insertNotesIntoExistingTable(notesToInsert);
                } else {
                  setNewTableCreationOpen(notesToInsert);
                }
              }

              setNotesToInsert(null);
            }
          },
          { equalityFn: shallow },
        ),
      [],
    );

    // load report or template and generate initial pre-signed url for saving
    useEffect(() => {
      if (ref.current !== null) {
        if (loading) {
          (async () => {
            try {
              let existing;
              if (isTemplate) {
                existing = (await axios.post(`${apiUrl}getTemplate`, uploadObject)).data;
                presignedS3Url.current = (
                  await axios.post(`${apiUrl}saveTemplate`, uploadObject)
                ).data;
              } else {
                const existingDataUrl = (await axios.post(`${apiUrl}getReport`, uploadObject)).data;
                presignedS3Url.current = (
                  await axios.post(`${apiUrl}saveReport`, uploadObject)
                ).data;
                const response = await axios.get(existingDataUrl, {
                  responseType: 'arraybuffer',
                  transformRequest: (data, headers) => {
                    delete headers.common.Authorization;
                    return data;
                  },
                });
                existing = new TextDecoder('utf-8').decode(response.data);
              }
              documentContent.current = existing;
              ref.current.documentEditor.open(existing);
              setLoading(false);
            } catch (err) {
              Sentry.captureException(err);
            }
          })();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current]);

    const refeshUrl = async () => {
      axios
        .post(`${apiUrl}${isTemplate ? 'saveTemplate' : 'saveReport'}`, uploadObject)
        .then((response) => {
          presignedS3Url.current = response.data;
        });
    };

    // Save on unmount
    useEffect(() => () => saveContent(), []);

    // attempt to save if tab is closed (async request, may not complete)
    useEffect(() => {
      const saving = () => {
        saveContent();
      };
      window.addEventListener('unload', saving);
      return () => window.removeEventListener('unload', saving);
    }, []);

    let throttleSaveChanges = false;

    const onContentChanged = () => {
      documentContent.current = ref.current.documentEditor.serialize();
      if (!throttleSaveChanges) {
        saveContent();
        throttleSaveChanges = true;

        setTimeout(() => {
          throttleSaveChanges = false;
        }, 3000);
      }
    };

    const saveContent = async () => {
      if (documentContent.current) {
        const file = new File([new Blob([documentContent.current], { type: 'Sfdt' })], 'test', {
          lastModified: new Date().getTime(),
          type: 'Sfdt',
        });

        if (presignedS3Url.current !== null && presignedS3Url?.current?.fields) {
          const { fields, url } = presignedS3Url.current;
          const form = new FormData();
          Object.keys(fields).forEach((key) => form.append(key, fields[key]));
          form.append('file', file);

          const estimatedContentLength = [...form.entries()].reduce((acc, [_, value]) => {
            if (typeof value === 'string') {
              return acc + value.length;
            }
            if (typeof value === 'object') {
              return acc + value.size;
            }
            return acc;
          }, 0);

          try {
            const response = await fetch(url, {
              method: 'POST',
              body: form,
              // if payload less than 64 bytes (chrome limitation), attempt to complete request if tab closed
              // subtract safe buffer of ~2 bytes for boundary
              keepalive: estimatedContentLength.size < 62 * 1024,
            });
            if (!response.ok) {
              refeshUrl();
            }
          } catch (error) {
            toast.error('Failed to save file.', { toastId: 'save-file' });
            Sentry.captureException(error);
            throw error;
          }
        } else {
          refeshUrl();
        }
      }
    };

    const tooltipInsertTemplate = {
      prefixIcon: 'e-icons template',
      tooltipText: 'Insert Template',
      text: 'Insert Template',
      id: '_template',
    };

    const tooltipOpenNotesSidebar = {
      prefixIcon: 'e-icons e-chevron-right',
      tooltipText: 'Open Notes',
      text: 'Open Notes',
      id: '_note',
    };

    const tooltipCloseNotesSidebar = {
      prefixIcon: 'e-icons e-chevron-left',
      tooltipText: 'Close Notes',
      text: 'Close Notes',
      id: '_note',
    };

    const tooltipExportPDF = {
      prefixIcon: 'e-de-ctnr-download',
      tooltipText: 'Export PDF',
      text: 'Export PDF',
      id: '_exportPDF',
    };
    const tooltipExportDocx = {
      prefixIcon: 'e-de-ctnr-download',
      tooltipText: 'Export Word',
      text: 'Export Word',
      id: '_exportDocx',
    };

    const toolbarItems = isTemplate
      ? [
          'Image',
          'Table',
          'Hyperlink',
          'Bookmark',
          'TableOfContents',
          'Separator',
          'Undo',
          'Redo',
          'Separator',
          'Header',
          'Footer',
          'PageSetup',
          'PageNumber',
          'Break',
          'InsertEndnote',
          'Separator',
          'Find',
          'Comments',
          'Separator',
          tooltipExportPDF,
          tooltipExportDocx,
        ]
      : [
          noteSidebarOpen ? tooltipCloseNotesSidebar : tooltipOpenNotesSidebar,
          tooltipInsertTemplate,
          'Separator',
          'Image',
          'Table',
          'Hyperlink',
          'Bookmark',
          'TableOfContents',
          'Separator',
          'Undo',
          'Redo',
          'Separator',
          'Header',
          'Footer',
          'PageSetup',
          'PageNumber',
          'Break',
          'InsertEndnote',
          'Separator',
          'Find',
          'Comments',
          'Separator',
          tooltipExportPDF,
          tooltipExportDocx,
        ];
    return (
      <div ref={drop} style={{ height: '105%', width: '100%', maxWidth: '100%' }} id="editor-drop">
        <DocumentEditorContainerComponent
          id="container"
          serviceUrl={`${apiUrl}syncfusionController`.concat('/')}
          headers={[{ authorization: authKey }]}
          style={{
            display: loading ? 'none' : 'inherit',
            float: 'right',
          }}
          enableToolbar={true}
          toolbarItems={toolbarItems}
          enableWordExport={true}
          contentChange={onContentChanged}
          ref={ref}
          toolbarClick={toolbarClick}
          currentUser={`${givenName.charAt(0).toUpperCase() + givenName.slice(1)} ${
            familyName.charAt(0).toUpperCase() + familyName.slice(1)
          }`}
          height="100%"
          width={window.innerWidth - convertRemToPixels(5) - (noteSidebarOpen ? 300 : 0)}
        />
        {problemMatchingDocuments && (
          <MatchHeaderFieldsModal
            {...problemMatchingDocuments}
            onSubmit={insertNotesIntoExistingTable}
            onClose={() => setProblemMatchingDocuments(null)}
            defaultColumns={defaultColumns}
          />
        )}

        {newTableCreationOpen && (
          <NewTableBuilder
            open
            notes={newTableCreationOpen}
            onClose={() => setNewTableCreationOpen(false)}
            onSubmit={insertNotesTable}
          />
        )}
      </div>
    );
  },
);

DocumentEditor.displayName = 'DocumentEditor';

export default DocumentEditor;
