import PropTypes from 'prop-types';
import { memo, useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { areEqual, FixedSizeList as List } from 'react-window';
import { shallow } from 'zustand/shallow';
import useDisplayStore from '../../containers/Timeline/useDisplayStore';
import Loading from '../common/Loading';
import DocumentPage from './DocumentPage';
import { useIsFileProcessor } from '../../containers/AccountSettings/useFileProcessing';

const renderPage = memo(
  ({ index, style, data }) => <DocumentPage index={index} style={style} data={data} />,
  areEqual,
);

export default function PDFDocumentList(props) {
  const {
    currentPageIndex,
    onScrollToDifferentPage,
    pages,
    documentZoom,
    documentRotation,
    fetchFile,
    caseID,
    searchResults,
    dismissDocumentEdge,
    pageDepthByPageID,
    groupIDByPageID,
    attachmentIDByPageID,
    isGrouping,
    groupingActionOnClicks,
    isUpdateRequestInProgress,
    getGroupingButtonDisabledStatuses,
  } = props;
  const ref = useRef(null);

  const {
    setLastGroupingScrollPosition,
    lastGroupingScrollPosition,
    windowSize,
    documentPadding,
    documentHeight,
  } = useDisplayStore(
    (state) => ({
      setLastGroupingScrollPosition: state.setLastGroupingScrollPosition,
      lastGroupingScrollPosition: state.lastGroupingScrollPosition,
      windowSize: state.windowSize,
      documentPadding: state.PDFDimensions.documentPadding,
      documentHeight: state.PDFDimensions.documentHeight,
    }),
    shallow,
  );

  useEffect(() => {
    if (isGrouping && pages[0].documentID === lastGroupingScrollPosition?.documentID) {
      if (lastGroupingScrollPosition.position && ref.current) {
        ref.current.scrollTo(lastGroupingScrollPosition.position);
      }
    }
  }, [ref.current]);

  const isFileProcessor = useIsFileProcessor();

  const [pageIndex, setPageIndex] = useState(currentPageIndex);
  const adjustedDocumentHeight = useMemo(() => {
    const GROUPING_BUTTONS_CONSTANT = isGrouping ? 30 : 0;
    return (documentHeight + documentPadding) * documentZoom + GROUPING_BUTTONS_CONSTANT;
  }, [windowSize, documentZoom, documentHeight, documentPadding]);

  const refOffset = ref.current?.state.scrollOffset;
  const refZoom = ref.current?.props.itemData.documentZoom;
  const baseOffset = refOffset / refZoom || 0;
  const normalizedOffset = Math.max(0, Math.round(currentPageIndex * adjustedDocumentHeight));

  useEffect(() => {
    // If currently scrolling or timeline entry still loading, skip.
    if (isScrolling() || !ref.current) {
      return;
    }
    if (pageIndex === currentPageIndex) {
      return;
    }
    setPageIndex(currentPageIndex);
    ref.current.scrollTo(normalizedOffset);
  }, [currentPageIndex, normalizedOffset]);

  useEffect(() => {
    ref.current.scrollTo(baseOffset * documentZoom);
  }, [documentZoom]);

  const updatePageIndex = useCallback((index) => {
    setPageIndex(index);
  }, []);

  //Managing changing the page while scrolling
  const onListScroll = useCallback(
    ({ scrollOffset }) => {
      window.lastScrollTime = new Date().getTime();
      //Get the next page number when halfway through the current page
      const index = Math.floor(
        (scrollOffset + adjustedDocumentHeight / 2) / adjustedDocumentHeight,
      );
      //If index doesn't match, set the page to the next page.
      if (index !== pageIndex) {
        onScrollToDifferentPage(index);
      }
      updatePageIndex(index);
      if (isGrouping && ref?.current?._outerRef?.scrollTop) {
        setLastGroupingScrollPosition({
          documentID: pages[0]?.documentID,
          position: ref?.current?._outerRef?.scrollTop,
        });
      }
    },
    [window.lastScrollTime, adjustedDocumentHeight, pageIndex, onScrollToDifferentPage],
  );

  const itemCount = Number(pages.length);

  if (pages.length === 0) {
    return <Loading />;
  }
  return (
    <List
      id="pdf-display-list"
      className="pdf-display-list"
      height={window.innerHeight - 132}
      itemCount={itemCount}
      itemSize={adjustedDocumentHeight}
      initialScrollOffset={normalizedOffset}
      onScroll={onListScroll}
      itemData={{
        documentZoom,
        documentRotation,
        pages: pages,
        height: adjustedDocumentHeight * itemCount,
        fetchFile,
        caseID,
        searchResults,
        dismissDocumentEdge,
        pageDepthByPageID,
        groupIDByPageID,
        attachmentIDByPageID,
        isGrouping,
        groupingActionOnClicks,
        isUpdateRequestInProgress,
        isFileProcessor,
        getGroupingButtonDisabledStatuses,
      }}
      ref={ref}
    >
      {renderPage}
    </List>
  );
}

function isScrolling() {
  return window.lastScrollTime && new Date().getTime() < window.lastScrollTime + 250;
}

PDFDocumentList.propTypes = {
  caseID: PropTypes.string,
  currentPageIndex: PropTypes.number,
  onScrollToDifferentPage: PropTypes.func,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      pageNumber: PropTypes.number,
      documentID: PropTypes.string,
    }),
  ),
  documentZoom: PropTypes.number,
  documentRotation: PropTypes.shape({}),
  fetchFile: PropTypes.func,
};

PDFDocumentList.defaultProps = {
  caseID: '',
  currentPageIndex: 0,
  onScrollToDifferentPage: null,
  pages: [],
  documentZoom: 1,
  documentRotation: {},
  fetchFile: null,
};
