import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import apiUrl from '../../library/utilities/apiUrl';

const queryDefaults = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

async function fetchThumbnailUrls(caseID) {
  return (await axios.get(`${apiUrl}case/${caseID}/thumbnails`)).data;
}

export default function useThumbnails(caseID) {
  return useQuery(['thumbnails', caseID], () => fetchThumbnailUrls(caseID), {
    ...queryDefaults,
  });
}
