/* eslint-disable react/require-default-props, react/jsx-props-no-spreading */
import Typography from '@mui/material/Typography';
import FillAndCenter, { FillAndCenterProps } from '../Base/FillAndCenter';

export type ErrorPlaceholderProps = FillAndCenterProps & {
  text?: string;
  caption?: string;
};

function ErrorPlaceholder({
  text = 'An error has happened!',
  caption = 'Please try again in a minute! In case the issue persists, please contact our support team.',
  ...props
}: ErrorPlaceholderProps) {
  return (
    <FillAndCenter p={1} {...props}>
      {text}

      <Typography variant="caption" color="grey">
        {caption}
      </Typography>
    </FillAndCenter>
  );
}

export default ErrorPlaceholder;
