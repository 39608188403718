import { SvgIcon } from '@mui/material';

export default function Templates(props) {
  const inTemplateInsert = props.sx.inTemplateInsert ? props.sx.inTemplateInsert : false;
  return (
    <SvgIcon {...props} viewBox="0 0 82 82" xmlns="http://www.w3.org/2000/svg">
      {!inTemplateInsert ? (
        <>
          <path d="M48.9707 44.4884H54.9473V47.4767H48.9707V44.4884Z" />
          <path d="M28.0527 44.4884H40.0059V59.4298H28.0527V44.4884Z" />
        </>
      ) : null}
      <path d="M19.0879 17.4943V22.0763H63.9121V17.4943C63.9121 16.6686 63.2436 16.0001 62.418 16.0001H20.582C19.7564 16.0001 19.0879 16.6686 19.0879 17.4943Z" />
      {!inTemplateInsert ? (
        <>
          <path d="M48.9707 56.4415H54.9473V59.4298H48.9707V56.4415Z" />
          <path d="M28.0527 32.5353H54.9473V35.5236H28.0527V32.5353Z" />
        </>
      ) : null}
      <path d="M19.0879 65.506C19.0879 66.3316 19.7564 67.0001 20.582 67.0001H62.418C63.2436 67.0001 63.9121 66.3316 63.9121 65.506V25.0646H19.0879V65.506ZM57.9355 60.924C57.9355 61.7496 57.2671 62.4181 56.4414 62.4181H47.4766C46.6509 62.4181 45.9824 61.7496 45.9824 60.924V54.9474C45.9824 54.1217 46.6509 53.4532 47.4766 53.4532H56.4414C57.2671 53.4532 57.9355 54.1217 57.9355 54.9474V60.924ZM57.9355 48.9708C57.9355 49.7965 57.2671 50.465 56.4414 50.465H47.4766C46.6509 50.465 45.9824 49.7965 45.9824 48.9708V42.9943C45.9824 42.1686 46.6509 41.5001 47.4766 41.5001H56.4414C57.2671 41.5001 57.9355 42.1686 57.9355 42.9943V48.9708ZM25.0645 31.0411C25.0645 30.2155 25.7329 29.547 26.5586 29.547H56.4414C57.2671 29.547 57.9355 30.2155 57.9355 31.0411V37.0177C57.9355 37.8434 57.2671 38.5118 56.4414 38.5118H26.5586C25.7329 38.5118 25.0645 37.8434 25.0645 37.0177V31.0411ZM25.0645 42.9943C25.0645 42.1686 25.7329 41.5001 26.5586 41.5001H41.5C42.3257 41.5001 42.9941 42.1686 42.9941 42.9943V60.924C42.9941 61.7496 42.3257 62.4181 41.5 62.4181H26.5586C25.7329 62.4181 25.0645 61.7496 25.0645 60.924V42.9943Z" />
    </SvgIcon>
  );
}
