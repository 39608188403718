export function queueLimit<T extends (...args: any[]) => Promise<any>>(
  options: { limit: number },
  func: T,
): T {
  const queue: { args: any[]; resolve: (data: any) => void; reject: (error: any) => void }[] = [];
  let running = 0;

  function drain() {
    if (queue.length === 0) {
      return;
    }

    if (running >= options.limit) {
      return;
    }

    running++;

    const { args, resolve, reject } = queue.shift()!;

    func(...args)
      .then(resolve)
      .catch(reject)
      .finally(() => {
        running--;

        drain();
      });
  }

  return ((...args: any[]) => {
    return new Promise((resolve, reject) => {
      queue.push({ args, resolve, reject });

      drain();
    });
  }) as T;
}

export async function retryRequest<T>(
  requestFn: (...args: any[]) => Promise<{ status: number; data?: T }>,
  maxRetries = 1,
  ...args: any[]
): Promise<{ status: 'resolved' | 'rejected'; data?: T }> {
  let attempts = 0;

  while (attempts <= maxRetries) {
    try {
      const response = await requestFn(...args);
      return { data: response.data, status: 'resolved' };
    } catch (error) {
      attempts += 1;
      console.log(error);
      if (attempts > maxRetries) {
        console.error('Request failed:', error);
        return { status: 'rejected' };
      }
    }
  }
  return { status: 'rejected' };
}
