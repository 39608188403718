import { Tooltip } from '@mui/material';

function FilterControl(props) {
  const { label = '', control, controlStyle = {}, toolTipTitle = '' } = props;

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
      }}
    >
      <div
        style={{
          ...controlStyle,
          flex: 'auto',
          textAlign: 'start',
          margin: 'auto',
          marginTop: '-0.5rem',
          marginLeft: '-0.65rem',
        }}
      >
        {control}
      </div>
      {label !== '' && (
        <div
          style={{
            flex: '50',
            margin: 'auto',
            marginTop: '0.2rem',
          }}
        >
          <Tooltip title={toolTipTitle} placement="top">
            <div
              style={{
                fontSize: '0.875rem',
                width: 'fit-content',
                cursor: toolTipTitle !== '' ? 'help' : 'default',
                verticalAlign: 'center',
              }}
            >
              {label}
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
}

export default FilterControl;
