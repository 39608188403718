import Theme from "../../theme";

export default function DuplicateHeaderIcon(props) {
  const { color } = props;
  return (
    <svg
      {...props}
      style={{ marginRight: '0.4rem' }}
      width="21"
      height="21"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1599_1355)">
        <path
          d="M14.4737 6V4.85802C14.4737 3.44315 14.4737 2.73572 14.1983 2.19531C13.9561 1.71995 13.5696 1.33348 13.0943 1.09127C12.5539 0.815918 11.8464 0.815918 10.4316 0.815918H5.04211C3.62724 0.815918 2.9198 0.815918 2.37939 1.09127C1.90404 1.33348 1.51756 1.71995 1.27535 2.19531C1 2.73572 1 3.44315 1 4.85802V13.6159C1 15.0308 1 15.7382 1.27535 16.2786C1.51756 16.754 1.90404 17.1405 2.37939 17.3827C2.9198 17.658 3.62724 17.658 5.04211 17.658H9"
          stroke={color || 'black'}
          strokeWidth="1.68421"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 10.5C16 12 16.0008 12.469 16 13C16 14.4989 16 15 16 15M16 18.5V18"
          stroke={color || Theme.typography.color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 10.08C9 8.65187 9 7.9378 9.28611 7.39232C9.53778 6.91251 9.93935 6.52241 10.4333 6.27793C10.9948 6 11.7299 6 13.2 6H18.8C20.2701 6 21.0052 6 21.5667 6.27793C22.0607 6.52241 22.4622 6.91251 22.7139 7.39232C23 7.9378 23 8.65187 23 10.08V18.92C23 20.3481 23 21.0622 22.7139 21.6077C22.4622 22.0875 22.0607 22.4776 21.5667 22.7221C21.0052 23 20.2701 23 18.8 23H13.2C11.7299 23 10.9948 23 10.4333 22.7221C9.93935 22.4776 9.53778 22.0875 9.28611 21.6077C9 21.0622 9 20.3481 9 18.92V10.08Z"
          stroke={color || 'black'}
          strokeWidth="1.625"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1599_1355">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
