/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
  IconButton,
  Select,
  MenuItem,
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState, useEffect } from 'react';
import { getTimeToNow, formatFormalDate } from '../../../library/utilities/useDates';
import NewSearchBar from '../../../components/Searchbar/NewSearchBar';

import {
  useAssignProcessor,
  useFileProcessorCases,
  useFileProcessorUserList,
} from '../../AccountSettings/useFileProcessing';
import Loading from '../../../components/common/Loading';

const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime);

const caseTableStyles = {
  root: {
    width: '100%',
    cursor: 'pointer',
  },
  paper: (theme) => ({
    width: '100%',
    marginBottom: theme.spacing(2),
  }),
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
};

function FileProcessorCaseTable() {
  const { isLoading, data: caseList } = useFileProcessorCases();
  const { data: fileProcessorUserList } = useFileProcessorUserList();
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <CircularProgress />
      </Box>
    );
  }

  return <CaseTable caseList={caseList} userList={fileProcessorUserList} />;
}

function CaseTable({ caseList, userList }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [clickedCaseID, setClickedCaseID] = useState('');

  const [order, setOrder] = React.useState(window.localStorage.getItem('Order') || 'asc');
  const [orderBy, setOrderBy] = React.useState(
    window.localStorage.getItem('fileProcessorOrderBy') || 'caseName',
  );
  const [eventAlert, setEventAlert] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const OrderBy = window.localStorage.getItem('fileProcessorOrderBy');

    if (OrderBy === null || OrderBy === undefined) {
      window.localStorage.setItem('fileProcessorOrderBy', orderBy);
    } else if (OrderBy !== orderBy) {
      setOrderBy(OrderBy);
    }
  }, [orderBy, setOrderBy]);

  useEffect(() => {
    const Order = window.localStorage.getItem('Order');
    if (Order === null || Order === undefined) {
      window.localStorage.setItem('Order', order);
    } else if (Order !== order) {
      setOrder(Order);
    }
  }, [order, setOrder]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    window.localStorage.setItem('fileProcessorOrderBy', property);
    window.localStorage.setItem('Order', newOrder);
    setOrder(newOrder);
    setOrderBy(property);
  };

  useEffect(() => {
    if (clickedCaseID) {
      navigate(`/case/${clickedCaseID}/files`);
    }
  }, [clickedCaseID]);

  const refreshCaseTable = () => {
    queryClient.invalidateQueries(['fileProcessorCases']);
  };

  const searchFilter = (caseToBeFiltered) => {
    const caseName = caseToBeFiltered.caseName || '';
    const patientName = caseToBeFiltered.patientName || '';
    const matchesSearchTerm =
      caseName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      patientName.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesSearchTerm;
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const archivedCases = caseList?.filter(
    (caseInstance) => !caseInstance.archived_at && !caseInstance.archived_by,
  );

  const filteredCaseList = archivedCases?.filter(searchFilter);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right!important',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '25%',
            backgroundColor: 'selectedGrey',
          }}
        >
          <NewSearchBar
            searchStr={searchTerm}
            label="Search Cases"
            handleChange={handleSearchTermChange}
            customStyles={{ flex: 90 }}
          />
        </Box>
        <IconButton sx={{ marginLeft: '0.5rem', marginTop: 0 }} onClick={() => refreshCaseTable()}>
          <Refresh sx={{ fontSize: '2rem' }} />
        </IconButton>
      </Box>

      <Box sx={{ marginTop: '2rem' }}>
        <Box sx={caseTableStyles.root}>
          <Paper sx={caseTableStyles.paper}>
            <TableContainer>
              <Table
                sx={caseTableStyles.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  sx={[
                    caseTableStyles.root,
                    caseTableStyles.paper,
                    caseTableStyles.table,
                    caseTableStyles.visuallyHidden,
                  ]}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={filteredCaseList.length}
                />
                <TableBody>
                  {filteredCaseList.length > 0 ? (
                    stableSort(filteredCaseList, getComparator(order, orderBy)).map(
                      (row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <CaseTableRow
                            key={row.caseID}
                            caseInstance={row}
                            labelId={labelId}
                            userList={userList}
                            setClickedCaseID={setClickedCaseID}
                            searchTerm={searchTerm}
                          />
                        );
                      },
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        <Typography variant="h6">No Cases Found</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={eventAlert !== ''}
            autoHideDuration={3000}
            onClose={() => setEventAlert('')}
          >
            <Alert severity="success" onClose={() => setEventAlert('')}>
              {eventAlert}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </>
  );
}

function descendingComparator(a, b, orderBy) {
  const statusA = getRowStatusText(a.caseStatus, a.pagesViewed);
  const statusB = getRowStatusText(b.caseStatus, b.pagesViewed);

  //Add checks for null
  if (b[orderBy] === null && a[orderBy === null]) {
    return 0;
  }
  if (b[orderBy] === null) {
    return -1;
  }
  if (a[orderBy] === null) {
    return 1;
  }

  if (orderBy === 'refID') {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  if (orderBy === 'caseStatus') {
    if (statusB.toLowerCase() < statusA.toLowerCase()) {
      return -1;
    }
    if (statusB.toLowerCase() > statusA.toLowerCase()) {
      return 1;
    }
  }
  if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
    return -1;
  }
  if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
    return 1;
  }
  return 0;
}

const getRowStatusText = (caseStatus, pagesViewed) => {
  switch (caseStatus) {
    case null:
      return 'NEW';
    case 'REQUIRES_PROCESSOR':
      return 'READY';
    case 'READY':
      if (pagesViewed === 0) {
        return 'READY';
      }
      return 'IN_PROGRESS';
    default:
      return caseStatus;
  }
};

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'refID',
    numeric: true,
    disablePadding: false,
    label: 'Case #',
    width: '10%',
  },
  {
    id: 'caseName',
    numeric: false,
    disablePadding: false,
    label: 'Case Name',
    width: '14%',
  },
  {
    id: 'processorAssigned',
    numeric: true,
    disablePadding: false,
    label: 'Assignee',
    width: '14%',
  },
  {
    id: 'pagesRead',
    numeric: true,
    disablePadding: false,
    label: 'Pages Read',
    width: '10%',
  },
  {
    id: 'caseStatus',
    numeric: false,
    disablePadding: false,
    label: 'Case Status',
    width: '14%',
  },
  {
    id: 'lastUploadDate',
    numeric: true,
    disablePadding: false,
    label: 'Last Upload',
    width: '14%',
  },
  {
    id: 'createDate',
    numeric: true,
    disablePadding: false,
    label: 'Creation Date',
    width: '14%',
  },
  {
    id: 'dueDate',
    numeric: true,
    disablePadding: false,
    label: 'Due Date',
    width: '19%',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            {headCell.id !== 'pagesRead' && headCell.id !== 'Actions' ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography variant="h4">{headCell.label}</Typography>
                {orderBy === headCell.id ? (
                  <span style={caseTableStyles.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <Typography variant="h4" sx={{ marginTop: 0.4 }}>
                {headCell.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function CaseTableRow({ caseInstance, userList, setClickedCaseID, searchTerm }) {
  const {
    refID,
    caseID,
    caseName,
    createDate,
    lastUploadDate = '',
    pageCount = 0,
    pagesViewed = 0,
    processorAssigned = '',
    caseStatus,
    dueDate,
  } = caseInstance;

  const { mutateAsync: assignProcessor } = useAssignProcessor();

  const filteredCaseStatus = getRowStatusText(caseStatus, pagesViewed);
  const statusColor = statusColorMap[filteredCaseStatus];
  const statusText = caseStatusTextMap[filteredCaseStatus];

  const highlightText = (text) => {
    if (!searchTerm) {
      return text;
    }
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ backgroundColor: 'yellow' }}>
          {part}
        </span>
      ) : (
        part
      ),
    );
  };

  return (
    <TableRow key={caseID} onClick={() => setClickedCaseID(caseID)} hover tabIndex={-1}>
      <TableCell align="left" key={`${refID} 1`}>
        {refID || '-'}
      </TableCell>
      <TableCell
        key={`${caseID} 2`}
        component="th"
        scope="row"
        align="left"
        style={{ paddingLeft: '1rem' }}
        className="fs-exclude"
      >
        {highlightText(caseName, searchTerm)}
      </TableCell>
      <TableCell
        key={`${caseID} 3`}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Select
          displayEmpty
          sx={{ width: '100%' }}
          value={processorAssigned || ''}
          size="small"
          onChange={(event) => assignProcessor({ caseID, userID: event.target.value })}
        >
          {userList?.map((user) => (
            <MenuItem key={user.username} value={user.username}>
              {`${user?.given_name} ${user?.family_name}`}
            </MenuItem>
          ))}
          <MenuItem key="unassigned" value="">
            Unassigned
          </MenuItem>
        </Select>
      </TableCell>
      <TableCell align="left" scope="string" sortDirection={false} className="" key={`${caseID} 3`}>
        {pagesViewed}/{pageCount}
      </TableCell>
      <TableCell align="left" key={`${caseID} 5`} sx={{ color: statusColor }}>
        {statusText}
      </TableCell>
      <TableCell key={`${caseID} 6`} align="left">
        {getTimeToNow(lastUploadDate)}
      </TableCell>
      <TableCell align="left" key={`${caseID} 7`}>
        {getTimeToNow(createDate)}
      </TableCell>
      <TableCell align="left" key={`${caseID} 8`}>
        {dayjs(dueDate).year() > 1970 ? formatFormalDate(dueDate) : '-'}
      </TableCell>
    </TableRow>
  );
}
export default FileProcessorCaseTable;

const statusColorMap = {
  NEW: 'themePurple.main',
  PROCESSING: 'rgb(0, 33, 74)',
  READY: 'darkSecondary.main',
  CLOSED: 'primary.light',
  ERROR: 'error.main',
  IN_PROGRESS: '#EDAC4B',
};

const caseStatusTextMap = {
  NEW: 'Files Required',
  PROCESSING: 'Being Processed',
  READY: 'Ready for Review',
  CLOSED: 'Closed',
  ERROR: 'Error',
  IN_PROGRESS: 'Review in Progress',
};
