import { Box, Typography, Button, MenuList, MenuItem, Tooltip } from '@mui/material';
import { ControlPoint, ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { useState, useEffect, useMemo, useContext } from 'react';
import axios from 'axios';
import { shallow } from 'zustand/shallow';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import APIURL from '../../library/utilities/apiUrl';
import { formatFormalDate } from '../../library/utilities/useDates';
import useReports, { useCreateNewReport } from './useReports';
import MoreIconMenu from '../../components/common/MoreIconMenu';
import ReportsIcon from '../../components/icons/ReportsIcon';
import FilterButton from '../../components/common/FilterButton';
import NewDocumentModal from '../../components/NewReportModal/index';
import ReportsFilterMenu from './ReportsFilterMenu';
import useReportsStore from './useReportsStore';
import NewSearchBar from '../../components/Searchbar/NewSearchBar';
import { displayReportType } from '../../utils/reportUtils';
import useTimelineReports from './gql/useTimelineReports';
import Loading from '../../components/common/Loading';
import useDeleteTimelineReport from './gql/useDeleteTimelineReport';
import { normalizeTimelineReport } from './gql/timelineReportGqlUtils';
import useCreateTimelineReport from './gql/useCreateTimelineReport';
import { useUser } from '../../library/contexts/AuthContext';
import CaseContext from '../Case/CaseContext';
import useNotes from '../Notes/gql/useNotes';
import NotesTable from './NotesTable';
import { useGetCaseQuery } from '../../__generated__/graphql';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import { useUserGroup } from '../MyCases/useCases';
import { useActivityLog } from '../../components/ActivityTracker/ActivityTracker';

function ReportsTable() {
  const {
    setCurrentReport,
    searchTerm,
    setSearchTerm,
    orderBy,
    setOrderBy,
    sortOrder,
    setSortOrder,
    reportType,
    createdDate,
    isNotesTableOpen,
    setIsNotesTableOpen,
  } = useReportsStore(
    (state) => ({
      setCurrentReport: state.setCurrentReport,
      searchTerm: state.searchTerm,
      setSearchTerm: state.setSearchTerm,
      orderBy: state.orderBy,
      setOrderBy: state.setOrderBy,
      setSortOrder: state.setSortOrder,
      sortOrder: state.sortOrder,
      createdDate: state.createdDate,
      reportType: state.reportType,
      isNotesTableOpen: state.isNotesTableOpen,
      setIsNotesTableOpen: state.setIsNotesTableOpen,
    }),
    shallow,
  );
  const logUserActivity = useActivityLog();

  const handleNotesTableToggle = () => {
    setIsNotesTableOpen(!isNotesTableOpen);
  };

  const handleCloseNotesTable = () => {
    setIsNotesTableOpen(false);
  };

  const handleOpenReportsTable = () => {
    setIsNotesTableOpen(false);
  };

  const { caseID } = useParams();

  useEffect(() => {
    if (caseID) {
      logUserActivity({
        activity: 'case:reports',
        case_id: caseID,
      });
    }
  }, [caseID]);

  const { caseInstance } = useContext(CaseContext);
  const { data: reports, isLoading: isReportsLoading } = useReports(caseID);

  const { loading: isTimelineReportsLoading, data: timelineReports } = useTimelineReports({
    case_id: caseID,
  });

  const { data: notes } = useNotes({
    case_id: caseID,
  });

  const [newReportDialogOpen, setNewReportDialogOpen] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const queryClient = useQueryClient();
  const [sortedReports, setSortedReports] = useState([
    ...(reports ?? []),
    ...timelineReports,
    { reportType: 'NOTES_TABLE', reportName: 'All Notes' },
  ]);
  const { userId } = useUser();
  const [createTimelineReportMutation] = useCreateTimelineReport();

  const isFileProcessor = useIsFileProcessor();
  const { data: userGroup } = useUserGroup();
  const processorOrLabeller = isFileProcessor || userGroup === 'Labeller';

  const [deleteTimelineReport] = useDeleteTimelineReport();

  const { mutateAsync: createReport } = useCreateNewReport();

  async function onCreateNewFile(uploadObj, fileJson) {
    const emptyBlob = new Blob([fileJson]);
    const file = new File([emptyBlob], uploadObj.fileName, {
      lastModified: new Date().getTime(),
      type: 'Sfdt',
    });
    await createReport({ caseID, uploadObj, file });

    const newReport = queryClient
      .getQueryData(['reports', caseID])
      .find((report) => report.reportName === uploadObj.fileName);
    setCurrentReport(newReport);
  }

  const areReportsLoading = useMemo(() => {
    return isTimelineReportsLoading || isReportsLoading;
  }, [isTimelineReportsLoading, isReportsLoading]);

  async function onCreateNewTimelineReport(newReportData) {
    try {
      const {
        data: { createTimelineReport: createdReportData },
      } = await createTimelineReportMutation({
        variables: {
          name: newReportData.name,
          case_id: caseID,
        },
      });
      setCurrentReport(normalizeTimelineReport(createdReportData));
    } catch (e) {
      setNewReportDialogOpen(false);
    }
  }

  useEffect(() => {
    let reportsBeingFiltered = [
      ...(reports ?? []),
      ...(timelineReports ?? []),
      { reportType: 'NOTES_TABLE', reportName: 'All Notes' },
    ];

    if (searchTerm) {
      reportsBeingFiltered = reportsBeingFiltered.filter((report) =>
        report.reportName.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    if (reportType) {
      reportsBeingFiltered = reportsBeingFiltered.filter(
        (report) => report.reportType === reportType,
      );
    }

    if (createdDate) {
      reportsBeingFiltered = reportsBeingFiltered.filter(
        (report) => report.creationDate?.split(' ')[0] === createdDate,
      );
    }

    if (
      caseInstance.caseStatus !== 'READY' &&
      caseInstance.caseStatus !== 'CLOSED' &&
      !processorOrLabeller
    ) {
      reportsBeingFiltered = reportsBeingFiltered.filter(
        (report) => report.isAutoGenerated !== true,
      );
    }

    reportsBeingFiltered = reportsBeingFiltered.sort((a, b) => {
      if (orderBy === 'owner') {
        if (a.owner?.given_name?.toUpperCase() < b.owner?.given_name?.toUpperCase()) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (a.owner?.given_name?.toUpperCase() > b.owner?.given_name?.toUpperCase()) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        if (a.owner?.family_name?.toUpperCase() < b.owner?.family_name?.toUpperCase()) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (a.owner?.family_name?.toUpperCase() > b.owner?.family_name?.toUpperCase()) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      }
      if (a[orderBy]?.toUpperCase() < b[orderBy]?.toUpperCase()) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (a[orderBy]?.toUpperCase() > b[orderBy]?.toUpperCase()) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });

    // processors should be able to see only index reports, to be able to QA them
    if (processorOrLabeller) {
      reportsBeingFiltered = reportsBeingFiltered.filter(
        (report) => report.reportType === 'TIMELINE_REPORT',
      );
    }
    setSortedReports(reportsBeingFiltered);
  }, [orderBy, sortOrder, searchTerm, reports, timelineReports, createdDate, reportType]);

  const [anchorEl, setAnchorEl] = useState(null);

  const { data } = useGetCaseQuery({
    variables: {
      id: caseID,
    },
  });

  const caseData = data?.case;

  const createDate = caseData ? caseData.createDate : null;

  const deleteWrittenReport = async (reportID) => {
    await axios.post(`${APIURL}deleteReport`, { reportID }).then(async () => {
      await queryClient.invalidateQueries(['reports', caseID]);
    });
  };

  const deleteReport = async ({ id, reportType }) => {
    if (reportType === 'TIMELINE_REPORT') {
      await deleteTimelineReport({
        variables: {
          id,
        },
      });
    } else if (reportType === 'WRITTEN_REPORT') {
      await deleteWrittenReport(id);
    }
  };

  const changeSorting = (orderNameClicked) => {
    if (orderBy === orderNameClicked) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(orderNameClicked);
      setSortOrder('desc');
    }
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setFiltersOpen(true);
  };

  let filterCount = 0;
  if (reportType) {
    filterCount++;
  }
  if (createdDate) {
    filterCount++;
  }

  const getDeleteReportTooltipText = (ownerID, activeUserID) => {
    if (ownerID !== activeUserID) {
      return 'Sorry, you do not have permission to delete this report. Please contact the owner of this report if you believe it needs to be deleted.';
    }
    return '';
  };

  const canUserDeleteReport = (ownerID, activeUserID) => {
    if (ownerID !== activeUserID) {
      return false;
    }
    return true;
  };

  return (
    <Box
      id="reports-table-container"
      sx={{
        width: '100%',
        borderRadius: 0,
        justifyContent: 'center',
        paddingLeft: '2rem',
        paddingRight: '2rem',
      }}
    >
      {isNotesTableOpen ? (
        <NotesTable
          loading={areReportsLoading}
          notes={notes}
          columns={['Title', 'Name', 'Date', 'Note', 'Tags', 'Note Source']}
          isOpen={isNotesTableOpen}
          onCloseNotesTable={handleCloseNotesTable}
          onOpenReportsTable={handleOpenReportsTable}
        />
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              paddingTop: '1.2rem',
              paddingBottom: '1.2rem',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              backgroundColor: 'white',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                backgroundColor: 'white',
              }}
            >
              <div>
                <Typography fontWeight={600} sx={{ display: 'inline' }}>
                  Reports
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => setNewReportDialogOpen(true)}
                  sx={{
                    backgroundColor: 'primary.light',
                    fontSize: '0.8rem',
                    marginLeft: '2rem',
                    borderRadius: '8px',
                  }}
                >
                  <ControlPoint fontSize="small" sx={{ marginRight: '0.5rem' }} />
                  New Report
                </Button>
              </div>
              <div>
                <NewSearchBar
                  label="Search Reports"
                  searchStr={searchTerm}
                  handleChange={(e) => setSearchTerm(e.target.value)}
                  customStyles={{ width: '22rem' }}
                />
                <FilterButton onClick={(e) => handlePopoverOpen(e)} filterCount={filterCount} />
              </div>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              border: '0.5px solid ',
              borderColor: 'primary.border',
              flexDirection: 'row',
              flexWrap: 'wrap',
              borderRadius: '8px',
              marginBottom: '2rem',
              '.MuiDataGrid-root': {
                borderRadius: '50px !important',
              },
            }}
          >
            <Box
              id="table-header"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'primary.columnHeader',
                width: '100%',
                borderBottom: '0.5px solid',
                borderColor: 'primary.border',
              }}
            >
              <Box
                sx={{ ...reportDisplayStyles.tableHead, width: '40%' }}
                onClick={() => changeSorting('reportName')}
              >
                Report Title
                {orderBy === 'reportName' &&
                  (sortOrder === 'asc' ? (
                    <ArrowUpward sx={{ ...reportDisplayStyles.arrows }} />
                  ) : (
                    <ArrowDownward sx={{ ...reportDisplayStyles.arrows }} />
                  ))}
              </Box>

              <Box
                sx={{ ...reportDisplayStyles.tableHead, width: '28%' }}
                onClick={() => changeSorting('reportType')}
              >
                Report Type
                {orderBy === 'reportType' &&
                  (sortOrder === 'asc' ? (
                    <ArrowUpward sx={{ ...reportDisplayStyles.arrows }} />
                  ) : (
                    <ArrowDownward sx={{ ...reportDisplayStyles.arrows }} />
                  ))}
              </Box>

              <Box
                sx={{ ...reportDisplayStyles.tableHead, width: '20%' }}
                onClick={() => changeSorting('creationDate')}
              >
                Created Date
                {orderBy === 'creationDate' &&
                  (sortOrder === 'desc' ? (
                    <ArrowUpward sx={{ ...reportDisplayStyles.arrows }} />
                  ) : (
                    <ArrowDownward sx={{ ...reportDisplayStyles.arrows }} />
                  ))}
              </Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                overflowX: 'hidden',
                ...reportDisplayStyles.scrollBar,
              }}
            >
              {areReportsLoading ? (
                <Loading text="Loading Reports..." />
              ) : (
                <>
                  {sortedReports?.length < 1 && (
                    <Box
                      sx={{
                        textAlign: 'center',
                        justifyContent: 'center',
                        marginTop: '5rem',
                        marginBottom: '5rem',
                        width: '100%',
                        padding: '2rem',
                      }}
                    >
                      <ReportsIcon />
                      <Typography sx={{ fontWeight: 600, fontSize: '0.9rem' }}>
                        No Reports Found
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          opacity: '50%',
                          fontSize: '0.8rem',
                        }}
                      >{`To create a new report, click the "New Report" button 
          above.`}</Typography>
                    </Box>
                  )}
                  {sortedReports?.map(
                    ({
                      creationDate,
                      id,
                      ownerID,
                      reportName,
                      reportType,
                      lastModifiedDate,
                      isAutoGenerated,
                    }) => (
                      <Box
                        key={id ?? reportName}
                        onClick={() => {
                          if (reportType === 'NOTES_TABLE') {
                            handleNotesTableToggle();
                          } else {
                            setCurrentReport({
                              caseID,
                              creationDate,
                              id,
                              lastModifiedDate,
                              reportName,
                              ownerID,
                              reportType,
                            });
                          }
                        }}
                        sx={{
                          cursor: 'pointer',
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          flexWrap: 'wrap',
                          alignItems: 'center',
                          borderBottom: '0.5px solid',
                          borderColor: 'primary.border',
                        }}
                      >
                        <Box
                          sx={{
                            ...reportDisplayStyles.cells,
                            width: '40.1%',
                            fontWeight: 600,
                            opacity: '80%',
                          }}
                        >
                          {(() => {
                            if (reportType === 'NOTES_TABLE') {
                              return 'All Notes';
                            }
                            return reportName;
                          })()}
                        </Box>
                        <Box sx={{ ...reportDisplayStyles.cells, width: '27.9%' }}>
                          {displayReportType(reportType)}
                        </Box>

                        <Box sx={{ ...reportDisplayStyles.cells, width: '23%' }}>
                          {reportType === 'NOTES_TABLE'
                            ? formatFormalDate(createDate)
                            : formatFormalDate(creationDate)}
                        </Box>
                        {reportType === 'WRITTEN_REPORT' && (
                          <Box
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            sx={{
                              ...reportDisplayStyles.cells,
                              width: '8%',
                              paddingRight: 0,
                              textAlign: 'right',
                            }}
                          >
                            <MoreIconMenu
                              size="1.5rem"
                              sx={{
                                visibility: isAutoGenerated && 'hidden',
                              }}
                            >
                              <MenuList sx={{ paddingBottom: '0.5rem' }}>
                                <Tooltip
                                  title={getDeleteReportTooltipText(
                                    ownerID,
                                    userId,
                                    isAutoGenerated,
                                  )}
                                  placement="top"
                                >
                                  <MenuItem
                                    sx={{
                                      opacity: userId !== ownerID && '60%',
                                    }}
                                    onClick={() => {
                                      if (canUserDeleteReport(ownerID, userId, isAutoGenerated)) {
                                        deleteReport({ id, reportType });
                                      }
                                    }}
                                  >
                                    Delete Report
                                  </MenuItem>
                                </Tooltip>
                              </MenuList>
                            </MoreIconMenu>
                          </Box>
                        )}
                      </Box>
                    ),
                  )}
                </>
              )}
              {/* change this to account for both kinds of reports */}
            </Box>
          </Box>
        </>
      )}
      {newReportDialogOpen && (
        <NewDocumentModal
          isOpen={newReportDialogOpen}
          onModalClose={() => setNewReportDialogOpen(!newReportDialogOpen)}
          onCreateNewFile={onCreateNewFile}
          onCreateNewTimelineReport={onCreateNewTimelineReport}
        />
      )}
      <ReportsFilterMenu
        filtersOpen={filtersOpen}
        setFiltersOpen={setFiltersOpen}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />

      <NotesTable
        loading={areReportsLoading}
        notes={notes}
        columns={['Title', 'Name', 'Date', 'Note', 'Tags', 'Note Source']}
      />
    </Box>
  );
}

export default ReportsTable;

const reportDisplayStyles = {
  tableHead: {
    fontSize: '0.8rem',
    fontWeight: 600,
    padding: '1rem',
    opacity: '80%',
    cursor: 'pointer',
  },
  cells: {
    padding: '1rem',
    fontSize: '0.9rem',
  },
  arrows: {
    fontSize: '1rem',
    opacity: '50%',
    verticalAlign: 'top',
    marginLeft: '0.2rem',
  },
  scrollBar: {
    '&::-webkit-scrollbar': {
      width: '1em',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D0D5DD',
      padding: '0 4px',
      border: '4px solid transparent',
      backgroundClip: 'padding-box',
      borderRadius: '100px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#D0D5DD',
    },
  },
};
