import { create } from 'zustand';

const useReportsStore = create((set) => ({
  currentReport: null,
  searchTerm: '',
  orderBy: 'lastModifiedDate',
  sortOrder: 'desc',
  createdDate: '',
  reportType: '',
  shouldHideConfirmedDuplicates: true,
  isNotesTableOpen: false,
  setCurrentReport: (currentReportData) => set(() => ({ currentReport: currentReportData })),
  setSearchTerm: (term) => set(() => ({ searchTerm: term })),
  setOrderBy: (orderBy) => set(() => ({ orderBy: orderBy })),
  setSortOrder: (sortOrder) => set(() => ({ sortOrder: sortOrder })),
  setCreatedDate: (createdDate) => set(() => ({ createdDate: createdDate })),
  setReportType: (reportType) => set(() => ({ reportType: reportType })),
  toggleShouldHideConfirmedDuplicates: () =>
    set((state) => ({ shouldHideConfirmedDuplicates: !state.shouldHideConfirmedDuplicates })),
  setIsNotesTableOpen: (isOpen) => set(() => ({ isNotesTableOpen: isOpen })),
}));

export default useReportsStore;
