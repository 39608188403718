import { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { createSelectorFunctions } from 'auto-zustand-selectors-hook';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import NoteEditableTemplate from './NoteEditableTemplate';
import useScreenCaptureStore from '../ScreenCapture/useScreenCaptureStore';
import { useGetPageByIdQuery } from '../../__generated__/graphql';

const useScreenCapStore = createSelectorFunctions(useScreenCaptureStore);

function NewNote(props) {
  const { open = false, onClose, onSuccess, saveNote } = props;
  const params = useParams();
  const { caseID } = params;

  const screenCaptures = useScreenCapStore.use.screenCaptures();
  const setScreenCaptures = useScreenCapStore.use.setScreenCaptures();

  const pageId = useMemo(() => {
    if (screenCaptures.length > 0) {
      return screenCaptures[0].pageID;
    }
    return params.pageID;
  }, [screenCaptures, params]);

  const {
    loading,
    data: pageObject,
    error: getPageError,
  } = useGetPageByIdQuery({
    variables: {
      id: pageId,
    },
    skip: !pageId,
  });

  useEffect(() => {
    if (getPageError) {
      toast.error(
        'There was an issue fetching page details required to create a note. Please refresh your browser page and try again.',
      );
      Sentry.captureException(getPageError);
    }
  }, [getPageError]);

  const page = pageObject?.page ?? {};

  const [partialNote, setPartialNote] = useState(null);

  const onTakeScreenshotClick = (note) => setPartialNote(note);

  const deleteImage = (imageToBeDeleted) => {
    setScreenCaptures(screenCaptures.filter((capture) => capture !== imageToBeDeleted));
  };

  async function submitNewNote(title, body, physician, tags, date, privateNote) {
    const note = {
      documentID: page.documentID,
      pageID: page.pageID,
      title: title,
      body: body,
      physician: physician,
      tags: tags,
      privateNote: privateNote,
      date: date,
      images: screenCaptures ?? [],
    };
    onClose();
    try {
      await saveNote({ variables: { case_id: caseID, data: note } });
      if (onSuccess) {
        await onSuccess();
      }
    } catch (e) {
      // eslint-disable-next-line no-empty
    }
  }

  return (
    <NoteEditableTemplate
      open={open}
      loadingPage={loading}
      screenCaptures={screenCaptures}
      setScreenCaptures={setScreenCaptures}
      onTakeScreenshotClick={onTakeScreenshotClick}
      defaultNoteValues={
        partialNote
          ? { ...partialNote }
          : {
              date: page?.correctedDate
                ? new Date(page.correctedDate).toISOString().split('T')[0]
                : page?.pageDate ?? '',
              pageID: page?.pageID,
            }
      }
      onClose={onClose}
      onDeleteImage={(imageObject) => deleteImage(imageObject)}
      onSubmit={submitNewNote}
      dialogTitle="Add Note"
      page={page}
    />
  );
}

export default NewNote;
