import { SvgIcon } from '@mui/material';

export default function Upload(props, { color }) {
  return (
    <SvgIcon
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.44038 12.5631C9.44038 12.846 9.53623 13.0829 9.72793 13.274C9.91896 13.4657 10.1559 13.5615 10.4388 13.5615C10.7217 13.5615 10.959 13.47 11.1507 13.2869C11.3417 13.1039 11.4372 12.8709 11.4372 12.5881V9.56783H13.2344C13.4674 9.56783 13.6211 9.46399 13.6957 9.25632C13.7709 9.04798 13.7336 8.86893 13.5838 8.71917L10.7883 5.92359C10.6884 5.82375 10.5719 5.77382 10.4388 5.77382C10.3057 5.77382 10.1892 5.82375 10.0894 5.92359L7.29378 8.71917C7.14402 8.86893 7.10674 9.04798 7.18195 9.25632C7.2565 9.46399 7.41026 9.56783 7.64323 9.56783H9.44038V12.5631ZM2.45143 16.5568C1.9023 16.5568 1.43238 16.3614 1.04166 15.9707C0.650281 15.5793 0.45459 15.1091 0.45459 14.5599V2.57887C0.45459 2.02974 0.650281 1.55982 1.04166 1.1691C1.43238 0.777722 1.9023 0.582031 2.45143 0.582031H7.61827C7.88451 0.582031 8.13844 0.631952 8.38006 0.731795C8.62101 0.831637 8.83301 0.97308 9.01606 1.15612L10.4388 2.57887H18.4262C18.9753 2.57887 19.4456 2.77456 19.837 3.16595C20.2277 3.55666 20.423 4.02659 20.423 4.57572V14.5599C20.423 15.1091 20.2277 15.5793 19.837 15.9707C19.4456 16.3614 18.9753 16.5568 18.4262 16.5568H2.45143Z"
        fill={color}
      />
    </SvgIcon>
  );
}
