import { Cancelable } from '@mui/utils/debounce';
import React, { Ref } from 'react';
import { VariableSizeList as List, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import config from '../../../config';

export default function DocumentList({
  handleIsItemLoaded,
  handleLoadMoreItems,
  calculateItemSize,
  renderComponent,
  onScroll,
  listRef,
  variableListRef,
  scrollOffsetState,
  timelineWidth,
  itemCount,
  listKey,
}: ListProps) {
  return (
    /* @ts-ignore */
    <InfiniteLoader
      itemCount={itemCount}
      isItemLoaded={handleIsItemLoaded}
      loadMoreItems={handleLoadMoreItems}
      threshold={30}
      minimumBatchSize={config.documents.gql.defaultTake}
    >
      {({ onItemsRendered }) => (
        // @ts-ignore
        <List
          key={listKey}
          className="List side-nav-list"
          initialScrollOffset={scrollOffsetState?.topOffset ?? 0}
          onScroll={onScroll}
          height={window.innerHeight - 230}
          itemCount={itemCount}
          itemSize={calculateItemSize}
          ref={listRef}
          onItemsRendered={onItemsRendered}
          outerRef={variableListRef}
          overscanCount={15}
          width={timelineWidth}
        >
          {renderComponent}
        </List>
      )}
    </InfiniteLoader>
  );
}

type ListProps = {
  handleIsItemLoaded: (index: number) => boolean;
  handleLoadMoreItems: ((from: any, to: any) => void) & Cancelable;
  calculateItemSize: (index: number) => number;
  renderComponent: (props: ListChildComponentProps) => React.JSX.Element;
  onScroll: (value: any) => void;
  listRef: Ref<any>;
  variableListRef: Ref<any>;
  scrollOffsetState: { pageID: number | null; topOffset: number };
  timelineWidth: number;
  itemCount: number;
  listKey: string;
};
