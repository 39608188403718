/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { Grid, Card, Checkbox, Box } from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import usePanZoom from 'use-pan-and-zoom';
import useZoomStore from './useZoomStore';
import { useUpdatePageRotationMutation } from '../../__generated__/graphql';
import PDFViewerPage from '../../components/DocumentScrolling/components/PdfViewerPage';
import Loading from '../../components/common/Loading';
import DocumentDisplayHeader from './DocumentDisplayHeader';
import DocumentDisplayFooter from './DocumentDisplayFooter';
import useDisplayStore from '../Timeline/useDisplayStore';
import usePDFViewerStore from '../Case/usePDFViewerStore';

const loadingDocHeight = 394;

function PageLoading() {
  return (
    <Box sx={{ height: loadingDocHeight, display: 'flex', alignItems: 'center' }}>
      <Loading />
    </Box>
  );
}

function DocumentDisplay(props) {
  const {
    documentFileName,
    documentChunkURL,
    duplicatesLength,
    similarityScore,
    similarPageIsDuplicate,
    primary,
    isSelected,
    onChange,
    docPageNumber,
    isDuplicate,
    chunkPageNumberOffset,
    rotation,
    pageID,
  } = props;
  const documentRotation = usePDFViewerStore((state) => state.documentRotation);
  const setDocumentRotation = usePDFViewerStore((state) => state.setDocumentRotation);
  const [pageRotationMutation] = useUpdatePageRotationMutation();
  const [panning, setPanning] = React.useState(false);
  const [, setZooming] = React.useState(null);
  const { windowSize } = useDisplayStore();
  const {
    height,
    width,
    allPanX,
    allPanY,
    allZoom,
    setHeight,
    setWidth,
    setAllPanX,
    setAllPanY,
    setAllZoom,
  } = useZoomStore();

  const { deltaX, deltaY } = useMemo(() => {
    if (rotation === 90 || rotation === 270) {
      return {
        deltaX: height * allZoom * 0.5,
        deltaY: width * allZoom * 0.5,
      };
    }

    return {
      deltaX: width * allZoom * 0.5,
      deltaY: height * allZoom * 0.5,
    };
  }, [allZoom, height, width, rotation]);

  const { container, setContainer, panZoomHandlers, pan, setPan, zoom, setZoom } = usePanZoom({
    minZoom: 0.8,
    maxZoom: 4.0,
    maxX: deltaX,
    maxY: deltaY,
    minX: -deltaX,
    minY: -deltaY,
    panOnDrag: true,
    zoomOnPinch: true,
    zoomSensitivity: 0.000125,
    onZoom: (transform) => {
      // usePanZoom seems to have a bug in it were calling setZoom
      // within the onZoom handler causes a react render loop.
      // to avoid this issue we call setZoom on the next frame (this works for some reason).
      setZooming((frameId) => {
        if (frameId != null) {
          cancelAnimationFrame(frameId);
        }
        return requestAnimationFrame(() => {
          setAllZoom(transform.zoom);
          setAllPanX(transform.x - 1);
          setAllPanY(transform.y - 1);

          // calling setZoom here isn't triggering render on none interacting components
          // however calling setPanX/Y on the next frame triggers rerender with the correct zoom.
          requestAnimationFrame(() => {
            setAllPanX(transform.x + 1);
            setAllPanY(transform.y + 1);
          });
        });
      });
    },
    onPanStart: (_, transform) => {
      setPanning(true);
      setAllPanX(transform.x);
      setAllPanY(transform.y);
    },
    onPan: (_, transform) => {
      setAllPanX(transform.x);
      setAllPanY(transform.y);
    },
    onPanEnd: () => {
      setPanning(false);
    },
  });

  useEffect(() => {
    if (container) {
      setHeight(container.offsetHeight);
      setWidth(container.offsetWidth);
    }
  }, [container, windowSize.width, windowSize.height]);

  useEffect(() => {
    if (container) {
      setHeight(container.offsetHeight);
      setWidth(container.offsetWidth);
    }
    setZoom(allZoom);
  }, [allZoom]);

  useEffect(() => {
    if (container) {
      setHeight(container.offsetHeight);
      setWidth(container.offsetWidth);
    }
    if (!panning) {
      setPan({ x: allPanX, y: allPanY });
    }
  }, [allPanX, allPanY]);

  useEffect(() => {
    // reset for new document
    setAllPanX(0);
    setAllPanY(0);
    setAllZoom(1);
  }, [documentFileName]);

  const saveRotation = async (newRotation) => {
    try {
      await pageRotationMutation({
        variables: {
          data: { pageID, rotation_angle: newRotation },
        },
      });
    } catch (error) {
      toast.error('Failed to save page rotation');
      Sentry.captureException(error);
    }
  };

  const rotateClockwise = () => {
    setDocumentRotation({
      ...documentRotation,
      [pageID]: rotation === 270 ? 0 : rotation + 90,
    });
    saveRotation(rotation === 270 ? 0 : rotation + 90);
  };

  const rotateCounterclockwise = () => {
    setDocumentRotation({
      ...documentRotation,
      [pageID]: rotation === 0 ? 270 : rotation - 90,
    });
    saveRotation(rotation === 0 ? 270 : rotation - 90);
  };

  return (
    <Grid
      item
      sx={{
        width: '40%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        overflow: 'visible',
        alignSelf: 'start',
        margin: '2.5%',
        marginLeft: '5%',
        marginRight: '5%',
      }}
    >
      {!primary && (
        <Box
          sx={{
            zIndex: 99,
            display: 'flex',
            background: 'transparent',
            position: 'relative',
            marginRight: '98%',
            marginTop: '-1.8rem',
            marginBottom: '-2.1rem',
          }}
        >
          <Checkbox
            icon={
              <Box
                sx={{
                  borderRadius: 999,
                  MozBorderRadius: 999,
                  WebkitBorderRadius: 999,
                  width: '21.594px',
                  height: '21.594px',
                  position: 'relative',
                  background: 'white',
                }}
              >
                <RadioButtonUnchecked
                  sx={{
                    marginLeft: '-0.155rem',
                    marginTop: '-0.375rem',
                    width: '122.954796031%', //55.75 (total width of svg)/actual width of checkmark circle in svg - scaled so check circle is at the desired size.
                    height: '122.954796031%',
                    position: 'relative',
                  }}
                />
              </Box>
            }
            checkedIcon={
              <Box
                sx={{
                  borderRadius: 999,
                  MozBorderRadius: 999,
                  WebkitBorderRadius: 999,
                  width: '21.594px',
                  height: '21.594px',
                  position: 'relative',
                  background: 'white',
                }}
              >
                <CheckCircle
                  sx={{
                    marginLeft: '-0.155rem',
                    marginTop: '-0.375rem',
                    width: '122.954796031%', //55.75 (total width of checkmark svg)/actual width of checkmark circle svg - scaled so check circle is at the desired size.
                    height: '122.954796031%',
                    position: 'relative',
                  }}
                />
              </Box>
            }
            onChange={onChange}
            checked={isSelected}
            sx={{
              zIndex: 100,
              color: 'primary',
              transform: 'scale(2.1)',
              position: 'relative',
              marginTop: '60%',
            }}
          />
        </Box>
      )}
      <Card
        variant="outlined"
        sx={{
          borderRadius: 4,
          border: isSelected && 'solid 1px #102449',
          '&:hover': {
            border: 'thin solid #e3e3e3',
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          overflow: 'hidden',
          width: '100%',
          height: '100%',
        }}
      >
        <DocumentDisplayHeader
          rotateClockwise={rotateClockwise}
          rotateCounterclockwise={rotateCounterclockwise}
          zoomIn={() => {
            setAllZoom(zoom + 0.2);
          }}
          zoomOut={() => {
            setAllZoom(zoom - 0.2);
          }}
          documentZoom={zoom}
          documentName={documentFileName}
          pageNumber={docPageNumber}
          selected={isSelected}
          duplicatesLength={duplicatesLength}
          similarityScore={similarityScore}
          similarPageIsDuplicate={similarPageIsDuplicate}
          isDuplicate={isDuplicate}
          onChange={onChange}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden',
            cursor: 'move',
          }}
          ref={(el) => {
            setContainer(el);
          }}
          id="container"
          {...panZoomHandlers}
        >
          <div
            style={{
              cursor: 'move',
              transform: `translate3D(${pan.x}px, ${pan.y}px, 0)`,
              width: '100%',
              height: '100%',
              userSelect: 'none',
              MozUserSelect: 'none',
              WebkitUserDrag: 'none',
              WebkitUserSelect: 'none',
              MsUserSelect: 'none',
            }}
          >
            <PDFViewerPage
              url={
                documentChunkURL?.url[documentChunkURL?.pageNumber.indexOf(chunkPageNumberOffset)]
              }
              width="100%"
              height="100%"
              scale={zoom}
              rotate={rotation}
              Loading={PageLoading}
            />
          </div>
        </Box>
        <DocumentDisplayFooter
          duplicatesLength={duplicatesLength}
          onChange={onChange}
          similarityScore={similarityScore}
          selected={isSelected}
          similarPageIsDuplicate={similarPageIsDuplicate}
          isDuplicate={isDuplicate}
        />
      </Card>
    </Grid>
  );
}

DocumentDisplay.propTypes = {
  documentFileName: PropTypes.string.isRequired,
};

export default DocumentDisplay;
