import { BrowserTracing } from '@sentry/tracing';
import SentryFullStory from '@sentry/fullstory';

export default {
  denyUrls: [/staging/],
  allowUrls: [/\.reportmed\.ca/, /\.siftmed\.ca/],
  dsn: 'https://bdbbabffe782487383d132769228666b@o1153355.ingest.sentry.io/6232384',
  environment: process.env.REACT_APP_GQL_BACKEND.split('//')[1].split('.')[0],
  integrations: [new BrowserTracing(), new SentryFullStory('siftmed')],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.25,
};
