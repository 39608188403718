import React, { useState, useEffect } from 'react';
import { logUserActivity, UserActivity } from '../../api';

const ActivityTrackerContext = React.createContext<(activity: UserActivity) => void>(() => {});

export type ActivityTrackerProps = {};

export function UserSessionTracker({ children }: React.PropsWithChildren<ActivityTrackerProps>) {
  const [isActive, setIsActive] = useState(document.hasFocus());
  const [activity, setActivity] = useState<UserActivity | undefined>();

  // Handle tab focus/unfocus and log user activity
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hasFocus()) {
        setIsActive(true);
      } else {
        setIsActive(false);
        if (activity) {
          logUserActivity({ ...activity, inactive: true });
        }
        updateLastActiveTime();
      }
    };

    window.addEventListener('blur', handleVisibilityChange);
    window.addEventListener('focus', handleVisibilityChange);
    return () => {
      window.removeEventListener('blur', handleVisibilityChange);
      window.removeEventListener('focus', handleVisibilityChange);
    };
  }, [activity]);

  // Log activity when activity or isActive changes and every minute
  useEffect(() => {
    function sendActivityLog() {
      if (activity && isActive) {
        logUserActivity(activity);
        updateLastActiveTime();
      }
    }

    sendActivityLog();
    const interval = setInterval(sendActivityLog, 1000 * 60);
    window.addEventListener('beforeunload', sendActivityLog);

    return () => {
      clearInterval(interval);
      window.removeEventListener('beforeunload', sendActivityLog);
    };
  }, [activity, isActive]);

  return (
    <ActivityTrackerContext.Provider value={setActivity}>
      {children}
    </ActivityTrackerContext.Provider>
  );
}

export function useActivityLog() {
  return React.useContext(ActivityTrackerContext);
}

const updateLastActiveTime = () => {
  localStorage.setItem('lastActiveTime', Date.now().toString());
};
