import { gql, useApolloClient } from '@apollo/client';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { useIsFileProcessor } from '../../containers/AccountSettings/useFileProcessing';
import { useUserGroup } from '../../containers/MyCases/useCases';
import useContentTypesAndSpecialities from '../../containers/Timeline/gql/useContentTypesAndSpecialities';
import useOrgs from '../../containers/Timeline/gql/useOrgEntities';
import usePeople from '../../containers/Timeline/gql/usePeopleEntities';
import useChangeEntityMutation from '../../containers/Timeline/gql/useUpdateEntity';
import ToolbarEntitySelect, { Option } from '../../containers/Timeline/ToolbarEntitySelect';
import useTimelineStore from '../../containers/Timeline/useTimelineStore';
import useTimelineEntryTags, { useUpdateTimelineEntryTag } from './useTimelineEntryTags';
import { ChangeEntityInput } from '../../__generated__/graphql';
import { Page as PageObject } from '../../containers/Timeline/types/timelineTypes';

export default function FileProcessorToolbar({ page }: { page: PageObject }) {
  const isFileProcessor = useIsFileProcessor();
  const { data: userGroup } = useUserGroup();
  const processorOrLabeller = isFileProcessor || userGroup === 'Labeller';
  const params = useParams();
  const caseID = params.caseID!;

  return (
    <Stack direction="row" sx={{ justifyContent: 'space-evenly', height: '36px' }}>
      {processorOrLabeller && <ContentTagSelection page={page} />}
      {processorOrLabeller && <EntitySelection page={page} caseID={caseID} />}
    </Stack>
  );
}

function ContentTagSelection({ page }: { page: PageObject }) {
  const pageTags = useContentTypesAndSpecialities();
  const { data: timelineEntryTags, isLoading: entryTagsLoading } = useTimelineEntryTags(
    page.entryID,
  );
  const { mutate: updateEntryTag } = useUpdateTimelineEntryTag();
  const tagMap = pageTags?.map;
  const contentTags = pageTags?.content;
  const specialityTags = pageTags?.specialities;

  const [isContentTagInputFocused, setIsContentTagInputFocused] = useState(false);
  const [isSpecialityTagInputFocused, setIsSpecialityTagInputFocused] = useState(false);

  const callUpdateEntryTag = (entityName: string, value: Option) => {
    const tagType = entityName === 'Document Type' ? 'content_type' : 'speciality';
    updateEntryTag({ entryId: page.entryID, tagId: value.id as number, tagType });
  };

  const contentTagsForEntry = useMemo(() => {
    let suggestedTags: Option[] = [];
    if (timelineEntryTags && timelineEntryTags.suggestedDocumentTypeIds.length > 0) {
      suggestedTags = timelineEntryTags.suggestedDocumentTypeIds.map((id) => tagMap[id]);
    }
    if (!isContentTagInputFocused) {
      suggestedTags.push({ id: null, value: 'Unknown', source: 'Suggested Document Types' });
    }
    return suggestedTags.sort((a, b) => (a.value && b.value ? a.value.localeCompare(b.value) : 0));
  }, [timelineEntryTags, contentTags, isContentTagInputFocused]);

  const contentTagsForCase = useMemo(() => {
    if (!timelineEntryTags) {
      return [];
    }
    return contentTags
      .filter(
        (tag) =>
          !timelineEntryTags.suggestedDocumentTypeIds.includes(tag.id) &&
          tag.origin !== 'deprecated',
      )
      .sort((a, b) => a.value.localeCompare(b.value));
  }, [timelineEntryTags, contentTags]);

  const specialityTagsForEntry = useMemo(() => {
    let suggestedTags: Option[] = [];
    if (timelineEntryTags && timelineEntryTags.suggestedSpecialityIds.length > 0) {
      suggestedTags = timelineEntryTags.suggestedSpecialityIds.map((id) => tagMap[id]);
    }
    if (!isSpecialityTagInputFocused) {
      suggestedTags.push({ id: null, value: 'Unknown', source: 'Suggested Specialities' });
    }
    return suggestedTags.sort((a, b) => (a.value && b.value ? a.value.localeCompare(b.value) : 0));
  }, [timelineEntryTags, specialityTags, isSpecialityTagInputFocused]);

  const specialityTagsForCase = useMemo(() => {
    if (!timelineEntryTags) {
      return [];
    }
    return specialityTags
      .filter(
        (tag) =>
          !timelineEntryTags.suggestedSpecialityIds.includes(tag.id) && tag.origin !== 'deprecated',
      )
      .sort((a, b) => a.value.localeCompare(b.value));
  }, [timelineEntryTags, specialityTags]);

  if (entryTagsLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
        }}
      />
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
      }}
    >
      <ToolbarEntitySelect
        entityName="Document Type"
        valueID={timelineEntryTags?.documentTypeId ?? null}
        optionsForCase={contentTagsForCase}
        optionsForEntry={contentTagsForEntry}
        allOptions={contentTags}
        setValues={callUpdateEntryTag}
        allowNewOptions={false}
        loading={false}
        onFocus={() => setIsContentTagInputFocused(true)}
        onBlur={() => setIsContentTagInputFocused(false)}
        enableUnknown={true}
      />

      <ToolbarEntitySelect
        entityName="Speciality"
        valueID={timelineEntryTags?.specialityId ?? null}
        optionsForCase={specialityTagsForCase}
        optionsForEntry={specialityTagsForEntry}
        allOptions={specialityTags}
        setValues={callUpdateEntryTag}
        allowNewOptions={false}
        loading={false}
        onFocus={() => setIsSpecialityTagInputFocused(true)}
        onBlur={() => setIsSpecialityTagInputFocused(false)}
        enableUnknown={true}
      />
    </Box>
  );
}

function EntitySelection({ page, caseID }: { page: PageObject; caseID: string }) {
  const [changeEntityMutation, entityState]: any = useChangeEntityMutation();
  const client = useApolloClient();

  const setEntity = async (type: string, entity: Option) => {
    if (entityState.loading) {
      return;
    }
    const data: ChangeEntityInput = {
      caseID: caseID,
      entryID: timelineEntry.id,
      origin: 'PROCESSOR',
      current_page_id: page.id,
    };

    if (type === 'author') {
      if (entity.id === timelineEntry.author_id) {
        return;
      }
      data.author = entity;
      await changeEntityMutation({ data });
    } else if (type === 'organization') {
      if (entity.id === timelineEntry.org_id) {
        return;
      }
      data.organization = entity;
      await changeEntityMutation({ data });
    }
  };

  const timelineEntry = client.readFragment({
    id: client.cache.identify({
      id: page.entryID,
      __typename: 'TimelineEntryObject',
    }),
    fragment: gql`
      fragment TimelineEntryWithTaggedPages on TimelineEntryObject {
        id
        author_id
        org_id
        pages {
          id
          tags {
            id
            name
            type
            parent_tag_id
            origin
          }
        }
      }
    `,
  });

  const {
    data: {
      sortedPeopleForCase: sortedAuthorsForCase,
      sortedPeopleForEntry: sortedAuthorsForEntry,
      allPeople: allAuthors,
    },
    loading: peopleLoading,
  } = usePeople(caseID, page.entryID);

  const {
    data: { sortedOrgsForCase, sortedOrgsForEntry, allOrgs },
    loading: orgsLoading,
  } = useOrgs(caseID, page.entryID);

  const { hiddenEntities } = useTimelineStore(
    (state) => ({
      hiddenEntities: state.hiddenEntities,
    }),
    shallow,
  );

  const filteredAuthors = useMemo(() => {
    return sortedAuthorsForCase.filter((author) => {
      return !hiddenEntities.includes(author.value) && !author.hidden;
    });
  }, [sortedAuthorsForCase, hiddenEntities]);

  const filteredOrgs = useMemo(() => {
    return sortedOrgsForCase.filter((org) => {
      return !hiddenEntities.includes(org.value) && !org.hidden;
    });
  }, [sortedOrgsForCase, hiddenEntities]);

  return (
    <Box
      id="entitiesToolbarContainer"
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        justifyContent: 'right',
        height: '36px',
        backgroundColor: 'selectedGrey.main',
        marginRight: '.3rem',
        lineHeight: '28px',
      }}
    >
      {allAuthors && sortedAuthorsForEntry && (
        <ToolbarEntitySelect
          entityName="author"
          valueID={timelineEntry?.author_id}
          optionsForCase={filteredAuthors}
          optionsForEntry={sortedAuthorsForEntry}
          allOptions={allAuthors}
          setValues={setEntity}
          allowNewOptions={true}
          loading={entityState.loading || !timelineEntry || peopleLoading}
          enableUnknown={false}
          onFocus={() => {}}
          onBlur={() => {}}
        />
      )}
      {allOrgs && sortedOrgsForEntry && (
        <ToolbarEntitySelect
          entityName="organization"
          valueID={timelineEntry?.org_id}
          optionsForCase={filteredOrgs}
          optionsForEntry={sortedOrgsForEntry}
          allOptions={allOrgs}
          setValues={setEntity}
          allowNewOptions={true}
          loading={entityState.loading || !timelineEntry || orgsLoading}
          enableUnknown={false}
          onFocus={() => {}}
          onBlur={() => {}}
        />
      )}
    </Box>
  );
}
