import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useState, useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import APIURL from '../../library/utilities/apiUrl';
import FeedbackPopup from '../common/FeedbackPopup';

export default function ExportProvider({ caseID, children }) {
  const [isDownloading, setisDownloading] = useState(false);
  const [exportInProgress, setExportInProgress] = useState(false);

  const queryClient = useQueryClient();

  const handleIsSegmentDownloading = useCallback((d) => setisDownloading(d), []);

  const onExportTimelineClick = useCallback(async (timelineID, entryCount, sortOrder, pageIDs) => {
    setExportInProgress(true);
    axios
      .post(`${APIURL}case/${caseID}/timeline/${timelineID}/download-async`, {
        sortOrder,
        pageIDs,
      })
      .then(async (response) => {
        console.log(response.data);

        const completed = false;
        let url = null;
        while (!completed) {
          const { data } = await axios.get(`${APIURL}/timeline-exports/${response.data.job_id}`);

          if (data.status === 'fulfilled') {
            url = data.download_url;
            break;
          }

          if (data.status === 'failed') {
            throw new Error('Timeline export failed');
          }

          await new Promise((resolve) => setTimeout(resolve, 5000));
        }

        setExportInProgress(false);
        queryClient.invalidateQueries(['timelineExports', caseID]);
        onDownloadTimeline(url);
      })
      .catch((error) => {
        setExportInProgress(false);
        toast.error(
          entryCount === 0
            ? 'Export failed. You are trying to export a timeline with no entries.'
            : 'A problem occurred when trying to export the timeline.',
        );
        Sentry.captureException(error);
      });
  }, []);

  const onDownloadTimeline = useCallback(
    async (url) => {
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    },
    [document],
  );

  return (
    <>
      {children(onExportTimelineClick, handleIsSegmentDownloading)}

      <FeedbackPopup
        text={
          <div>
            <div>Exporting Segment...</div>
          </div>
        }
        severity="info"
        open={isDownloading}
        onClose={() => setisDownloading(false)}
        verticalLocation="bottom"
        horizontalLocation="center"
      />
      <FeedbackPopup
        text={
          <div>
            <div>Exporting...</div>
            <div style={{ fontSize: '12px' }}>View your downloads in the Exports tab</div>
          </div>
        }
        severity="info"
        icon={<CircularProgress size={24} sx={{ color: 'white', margin: 'auto 15px auto 0px' }} />}
        open={exportInProgress}
        duration={null}
        verticalLocation="bottom"
        horizontalLocation="center"
      />
    </>
  );
}
