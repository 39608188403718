import { useCallback, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { useApolloClient } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useUpdateDuplicatePageSetViewedMutation,
  UpdateDuplicatePageSetViewedMutationVariables,
} from '../../../__generated__/graphql';
import { useUser } from '../../../library/contexts/AuthContext';

export default function useUpdateDuplicatePageSetViewed() {
  const [mutation, state] = useUpdateDuplicatePageSetViewedMutation();
  const client = useApolloClient();
  const { userId } = useUser();
  const { caseID } = useParams();

  const wrappedMutation = useCallback(
    (params: UpdateDuplicatePageSetViewedMutationVariables) => {
      const { duplicate_page_set_id, viewed } = params.data;

      try {
        return mutation({
          variables: {
            data: params.data,
          },
          optimisticResponse: {
            updateDuplicatePageSetViewedStatus: {
              __typename: 'DuplicateSet',
              id: +duplicate_page_set_id,
              viewed_at: viewed === 1 ? new Date() : null,
            },
          },
        });
      } catch (error) {
        toast.error(
          'A problem occurred while updating page to viewed. If issues persist, please try refreshing the page.',
        );
        Sentry.captureException(error);
        throw error;
      }
    },
    [mutation, userId, client, caseID],
  );

  return useMemo(() => [wrappedMutation, state] as const, [wrappedMutation, state]);
}
