import { useContext, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import CaseContext from '../Case/CaseContext';
import DuplicatesByDocumentContainer from '../DuplicatesByDocument/DuplicatesByDocumentContainer';
import Duplicates from '../Duplicates';
import CaseLoading from '../Case/CaseDisplays/CaseLoading';

export default function DuplicatesByCaseVersion() {
  const { caseInstance } = useContext(CaseContext);
  const caseVersion = useMemo(() => caseInstance?.version, [caseInstance]);

  if (caseVersion === undefined) {
    return <CaseLoading />;
  }
  if (caseVersion === 1) {
    return (
      <Routes>
        <Route path="" element={<Duplicates />}>
          <Route path=":setID" element={<Duplicates />} />
        </Route>
      </Routes>
    );
  }

  if (caseVersion >= 2) {
    return (
      <Routes>
        <Route path="/" element={<DuplicatesByDocumentContainer />}>
          <Route path=":firstDocID..:secondDocID" element={<DuplicatesByDocumentContainer />} />
        </Route>
      </Routes>
    );
  }
}
