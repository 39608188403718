/* eslint-disable no-nested-ternary */
import { Box } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import FillAndCenter from '../../components/common/Base/FillAndCenter';
import Loading from '../../components/common/Loading';
import TimelineView from './TimelineView';
import { useTimelineList } from './useTimeline';
import useTimelineStore from './useTimelineStore';

/*
Layout - View selection, search, and document/page view.
Breaks down pages into segments
*/

export default function Timeline() {
  const params = useParams();
  const { caseID, timelineID } = params;
  const { data: timelineList, isLoading: isTimelineListLoading } = useTimelineList(caseID);
  const defaultTimeline = useMemo(
    () => timelineList?.find((timeline) => timeline.isDefault),
    [timelineList],
  ); //TODO: Remove

  const navigate = useNavigate();

  const containerRef = React.useRef(null);

  const { clearSelection, setShowCheckboxes } = useTimelineStore(
    (state) => ({
      clearSelection: state.clearSelection,
      setShowCheckboxes: state.setShowCheckboxes,
    }),
    shallow,
  );

  // Navigate to the /timelineID + persist pageID search param
  const pageID = params?.pageID;
  useEffect(() => {
    if (!defaultTimeline || timelineID) {
      return;
    }

    if (pageID) {
      navigate(`${defaultTimeline.id}?pageID=${pageID}`);
    } else {
      navigate(`${defaultTimeline.id}`);
    }
  }, [defaultTimeline]);

  const { handleIsSegmentDownloading } = useOutletContext();

  // Clears merge and split data when component unmounts
  useEffect(
    () => () => {
      clearSelection();
      setShowCheckboxes(false);
    },
    [],
  );

  return (
    <Box
      id="timeline-outer-container"
      sx={{
        flexGrow: 1,
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        maxWidth: '100%',
      }}
    >
      <Box
        ref={containerRef}
        id="timeline-list-container"
        sx={{
          padding: 0,
          flex: 1,
          minHeight: 0,
          height: '100%',
          overflow: 'hidden',
        }}
      >
        {isTimelineListLoading ? (
          <FillAndCenter>
            <Loading text="Loading timelines..." />
          </FillAndCenter>
        ) : timelineID ? (
          <TimelineView
            caseID={caseID}
            timelineID={timelineID}
            handleIsSegmentDownloading={handleIsSegmentDownloading}
          />
        ) : null}
      </Box>
    </Box>
  );
}
