/* eslint-disable react/require-default-props, react/jsx-props-no-spreading */
import Box from '@mui/material/Box';
import React from 'react';
import { ComponentOverrideProp } from '../types/commonPropTypes';

export type FillProps<C extends React.ComponentType> = ComponentOverrideProp<C>;

function Fill<C extends React.ComponentType>(props: React.PropsWithChildren<FillProps<C>>) {
  const { children, ...restProps } = props;
  return (
    <Box width="100%" height="100%" flexGrow={1} {...restProps}>
      {children}
    </Box>
  );
}

export default Fill;
