import { FormControl, MenuItem, Select, Box, InputLabel } from '@mui/material';
import { useParams } from 'react-router-dom';
import FilterControl from '../FilterControl';
import useNotesStore from '../../../Notes/useNotesStore';
import useNotes from '../../../Notes/gql/useNotes';

export default function PhysicianFilter() {
  const { caseID } = useParams();
  const { data: notes } = useNotes({ case_id: caseID });
  const physician = useNotesStore((state) => state.physician);
  const setPhysician = useNotesStore((state) => state.setPhysician);

  let physicianNames = [];
  notes?.forEach((note) => {
    if (!physicianNames.includes(note.physician)) {
      if (!note.physician && !physicianNames.includes('No Physician')) {
        physicianNames.push('No Physician');
      } else if (note.physician) {
        physicianNames.push(note.physician);
      }
    }
  });

  physicianNames.sort();
  if (physicianNames.includes('No Physician')) {
    physicianNames = physicianNames.filter((name) => name !== 'No Physician');
    physicianNames.unshift('--', 'No Physician');
  } else {
    physicianNames.unshift('--');
  }

  return (
    <FilterControl
      control={
        <FormControl variant="standard" sx={{ width: '100%', maxWidth: '420px' }}>
          <InputLabel
            id="mutiple-select-label"
            sx={{
              fontSize: '0.875rem',
              paddingLeft: 1.5,
              paddingTop: 1,
              '&.Mui-focused': {
                display: 'none',
              },
              '&.MuiInputLabel-shrink': {
                display: 'none',
              },
            }}
          >
            Physician
          </InputLabel>

          <Select
            value={physician}
            variant="outlined"
            onChange={(e) => setPhysician(e.target.value === '--' ? '' : e.target.value)}
            sx={{
              mt: 2,
              width: '97%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
            inputProps={{
              sx: {
                width: '20rem',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              },
            }}
          >
            {physicianNames.map((physicianName) => (
              <MenuItem key={physicianName} value={physicianName}>
                <Box sx={{ fontSize: '0.875rem' }}>{physicianName}</Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
    />
  );
}
