import { CircularProgress, Typography } from '@mui/material';

/**
 * Spinner to show that something is loading.
 */

function Loading({
  text = '',
  top = false,
  className = '',
  customStyles = {},
  typographyProps = {},
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: top ? 'unset' : 'auto',
        textAlign: 'center',
        ...customStyles,
      }}
      className={className}
    >
      <CircularProgress style={{ margin: 'auto', marginTop: '2rem' }} />
      {text.length > 0 && <Typography {...typographyProps}>{text}</Typography>}
    </div>
  );
}

export default Loading;
