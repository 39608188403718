import { FormControl, TextField, Box, InputLabel } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import FilterControl from '../FilterControl';

export default function PageFilter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const setPageBeingFiltered = (pageId) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      notes__pageId: pageId,
    });
  };
  const docBeingFiltered = searchParams.get('notes__docId');
  const pageBeingFiltered = parseInt(searchParams.get('notes__pageId'), 10);

  if (!docBeingFiltered) {
    return;
  }
  // eslint-disable-next-line consistent-return
  return (
    <>
      <InputLabel
        sx={{
          fontWeight: 500,
          fontSize: '0.7rem',
          marginTop: 1.5,
          marginLeft: 1,
        }}
      >
        Page
      </InputLabel>
      <FilterControl
        control={
          <FormControl variant="standard" sx={{ width: '100%', maxWidth: '420px' }}>
            <Box sx={{ marginTop: -0.5 }}>
              <TextField
                type="number"
                disabled={!docBeingFiltered}
                value={pageBeingFiltered || ''}
                inputProps={{
                  min: 1,
                  max: 9999,
                }}
                onChange={(e) => setPageBeingFiltered(e.target.value)}
              />
            </Box>
          </FormControl>
        }
      />
    </>
  );
}
