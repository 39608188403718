import { useParams } from 'react-router-dom';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { useGetDuplicateStatsQuery, useGetDuplicatesQuery } from '../../../__generated__/graphql';

const useDuplicateStore = create((set) => ({
  filters: {
    hideResolved: true,
  },
  setFilters: (filters) => set({ filters }),
  currentIndex: 0,
  setCurrentIndex: (index) => set({ currentIndex: index }),
}));

export default function useDuplicateSets() {
  const { caseID } = useParams();

  const [filters, setFilters, currentIndex, setCurrentIndex] = useDuplicateStore(
    (state) => [state.filters, state.setFilters, state.currentIndex, state.setCurrentIndex],
    shallow,
  );

  const {
    data: filteredDuplicates,
    loading: duplicatesLoading,
    refetch,
  } = useGetDuplicatesQuery({
    variables: {
      case_id: caseID,
      hide_resolved: filters?.hideResolved ?? false,
      set_id: null,
    },
  });

  const filteredDuplicateSets = filteredDuplicates?.duplicates ?? [];

  const { data: statsQuery, loading: loadingStats } = useGetDuplicateStatsQuery({
    variables: {
      case_id: caseID,
    },
  });

  const duplicateStats = statsQuery?.duplicateStats;

  return {
    duplicateSets: filteredDuplicateSets,
    loading: duplicatesLoading || loadingStats,
    duplicateStats: duplicateStats,
    filters: filters,
    setFilters: setFilters,
    toggleResolved: () => setFilters({ ...filters, hideResolved: !filters.hideResolved }),
    currentDuplicateSet: currentIndex > -1 ? filteredDuplicateSets?.[currentIndex] : undefined,
    setCurrentDuplicateSet: (setID) =>
      setCurrentIndex(filteredDuplicateSets.findIndex((x) => x.setID === setID)),
    nextDuplicateSet: () => filteredDuplicateSets[currentIndex + 1].setID,
    previousDuplicateSet: () => filteredDuplicateSets[currentIndex - 1].setID,
    isFirstDuplicateSet: () => currentIndex === 0,
    isLastDuplicateSet: () => currentIndex === filteredDuplicateSets.length - 1,
    refetch,
  };
}
