import { SvgIcon } from '@mui/material';

export default function Success(props) {
  return (
    <SvgIcon
      {...props}
      width="117"
      height="117"
      viewBox="0 0 117 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.5576 0.828125C73.8926 0.828125 88.5995 6.91992 99.4429 17.7634C110.286 28.6068 116.378 43.3137 116.378 58.6487C116.378 73.9837 110.286 88.6905 99.4429 99.534C88.5995 110.377 73.8926 116.469 58.5576 116.469C43.2227 116.469 28.5158 110.377 17.6723 99.534C6.82885 88.6905 0.737061 73.9837 0.737061 58.6487C0.737061 43.3137 6.82885 28.6068 17.6723 17.7634C28.5158 6.91992 43.2227 0.828125 58.5576 0.828125ZM51.3548 70.0558L38.5104 57.2032C38.0499 56.7427 37.5033 56.3774 36.9017 56.1282C36.3 55.879 35.6552 55.7508 35.004 55.7508C34.3528 55.7508 33.708 55.879 33.1063 56.1282C32.5047 56.3774 31.9581 56.7427 31.4976 57.2032C30.5676 58.1331 30.0452 59.3944 30.0452 60.7096C30.0452 62.0247 30.5676 63.286 31.4976 64.216L47.8526 80.5709C48.3117 81.0337 48.858 81.401 49.4598 81.6517C50.0616 81.9023 50.707 82.0314 51.359 82.0314C52.0109 82.0314 52.6563 81.9023 53.2582 81.6517C53.86 81.401 54.4062 81.0337 54.8654 80.5709L88.7317 46.6963C89.1983 46.2378 89.5695 45.6914 89.8239 45.0886C90.0783 44.4859 90.2108 43.8388 90.2139 43.1845C90.2169 42.5303 90.0904 41.882 89.8416 41.2769C89.5929 40.6718 89.2268 40.122 88.7644 39.6591C88.3021 39.1962 87.7527 38.8295 87.1479 38.58C86.5431 38.3305 85.8949 38.2032 85.2407 38.2055C84.5865 38.2077 83.9392 38.3395 83.3361 38.5932C82.7331 38.8469 82.1863 39.2175 81.7271 39.6835L51.3548 70.0558Z"
        fill="#33DC9C"
      />
    </SvgIcon>
  );
}
