import { Close } from '@mui/icons-material';
import { DialogTitle, IconButton, Typography } from '@mui/material';

/**
 * Component for handling the creation of a new case
 */
export default function CaseDetailsHeader({ onClose }) {
  return (
    <DialogTitle
      id="dialogTitle"
      onClose={() => onClose(null)}
      sx={{
        textAlign: 'left',
        fontSize: '0.9rem',
        padding: '2rem 3.5rem 0.5rem 3.5rem',
        fontWeight: 700,
        color: 'primary.light',
      }}
    >
      Create New Case
      <Typography sx={{ fontSize: '0.7rem', color: '#002147' }}>
        Please enter the name of your case below. You will be able to add/edit these details in
        “Case Settings”
      </Typography>
      <IconButton aria-label="close" sx={caseStyles.iconBtn} onClick={() => onClose(null)}>
        <Close />
      </IconButton>
    </DialogTitle>
  );
}
const caseStyles = {
  iconBtn: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
};
