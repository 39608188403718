import { Container, Grid, Card, Typography } from '@mui/material';
import useDuplicateSets from './hooks/useDuplicateSets';

function Metric({ color, title, children }) {
  return (
    <Card
      sx={{
        boxShadow: 'none',
        border: 'solid 1px',
        borderColor: '#D3D3D3',
        width: '11em',
        height: '10em',
        margin: 3,
        marginLeft: 0,
        paddingTop: 3,
        textAlign: 'center',
        borderRadius: 4,
        '&:hover': {
          border: 'thin solid #e3e3e3',
        },
      }}
    >
      <Typography sx={{ color, fontSize: '3em', fontWeight: '500' }}>{children}</Typography>
      <Typography sx={{ color: '#000', fontSize: '0.8em', fontWeight: '500' }}>{title}</Typography>
    </Card>
  );
}

function DuplicateDashboard() {
  const { duplicateStats } = useDuplicateSets();
  const resolvedCount = duplicateStats?.resolvedCount;
  const unresolvedCount = duplicateStats?.unresolvedCount;
  const unresolvedDuplicateSetsCount = duplicateStats?.unresolvedDuplicateSetsCount;

  return (
    <Container maxWidth="md" style={{ marginTop: '1rem' }}>
      <Typography variant="h3" sx={{ fontWeight: '700' }}>
        Duplicate Metrics
      </Typography>
      <Grid container direction="row">
        <Metric color="themeDuplicates.dark" title="Unresolved Duplicates">
          {unresolvedCount}
        </Metric>
        <Metric color="secondary.main" title="Resolved Duplicates">
          {resolvedCount}
        </Metric>
        <Metric color="themeNotes.dark" title="Duplicate Sets">
          {unresolvedDuplicateSetsCount}
        </Metric>
      </Grid>
    </Container>
  );
}

export default DuplicateDashboard;
