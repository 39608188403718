import FillAndCenter from '../../components/common/Base/FillAndCenter';
import ErrorPlaceholder from '../../components/common/Placeholders/ErrorPlaceholder';
import { SmallPageCardSkeleton } from '../Timeline/Components/SmallPageCard';
import { DocumentCardSkeleton } from './Components/DocumentCard';
import useNotes from '../Notes/gql/useNotes';
import useDisplayStore from '../Timeline/useDisplayStore';
import { PAGE_SIZE } from './utils/DocumentListUtils';
import DocumentListController from './DocumentListController';

export default function DocumentListContainer({
  documentDescriptorQuery,
  searchResults,
  caseID,
}: Props) {
  const [timelineWidth] = useDisplayStore((state) => [state.timelineWidth]);
  const { data: notes } = useNotes({ case_id: caseID });

  if (documentDescriptorQuery.error) {
    return <ErrorPlaceholder />;
  }
  if (!documentDescriptorQuery.called || documentDescriptorQuery.loading) {
    const pageSkeletonCount = Math.ceil(timelineWidth / PAGE_SIZE);
    return (
      <>
        <DocumentCardSkeleton />
        {Array.from({ length: pageSkeletonCount }).map(() => (
          <SmallPageCardSkeleton />
        ))}
      </>
    );
  }
  if (!documentDescriptorQuery?.data?.documentListDescriptor.length) {
    return (
      <FillAndCenter>
        <div>No Documents to display.</div>
        <div>Consider adjusting your filters or search query.</div>
      </FillAndCenter>
    );
  }
  return <DocumentListController searchResults={searchResults} notes={notes} />;
}

type Props = {
  documentDescriptorQuery: any;
  searchResults: any;
  caseID: string;
};
