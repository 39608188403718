/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Center, { CenterProps } from './Center';
import Fill from './Fill';

export type FillAndCenterProps = CenterProps<typeof Fill>;

function FillAndCenter(props: React.PropsWithChildren<FillAndCenterProps>) {
  const { children, ...restProps } = props;

  return (
    <Center component={Fill} {...restProps}>
      {children}
    </Center>
  );
}

export default FillAndCenter;
