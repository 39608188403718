import { Box, Stack } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import useDisplayStore from '../../containers/Timeline/useDisplayStore';

export default function DocumentSkeleton() {
  const { documentWidth, documentHeight } = useDisplayStore((state) => state.PDFDimensions);
  return (
    <>
      <Stack sx={{ height: '40px', padding: '0.25rem 1rem 0.25rem 1rem' }}>
        <Skeleton variant="rectangular" width={340} height={30} sx={{ margin: 'auto' }} />
      </Stack>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '1rem',
          justifyContent: 'space-between',
          padding: '9px 1rem 9px 1rem',
        }}
      >
        <Skeleton variant="rectangular" width={130} height={32} sx={{ borderRadius: '18px' }} />
        <Skeleton variant="rectangular" width={160} height={32} />
        <Skeleton variant="rectangular" width={130} height={32} sx={{ borderRadius: '18px' }} />
      </Stack>
      <Box
        sx={{
          height: 'calc(100% - 106px)',
          overflowY: 'hidden',
          textAlign: 'center',
        }}
      >
        <Skeleton
          sx={{ margin: 'auto' }}
          variant="rectangular"
          width={documentWidth}
          height={documentHeight}
        />
      </Box>
    </>
  );
}
