import { useEffect, useState, useCallback, useRef } from 'react';
import { Container, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import axios from 'axios';
import apiUrl from '../../library/utilities/apiUrl';
import TemplateTableDisplay from './TemplateTableDisplay';
import NewTemplateModal from '../../components/NewTemplateModal';
import Loading from '../../components/common/Loading';
import PageHeader from '../../components/common/PageHeader';
import { useUser } from '../../library/contexts/AuthContext';
import { useActivityLog } from '../../components/ActivityTracker/ActivityTracker';

export const useFetchTemplates = (username) => {
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const abortControllerRef = useRef(null);

  const fetch = useCallback(async () => {
    if (username) {
      try {
        setIsLoading(true);
        const dataResponse = await axios.post(
          `${apiUrl}getTemplates`,
          { userID: username },
          { signal: abortControllerRef?.current?.signal },
        );
        setIsLoading(false);
        setTemplates(dataResponse.data || []);
      } catch (e) {
        if (e.message !== 'canceled') {
          toast.error('Failed to grab templates...', {
            toastId: 'load-templates',
          });
          console.error(e);
        }
      }
    }
  }, [username]);

  useEffect(() => {
    abortControllerRef.current = new AbortController();
    fetch();
    return () => abortControllerRef.current.abort();
  }, [fetch]);

  return { isLoading, templates, refetch: fetch };
};

function TemplateSelection() {
  const logUserActivity = useActivityLog();
  const [isModalOpen, setModalOpen] = useState(false);
  const { user } = useUser();
  const { isLoading, templates, refetch } = useFetchTemplates(user.username);

  const onModalClose = (e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setModalOpen(!isModalOpen);
  };

  useEffect(() => {
    logUserActivity({
      activity: 'report-templates',
    });
  }, []);

  return (
    <Container maxWidth="lg" style={{ marginTop: '2rem' }}>
      <PageHeader
        title="Templates"
        actions={
          <Button
            variant="contained"
            sx={{ backgroundColor: 'primary.light', mt: '-0.4rem' }}
            onClick={() => setModalOpen(true)}
            startIcon={<AddIcon />}
          >
            New Template
          </Button>
        }
      />
      <div style={{ marginTop: '2rem' }}>
        {!isLoading ? (
          <TemplateTableDisplay
            templates={templates}
            currentUser={user.username}
            refetchTemplates={refetch}
          />
        ) : (
          <Loading sx={{ marginTop: '2rem' }} text="Gathering your templates..." />
        )}
      </div>
      <NewTemplateModal
        onModalClose={onModalClose}
        onCreateNewFile={refetch}
        templates={templates}
        isOpen={isModalOpen}
      />
    </Container>
  );
}

export default TemplateSelection;
