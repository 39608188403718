import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * Page header used in my case and templates.
 */
function PageHeader(props) {
  const { title, subtitle, actions } = props;
  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
      <div style={{ display: 'inline-block' }}>
        <Typography variant="h1">{title}</Typography>
        <div style={{ fontSize: '1rem' }}>{subtitle}</div>
      </div>
      <div
        style={{
          marginTop: '0.5rem',
          marginRight: '1.5rem',
          display: 'flex',
        }}
      >
        {actions}
      </div>
    </div>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  actions: PropTypes.element,
};

export default PageHeader;
