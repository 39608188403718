import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Tooltip,
  Box,
} from '@mui/material';

export default function DeleteDialog({
  open,
  onClose,
  onConfirm,
  title,
  content,
  contentText,
  confirmText = 'Confirm Deletion',
  confirmDisabled,
  cancelText,
  disabledTooltip = '',
  footerComponent = undefined,
  sx = {},
}) {
  return (
    <Dialog
      id="delete-dialog"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" onClose={onClose}>
        <Typography variant="h3">{title}</Typography>
        <IconButton
          aria-label="close"
          sx={(theme) => ({
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          })}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {content}
        <DialogContentText id="alert-dialog-description" sx={{ fontSize: '16px' }}>
          {contentText}
        </DialogContentText>
      </DialogContent>
      {footerComponent && <DialogContent>{footerComponent}</DialogContent>}
      <DialogActions>
        {cancelText && (
          <Button
            onClick={onClose}
            variant="outlined"
            className="confirm-delete"
            aria-label="confirm deletion"
          >
            {cancelText}
          </Button>
        )}
        <Tooltip title={confirmDisabled === true ? disabledTooltip : ''}>
          <Box
            sx={{
              ml: '.5rem',
            }}
          >
            <Button
              onClick={onConfirm}
              disabled={confirmDisabled === true}
              variant="outlined"
              className="confirm-delete"
              aria-label="confirm deletion"
              sx={sx}
            >
              {confirmText}
            </Button>
          </Box>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
}
