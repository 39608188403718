import React from 'react';
import { Divider } from '@mui/material';
import Theme from '../../theme';

const getNewWidth = (side, { right, left, width }, { clientX }) => {
  switch (side) {
    case 'right':
      return clientX - right + width;
    case 'left':
      return left - clientX + width;
    default:
      throw new Error(`Unhandled side: ${side}`);
  }
};

function WidthCustomizer({
  setWidth,
  containerRef,
  dividerPosition = 'right',
  maxWidth = 470,
  minWidth = 240,
  storageName = 'timelineWidth',
}) {
  const dividerRef = React.useRef(null);

  const makeHandleMousemove = (rect) => (e) => {
    document.body.style.userSelect = 'none';

    const newWidth = Math.min(Math.max(getNewWidth(dividerPosition, rect, e), minWidth), maxWidth);
    window.sessionStorage.setItem(storageName, newWidth);
    setWidth(newWidth);
  };

  const handleMousedown = () => {
    if (!containerRef.current) {
      throw new Error('Ref current is empty');
    }
    const handler = makeHandleMousemove(containerRef.current.getBoundingClientRect());
    document.addEventListener('mousemove', handler);
    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', handler);
      document.body.style.userSelect = '';
    });
  };

  const setDividerHover = (e) => {
    if (dividerPosition === 'left') {
      e.target.style.borderLeftColor = Theme.palette.mediumGrey.light;
    } else {
      e.target.style.borderRightColor = Theme.palette.mediumGrey.light;
    }
  };

  const setDividerDefault = (e) => {
    e.target.style.borderColor = Theme.palette.background.default;
  };

  return (
    <Divider
      id="width-customizer"
      ref={dividerRef}
      orientation="vertical"
      flexItem
      sx={{
        cursor: 'ew-resize',
        width: '8px',
        zIndex: 1,
        backgroundColor: Theme.palette.background.default,
        borderColor: Theme.palette.background.default,
        borderWidth: '2px',
      }}
      onMouseDown={handleMousedown}
      onMouseEnter={setDividerHover}
      onMouseLeave={setDividerDefault}
    />
  );
}
export default WidthCustomizer;
