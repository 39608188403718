import { Box, BoxProps, CardProps } from '@mui/material';
import { useState } from 'react';
import CustomRadioCard from './CustomRadioCard';

interface RadioCardSelectorProps extends BoxProps {
  options: {
    title: string;
    icon: string;
  }[];
  radioOnChange?: (value: string) => void;
  cardProps: CardProps;
}

RadioCardSelector.defaultProps = {
  radioOnChange: () => {},
};

function RadioCardSelector({
  options,
  radioOnChange,
  cardProps,
  ...boxProps
}: RadioCardSelectorProps) {
  const [selectedValue, setSelectedValue] = useState('');

  const handleChange = (newValue: string) => {
    setSelectedValue(newValue);
    if (radioOnChange) {
      radioOnChange(newValue);
    }
  };

  return (
    <Box {...boxProps}>
      {options.map((option) => (
        <CustomRadioCard
          key={option.title}
          title={option.title}
          icon={option.icon}
          selected={selectedValue === option.title}
          onSelectedValueChange={handleChange}
          {...cardProps}
        />
      ))}
    </Box>
  );
}

export default RadioCardSelector;
