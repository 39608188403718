import { Box } from '@mui/material';
import { DocumentSimilarityRecord } from '../../api';
import Theme from '../../theme';

type DuplicatesListChipProps = {
  selectedSimilarity: DocumentSimilarityRecord | null;
  similarity: DocumentSimilarityRecord;
  navigateTo: (original: string, compare: string) => void;
};

export function DuplicatesListChip({
  selectedSimilarity,
  similarity,
  navigateTo,
}: DuplicatesListChipProps) {
  const duplicateStatusLabel = {
    Duplicate: 'Duplicate',
    NotDuplicate: 'Not Duplicate',
    Unset: '',
  };

  const similarityScoreLabel = (score: number) => {
    if (score >= 0.98) {
      return 'High';
    }
    if (score >= 0.95) {
      return 'Medium';
    }
    if (score >= 0.9) {
      return 'Low';
    }
    return '';
  };

  const isChipSelected =
    (String(selectedSimilarity?.duplicate_of) === similarity.duplicate_of &&
      String(selectedSimilarity?.document_id) === similarity.document_id) ||
    (String(selectedSimilarity?.document_id) === similarity.duplicate_of &&
      String(selectedSimilarity?.duplicate_of) === similarity.document_id);

  return (
    <div style={{ marginLeft: '5px', width: '95%', marginTop: '1px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: '12px',
          width: '95%',
        }}
      >
        <span
          style={{
            color:
              similarity.status === 'NotDuplicate'
                ? Theme.palette.duplicatesByDocumentOrange.main
                : Theme.palette.darkgrey.main,
          }}
        >
          {duplicateStatusLabel[similarity.status]}
        </span>
        <span
          style={{
            color: Theme.palette.darkgrey.main,
          }}
        >
          {similarityScoreLabel(similarity.similarity)}
        </span>
      </div>
      <Box
        style={{
          width: '100%',
          height: 'fit-content',
          border: '1.5px solid',
          borderColor: isChipSelected
            ? Theme.palette.duplicatesByDocumentBlue.main
            : Theme.palette.borderGrey.main,
          backgroundColor: isChipSelected ? Theme.palette.duplicatesByDocumentBlue.light : 'white',
          borderRadius: '10px',
          padding: '5px 5px 8px 8px',
          fontSize: '12px',
          cursor: 'pointer',
          marginTop: '0px',
          color: isChipSelected ? Theme.palette.duplicatesByDocumentBlue.main : '',
          fontWeight: isChipSelected ? 'bold' : '',
        }}
        onClick={() => navigateTo(similarity.duplicate_of, similarity.document_id)}
      >
        Document {similarity.duplicate_of}
        <br />
        Document {similarity.document_id}
      </Box>
    </div>
  );
}
