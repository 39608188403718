/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { Backdrop, Box, CircularProgress, Divider, debounce } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import FillAndCenter from '../../components/common/Base/FillAndCenter';
import Loading from '../../components/common/Loading';
import ErrorPlaceholder from '../../components/common/Placeholders/ErrorPlaceholder';
import { selectors, useDebounceInput, useSearchQuery } from '../../library/utilities/useSearch';
import { isEmpty } from '../../utils/other';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import { useUserGroup } from '../MyCases/useCases';
import ActionFooter from './TimelineList/ActionFooter';
import SearchAndFilterBar from './TimelineList/SearchAndFilterBar';
import TimelineEntryList, { getHeight } from './TimelineList/TimelineEntryList';
import TimelineHeader from './TimelineList/TimelineHeader';
import useTimelineEntries from './gql/useTimelineEntries';
import { useTimelineEntryDescriptors } from './gql/useTimelineEntryDescriptors';
import { KeywordSearchConstraints, TimelineKeywordSearchResults } from './types/timelineTypes';
import useDisplayStore from './useDisplayStore';
import useDocumentSearchStore from './useDocumentSearchStore';
import useTimelineStore from './useTimelineStore';
import useMergeSplitTimelineEntry from './gql/useMergeSplitEntry';
import useUpdateTimelineEntry from './gql/updateTimelineEntry';
import useBreakApartTimelineEntries from './gql/useBreakApartTimelineEntries';
import { getQueryParams } from './LinkWithQuery';

type TimelineListProps = {
  caseID: string;
  handleIsSegmentDownloading: Function;
};

export default function TimelineList({ caseID, handleIsSegmentDownloading }: TimelineListProps) {
  const params = useParams();
  const timelineID = params.timelineID || '-1';
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const showThumbnails = useDisplayStore((state) => state.showThumbnails);
  const thumbnailHeight = useDisplayStore((state) => state.getThumbnailHeight());
  const windowSize = useDisplayStore((state) => state.windowSize);
  const getThumbnailGridListHeight = useDisplayStore((state) => state.getThumbnailGridListHeight);
  const [scrollOffset, setScrollOffset] = useScrollOffset({
    entryID: null,
    pageID: null,
    topOffset: -1,
  });

  const isFileProcessor = useIsFileProcessor() ?? false;
  const { data: userGroup } = useUserGroup();
  const isLabeller = userGroup === 'Labeller';

  const [updateTimelineEntry, updateEntryLoading] = useUpdateTimelineEntry();

  const { showCheckboxes, splitInProgress, mergeInProgress, setShowCheckboxes } = useTimelineStore(
    (state) => ({
      showCheckboxes: state.showCheckboxes,
      splitInProgress: state.splitInProgress,
      mergeInProgress: state.mergeInProgress,
      setShowCheckboxes: state.setShowCheckboxes,
    }),
    shallow,
  );

  const showMergeSplitAction = showCheckboxes || splitInProgress || mergeInProgress;

  const listHeight = useMemo(
    () =>
      window.innerHeight -
      (showCheckboxes || splitInProgress ? 270 : 230) +
      (isFileProcessor ? 70 : 0),
    [window.innerHeight, showCheckboxes, splitInProgress, isFileProcessor],
  );
  const [
    searchStr,
    setSearchStr,
    filters,
    setFilters,
    areFiltersEqual,
    areFiltersUpdating,
    currentCaseID,
  ] = useDocumentSearchStore(
    (state) => [
      state.searchStr,
      state.setSearchStr,
      state.filters,
      state.setFilters,
      state.areFiltersEqual,
      state.areFiltersUpdating,
      state.currentCaseID,
    ],
    shallow,
  );
  const { documentOrder: segmentOrder } = filters;

  const activeDocumentID = searchParams.get('documentID') ?? undefined;
  const startPageNumber = searchParams.get('startPage') ?? undefined;
  const endPageNumber = searchParams.get('endPage') ?? undefined;

  //TODO: Can we implement a step to get initial filters before loading timeline entries?
  const {
    isLoading: searchingInProgress,
    isFetching: searchFetching,
    data: timelineSearchResults,
  } = useSearchQuery(caseID, searchStr, (value: any) => value, {
    onSuccess: (data: KeywordSearchConstraints, searchKeyword: string) =>
      timelineEntriesQuery.refetch({
        timelineId: timelineID,
        filters,
        keywordSearchConstraints: data,
        searchKeyword,
        documentID: activeDocumentID ? [activeDocumentID] : undefined,
        startPageNumber,
        endPageNumber,
      }),
  });

  const timelineEntriesQuery = useTimelineEntries({
    timelineId: timelineID,
    filters,
    keywordSearchConstraints: timelineSearchResults,
    searchKeyword: searchStr,
    shouldSkip: areFiltersUpdating || currentCaseID !== caseID,
    documentID: activeDocumentID ? [activeDocumentID] : undefined,
    startPageNumber,
    endPageNumber,
  });

  const timelineEntryDescriptorsQuery = useTimelineEntryDescriptors({
    timelineID,
    caseID,
    documentID: activeDocumentID ? [activeDocumentID] : undefined,
    startPageNumber: Number(startPageNumber) ?? undefined,
    endPageNumber: Number(endPageNumber) ?? undefined,
  });

  const timelineEntryDescriptors =
    timelineEntryDescriptorsQuery.data?.timelineEntryDescriptors ?? [];
  const timelineEntriesTotalCount = timelineEntryDescriptors.length ?? 0;
  const timelineEntriesDisplayed = timelineEntriesQuery.data?.entriesByOrderInDataset ?? [];

  const searchResultCounts: TimelineKeywordSearchResults = useMemo(() => {
    if (isEmpty(timelineSearchResults) || isEmpty(timelineEntriesDisplayed)) {
      return {};
    }
    return selectors.filterByVisibleTimelinePages(
      timelineEntryDescriptorsQuery?.data?.timelineEntryDescriptors,
    )(timelineSearchResults);
  }, [timelineEntriesDisplayed, timelineSearchResults, timelineEntryDescriptorsQuery]);

  const handleFiltersChange = useCallback(
    async (formFilters: any) => {
      if (await areFiltersEqual(formFilters)) {
        return;
      }
      timelineEntriesQuery.refetch({
        timelineId: timelineID,
        filters: formFilters,
        keywordSearchConstraints: timelineSearchResults,
        searchKeyword: searchStr,
        documentID: activeDocumentID ? [activeDocumentID] : undefined,
      });
      setFilters(formFilters);
    },
    [timelineEntriesQuery, timelineID, timelineSearchResults, searchStr, setFilters],
  );

  const handlePaginationChange = useCallback(
    debounce((from, to) => {
      // eslint-disable-next-line no-param-reassign
      to = Math.max(to, from + 10);

      timelineEntriesQuery.fetchMore({
        timelineId: timelineID,
        filters,
        keywordSearchConstraints: timelineSearchResults,
        searchKeyword: searchStr,
        documentID: activeDocumentID ? [activeDocumentID] : undefined,
        startPageNumber: startPageNumber ?? undefined,
        endPageNumber: endPageNumber ?? undefined,
        skip: from,
        take: to - from,
      });
    }, 100),
    [timelineEntriesQuery, timelineID, filters, timelineSearchResults, searchStr],
  );

  const pageID = params.pageID ? +params.pageID : null;
  const entryID = params.entryID ? +params.entryID : null;

  const timelineEntryIndex = timelineEntriesDisplayed.findIndex(
    (timelineEntry) => timelineEntry?.id === entryID,
  );
  const currentTimelineEntry = timelineEntriesDisplayed[timelineEntryIndex];

  // Navigate to first page if none selected
  useEffect(() => {
    if (!pageID && timelineID && timelineEntriesQuery?.data?.entriesByOrderInDataset) {
      const firstEntryPage = timelineEntriesQuery.data.entriesByOrderInDataset[0]?.pages[0];
      const searchParamsObj = Object.fromEntries(searchParams.entries());
      const searchParamString = getQueryParams(searchParamsObj);
      if (firstEntryPage && firstEntryPage.entryID && firstEntryPage.id) {
        navigate(`${firstEntryPage.entryID}/${firstEntryPage.id}${searchParamString}`);
      }
    }
  }, [pageID, timelineID, timelineEntriesQuery]);

  const [showTimelineUpdated, setShowTimelineUpdated] = useState(false);
  const [mergeDialogOpen, setMergeDialogOpen] = useState(false);
  const [bulkDialog, setbulkDialog] = useState(false);

  const [targetEntryID, setTargetEntryID] = useState<number | null>(null);
  const [targetPageID, setTargetPageID] = useState<number | null>(null);

  useEffect(() => {
    setShowCheckboxes(splitInProgress || mergeInProgress);
  }, [splitInProgress, mergeInProgress]);

  const [mergeSplitTimelineEntries, mergeState] = useMergeSplitTimelineEntry();
  const [breakApartTimelineEntries, breakState] = useBreakApartTimelineEntries();

  const handleMergeAllEntries = async () => {
    try {
      const pages = timelineEntriesDisplayed.flatMap((entry) => entry.pages).map((page) => page.id);
      const firstEntry = timelineEntriesDisplayed[0];
      const lastEntry = timelineEntriesDisplayed[timelineEntriesDisplayed.length - 1]; // left for potential future use

      const payload = {
        caseID,
        entryDate: firstEntry.entryDate,
        entryID: null,
        pages,
        sourceID: firstEntry.sourceID,
        timelineID,
        author: { id: firstEntry.author_id, name: null },
        organization: { id: firstEntry.org_id, name: null },
        subject: { id: firstEntry.subject_id, name: null },
      };

      const response = await mergeSplitTimelineEntries({ data: payload });

      const newEntry = response?.data?.mergeSplitTimelineEntries?.newEntry;
      if (newEntry) {
        const searchParamsObj = Object.fromEntries(searchParams.entries());
        const searchParamString = getQueryParams(searchParamsObj);
        navigate(
          `../timeline/${timelineID}/${newEntry.id}/${
            newEntry.pages[0]?.id ?? ''
          }${searchParamString}`,
        );
      }

      toast.success('Entries merged successfully.');
      setMergeDialogOpen(false);
    } catch (error) {
      console.error(error);
      toast.error('Error merging entries. Please try again.', {
        toastId: 'merge-entries-error',
      });
    }
  };

  const outerListRef = useRef<HTMLElement>(null);

  const getTopOffset = useCallback(
    (entryID: number, pageID: number) => {
      const entryIndex = timelineEntryDescriptors.findIndex((entry) =>
        entry.pages.some((page) => page.id === pageID && entry.id === entryID),
      );

      if (entryIndex < 0) {
        return -1;
      }

      return Array.from({ length: entryIndex }).reduce(
        (accumulatedHeight: number, _, currentEntryIndex) =>
          accumulatedHeight +
          getHeight({
            index: currentEntryIndex,
            items: timelineEntryDescriptors,
            searchResultCounts,
            showThumbnails,
            getThumbnailGridListHeight,
          }),
        0,
      );
    },
    [getHeight, timelineEntryDescriptors, searchResultCounts, showThumbnails],
  );

  useEffect(() => {
    if (targetEntryID && targetPageID) {
      const topOffset = getTopOffset(targetEntryID, targetPageID);
      if (topOffset === -1) {
        return;
      }

      setScrollOffset({
        topOffset,
        entryID: targetEntryID,
        pageID: targetPageID,
      });

      outerListRef.current?.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
      setTargetEntryID(null);
      setTargetPageID(null);
    }
  }, [targetEntryID, targetPageID, getTopOffset, setScrollOffset]);

  useEffect(() => {
    if (!entryID || !pageID) {
      return;
    }
    const topOffset = getTopOffset(entryID, pageID);
    if (topOffset === -1) {
      return;
    }
    const isSamePosition = scrollOffset?.topOffset === topOffset;

    if (isSamePosition) {
      return;
    }

    setScrollOffset({
      topOffset,
      entryID,
      pageID,
    });

    outerListRef.current?.scrollTo({
      top: topOffset,
      behavior: 'smooth',
    });
  }, [timelineEntryDescriptors, pageID, entryID, showThumbnails, thumbnailHeight]);

  const handleVariableListLengthScroll = useCallback((value: any) => {
    scrollOffset.topOffset = value.scrollOffset;
  }, []);

  useEffect(() => {
    if (!searchResultCounts.totalIncludingHiddenDocumentsAndPages) {
      return;
    }

    if (searchResultCounts?.totalIncludingHiddenDocumentsAndPages >= 5000) {
      toast.warn('Search results limited to 5000 results. Consider refining your search.');
    }
  }, [searchResultCounts?.totalIncludingHiddenDocumentsAndPages]);

  const { onExportTimelineClick }: { onExportTimelineClick: Function } = useOutletContext();

  const exportFilteredSortedTimeline = useCallback(() => {
    const pageIDsArray: number[] = [];

    timelineEntryDescriptors.forEach(
      (entry) => entry.pages?.forEach((page) => pageIDsArray.push(page.id)),
    );

    onExportTimelineClick(timelineID, timelineEntriesDisplayed.length, segmentOrder, pageIDsArray);
  }, [
    timelineEntryDescriptors,
    timelineID,
    timelineEntriesDisplayed,
    segmentOrder,
    onExportTimelineClick,
  ]);

  const debounceInputHandler = useDebounceInput({
    initialValue: searchStr,
    delay: 500,
    onChange: setSearchStr,
  });

  const listKey = useMemo(
    () =>
      JSON.stringify(timelineEntryDescriptors.length) +
      JSON.stringify(filters) +
      JSON.stringify(timelineSearchResults) +
      JSON.stringify(filters?.contentTypes) +
      JSON.stringify(filters?.documentID) +
      JSON.stringify(filters?.sources) +
      JSON.stringify(filters?.subContentTypes) +
      JSON.stringify(windowSize.height) +
      JSON.stringify(startPageNumber) +
      JSON.stringify(endPageNumber) +
      showMergeSplitAction,
    [
      timelineEntryDescriptors.length,
      filters,
      timelineSearchResults,
      filters?.contentTypes,
      filters?.documentID,
      filters?.sources,
      filters?.subContentTypes,
      windowSize,
      startPageNumber,
      endPageNumber,
      showMergeSplitAction,
    ],
  );

  const onMergePreviousEntry = useCallback(
    // eslint-disable-next-line no-shadow
    async (entryID: number) => {
      const timelineEntryIndex = timelineEntriesDisplayed.findIndex(
        (timelineEntry) => timelineEntry?.id === entryID,
      );
      if (timelineEntryIndex === -1) {
        toast.error(
          'Timeline entry not found during merge. Please ensure you have loaded both the entry being merged, and the entry it is being merged into by loading and viewing them in the PDF viewer.',
        );
        return;
      }
      const previousTimelineEntry = timelineEntriesDisplayed[timelineEntryIndex - 1];
      if (!previousTimelineEntry) {
        toast.error(
          'Timeline entry not found during merge. Please ensure you have loaded both the entry being merged, and the entry it is being merged into by loading and viewing them in the PDF viewer.',
        );
        return;
      }
      // merge pages in current entry into previous entry
      const currentTimelineEntry = timelineEntriesDisplayed[timelineEntryIndex];
      const pages = [...previousTimelineEntry.pages, ...currentTimelineEntry.pages].map(
        (p) => +p.id,
      );
      const sourceID = previousTimelineEntry?.sourceID;
      const date = previousTimelineEntry?.entryDate;
      const author = { id: previousTimelineEntry?.author_id, name: null };
      const organization = { id: previousTimelineEntry?.org_id, name: null };
      const subject = { id: previousTimelineEntry?.subject_id, name: null };

      const response = await mergeSplitTimelineEntries({
        data: {
          caseID,
          entryDate: date,
          entryID: null,
          pages,
          sourceID,
          timelineID,
          author,
          organization,
          subject,
        },
      });

      const newEntry = response?.data?.mergeSplitTimelineEntries?.newEntry;
      if (newEntry && newEntry.pages?.find((page: { id: number }) => page.id === pageID)) {
        const searchParamsObj = Object.fromEntries(searchParams.entries());
        const searchParamString = getQueryParams(searchParamsObj);
        navigate(`../timeline/${timelineID}/${newEntry.id}/${pageID}${searchParamString}`);
      }
    },
    [timelineEntriesDisplayed, caseID, timelineID, mergeSplitTimelineEntries],
  );

  const onBreakApartEntries = useCallback(
    async (entryID: number) => {
      const response = await breakApartTimelineEntries({
        data: {
          entryID,
          caseID,
          timelineID,
        },
      });
      const updatedPages = response?.data?.breakApartTimelineEntries?.updatedPages;
      const currentPage = updatedPages?.find((page: { id: number }) => page.id === pageID);
      if (updatedPages && currentPage) {
        setTargetEntryID(currentPage.timeline_entry_id);
        setTargetPageID(pageID);
        const searchParamsObj = Object.fromEntries(searchParams.entries());
        const searchParamString = getQueryParams(searchParamsObj);
        navigate(
          `../timeline/${timelineID}/${currentPage.timeline_entry_id}/${pageID}${searchParamString}`,
        );
      }
    },
    [caseID, timelineID, pageID, breakApartTimelineEntries],
  );

  const showError = timelineEntriesQuery.error != null;
  const showLoading =
    !timelineEntriesQuery.called ||
    timelineEntryDescriptorsQuery.data === undefined ||
    timelineEntriesQuery.loading ||
    timelineEntriesQuery.data === undefined ||
    updateEntryLoading?.loading ||
    searchFetching;
  const showMergeButton = isFileProcessor || isLabeller;
  const showMergeOrSplitLoading = mergeState.loading || breakState.loading;
  const showNoResults = timelineEntriesDisplayed?.length === 0;

  return (
    <Box
      sx={{
        height: '100%',
        position: 'relative',
        backgroundColor: 'selectedGrey.main',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '.5rem',
          paddingTop: '1rem',
          paddingBottom: '0.2rem',
        }}
      >
        <TimelineHeader
          entryID={entryID}
          currentTimelineEntry={currentTimelineEntry}
          timelineEntries={timelineEntriesDisplayed}
          caseID={caseID}
          exportFilteredSortedTimeline={exportFilteredSortedTimeline}
          mergeDialogOpen={mergeDialogOpen}
          handleMergeAllEntries={handleMergeAllEntries}
          setMergeDialogOpen={setMergeDialogOpen}
          bulkDialog={bulkDialog}
          setbulkDialog={setbulkDialog}
        />

        <Divider sx={{ marginBottom: '0.5rem', marginTop: '0.8rem' }} />
        <>
          <SearchAndFilterBar
            searchStr={searchStr}
            debounceInputHandler={debounceInputHandler}
            searchResultCounts={searchResultCounts}
            searchingInProgress={searchingInProgress}
            handleFiltersChange={handleFiltersChange}
            isFileProcessor={isFileProcessor}
          />

          <Divider />
        </>
      </Box>
      {showError ? (
        <ErrorPlaceholder text="Failed to load timeline entries :(" />
      ) : showLoading ? (
        <FillAndCenter>
          <Loading text="Loading Timeline..." />
        </FillAndCenter>
      ) : showNoResults ? (
        <FillAndCenter>
          <div>No Entries to display.</div>
          <div>Consider adjusting your filters or search query.</div>
        </FillAndCenter>
      ) : (
        <Box position="relative">
          <Backdrop
            open={showMergeOrSplitLoading}
            style={{ zIndex: '1000', position: 'absolute', opacity: 0.9 }}
          >
            <CircularProgress color="primary" />
          </Backdrop>
          <MemoizedTimelineEntriesList
            listKey={listKey}
            items={timelineEntriesDisplayed}
            itemDescriptors={timelineEntryDescriptors}
            count={timelineEntriesTotalCount}
            searchResultCounts={searchResultCounts}
            outerRef={outerListRef}
            height={listHeight}
            initialScrollOffset={scrollOffset?.topOffset}
            onScroll={handleVariableListLengthScroll}
            onMergePreviousEntry={onMergePreviousEntry}
            onBreakApartEntries={onBreakApartEntries}
            onLoadMoreItems={handlePaginationChange}
            handleIsSegmentDownloading={handleIsSegmentDownloading}
            setShowTimelineUpdated={setShowTimelineUpdated}
            updateTimelineEntry={updateTimelineEntry}
            showMergeButton={showMergeButton}
            caseID={caseID}
            currentDocumentID={activeDocumentID}
            showMergeSplitAction={showMergeSplitAction}
          />
        </Box>
      )}
      <ActionFooter
        currentDocumentID={activeDocumentID}
        mergeTimeline={mergeSplitTimelineEntries}
        setShowTimelineUpdated={setShowTimelineUpdated}
        showMergeSplitAction={showMergeSplitAction}
        showTimelineUpdated={showTimelineUpdated}
      />
    </Box>
  );
}

const MemoizedTimelineEntriesList = memo(
  TimelineEntryList,
  (prevProps, nextProps) =>
    prevProps.listKey === nextProps.listKey && prevProps.items === nextProps.items,
);

type ScrollOffset = {
  entryID: number | null;
  pageID: number | null;
  topOffset: number;
};
const useScrollOffset = (initial: ScrollOffset) => useState<ScrollOffset>(initial);
