/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon } from '@mui/material';

export default function Cases(props, { color }) {
  return (
    <SvgIcon {...props} viewBox="0 0 82 82" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.2918 37.5833H22.8751C22.2078 37.5833 21.6668 38.1242 21.6668 38.7916V43.6249C21.6668 44.2923 22.2078 44.8333 22.8751 44.8333H25.2918C25.9591 44.8333 26.5001 44.2923 26.5001 43.6249V38.7916C26.5001 38.1242 25.9591 37.5833 25.2918 37.5833Z"
        fill={color}
      />
      <path
        d="M62.7501 43.6251C62.7491 44.5862 62.3668 45.5076 61.6872 46.1872C61.0076 46.8668 60.0862 47.249 59.1251 47.2501H56.7085C55.7474 47.249 54.826 46.8668 54.1464 46.1872C53.4668 45.5076 53.0845 44.5862 53.0835 43.6251V42.4167H28.9168V43.6251C28.9157 44.5862 28.5335 45.5076 27.8539 46.1872C27.1743 46.8668 26.2529 47.249 25.2918 47.2501H22.8751C21.914 47.249 20.9926 46.8668 20.313 46.1872C19.6334 45.5076 19.2512 44.5862 19.2501 43.6251V42.4167H14.4168V61.7501C14.4168 62.7115 14.7987 63.6335 15.4785 64.3134C16.1583 64.9932 17.0804 65.3751 18.0418 65.3751H63.9585C64.9199 65.3751 65.8419 64.9932 66.5217 64.3134C67.2015 63.6335 67.5835 62.7115 67.5835 61.7501V42.4167H62.7501V43.6251Z"
        fill={color}
      />
      <path
        d="M65.1667 24.2917H53.0834V19.4583C53.0834 18.1765 52.5742 16.9471 51.6677 16.0407C50.7613 15.1342 49.5319 14.625 48.25 14.625H33.75C32.4682 14.625 31.2388 15.1342 30.3324 16.0407C29.4259 16.9471 28.9167 18.1765 28.9167 19.4583V24.2917H16.8334C15.8723 24.2927 14.9509 24.675 14.2713 25.3546C13.5917 26.0342 13.2094 26.9556 13.2084 27.9167V36.375C13.2094 37.3361 13.5917 38.2575 14.2713 38.9371C14.9509 39.6167 15.8723 39.9989 16.8334 40H19.25V38.7917C19.2511 37.8306 19.6334 36.9092 20.3129 36.2296C20.9925 35.55 21.914 35.1677 22.875 35.1667H25.2917C26.2528 35.1677 27.1742 35.55 27.8538 36.2296C28.5334 36.9092 28.9157 37.8306 28.9167 38.7917V40H53.0834V38.7917C53.0844 37.8306 53.4667 36.9092 54.1463 36.2296C54.8259 35.55 55.7473 35.1677 56.7084 35.1667H59.125C60.0861 35.1677 61.0075 35.55 61.6871 36.2296C62.3667 36.9092 62.749 37.8306 62.75 38.7917V40H65.1667C66.1278 39.9989 67.0492 39.6167 67.7288 38.9371C68.4084 38.2575 68.7907 37.3361 68.7917 36.375V27.9167C68.7907 26.9556 68.4084 26.0342 67.7288 25.3546C67.0492 24.675 66.1278 24.2927 65.1667 24.2917ZM33.75 24.2917V19.4583H48.25V24.2917H33.75Z"
        fill={color}
      />
      <path
        d="M59.1251 37.5833H56.7084C56.0411 37.5833 55.5001 38.1242 55.5001 38.7916V43.6249C55.5001 44.2923 56.0411 44.8333 56.7084 44.8333H59.1251C59.7924 44.8333 60.3334 44.2923 60.3334 43.6249V38.7916C60.3334 38.1242 59.7924 37.5833 59.1251 37.5833Z"
        fill={color}
      />
    </SvgIcon>
  );
}
