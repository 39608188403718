/* eslint-disable react/jsx-props-no-spreading */
import styled from '@mui/styles/styled';
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Success from '../icons/Success';
import Error from '../icons/Error';

/**
 * Case processing status dialog
 */

const DialogRoot = styled(MuiDialog, {
  name: 'Dialog',
  slot: 'Root',
})({
  '& .MuiDialog-paper': {
    height: '20rem',
    minWidth: '30%',
    maxHeight: '60%',
    width: '13rem',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

export default function CaseProcessingStatus({ open, retry, onClose, caseStatus, ...props }) {
  const navigate = useNavigate();

  return (
    <DialogRoot open={open} {...props}>
      {caseStatus === 'success' ? (
        <Success
          sx={{
            marginTop: '3rem',
            fontSize: '5rem',
          }}
        />
      ) : (
        <Error
          sx={{
            marginTop: '3rem',
            fontSize: '5rem',
          }}
        />
      )}
      <DialogTitle component="div" sx={{ color: '#3658ad', marginBottom: '-10px' }}>
        <Typography variant="h4" fontSize="1rem">
          {caseStatus === 'success' ? 'Success!' : 'Uh-oh!'}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center', overflow: 'hidden' }}>
        <Typography variant="body2" sx={{ marginTop: 1 }}>
          {caseStatus === 'success'
            ? "We've received your files."
            : "Looks like we've encountered an error with one of your files"}
        </Typography>
        <Typography variant="body2">
          {caseStatus === 'success'
            ? 'We will send you an email once your case is ready!'
            : 'Please try again!'}
        </Typography>
        <Box
          style={{
            textAlign: 'center',
            marginTop: '2.5rem',
            marginBottom: '1em',
          }}
        >
          {caseStatus === 'error' && (
            <Button variant="outlined" size="small" onClick={() => retry()}>
              Retry
            </Button>
          )}
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              if (onClose) {
                onClose();
              }
              navigate('/cases');
            }}
          >
            Back to Cases
          </Button>
        </Box>
      </DialogContent>
    </DialogRoot>
  );
}

CaseProcessingStatus.propTypes = {
  open: PropTypes.bool,
  link: PropTypes.string,
  retry: PropTypes.func,
  onClose: PropTypes.func,
  caseStatus: PropTypes.oneOf(['success', 'error']),
};

CaseProcessingStatus.defaultProps = {
  open: true,
  link: '',
  retry: undefined,
  onClose: undefined,
  caseStatus: 'success',
};
