import { useCallback } from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { useApolloClient } from '@apollo/client';
import {
  SetOriginalPageDuplicateInput,
  useSetNewOriginalMutation,
  GetDuplicateStatsDocument,
  GetTimelineEntriesDocument,
  GetTimelineEntriesDescriptorsDocument,
} from '../../../__generated__/graphql';

const useSetNewOriginalDuplicate = () => {
  const [setNewOriginalDuplicateMutation, state] = useSetNewOriginalMutation();
  const client = useApolloClient();

  return [
    useCallback(
      async (data: SetOriginalPageDuplicateInput) => {
        try {
          return await setNewOriginalDuplicateMutation({
            variables: {
              data,
            },
            update: (cache, { data }) => {
              if (data) {
                client.refetchQueries({
                  include: [
                    GetTimelineEntriesDocument,
                    GetTimelineEntriesDescriptorsDocument,
                    GetDuplicateStatsDocument,
                  ],
                });
              }
            },
          });
        } catch (error) {
          toast.error(
            'A problem occurred while setting new duplicate original. If issues persist, please try refreshing the page.',
          );
          Sentry.captureException(error);
          throw error;
        }
      },
      [setNewOriginalDuplicateMutation],
    ),
    state,
  ];
};

export default useSetNewOriginalDuplicate;
