import { useQuery } from '@tanstack/react-query';
import { authenticatedRequest } from '../api';

async function fetchFeatureFlags() {
  const res = await authenticatedRequest<FeatureFlags>({
    method: 'GET',
    url: '/user/featureflags',
  });

  return res.data;
}

export default function useFeatureFlags() {
  const { data, error, isLoading, isError } = useQuery<FeatureFlags>(
    ['featureFlags'],
    () => fetchFeatureFlags(),
    {
      staleTime: Infinity,
    },
  );

  return { data, isLoading, isError, error };
}

export interface FeatureFlags {
  FileProcessingEnabled: boolean;
  CaseShareEmailDisabled: boolean;
  IndexReportEnabled: boolean;
  MicrosoftOIDCEnabled: boolean;
  ShareCasesByDefaultEnabled: boolean;
  PreferNestApi: boolean;
  AuthorExtractionEnabled: boolean;
  hideCaseNumbersFromUsers: boolean;
  duplicatesByDocumentEnabled: boolean;
}
