import { useState, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import ChangePassword from './Settings/ChangePassword';
import MFASetup from '../Auth/MFASetup';
import UserDetails from './Settings/UserDetails';
import { AuthContext } from '../../library/contexts/AuthContext';

export default function AccountSettings(props) {
  const { settingsOpen, handleSettingsClose } = props;
  const { user, removeMFAFromAccount, updateUserState } = useContext(AuthContext);
  const [settingSelected, setSettingSelected] = useState('UserDetails');
  const classes = styles();

  const onMfaComplete = () => {
    updateUserState();
    setSettingSelected('UserDetails');
  };

  const settingComponents = {
    UserDetails: (
      <UserDetails
        setSettingSelected={setSettingSelected}
        removeMFAFromAccount={removeMFAFromAccount}
        user={user}
      />
    ),
    'Change Password': (
      <ChangePassword
        handleSettingsClose={handleSettingsClose}
        back={() => setSettingSelected('UserDetails')}
      />
    ),
    MFA: <MFASetup onComplete={onMfaComplete} back={() => setSettingSelected('UserDetails')} />,
  };

  return (
    <Dialog
      onClose={handleSettingsClose}
      aria-labelledby="dialogTitle"
      open={settingsOpen}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="dialogTitle" onClose={handleSettingsClose} sx={{ fontSize: '1.2rem' }}>
        Account Settings
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          onClick={handleSettingsClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent} style={{ padding: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.settingBody}>{settingComponents[settingSelected]}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
const styles = makeStyles(() => ({
  iconBtn: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  sidebar: {
    width: '200px',
    height: '100%',
    minHeight: 280,
    borderRight: 'thin solid #e3e3e3',
  },
  dialogContent: {
    padding: 0,
  },
  listContainer: {
    padding: 0,
  },
  selected: {
    backgroundColor: '#DEECFF',
  },
  settingBody: {
    padding: 8,
    width: '100%',
  },
}));
