import { Chip, InputAdornment, TextField, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import SearchIcon from '../icons/SearchIcon';

function Searchbar(props) {
  const {
    label = 'Search for text',
    customStyles,
    searchStr = '',
    isLoading = false,
    totalSearchOccurances,
    handleChange,
    searchingInProgress = false,
  } = props;

  return (
    <TextField
      name="search"
      placeholder={label}
      sx={{
        width: '100%',
        flex: 85,
        '& .MuiOutlinedInput-notchedOutline': {
          marginTop: 0.65,
        },
        ...customStyles,
      }}
      onChange={handleChange}
      value={searchStr}
      disabled={isLoading}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            sx={{
              opacity: '100%',
              color: 'primary.light',
              backgroundColor: 'white',
            }}
          >
            {searchingInProgress ? (
              <CircularProgress size={18} />
            ) : totalSearchOccurances > 0 ? (
              <Chip
                label={totalSearchOccurances}
                size="small"
                sx={{ backgroundColor: '#d6f5d6' }}
                icon={<SearchIcon sx={{ color: 'green' }} />}
              />
            ) : (
              <SearchIcon sx={{ color: 'primary' }} />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
}

Searchbar.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  searchStr: PropTypes.string,
  customStyles: PropTypes.object,
  totalSearchOccurances: PropTypes.number,
  handleChange: PropTypes.func,
  isLoading: PropTypes.bool,
  searchingInProgress: PropTypes.bool,
  endAdornment: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
};

export default Searchbar;
