export default function Pencil(props) {
  return (
    <svg {...props} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4141 6.65705L8.72896 3.97187M1.00903 14.377L3.28095 14.1245C3.55852 14.0937 3.69731 14.0783 3.82703 14.0363C3.94212 13.999 4.05165 13.9464 4.15264 13.8798C4.26647 13.8047 4.36521 13.706 4.56269 13.5085L13.428 4.64316C14.1695 3.90167 14.1695 2.69947 13.428 1.95798C12.6865 1.21648 11.4843 1.21648 10.7428 1.95797L1.87751 10.8233C1.68002 11.0208 1.58128 11.1195 1.50622 11.2334C1.43962 11.3343 1.38698 11.4439 1.34973 11.559C1.30773 11.6887 1.29231 11.8275 1.26147 12.105L1.00903 14.377Z"
        stroke={props.color ?? '#344054'}
        strokeWidth="1.34259"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
