import { IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

function IconButtonContainer(props) {
  const { customStyles, tooltipText, tooltipPlacement, onClick, disableClick, size, icon } = props;

  return (
    <span style={{ ...customStyles }}>
      <Tooltip title={tooltipText || ''} placement={tooltipPlacement || 'top'}>
        <span>
          <IconButton onClick={onClick} disabled={disableClick} size={size || 'medium'}>
            {icon}
          </IconButton>
        </span>
      </Tooltip>
    </span>
  );
}

IconButtonContainer.propTypes = {
  customStyles: PropTypes.object,
  onClick: PropTypes.func,
  icon: PropTypes.element,
  tooltipText: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  disableClick: PropTypes.bool,
  size: PropTypes.string,
};

export default IconButtonContainer;
