import { useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  CreateTimelineReportInput,
  useCreateTimelineReportMutation,
} from '../../../__generated__/graphql';

const useCreateTimelineReport = () => {
  const [createTimelineReport, mutationStateInfo] = useCreateTimelineReportMutation();

  const createTimelineReportCallback = useCallback(
    async ({ variables }: { variables: CreateTimelineReportInput }) => {
      try {
        return await createTimelineReport({
          variables: { data: variables },
          update: (cache, { data }) => {
            const createdTimelineReport = data?.createTimelineReport;
            if (createdTimelineReport) {
              cache.modify({
                fields: {
                  timeline_reports(existingTimelineReports = []) {
                    return [...existingTimelineReports, createdTimelineReport];
                  },
                },
              });
            }
          },
        });
      } catch (err) {
        toast.error('Failed to create timeline report');
        throw err;
      }
    },
    [createTimelineReport],
  );

  return [createTimelineReportCallback, mutationStateInfo];
};

export default useCreateTimelineReport;
