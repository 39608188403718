import { useQuery } from '@tanstack/react-query';
import { getImagePages } from '../../../api';

export function useImagePages(caseID?: string) {
  const { data, isLoading, isError } = useQuery(
    ['getImagePages', caseID],
    () => getImagePages(caseID as string),
    {
      enabled: !!caseID,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 10,
    },
  );

  return { data: data?.data, isLoading, isError };
}
