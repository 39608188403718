import {
  Checkbox,
  Divider,
  ListItemIcon,
  ListItemText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import FilterControl from '../FilterControl';
import Tag from '../../../Tags/Tag';
import Loading from '../../../../components/common/Loading';
import useNotesStore from '../../../Notes/useNotesStore';
import useGetCustomTagsForNotes from '../../../Notes/gql/useCustomTagsForNotes';

export default function TagFilter() {
  const { caseID } = useParams();
  const { loading: isLoading, notes: noteTags } = useGetCustomTagsForNotes({
    case_id: caseID,
  });
  const tagsBeingFiltered = useNotesStore((state) => state.tagsBeingFiltered);
  const setTagsBeingFiltered = useNotesStore((state) => state.setTagsBeingFiltered);

  const handleTagSelectChange = (event) => {
    if (isLoading) {
      return;
    }
    if (event.target.value.includes('all')) {
      setTagsBeingFiltered(
        tagsBeingFiltered.length > 0 ? [] : [...noteTags.map((tag) => tag.tagID), 'untagged'],
      );
    } else {
      setTagsBeingFiltered(event.target.value);
    }
  };
  return (
    <FilterControl
      control={
        <FormControl variant="standard" sx={{ width: '97%' }}>
          <InputLabel
            id="mutiple-select-label"
            sx={{
              fontSize: '0.875rem',
              paddingLeft: 1.5,
              paddingTop: 1,
              '&.Mui-focused': {
                display: 'none',
              },
              '&.MuiInputLabel-shrink': {
                display: 'none',
              },
            }}
          >
            Tags Being Displayed
          </InputLabel>
          {isLoading ? (
            <Loading />
          ) : (
            <Select
              variant="outlined"
              labelId="mutiple-select-label"
              multiple
              value={tagsBeingFiltered}
              onChange={handleTagSelectChange}
              sx={{
                width: '100%',
                maxWidth: '400px',
                mt: 2,
              }}
              renderValue={() =>
                `${tagsBeingFiltered.length}/${noteTags.length + 1} Hidden: ${tagsBeingFiltered
                  ?.map((tag) =>
                    tag === 'untagged'
                      ? 'Untagged Notes'
                      : noteTags.find((t) => t.tagID === tag)?.title,
                  )
                  .join(', ')}`
              }
              MenuProps={{
                getcontentanchorel: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'menu',
              }}
            >
              <MenuItem key="allTags" value="all" style={{ height: 36 }}>
                <ListItemIcon>
                  <Checkbox
                    checked={tagsBeingFiltered.length === 0}
                    size="small"
                    indeterminate={
                      tagsBeingFiltered.length > 0 &&
                      tagsBeingFiltered.length !== noteTags.length + 1
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={<div style={{ fontSize: '0.875rem' }}>Show All Tags</div>} />
              </MenuItem>
              <MenuItem key="noTags" value="untagged" style={{ height: 36 }}>
                <ListItemIcon>
                  <Checkbox checked={!tagsBeingFiltered.includes('untagged')} size="small" />
                </ListItemIcon>
                <ListItemText
                  primary={<div style={{ fontSize: '0.875rem' }}>Show notes with no tags</div>}
                />
              </MenuItem>
              <Divider />
              {noteTags.map((tag) => (
                <MenuItem key={tag.tagID} value={tag.tagID} style={{ height: 36 }}>
                  <ListItemIcon>
                    <Checkbox checked={!tagsBeingFiltered.includes(tag.tagID)} size="small" />
                  </ListItemIcon>
                  <ListItemText primary={<Tag tag={tag} />} />
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      }
    />
  );
}
