import { Tooltip } from '@mui/material';
import React from 'react';
import Theme from '../../../../theme';

type Props = {
  documentId: string;
  isDocumentDuplicate: boolean;
  handleUpdateDocumentToDuplicate: (entryId: string) => void;
  handleUpdateDocumentToNonDuplicate: (entryId: string) => void;
  isUpdateInProgress: boolean;
  style?: React.CSSProperties;
};

export default function DuplicateActionButtons({
  documentId,
  isDocumentDuplicate,
  handleUpdateDocumentToDuplicate,
  handleUpdateDocumentToNonDuplicate,
  isUpdateInProgress,
  style = {},
}: Props) {
  return (
    <div style={{ ...style, margin: 'auto' }}>
      <Tooltip title={!isDocumentDuplicate ? 'Mark Document As Confirmed Duplicate' : ''}>
        <span>
          <button
            type="button"
            disabled={isUpdateInProgress || isDocumentDuplicate}
            className={`sm-button ${isDocumentDuplicate && 'sm-translucent'}`}
            style={{
              marginRight: '1rem',
              backgroundColor: Theme.palette.duplicatesByDocumentBlue.main,
              color: 'white',
              fontFamily: 'Inter',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '15px',
              opacity: isDocumentDuplicate ? '50%' : '',
            }}
            onClick={() => handleUpdateDocumentToDuplicate(String(documentId))}
          >
            Duplicate
          </button>
        </span>
      </Tooltip>
      <Tooltip title={isDocumentDuplicate ? 'Mark Document as Non-Duplicate' : ''}>
        <span>
          <button
            className={`sm-button ${!isDocumentDuplicate && 'sm-translucent'}`}
            type="button"
            disabled={isUpdateInProgress || !isDocumentDuplicate}
            style={{
              marginLeft: '1rem',
              color: Theme.palette.duplicatesByDocumentBlue.main,
              backgroundColor: 'white',
              fontFamily: 'Inter',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '15px',
              opacity: !isDocumentDuplicate ? '50%' : '',
            }}
            onClick={() => handleUpdateDocumentToNonDuplicate(String(documentId))}
          >
            Not Duplicate
          </button>
        </span>
      </Tooltip>
    </div>
  );
}
