import { HorizontalSplit, CallMerge } from '@mui/icons-material';
import { Box, Card, Checkbox, Chip, Stack, Typography, Tooltip, Badge } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import IconButtonContainer from '../../components/common/IconButtonContainer';
import SearchIcon from '../../components/icons/SearchIcon';
import DateSelector from './Components/DateSelector';
import PageGridView from './Components/PageGridView';
import PageListView from './Components/PageListView';
import SourceSelector from './Components/SourceSelector';
import LinkWithQuery from './LinkWithQuery';
import CardMenu from './TimelineViewCardMenu';
import {
  TimelineEntry as TimelineEntryType,
  TimelineKeywordSearchResults,
} from './types/timelineTypes';
import useDisplayStore from './useDisplayStore';
import downloadSegment from './useSegment';
import useTimelineStore from './useTimelineStore';
import { PageControls } from '../Page/types/pageTypes';
import { Note } from '../Notes/types/noteTypes';
import MDocRules from './MDocRules';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import useSources from './gql/useSources';
import { selectors } from './useSourceContent';
import Modal from '../../components/common/HTML_components/Modal/Modal';

export default function TimelineEntry({
  entry,
  caseID,
  handleIsSegmentDownloading,
  setShowTimelineUpdated,
  updateTimelineEntry,
  showMergeButton,
  onMergePreviousEntry,
  onBreakApartEntries,
  searchResultsCounts,
  pageControls,
  notes,
  currentDocumentID,
}: EntryProps) {
  const { id: entryID, timeline_id: timelineID, pages, entryDate: date, sourceID } = entry;
  const searchMatches = searchResultsCounts?.[entry.id]?.numOfMatchesInEntry ?? 0;
  const {
    showCheckboxes,
    startTimelineMerge,
    startTimelineEntrySplit,
    toggleSelectTimelineEntry,
    splitInProgress,
    isTimelineEntrySelected,
    isTimelineEntryPartiallySelected,
  } = useTimelineStore(
    (state) => ({
      showCheckboxes: state.showCheckboxes,
      startTimelineMerge: state.startTimelineMerge,
      startTimelineEntrySplit: state.startTimelineEntrySplit,
      toggleSelectTimelineEntry: state.toggleSelectTimelineEntry,
      splitInProgress: state.splitInProgress,
      isTimelineEntrySelected: state.isTimelineEntrySelected(entry.id, entry.pages),
      isTimelineEntryPartiallySelected: state.isTimelineEntryPartiallySelected(
        entry.id,
        entry.pages,
      ),
    }),
    shallow,
  );
  const [isConfirmLargeBreakApartModalOpen, setIsConfirmLargeBreakApartModalOpen] = useState(false);
  const showThumbnails = useDisplayStore((state) => state.showThumbnails);

  const params = useParams();
  const pageID = params.pageID ? +params.pageID : null;
  const currentEntryID = params.entryID ? +params.entryID : null;
  const isFileProcessor = useIsFileProcessor();

  const sourcesQuery = useSources();
  const sources = useMemo(() => selectors.asMap(sourcesQuery.data ?? []), [sourcesQuery.data]);

  const { count: missingInfoCount, fields: missingFields } = MDocRules({ entry, pages });

  const { border, borderColor } = getBorderStyles(isFileProcessor, missingInfoCount);

  const tooltipContent = missingFields.join('\n ');

  const handleDownload = useCallback(async () => {
    handleIsSegmentDownloading(true);
    try {
      await downloadSegment(caseID, entryID);
    } catch (err) {
      console.error(err);
      toast.error('Error Downloading Segment...', {
        toastId: 'segment-download',
      });
    }
    handleIsSegmentDownloading(false);
  }, [handleIsSegmentDownloading, caseID, entryID]);

  const handleChangeTimelineEntry = useCallback(
    async (
      newSource: string,
      newDate: string,
      author: object,
      subject: object,
      organization: object,
      currentPageID: BigInt,
    ) => {
      const input = {
        caseID,
        entryDate: newDate,
        entryID: entryID,
        sourceID: newSource,
        timelineID: Number(timelineID),
        author,
        subject,
        organization,
        current_page_id: currentPageID,
      };
      try {
        await updateTimelineEntry(input);
        setShowTimelineUpdated(true);
      } catch (e) {
        console.error(e);
        toast.error('Error updating timeline ...', {
          toastId: 'timeline-source-date-change',
        });
      }
    },
    [setShowTimelineUpdated, caseID, entryID, timelineID, updateTimelineEntry],
  );

  const PageListComponent = useMemo(() => (showThumbnails ? PageGridView : PageListView), []);

  const handleMergeSelectionInProgress = useCallback(() => {
    startTimelineMerge(
      date,
      pages,
      sourceID,
      entry?.author_id,
      entry?.org_id,
      entry?.subject_id,
      entryID,
    );
  }, [startTimelineMerge]);

  const handleSplitInProgress = useCallback(() => {
    startTimelineEntrySplit(
      entryID,
      date,
      sourceID,
      entry?.author_id,
      entry?.org_id,
      entry?.subject_id,
    );
  }, [startTimelineEntrySplit]);

  const handleSelectTimelineEntry = useCallback(
    () => toggleSelectTimelineEntry(entryID, pages),
    [toggleSelectTimelineEntry, entryID, pages],
  );

  const handleMergePreviousEntry = useCallback(
    () => onMergePreviousEntry(entryID),
    [entryID, onMergePreviousEntry],
  );

  const handleBreakApartEntries = useCallback(() => {
    if (pages.length >= 15) {
      setIsConfirmLargeBreakApartModalOpen(true);
      return;
    }
    onBreakApartEntries(entryID);
  }, [entryID, onBreakApartEntries, pages]);

  const handleModalConfirmLargeBreakApart = useCallback(() => {
    onBreakApartEntries(entryID);
    setIsConfirmLargeBreakApartModalOpen(false);
  }, [entryID, onBreakApartEntries]);

  return (
    <>
      <Modal
        isOpen={isConfirmLargeBreakApartModalOpen}
        onClose={() => setIsConfirmLargeBreakApartModalOpen(false)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: '1.25rem',
              fontWeight: 500,
              color: 'var(--color-text-primary)',
              marginBottom: '1rem',
            }}
          >
            Break Apart Large Grouping?
          </span>
          <span>
            Breaking apart this grouping will create {pages.length} new groupings. Are you sure you
            want to proceed?
          </span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              marginTop: '1rem',
            }}
          >
            <button
              type="button"
              onClick={() => setIsConfirmLargeBreakApartModalOpen(false)}
              className="sm-button"
              style={{
                marginRight: '1rem',
              }}
            >
              Cancel
            </button>
            <button
              onClick={handleModalConfirmLargeBreakApart}
              type="button"
              className="sm-button sm-button-primary"
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '95%',
          marginLeft: '0.25rem',
          px: '0.75rem',
          py: '0.25rem',
          marginTop: '0.5rem',
          width: '98%',
          border,
          borderColor,
          '&:hover': {
            border,
            borderColor,
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            right: 13,
            top: 13,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {!showCheckboxes && !splitInProgress && (
            <CardMenu
              currentDate={date}
              handleDownload={handleDownload}
              entry={entry}
              currentSource={sourceID}
              entryID={entryID}
              handleChangeTimelineEntry={handleChangeTimelineEntry}
              handleMergeSelectionInProgress={handleMergeSelectionInProgress}
              handleSplitInProgress={handleSplitInProgress}
              multiplePages={pages.length > 1}
            />
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            paddingLeft: showCheckboxes ? '0.75rem' : '0',
            paddingBottom: 0,
          }}
        >
          {showCheckboxes && !splitInProgress && (
            <Checkbox
              sx={{
                '& .MuiSvgIcon-root': {
                  marginLeft: -3.5,
                  fontSize: 22,
                  marginTop: -1.3,
                },
              }}
              checked={isTimelineEntrySelected}
              indeterminate={isTimelineEntryPartiallySelected}
              onClick={handleSelectTimelineEntry}
            />
          )}
          <Box sx={{ width: showMergeButton ? '80%' : '92%' }}>
            <LinkWithQuery
              style={{ textDecoration: 'none', color: '#344054' }}
              documentID={currentDocumentID}
              to={`../timeline/${timelineID}/${entryID}/${pages[0]?.id}`}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <DateSelector entryID={entryID} entryDate={entry.entryDate} />
                {isFileProcessor && missingInfoCount > 0 && !showCheckboxes && !splitInProgress && (
                  <Tooltip title={tooltipContent}>
                    <Badge
                      badgeContent={missingInfoCount}
                      color="warning"
                      sx={{
                        cursor: 'pointer',
                        marginTop: '0.75rem',
                        fontSize: '4px',
                        padding: '0 4px',
                      }}
                    />
                  </Tooltip>
                )}
                <Typography
                  component="div"
                  color="#98A2B3"
                  sx={{
                    fontSize: '0.75rem',
                    fontWeight: 500,
                    pt: '0.3rem',
                    display: 'inline-block',
                  }}
                >
                  {pages.length === 1 ? `${pages.length} Page` : `${pages.length} Pages`}
                </Typography>
              </Box>
              <Stack direction="row">
                <SourceSelector entry={entry} showCheckboxes={showCheckboxes} caseID={caseID} />
              </Stack>
            </LinkWithQuery>
          </Box>
          {showMergeButton && (
            <Stack direction="row" mt={-0.4}>
              <IconButtonContainer
                size="small"
                tooltipText="Merge with previous entry"
                onClick={handleMergePreviousEntry}
                icon={<CallMerge sx={{ fontSize: '24px' }} />}
              />
              <IconButtonContainer
                size="small"
                tooltipText="Break apart timeline entry into pages"
                onClick={handleBreakApartEntries}
                customStyles={{ marginRight: '2.5rem' }}
                disableClick={pages.length < 2}
                icon={<HorizontalSplit sx={{ fontSize: '24px' }} />}
              />
            </Stack>
          )}
        </Box>

        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            marginLeft: '-1rem',
            paddingBottom: '0.5rem',
            gap: showThumbnails ? '1rem' : '0px',
            paddingX: showThumbnails ? '1rem' : '0rem',
            mt: '.5rem',
          }}
        >
          <PageListComponent
            pages={pages}
            count={pages?.length}
            searchMatches={searchMatches}
            currentPageID={pageID}
            isCurrentlyFocused={entryID === currentEntryID}
            isInTimelineView={true}
            pageControls={pageControls}
            notes={notes}
            currentDocumentID={currentDocumentID}
            isFileProcessor={isFileProcessor}
            sources={sources}
          />
        </Box>
        <Box id="bottom-of-timeline-card" sx={{ width: '100%' }}>
          {searchMatches > 0 ? (
            <Chip
              size="small"
              sx={[styles.searchChip, searchMatches > 0 && styles.bgGreen]}
              icon={<SearchIcon width="1rem" />}
              label={searchMatches}
            />
          ) : (
            <Box />
          )}
        </Box>
      </Card>
    </>
  );
}

const styles = {
  bgGreen: {
    backgroundColor: '#d6f5d6',
  },
  searchChip: {
    fontSize: '0.75rem',
  },
};

type EntryProps = {
  entry: TimelineEntryType;
  caseID: string;
  handleIsSegmentDownloading: Function;
  setShowTimelineUpdated: Function;
  showMergeButton: boolean;
  onMergePreviousEntry: (entryID: number) => void;
  onBreakApartEntries: (entryID: number) => void;
  searchResultsCounts: TimelineKeywordSearchResults;
  updateTimelineEntry: Function;
  pageControls: PageControls;
  notes: Note[];
  currentDocumentID?: string;
};

export function getBorderStyles(isFileProcessor: boolean, missingInfoCount: number) {
  if (isFileProcessor && missingInfoCount > 0) {
    return {
      border: '1px solid',
      borderColor: 'status.warning',
    };
  }
  return {
    border: 'none',
    borderColor: 'none',
  };
}
