import { Circle } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import theme from '../theme';

export type ViewedIndicatorProps = {
  isViewed: boolean;
  // eslint-disable-next-line react/require-default-props
  toggleViewed?: (() => void) | undefined;
};

export function ViewedIndicator({ isViewed, toggleViewed }: ViewedIndicatorProps) {
  let title = isViewed ? 'Viewed' : 'Not Viewed';

  if (toggleViewed != null) {
    title = isViewed ? 'Mark As Not Viewed' : 'Mark As Viewed';
  }

  return (
    <Tooltip title={title} placement="top">
      <Circle
        sx={{
          fontSize: '0.75rem',
          marginRight: '0.25rem',
          cursor: 'pointer',
          color: isViewed
            ? `${theme.palette.mediumGrey.light} !important`
            : `${theme.palette.warning.light} !important`,
          opacity: isViewed ? 0.5 : 1,
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          toggleViewed?.();
        }}
      />
    </Tooltip>
  );
}
