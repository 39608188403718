/* eslint-disable no-restricted-syntax */
export type Direction = 'asc' | 'desc';

export const directionalCompare = <T>(compare: (a: T, b: T) => number) => ({
  asc: compare,
  desc: (a: T, b: T) => compare(b, a),
});

export const mapCompare =
  <T, M>(map: (item: T) => M, compare: (a: M, b: M) => number) =>
  (a: T, b: T) =>
    compare(map(a), map(b));

export const nullishCompare =
  <T>(nullishDirection: Direction, compare: (a: T, b: T) => number) =>
  (a: T | null | undefined, b: T | null | undefined) => {
    if (a != null && b != null) {
      return compare(a, b);
    }

    return compareNullish[nullishDirection](a, b);
  };

export const multiCompare =
  <T>(...comparers: ((a: T, b: T) => number)[]) =>
  (a: T, b: T) => {
    for (const compare of comparers) {
      const comparison = compare(a, b);

      if (comparison !== 0) {
        return comparison;
      }
    }

    return 0;
  };

export const compareBigInts = directionalCompare((a: bigint, b: bigint) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
});

export const compareBooleans = directionalCompare(
  mapCompare<boolean, number>(Number, (a, b) => a - b),
);

export const compareDates = directionalCompare((a: Date, b: Date) => a.valueOf() - b.valueOf());

export const compareNumbers = directionalCompare((a: number, b: number) => a - b);

export const compareStrings = directionalCompare((a: string, b: string) => a.localeCompare(b));

export const compareNullish = directionalCompare((a: unknown, b: unknown) => {
  if (a == null && b != null) {
    return -1;
  }
  if (a != null && b == null) {
    return 1;
  }
  return 0;
});

export const compareAlphaNumeric = directionalCompare((a: string, b: string): number => {
  // Handle cases where a or b might be undefined or null
  if (a == null && b == null) {
    return 0;
  }
  if (a == null) {
    return -1;
  }
  if (b == null) {
    return 1;
  }

  const re = /(\d+)|(\D+)/g; // Regular expression to capture groups of digits or non-digits
  const aParts = a.match(re) || [];
  const bParts = b.match(re) || [];

  while (aParts.length && bParts.length) {
    const aPart = aParts.shift()!;
    const bPart = bParts.shift()!;

    // If both parts are numeric, convert them to integers for comparison
    if (!Number.isNaN(Number(aPart)) && !Number.isNaN(Number(bPart))) {
      const diff = Number(aPart) - Number(bPart);
      if (diff !== 0) {
        return diff;
      }
    } else {
      // Fallback to standard string comparison if not both numeric
      const stringComparison = aPart.localeCompare(bPart);
      if (stringComparison !== 0) {
        return stringComparison;
      }
    }
  }
  // Base cases where strings differ in length
  return aParts.length - bParts.length;
});
