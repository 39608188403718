import { useState, useContext } from 'react';
import {
  Dialog,
  Button,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import { useGetTemplate } from '../../components/NewReportModal/index';
import { useFetchTemplates } from '../Templates/TemplateSelection';
import Templates from '../../components/icons/Templates';
import { AppContext } from '../../library/contexts/AppContext';
import { useUser } from '../../library/contexts/AuthContext';

export default function InsertTemplate(props) {
  const { editorcontainer } = props;
  const { templatePopupOpen, setTemplatePopupOpen } = useContext(AppContext);
  const { user } = useUser();
  const { templates } = useFetchTemplates(user.username);
  const getTemplate = useGetTemplate();
  const [templateInsertProgress, setTemplateInsertProgress] = useState(0);
  const [templateInsertProgressString, setTemplateInsertProgressString] = useState('');
  const [isInserting, setIsInserting] = useState(false);
  const [templateSelected, setTemplateSelected] = useState(null);
  const templatesList = (templates) =>
    templates.map((template, i) => (
      <Button
        key={i}
        variant="contained"
        title={template.templateName}
        id={template.templateId}
        value={template}
        onClick={handleTemplateSelect}
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: 'center',
          mx: '4rem',
          marginTop: '0.5rem',
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'hidden',
          backgroundColor: '#FFFFFF',
          border: '0.5px solid #FFFFFF',
          color: '#000000',
          fill: '#FFFFFF',
          boxShadow: 3,
          '&:hover': {
            border: '1px solid #375D9F',
            boxShadow: 5,
            backgroundColor: '#FFFFFF',
          },
          '&:focus': {
            border: '1px solid #375D9F !important',
            boxShadow:
              '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%) !important',
            backgroundColor: '#FFFFFF !important',
          },
        }}
      >
        <div style={{ minWidth: '40%' }}>
          <p
            value={template}
            title={template.templateName}
            id={template.templateId}
            style={{
              marginBottom: 0,
              overflow: 'hidden',
              height: '1.75rem',
              textOverflow: 'ellipsis',
              fontSize: '0.9rem',
              textAlign: 'left',
              minWidth: '90%',
              maxWidth: '60%',
              lineHeight: '1.75rem',
            }}
          >
            {template.templateName}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 'row',
            minWidth: 'fit-content',
          }}
        >
          <p
            value={template}
            title={template.templateName}
            id={template.templateId}
            style={{
              marginBottom: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              height: '1.75rem',
              fontSize: '0.9rem',
              textAlign: 'right',
              lineHeight: '1.75rem',
              marginRight: '0.175rem',
              textTransform: 'none',
              minWidth: 'fit-content',
              color: '#ABAEB7',
            }}
          >
            Created by{'  '}
          </p>

          <p
            value={template}
            title={template.templateName}
            id={template.templateId}
            style={{
              marginBottom: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              height: '1.75rem',
              fontSize: '0.9rem',
              textAlign: 'right',
              lineHeight: '1.75rem',
              marginLeft: 0,
              minWidth: 'fit-content',
            }}
          >
            {[template.givenName, ' ', template.familyName]}
          </p>
        </div>
      </Button>
    ));

  function handleCompleteLoading() {
    setTemplateInsertProgress(0);
    setTemplateInsertProgressString('');
    setIsInserting(false);
  }

  async function handleTemplatePopupClose() {
    setTemplatePopupOpen(false);
    window.localStorage.setItem('templatePopupOpen', false);
  }

  function handleTemplateSelect(event) {
    const templateId = event.target.id;
    const templateName = event.target.title;
    setTemplateSelected({ templateId, templateName });
  }

  //Had to move into separate function to behave properly with setTimeout -
  //not recognized as fn with argument by itself, for unknown reasons
  function pasteText(templateSFDT) {
    editorcontainer.current.documentEditor.editor.owner.enableLocalPaste = true;
    editorcontainer.current.documentEditor.editor.paste(templateSFDT);
  }

  async function handleTemplateInsert() {
    setIsInserting(true);
    setTemplateInsertProgress(0);
    setTemplateInsertProgressString('');
    setTemplateInsertProgress(25);
    setTemplateInsertProgressString('Downloading template...');
    const templateSFDT = await getTemplate(templateSelected);
    setTemplateInsertProgress(75);
    setTemplateInsertProgressString('Inserting...');
    window.setTimeout(setTemplateInsertProgress, 500, 100);
    window.setTimeout(setTemplateInsertProgressString, 500, 'Complete! Returning to editor...');
    window.setTimeout(pasteText, 750, templateSFDT);
    window.setTimeout(setTemplateInsertProgress, 1000, 0);
    window.setTimeout(setTemplateInsertProgressString, 500, 'Complete! Returning to editor...');
    window.setTimeout(handleTemplatePopupClose, 1250);
    window.setTimeout(handleCompleteLoading, 2000);
  }

  return isInserting ? (
    <Dialog
      open={templatePopupOpen}
      onClose={handleTemplatePopupClose}
      maxWidth="sm"
      fullWidth={true}
      sx={{ justifyContent: 'center' }}
      PaperProps={{
        sx: {
          height: '15rem',
          overflow: 'hidden',
          textOverflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
    >
      <CircularProgress
        variant="determinate"
        value={templateInsertProgress}
        sx={{
          color: '#00F2BA',
          width: '5rem !important',
          height: '5rem !important',
          display: 'flex !important',
        }}
      />
      <div
        style={{
          width: '100%',
          padding: '1rem',
          marginTop: '1rem',
          paddingBottom: '0.5rem',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <DialogTitle
          id="dialogTitle"
          // onClose={() => onClose(null)} // onClose in not defined -- this will throw error if this ever got run
          sx={{
            textAlign: 'center',
            alignSelf: 'center',
            justifyContent: 'center',
            fontSize: '1rem',
            paddingTop: 0,
            paddingBottom: 0,
            fontWeight: 700,
            color: 'primary.light',
          }}
        >
          Inserting Template
        </DialogTitle>
        <Typography
          sx={{
            fontSize: '0.7rem',
            textAlign: 'center',
            width: '100%',
            color: '#002147',
          }}
        >
          {templateInsertProgressString}
        </Typography>
      </div>
    </Dialog>
  ) : (
    <Dialog
      open={templatePopupOpen}
      onClose={handleTemplatePopupClose}
      sx={{
        justifyContent: 'center',
        width: '100%',
      }}
      PaperProps={{
        sx: {
          maxHeight: '40rem',
          minWidth: '70%',
          overflow: 'hidden',
          textOverflow: 'hidden',
          display: 'flex',
        },
      }}
    >
      <div
        style={{
          width: '100%',
          padding: '1rem',
          marginTop: '3rem',
          paddingBottom: '2rem',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Templates sx={{ inTemplateInsert: true, fill: '#375D9F', fontSize: '3rem' }} />
        <DialogTitle
          id="dialogTitle"
          // onClose={() => onClose(null)} // onClose in not defined -- this will throw error if this ever got run
          sx={{
            textAlign: 'center',
            alignSelf: 'center',
            justifyContent: 'center',
            fontSize: '1rem',
            paddingTop: 0,
            paddingBottom: 0,
            fontWeight: 700,
            color: 'primary.light',
          }}
        >
          Insert Templates
        </DialogTitle>
        <Typography
          sx={{
            fontSize: '0.7rem',
            textAlign: 'center',
            color: '#002147',
          }}
        >
          Please select the template you want to insert.
        </Typography>
      </div>
      <DialogContent
        sx={{
          border: 'none',
          marginRight: '0.1rem',
          marginLeft: '0.1rem',
          paddingTop: '0rem',
          overflow: 'scroll',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {templatesList(templates).length > 0 ? (
          templatesList(templates)
        ) : (
          <Button>No templates available!</Button>
        )}
      </DialogContent>{' '}
      <DialogActions
        sx={{
          justifyContent: 'center',
          paddingBottom: '2rem',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Button
          onClick={handleTemplatePopupClose}
          aria-label="Cancel"
          variant="outlined"
          size="small"
        >
          Cancel
        </Button>
        {!isInserting ? (
          <Button
            onClick={handleTemplateInsert}
            disabled={!templateSelected}
            aria-label="insert template"
            variant="contained"
            size="small"
          >
            Insert
          </Button>
        ) : (
          <Button disabled={true} aria-label="inserting template" variant="contained" size="small">
            Inserting Template...
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
