import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';
import useFeatureFlags from '../../../config/useFeatureFlags';
import { useUser } from '../../../library/contexts/AuthContext';
import apiUrl from '../../../library/utilities/apiUrl';
import { useUserGroup } from '../../MyCases/useCases';

export default function useCaseLocks(caseInstance) {
  const { user } = useUser();
  const caseID = caseInstance?.caseID;
  const { data: userGroup } = useUserGroup();
  const caseStatus = caseInstance?.caseStatus;
  const { data: { FileProcessingEnabled = false } = {} } = useFeatureFlags();
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        removeCaseLock(caseID);
      }
    };

    window.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      removeCaseLock(caseID);
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const {
    data: lockStatuses,
    isLoading,
    isError,
  } = useQuery(
    ['caseLock', caseID],
    async () => {
      const {
        data: { access, userInCase },
      } = await axios.post(`${apiUrl}checkLocksForCase`, { caseID });
      const processingLock =
        FileProcessingEnabled &&
        (caseStatus === 'PROCESSING' || caseStatus === 'REQUIRES_PROCESSOR') &&
        userGroup !== 'Processors';

      return {
        caseAccess: !processingLock && access && caseStatus === 'READY',
        fileAccess: access,
        processorAccess: caseStatus === 'REQUIRES_PROCESSOR' && access,
        userInCase: !access ? await getUser(user, caseID, userInCase) : undefined,
        showProcessingLock: processingLock,
      };
    },
    {
      refetchInterval: 10 * 60 * 1000, //10 minutes
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: true,
      enabled: !!caseInstance && !!userGroup && !!caseID,
    },
  );

  return {
    isLoading,
    isError,
    ...(lockStatuses ?? {}),
  };
}

const removeCaseLock = (caseID) => {
  if (!caseID) {
    return;
  }

  fetch(`${apiUrl}removeCaseLock`, {
    method: 'POST',
    body: JSON.stringify({ caseID }),
    headers: {
      'Content-type': 'application/json',
      Authorization: axios.defaults.headers.common.Authorization,
    },
    keepalive: true,
  });
};

// TODO: Change this to GQL query
export async function getUser(user, caseID, userInCase) {
  const usersList = await axios.post(`${apiUrl}getUsersForCase`, {
    userID: user.username,
    caseID,
  });
  return usersList?.data?.find((userIn) => userIn.username === userInCase);
}
