import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useApolloClient, gql, ApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { getEntryTags, EntryTagsObject, updateEntryTag, UpdateEntryTagInput } from '../../api';
import { Tag } from '../../containers/Timeline/types/timelineTypes';
import { useIsFileProcessor } from '../../containers/AccountSettings/useFileProcessing';

async function fetchEntryTags(entryId: number): Promise<EntryTagsObject> {
  const res = await getEntryTags(entryId);
  return res.data as EntryTagsObject;
}

export default function useTimelineEntryTags(entryId: number) {
  return useQuery<EntryTagsObject>(['entryTags', entryId], () => fetchEntryTags(entryId));
}

export function useUpdateTimelineEntryTag() {
  const queryClient = useQueryClient();
  const apolloClient = useApolloClient();
  const isFileProcessor = useIsFileProcessor();

  return useMutation(updateEntryTag, {
    onMutate: async ({ entryId, tagId, tagType }: UpdateEntryTagInput) => {
      await queryClient.cancelQueries(['entryTags', entryId]);
      const previousEntryTags: EntryTagsObject | undefined = queryClient.getQueryData([
        'entryTags',
        entryId,
      ]);

      if (!previousEntryTags) {
        return;
      }

      updateApolloCacheAfterMutation(
        apolloClient,
        Number(entryId),
        tagType === 'content_type'
          ? previousEntryTags.documentTypeId
          : previousEntryTags.specialityId,
        tagId,
      );

      const newEntryTags = { ...previousEntryTags };
      if (tagType === 'content_type') {
        newEntryTags.documentTypeId = tagId;
      }
      if (tagType === 'speciality') {
        newEntryTags.specialityId = tagId;
      }
      queryClient.setQueryData(['entryTags', entryId], newEntryTags);
    },

    onSuccess: (_, variables) => {
      toast.success(
        `${
          variables.tagType === 'content_type' ? 'Document Type' : 'Speciality'
        } updated successfully`,
        isFileProcessor
          ? { position: 'bottom-right', autoClose: 500 }
          : {
              autoClose: 500,
              hideProgressBar: true,
            },
      );
    },

    onError: async (error, { entryId }) => {
      toast.error('A problem occurred when assigning a new entry tag');
      Sentry.captureException(error);
      queryClient.invalidateQueries(['entryTags', entryId]);
    },
  });
}

const updateApolloCacheAfterMutation = (
  apolloClient: ApolloClient<any>,
  timelineEntryId: number,
  oldTagId: number | null,
  newTagId: number | null,
) => {
  const cacheId = apolloClient.cache.identify({
    __typename: 'TimelineEntryObject',
    id: timelineEntryId,
  });
  const entry = apolloClient.readFragment({
    id: cacheId,
    fragment: GET_ENTRY_OBJECT,
  });

  if (entry) {
    let newTag = null;
    if (newTagId) {
      newTag = apolloClient.readFragment({
        id: apolloClient.cache.identify({ __typename: 'TagDisplayObject', id: newTagId }),
        fragment: GET_TAG_OBJECT,
      });
    }
    const updatedPages = entry.pages.map((page: PageFragment) =>
      updatePageTags(page, oldTagId, newTag),
    );

    apolloClient.writeFragment({
      id: cacheId,
      fragment: GET_ENTRY_OBJECT,
      data: { ...entry, pages: updatedPages },
    });
  }
};

// Helper function to update tags on a page
function updatePageTags(page: PageFragment, oldTagId: number | null, newTag: Tag | null) {
  let updatedTags = page.tags.filter((tag) => tag.id !== oldTagId);
  if (newTag) {
    updatedTags = [...updatedTags, newTag];
  }
  return { ...page, tags: updatedTags };
}

const GET_ENTRY_OBJECT = gql`
  fragment EntryUpdateFragment on TimelineEntryObject {
    pages {
      id
      tags {
        id
        name
        origin
        type
        parent_tag_id
      }
    }
  }
`;

const GET_TAG_OBJECT = gql`
  fragment NewTagFragment on TagDisplayObject {
    id
    name
    origin
    type
    parent_tag_id
  }
`;

type PageFragment = {
  id: number;
  tags: Tag[];
};
