import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Box from '@mui/material/Box';
// eslint-disable react/jsx/props-no-spreading

export default function LinearProgressWithLabel({
  value,
  label,
  progressBarStyleProps = {},
  labelStyleProps = {},
}: {
  value: number;
  label: string;
  progressBarStyleProps?: Partial<LinearProgressProps>;
  labelStyleProps?: Partial<TypographyProps>;
}) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '30rem' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={value} {...progressBarStyleProps} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" {...labelStyleProps}>
          {label}
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.defaultProps = {
  progressBarStyleProps: {},
  labelStyleProps: {},
};
