import { CSSProperties } from 'react';
import './multi-option-button.css';

type MultiOptionButtonProps = {
  onClick: (value: string) => void;
  options: { label: string; value: string }[];
  selectedValue: string;
  style?: CSSProperties;
};

export default function MultiOptionButton({
  onClick,
  options,
  selectedValue,
  style = {},
}: MultiOptionButtonProps) {
  return (
    <div
      className="sm-multi-option-button"
      style={{
        ...style,
      }}
    >
      {options.map((option) => (
        <button
          type="button"
          className="sm-button"
          style={{
            backgroundColor: selectedValue === option.value ? 'lightgrey' : 'white',
          }}
          tabIndex={-1}
          onClick={() => onClick(option.value)}
          key={`${option.value}-${option.label}`}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
}
