export default function Export(props) {
  const { color } = props;
  return (
    <svg
      {...props}
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33341 12.5352C2.32843 11.8625 1.66675 10.7168 1.66675 9.41667C1.66675 7.46369 3.15967 5.85941 5.06653 5.68281C5.45659 3.31011 7.51695 1.5 10.0001 1.5C12.4832 1.5 14.5436 3.31011 14.9336 5.68281C16.8405 5.85941 18.3334 7.46369 18.3334 9.41667C18.3334 10.7168 17.6717 11.8625 16.6667 12.5352M6.66675 13.1667L10.0001 16.5M10.0001 16.5L13.3334 13.1667M10.0001 16.5V9"
        stroke={color || '#344054'}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
