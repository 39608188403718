import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export default function MergeAll(props: {
  mergeDialogOpen: boolean;
  setMergeDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleMergeAllEntries: () => void;
}) {
  const { mergeDialogOpen, setMergeDialogOpen, handleMergeAllEntries } = props;

  const handleConfirmAndMerge = () => {
    setMergeDialogOpen(false);
    handleMergeAllEntries();
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
        }}
      >
        <Button onClick={() => setMergeDialogOpen(true)} sx={{ fontSize: '0.8rem', padding: '0' }}>
          Merge All Entries
        </Button>
      </Box>
      <Dialog open={mergeDialogOpen} onClose={() => setMergeDialogOpen(false)}>
        <DialogTitle>Confirm Merge</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to merge all entries in the file?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMergeDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAndMerge} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
