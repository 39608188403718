import { useQuery } from '@tanstack/react-query';
import { getDocumentPreview } from '../../../../api';
import useEntryPages from '../../../Timeline/useEntryPages';

type PreviewPagesParams = {
  caseId: string;
  documentId: string;
  hideBlanks: boolean;
};

export default function usePreviewPages({ caseId, documentId, hideBlanks }: PreviewPagesParams) {
  const entryPages = useEntryPages({ entryID: documentId });

  return useQuery(
    ['previewPages', caseId, documentId, hideBlanks],
    () => getDocumentPreview({ caseId, documentID: documentId, hideBlanks }),
    {
      select: (data) => {
        const previewPages = data?.data?.pages;
        if (previewPages && entryPages?.data) {
          return previewPages.map((page) => {
            const entryPage = entryPages.data.find((entry) => entry.id === page.id);
            return { ...page, pageDetails: entryPage };
          }, {});
        }
        return [];
      },
      enabled: !!entryPages?.data,
      staleTime: 1000 * 60 * 10,
    },
  );
}
