import React, { useState } from 'react';
import styled from '@mui/styles/styled';
import { IconButton as MuiIconButton, Menu } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import PropTypes from 'prop-types';

const IconButtonRoot = styled(MuiIconButton, {
  name: 'IconButtonRoot',
  slot: 'Root',
})({
  borderRadius: 10,
  boxShadow: 'none',
});

function MoreIconMenu({
  size,
  ariaL,
  children,
  transformOrigin,
  labeltext,
  anchorOrigin,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <IconButtonRoot
        variant="contained"
        aria-label={ariaL}
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        {...props}
      >
        <MoreVert sx={{ fontSize: size }} />
      </IconButtonRoot>
      <Menu
        transformOrigin={transformOrigin}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        onClick={() => setAnchorEl(null)}
      >
        {children}
      </Menu>
    </>
  );
}

MoreIconMenu.propTypes = {
  'aria-label': PropTypes.string,
  selected: PropTypes.bool,
  menu: PropTypes.element,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
};

MoreIconMenu.defaultProps = {
  'aria-label': 'drop down menu',
  selected: false,
  sx: undefined,
  menu: undefined,
};

export default React.forwardRef(MoreIconMenu);
