export default function Folder(props) {
  const { color, sx } = props;
  return (
    <svg
      viewBox="0 0 25 24"
      width="24"
      height="20"
      fill="none"
      style={{ ...sx }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.084 7L11.9684 4.76892C11.6474 4.1268 11.4868 3.80573 11.2474 3.57116C11.0356 3.36373 10.7803 3.20597 10.5001 3.10931C10.1831 3 9.82419 3 9.10628 3H5.28398C4.16388 3 3.60383 3 3.176 3.21799C2.79968 3.40973 2.49372 3.71569 2.30197 4.09202C2.08398 4.51984 2.08398 5.0799 2.08398 6.2V7M2.08398 7H17.284C18.9641 7 19.8042 7 20.446 7.32698C21.0104 7.6146 21.4694 8.07354 21.757 8.63803C22.084 9.27976 22.084 10.1198 22.084 11.8V16.2C22.084 17.8802 22.084 18.7202 21.757 19.362C21.4694 19.9265 21.0104 20.3854 20.446 20.673C19.8042 21 18.9641 21 17.284 21H6.88398C5.20383 21 4.36375 21 3.72201 20.673C3.15753 20.3854 2.69858 19.9265 2.41096 19.362C2.08398 18.7202 2.08398 17.8802 2.08398 16.2V7Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
