/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useMemo, useState, useCallback } from 'react';
import { Box, Switch, Typography } from '@mui/material';
import _ from 'lodash';
import Loading from '../../../components/common/Loading';
import useReportsStore from '../useReportsStore';
import TimelineEntriesTableSection from './IndexReportTableSection';
import EditHoverCellEffect from '../../../components/common/Table/EditHoverCellEffect';
import { CustomDataGridProps } from '../../../components/common/Table/CustomDataGridTypes';
import ExportTimelineReportButton from './ExportTimelineReportButton';
import useContentTypesAndSpecialities from '../../Timeline/gql/useContentTypesAndSpecialities';
import theme from '../../../theme';
import formatEntities from '../../../library/utilities/useEntities';
import UploadDateFilterModal from './UploadDateFilterModal';
import FilterButton from '../../../components/common/FilterButton';
import { convertDateStringToUTCDate } from '../../../library/utilities/useDates';
import { DocumentPreviewerModal } from './DocumetPreviewer/DocumentPreviewer';
import { EditableTimelineEntriesReportTitle } from './EditableIndexReportTitle';
import { EntityEditCell } from './TableCellComponents/EntityEditCell';
import { DocumentTypeEditCell } from './TableCellComponents/DocumentTypeEditCell';
import { DocumentTypeCell } from './TableCellComponents/DocumentTypeCell';
import { DocumentNameEdit } from './TableCellComponents/DocumentNameEdit';
import './timeline-entries-table.css';
import IndexTableActions from './TableCellComponents/IndexTableActions';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';
import { formatDate, processorFileStatusLabels } from './utils/tableUtils';
import DocumentComparisonModal from './DocumentComparisonModal/DocumentComparisonModal';
import { MonetaryTotalEdit } from './TableCellComponents/MonetaryTotalEdit';
import { useIndexReport } from './useIndexReport';
import { useActivityLog } from '../../../components/ActivityTracker/ActivityTracker';

const SHOULD_EXCLUDE_REPEATED_ITEMS = true;

type Props = {
  currentReport: { id: string };
};

type Option = {
  label: string;
  value: string;
};

export type PreviewEntryDetails = {
  sourceID: number;
  contentTags: Option[];
  sectionId: string;
  entryDate: string;
  documentName: string;
  isHidden: boolean;
  author: { id: number | null; name: string | null; label: string | null };
  organization: { id: number | null; name: string | null; label: string | null };
  monetary_total?: string;
  markedImportant?: boolean;
  file_id: string;
};

export default function IndexReportTable({ currentReport }: Props) {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const logUserActivity = useActivityLog();
  const [previewDocumentId, setPreviewDocumentId] = useState<string | null>(null);
  const [previewTimelineID, setPreviewTimelineID] = useState<bigint | null>(null);
  const [previewFirstPage, setPreviewFirstPage] = useState<bigint | null>(null);
  const [previewEntryDetails, setPreviewEntryDetails] = useState<PreviewEntryDetails>({
    sourceID: 0,
    contentTags: [],
    sectionId: '',
    entryDate: '',
    documentName: '',
    author: { id: null, name: null, label: null },
    organization: { id: null, name: null, label: null },
    isHidden: false,
    monetary_total: '',
    markedImportant: false,
    file_id: '',
  });

  const isFileProcessor = useIsFileProcessor();

  const {
    data: reportData,
    isLoading,
    shouldHideDuplicates,
    setShouldHideDuplicates,
    uploadedAfterDate,
    setUploadedAfterDate,
    updateIndexReportName,
    updateIndexReportSectionName,
    areIndexSectionsEmpty,
    sortModel,
    handleSortChange,
    updateIndexRow,
    updateIndexRowCache,
    handleUpdateDuplicateDocumentV1Cases,
    isFirstComparisonDocumentDuplicate,
    isSecondComparisonDocumentDuplicate,
    clearDocumentComparisonIDs,
    closeDocumentComparerModal,
    handleSetFirstDocumentComparisonID,
    handleSetSecondDocumentComparisonIDAndOpenModal,
    isDocumentComparerModalOpen,
    documentComparerDocumentIds,
    setDocumentComparerDocumentIds,
    firstComparisonDocumentSectionID,
    secondComparisonDocumentSectionID,
    handleUpdateTimelineEntryFromDocumentPreview,
    refreshIndexReport,
    handleChangeImportanceMark,
  } = useIndexReport(currentReport.id);

  const allContentTags = useContentTypesAndSpecialities();

  useEffect(() => {
    if (reportData?.case_id) {
      logUserActivity({
        activity: 'case:index',
        case_id: reportData?.case_id,
        index_report_id: reportData?.id,
      });
    }
  }, [reportData?.case_id, reportData?.id]);

  const [contentTagOptions, deprecatedTagOptions] = useMemo(() => {
    const contentTags = [];
    const deprecatedTags = [];

    allContentTags.list.forEach((tag) => {
      const mappedTag = {
        value: String(tag.id),
        label: tag.label,
        origin: tag.origin,
      };
      if (tag.origin === 'deprecated') {
        deprecatedTags.push(mappedTag);
      } else {
        contentTags.push(mappedTag);
      }
    });
    return [contentTags, deprecatedTags];
  }, [allContentTags.list]);

  const { setCurrentReport } = useReportsStore((state) => ({
    setCurrentReport: state.setCurrentReport,
  }));

  // can we just get rid of this part?
  const shouldDisableIndexExport = useMemo(() => {
    if (areIndexSectionsEmpty == null) {
      return true;
    }
    return areIndexSectionsEmpty;
  }, [areIndexSectionsEmpty]);

  const lastDocumentOfReport = useMemo(
    () =>
      reportData?.sections
        ?.slice()
        .reverse()
        .find((section) => section.rows.length > 0)
        ?.rows.slice(-1)[0],
    [reportData],
  );

  const firstDocumentOfReport = useMemo(
    () => reportData?.sections?.find((section) => section.rows.length > 0)?.rows[0],
    [reportData],
  );

  const timelineEntriesTableConfig: CustomDataGridProps = {
    columns: [
      {
        headerName: 'Document Name',
        field: 'document_name',
        flex: isFileProcessor ? 0.6 : 1,
        editable: true,
        renderCell: (params) => <EditHoverCellEffect params={params} cellValue={params.value} />,
        renderEditCell: (params) => <DocumentNameEdit {...params} defaultValue={params.value} />,
        disableColumnMenu: true,
      },
      {
        headerName: 'Tags',
        field: 'content_tags',
        flex: 1,
        editable: true,
        renderCell: (params) => <DocumentTypeCell {...params} />,
        renderEditCell: (params) => (
          <DocumentTypeEditCell
            {...params}
            contentTagOptions={contentTagOptions}
            deprecatedTagOptions={deprecatedTagOptions}
          />
        ),
        disableColumnMenu: true,
      },
      {
        headerName: 'Organization',
        field: 'organization_name',
        flex: 0.66,
        editable: true,
        disableColumnMenu: true,
        valueGetter: (params: any) => {
          if (params.row.organization_origin === 'ML') {
            return formatEntities(params.row.organization_name);
          }
          return params.row.organization_name ?? '';
        },
        renderEditCell: (params) => (
          <EntityEditCell entityType="organization" {...params} caseID={reportData?.case_id} />
        ),
        renderCell: (params) => <EditHoverCellEffect params={params} cellValue={params.value} />,
      },
      {
        headerName: 'Author',
        field: 'author_name',
        flex: 0.66,
        editable: true,
        disableColumnMenu: true,
        valueGetter: (params: any) => {
          if (params.row.author_origin === 'ML') {
            return formatEntities(params.row.author_name);
          }
          return params.row.author_name ?? '';
        },
        renderEditCell: (params) => (
          <EntityEditCell entityType="author" {...params} caseID={reportData?.case_id} />
        ),
        renderCell: (params) => <EditHoverCellEffect params={params} cellValue={params.value} />,
      },
      {
        headerName: 'Date',
        field: 'entry_date',
        flex: 0.5,
        editable: true,
        type: 'date',
        valueGetter: (params) => {
          if (typeof params.value === 'string') {
            return convertDateStringToUTCDate(params.row.entry_date);
          }
          return params.value;
        },
        disableColumnMenu: true,
        renderCell: (params) => (
          <EditHoverCellEffect
            params={params}
            cellValue={params.value}
            formatValueFunction={formatDate}
          />
        ),
      },
      {
        headerName: 'Pages',
        field: 'page_count',
        flex: 0.25,
        disableColumnMenu: true,
      },
      ...(isFileProcessor
        ? [
            {
              headerName: 'File Status',
              field: 'file_status',
              flex: 0.4,
              disableColumnMenu: true,
              valueGetter: (params) => {
                if (!(params.row.file_status in processorFileStatusLabels)) {
                  return params.row.file_status;
                }
                return processorFileStatusLabels[
                  params.row.file_status as keyof typeof processorFileStatusLabels
                ];
              },
            },
          ]
        : []),
      {
        headerName: 'Actions',
        field: 'goToSource',
        flex: 0.37,
        sortable: false,
        renderCell: (params) => {
          return (
            <IndexTableActions
              file_id={params?.row?.file_id}
              timelineID={reportData?.timeline_id}
              timelineEntryID={params.row.id}
              firstPageID={params.row.first_page_id}
              setPreviewDocumentID={setPreviewDocumentId}
              setPreviewTimelineID={setPreviewTimelineID}
              setPreviewFirstPage={setPreviewFirstPage}
              handleSetFirstDocumentComparisonID={handleSetFirstDocumentComparisonID}
              handleSetSecondDocumentComparisonIDAndOpenModal={
                handleSetSecondDocumentComparisonIDAndOpenModal
              }
              clearDocumentComparisonIDs={clearDocumentComparisonIDs}
              documentComparerDocumentIds={documentComparerDocumentIds}
              sourceName={params.row.source_name}
              contentTags={params.row.content_tags}
              setPreviewEntryDetails={setPreviewEntryDetails}
              sectionId={params.row.section_id}
              entryDate={params.row.entry_date}
              documentName={params.row.document_name}
              monetary_total={params.row.monetary_total}
              author={{ id: Number(params.row.author_id), name: params.row.author_name }}
              organization={{ id: Number(params.row.org_id), name: params.row.organization_name }}
              isHidden={params.row.is_hidden === 1}
              markedImportant={params.row.marked_important}
              isFileProcessor={isFileProcessor}
              allPagesMarkedAsDuplicate={params.row.all_pages_marked_duplicate}
              handleChangeImportanceMark={handleChangeImportanceMark}
              updateIndexRowCache={updateIndexRowCache}
            />
          );
        },
        disableColumnMenu: true,
      },
    ],
    loading: isLoading,
    slots: {
      loadingOverlay: TableLoadingIcon,
    },
    pageSizeOptions: [5, 20, 50, 100],
    initialState: {
      pagination: { paginationModel: { pageSize: 20 } },
    },
    sortModel,
    onSortModelChange: handleSortChange,
    // set to server so we can manually control sorting
    sortingMode: 'server',
    localeText: {
      noRowsLabel: "Sorry, we couldn't find any documents that match this section.",
    },

    processRowUpdate: updateIndexRow,
    getRowClassName: (params) => {
      const classNames: string[] = [];

      if (
        params.row.is_hidden === 1 ||
        (isFileProcessor && params.row.all_pages_marked_duplicate)
      ) {
        classNames.push('hidden-row');
      }
      if (isFileProcessor && params.row.file_status === 'COMPLETE') {
        classNames.push('complete-row');
      }
      return classNames.join(' ');
    },
    sx: {
      '& .MuiDataGrid-cell': {
        overflow: 'visible',
      },
      '& .MuiDataGrid-virtualScroller': {
        minHeight: '6rem',
      },
      '& .MuiDataGrid-cell--withBorder': {
        border: 'none',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
        cursor: 'default',
      },
      '& .MuiDataGrid-cell:focus-within': {
        // Tried various ways to avoid - Important required, styling is overridden by MUI otherwise
        outline: `1px solid ${theme.palette.mediumGrey.dark} !important`,
      },
    },
  };

  const onCloseFilterModal = () => {
    setIsFilterModalOpen(false);
  };
  const handleCloseDocumentPreview = () => setPreviewDocumentId(null);

  const navigateDocument = useCallback(
    (direction: 'next' | 'previous') => {
      if (!reportData?.sections) {
        return;
      }

      let currentSectionIndex = reportData.sections.findIndex(
        (s) => s.id === previewEntryDetails.sectionId,
      );

      if (currentSectionIndex === -1) {
        return;
      }

      let currentSection = reportData.sections[currentSectionIndex];
      let documentIndex = currentSection.rows.findIndex(
        (document) => document.id === previewDocumentId,
      );

      if (direction === 'next') {
        documentIndex++;
        while (currentSectionIndex < reportData.sections.length) {
          if (documentIndex < currentSection.rows.length) {
            break;
          } else {
            currentSectionIndex++;
            if (currentSectionIndex < reportData.sections.length) {
              currentSection = reportData.sections[currentSectionIndex];
              documentIndex = 0;
            } else {
              return;
            }
          }
        }
      } else {
        documentIndex--;
        while (currentSectionIndex >= 0) {
          if (documentIndex >= 0) {
            break;
          } else {
            currentSectionIndex--;
            if (currentSectionIndex >= 0) {
              currentSection = reportData.sections[currentSectionIndex];
              documentIndex = currentSection.rows.length - 1;
            } else {
              return;
            }
          }
        }
      }
      const nextDocument = currentSection.rows[documentIndex];

      if (nextDocument) {
        setPreviewDocumentId(nextDocument.id);
        setPreviewEntryDetails({
          sourceName: nextDocument.source_name,
          contentTags:
            nextDocument.content_tags?.map((tag) => ({
              value: tag.id,
              label: tag.value,
            })) ?? [],
          sectionId: nextDocument.section_id,
          entryDate: nextDocument.entry_date,
          documentName: nextDocument.document_name,
          monetary_total: nextDocument.monetary_total,
          author: {
            id: nextDocument.author_id ? +nextDocument.author_id : null,
            name: nextDocument.author_name,
          },
          organization: {
            id: nextDocument.org_id ? +nextDocument.org_id : null,
            name: nextDocument.organization_name,
          },
          isHidden: nextDocument.is_hidden,
          markedImportant: nextDocument.marked_important,
          file_id: nextDocument.file_id,
        });
      }
    },
    [reportData, previewDocumentId, previewEntryDetails],
  );

  if (isLoading) {
    return (
      <Loading
        text="Loading Index Sections..."
        typographyProps={{
          sx: {
            fontWeight: 600,
            color: 'primary.main',
          },
        }}
      />
    );
  }

  const handleSelectedDateChange = (date) => {
    setPreviewEntryDetails((prevDetails) => ({
      ...prevDetails,
      entryDate: date,
    }));
  };

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <UploadDateFilterModal
        isOpen={isFilterModalOpen}
        onClose={onCloseFilterModal}
        currentlySelectedDate={uploadedAfterDate}
        handleChangeSelectedDate={setUploadedAfterDate}
      />
      {previewDocumentId && (
        <DocumentPreviewerModal
          file_id={previewEntryDetails.file_id}
          caseId={reportData?.case_id}
          documentId={previewDocumentId}
          navigateDocument={navigateDocument}
          timelineID={previewTimelineID}
          firstPageID={previewFirstPage}
          currentEntryDetails={previewEntryDetails}
          setCurrentEntryDetails={setPreviewEntryDetails}
          onClose={handleCloseDocumentPreview}
          onUpdate={handleUpdateTimelineEntryFromDocumentPreview}
          setSelectedDate={handleSelectedDateChange}
          sectionName={
            reportData?.sections?.find((section) => section.id === previewEntryDetails.sectionId)
              ?.name
          }
          handleChangeImportanceMark={handleChangeImportanceMark}
          refreshIndexReport={refreshIndexReport}
          updateIndexRowCache={updateIndexRowCache}
          lastDocumentOfReport={lastDocumentOfReport?.id === previewDocumentId}
          firstDocumentOfReport={firstDocumentOfReport?.id === previewDocumentId}
        />
      )}
      <DocumentComparisonModal
        isDocumentComparerModalOpen={isDocumentComparerModalOpen}
        closeDocumentComparerModal={closeDocumentComparerModal}
        documentComparerDocumentIds={documentComparerDocumentIds}
        setDocumentComparerDocumentIds={setDocumentComparerDocumentIds}
        isFirstComparisonDocumentDuplicate={isFirstComparisonDocumentDuplicate}
        isSecondComparisonDocumentDuplicate={isSecondComparisonDocumentDuplicate}
        firstDocumentSectionID={firstComparisonDocumentSectionID}
        secondDocumentSectionID={secondComparisonDocumentSectionID}
        handleUpdateDocumentDuplicateStatusV1Cases={handleUpdateDuplicateDocumentV1Cases}
        updateIndexRowCache={updateIndexRowCache}
      />

      <Box
        sx={{
          mx: '2rem',
          mt: '1rem',
          pb: '4rem',
          backgroundColor: 'white',
        }}
      >
        <Box
          sx={{
            mb: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <EditableTimelineEntriesReportTitle
              value={reportData?.name ?? 'Loading...'}
              updateIndexReportName={updateIndexReportName}
              timelineReportID={currentReport.id}
              setCurrentReport={setCurrentReport}
            />
            {!isFileProcessor && (
              <ExportTimelineReportButton
                timelineReportId={currentReport.id}
                caseId={reportData?.case_id}
                shouldExcludePreviousEntries={SHOULD_EXCLUDE_REPEATED_ITEMS}
                shouldExcludeConfirmedDuplicates={shouldHideDuplicates}
                uploadedAfterDate={uploadedAfterDate}
                sortModel={sortModel[0]}
                disabled={shouldDisableIndexExport}
                disabledMessage="No documents available to export. Please adjust your filters and try again."
                reportName={reportData?.reportName}
              />
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '12px',
                color: '#667085',
                mr: '0.5rem',
              }}
            >
              Hide Confirmed Duplicates
            </Typography>
            <Switch
              checked={shouldHideDuplicates}
              onChange={() => setShouldHideDuplicates(!shouldHideDuplicates)}
              sx={{
                mr: '0.5rem',
              }}
            />
            <FilterButton
              onClick={() => setIsFilterModalOpen(true)}
              dot={uploadedAfterDate != null}
              filterCount={uploadedAfterDate != null ? 1 : 0}
            />
          </Box>
        </Box>
        {reportData?.sections?.map((section, index) => {
          // Create a copy of the base columns
          const columns = [...timelineEntriesTableConfig.columns];

          const sectionShouldIncludeAmount = section.extra_columns?.includes('monetary_total');

          const amountColumn = {
            headerName: 'Amount',
            field: 'monetary_total',
            flex: 0.5,
            editable: true,
            sortable: false,
            renderCell: (params) => {
              const value = params.value ? `$${params.value}` : '';
              return <EditHoverCellEffect params={params} cellValue={value} />;
            },
            renderEditCell: (params) => (
              <MonetaryTotalEdit {...params} defaultValue={params.value} />
            ),
            disableColumnMenu: true,
          };

          const actionsColumnIndex = columns.findIndex((col) => col.field === 'goToSource');

          if (sectionShouldIncludeAmount) {
            columns.splice(1, 0, amountColumn);
            columns[actionsColumnIndex + 1] = { ...columns[actionsColumnIndex + 1], flex: 0.42 };
          } else {
            columns[actionsColumnIndex] = { ...columns[actionsColumnIndex], flex: 0.37 };
          }

          return (
            <TimelineEntriesTableSection
              key={section.id}
              {...timelineEntriesTableConfig}
              columns={columns}
              timelineID={reportData?.timeline_id}
              caseID={reportData?.case_id}
              rows={section.rows}
              section={section}
              // accessing the first sort model as the free MUI table only supports one sort model
              sortField={sortModel[0].field}
              sortDirection={sortModel[0].sort}
              updateIndexReportSectionName={updateIndexReportSectionName}
            />
          );
        })}
      </Box>
    </div>
  );
}

function TableLoadingIcon() {
  return <Loading text="Loading Timeline Entries..." />;
}
