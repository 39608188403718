import { CardContent, Box, Card, Typography, CardHeader, IconButton, Tooltip } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import { createSelectorFunctions } from 'auto-zustand-selectors-hook';
import { useDrag } from 'react-dnd';
import { Reply, VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import Tag from '../Tags/Tag';
import AddTagButton from '../Tags/AddTagButton';
import NoteActions from './NoteActions';
import NoteInfo from './NoteInfo';
import useNotesStore from './useNotesStore';
import Truncate from '../../components/common/Truncate';
import { useRemoveTagFromNoteMutation } from '../../__generated__/graphql';
/**
 * Component to display notes in a card format
 *
 * @param note - The Note object being edited
 * @param editNote - The function to start editing this note
 */

const useNoteStore = createSelectorFunctions(useNotesStore);

function NoteCard({
  note,
  showInsertIntoReportIcon,
  setSingleNoteToInsert,
  textBeingSearched,
  editNote,
  handleDelete,
  documentName,
  currentDocumentID,
}) {
  const {
    title,
    tags,
    body,
    date,
    noteID,
    documentID,
    caseID,
    pageID,
    pageNumber,
    numberOfPages,
    privateNote,
    ownerID,
    physician,
  } = note;
  const [removeTagFromNote] = useRemoveTagFromNoteMutation();
  const [textBoxExpanded, setTextBoxExpanded] = useState(false);
  const [overflowActive, setOverflowActive] = useState(false);
  const noteSelected = useNoteStore.use.noteSelected();
  const setNoteSelected = useNoteStore.use.setNoteSelected();

  const info = `${documentName} | Page ${pageNumber}`;
  async function removeTag(tagID) {
    removeTagFromNote({ variables: { note_id: noteID, tag_id: tagID } });
  }

  const noteBodyRef = useRef();

  useEffect(() => {
    if (isOverflowActive(noteBodyRef.current)) {
      setOverflowActive(true);
      return;
    }
    setOverflowActive(false);
  }, [isOverflowActive]);

  const [, drag] = useDrag(
    () => ({
      type: 'card',
      item: {
        text: {
          info: info,
          text: body,
          physician: physician,
          date: date,
          title: title,
        },
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [noteID, body, physician, date, title],
  );

  return (
    <>
      <NoteInfo
        displayedName={documentName}
        pageNumber={pageNumber}
        documentPageNumber={numberOfPages}
      />
      <Card
        sx={{
          ...noteCardStyles.root,
          margin: '10px',
          marginTop: '0.2rem',
          border: noteSelected === noteID ? '2px solid #385D9F !important' : '2px solid #E3E8EF',
          backgroundColor: noteSelected === noteID ? '#F1F4F8 !important' : '#FFFFFF',
          cardHeader: {
            padding: '8px',
          },
          '&:hover': {
            border: '2px solid #385D9F !important',
          },
        }}
        data-testid={`note-${noteID}`}
        variant="outlined"
        onClick={() => setNoteSelected(noteSelected === noteID ? '' : noteID)}
      >
        <CardHeader
          title={
            showInsertIntoReportIcon ? (
              <>
                <Typography
                  sx={{
                    fontSize: '0.8rem',
                    fontWeight: 500,
                    width: '8rem',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {textBeingSearched !== '' ? getHighlightedText(title, textBeingSearched) : title}
                </Typography>
                <Box
                  sx={{
                    width: '50%',
                    display: 'inline flex',
                    flexDirection: 'row',
                  }}
                >
                  {physician && (
                    <Truncate maxWidth="4.3rem">
                      <Typography
                        sx={{
                          fontSize: '0.7rem',
                          display: 'inline',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          fontWeight: 300,
                          color: '#697586',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {physician !== ''
                          ? getHighlightedText(physician, textBeingSearched)
                          : physician}{' '}
                      </Typography>
                    </Truncate>
                  )}
                  {date && date !== '1900-01-01' && (
                    <Typography
                      sx={{
                        display: 'inline',
                        fontSize: '0.7rem',
                        fontWeight: 300,
                        color: '#697586',
                        minWidth: '50px',
                        whiteSpace: 'nowrap',
                        marginLeft: physician && '0.2rem',
                      }}
                    >
                      {physician && '|'} {date.replaceAll('-', '/')}
                    </Typography>
                  )}
                </Box>
              </>
            ) : (
              <>
                <Truncate maxWidth="11rem">
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      fontWeight: 500,
                    }}
                    className="fs-exclude"
                  >
                    {textBeingSearched !== ''
                      ? getHighlightedText(title, textBeingSearched)
                      : title}{' '}
                  </Typography>
                </Truncate>
                <Box
                  sx={{
                    width: '50%',
                    display: 'inline flex',
                    flexDirection: 'row',
                  }}
                >
                  {physician && (
                    <Truncate maxWidth="7rem">
                      <Typography
                        sx={{
                          fontSize: '0.7rem',
                          color: '#697586',
                          fontWeight: 300,
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          display: 'inline',
                        }}
                      >
                        {textBeingSearched !== ''
                          ? getHighlightedText(physician, textBeingSearched)
                          : physician}{' '}
                      </Typography>
                    </Truncate>
                  )}
                  {date && date !== '1900-01-01' && (
                    <Typography
                      sx={{
                        fontSize: '0.7rem',
                        fontWeight: 300,
                        color: '#697586',
                        minWidth: '50px',
                        display: 'inline',
                        whiteSpace: 'nowrap',
                        marginLeft: physician && '0.2rem',
                      }}
                    >
                      {physician && '|'} {date.replaceAll('-', '/')}
                    </Typography>
                  )}
                </Box>
              </>
            )
          }
          ref={drag}
          sx={[
            noteSelected === noteID && noteCardStyles.cardHeader,
            showInsertIntoReportIcon && noteCardStyles.cardGrab,
          ]}
          action={
            <>
              {privateNote === 0 ? (
                <Tooltip title="This note is shared">
                  <VisibilityOutlined
                    sx={{
                      transform: 'scaleX(-1)',
                      marginRight: 0.5,
                      fontSize: '1.1rem',
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="This note is private to you">
                  <VisibilityOffOutlined
                    sx={{
                      transform: 'scaleX(-1)',
                      marginRight: 0.5,
                      fontSize: '1.1rem',
                    }}
                  />
                </Tooltip>
              )}
              {showInsertIntoReportIcon && (
                <Tooltip title="Insert In Report">
                  <IconButton
                    size="small"
                    onClick={() =>
                      setSingleNoteToInsert({
                        info: info,
                        title: title,
                        physician: physician,
                        date: date,
                        body: body,
                        documentID: documentID,
                        pageID: pageID,
                      })
                    }
                  >
                    <Reply
                      sx={{
                        transform: 'scaleX(-1)',
                        fontSize: '1.1rem',
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
              <NoteActions
                editNote={() => editNote(noteID)}
                noteID={noteID}
                handleDelete={() => handleDelete(noteID)}
                caseID={caseID}
                pageID={pageID}
                ownerID={ownerID}
                documentID={currentDocumentID}
              />
            </>
          }
        />
        <CardContent
          sx={{
            paddingTop: '0.25rem',
            paddingBottom: '0.5rem',
            backgroundColor: '#FFFFFF !important',
            borderTop: noteSelected !== noteID ? '2px solid #E3E8EF' : '2px solid #F1F4F8',
          }}
        >
          <Typography
            variant="body2"
            component="p"
            ref={noteBodyRef}
            sx={{
              maxHeight: textBoxExpanded ? '400px' : '130px',
              overflowY: textBoxExpanded && 'auto',
              overflow: !textBoxExpanded && 'hidden',
              marginTop: '.5rem',
              whiteSpace: 'pre-wrap',
            }}
            className="fs-exclude"
          >
            {textBeingSearched !== '' ? getHighlightedText(body, textBeingSearched) : body}
          </Typography>
          {overflowActive && (
            <Box
              sx={{
                fontSize: '0.75rem',
                marginTop: '1rem',
                cursor: 'pointer',
                color: 'darkBlue',
              }}
              onClick={() => setTextBoxExpanded(!textBoxExpanded)}
            >
              {textBoxExpanded ? 'View Less' : 'View More'}
            </Box>
          )}
          {tags && (
            <Box sx={{ marginTop: '8px' }}>
              {tags.length > 0 ? (
                <>
                  {tags
                    .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1))
                    .map((tag) => (
                      <Tag
                        space
                        marginAuto
                        tag={tag}
                        key={tag.tagID}
                        onDelete={() => removeTag(tag.tagID)}
                      />
                    ))}
                  <AddTagButton noteID={noteID} tags={tags} caseID={caseID} />
                </>
              ) : (
                <AddTagButton text noteID={noteID} tags={tags} caseID={caseID} />
              )}
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
}

const noteCardStyles = {
  root: (theme) => ({
    '& .MuiCardHeader-action': {
      marginTop: '0px!important',
    },
    [theme.breakpoints.down('sm')]: {
      flex: '1 0 40%',
    },
  }),
  cardGrab: {
    cursor: 'grab',
  },
  cardHeader: {
    backgroundColor: '#F1F4F8',
  },
};

NoteCard.propTypes = {
  note: PropTypes.shape({
    noteID: PropTypes.string,
    title: PropTypes.string,
    tags: PropTypes.array,
    body: PropTypes.string,
    privateNote: PropTypes.number,
    documentID: PropTypes.string,
    caseID: PropTypes.string,
    pageID: PropTypes.number,
  }),
  reportOpen: PropTypes.bool,
  setSingleNoteToInsert: PropTypes.func,
  textBeingSearched: PropTypes.string,
  editNote: PropTypes.func,
};

// ty https://stackoverflow.com/questions/29652862/highlight-text-using-reactjs/43235785
function getHighlightedText(text, highlight) {
  // Split text on highlight term, include term itself into parts, ignore case
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  const highlightEscaped = escapeRegExp(highlight);
  const parts = text.split(new RegExp(`(${highlightEscaped})`, 'gi'));
  return (
    <span>
      {parts.map((part, i) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <mark key={i} style={{ backgroundColor: 'yellow', padding: 0 }}>
            {part}
          </mark>
        ) : (
          part
        ),
      )}
    </span>
  );
}

const isOverflowActive = (event) =>
  event.offsetHeight < event.scrollHeight || event.offsetWidth < event.scrollWidth;

export default NoteCard;
