export type Note = {
  noteID: string;
  caseID: string;
  documentID: string;
  pageID: string;
  ownerID: string;
  fileName?: string;

  title?: string;
  physician: string;
  body: string;
  date?: string;
  createdDate: string;
  lastModified: string;
  privateNote: number; // can this be a bool?
  pageNumber: number;

  tags?: NoteTag[];
  images?: NoteImage[];
};

export type NoteTag = {
  tagID: string;
  title: string;
  color: string; // is this always here?
  userID: string;
};

export enum NotesListOrderEnum {
  Document = 'Document',
  FirstTag = 'First Tag',
  Physician = 'Physician',
  DateAsc = 'Date Ascending',
  DateDesc = 'Date Descending',
}
export type NoteImage = {
  imageID: string;
  pageID: string;
  coordinates: {
    sx: number;
    sy: number;
    ex: number;
    ey: number;
  };
};
