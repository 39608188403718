/* eslint-disable no-restricted-syntax */
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { enablePatches } from 'immer';
import { setContext } from '@apollo/client/link/context';
import { Auth } from '@aws-amplify/auth';
import * as Sentry from '@sentry/react';
import { registerLicense } from '@syncfusion/ej2-base';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import SyncfusionLicenseKey from './SyncfusionLicenseKey.json';
import { PagesViewedObject, TimelineEntryObject } from './__generated__/graphql';
import config from './config';
import {
  PAGE_WITH_CONTENT_TYPES_AND_SPECIALITIES_FRAGMENT,
  PAGE_WITH_CONTENT_TYPES_FRAGMENT,
  PAGE_WITH_SPECIALITIES_FRAGMENT,
} from './containers/Timeline/gql/fragments';
import { AuthContextProvider } from './library/contexts/AuthContext';
import * as serviceWorker from './registerServiceWorker';
import './resources/styles/index.css';
import sentrySettings from './sentrySettings';
import { wrapQueryCacheToHandleDataNormalization } from './library/utilities/reactQueryUtils';

Sentry.init(sentrySettings);
registerLicense(SyncfusionLicenseKey.key);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const httpLink = createHttpLink({
  uri: config.api.graphQlUrl,
});

enablePatches();

const namedLinkMiddleware = new ApolloLink((operation, forward) => {
  if (process.env.NODE_ENV === 'development') {
    operation.setContext(() => ({
      uri: `${config.api.graphQlUrl}?${operation.query.kind}=${operation.operationName}`,
    }));
  }
  return forward ? forward(operation) : null;
});

const authMiddleware = setContext(async () => {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  return {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
});

const createApolloClient = () =>
  new ApolloClient({
    connectToDevTools: true,
    link: ApolloLink.from([authMiddleware, namedLinkMiddleware, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            paginatedPages: {
              keyArgs: ['orderBy', 'where'],
              merge(existing, incoming) {
                if (!incoming) {
                  return existing;
                }
                const combinedPages = [...(existing ?? []), ...(incoming ?? [])];
                const uniquePages = combinedPages.reduce((unique, page) => {
                  // eslint-disable-next-line no-underscore-dangle
                  if (!unique.some((u) => u.__ref === page.__ref)) {
                    unique.push(page);
                  }
                  return unique;
                }, []);
                return uniquePages;
              },
            },
            timelineEntires: {
              keyArgs: ['orderBy', 'where'],
              merge(
                existing: TimelineEntryObject[],
                incoming: TimelineEntryObject[],
                { readField },
              ) {
                const keysMap = new Map();

                return [...(existing ?? []), ...(incoming ?? [])].reduce(
                  (result: TimelineEntryObject[], item) => {
                    const itemId = Number(readField('id', item));
                    if (!keysMap.has(itemId)) {
                      keysMap.set(itemId, item);
                      result.push(item);
                    } else {
                      const existingItem = keysMap.get(itemId);
                      const sameItemsHaveDifferentOrder =
                        existingItem &&
                        readField('order', existingItem) !== readField('order', item);

                      if (sameItemsHaveDifferentOrder) {
                        console.log('merge item - existing item with different id', {
                          newItem: {
                            order: readField('order', item),
                            id: readField('id', item),
                          },
                          existingItem: {
                            order: readField('order', existingItem),
                            id: readField('id', existingItem),
                          },
                        });
                      }
                    }
                    return result;
                  },
                  [],
                );
              },
            },
          },
        },
        PageObject: {
          fields: {
            documents: {
              merge(existing, incoming) {
                return { ...(existing ?? {}), ...(incoming ?? {}) };
              },
            },
            source: {
              merge: true,
            },
            pages_viewed: {
              keyArgs: false,
              merge(existing: PagesViewedObject[], incoming: PagesViewedObject[]) {
                return [...(incoming ?? existing ?? [])];
              },
            },
          },
        },
        PagesViewedObject: {
          keyFields: ['user_id', 'page_id'],
        },
        PageTimelineEntryObject: {
          keyFields: ['page_id', 'timeline_entries_id'],
        },
        TimelineEntryObject: {
          keyFields: ['id'],
          fields: {
            pages: {
              keyArgs: false,
              merge(existing, incoming) {
                return incoming ?? existing ?? [];
              },
            },
          },
        },
        NotesDocument: {
          keyFields: ['id'],
          fields: {
            // note_tags: {
            //   merge(existing, incoming) {
            //     // This is a basic merge that combines both arrays and removes duplicates.
            //     // You might need a more sophisticated merging strategy based on your needs.
            //     return incoming;
            //   },
            // },
          },
        },
        CustomTag: {
          keyFields: ['tagID'],
        },
        DocumentObject: {
          merge: true,
          fields: {
            pageListNodes: {
              keyArgs: ['where'],
              merge(existing, incoming) {
                console.log('merge document pages', { existing, incoming });

                // @TODO: temporary solution
                // Deduplication itself will be done a the hook level by the use of "order" field
                return [...(existing ?? []), ...(incoming ?? [])];
              },
            },
          },
        },
        PageListNodeObject: {
          keyFields: ['id', 'order'],
        },
        TagDisplayObject: {
          fields: {
            sub_tags: {
              merge(existing, incoming) {
                return incoming?.length > 0 ? incoming : existing;
              },
            },
          },
        },
      },
      fragments: createFragmentRegistry(
        PAGE_WITH_CONTENT_TYPES_FRAGMENT,
        PAGE_WITH_SPECIALITIES_FRAGMENT,
        PAGE_WITH_CONTENT_TYPES_AND_SPECIALITIES_FRAGMENT,
      ),
    }),
  });

const queryCache = new QueryCache();

const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
    },
    mutations: {
      networkMode: 'always',
    },
  },
});

wrapQueryCacheToHandleDataNormalization({
  queryCache,
  queryClient,
  enableLogs: false,
});

root.render(
  <ApolloProvider client={createApolloClient()}>
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthContextProvider>
    </QueryClientProvider>
  </ApolloProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();
