import { useApolloClient } from '@apollo/client';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import apiUrl from '../../library/utilities/apiUrl';
import { GetCasesDocument } from '../../__generated__/graphql';
import { useUser } from '../../library/contexts/AuthContext';

export function deleteCase({ userID, caseID }) {
  return axios.post(`${apiUrl}deleteCase`, { caseID, userID });
}

export function useDeleteCase() {
  const client = useApolloClient();

  return useMutation(deleteCase, {
    onSettled: () => {
      client.refetchQueries({
        include: [GetCasesDocument],
      });
    },
    onError: (error) => {
      console.log(error);
      Sentry.captureException(error);
      toast.error('Error deleting case');
    },
  });
}

export function setCaseStatus({ caseID, caseStatus, sendReadyEmail = false }) {
  return axios
    .post(`${apiUrl}updateCaseDetails`, {
      caseID: caseID,
      updatedCaseStatus: caseStatus,
      sendReadyEmail,
    })
    .catch((err) => {
      if (err?.response?.data === 'missing pages') {
        toast.error(
          'The case cannot be marked as ready since pages were found missing. Please contact engineering for help with the case.',
          {
            toastId: 'caseStatus',
          },
        );
      } else {
        toast.error('Failed to change case status.', {
          toastId: 'caseStatus',
        });
      }

      Sentry.captureException(err);
      throw err;
    });
}

export function useSetCaseStatus() {
  const client = useApolloClient();
  return useMutation(setCaseStatus, {
    onSettled: () => {
      client.refetchQueries({
        include: [GetCasesDocument],
      });
    },
  });
}

export function removeSelfFromCase({ userID, caseID }) {
  return axios.post(`${apiUrl}removeUserAccessForCase`, {
    caseID: caseID,
    userID: userID,
  });
}

export function useRemoveSelfFromCase() {
  const client = useApolloClient();

  return useMutation(removeSelfFromCase, {
    onSettled: () => {
      client.refetchQueries({
        include: [GetCasesDocument],
      });
    },
    onError: (error) => {
      console.log(error);
      Sentry.captureException(error);
      toast.error('Error removing from case');
    },
  });
}

export function createCase(caseDetails) {
  return axios.post(`${apiUrl}createCase`, caseDetails);
}

export function useCreateCase() {
  const client = useApolloClient();

  return useMutation(createCase, {
    onSettled: () => {
      client.refetchQueries({
        include: [GetCasesDocument],
      });
    },
    onError: (error) => {
      console.log(error);
      Sentry.captureException(error);
      toast.error('Error creating case');
    },
  });
}

async function fetchUserGroup() {
  return (await axios.get(`${apiUrl}user/group`)).data;
}

export function useUserGroup() {
  const { userId } = useUser();
  return useQuery(['group', userId], fetchUserGroup, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}
