import { create } from 'zustand';

interface PDFViewerStore {
  documentZoom: number;
  documentRotation: documentRotationType;
  isScreenCapturing: boolean;
  setDocumentZoom: (zoom: number) => void;
  setDocumentRotation: (rotation: documentRotationType) => void;
  setIsScreenCapturing: (isScreenCapturing: boolean) => void;
}

const usePDFViewerStore = create<PDFViewerStore>((set) => ({
  documentZoom: 1.0,
  documentRotation: {},
  isScreenCapturing: false,
  setDocumentZoom: (zoom: number) => set({ documentZoom: zoom }),
  setDocumentRotation: (rotation: documentRotationType) => set({ documentRotation: rotation }),
  setIsScreenCapturing: (isScreenCapturing: boolean) => set({ isScreenCapturing }),
}));

export type documentRotationType = {
  [key: number]: documentRotationValues;
};

export type documentRotationValues = number;

export default usePDFViewerStore;
