import { useMutation } from '@tanstack/react-query';
import { gql, useApolloClient } from '@apollo/client';

const UPDATE_MARKED_IMPORTANT = gql`
  mutation UpdateMarkedImportant($entryID: BigInt!, $markedImportant: Boolean!) {
    updateMarkedImportant(data: { entryID: $entryID, marked_important: $markedImportant }) {
      id
      marked_important
    }
  }
`;

export const useUpdateMarkedImportant = () => {
  const apolloClient = useApolloClient();

  return useMutation(
    async ({ entryID, markedImportant }: { entryID: string; markedImportant: boolean }) => {
      const response = await apolloClient.mutate({
        mutation: UPDATE_MARKED_IMPORTANT,
        variables: { entryID, markedImportant },
      });
      return response.data.updateMarkedImportant;
    },
  );
};
