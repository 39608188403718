import { Backdrop, Button, CircularProgress, Divider, Typography, Grid, Box } from '@mui/material';
import {
  CheckCircle,
  Close,
  NavigateBefore,
  NavigateNext,
  LooksOneOutlined,
} from '@mui/icons-material';
import { useEffect, useRef, useContext } from 'react';
import { useParams, useSearchParams, Link } from 'react-router-dom';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import DocumentDisplay from './DocumentDisplay';
import { theme } from '../../resources/Theme/Theme';
import IconButtonContainer from '../../components/common/IconButtonContainer';
import DuplicateDashboard from './DuplicateDashboard';
import DuplicatesList from './DuplicatesList';
import useCompareSimilarPages from './hooks/useCompareSimilarPages';
import useDuplicateSets from './hooks/useDuplicateSets';
import apiUrl from '../../library/utilities/apiUrl';
import CaseHeader from '../Timeline/CaseHeader';
import CaseContext from '../Case/CaseContext';
import useResolveSelectedDuplicatePages from './gql/useResolveDuplicatePages';
import useSetNewOriginalDuplicate from './gql/useSetNewOriginalDuplicate';
import useUpdateDuplicatePageSetViewed from './gql/updateDuplicatePageSetViewed';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import { useTimelineList } from '../Timeline/useTimeline';
import usePDFViewerStore from '../Case/usePDFViewerStore';
import { useActivityLog } from '../../components/ActivityTracker/ActivityTracker';

function Duplicates() {
  const {
    duplicateSets,
    currentDuplicateSet,
    setCurrentDuplicateSet,
    isFirstDuplicateSet,
    isLastDuplicateSet,
    duplicateSetsLoading,
    previousDuplicateSet,
    nextDuplicateSet,
    filters,
  } = useDuplicateSets();
  const {
    selectedPages,
    similarPages,
    togglePageSelection,
    clearSelection,
    selectFirst,
    selectAll,
  } = useCompareSimilarPages();

  const { caseID } = useParams();
  const logUserActivity = useActivityLog();
  let { setID } = useParams();
  const [searchParams] = useSearchParams();
  const pageID = searchParams.get('pageID');
  const { caseInstance } = useContext(CaseContext);
  const isFileProcessor = useIsFileProcessor();

  const { data: timelineList } = useTimelineList(caseID);
  const timelineID = timelineList?.find((timeline) => timeline.isDefault).id;

  const isMounted = useRef(false);

  const [setNewOriginal, newOriginalState] = useSetNewOriginalDuplicate();
  const [resolveSelectedPages, resolveState] = useResolveSelectedDuplicatePages();
  const [updateDuplicatePageSetViewed] = useUpdateDuplicatePageSetViewed();

  const documentRotation = usePDFViewerStore((state) => state.documentRotation);

  useEffect(() => {
    if (currentDuplicateSet != null && currentDuplicateSet.viewedAt == null) {
      updateDuplicatePageSetViewed({
        data: {
          duplicate_page_set_id: currentDuplicateSet.setID,
          viewed: 1,
        },
      });
    }
  }, [setID]);

  useEffect(() => {
    isMounted.current = true;
    // eslint-disable-next-line no-return-assign
    return () => (isMounted.current = false);
  }, []);

  useEffect(() => {
    // Set current set to one that includes pageID
    if (!setID && pageID) {
      setID = duplicateSets.find(
        (set) =>
          set.originalPage?.pageID === +pageID ||
          set.similarPages.find((page) => page?.pageID === +pageID),
      )?.setID;
    }
    if (setID) {
      setCurrentDuplicateSet(+setID);
    }
  }, [duplicateSets, setID, pageID]);

  useEffect(() => {
    if (isFileProcessor) {
      selectFirst();
    } else {
      clearSelection();
    }
  }, [currentDuplicateSet]);

  const nextUnresolvedDuplicate = () => {
    if (similarPages.length > 1) {
      if (
        currentDuplicateSet.similarPages?.filter(
          (page) => page.isDuplicate === null && !selectedPages.includes(page.pageID),
        ).length > 0
      ) {
        return currentDuplicateSet.setID;
      }
    }
    const currentIndex = duplicateSets.findIndex((set) => set.setID === currentDuplicateSet.setID);
    let nextIndex = (currentIndex + 1) % duplicateSets.length;

    while (nextIndex !== currentIndex) {
      if (
        duplicateSets[nextIndex].similarPages?.filter((page) => page.isDuplicate === null).length >
        0
      ) {
        return duplicateSets[nextIndex].setID;
      }
      nextIndex = (nextIndex + 1) % duplicateSets.length;
    }
    return null;
  };

  const getDocumentChunkUrl = async (documentID, pageNum) =>
    axios
      .post(`${apiUrl}getDocumentChunk`, {
        caseID: caseID,
        document_id: documentID,
        document_pn: pageNum,
      })
      .then((res) => {
        const { urls } = res.data;
        return {
          urls: urls,
          pageOffset: pageNum % 50 ? pageNum % 50 : 50,
        };
      });

  useQueries({
    queries: [
      {
        queryKey: [
          'documentChunkUrls',
          currentDuplicateSet?.originalPage?.documentID,
          currentDuplicateSet?.originalPage?.pageNumber,
        ],
        queryFn: () =>
          getDocumentChunkUrl(
            currentDuplicateSet?.originalPage?.documentID,
            currentDuplicateSet?.originalPage?.pageNumber,
          ),
        enabled: !!currentDuplicateSet,
        staleTime: Infinity,
      },
      ...(currentDuplicateSet?.similarPages.map((page) => ({
        queryKey: ['documentChunkUrls', page.documentID, page.pageNumber],
        queryFn: () => getDocumentChunkUrl(page.documentID, page.pageNumber),
        enabled: !!currentDuplicateSet,
        staleTime: 55 * 60 * 1000, // 55 mins
      })) ?? []),
    ],
  });

  useEffect(() => {
    logUserActivity({
      activity: 'case:duplicates',
      case_id: caseID,
      duplicate_set_id: setID,
    });
  }, [caseID, setID]);

  const queryClient = useQueryClient();

  return (
    <>
      <Backdrop
        open={resolveState.loading || newOriginalState.loading || duplicateSetsLoading}
        style={{ zIndex: '1000', position: 'absolute', opacity: 0.9 }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Box height="100%" sx={{ overflowY: 'hidden' }} display="flex" flexDirection="column">
        <CaseHeader caseInstance={caseInstance} timelineID={timelineID} />
        <Box display="flex" flexDirection="row" minHeight={0} flex={1}>
          <Box width="300px">
            <DuplicatesList caseID={caseID} />
          </Box>

          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            sx={{
              minWidth: '600px',
              overflowX: 'hidden',
            }}
          >
            {currentDuplicateSet ? (
              <>
                <Grid
                  container
                  id="dup-buttons-container"
                  sx={{
                    justifyContent: 'space-between',
                    marginBottom: '.5rem',
                    paddingTop: 1.5,
                    paddingBottom: 0.5,
                  }}
                >
                  <Typography
                    component="div"
                    display="inline"
                    sx={{
                      fontSize: '0.7rem',
                      lineHeight: '30px',
                      fontWeight: 500,
                      color: isFirstDuplicateSet() && '#D3D3D3',
                    }}
                  >
                    {duplicateSets.length > 0 && (
                      <Link
                        to={
                          !isFirstDuplicateSet() &&
                          `/case/${caseID}/duplicates/${previousDuplicateSet()}${
                            pageID ? `?pageID=${pageID}` : ''
                          }`
                        }
                        style={{ textDecoration: 'none' }}
                      >
                        <IconButtonContainer
                          size="small"
                          disableClick={isFirstDuplicateSet()}
                          tooltipText="Previous Duplicate Set"
                          icon={
                            <NavigateBefore
                              sx={{
                                marginBottom: 0.3,
                                fontSize: '1.5rem',
                                color: isFirstDuplicateSet() ? 'e3e3e3' : 'primary.light',
                              }}
                            />
                          }
                        />
                        <Box
                          display="inline"
                          sx={{
                            [theme.breakpoints.down('xl')]: {
                              display: 'none',
                            },
                            color: isFirstDuplicateSet() ? '#b0b0b0' : 'black',
                            fontSize: '0.8rem',
                          }}
                        >
                          Previous Set
                        </Box>
                      </Link>
                    )}
                  </Typography>

                  <Grid
                    item
                    xs={9}
                    md={9}
                    lg={7}
                    container
                    justify="center"
                    sx={{
                      justifyContent: 'center',
                      flexWrap: 'nowrap',
                    }}
                  >
                    <Link
                      to={`/case/${caseID}/duplicates/${nextUnresolvedDuplicate()}${
                        pageID ? `?pageID=${pageID}` : ''
                      }`}
                      style={{ display: 'contents' }}
                    >
                      <Button
                        variant="contained"
                        classes="duplicates"
                        disabled={selectedPages.length < 1}
                        onClick={() => {
                          resolveSelectedPages({
                            case_id: caseID,
                            page_ids: selectedPages,
                            duplicate_set_id: currentDuplicateSet.setID,
                            is_duplicate: true,
                            hide_resolved: filters.hideResolved,
                          });
                          clearSelection();
                        }}
                        sx={{ mr: '.5rem' }}
                        startIcon={
                          <CheckCircle
                            sx={{
                              [theme.breakpoints.down('lg')]: {
                                display: 'none',
                              },
                            }}
                          />
                        }
                      >
                        Confirm Duplicate
                      </Button>
                    </Link>
                    <Link
                      to={`/case/${caseID}/duplicates/${nextUnresolvedDuplicate()}${
                        pageID ? `?pageID=${pageID}` : ''
                      }`}
                      style={{ display: 'contents' }}
                    >
                      <Button
                        variant="contained"
                        color="themeTeal"
                        classes="duplicates"
                        disabled={selectedPages.length < 1}
                        onClick={() => {
                          resolveSelectedPages({
                            case_id: caseID,
                            page_ids: selectedPages,
                            duplicate_set_id: currentDuplicateSet.setID,
                            is_duplicate: false,
                            hide_resolved: filters.hideResolved,
                          });
                          clearSelection();
                        }}
                        sx={{ mr: '.5rem' }}
                        startIcon={
                          <Close
                            sx={{
                              [theme.breakpoints.down('lg')]: {
                                display: 'none',
                              },
                            }}
                          />
                        }
                      >
                        Not Duplicate
                      </Button>
                    </Link>
                    <Button
                      variant="contained"
                      color="themeTeal"
                      classes="duplicates"
                      disabled={selectedPages.length !== 1}
                      onClick={() => {
                        setNewOriginal({
                          case_id: caseID,
                          page_id: selectedPages[0],
                          duplicate_set_id: currentDuplicateSet?.setID,
                        });
                        clearSelection();
                      }}
                      sx={{ mr: '.5rem' }}
                      startIcon={
                        <LooksOneOutlined
                          sx={{
                            [theme.breakpoints.down('lg')]: {
                              display: 'none',
                            },
                          }}
                        />
                      }
                    >
                      Set New Original
                    </Button>
                    <Button
                      variant="contained"
                      color="themeTeal"
                      classes="duplicates"
                      onClick={
                        selectedPages.length === similarPages.length
                          ? () => clearSelection()
                          : () => selectAll()
                      }
                    >
                      {selectedPages.length === similarPages.length ? 'Unselect All' : 'Select All'}
                    </Button>
                  </Grid>
                  <Grid
                    xs={0.5}
                    md={0.5}
                    lg={1}
                    container
                    item
                    sx={{ justifyContent: 'end', mr: 2 }}
                  >
                    {duplicateSets.length > 0 && (
                      <Link
                        to={
                          !isLastDuplicateSet() &&
                          `/case/${caseID}/duplicates/${nextDuplicateSet()}${
                            pageID ? `?pageID=${pageID}` : ''
                          }`
                        }
                        style={{ textDecoration: 'none', display: 'inline' }}
                      >
                        <Box
                          display="inline"
                          sx={{
                            [theme.breakpoints.down('xl')]: {
                              display: 'none',
                            },
                            color: isLastDuplicateSet() ? '#b0b0b0' : 'black',
                            fontSize: '0.8rem',
                          }}
                        >
                          Next Set
                        </Box>
                        <Typography
                          component="div"
                          display="inline"
                          sx={{
                            fontSize: '0.7rem',
                            lineHeight: '30px',
                            fontWeight: 500,
                            color: isLastDuplicateSet() && '#D3D3D3',
                          }}
                        >
                          <IconButtonContainer
                            size="small"
                            disableClick={isLastDuplicateSet()}
                            tooltipText="Next Duplicate Set"
                            icon={
                              <NavigateNext
                                sx={{
                                  marginBottom: 0.3,
                                  fontSize: '1.5rem',
                                  color: isLastDuplicateSet() ? 'e3e3e3' : 'primary.light',
                                }}
                              />
                            }
                          />
                        </Typography>
                      </Link>
                    )}
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  container
                  spacing={3}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    marginTop: 1,
                    paddingLeft: '5.75%',
                    paddingRight: '5.75%',
                    marginRight: '4.35%',
                    paddingBottom: '3rem',
                    overflowY: 'auto',
                  }}
                  id="dup-documents-container"
                  className="pdf-display-list"
                >
                  {currentDuplicateSet.originalPage !== null && (
                    <DocumentDisplay
                      key={`${currentDuplicateSet.originalPage.pageID} ${currentDuplicateSet.setID}`}
                      documentFileName={currentDuplicateSet.originalPage.documentFileName}
                      documentChunkURL={
                        queryClient.getQueryData([
                          'documentChunkUrls',
                          currentDuplicateSet.originalPage.documentID,
                          currentDuplicateSet.originalPage.pageNumber,
                        ])?.urls
                      }
                      chunkPageNumberOffset={
                        queryClient.getQueryData([
                          'documentChunkUrls',
                          currentDuplicateSet.originalPage.documentID,
                          currentDuplicateSet.originalPage.pageNumber,
                        ])?.pageOffset
                      }
                      pageID={currentDuplicateSet.originalPage.pageID}
                      docPageNumber={parseInt(currentDuplicateSet.originalPage.pageNumber, 10)}
                      primary={true}
                      rotation={
                        documentRotation?.[currentDuplicateSet.originalPage.pageID] ??
                        currentDuplicateSet.originalPage.rotationAngle
                      }
                    />
                  )}
                  {currentDuplicateSet.similarPages
                    ?.filter((duplicate) => duplicate.isDuplicate !== 0)

                    .sort((a, b) => {
                      if (a.isDuplicate === b.isDuplicate) {
                        return 0;
                      }
                      if (a.isDuplicate) {
                        return 1;
                      }
                      return -1;
                    })
                    .map((duplicate) => (
                      <DocumentDisplay
                        key={`${duplicate.pageID} ${duplicate.setID}`}
                        documentFileName={duplicate.documentFileName}
                        documentChunkURL={
                          queryClient.getQueryData([
                            'documentChunkUrls',
                            duplicate.documentID,
                            duplicate.pageNumber,
                          ])?.urls
                        }
                        chunkPageNumberOffset={
                          queryClient.getQueryData([
                            'documentChunkUrls',
                            duplicate.documentID,
                            duplicate.pageNumber,
                          ])?.pageOffset
                        }
                        isDuplicate={duplicate.isDuplicate}
                        duplicatesLength={duplicateSets.length}
                        docPageNumber={duplicate.pageNumber}
                        similarityScore={duplicate.score}
                        isSelected={selectedPages.indexOf(duplicate.pageID) > -1}
                        onChange={() => togglePageSelection(duplicate.pageID)}
                        pageID={duplicate.pageID}
                        rotation={documentRotation?.[duplicate.pageID] ?? duplicate.rotationAngle}
                      />
                    ))}
                </Grid>
              </>
            ) : (
              <DuplicateDashboard />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Duplicates;
