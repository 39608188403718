import React, { useMemo, useRef, useState, useEffect } from 'react';
import {
  Autocomplete,
  Box,
  createFilterOptions,
  Divider,
  FormControl,
  IconButton,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Save } from '@mui/icons-material';
import AutoCompleteRenderOption from '../../components/common/Autocomplete/AutoCompleteRenderOption';
import { mapCompare, compareAlphaNumeric } from '../../utils/compare';

interface FilterInput {
  value: string;
  label?: string;
  source: string;
  isNewValue?: boolean;
}

const entityLabelByEntityName = {
  'Document Type': 'Document Type',
  Speciality: 'Specialitie',
  author: 'Author',
  organization: 'Organization',
  subject: 'Subject',
};

const displayLabelByEntityName = {
  'Document Type': 'What type of document is this?',
  Speciality: 'Is there a specialist involved?',
  author: 'Author',
  organization: 'Organization',
  subject: 'Subject',
};

export type Option = {
  id: string | number | null;
  value?: string;
  source?: string;
  [key: string]: any;
};

type Props = {
  entityName: keyof typeof entityLabelByEntityName;
  valueID: string | number | null;
  optionsForCase: Option[];
  optionsForEntry: Option[];
  setValues: (entityName: string, value: Option) => void;
  allOptions: Option[];
  allowNewOptions: boolean;
  loading: boolean;
  onFocus: () => void;
  onBlur: () => void;
  enableUnknown?: boolean;
};

const labelStyles = {
  '& .MuiOutlinedInput-input': {
    mt: '-0.5rem',
  },
  '& .MuiOutlinedInput-notchedOutline legend': {
    display: 'block !important',
  },
  '& .MuiInputLabel-shrink': {
    display: 'block !important',
  },
  '& .MuiInputLabel-formControl': {
    marginTop: '0.45rem !important',
  },
};

export default function ToolbarEntitySelect({
  entityName,
  valueID,
  optionsForCase,
  optionsForEntry,
  setValues,
  allOptions,
  allowNewOptions,
  loading,
  onFocus,
  onBlur,
  enableUnknown = false,
}: Props) {
  const filter = createFilterOptions<FilterInput>();
  const [entityInput, setEntityInput] = useState<Option>({ id: null, name: '' });
  const [inputValue, setInputValue] = useState<string>('');
  const [open, setOpen] = useState(false);
  const autoCompleteRef = useRef(null);

  const entityLabel = entityLabelByEntityName[entityName];
  const displayLabel = displayLabelByEntityName[entityName];

  const handleInputChange = (_: any, newValue: string) => {
    setInputValue(newValue);
    const lowercasedValue = newValue.trim().toLowerCase();
    const existingOption = allOptions?.find(
      (option) => option.value?.toLowerCase() === lowercasedValue,
    );
    if (newValue && !existingOption) {
      setEntityInput({ id: null, name: newValue });
    } else if (existingOption) {
      setEntityInput({ id: existingOption.id, name: existingOption.value });
    } else if (newValue === '') {
      setEntityInput({ id: null, name: '' });
    }
  };

  const groupBySource = (option: Option) => option.source!;

  const selectedValue = useMemo(() => {
    if (!valueID && enableUnknown) {
      return { id: null, value: 'Unknown' };
    }
    return allOptions?.find((option) => option.id === valueID);
  }, [valueID, allOptions, enableUnknown]);

  const groupedOptions = useMemo(() => {
    const allOptions: Option[] = [];

    if (optionsForEntry) {
      optionsForEntry.forEach((option) => {
        allOptions.push({ ...option, source: `Suggested ${entityLabel}s` });
      });
    }
    if (optionsForCase) {
      optionsForCase.forEach((option) => {
        allOptions.push({ ...option, source: `Other ${entityLabel}s` });
      });
    }

    if (!open && enableUnknown) {
      allOptions.push({ id: null, value: 'Unknown', source: `Suggested ${entityLabel}s` });
    }

    return allOptions?.sort(
      mapCompare<Option, string>((option) => option.label ?? '', compareAlphaNumeric.asc),
    );
  }, [optionsForCase, optionsForEntry, open, enableUnknown]);

  const selectedValueInGroupedOptions = useMemo(() => {
    return (
      groupedOptions.find((option) => option.id === selectedValue?.id) ??
      groupedOptions.find((option) => option.value === selectedValue?.value) ??
      null
    );
  }, [groupedOptions, selectedValue]);

  useEffect(() => {
    if (entityInput.id !== valueID || !entityInput.name) {
      setEntityInput({ id: valueID, name: selectedValue?.value ?? '' });
    }
  }, [valueID, selectedValue]);

  const width = useMemo(() => {
    if (entityName === 'organization') {
      return '16rem';
    }
    return '12rem';
  }, [entityName]);

  const handleFocus = () => {
    if (autoCompleteRef.current) {
      autoCompleteRef.current.querySelector('input').select();
    }
    if (onFocus) {
      onFocus();
    }
  };

  const handleBlur = () => {
    const currentValue = selectedValue?.value ?? '';
    if (!entityInput.name && currentValue !== 'Unknown') {
      setValues(entityName, { id: null, name: enableUnknown ? 'Unknown' : '' });
    }
    if (onBlur) {
      onBlur();
    }
  };

  const handleChange = (_: any, newValue: string) => {
    const currentValue = selectedValue?.value ?? '';
    if (typeof newValue === 'string') {
      if (newValue !== currentValue) {
        setEntityInput({ id: null, name: newValue });
        if (allowNewOptions && newValue !== '') {
          setValues(entityName, { id: null, name: newValue });
        } else {
          const existingOption = allOptions?.find(
            (option) =>
              option.value?.toLowerCase() === newValue.toLowerCase() &&
              option.origin !== 'deprecated',
          );
          if (!existingOption) {
            setValues(entityName, { id: null, name: enableUnknown ? 'Unknown' : newValue });
          }
        }
      }
    } else if (newValue && typeof newValue === 'object') {
      if (newValue.value !== currentValue) {
        setEntityInput(newValue);
        const existingOption = allOptions?.find(
          (option) =>
            option.value?.toLowerCase() === newValue.value?.toLowerCase() &&
            option.origin !== 'deprecated',
        );
        if (existingOption) {
          setValues(entityName, { id: newValue.id, name: newValue.value });
        } else {
          setValues(entityName, { id: null, name: enableUnknown ? 'Unknown' : newValue.value });
        }
      }
    }
  };

  return (
    <FormControl sx={{ width: width }}>
      <Autocomplete
        ref={autoCompleteRef}
        freeSolo
        open={open}
        autoHighlight
        clearOnEscape
        onOpen={() => {
          setOpen(true);
          setTimeout(() => {
            handleFocus();
          }, 0);
        }}
        onClose={() => setOpen(false)}
        options={groupedOptions}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const matchingOption = options.find(
            (option) => option.value?.toLowerCase() === params.inputValue.toLowerCase(),
          );

          if (matchingOption || params.inputValue === '') {
            return filtered;
          }

          if (allowNewOptions) {
            filtered.push({
              value: params.inputValue,
              source: `New ${entityLabel}`,
              isNewValue: true,
            });
          }
          return filtered;
        }}
        groupBy={groupBySource}
        value={selectedValueInGroupedOptions ?? ''}
        inputValue={inputValue}
        sx={{
          fontSize: '0.7rem',
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid',
            marginRight: '0.2rem',
            marginBottom: '0.2rem',
          },
          '& .MuiInputBase-root': {
            height: '2.3rem',
            fontSize: '0.7rem',
          },
          '& .MuiAutocomplete-endAdornment': {
            mt: '-0.15rem',
            display: 'none',
          },
        }}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: true,
              style: {
                fontSize: '0.6rem',
                opacity: '60%',
              },
            }}
            sx={labelStyles}
            label={displayLabel}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress size={12} sx={{ mb: '.3rem' }} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <AutoCompleteRenderOption
            {...props}
            option={option}
            selected={selected}
            onClick={() => {
              setValues(entityName, {
                id: option.id,
                name: option.value ?? null,
              });
            }}
          />
        )}
        renderGroup={(params) => [
          <Box
            key={params.key}
            sx={{
              fontSize: '0.75rem',
              ml: '0.5rem',
              mt: '0.5rem',
              color: 'dropdown.subgroupHeader',
              width: 'auto',
            }}
          >
            {params.group}
          </Box>,
          <Divider sx={{ color: 'dropdown.subgroupHeader' }} />,
          params.children,
        ]}
        getOptionLabel={(option) =>
          typeof option !== 'string' ? String(option?.label ?? option?.value) : ''
        }
      />
    </FormControl>
  );
}
