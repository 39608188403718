import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function MFAVerificationSuccessful({ next, nextText }) {
  return (
    <div style={{ height: '100%', textAlign: 'center' }}>
      <div>
        <CheckCircleIcon style={{ fontSize: '48px', color: 'green' }} />
      </div>
      <div style={{ fontSize: '16px', marginBottom: '2rem', marginTop: '0.5rem' }}>
        MFA Has been successfully set up.
      </div>

      <Button fullWidth variant="contained" color="primary" onClick={next}>
        {nextText}
      </Button>
    </div>
  );
}
