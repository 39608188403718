import { useMemo } from 'react';
import { TagType, useGetPageTagsQuery } from '../../../__generated__/graphql';
import { toDisplayableTagSavingOriginalValues } from './utils/pageTagUtils';

const useSpecialities = () => {
  const query = useGetPageTagsQuery({
    variables: {
      where: {
        type: {
          equals: TagType.Speciality,
        },
      },
    },
  });

  return useMemo(
    () => ({
      ...query,
      data: query.data?.tags.map(toDisplayableTagSavingOriginalValues),
    }),
    [query],
  );
};

export default useSpecialities;
