import { GridRenderCellParams } from '@mui/x-data-grid';
import { TextField } from '@mui/material';

export function DocumentNameEdit(
  params: GridRenderCellParams & {
    defaultValue?: string;
  },
) {
  const handleInputChange = (event) => {
    params.api.setEditCellValue(
      { id: params.id, field: params.field, value: event.target.value },
      event,
    );
  };

  return (
    <TextField
      defaultValue={params.defaultValue}
      onChange={handleInputChange}
      autoFocus
      sx={{
        minWidth: '100%',
        minHeight: 'fit-content',
        overflowY: 'visible',
        my: 'auto',
        border: 'none',
        alignContent: 'center',
        mx: 'auto',
        '& .MuiAutocomplete-inputRoot': {
          border: 'none',
          paddingY: 0,
          paddingX: 0,
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none', // Removes border
          },
          '&:hover fieldset': {
            border: 'none', // Removes border on hover
          },
          '&.Mui-focused fieldset': {
            border: 'none', // Removes border when focused
          },
        },
      }}
    />
  );
}
