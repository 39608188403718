import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import AuthCode from 'react-auth-code-input';
import BackButton from '../../../components/common/BackButton';

export default function EnterVerificationCode({ back, onSubmit, title, length = 6 }) {
  const [code, setCode] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const submitCode = async () => {
    setSubmitting(true);
    await onSubmit(code);
    setSubmitting(false);
  };

  useEffect(() => {
    if (code.length === 6) {
      submitCode();
    }
  }, [code]);

  return (
    <div style={{ height: '100%', minWidth: '100%' }}>
      {back && <BackButton onClick={back} style={{ float: 'left', marginTop: '-2px' }} />}
      <div style={{ fontSize: '0.85rem', textAlign: 'center', marginBottom: '2rem' }}>{title}</div>
      <AuthCode
        length={length}
        onChange={setCode}
        containerClassName="auth-container"
        inputClassName="auth-input"
        style={{
          '.auth-input': {
            fontSize: 10,
          },
        }}
      />

      <div style={{ textAlign: 'center', marginTop: '5%' }}>
        <Button variant="contained" color="primary" onClick={submitCode} disabled={submitting}>
          Submit
        </Button>
      </div>
    </div>
  );
}
