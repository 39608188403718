import { useCallback, useMemo } from 'react';
import { create } from 'zustand';

export const selectors = {
  byParent: (parent) => (data) => data.filter((item) => item.parent === parent),
  rootLevel: (data) => data.filter((item) => !item.parent),
  asMap: (data) => Object.fromEntries(data.map((item) => [item.id, item])),
};

const useSelectedTagsStore = create((set) => ({
  selectedTags: {},
  setSelectedTags: (fn) => set((value) => ({ selectedTags: fn(value.selectedTags) })),
}));

export function usePageSelectedTags() {
  const [selectedTags, setSelectedTags] = useSelectedTagsStore((state) => [
    state.selectedTags,
    state.setSelectedTags,
  ]);

  const toggleTagSelection = useCallback(
    (tag) => {
      setSelectedTags((value) => {
        if (value[tag.id]) {
          const updatedValue = { ...value };
          delete updatedValue[tag.id];

          return updatedValue;
        }

        return {
          ...value,
          [tag.id]: tag,
        };
      });
    },
    [setSelectedTags],
  );

  return useMemo(
    () => ({
      selectedTags,
      toggleTagSelection,
    }),
    [selectedTags, toggleTagSelection],
  );
}
