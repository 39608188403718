import { Typography, Chip, Box, Stack } from '@mui/material';

export default function UserAccessRow(props) {
  const { givenName, familyName, email, username, accessRole, isLastOwner, deleteProposedUser } =
    props;

  return (
    <Stack spacing={3} width="90%" direction="row" sx={{ m: 1, ml: 3 }}>
      <Box width="25rem">
        <Typography fontSize="small" fontWeight={800} noWrap>
          {givenName} {familyName}
        </Typography>
        <Typography fontSize="small" noWrap>
          {email}
        </Typography>
      </Box>
      <Box pt={0.5}>
        {!isLastOwner && (
          <Chip
            size="small"
            label="Remove"
            sx={{ fontSize: 12 }}
            onClick={() => deleteProposedUser(accessRole, username)}
          />
        )}
      </Box>
    </Stack>
  );
}
