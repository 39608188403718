import { differenceInDays, differenceInWeeks } from 'date-fns';
import {
  formatDateToDateNoTimezone,
  getDifferenceInMilliSeconds,
} from '../../../library/utilities/useDates';

export function validateCaseDetails(
  caseName: string,
  dueDate: string | null,
  dateOfBirth: string | null,
  dateOfIncident: string | null,
) {
  if (caseName.length > 100) {
    return [false, 'Your Case Name must be 100 characters or less.'];
  }
  const currentDate = formatDateToDateNoTimezone(new Date());
  currentDate.setHours(0, 0, 0, 0);

  if (dueDate != null && dueDate !== '') {
    const formattedDueDate = formatDateToDateNoTimezone(dueDate);

    if (differenceInDays(formattedDueDate, currentDate) <= 0) {
      return [false, "Due date must be after today's date."];
    }
    if (differenceInWeeks(formattedDueDate, currentDate) >= 52) {
      return [false, 'Please select a due date within the next year.'];
    }
  }
  if (dateOfBirth != null && dateOfBirth !== '') {
    const formattedDateOfBirth = formatDateToDateNoTimezone(dateOfBirth);
    if (differenceInDays(formattedDateOfBirth, currentDate) >= 0) {
      return [false, "Date of birth must be before today's date."];
    }
    if (getDifferenceInMilliSeconds('01/01/1900', dateOfBirth) > 0) {
      return [false, 'Date of birth must be a valid date.'];
    }
  }
  if (dateOfIncident != null && dateOfIncident !== '') {
    const formattedDateOfIncident = formatDateToDateNoTimezone(dateOfIncident);
    if (differenceInDays(formattedDateOfIncident, currentDate) >= 0) {
      return [false, "Date of incident must be before today's date."];
    }
    if (dateOfBirth === '' || dateOfBirth == null) {
      return [true, 'Good!'];
    }
    const formattedDateOfBirth = formatDateToDateNoTimezone(dateOfBirth);
    if (differenceInDays(formattedDateOfBirth, formattedDateOfIncident) >= 0) {
      return [false, 'Date of incident must be after the date of birth.'];
    }
  }
  return [true, 'Good!'];
}
