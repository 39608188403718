/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  Box,
  TypographyProps,
  ButtonProps,
  TextFieldProps,
  BoxProps,
  CircularProgress,
} from '@mui/material';
import { Check, Close } from '@mui/icons-material';

type EditableTextProps = {
  value: string;
  onSubmit?: (value: string) => void;
  editIcon?: React.JSX.Element;
  typographyProps?: TypographyProps;
  textFieldProps?: TextFieldProps;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  boxProps?: BoxProps;
  height?: string;
  typographyBoxProps?: BoxProps;
};

function InlineEditableText({
  value,
  onSubmit,
  typographyProps = {},
  textFieldProps = {},
  confirmButtonProps = {},
  cancelButtonProps = {},
  boxProps = {},
  typographyBoxProps = {},
  height = '2rem',
}: EditableTextProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentValue, setCurrentValue] = useState('');

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleInputBlur = (event) => {
    if (
      event.relatedTarget == null ||
      !event.relatedTarget.classList.contains('editableInputButton')
    ) {
      setIsEditing(false);
    }
  };

  const handleInputChange = (event) => {
    setCurrentValue(event.target.value);
  };

  const handleSubmitInput = async () => {
    if (onSubmit != null) {
      setIsSubmitting(true);
      await onSubmit(currentValue);
      setIsSubmitting(false);
    }
    setIsEditing(false);
  };

  const handleCancelInput = () => {
    setCurrentValue(value);
    setIsEditing(false);
  };

  return (
    <Box
      {...boxProps}
      sx={{
        height: height,
        display: 'flex',
        width: 'fit-content',
        ...boxProps?.sx,
      }}
    >
      {isEditing ? (
        <TextField
          variant="standard"
          autoFocus
          {...textFieldProps}
          sx={{
            backgroundColor: 'grey.200',
            '& .MuiOutlinedInput-root': {
              paddingY: '0',
            },
            variant: 'standard',
            height: height,
            '& .MuiInputBase-root': {
              height: '100%',
            },
            '& .MuiInputBase-input': {
              height: '100%',
            },
            '& .MuiInput-underline:before': {
              borderBottom: 'none',
            },
            '& .MuiInput-underline:after': {
              borderBottom: 'none',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
              borderBottom: 'none',
            },
            ...textFieldProps?.sx,
          }}
          value={currentValue}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: isSubmitting ? (
              <CircularProgress size={20} sx={{ mr: '0.5rem' }} />
            ) : (
              <EditableTextButtons
                onSubmit={handleSubmitInput}
                onCancel={handleCancelInput}
                confirmButtonProps={confirmButtonProps}
                cancelButtonProps={cancelButtonProps}
              />
            ),
          }}
          onBlur={handleInputBlur}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            height,
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: 'grey.200',
            },
            ...typographyBoxProps?.sx,
          }}
          onClick={handleTextClick}
        >
          <Typography {...typographyProps} sx={{ mx: '0.5rem', ...typographyProps?.sx }}>
            {value}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

InlineEditableText.defaultProps = {
  typographyProps: {},
  textFieldProps: {},
  confirmButtonProps: {},
  cancelButtonProps: {},
  boxProps: {},
  onSubmit: () => {},
  height: '2rem',
};

export default InlineEditableText;

type EditableTextButtonsProps = {
  onSubmit: (event: any) => void;
  onCancel: () => void;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
};

function EditableTextButtons({
  onSubmit,
  onCancel,
  confirmButtonProps,
  cancelButtonProps,
}: EditableTextButtonsProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
      }}
    >
      <Button
        className="editableInputButton"
        sx={{ minWidth: 'fit-content', height: '100%' }}
        {...confirmButtonProps}
        onClick={onSubmit}
      >
        <Check />
      </Button>
      <Button
        className="editableInputButton"
        sx={{ minWidth: 'fit-content', height: '100%' }}
        {...cancelButtonProps}
        onClick={onCancel}
      >
        <Close />
      </Button>
    </Box>
  );
}
