import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const useDisplayStore = create(
  persist(
    (set, get) => ({
      timelineWidth: 350,
      notesWidth: 350,
      showThumbnails: false,
      showThumbnailSource: false,
      thumbnailSize: 50,
      gapBetweenThumbnails: 16,
      lastGroupingScrollPosition: { documentID: null, postion: 0 },
      windowSize: { width: window.innerWidth, height: window.innerHeight },
      PDFDimensions: { documentHeight: 793.75, documentWidth: 635, documentPadding: 16 },

      setShowThumbnails: (showThumbnails) => {
        set({ showThumbnails });
      },
      setShowThumbnailSource: (showThumbnailSource) => {
        set({ showThumbnailSource });
      },
      setThumbnailSize: (size) => {
        set({ thumbnailSize: size });
      },

      setTimelineWidth: (timelineWidth) => {
        set({ timelineWidth });
      },

      setNotesWidth: (notesWidth) => {
        set({ notesWidth });
      },

      setLastGroupingScrollPosition: (scrollPosition) => {
        set({ lastGroupingScrollPosition: scrollPosition });
      },

      setWindowSize: (windowSize) => {
        const scaleFactor = windowSize.width / 1440; // Assuming original width of 1440
        const newDocumentWidth = 635 * scaleFactor;
        const newDocumentHeight = 793.75 * scaleFactor;
        const newTimelineWidth = 350 * scaleFactor;
        const newNotesWidth = 350 * scaleFactor;

        set((prevState) => ({
          windowSize,
          PDFDimensions: {
            ...prevState.PDFDimensions,
            documentWidth: newDocumentWidth,
            documentHeight: newDocumentHeight,
          },
          timelineWidth: newTimelineWidth,
          notesWidth: newNotesWidth,
        }));
      },

      getGridWidth: () => get().timelineWidth - 48,

      getThumbnailHeight: () => 4 * get().thumbnailSize + (get().showThumbnailSource ? 18 : 0) + 24,

      getThumbnailWidth: () => get().thumbnailSize * 3,

      getThumbnailsPerRow: () =>
        Math.floor(get().getGridWidth() / (get().getThumbnailWidth() + get().gapBetweenThumbnails)),

      getThumbnailGridListHeight: (numberOfPages) => {
        const rows = Math.ceil(numberOfPages / get().getThumbnailsPerRow());
        return rows * get().getThumbnailHeight() + (rows - 1) * 16 + 24;
      },
    }),

    {
      name: 'case-display-storage',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export default useDisplayStore;
