import { Box, Typography, Button } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import WaveDiagram from '../../resources/images/WaveDiagram.png';
import SiftMedBenefitsDiagram from '../../resources/images/SiftMedBenefitsDiagram.png';
import FileOverlap from '../../resources/images/FileOverlap.png';

export default function SiftMedCarousel() {
  return (
    <Carousel
      sx={{ width: '80%', margin: 'auto', position: 'relative' }}
      height="77vh"
      animation="slide"
      duration={1000}
      indicatorIconButtonProps={{
        style: {
          color: '#517195',
          '&:active': {
            color: '#213975',
          },
          margin: '0 5px',
          height: '1rem',
          width: '1rem',
          position: 'relative',
          top: 'calc(50% - 20px) !important',
          transition: '1800ms',
          cursor: 'pointer',
        },
      }}
      activeIndicatorIconButtonProps={{
        style: {
          backgroundColor: '#33DC9C',
          color: '#33DC9C',
        },
      }}
      indicatorContainerProps={{
        sx: {
          marginTop: '-1.5rem',
          zIndex: 2000,
          position: 'absolute',
          width: '100%',
        },
      }}
    >
      <Box sx={carouselStyles.boxStyle}>
        <Typography sx={carouselStyles.title}>Welcome To SiftMed</Typography>
        <Typography
          align="center"
          sx={{
            color: '#FFFFFF',
            fontSize: { xs: '0.8rem', md: '0.8rem', lg: '0.8rem', xl: '0.9rem' },
          }}
        >
          The quickest way to <b>review</b> and <b>analyze</b> unstructured medical data <br />
          <a
            href="http://www.siftmed.ca"
            rel="noopener noreferrer"
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            <Button variant="contained" sx={carouselStyles.learnButton}>
              Learn More
            </Button>
          </a>
        </Typography>
        <Box
          component="img"
          src={FileOverlap}
          alt="SiftMed Notes View"
          sx={{
            width: { xs: '27rem', md: '27rem', lg: '27rem', xl: '40rem' },
            height: 'auto',
            marginTop: { xl: '-6rem' },
          }}
        />
        <Typography sx={carouselStyles.standardText}>
          SiftMed is transforming medical file processing with artificial intelligence. Our platform
          helps insurance, legal, and medical professionals bring clarity to health care
          investigations.
        </Typography>
      </Box>
      <Box sx={{ ...carouselStyles.boxStyle }}>
        <Typography sx={carouselStyles.title}>Improve the Way you Sift Through Files</Typography>
        <Typography sx={{ ...carouselStyles.standardText, marginTop: 0 }}>
          SiftMed's workflow makes it <b>easier than ever</b> to complete your medical record
          reviews.
        </Typography>
        <a
          href="http://www.siftmed.ca"
          rel="noopener noreferrer"
          target="_blank"
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained" sx={carouselStyles.learnButton}>
            Learn More
          </Button>
        </a>
        <Box
          component="img"
          src={WaveDiagram}
          alt="SiftMed Workflow Diagram"
          sx={{
            width: { xs: '24rem', md: '24rem', lg: '24rem', xl: '33rem' },
            height: 'auto',
            padding: '0.5rem',
            marginBottom: {
              xs: '2.5rem',
              md: '2.5rem',
              lg: '2.5rem',
              xl: '3rem',
            },
            marginTop: { xs: '-1rem', md: '-1rem', lg: '-1rem', xl: '-5rem' },
          }}
        />
      </Box>
      <Box sx={{ ...carouselStyles.boxStyle }}>
        <Typography sx={carouselStyles.title}>Find The Facts That Matter</Typography>
        <Typography sx={{ ...carouselStyles.standardText, marginTop: 0 }}>
          The SiftMed platform allows for the <b>sorting of medical files</b> by date and specific{' '}
          <br />
          content type, so you can work with a <b>clean and organized record</b>.{' '}
        </Typography>

        <a
          href="http://www.siftmed.ca"
          rel="noopener noreferrer"
          target="_blank"
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained" sx={carouselStyles.learnButton}>
            Learn More
          </Button>
        </a>
        <Box
          component="img"
          src={SiftMedBenefitsDiagram}
          alt="SiftMed Process Diagram"
          sx={{
            width: { xs: '18rem', md: '18rem', lg: '18rem', xl: '22rem' },
            height: 'auto',
            padding: '0.5rem',
            marginBottom: { xs: '5rem', md: '5rem', lg: '5rem', xl: '10rem' },
            marginTop: { xs: '-1rem', md: '-1rem', lg: '-1rem', xl: '-6rem' },
          }}
        />
      </Box>
    </Carousel>
  );
}

const carouselStyles = {
  boxStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    height: '100%',
    paddingLeft: '5rem',
    paddingRight: '5rem',
  },
  learnButton: {
    width: '7rem',
    fontWeight: 600,
    fontSize: '0.75rem',
    color: '#040913',
    backgroundColor: 'secondary.main',
    margin: { xs: '1.5rem', md: '1.5rem', lg: '1.5rem', xl: '3rem' },
    marginBottom: { xs: '2.5rem', md: '2.5rem', lg: '2.5rem', xl: '9rem' },
  },
  standardText: {
    color: '#FFFFFF',
    fontSize: { xs: '0.75rem', md: '0.75rem', lg: '0.75rem', xl: '0.85rem' },
    marginTop: { xs: '1rem', md: '1rem', lg: '1rem', xl: '1.5rem' },
    width: { xl: '70%' },
  },
  title: {
    color: '#FFFFFF',
    fontSize: { xs: '1.2rem', md: '1.2rem', lg: '1.2rem', xl: '1.45rem' },
    fontWeight: 400,
  },
};
