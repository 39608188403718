import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import SmsIcon from '@mui/icons-material/Sms';
import { MenuItem, Paper, Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuList from '@mui/material/MenuList';

export default function SelectMFAMethod({ setMFAMethod, back, location = 'settings' }) {
  return (
    <>
      {location === 'login' && (
        <p style={{ margin: 'auto', marginBottom: '1rem' }}>
          To continue, please set up multi-factor authentication.
        </p>
      )}
      <Paper sx={{ boxShadow: 'none', border: 'thin solid #e3e3e3' }}>
        <MenuList sx={{ padding: 0 }}>
          <MenuItem sx={{ height: '80px' }} onClick={() => setMFAMethod('sms')}>
            <ListItemIcon>
              <SmsIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="h4">Set up MFA using SMS</Typography>
            <ListItemIcon>
              <NavigateNextIcon fontSize="small" />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            sx={{ height: '80px', borderTop: 'thin solid #e3e3e3' }}
            onClick={() => setMFAMethod('totp')}
          >
            <ListItemIcon>
              <PhonelinkSetupIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="h4">Set up MFA using an authenticator app</Typography>
            <ListItemIcon>
              <NavigateNextIcon fontSize="small" />
            </ListItemIcon>
          </MenuItem>
        </MenuList>
      </Paper>
      <div
        role="presentation"
        onClick={back}
        style={{
          cursor: 'pointer',
          marginTop: '1rem',
          width: 'fit-content',
          margin: '1rem auto 0px auto',
          fontSize: '12px',
          color: '#2B60DE',
        }}
      >
        <ArrowBackIosIcon style={{ fontSize: '16px' }} />
        Back to {location}
      </div>
    </>
  );
}
