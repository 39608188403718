import { GetDuplicatesQuery } from '../../../__generated__/graphql';
import { ViewedIndicator } from '../../../components/ViewedIndicator';
import useUpdateDuplicatePageSetViewed from '../gql/updateDuplicatePageSetViewed';

export type DuplicateSetViewedControlProps = {
  duplicateSet: GetDuplicatesQuery['duplicates'][number];
};

export default function DuplicateSetViewedControl({
  duplicateSet,
}: DuplicateSetViewedControlProps) {
  const { setID, viewedAt } = duplicateSet;

  const [updatePageToViewed] = useUpdateDuplicatePageSetViewed();

  const isViewed = Boolean(viewedAt);

  const handleToggleViewed = () => {
    updatePageToViewed({
      data: {
        duplicate_page_set_id: setID,
        viewed: isViewed ? 0 : 1,
      },
    });
  };

  return <ViewedIndicator isViewed={isViewed} toggleViewed={handleToggleViewed} />;
}
