import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import LockIcon from '@mui/icons-material/Lock';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';

export default function TemplateLockedModal(props) {
  const { open, problem, checkIfTemplateIsLocked } = props;
  const [loading, setLoading] = useState(false);

  const refresh = async () => {
    setLoading(true);
    await checkIfTemplateIsLocked();
    setTimeout(() => setLoading(false), 2000);
  };

  const header = problem
    ? 'A problem has occurred while checking access'
    : 'Somebody else is working on this template.';
  const bodyText = problem
    ? 'Please contact support if the problem persists.'
    : 'Check back again later to see if the template is ready for editing.';
  return (
    <Dialog
      open={open}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
          height: 300,
          minWidth: 500,
          width: '50%',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },
      }}
    >
      <LockIcon
        sx={{
          color: 'primary.light',
          marginTop: 7,
          fontSize: 40,
        }}
      />
      <DialogTitle sx={{ color: 'primary.light', marginBottom: '-10px' }}>
        <IconButton
          aria-label="close"
          disabled={loading}
          onClick={refresh}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <AutorenewIcon />
        </IconButton>
        {header}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{
            color: 'grey',
            marginTop: '-2px',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontSize: 'small',
            marginBottom: 3,
          }}
        >
          {bodyText}
        </DialogContentText>
        <div style={{ textAlign: 'center' }}>
          <Link to="/templates">
            <Button
              color="primary"
              size="large"
              variant="contained"
              startIcon={<ArrowBackIosNewIcon />}
              sx={{ mt: 2 }}
            >
              Back to Templates
            </Button>
          </Link>
        </div>
      </DialogContent>
    </Dialog>
  );
}
