import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Stack } from '@mui/material';
import CaseHeader from '../../containers/Timeline/CaseHeader';
import CaseContext from '../../containers/Case/CaseContext';
import { useTimelineList } from '../../containers/Timeline/useTimeline';
import TableOrg from './TableOrg';
import TablePeople from './TablePeople';
import { useActivityLog } from '../ActivityTracker/ActivityTracker';

export default function EntitiesTab() {
  const { caseInstance } = useContext(CaseContext);
  const { caseID } = useParams();
  const logUserActivity = useActivityLog();

  const { data: timelineList } = useTimelineList(caseID);
  const timelineID = timelineList?.find((timeline: { isDefault: number }) => timeline.isDefault).id;

  useEffect(() => {
    if (caseID) {
      logUserActivity({
        activity: 'case:entities',
        case_id: caseID,
      });
    }
  }, [caseID]);

  return (
    <>
      <CaseHeader caseInstance={caseInstance} timelineID={timelineID} />
      <Box
        sx={{
          height: 'auto',
          width: '100%',
          borderRadius: 0,
          justifyContent: 'center',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          backgroundColor: 'themeDuplicates.contrastText',
        }}
      >
        <Stack
          direction="row"
          spacing={4}
          sx={{
            paddingTop: '1.2rem',
            paddingBottom: '1.2rem',
            width: '100%',
          }}
        >
          <Typography fontSize="1.2rem" fontWeight={600} sx={{ display: 'inline' }}>
            Entities
          </Typography>
        </Stack>
        <Box>
          <TableOrg caseID={caseID ?? ''} />
          <TablePeople caseID={caseID ?? ''} />
        </Box>
      </Box>
    </>
  );
}
