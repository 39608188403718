import { useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  DeleteTimelineReportMutationVariables,
  TimelineReportDocument,
  TimelineReportFragmentFragmentDoc,
  useDeleteTimelineReportMutation,
} from '../../../__generated__/graphql';

const useDeleteTimelineReport = () => {
  const [deleteTimelineReport, mutationStateInfo] = useDeleteTimelineReportMutation();

  const deleteTimelineReportCallback = useCallback(
    async ({ variables }: { variables: DeleteTimelineReportMutationVariables }) => {
      try {
        await deleteTimelineReport({
          variables,
          update: (cache, { data }) => {
            const deletedTimelineReportId = data?.deleteTimelineReport;

            if (deletedTimelineReportId) {
              cache.modify({
                fields: {
                  timeline_reports(existingTimelineReports = []) {
                    // Filter out the deleted timeline report from the cached list
                    return existingTimelineReports.filter(
                      (timelineReportRef: { __ref: string; id: string }) => {
                        // Extract the ID of each timeline report from its reference
                        const foundTimelineReport = cache.readFragment({
                          id: timelineReportRef.__ref,
                          fragment: TimelineReportFragmentFragmentDoc,
                          fragmentName: 'TimelineReportFragment',
                        }) as TimelineReportDocument | null;

                        const foundTimelineId = timelineReportRef?.id ?? foundTimelineReport?.id;
                        return foundTimelineId !== deletedTimelineReportId;
                      },
                    );
                  },
                },
              });
            }
          },
        });
      } catch (err) {
        toast.error('Failed to delete timeline report');
        throw err;
      }
    },
    [deleteTimelineReport],
  );

  return [deleteTimelineReportCallback, mutationStateInfo];
};

export default useDeleteTimelineReport;
