/* eslint-disable no-restricted-syntax */
/**
 * Processes an array in chunks with a delay to allow the browser to keep rendering.
 *
 * @param {Array} arr - The array to be processed.
 * @param {Function} fn - The function to process each element of the array.
 * @param {number} chunkSize - The number of elements to process in a single chunk.
 * @return {Promise<void>} - A promise that resolves when all elements have been processed.
 */
export function processInChunks<T>(
  arr: T[],
  fn: (data: T) => void,
  chunkSize: number,
): Promise<void> {
  return new Promise((resolve, reject) => {
    if (chunkSize <= 0) {
      return reject(new RangeError('Chunk size must be a positive number.'));
    }

    let i = 0;

    function processNextChunk() {
      const end = Math.min(i + chunkSize, arr.length);

      for (; i < end; i++) {
        fn(arr[i]);
      }

      // If we've processed the entire array, resolve the promise.
      if (i >= arr.length) {
        resolve();
      } else {
        // Otherwise, schedule the next chunk.
        setTimeout(processNextChunk, 0);
      }
    }

    setTimeout(processNextChunk, 0);
  });
}

export const identity = <T>(x: T): T => x;

export function toObjectMap<T>(arr: T[], mapKey: (item: T) => string): Record<string, T>;
export function toObjectMap<T, M>(
  arr: T[],
  mapKey: (item: T) => string,
  mapItem: (item: T) => M,
): Record<string, M>;
export function toObjectMap(
  arr: any[],
  mapKey: (item: any) => string,
  mapItem = identity,
): Record<string, any> {
  const result: Record<string, any> = {};

  for (const item of arr) {
    result[mapKey(item)] = mapItem(item);
  }

  return result;
}
