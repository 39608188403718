export const PAGE_SIZE = 26;
export const DOCUMENT_SIZE = 50;

/**
 * Uses the pageID to calculate the current index of the page in the list,
 * then uses that index to calculate the top offset for that page.
 */
export const getTopOffset = (pageID: number, listContent: (string | number)[]) => {
  if (!pageID || !listContent?.length) {
    return -1;
  }
  let accumulatedHeight = 0;

  for (const key of listContent) {
    if (key === pageID) {
      return accumulatedHeight;
    }
    accumulatedHeight += getItemSize(key);
  }

  return -1;
};

export const getItemSize = (key: string | number): number => {
  if (!key) {
    return PAGE_SIZE;
  }
  return keyIsPage(key) ? PAGE_SIZE : DOCUMENT_SIZE;
};

export const keyIsPage = (key: string | number): boolean => !(typeof key === 'string');

/**
 * Calculates the number of documents in the list before any given index
 */
export const calculateNumberOfDocumentsBeforeIndex = (
  listDescriptor: (number | string)[],
  from: number,
) => {
  return listDescriptor
    .slice(0, from) // Consider items up to the 'from' index
    .filter((item: number | string) => typeof item === 'string').length; // Filter out documents by checking if the ID is a string
};
