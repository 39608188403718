import React, { CSSProperties, ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './modal.css';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  style?: CSSProperties;
}

function Modal({ isOpen, onClose, children, style = {} }: ModalProps) {
  useEffect(() => {
    function keyListener(e) {
      if (e.key === 'Escape') {
        onClose();
      }
    }
    document.addEventListener('keydown', keyListener);
    return () => document.removeEventListener('keydown', keyListener);
  }, [onClose]);

  const onClick = (e) => {
    if (e.target.classList.contains('sm-modal-backdrop')) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }
  return ReactDOM.createPortal(
    <div className="sm-modal-backdrop" onClick={onClick}>
      <div className="sm-modal-content" role="dialog" aria-modal="true" style={style}>
        {children}
      </div>
    </div>,
    document.body,
  );
}

export default Modal;
