import { Edit } from '@mui/icons-material';
import { Box } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useState } from 'react';

export default function EditHoverCellEffect({
  cellValue,
  formatValueFunction,
  params,
}: {
  cellValue: any;
  formatValueFunction?: (value: any) => string | number;
  params: GridRenderCellParams;
}) {
  const [hover, setHover] = useState(false);
  const handleEditClick = () => {
    params.api.startCellEditMode({
      id: params.id,
      field: params.field,
    });
  };

  const displayValue = formatValueFunction ? formatValueFunction(cellValue) : cellValue ?? '';

  return (
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          flex: '1 1 auto',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {displayValue}
      </Box>
      {hover && (
        <Edit
          style={{
            flex: '0 0 auto',
            marginLeft: '1rem',
            bottom: '2px',
            position: 'relative',
            cursor: 'pointer',
          }}
          onClick={handleEditClick}
        />
      )}
    </Box>
  );
}
