export default function Delete(props) {
  const { color, backgroundColor } = props;
  return (
    <svg
      {...props}
      viewBox="0 0 12 13"
      fill={backgroundColor || 'none'}
      cursor="pointer"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 3.43359V3.00859C8.25 2.41354 8.25 2.11601 8.1342 1.88873C8.03233 1.68881 7.86979 1.52626 7.66987 1.4244C7.44258 1.30859 7.14506 1.30859 6.55 1.30859H5.7C5.10494 1.30859 4.80742 1.30859 4.58014 1.4244C4.38021 1.52626 4.21767 1.68881 4.11581 1.88873C4 2.11601 4 2.41354 4 3.00859V3.43359M5.0625 6.35547V9.01172M7.1875 6.35547V9.01172M1.34375 3.43359H10.9063M9.84375 3.43359V9.3836C9.84375 10.2762 9.84375 10.7225 9.67004 11.0634C9.51724 11.3633 9.27343 11.6071 8.97355 11.7599C8.63263 11.9336 8.18633 11.9336 7.29375 11.9336H4.95625C4.06367 11.9336 3.61737 11.9336 3.27645 11.7599C2.97657 11.6071 2.73276 11.3633 2.57996 11.0634C2.40625 10.7225 2.40625 10.2762 2.40625 9.3836V3.43359"
        stroke={color || '#B42318'}
        strokeWidth="1.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
