import { useMemo } from 'react';
import useCaseFiles from '../../Files/useCaseFiles';

export default function useDocumentNames(caseID: string) {
  const { data: caseFiles, isLoading: caseFilesLoading } = useCaseFiles(caseID)


  return useMemo(() => {
    if (caseFilesLoading || !caseFiles) {
      return null;
    }

    const docNames: Record<string, string> = {};
    for (const docs of caseFiles ?? []) {
      docNames[docs.documentID] = docs.docFileName;
    }
    return docNames;
  }, [caseID, caseFiles]);
}
