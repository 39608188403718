import React from 'react';

type Props = {};

export default function IconCancel(props: React.SVGProps<SVGSVGElement>) {
  const stroke = props?.style?.color ?? 'var(--color-icon-grey)';
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12" cy="12" r="9" stroke={stroke} strokeWidth="2" />
      <path d="M18 18L6 6" stroke={stroke} strokeWidth="2" />
    </svg>
  );
}
