/* eslint no-underscore-dangle: 0 */
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { useCallback, useState } from 'react';
import APIURL from './apiUrl';

export function useSearchQuery(caseID, searchTerm, select, { onSuccess } = {}) {
  return useQuery(
    ['searchResults', caseID, searchTerm],
    async () => findSearchOccurances(searchTerm, caseID),
    {
      // enabled: Boolean(searchTerm && searchTerm.length > 0),
      select,
      onSuccess: (data) => {
        if (typeof onSuccess === 'function') {
          onSuccess(data, searchTerm);
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
}

export const documentSelector = {
  filterByVisibleDocumentsAndPages: (documentList) => (data) => {
    const result = {
      totalCount: 0,
      totalIncludingHiddenDocumentsAndPages: data.totalOccurancesFound,
      visibleDocuments: [],
    };
    Object.entries(data || {}).forEach(([documentID, documentResults]) => {
      if (documentID === 'totalOccurancesFound') {
        return;
      }

      const matchingDocument = documentList.find((doc) => doc.documentID.includes(documentID));
      if (matchingDocument) {
        const visiblePages = matchingDocument.pages.filter((page) =>
          Object.keys(documentResults).includes(page.pageNumber.toString()),
        );

        if (visiblePages.length > 0) {
          const matchesPerPage = visiblePages.reduce((acc, page) => {
            const pageNumber = page.pageNumber.toString();
            if (documentResults[pageNumber]) {
              acc[pageNumber] = documentResults[pageNumber];
              result.totalCount += documentResults[pageNumber].length;
            }
            return acc;
          }, {});

          result.visibleDocuments.push({
            ...matchingDocument,
            matches: matchesPerPage,
          });
        }
      }
    });

    return result;
  },
};

export const selectors = {
  filterByVisibleDocumentsAndPages: (documentList) => (data) => {
    const result = {
      totalCount: 0,
      totalIncludingHiddenDocumentsAndPages: data.totalOccurancesFound,
    };
    Object.entries(data || {}).forEach(([documentID, documentResults]) => {
      Object.entries(documentResults).forEach(([pageNumber, pageMatches]) => {
        const matchingPage = documentList.find(
          ({ documentID: docID, pages }) =>
            docID === documentID &&
            pages.some(({ pageNumber: pageNum }) => pageNum.toString() === pageNumber),
        );

        if (matchingPage) {
          result[documentID] = {
            ...(result[documentID] || {}),
            [pageNumber]: pageMatches,
          };

          result.totalCount += pageMatches.length;

          result[documentID].numOfMatchesInDocument =
            (result[documentID]?.numOfMatchesInDocument || 0) + pageMatches.length;
        }
      });
    });
    return result;
  },
  filterByVisibleTimelinePages: (timelineEntriesList) => (data) => {
    const result = {
      totalCount: 0,
      totalIncludingHiddenDocumentsAndPages: data.totalOccurancesFound,
    };

    Object.entries(data || {}).forEach(([documentID, documentResults]) => {
      Object.entries(documentResults).forEach(([pageNumber, pageMatches]) => {
        const matchingEntry = timelineEntriesList?.find((entry) =>
          entry?.pages.some(
            ({ pageNumber: pageNum, documentID: docID }) =>
              pageNum.toString() === pageNumber && docID === documentID,
          ),
        );

        if (matchingEntry) {
          result[matchingEntry.id] = {
            ...(result[matchingEntry.id] || {}),
            [pageNumber]: pageMatches,
          };

          result.totalCount += pageMatches.length;

          result[matchingEntry.id].numOfMatchesInEntry =
            (result[matchingEntry.id]?.numOfMatchesInEntry || 0) + pageMatches.length;
        }
      });
    });
    return result;
  },
};

/**
 * 
 * @param {String} keyword The text sting being searched
 * @param {String} caseID The caseID being queried
 * @returns {Promise} Returns object with search results in the following format:
 * 
 *{ documentID: { pageNumber: # numOfMatchesInDocument: # }, ...other documents, totalOccurancesFound: # }
        
 * 
 */
export function findSearchOccurances(keyword, caseID) {
  return new Promise((resolve, reject) => {
    if (keyword === '') {
      resolve({});
    } else if (keyword.length < 3) {
      reject(new Error('Search query must be at least 3 characters long'));
    } else {
      const terms = [];
      keyword.split(' ').forEach((term) => {
        if (term.length > 2) {
          terms.push({
            wildcard: {
              text: {
                value: `*${term}*`,
                case_insensitive: true,
              },
            },
          });
        }
      });
      axios
        .post(`${APIURL}searchDocuments`, {
          size: 5000,
          query: {
            bool: {
              minimum_should_match: 1,
              should: terms,
              filter: {
                match: {
                  caseID: {
                    query: caseID,
                    operator: 'and',
                  },
                },
              },
            },
          },
        })
        .then((response) => {
          /*
          Format results in the following format:
          { documentID: { pageNumber: # numOfMatchesInDocument: # }, ...other documents, totalOccurancesFound: # }
        */
          const hits = groupResults(response.data.hits.hits, keyword);
          const searchResults = {};

          hits.forEach((res) => {
            if (searchResults[res._source.documentID] === undefined) {
              searchResults[res._source.documentID] = {};
            }

            if (searchResults[res._source.documentID][res._source.pageNumber + 1] === undefined) {
              searchResults[res._source.documentID][res._source.pageNumber + 1] = [];
            }

            searchResults[res._source.documentID][res._source.pageNumber + 1].push(res._source);
          });

          let totalOccurancesFound = 0;
          Object.keys(searchResults).forEach((document) => {
            let count = 0;
            Object.values(searchResults[document]).forEach((a) => {
              count += a.length;
            });
            searchResults[document].numOfMatchesInDocument = count;
            totalOccurancesFound += count;
          });
          searchResults.totalOccurancesFound = totalOccurancesFound;

          return resolve(searchResults);
        })
        .catch((error) => {
          Sentry.captureException(error);
          if (error.message === 'Network Error') {
            return reject(
              Error('Network Error. Please check your internet connection and try again.'),
            );
          }

          return reject(error.message);
        });
    }
  });
}

function groupResults(hits, keyword) {
  const numberOfWords = keyword.trim().split(' ').length;
  if (numberOfWords === 1) {
    return hits;
  }
  hits = hits.sort(positionSortWithPageNumberAndDocumentID);
  const hitsGrouped = [];
  for (let i = 0; i < hits.length; i += 1) {
    const subArray = hits.slice(i, i + numberOfWords);
    if (!subArrayValid(subArray)) {
      continue;
    }
    if (subArrayValidMatch(subArray, keyword)) {
      hitsGrouped.push(combineResults(subArray));
    }
  }
  return hitsGrouped;
}

function combineResults(arrayOfHits) {
  return {
    _index: arrayOfHits[0]._index,
    _source: {
      text: arrayOfHits.map((hit) => hit._source.text).join(' '),
      pageNumber: arrayOfHits[0]._source.pageNumber,
      documentID: arrayOfHits[0]._source.documentID,
      word_position: arrayOfHits[0]._source.word_position,
      Left: arrayOfHits[0]._source.Left,
      Top: arrayOfHits[0]._source.Top,
      Height: arrayOfHits[0]._source.Height,
      Width:
        arrayOfHits.map((hit) => hit._source.Width).reduce((a, b) => a + b) +
        0.001 * arrayOfHits.length,
    },
  };
}

/**
 * Gets the number of search results for a given range of pages in a segment.
 *
 * @param {Array} segmentPages
 * @param {Object} documentSearchResults
 * @returns {Number}
 */
export function getSearchMatchesCount(segmentPages, documentSearchResults, hideDuplicates) {
  if (!documentSearchResults) {
    return 0;
  }
  let count = 0;
  if (hideDuplicates) {
    segmentPages = segmentPages.filter((page) => page.isDuplicate !== 1);
  }

  segmentPages.forEach((page) => {
    count += documentSearchResults[page.page_number]?.length || 0;
  });
  return count;
}

const positionSortWithPageNumberAndDocumentID = (f1, f2) =>
  f1._source.documentID.localeCompare(f2._source.documentID) ||
  f1._source.pageNumber - f2._source.pageNumber ||
  f1._source.word_position - f2._source.word_position;

const subArrayValid = (subArray) =>
  subArray.filter(
    (hit) =>
      hit._source.pageNumber === subArray[0]._source.pageNumber &&
      hit._source.documentID === subArray[0]._source.documentID,
  ).length === subArray.length &&
  subArray[subArray.length - 1]._source.word_position - subArray[0]._source.word_position ===
    subArray.length - 1;

const subArrayValidMatch = (subArray, keyword) => {
  const wordArr = strippedString(subArray.map((hit) => hit._source.text).join(' ')).split(' ');

  const keywordArr = strippedString(keyword).split(' ');
  for (let i = 0; i < wordArr.length; i++) {
    if (!wordArr[i].includes(keywordArr[i])) {
      return false;
    }
  }
  return true;
};

function strippedString(str) {
  return str.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();
}

export async function getTextFromNoteScreenshot(caseID, documentID, pageNumber, boundingBox) {
  const { sx, sy, ex, ey } = boundingBox;
  return axios
    .get(
      `${APIURL}note/textDetection/${caseID}/${documentID}/${pageNumber}?sx=${sx}&sy=${sy}&ex=${ex}&ey=${ey}`,
    )
    .then((text) => text.data);
}

export const useDebounceInput = ({ initialValue, onChange, delay }) => {
  const [internalValue, setInternalValue] = useState(initialValue);

  const propagateValue = useCallback(
    debounce((value) => {
      onChange(value);
    }, delay),
    [onChange, delay],
  );

  const handleChange = useCallback(
    (inputEvent) => {
      const { value } = inputEvent.target;

      setInternalValue(value);
      propagateValue(value);
    },
    [propagateValue],
  );

  const reset = useCallback((resetValue = '') => {
    setInternalValue(resetValue);
    propagateValue(resetValue);
  }, []);

  return {
    value: internalValue,
    onChange: handleChange,
    reset,
  };
};
