import { useMemo } from 'react';
import { useGetNotesDocumentsQuery } from '../../../__generated__/graphql';
import { convertObjectToWhereInput, formatNoteDbResult } from './gqlUtils';

const useNotes = (getNotesArgs: {
  id?: string;
  case_id?: string;
  document_id?: string;
  page_id?: string;
  owner_id?: string;
}) => {
  const whereInput = useMemo(() => convertObjectToWhereInput(getNotesArgs), [getNotesArgs]);

  const { data, ...rest } = useGetNotesDocumentsQuery({
    variables: { where: whereInput },
  });

  return useMemo(
    () => ({
      data: data?.notes.map(formatNoteDbResult) ?? [],
      ...rest,
    }),
    [data?.notes],
  );
};

export default useNotes;
