import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

export default function IconSplit(props: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.625 10.5H17.375M9.5 7.875V0.875M9.5 0.875L13 3.9375M9.5 0.875L6 3.9375M9.5 12.6875V19.6875M9.5 19.6875L13 17.0625M9.5 19.6875L6 17.0625"
        stroke={props?.style?.color ?? 'var(--color-icon-grey)'}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
