import { RotateLeft, RotateRight } from '@mui/icons-material';
import NavigationButton from '../../common/PdfNavigation/Components/NavigationButton';

type Props = {
  rotateCounterClockwise: () => void;
  rotateClockwise: () => void;
};

export default function PageControls({ rotateCounterClockwise, rotateClockwise }: Props) {
  return (
    <div
      style={{
        display: 'inline-block',
        float: 'inline-end',
      }}
    >
      <div
        style={{
          display: 'inline-flex',
          width: '90px',
          zIndex: 1000,
          position: 'fixed',
          marginTop: '0px',
          margin: '3px',
          padding: '6px',
          borderRadius: '7px',
          paddingRight: '12px',
          paddingLeft: '12px',
        }}
      >
        <NavigationButton
          onClick={() => rotateCounterClockwise()}
          sx={{ mr: 0.5, opacity: '100%', backgroundColor: 'white !important' }}
          icon={
            <RotateLeft
              sx={{
                fontSize: '1rem',
              }}
            />
          }
        />
        <NavigationButton
          onClick={() => rotateClockwise()}
          sx={{ mr: 1, opacity: '100%', backgroundColor: 'white !important' }}
          icon={
            <RotateRight
              sx={{
                fontSize: '1rem',
              }}
            />
          }
        />
      </div>
    </div>
  );
}
