import React, { useState } from 'react';
import { Box, Stack, TextField, Tooltip } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import OverflowText from '../common/OverflowText';
import AnchoredDropdownMenu from '../common/HTML_components/AnchoredDropdownMenu/AnchoredDropdownMenu';
import LinkWithQuery from '../../containers/Timeline/LinkWithQuery';

type Props = {
  isEditing: boolean;
  editedFileName: string;
  setEditedFileName: (value: string) => void;
  handleRenameFile: (selectedFile: File, e: any) => void;
  fileStatus: string;
  isFileProcessor: boolean;
  fileName: string;
  fileID: string;
  timelineID: number;
  handleFileStatusChange: (fileID: string, fileStatus: string) => void;
  selectedFile: File;
  groupingTabLock: { fileID: string; access: boolean; currentUser: string };
  insertGroupingLock: ({ fileID, caseID }: { fileID: string; caseID: string }) => void;
};

type File = {
  authorStatus: string;
  fileStatus: string;
  id: string;
  name: string;
  numOfPages: number;
  refID: number;
  type: string;
  uploadDate: string;
} | null;

export default function FilesTableNameCell({
  isEditing,
  editedFileName,
  setEditedFileName,
  handleRenameFile,
  fileStatus,
  isFileProcessor,
  fileName,
  fileID,
  timelineID,
  handleFileStatusChange,
  selectedFile,
  groupingTabLock,
  insertGroupingLock,
}: Props) {
  const [isMenuDropdownOpen, setIsMenuDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const componentRef = React.useRef(null);
  const { caseID } = useParams();

  const navigateToReviewTab = () => {
    if (fileStatus === 'PENDING') {
      handleFileStatusChange(fileID, fileStatus);
    }
    navigate(`../timeline/${timelineID}?documentID=${fileID}`);
  };

  const navigateToGroupingTab = async () => {
    if (fileStatus === 'PENDING') {
      handleFileStatusChange(fileID, fileStatus);
    }
    navigate(`${fileID}/grouping`);
    insertGroupingLock({ fileID: fileID, caseID: caseID });
  };

  const navigateToTaggingTab = () => {
    navigate(`tagging?fileID=${fileID}`);
  };

  const options = [
    {
      label: 'Review Tab',
      onClick: navigateToReviewTab,
    },
    ...(fileStatus === 'GROUPING' || fileStatus === 'PENDING'
      ? [
          {
            label: 'Grouping Tab',
            onClick: navigateToGroupingTab,
            disabled: groupingTabLock ? !groupingTabLock.access : false,
          },
        ]
      : []),
    ...(fileStatus !== 'PENDING'
      ? [
          {
            label: 'Tagging Tab',
            onClick: navigateToTaggingTab,
          },
        ]
      : []),
  ];

  if (!isFileProcessor) {
    return (
      <Stack direction="row" alignItems="center" sx={{ width: '80%' }}>
        {isEditing ? (
          <TextField
            defaultValue={editedFileName}
            onChange={(e) => setEditedFileName(e.target.value)}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === 'Enter') {
                handleRenameFile(selectedFile, e.target.value);
              }
            }}
            autoFocus
            sx={{ mt: 0.8 }}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              '&:hover': {
                '& svg': {
                  opacity: 1,
                },
              },
            }}
          >
            {fileStatus === 'COMPLETE' ? (
              <LinkWithQuery
                to={`../timeline/${timelineID}`}
                documentID={fileID}
                onClick={navigateToReviewTab}
              >
                <OverflowText>{fileName}</OverflowText>
              </LinkWithQuery>
            ) : (
              fileName
            )}
          </Box>
        )}
      </Stack>
    );
  }

  return (
    <Stack direction="row" alignItems="center" sx={{ width: '80%' }}>
      {isEditing ? (
        <TextField
          defaultValue={editedFileName}
          onChange={(e) => setEditedFileName(e.target.value)}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === 'Enter') {
              handleRenameFile(selectedFile);
            }
          }}
          autoFocus
          sx={{ mt: 0.8 }}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            '&:hover': {
              '& svg': {
                opacity: 1,
              },
            },
          }}
        >
          {fileStatus === 'COMPLETE' || (isFileProcessor && fileStatus !== 'UPLOADING') ? (
            <Tooltip
              title={
                groupingTabLock?.currentUser
                  ? `${groupingTabLock?.currentUser} is in the grouping tab`
                  : ''
              }
              placement="right-end"
            >
              <span ref={componentRef} onClick={() => setIsMenuDropdownOpen(!isMenuDropdownOpen)}>
                <OverflowText
                  sx={{
                    color: 'var(--color-link-blue)',
                  }}
                >
                  <span
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    {fileName}
                  </span>
                </OverflowText>
                <AnchoredDropdownMenu anchorComponentRef={componentRef} options={options} />
              </span>
            </Tooltip>
          ) : (
            fileName
          )}
        </Box>
      )}
    </Stack>
  );
}
