import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box } from '@mui/material';
import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InsertNote from '../../components/icons/InsertNotes';
import useNotesStore from '../Notes/useNotesStore';

export default function InsertNotesButton({ notes }) {
  const notesInsertionOption = useNotesStore((state) => state.notesInsertionOption);
  const setNotesInsertionOption = useNotesStore((state) => state.setNotesInsertionOption);
  const disabled = useNotesStore((state) => state.notesInsertLoading);
  const setNotesToInsert = useNotesStore((state) => state.setNotesToInsert);
  const setTotalNotesToInsert = useNotesStore((state) => state.setTotalNotesToInsert);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeInsertionType = (type) => {
    setNotesInsertionOption(type);
    handleClose();
  };

  const insertNotes = () => {
    const numNotesToInsert = Object.values(notes).flat().length;
    setNotesToInsert(notes);
    setTotalNotesToInsert(numNotesToInsert);
  };

  return (
    <>
      <Box
        textAlign="center"
        sx={{
          height: '2em',
          paddingTop: 1.2,
        }}
      >
        <Box
          sx={{
            zIndex: 100,
            width: '90%',
            margin: '.5rem .25rem .25rem 0.85rem',
            top: 'auto',
            left: 0,
            bottom: 7,
            position: 'absolute',
            backgroundColor: 'secondary.light',
            textTransform: 'capitalize',
            display: 'flex',
            justifyContent: 'space-between',
            borderRadius: '12px',
            color: 'black',
            cursor: 'pointer',
            overflow: 'hidden',
          }}
        >
          <Box
            display="flex"
            padding="8px"
            width="100%"
            margin="auto"
            onClick={() => !disabled && insertNotes()}
            sx={{
              '&:hover': {
                backgroundColor: 'secondary.main',
              },
            }}
          >
            <InsertNote sx={{ marginRight: '8px', marginLeft: '8px', fontSize: '24px' }} />
            <Box sx={{ fontSize: '14px', lineHeight: '24px' }}>
              {mapNotesInsertionOptionToText[notesInsertionOption]}
            </Box>
          </Box>
          <Box
            sx={{
              borderLeft: 'thin solid',
              borderColor: 'secondary.dark',
              '&:hover': {
                backgroundColor: 'secondary.main',
              },
            }}
            padding="8px"
            onClick={handleClick}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <ArrowDropUpIcon />
          </Box>
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {Object.keys(mapNotesInsertionOptionToText).map((key) => (
          <MenuItem
            key={key}
            selected={notesInsertionOption === key}
            onClick={() => handleChangeInsertionType(key)}
          >
            {mapNotesInsertionOptionToText[key]}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

const mapNotesInsertionOptionToText = {
  List: 'Insert as List',
  Table: 'Insert as Table',
};
