import { Card, CardContent, Radio, CardMedia, CardProps, CardHeader, Divider } from '@mui/material';

interface CustomRadioCardProps extends CardProps {
  title: string;
  icon: string;
  selected: boolean;
  onSelectedValueChange: (newValue: string) => void;
  imageDimensions?: {
    height: string;
    width: string;
  };
  cardDimensions?: {
    height: string;
    width: string;
  };
}

export default function CustomRadioCard({
  title,
  icon,
  selected,
  onSelectedValueChange,
  imageDimensions = { height: '75.62px', width: '152.5px' },
  cardDimensions = { height: '168px', width: '232px' },
  ...rest
}: CustomRadioCardProps) {
  return (
    <Card
      sx={{
        position: 'relative',
        width: cardDimensions.width,
        height: cardDimensions.height,
        cursor: 'pointer',
        '&:hover': {
          borderColor: '#1d3b61',
        },
      }}
      onClick={() => onSelectedValueChange(title)}
      {...rest}
    >
      <CardHeader title={title} titleTypographyProps={{ fontWeight: 600, fontSize: '1rem' }} />
      <Divider />
      <CardContent>
        <CardMedia
          component="img"
          height={imageDimensions.height}
          width={imageDimensions.width}
          image={icon}
          alt={title}
        />
      </CardContent>
      <Radio
        checked={selected}
        value={title}
        name="radio-button-card"
        disabled // value is changed by clicking card, radio is visual only
        sx={{ position: 'absolute', top: 8, right: 8 }}
      />
    </Card>
  );
}
