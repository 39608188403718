import { useContext, useMemo, useState } from 'react';
import { Switch, Tooltip } from '@mui/material';

import DocumentComparer from '../../../../components/DocumentComparer/DocumentComparer';
import CloseIcon from '../../../../components/icons/Close';
import Modal from '../../../../components/common/HTML_components/Modal/Modal';
import DuplicateActionButtons from './DuplicateActionButtons';
import DocumentCompareHeader, {
  ToolbarZoom,
} from '../../../DuplicatesByDocument/DocumentCompareHeader';
import CaseContext from '../../../Case/CaseContext';
import { useCompareDocuments } from './useCompareDocuments';
import { useSyncDocumentsScroll } from '../../../../components/DocumentComparer/useSyncDocumentsScroll';
import IconLocked from '../../../../components/icons/IconLocked';
import IconMouseScroll from '../../../../components/icons/IconMouseScroll';

type Props = {
  isDocumentComparerModalOpen: boolean;
  closeDocumentComparerModal: () => void;
  documentComparerDocumentIds: {
    firstDocumentID: string;
    secondDocumentID: string;
  };
  setDocumentComparerDocumentIds: (ids: {
    firstDocumentID?: string;
    secondDocumentID?: string;
  }) => void;
  isFirstComparisonDocumentDuplicate: boolean;
  isSecondComparisonDocumentDuplicate: boolean;
  handleUpdateDocumentDuplicateStatusV1Cases: (
    entryId: bigint,
    isDuplicate: boolean,
    contentTagsSectionID: number,
  ) => void;
  firstDocumentSectionID: string;
  secondDocumentSectionID: string;
  updateIndexRowCache: (sectionId: string, rowId: string, updatedValues: any) => void;
};

export default function DocumentComparisonModal({
  isDocumentComparerModalOpen,
  closeDocumentComparerModal,
  documentComparerDocumentIds,
  setDocumentComparerDocumentIds,
  isFirstComparisonDocumentDuplicate,
  isSecondComparisonDocumentDuplicate,
  handleUpdateDocumentDuplicateStatusV1Cases,
  firstDocumentSectionID,
  secondDocumentSectionID,
  updateIndexRowCache,
}: Props) {
  const [zoom, setZoom] = useState<number>(0.8);
  const { caseInstance } = useContext(CaseContext);
  const caseVersion = useMemo(() => caseInstance?.version, [caseInstance]);

  const {
    isFirstDocumentDuplicate,
    isSecondDocumentDuplicate,
    updateDocumentToDuplicate,
    updateDocumentToNonDuplicate,
    isUpdateInProgress,
    setNewOriginal,
  } = useCompareDocuments(
    caseVersion,
    documentComparerDocumentIds.firstDocumentID,
    documentComparerDocumentIds.secondDocumentID,
    setDocumentComparerDocumentIds,
    firstDocumentSectionID,
    secondDocumentSectionID,
    handleUpdateDocumentDuplicateStatusV1Cases,
    isFirstComparisonDocumentDuplicate,
    isSecondComparisonDocumentDuplicate,
    updateIndexRowCache,
  );

  const {
    areDocumentScrollsSynced,
    setAreDocumentScrollsSynced,
    setFirstDocumentPageCount,
    setSecondDocumentPageCount,
  } = useSyncDocumentsScroll(isDocumentComparerModalOpen);

  return (
    <Modal
      isOpen={isDocumentComparerModalOpen}
      onClose={closeDocumentComparerModal}
      style={{ height: '95%' }}
    >
      <div
        style={{
          padding: '1rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <span
            style={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
              color: 'var(--color-text-primary)',
            }}
          >
            Document Comparison
          </span>
          <span>
            {caseVersion === 1 && (
              <Tooltip title="Sync Document Scrolling">
                <span
                  style={{
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    lineHeight: 1,
                  }}
                >
                  <IconMouseScroll />

                  <IconLocked />
                  <Switch
                    checked={areDocumentScrollsSynced}
                    onChange={() => setAreDocumentScrollsSynced(!areDocumentScrollsSynced)}
                    sx={{
                      mr: '0.5rem',
                    }}
                  />
                </span>
              </Tooltip>
            )}

            <button className="sm-icon-button" type="button" onClick={closeDocumentComparerModal}>
              <CloseIcon />
            </button>
          </span>
        </div>
        <hr className="sm-horizontal-divider" />
        {caseVersion === 2 && (
          <DocumentCompareHeader
            duplicate={() =>
              updateDocumentToDuplicate(documentComparerDocumentIds.secondDocumentID)
            }
            notDuplicate={() =>
              updateDocumentToNonDuplicate(documentComparerDocumentIds.secondDocumentID)
            }
            setOriginal={() =>
              setNewOriginal(
                documentComparerDocumentIds.secondDocumentID,
                documentComparerDocumentIds.firstDocumentID,
              )
            }
            zoom={zoom}
            setZoom={setZoom}
            areDocumentScrollsSynced={areDocumentScrollsSynced}
            setAreDocumentScrollsSynced={() =>
              setAreDocumentScrollsSynced(!areDocumentScrollsSynced)
            }
          />
        )}
        {caseVersion === 1 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '1rem',
            }}
          >
            <DuplicateActionButtons
              documentId={documentComparerDocumentIds.firstDocumentID}
              isDocumentDuplicate={isFirstDocumentDuplicate}
              handleUpdateDocumentToDuplicate={updateDocumentToDuplicate}
              handleUpdateDocumentToNonDuplicate={updateDocumentToNonDuplicate}
              isUpdateInProgress={isUpdateInProgress}
              style={{
                marginLeft: 'auto',
              }}
            />
            <ToolbarZoom zoom={zoom} setZoom={setZoom} style={{ margin: 'auto' }} />
            <DuplicateActionButtons
              documentId={documentComparerDocumentIds.secondDocumentID}
              isDocumentDuplicate={isSecondDocumentDuplicate}
              handleUpdateDocumentToDuplicate={updateDocumentToDuplicate}
              handleUpdateDocumentToNonDuplicate={updateDocumentToNonDuplicate}
              isUpdateInProgress={isUpdateInProgress}
              style={{
                marginRight: 'auto',
              }}
            />
          </div>
        )}
        <DocumentComparer
          firstDocumentID={documentComparerDocumentIds.firstDocumentID}
          secondDocumentID={documentComparerDocumentIds.secondDocumentID}
          setFirstDocumentPageCount={setFirstDocumentPageCount}
          setSecondDocumentPageCount={setSecondDocumentPageCount}
          zoom={zoom}
        />
      </div>
    </Modal>
  );
}
