import axios from 'axios';
import * as Sentry from '@sentry/react';
import apiUrl from '../../library/utilities/apiUrl';

async function downloadSegment(caseID, entryID) {
  const segmentUrl = await axios
    .get(`${apiUrl}case/${caseID}/entry/${entryID}/download`)
    .catch((err) => {
      console.error(err);
      Sentry.captureException(err);
    });
  const link = document.createElement('a');
  link.href = segmentUrl.data;
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

export default downloadSegment;
