import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  Autocomplete,
  Button,
  Chip,
  Typography,
  IconButton,
  Box,
  Dialog,
  Stack,
  DialogActions,
  Divider,
  CircularProgress,
  TextField,
} from '@mui/material';
import { Close, Add } from '@mui/icons-material';
import { toast } from 'react-toastify';
import useFeatureFlags from '../../../config/useFeatureFlags';
import apiUrl from '../../../library/utilities/apiUrl';
import UserAccessRow from './UserAccessRow';
import SearchIcon from '../../../components/icons/SearchIcon';
import { useUser } from '../../../library/contexts/AuthContext';

export default function AddUsersMenu(props) {
  const { user } = useUser();
  const { caseID, addUsersOpen, handleUserAddClose, setEventAlert } = props;
  const [searchDisplay, setSearchDisplay] = useState([]);
  const [proposedAdditions, setProposedAdditions] = useState([]);
  const [proposedRemovals, setProposedRemovals] = useState([]);

  const { data: featureFlags } = useFeatureFlags();
  const emailDisabled = featureFlags?.CaseShareEmailDisabled ?? false;

  const handleSaveAccessChanges = async () => {
    try {
      const accessChangePromises = [];
      proposedAdditions.forEach((user) => {
        accessChangePromises.push(addUserToCase(user, caseID, emailDisabled));
      });

      proposedRemovals.forEach((user) => {
        accessChangePromises.push(removeUserAccess(user, caseID));
      });
      await Promise.all(accessChangePromises);
      setProposedRemovals([]);
      setProposedAdditions([]);
      handleUserAddClose();
      setEventAlert('Case access successfully updated');
    } catch (error) {
      console.error(error);
      toast.error('Unable to save case access changes.', {
        toastId: 'access-change',
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const usersList = await axios.post(`${apiUrl}getUsersForCase`, {
          userID: user.username,
          caseID,
        });
        if (isMounted) {
          setSearchDisplay(usersList.data);
        }
      } catch (err) {
        toast.error("Couldn't grab users.", {
          toastId: 'users-grab',
        });
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  const caseOwners = useMemo(
    () => searchDisplay.filter((user) => user.role === 'OWNER'),
    [searchDisplay],
  );

  const deleteProposedUser = (accessRole, username) => {
    if (accessRole === 'OWNER' && !proposedRemovals.includes(username)) {
      setProposedRemovals([...proposedRemovals, username]);
    } else if (proposedAdditions.includes(username)) {
      setProposedAdditions(proposedAdditions.filter((userID) => userID !== username));
    }
  };

  const addProposedUser = (accessRole, username) => {
    if (accessRole !== 'OWNER' && !proposedAdditions.includes(username)) {
      setProposedAdditions([...proposedAdditions, username]);
    }
  };

  const isLastCaseOwner =
    caseOwners.length === 1 || caseOwners.length - proposedRemovals.length <= 1;

  return (
    <Dialog
      open={addUsersOpen}
      onClose={handleUserAddClose}
      PaperProps={{
        sx: {
          borderRadius: 2,
          height: '28rem',
          minWidth: '41rem',
          pt: 4,
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack sx={{ width: '90%' }} spacing={1.5}>
        <Typography variant="h3">Share Case Access</Typography>
        <Typography variant="caption">
          To share case access, please search for a team member below and then click "Save"
        </Typography>
      </Stack>
      <Divider
        sx={{
          mt: 2,
          width: '100%',
        }}
      />
      <Stack direction="row" sx={{ width: '82%', mt: 3 }} spacing={17}>
        <Typography variant="h3" fontSize={17} mt={1}>
          Team members
        </Typography>
        <Autocomplete
          disablePortal
          sx={{ width: '50%' }}
          options={searchDisplay}
          getOptionLabel={(user) => `${user.given_name} ${user.family_name}`}
          renderOption={(props, user) => (
            <Stack direction="row" spacing={5} key={user.username} mt={2} ml={2}>
              <Typography
                variant="caption"
                fontWeight={500}
                width="60%"
                sx={{
                  opacity:
                    Boolean(user.role === 'OWNER' || proposedAdditions.includes(user.username)) &&
                    '50%',
                }}
              >
                {user.given_name} {user.family_name}
              </Typography>
              <Chip
                label="Add"
                onClick={() => addProposedUser(user.role, user.username)}
                onDelete={() => addProposedUser(user.role, user.username)}
                deleteIcon={<Add sx={{ fontSize: '1rem !important' }} />}
                variant="filled"
                color="lightSuccess"
                disabled={Boolean(
                  user.role === 'OWNER' || proposedAdditions.includes(user.username),
                )}
                classes="small-page-card"
              />
            </Stack>
          )}
          freeSolo
          forcePopupIcon
          ListboxProps={{
            sx: {
              maxHeight: '8.8rem',
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="search"
              type="new-search"
              onClick={(e) => {
                e.stopPropagation();
              }}
              placeholder="Search"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <SearchIcon sx={{ color: 'primary' }} />
                  </Stack>
                ),
              }}
            />
          )}
        />
      </Stack>
      <Divider
        sx={{
          mt: 2,
          width: '90%',
        }}
      />
      <Stack sx={{ width: '82%', my: 0.8 }}>
        <Typography variant="caption">Name</Typography>
      </Stack>

      <Stack
        direction="column"
        sx={{
          width: '91%',
          ml: 0.5,
          height: '8rem',
          overflowY: 'scroll',
        }}
      >
        {caseOwners.length > 0 &&
          caseOwners.map(
            (user) =>
              !proposedRemovals.includes(user.username) && (
                <>
                  <Divider />
                  <UserAccessRow
                    key={user.username}
                    givenName={user.given_name}
                    familyName={user.family_name}
                    email={user.email}
                    username={user.username}
                    accessRole={user.role}
                    deleteProposedUser={deleteProposedUser}
                    isLastOwner={isLastCaseOwner}
                  />
                </>
              ),
          )}
        {caseOwners.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: '3rem',
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {proposedAdditions.length > 0 &&
          searchDisplay.map(
            (user) =>
              proposedAdditions.includes(user.username) && (
                <>
                  <Divider />
                  <UserAccessRow
                    key={user.username}
                    givenName={user.given_name}
                    familyName={user.family_name}
                    email={user.email}
                    username={user.username}
                    accessRole={user.role}
                    deleteProposedUser={deleteProposedUser}
                    isLastOwner={false}
                  />
                </>
              ),
          )}
        <Divider />
      </Stack>
      <Divider sx={{ width: '100%' }} />
      <Stack
        direction="row"
        spacing={2}
        mt={3}
        width="100%"
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <Button
          variant="outlined"
          classes="primary"
          sx={{ width: '35%' }}
          onClick={() => {
            handleUserAddClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          classes="primary"
          onClick={() => handleSaveAccessChanges()}
          sx={{ width: '35%' }}
        >
          Save
        </Button>
      </Stack>
      <DialogActions>
        <IconButton
          aria-label="close"
          style={{ position: 'absolute', right: 8, top: 8 }}
          onClick={() => {
            handleUserAddClose();
          }}
        >
          <Close />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
}

const addUserToCase = async (username, caseID, emailDisabled) => {
  try {
    await axios.post(`${apiUrl}addUserRoleForCase`, {
      userID: username,
      caseID: caseID,
      role: 'OWNER',
      emailDisabled: emailDisabled,
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};

const removeUserAccess = async (username, caseID) => {
  try {
    await axios.post(`${apiUrl}removeUserAccessForCase`, {
      userID: username,
      caseID: caseID,
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};
