import { useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  useUpdateNoteMutation,
  EditNoteInput,
  UpdateNoteMutationVariables,
} from '../../../__generated__/graphql';
import { Note, NoteImage } from '../types/noteTypes';
import { formatNoteImageForGQL } from './gqlUtils';

type EditedNote = Partial<
  Note & {
    tagsToAdd?: string[];
    tagsToRemove?: string[];
    imagesToAdd?: NoteImage[];
    imagesToRemove?: number[];
  }
>;
const useUpdateNote = () => {
  const [updateNoteMutation, mutationStateInfo] = useUpdateNoteMutation();

  const callbackMutation = useCallback(
    async ({
      variables,
    }: {
      variables: {
        note_id: UpdateNoteMutationVariables['note_id'];
        editNoteData: EditedNote;
      };
    }) => {
      try {
        await updateNoteMutation({
          variables: {
            note_id: variables.note_id,
            editNoteData: formatEditNoteData(variables.editNoteData),
          },
        });
      } catch (err) {
        console.error('error updating note', err);
        toast.error('Failed to update note');
        throw new Error();
      }
    },
    [updateNoteMutation],
  );

  return [callbackMutation, mutationStateInfo];
};

export default useUpdateNote;

const formatEditNoteData = (
  note: Partial<
    Note & {
      tagsToAdd?: string[];
      tagsToRemove?: string[];
      imagesToAdd?: NoteImage[];
      imagesToRemove?: number[];
    }
  >,
): EditNoteInput => ({
  title: note.title,
  body: note.body,
  date: note.date,
  physician: note.physician,
  private_note: note.privateNote != null && note.privateNote > 0,
  tagsToAdd: note.tagsToAdd,
  tagsToRemove: note.tagsToRemove,
  imagesToAdd: note.imagesToAdd?.map(formatNoteImageForGQL),
  imagesToRemove: note.imagesToRemove,
});
