import { useEffect, useState } from 'react';

type AsyncResult<T> =
  | { status: 'pending' }
  | { status: 'resolved'; data: T }
  | { status: 'rejected'; error: any };

type Cancelable<fn extends (...args: any[]) => void> = {
  cb: fn;
  cancel: () => void;
};

function cancelable<fn extends (...args: any[]) => void>(cb: fn): Cancelable<fn> {
  let canceled = false;
  return {
    cb: ((...args) => {
      if (canceled) {
        return;
      }
      cb(...args);
    }) as fn,
    cancel() {
      canceled = true;
    },
  };
}

export function useAsync<T>(func: (abort: AbortSignal) => Promise<T>, deps: any[]) {
  const [result, set_result] = useState<AsyncResult<T>>({ status: 'pending' });
  const [refresh_token, set_refresh_token] = useState(0);
  const [prev_abort, set_prev_abort] = useState<AbortController | undefined>();
  const [prev_resolve, set_prev_resolve] = useState<Cancelable<(value: T) => void> | undefined>();
  const [prev_reject, set_prev_reject] = useState<Cancelable<(error: any) => void> | undefined>();

  useEffect(() => {
    prev_resolve?.cancel();
    prev_reject?.cancel();
    prev_abort?.abort('cancelled');
    const abort = new AbortController();
    const resolve = cancelable((data: T) => set_result({ status: 'resolved', data }));
    const reject = cancelable((error: any) => set_result({ status: 'rejected', error }));
    set_prev_abort(abort);
    set_prev_resolve(resolve);
    set_prev_reject(reject);
    set_result({ status: 'pending' });

    func(abort.signal).then(resolve.cb, reject.cb);

    return () => {
      resolve.cancel();
      reject.cancel();
      abort.abort('cancelled');
    };
  }, [refresh_token, ...deps]);

  function refresh() {
    set_refresh_token(Date.now() + Math.random());
  }

  return [result, refresh] as const;
}
