import { AppBar, Toolbar, Box, Typography } from '@mui/material';
import { LooksOneOutlined } from '@mui/icons-material';
import { useMemo } from 'react';

function DocumentDisplayFooter(props) {
  const { similarityScore, duplicatesLength, selected, isDuplicate, onChange } = props;

  const backgroundColor = useMemo(() => {
    if (duplicatesLength === undefined) {
      return 'primary';
    }
    if (selected === true) {
      return '#6EE4E4';
    }
    if (isDuplicate === true) {
      return 'themeDuplicates.main';
    }
    return 'themeNotes.light';
  }, [duplicatesLength, selected, isDuplicate]);

  const similarity = useMemo(() => {
    if (similarityScore >= 0.98) {
      return 'High';
    }
    if (similarityScore >= 0.95) {
      return 'Medium';
    }
    return 'Low';
  }, [similarityScore]);

  return (
    <AppBar
      position="static"
      onClick={onChange}
      sx={{
        maxWidth: '100%',
        backgroundColor: backgroundColor,
        color: '#000000DD',
        boxShadow: 'none',
        cursor: duplicatesLength !== undefined && 'pointer',
        borderRadius: 0,
      }}
    >
      {duplicatesLength === undefined ? (
        <Toolbar variant="dense" sx={{ maxWidth: '100%', justifyContent: 'center' }}>
          <LooksOneOutlined sx={{ color: '#FFFFFF' }} />
          <Box sx={{ fontSize: '0.75rem', color: '#FFFFFF', marginLeft: 1 }}>Original</Box>
        </Toolbar>
      ) : (
        <Toolbar variant="dense" sx={{ maxWidth: '100%', justifyContent: 'center' }}>
          {isDuplicate === true ? (
            <Typography sx={{ fontWeight: '500', fontSize: '0.8em' }}>
              Confirmed Duplicate
            </Typography>
          ) : (
            <Box sx={{ fontSize: '0.75rem' }}>{similarity}</Box>
          )}
        </Toolbar>
      )}
    </AppBar>
  );
}

export default DocumentDisplayFooter;
