export default function ReportsIcon(props) {
  return (
    <svg
      {...props}
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" y="4" width="48" height="48" rx="24" fill="#002147" />
      <path
        d="M30 18.2695V22.4001C30 22.9601 30 23.2401 30.109 23.4541C30.2049 23.6422 30.3578 23.7952 30.546 23.8911C30.7599 24.0001 31.0399 24.0001 31.6 24.0001H35.7305M32 29H24M32 33H24M26 25H24M30 18H24.8C23.1198 18 22.2798 18 21.638 18.327C21.0735 18.6146 20.6146 19.0735 20.327 19.638C20 20.2798 20 21.1198 20 22.8V33.2C20 34.8802 20 35.7202 20.327 36.362C20.6146 36.9265 21.0735 37.3854 21.638 37.673C22.2798 38 23.1198 38 24.8 38H31.2C32.8802 38 33.7202 38 34.362 37.673C34.9265 37.3854 35.3854 36.9265 35.673 36.362C36 35.7202 36 34.8802 36 33.2V24L30 18Z"
        stroke="#33DC9C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="4" y="4" width="48" height="48" rx="24" stroke="#AEBBCA" strokeWidth="8" />
    </svg>
  );
}
