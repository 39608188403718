import { Theme } from '@mui/material/styles';
import { Box, CircularProgress, Typography } from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

type LoadingScreenProps = {
  averageUploadProgress: number;
  theme: Theme;
};

export default function LoadingScreen({ averageUploadProgress, theme }: LoadingScreenProps) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '0.75rem',
      }}
    >
      {averageUploadProgress === 100 ? (
        <CheckCircleIcon
          color="success"
          sx={{
            width: '4rem',
            height: '4rem',
          }}
        />
      ) : (
        <CircularProgress
          variant="determinate"
          value={averageUploadProgress}
          color={averageUploadProgress === 100 ? 'success' : 'primary'}
          size="4rem"
        />
      )}
      <Typography variant="h5" color={theme.palette.subHeading.main}>
        Uploading...
      </Typography>
      <Typography variant="subtitle" color={theme.palette.subHeading.main}>
        Please wait while we upload your template to Siftmed.
      </Typography>
    </Box>
  );
}
