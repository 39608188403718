import { SvgIcon } from '@mui/material';

export default function Error(props) {
  return (
    <SvgIcon
      {...props}
      width="106"
      height="106"
      viewBox="0 0 106 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.8301 79.3937C54.3228 79.3937 55.5749 78.8879 56.5865 77.8764C57.5945 76.8683 58.0985 75.618 58.0985 74.1252C58.0985 72.6325 57.5945 71.3804 56.5865 70.3689C55.5749 69.3608 54.3228 68.8568 52.8301 68.8568C51.3374 68.8568 50.087 69.3608 49.079 70.3689C48.0674 71.3804 47.5616 72.6325 47.5616 74.1252C47.5616 75.618 48.0674 76.8683 49.079 77.8764C50.087 78.8879 51.3374 79.3937 52.8301 79.3937ZM52.8301 105.736C45.5421 105.736 38.6931 104.352 32.2832 101.584C25.8733 98.8201 20.2975 95.0673 15.5559 90.3257C10.8143 85.5841 7.06145 80.0083 4.29728 73.5984C1.52959 67.1885 0.145752 60.3395 0.145752 53.0515C0.145752 45.7635 1.52959 38.9146 4.29728 32.5046C7.06145 26.0947 10.8143 20.5189 15.5559 15.7774C20.2975 11.0358 25.8733 7.28113 32.2832 4.51344C38.6931 1.74927 45.5421 0.367188 52.8301 0.367188C60.1181 0.367188 66.967 1.74927 73.377 4.51344C79.7869 7.28113 85.3627 11.0358 90.1042 15.7774C94.8458 20.5189 98.5987 26.0947 101.363 32.5046C104.131 38.9146 105.514 45.7635 105.514 53.0515C105.514 60.3395 104.131 67.1885 101.363 73.5984C98.5987 80.0083 94.8458 85.5841 90.1042 90.3257C85.3627 95.0673 79.7869 98.8201 73.377 101.584C66.967 104.352 60.1181 105.736 52.8301 105.736ZM52.8301 58.3199C54.3228 58.3199 55.5749 57.8142 56.5865 56.8026C57.5945 55.7946 58.0985 54.5442 58.0985 53.0515V31.9778C58.0985 30.4851 57.5945 29.2329 56.5865 28.2214C55.5749 27.2134 54.3228 26.7094 52.8301 26.7094C51.3374 26.7094 50.087 27.2134 49.079 28.2214C48.0674 29.2329 47.5616 30.4851 47.5616 31.9778V53.0515C47.5616 54.5442 48.0674 55.7946 49.079 56.8026C50.087 57.8142 51.3374 58.3199 52.8301 58.3199Z"
        fill="#F05353"
      />
    </SvgIcon>
  );
}
