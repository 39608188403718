import { TextField, Box, InputLabel } from '@mui/material';
import useNotesStore from '../../../Notes/useNotesStore';
import { FilterInputLabel } from '../ConfigurableViewFilter';

export default function DateFilter() {
  const afterDate = useNotesStore((state) => state.afterDate);
  const setAfterDate = useNotesStore((state) => state.setAfterDate);
  const beforeDate = useNotesStore((state) => state.beforeDate);
  const setBeforeDate = useNotesStore((state) => state.setBeforeDate);

  return (
    <>
      <FilterInputLabel>Refine</FilterInputLabel>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box>
          <InputLabel sx={{ fontSize: '0.7rem' }}>After</InputLabel>
          <TextField
            type="date"
            size="small"
            name="afterDate"
            value={afterDate}
            sx={{ width: '12rem' }}
            onChange={(e) => setAfterDate(e.target.value)}
            inputProps={{ min: '1800-01-01', max: '2999-12-31' }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          mt: 2,
          mb: 2,
        }}
      >
        <Box>
          <InputLabel sx={{ fontSize: '0.7rem' }}>Before</InputLabel>
          <TextField
            type="date"
            size="small"
            name="beforeDate"
            value={beforeDate}
            sx={{ width: '12rem' }}
            onChange={(e) => {
              setBeforeDate(e.target.value);
            }}
            inputProps={{ min: '1800-01-01', max: '3999-12-31' }}
          />
        </Box>
      </Box>
    </>
  );
}
