import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { useApolloClient } from '@apollo/client';
import {
  ResolveSelectedDuplicatePagesInput,
  useResolveSelectedDuplicatePagesMutation,
  GetTimelineEntriesDocument,
  GetTimelineEntriesDescriptorsDocument,
  GetDuplicateStatsDocument,
  GetDuplicatesDocument,
} from '../../../__generated__/graphql';

const useResolveSelectedDuplicatePages = () => {
  const [resolveSelectedDuplicatePagesMutation, state] = useResolveSelectedDuplicatePagesMutation();
  const client = useApolloClient();

  const wrappedMutation = useCallback(
    (params: ResolveSelectedDuplicatePagesInput) => {
      try {
        return resolveSelectedDuplicatePagesMutation({
          variables: {
            data: params,
          },
          update: (cache, { data }) => {
            if (data) {
              client.refetchQueries({
                include: [
                  GetTimelineEntriesDocument,
                  GetTimelineEntriesDescriptorsDocument,
                  GetDuplicateStatsDocument,
                  GetDuplicatesDocument,
                ],
              });
            }
          },
        });
      } catch (error) {
        toast.error(
          'A problem occurred while resolving duplicate sets. If issues persist, please try refreshing the page.',
        );
        Sentry.captureException(error);
        throw error;
      }
    },
    [resolveSelectedDuplicatePagesMutation, client],
  );

  return useMemo(() => [wrappedMutation, state], [wrappedMutation, state]);
};

export default useResolveSelectedDuplicatePages;
