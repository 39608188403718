import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
//TODO Eventually add error message

/**
 * Material-ui snackbar component.
 *
 * @param {bool} open - Whether the snackbar is showing or not
 * @param {String} severity - The background color of the snackbar
 * @param {String} variant - The variant of the snackbar
 * @param {String} text - The text displayed in the snackbar
 * @param  icon - The component icon that gets displayed on the left
 * @param  onClose - Function that is called after 3 seconds.
 * @param {String} verticalLocation - The vertical location of the snackbar
 * @param {String} horizontalLocation - The horizontal location of the snackbar
 *
 */
function FeedbackPopup(props) {
  const {
    open,
    severity = 'success',
    variant = 'filled',
    text,
    icon = '',
    onClose,
    duration = 3000,
    verticalLocation = 'top',
    horizontalLocation = 'right',
  } = props;
  return (
    open && (
      <Snackbar
        anchorOrigin={{
          vertical: verticalLocation,
          horizontal: horizontalLocation,
        }}
        open={open}
        autoHideDuration={duration}
        onClose={onClose}
      >
        <Alert variant={variant} severity={severity} onClose={onClose} icon={icon}>
          <Typography sx={{ fontSize: '12px', pt: '1px' }}>{text}</Typography>
        </Alert>
      </Snackbar>
    )
  );
}

FeedbackPopup.propTypes = {
  open: PropTypes.bool,
  severity: PropTypes.string,
  variant: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.element,
  onClose: PropTypes.func,
  verticalLocation: PropTypes.string,
  horizontalLocation: PropTypes.string,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default FeedbackPopup;
