/* eslint-disable no-unused-vars */
//Fixes false eslint warnings for unused args to handleFileUpload in FileUploadBoxPros type

import { useTheme } from '@mui/material';
import { useMemo, useState, SetStateAction, Dispatch } from 'react';
import { toast } from 'react-toastify';
import LoadingScreen from './LoadingScreen';
import FileSelect from './FileSelect';

type FileUploadBoxProps = {
  handleFileUpload: (files: FileList) => void;
  setUploadProgress: Dispatch<SetStateAction<{ [key: string]: number }>>;
  averageUploadProgress: number;
  uploadProgressLen: number;
  allowMultipleUploads?: boolean;
  acceptedFormats: string[];
};

FileUploadBox.defaultProps = {
  allowMultipleUploads: true,
};

export default function FileUploadBox({
  handleFileUpload,
  averageUploadProgress,
  uploadProgressLen,
  setUploadProgress,
  allowMultipleUploads = true,
  acceptedFormats,
}: FileUploadBoxProps) {
  const theme = useTheme();
  const [dragging, setDragging] = useState(false);
  const [hovering, setHovering] = useState(false);

  const acceptedFormatsString = useMemo(
    () => 'Accepted formats: '.concat(acceptedFormats.sort().join(', ')).concat('.'),
    [acceptedFormats],
  );

  const handleDrop = (files: FileList | null) => {
    if (files) {
      if (!allowMultipleUploads && files.length > 1) {
        toast.error('Only one file can be uploaded at a time.', {
          toastId: 'userAttemptedMultipleTemplateUpload',
        });
        setUploadProgress({});
      } else if (
        !Array.from(files).every((file) =>
          acceptedFormats.includes(file.name.split('.').pop()?.toUpperCase() ?? ''),
        )
      ) {
        toast.error(
          'Only files with the following extensions can be uploaded: '.concat(
            acceptedFormats.sort().join(', '),
          ),
          { toastId: 'userAttemptedInvalidFileFormatUpload' },
        );
        setUploadProgress({});
      } else {
        //uploadProgress not initialized here due to, surprisingly, causing a race condition!
        handleFileUpload(files);
      }
    }
  };

  const clickUpload = () => {
    const fileUpload = document.getElementById('click-file-upload') as HTMLInputElement;
    fileUpload.click();
  };

  return uploadProgressLen ? (
    <LoadingScreen averageUploadProgress={averageUploadProgress} theme={theme} />
  ) : (
    <FileSelect
      setHovering={setHovering}
      hovering={hovering}
      setDragging={setDragging}
      dragging={dragging}
      handleDrop={handleDrop}
      clickUpload={clickUpload}
      acceptedFormatsString={acceptedFormatsString}
      theme={theme}
    />
  );
}
