import React from 'react';

export default function IconHidden(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.17723 2.38495C7.44875 2.3448 7.72886 2.32327 8.01735 2.32327C11.428 2.32327 13.6661 5.33297 14.418 6.52352C14.509 6.66761 14.5545 6.73966 14.5799 6.85079C14.5991 6.93424 14.5991 7.06591 14.5799 7.14936C14.5544 7.26049 14.5086 7.33301 14.417 7.47806C14.2166 7.79513 13.9112 8.24073 13.5065 8.724M4.49237 3.46909C3.04789 4.44897 2.06724 5.81035 1.61738 6.52249C1.52597 6.66719 1.48027 6.73954 1.45479 6.85066C1.43565 6.93411 1.43564 7.06576 1.45477 7.14922C1.48023 7.26034 1.52573 7.33238 1.61673 7.47647C2.36862 8.66702 4.60668 11.6767 8.01735 11.6767C9.39258 11.6767 10.5772 11.1874 11.5506 10.5253M2.00442 0.987061L14.0303 13.0129M6.60009 5.58273C6.23738 5.94544 6.01304 6.44652 6.01304 6.99999C6.01304 8.10694 6.9104 9.0043 8.01735 9.0043C8.57083 9.0043 9.0719 8.77996 9.43461 8.41725"
        stroke={props?.style?.color ?? 'var(--color-icon-grey)'}
        strokeWidth="1.33621"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
