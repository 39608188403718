import { useCallback } from 'react';
import { ChangeCaseDetailsInput, useUpdateCaseMutation } from '../../../__generated__/graphql';

const useUpdateCaseDetails = () => {
  const [updateCaseMutation] = useUpdateCaseMutation();

  return useCallback(
    async (data: ChangeCaseDetailsInput) => {
      await updateCaseMutation({
        variables: {
          changeCaseDetailsInput: data,
        },
      });
    },
    [updateCaseMutation],
  );
};

export default useUpdateCaseDetails;
