import { useCallback, useMemo } from 'react';
import {
  useUpdatePageFavouriteMutation,
  UpdatePageFavouriteMutationVariables,
} from '../../../__generated__/graphql';

export default function useToggleFavouriteMutation() {
  const [mutation, state] = useUpdatePageFavouriteMutation();

  const wrappedMutation = useCallback(
    (params: UpdatePageFavouriteMutationVariables) =>
      mutation({
        variables: {
          data: params.data,
        },
        optimisticResponse: {
          updatePageFavouriteStatus: {
            __typename: 'PageObject',
            id: params.data.pageID,
            is_favourite: +params.data.isFavourite,
          },
        },
        update: (cache, { data }) => {
          const page = data?.updatePageFavouriteStatus;
          if (!page) {
            return;
          }

          cache.modify({
            id: cache.identify({
              __typename: 'PageObject',
              id: page.id,
            }),
            fields: {
              is_favourite: () => page.is_favourite,
            },
          });
        },
      }),

    [mutation],
  );

  return useMemo(() => [wrappedMutation, state], [wrappedMutation, state]);
}
