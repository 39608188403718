export default function FilterOutlined(props) {
  return (
    <svg
      width="18"
      height="18"
      {...props}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.8215 3.72239C1.19121 3.01796 0.87607 2.66574 0.864183 2.3664C0.853857 2.10636 0.965603 1.85643 1.16628 1.69074C1.39728 1.5 1.86991 1.5 2.81515 1.5H15.1843C16.1295 1.5 16.6021 1.5 16.8331 1.69074C17.0338 1.85643 17.1456 2.10636 17.1352 2.3664C17.1233 2.66574 16.8082 3.01796 16.1779 3.72239L11.4227 9.03703C11.2971 9.17745 11.2343 9.24766 11.1895 9.32756C11.1497 9.39843 11.1206 9.47473 11.1029 9.55403C11.083 9.64345 11.083 9.73766 11.083 9.92609V14.382C11.083 14.5449 11.083 14.6264 11.0568 14.6969C11.0335 14.7591 10.9958 14.8149 10.9466 14.8596C10.8909 14.9102 10.8153 14.9404 10.664 15.001L7.83063 16.1343C7.52435 16.2568 7.3712 16.3181 7.24827 16.2925C7.14076 16.2702 7.04642 16.2063 6.98575 16.1148C6.91637 16.0101 6.91637 15.8452 6.91637 15.5153V9.92609C6.91637 9.73766 6.91637 9.64345 6.89647 9.55403C6.87883 9.47473 6.84968 9.39843 6.80995 9.32756C6.76516 9.24766 6.70233 9.17745 6.57669 9.03703L1.8215 3.72239Z"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
