import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import IconButtonContainer from './IconButtonContainer';

export default function BackButton({ onClick, style }) {
  return (
    <IconButtonContainer
      onClick={onClick}
      tooltipText="Back"
      icon={<ArrowBackIosIcon style={{ color: 'rgba(0,0,0,0.75)', fontSize: '18px' }} />}
      size="small"
      customStyles={{
        ...style,
      }}
    />
  );
}
