import { format } from 'date-fns';
import { produce, produceWithPatches } from 'immer';
import get from 'lodash.get';
import merge from 'lodash.merge';
import { denormalize, normalize } from 'normalizr';

const createKeySpecificMetadataHostKey = (queryKey) => [
  '@@__key_specific_normalized_metadata_host__@@',
  ...queryKey,
];

const createNormalizedStorageKey = () => ['@@__normalized_storage__@@'];

const createMetadataStorageKey = () => ['@@__normalization_metadata__@@'];

const createEntityIdPath = (...parts) => parts.join('.');

const createSerializedQueryKey = (queryKey) => queryKey.join('/');

const schemaStorage = {};

const globalSettingsStorage = {
  enableLogs: false,
};

const loggerFactory = ({ context }) => {
  const formatMessage = (...message) => {
    const now = new Date();
    const timestamp = format(now, 'HH:mm:ss.SSS');

    return [`[${context} - ${timestamp}]`, ...message];
  };

  const canLog = () => globalSettingsStorage.enableLogs;

  const runLogFunctionIfCan =
    (fn) =>
    (...params) => {
      if (!canLog()) {
        return;
      }

      fn(...params);
    };

  return {
    log: runLogFunctionIfCan((...message) => {
      console.log(...formatMessage(...message));
    }),

    warn: runLogFunctionIfCan((...message) => {
      console.warn(...formatMessage(...message));
    }),

    childLogger: ({ childContext }) =>
      loggerFactory({
        context: `${context}/${childContext}`,
      }),
  };
};

const logger = loggerFactory({ context: 'rq-normalized' });

export const wrapQueryCacheToHandleDataNormalization = ({
  queryCache,
  queryClient,
  enableLogs = false,
}) => {
  globalSettingsStorage.enableLogs = enableLogs;

  const childLogger = logger.childLogger({ childContext: 'success-query-handler' });

  queryCache.subscribe((event) => {
    const { query, action } = event;

    if (action?.type === 'success' && query.options?.meta?.schema) {
      childLogger.log('Updating normalized storage', {
        queryKey: createSerializedQueryKey(query.queryKey),
      });

      const uniqueQueryId = query.queryKey;
      const normalized = normalize(action.data, query.options.meta.schema);

      const serializedQueryKey = createSerializedQueryKey(query.queryKey);
      const metadataStorageKey = createMetadataStorageKey();
      const keySpecificMetadataHostQueryKey = createKeySpecificMetadataHostKey(uniqueQueryId);
      const normalizedStorageKey = createNormalizedStorageKey();

      schemaStorage[serializedQueryKey] = query.options.meta.schema;

      queryClient.setQueryData(keySpecificMetadataHostQueryKey, () => ({
        result: normalized.result,
      }));

      const normalizedStorage = queryClient.getQueryData(normalizedStorageKey);
      queryClient.setQueryData(
        normalizedStorageKey,
        // The current merge strategy is deep merge though the holistic one is just replacing entities by their IDs
        // The deep merge is used because replacing by ID does not fit to the current model of working with data
        // Some endpoints might return missing or additional data for the same intities and replacing of the entities does not work well in this case
        // produce(normalizedStorage || {}, (value) => mergeNormalizedEntries(value, normalized.entities))
        produce(normalizedStorage || {}, (value) => merge(value, normalized.entities)),
      );

      const metadataStorage = queryClient.getQueryData(metadataStorageKey);
      queryClient.setQueryData(
        metadataStorageKey,
        produce(metadataStorage || {}, (value) => {
          if (!value?.entityIdsToQueryRelation) {
            value.entityIdsToQueryRelation = {};
          }

          Object.entries(normalized.entities).forEach(([entityName, entity]) => {
            const entityIds = Object.keys(entity);

            entityIds.forEach((entityId) => {
              const entityPath = createEntityIdPath(entityName, entityId);
              if (!value.entityIdsToQueryRelation[entityPath]) {
                value.entityIdsToQueryRelation[entityPath] = [];
              }

              const queryKeyIndex = value.entityIdsToQueryRelation[entityPath].findIndex(
                (storedQueryKey) =>
                  createSerializedQueryKey(storedQueryKey) ===
                  createSerializedQueryKey(uniqueQueryId),
              );

              if (queryKeyIndex < 0) {
                value.entityIdsToQueryRelation[entityPath].push(uniqueQueryId);
              }
            });
          });
        }),
      );
    } else {
      logger.log('unhandled query cache event:', event);
    }

    const queriesData = Object.fromEntries(queryClient.getQueriesData());
    childLogger.log('queries data: ', queriesData);
  });
};

const selectFromNormalizedStorage = ({ queryClient, queryKey, schema }) => {
  const childLogger = logger.childLogger({ childContext: 'select-from-normalized-storage' });

  const normalizedMetadataKey = createKeySpecificMetadataHostKey(queryKey);
  const normalizedStorageKey = createNormalizedStorageKey();

  const metadataHost = queryClient.getQueryData(normalizedMetadataKey);
  const storage = queryClient.getQueryData(normalizedStorageKey);

  if (!metadataHost) {
    childLogger.warn('No metadata found for query key', queryKey);
    return null;
  }

  return denormalize(metadataHost.result, schema, storage);
};

export const selectFromNormalizedStorageByQueryKey = ({ queryClient, queryKey }) =>
  selectFromNormalizedStorage({
    queryClient,
    queryKey,
    schema: schemaStorage[createSerializedQueryKey(queryKey)],
  });

export const createNormalizedSelectorWithFallback =
  ({ queryKey, queryClient, select }) =>
  (originalData) => {
    const dataFromStorage = selectFromNormalizedStorage({
      queryClient,
      queryKey,
      schema: schemaStorage[createSerializedQueryKey(queryKey)],
    });

    const result = dataFromStorage ?? originalData;

    return typeof select === 'function' ? select(result) : result;
  };

export const setObjectPath = (targetObject, path, value) => {
  const pathParts = Array.isArray(path) ? path : path.split('.');
  const lastPathPart = pathParts.pop();
  const parentObject = pathParts.reduce((acc, part) => {
    acc[part] = acc[part] ?? {};
    return acc[part];
  }, targetObject);

  parentObject[lastPathPart] = value;

  return parentObject;
};

export const updateNormalizedStorage = (queryClient, updateFn) => {
  const childLogger = logger.childLogger({ childContext: 'update-normalized-storage' });

  const normalizedStorageKey = createNormalizedStorageKey();
  const metadataStorageKey = createMetadataStorageKey();

  const existingStorage = queryClient.getQueryData(normalizedStorageKey);
  const metadataStorage = queryClient.getQueryData(metadataStorageKey);

  childLogger.log('existing storage before update', existingStorage);
  childLogger.log('existing global data: ', Object.fromEntries(queryClient.getQueriesData()));

  const [nextState, changes] = produceWithPatches(existingStorage || {}, updateFn);
  queryClient.setQueryData(normalizedStorageKey, nextState);

  /**
   * Paths point to the top-level entities IDs
   * @example
   * original path - [page, 1]
   * result - page.1
   *
   * original path - [page, 1, comments, 1]
   * result - page.1
   */
  const updatedEntitiesPaths = changes.map(({ path: [entityName, entityId] }) =>
    createEntityIdPath(entityName, entityId),
  );

  childLogger.log('Updated entities', updatedEntitiesPaths);

  const queriesToInvalidate = {};
  const changedDataPrevState = {};

  updatedEntitiesPaths.forEach((path) => {
    const relatedQueries = metadataStorage?.entityIdsToQueryRelation?.[path] ?? [];
    setObjectPath(changedDataPrevState, path, get(existingStorage, path));

    relatedQueries.forEach((queryKey) => {
      const serializedQueryKey = createSerializedQueryKey(queryKey);
      if (!queriesToInvalidate[serializedQueryKey]) {
        queriesToInvalidate[serializedQueryKey] = queryKey;
      }
    });
  });

  childLogger.log('Queries to invalidate: ', queriesToInvalidate);

  /**
   * Queries are deduplicated what means that each query is updated only once!
   */
  Object.values(queriesToInvalidate).forEach((queryKey) => {
    queryClient.cancelQueries(queryKey);

    queryClient.setQueryData(queryKey, () =>
      selectFromNormalizedStorage({
        queryClient,
        queryKey,
        schema: schemaStorage[createSerializedQueryKey(queryKey)],
      }),
    );
  });

  childLogger.log('Changed data prev state:  ', changedDataPrevState);

  /**
   * @property {object} changedDataPrevState - contains a snapshot of only changed nodes and is used to rollback the changes to the storage
   */
  return {
    changedDataPrevState,
  };
};

export const updateNormalizedStoreByRootEntity = ({ rootEntity, schema, queryClient }) => {
  const childLogger = logger.childLogger({
    childContext: 'update-notmalized-store-by-root-entity',
  });

  const normalized = normalize(rootEntity, schema);

  childLogger.log('Normalized root entity before update', normalized);

  return updateNormalizedStorage(queryClient, (storage) => {
    mergeNormalizedEntries(storage, normalized.entities);
  });
};

const mergeNormalizedEntries = (target, source) => {
  Object.entries(source).forEach(([entityType, entitiesMap]) => {
    Object.entries(entitiesMap).forEach(([entityID, entity]) => {
      setObjectPath(target, [entityType, entityID], entity);
    });
  });
};

export const rollbackWithPrevState = (queryClient, prevStateHost) =>
  updateNormalizedStorage(queryClient, (draft) => merge(draft, prevStateHost.changedDataPrevState));
