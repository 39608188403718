import { useState, useEffect } from 'react';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Tooltip, FilledInput, FormControl, Box } from '@mui/material';
import PropTypes from 'prop-types';
import NavigationButton from './NavigationButton';

export default function PageNavigator({
  onChangePage,
  currentPage = 1,
  numOfPages = 1,
  nextPageButtonDisabled = null,
  previousPageButtonDisabled = null,
  navigateType,
}) {
  const [inputPage, setInputPage] = useState(currentPage);
  useEffect(() => {
    setInputPage(currentPage);
  }, [currentPage]);

  const changePage = (p, isCustom) => {
    if (p > 0 && p <= numOfPages) {
      onChangePage(Number(p), isCustom);
    }
    if (p === 0 || p === '0' || p > numOfPages) {
      setInputPage(currentPage);
    }
  };

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      changePage(Number(inputPage), true);
    }
  };

  const onBlur = () => {
    changePage(Number(inputPage), true);
  };

  const previousPageDisabled = previousPageButtonDisabled || currentPage <= 1;
  const nextPageDisabled = nextPageButtonDisabled || currentPage === numOfPages;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'selectedGrey.main',
      }}
    >
      <Tooltip title={`Previous ${navigateType}`} placement="top">
        <span>
          <NavigationButton
            id="navbar-previous-page-button"
            onClick={() => onChangePage(currentPage - 1)}
            icon={<KeyboardArrowLeft sx={{ fontSize: '1rem' }} />}
            disabled={previousPageDisabled}
          />
        </span>
      </Tooltip>
      <Box
        sx={{
          fontWeight: '700',
          fontSize: '0.75rem',
          display: 'flex',
          mx: '0.5rem',
          minWidth: 'fit-content',
        }}
      >
        <Box
          sx={{
            fontSize: '0.75rem',
            fontWeight: 500,
            color: '#344054',
            margin: 'auto',
            marginRight: '0.2rem',
          }}
        >
          {navigateType}
        </Box>
        <FormControl>
          <FilledInput
            id="page_number"
            disableUnderline={true}
            sx={{
              width: '52px',
              margin: 'auto',
              backgroundColor: 'white',
              padding: '0 5px',
              fontWeight: 600,
              marginRight: '4px',
              borderRadius: 2,
              border: '1px solid #D0D5DD',
              '&:hover': {
                backgroundColor: 'white',
              },
            }}
            value={inputPage}
            onBlur={onBlur}
            inputProps={{
              style: {
                padding: '0',
                fontSize: '0.75rem',
                lineHeight: '8px',
                textAlign: 'right',
                background: 'none',
              },
              pattern: '[0-9]*',
              type: 'text',
            }}
            onChange={(e) =>
              setInputPage((prevPageNumber) =>
                e.target.validity.valid ? e.target.value : prevPageNumber,
              )
            }
            onKeyPress={onKeyPress}
          />
        </FormControl>
        <Box
          sx={{
            fontSize: '0.75rem',
            fontWeight: 500,
            color: '#344054',
            margin: 'auto',
          }}
        >
          of {numOfPages}
        </Box>
      </Box>
      <Tooltip title={`Next ${navigateType}`} placement="top">
        <span>
          <NavigationButton
            id="navbar-next-page-button"
            onClick={() => onChangePage(currentPage + 1)}
            icon={<KeyboardArrowRight sx={{ fontSize: '1rem' }} />}
            disabled={nextPageDisabled}
          />
        </span>
      </Tooltip>
    </Box>
  );
}

PageNavigator.defaultProps = {
  onChangePage: null,
  currentPage: 1,
  numOfPages: 1,
};

PageNavigator.propTypes = {
  onChangePage: PropTypes.func,
  currentPage: PropTypes.number,
  numOfPages: PropTypes.number,
};
