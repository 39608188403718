import { AxiosResponse } from 'axios';
import { authenticatedRequest } from '../../../api';

function updateMonetaryTotal(entryID: bigint, monetary_total: string): Promise<AxiosResponse> {
  const res = authenticatedRequest({
    method: 'POST',
    url: `/timeline-entry/updateMonetaryTotal/${entryID}`,
    data: { monetary_total: monetary_total },
  });
  return res;
}

export function useUpdateMonetaryTotal(): (
  entryID: bigint,
  monetary_total: string,
) => Promise<void> {
  return async (entryID, monetary_total) => {
    await updateMonetaryTotal(entryID, monetary_total);
  };
}
