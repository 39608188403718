import { useState, useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Box, Button, Typography, Stack } from '@mui/material';
import HideSourceIcon from '@mui/icons-material/HideSource';
import usePeopleEntities from '../../containers/Timeline/gql/usePeopleEntities';
import Loading from '../common/Loading';
import { apiBaseUrl } from '../../config/apiConfig';
import useTimelineStore from '../../containers/Timeline/useTimelineStore';

interface Person {
  id: string;
  value: string;
  ml_suggested_entity: string;
  origin: string;
  hidden: boolean;
}

type PropTypes = {
  caseID: string;
};

export default function TablePeople(props: PropTypes) {
  const { caseID } = props;
  const { hiddenEntities, setHiddenEntities } = useTimelineStore(
    (state) => ({
      hiddenEntities: state.hiddenEntities,
      setHiddenEntities: state.setHiddenEntities,
    }),
    shallow,
  );
  const [peopleData, setPeopleData] = useState<Person[]>([]);
  const { data: peopleDataFromHook, loading: loadingPeopleData } = usePeopleEntities(caseID ?? '');
  const [loading, setLoading] = useState(true);
  const [hiddenPeople, setHiddenPeople] = useState<string[]>([]);

  useEffect(() => {
    if (peopleDataFromHook && peopleDataFromHook.allPeople) {
      const updatedPeopleData = peopleDataFromHook.allPeople.map((person: Person) => {
        if (!Array.isArray(hiddenPeople) || !person.value) {
          return person;
        }
        const isHiddenFromBackend = person.hidden;
        const isHiddenFromLocal = hiddenPeople.includes(person.value);
        return {
          ...person,
          hidden: isHiddenFromBackend || isHiddenFromLocal,
        };
      });
      setPeopleData(updatedPeopleData);
      setLoading(false);
    } else {
      setPeopleData([]);
      setLoading(false);
    }
  }, [peopleDataFromHook, hiddenPeople]);

  useEffect(() => {
    const fetchHiddenPeople = async () => {
      try {
        const response = await axios.get(
          `${apiBaseUrl}/api/v1/cases/${caseID}/entities/hidden-people`,
          {
            headers: { Authorization: `Bearer ${axios.defaults.headers.common.Authorization}` },
          },
        );

        setHiddenPeople(response.data.hiddenEntities);
      } catch (error) {
        console.error('Error fetching hidden people:', error);
      }
    };

    fetchHiddenPeople();
  }, [caseID]);

  if (loadingPeopleData || loading) {
    return <Loading text="Loading People..." />;
  }

  const handleToggleHideEntity = async (entityType: string, value: string, entityValue: string) => {
    try {
      if (entityType === 'person') {
        const updatedData = peopleData.map((person) => {
          if (person.value === entityValue) {
            return { ...person, hidden: true };
          }
          return person;
        });

        setPeopleData(updatedData);

        const isEntityHidden = hiddenEntities.includes(entityValue);

        if (isEntityHidden) {
          toast.info('Entity is already hidden', {
            toastId: 'entity-already-hidden',
          });
          return;
        }

        const updatedHiddenEntities = [...hiddenEntities, entityValue];
        setHiddenEntities(updatedHiddenEntities);

        const response = await axios.put(
          `${apiBaseUrl}/api/v1/cases/${caseID}/entities/updatePeopleStatus`,
          {
            caseID,
            value: entityValue,
            hidden: true,
          },
          { headers: { Authorization: `Bearer ${axios.defaults.headers.common.Authorization}` } },
        );

        if (response.data && response.data.hidden) {
          const updatedHiddenEntity = entityValue;
          const updatedHiddenEntities = [...hiddenEntities, updatedHiddenEntity];
          setHiddenEntities(updatedHiddenEntities);
        }
        toast.success('Entity visibility toggled successfully', {
          toastId: 'entity-toggle-success',
        });
      }
    } catch (error) {
      console.error('Error toggling entity visibility:', error);
      toast.error('Failed to toggle entity visibility...', {
        toastId: 'entity-toggle-fail',
      });
    }
  };

  const handleToggleUnhideEntity = async (
    entityType: string,
    value: string,
    entityValue: string,
  ) => {
    try {
      if (entityType === 'person') {
        const updatedData = peopleData.map((person) => {
          if (person.value === entityValue) {
            return { ...person, hidden: false };
          }
          return person;
        });

        setPeopleData(updatedData);

        const updatedHiddenEntities = hiddenEntities.filter((id) => id !== entityValue);
        setHiddenEntities(updatedHiddenEntities);

        const response = await axios.put(
          `${apiBaseUrl}/api/v1/cases/${caseID}/entities/updatePeopleStatus`,
          {
            caseID,
            value: entityValue,
            hidden: false,
          },
          { headers: { Authorization: `Bearer ${axios.defaults.headers.common.Authorization}` } },
        );

        if (response.data && !response.data.hidden) {
          const updatedHiddenEntities = hiddenEntities.filter((id) => id !== entityValue);
          setHiddenEntities(updatedHiddenEntities);
        }

        toast.success('Entity visibility toggled successfully', {
          toastId: 'entity-toggle-success',
        });
      }
    } catch (error) {
      console.error('Error toggling entity visibility:', error);
      toast.error('Failed to toggle entity visibility...', {
        toastId: 'entity-toggle-fail',
      });
    }
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={4}
        sx={{
          paddingBottom: '1.2rem',
          width: '100%',
        }}
      >
        <Typography fontSize="1rem" fontWeight={600} sx={{ display: 'inline' }}>
          Authors
        </Typography>
      </Stack>
      <TablePeopleHeader columnsPeople={['Author Name', 'Created By', 'Action']} />
      <Box
        sx={{
          maxHeight: { xs: '27rem', md: '27rem', lg: '32rem', xl: '35rem' },
          width: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          borderRight: '0.5px solid',
          borderLeft: '0.5px solid',
          borderColor: 'primary.border',
          borderRadius: '1px',
          backgroundColor: 'themeDuplicates.contrastText',
        }}
      >
        {loadingPeopleData ? (
          <Loading text="Loading Entities" />
        ) : (
          <Box sx={{ marginBottom: '2rem' }}>
            {peopleData.length > 0 &&
              [...new Map(peopleData.map((person) => [person.value, person])).values()]
                .sort((a, b) => {
                  if (a.hidden === b.hidden) {
                    return a.value.localeCompare(b.value);
                  }
                  return a.hidden ? 1 : -1;
                })
                .map((person) => (
                  <TablePeopleRow
                    key={person.id}
                    person={person}
                    handleToggleHideEntity={handleToggleHideEntity}
                    handleToggleUnhideEntity={handleToggleUnhideEntity}
                    loadingPeopleData={loadingPeopleData}
                    peopleData={peopleData}
                  />
                ))}
          </Box>
        )}
      </Box>
    </>
  );
}

type TableRowPropTypes = {
  columnsPeople: string[];
};

function TablePeopleHeader({ columnsPeople }: TableRowPropTypes) {
  return (
    <Box
      id="table-header"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'primary.column',
        width: '100%',
        border: '0.5px solid ',
        borderColor: 'primary.border',
      }}
    >
      {columnsPeople.map((column, index) => (
        <Box
          key={index}
          sx={{
            fontSize: '0.8rem',
            fontWeight: 700,
            padding: '1rem',
            opacity: '80%',
            cursor: 'pointer',
            width: `${100 / columnsPeople.length}%`,
          }}
        >
          {column}
        </Box>
      ))}
    </Box>
  );
}

function TablePeopleRow({
  person,
  handleToggleHideEntity,
  handleToggleUnhideEntity,
  loadingPeopleData,
  peopleData,
}: {
  person: Person;
  handleToggleHideEntity: Function;
  handleToggleUnhideEntity: Function;
  loadingPeopleData: boolean;
  peopleData: Person[];
}) {
  const isEntityHidden = peopleData.find((p) => p.value === person.value)?.hidden;

  return (
    <Box
      sx={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        flexWrap: 'wrap',
        alignItems: 'center',
        borderBottom: '0.5px solid',
        borderColor: 'primary.border',
      }}
    >
      <Box
        sx={{
          padding: '1rem',
          flex: '1',
          fontWeight: 600,
          opacity: isEntityHidden ? '40%' : '80%',
        }}
      >
        {person.value}
      </Box>
      <Box
        sx={{
          padding: '1rem',
          flex: '1',
          fontWeight: 600,
          opacity: isEntityHidden ? '40%' : '80%',
        }}
      >
        {person.origin}
      </Box>
      <Box
        sx={{
          padding: '1rem',
          flex: '1',
          fontWeight: 600,
          opacity: isEntityHidden ? '40%' : '80%',
        }}
      >
        <Button
          onClick={() => handleToggleHideEntity('person', person.id, person.value)}
          disabled={loadingPeopleData}
        >
          <HideSourceIcon width={19} height={19} />
        </Button>
        {isEntityHidden && (
          <Button
            onClick={() => handleToggleUnhideEntity('person', person.id, person.value)}
            disabled={loadingPeopleData}
            sx={{ fontSize: '0.9rem', opacity: '100%' }}
          >
            Show
          </Button>
        )}
      </Box>
    </Box>
  );
}
