import React from 'react';
import styled from '@mui/styles/styled';
import { Button as MuiButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * Primary UI component for user interaction
 */

const StyledButton = styled(MuiButton, {
  name: 'Button',
  slot: 'Root',
})(({ selected }) => ({
  borderRadius: 17,
  height: '4rem',
  width: '4rem',
  cursor: 'pointer',
  marginTop: 2,
  flexDirection: 'column',
  boxShadow: 'none',
  background: selected ? 'radial-gradient(circle at center, #223b7a, #505b7a)' : 'transparent',
  '&:hover': {
    boxShadow: 'none',
    background: !selected && 'radial-gradient(circle at center, #223b7a, #505b7a)',
  },
}));

const SideBarButton = React.forwardRef(
  ({ selected, size, tooltiptext, ariaL, Icon, ...props }, ref) => (
    <Tooltip title={tooltiptext} placement="right-end">
      <span>
        <StyledButton
          ref={ref}
          variant="contained"
          aria-label={ariaL}
          selected={selected}
          {...props}
        >
          <Icon sx={{ width: '1.5em', height: '1.5em' }} color={selected ? '#ffffff' : '#E5EAF8'} />
        </StyledButton>
      </span>
    </Tooltip>
  ),
);

SideBarButton.displayName = 'SideBarButton';

SideBarButton.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  'aria-label': PropTypes.string,
  tooltiptext: PropTypes.string,
  Icon: PropTypes.elementType.isRequired,
  selected: PropTypes.bool,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
};

SideBarButton.defaultProps = {
  size: 'medium',
  'aria-label': 'square icon button',
  tooltiptext: '',
  selected: false,
  sx: undefined,
};

export default SideBarButton;
