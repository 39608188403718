import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import './anchored-dropdown-menu.css';

type MenuPosition = {
  top: number;
  left: number;
};

type Props = {
  anchorComponentRef: React.RefObject<HTMLElement>;
  options: {
    label: string;
    onClick: () => void;
    disabled: boolean;
  }[];
};

export default function AnchoredDropdownMenu({ anchorComponentRef, options }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState<MenuPosition | null>(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const anchor = anchorComponentRef.current;
    const toggleDropdown = () => {
      if (!isOpen) {
        const rect = anchor.getBoundingClientRect();
        setMenuPosition({
          top: rect.top + rect.height + window.scrollY,
          left: rect.left + window.scrollX,
        });
      }
      setIsOpen((prev) => !prev);
    };

    if (anchor) {
      anchor.addEventListener('click', toggleDropdown);
    }

    return () => {
      if (anchor) {
        anchor.removeEventListener('click', toggleDropdown);
      }
    };
  }, [isOpen, anchorComponentRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        anchorComponentRef.current &&
        !anchorComponentRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleScroll = () => {
      if (!anchorComponentRef.current) {
        return;
      }
      const rect = anchorComponentRef.current.getBoundingClientRect();
      setMenuPosition({
        top: rect.top + rect.height + window.scrollY,
        left: rect.left + window.scrollX,
      });
    };

    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [anchorComponentRef, isOpen]);

  const dropdownMenu = menuPosition && (
    <div
      className="sm-dropdown-menu-container"
      style={{
        transform: `translate3d(${menuPosition.left}px, ${menuPosition.top}px, 0)`,
      }}
      role="menu"
      ref={dropdownRef}
    >
      {options.map((option) => (
        <div
          key={option.label}
          onClick={(e) => {
            e.stopPropagation();
            if (!option.disabled) {
              option.onClick();
              setIsOpen(false);
            }
          }}
          className={option.disabled ? `sm-dropdown-menu-item-disabled` : `sm-dropdown-menu-item`}
          role="menuitem"
          tabIndex={0}
        >
          {option.label}
        </div>
      ))}
    </div>
  );

  if (isOpen && dropdownMenu) {
    return <>{ReactDOM.createPortal(dropdownMenu, document.body)}</>;
  }

  return null;
}
