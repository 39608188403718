import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { NotesListOrderEnum } from './types/noteTypes';

const useNotesStore = create(
  subscribeWithSelector((set) => ({
    noteSelected: '',
    setNoteSelected: (note) => set(() => ({ noteSelected: note })),

    notesViewOpen: false,
    toggleNotesView: () => set((state) => ({ notesViewOpen: !state.notesViewOpen })),

    tagsBeingFiltered: [],
    setTagsBeingFiltered: (tags) => set(() => ({ tagsBeingFiltered: tags })),

    notesOrder: NotesListOrderEnum.DateAsc,
    setNotesOrder: (order) => set(() => ({ notesOrder: order })),

    afterDate: '',
    setAfterDate: (date) => set(() => ({ afterDate: date })),

    beforeDate: '',
    setBeforeDate: (date) => set(() => ({ beforeDate: date })),

    showOnlyMyNotes: false,
    setShowOnlyMyNotes: (showOnlyMyNotes) => set(() => ({ showOnlyMyNotes: showOnlyMyNotes })),

    isDateRangeValid: false,
    setIsDateRangeValid: (isDateRangeValid) => set(() => ({ isDateRangeValid: isDateRangeValid })),

    singleNoteToInsert: '',
    setSingleNoteToInsert: (note) => set(() => ({ singleNoteToInsert: note })),

    notesToInsert: null,
    setNotesToInsert: (notes) => set(() => ({ notesToInsert: notes })),

    notesInsertionOption: 'Table',
    setNotesInsertionOption: (option) => set(() => ({ notesInsertionOption: option })),

    notesInsertLoading: false,
    setNotesInsertLoading: (notesInsertLoading) => {
      set(() => ({ notesInsertLoading }));
    },

    currentlyInsertedNotes: 0,
    incrementCurrentlyInsertedNotes: () =>
      set((state) => ({
        currentlyInsertedNotes: state.currentlyInsertedNotes + 1,
      })),
    resetCurrentlyInsertedNotes: () => set(() => ({ currentlyInsertedNotes: 0 })),

    totalNotesToInsert: 0,
    setTotalNotesToInsert: (totalNotesToInsert) => {
      set(() => ({ totalNotesToInsert: totalNotesToInsert }));
    },

    noteBeingEdited: null,
    setNoteBeingEdited: (note) => set(() => ({ noteBeingEdited: note })),

    physician: '',
    setPhysician: (physician) => set(() => ({ physician: physician })),

    clearFilters: () =>
      set(() => ({
        afterDate: '',
        beforeDate: '',
        tagsBeingFiltered: [],
        showOnlyMyNotes: false,
        physician: '',
      })),
  })),
);

export default useNotesStore;
