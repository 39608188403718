import { useCallback } from 'react';
import {
  CustomTag,
  CustomTagFragmentFragmentDoc,
  DeleteCustomTagMutationVariables,
  NoteFragmentFragmentDoc,
  NoteTagObject,
  NotesDocument,
  useDeleteCustomTagMutation,
} from '../../../__generated__/graphql';

const useDeleteCustomTag = () => {
  const [deleteCustomTagMutation, mutationStateInfo] = useDeleteCustomTagMutation();

  const deleteCustomTagCallback = useCallback(
    async ({ variables }: { variables: DeleteCustomTagMutationVariables }) => {
      try {
        await deleteCustomTagMutation({
          variables,
          update: (cache, { data }) => {
            try {
              cache.modify({
                fields: {
                  custom_tags(existingTags = []) {
                    return existingTags.filter((tagRef: { __ref: string }) => {
                      const foundTag = cache.readFragment({
                        id: tagRef.__ref,
                        fragment: CustomTagFragmentFragmentDoc,
                      }) as CustomTag | null;

                      return foundTag !== null && foundTag?.tagID !== data?.deleteCustomTag;
                    });
                  },
                  notes(existingNotes = []) {
                    existingNotes.forEach((noteRef: { __ref: string }) => {
                      const foundNote = cache.readFragment({
                        id: noteRef.__ref,
                        fragment: NoteFragmentFragmentDoc,
                        fragmentName: 'NoteFragment',
                      }) as NotesDocument | null;
                      if (foundNote == null) {
                        return;
                      }
                      const updatedNoteTags = foundNote.note_tags?.filter(
                        (noteTag: NoteTagObject) => noteTag.tagID !== data?.deleteCustomTag,
                      );
                      if (
                        updatedNoteTags != null &&
                        updatedNoteTags.length !== foundNote.note_tags?.length
                      ) {
                        cache.updateFragment(
                          {
                            id: noteRef.__ref,
                            fragment: NoteFragmentFragmentDoc,
                            fragmentName: 'NoteFragment',
                          },
                          (data) => ({
                            ...data,
                            note_tags: updatedNoteTags,
                          }),
                        );
                      }
                    });
                    return existingNotes;
                  },
                },
              });
            } catch (err) {
              console.log('error in cache update', err);
            }
          },
        });
      } catch (err) {
        throw new Error();
      }
    },
    [deleteCustomTagMutation],
  );

  return [deleteCustomTagCallback, mutationStateInfo];
};

export default useDeleteCustomTag;
