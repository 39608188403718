import { useMemo } from 'react';
import { ViewedIndicator } from '../../../components/ViewedIndicator';
import { PageType, PageViewedType } from '../types/pageTypes';

export default function PageViewedControl({ page, handleToggleViewed }: Props) {
  const { viewedAt } = page;

  const isViewed = useMemo(
    () => (viewedAt ? Boolean(viewedAt) : Boolean(page?.pages_viewed?.[0]?.viewed_at)),
    [page],
  );

  return (
    <ViewedIndicator
      isViewed={isViewed}
      toggleViewed={() =>
        handleToggleViewed({
          data: {
            pageID: page.id,
            viewed: isViewed ? 0 : 1,
          },
        })
      }
    />
  );
}

export type Props = {
  page: PageType & { pages_viewed?: PageViewedType[] };
  handleToggleViewed: (params: { data: { pageID: number; viewed: number } }) => void;
};
