import Loading from '../../../components/common/Loading';

export default function CaseLoading() {
  return (
    <div
      style={{ margin: 'auto', textAlign: 'center', marginTop: '2rem' }}
      id="case-loading-container"
    >
      <Loading />
      <div>Loading Case...</div>
    </div>
  );
}
