/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { IconButton, Box, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import ProcessingFiles from '../icons/ProcessingFiles';

/**
 * Announcement as an Alert
 */

interface AlertProps {
  open?: boolean;
  onClose?: () => void;
  announcementTitle?: string;
  announcementBody?: string;
}

export default function Alert({
  announcementTitle,
  open,
  onClose,
  announcementBody,
  ...props
}: AlertProps) {
  return (
    <Box
      sx={{
        display: !open ? 'none' : 'flex',
        border: '1px solid',
        borderColor: 'themeOrange.light',
        backgroundColor: 'themeOrange.lighter',
        width: { sm: '45rem', md: '55rem', lg: '72rem', xl: '77rem' },
        borderRadius: 3,
        marginTop: '1.5rem',
        flexDirection: 'row',
      }}
      {...props}
    >
      <Box
        sx={{
          width: '5%',
          height: '100%',
          overflow: 'hidden',
          display: 'inline',
          borderRadius: 3,
          marginLeft: '0.5rem',
          marginTop: '0.5rem',
        }}
      >
        <ProcessingFiles />
      </Box>

      <Box
        sx={{
          textAlign: 'left',
          overflow: 'hidden',
          paddingTop: '1rem',
          width: '100%',
          paddingBottom: '1rem',
          position: 'relative',
          display: 'inline',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => onClose && onClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: '0.5rem',
            color: 'themeOrange.light',
          }}
        >
          <Close sx={{ fontSize: '1.6rem' }} />
        </IconButton>

        <Box
          sx={{
            display: 'flex',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            marginTop: '0.3rem',
            cursor: 'pointer',
            color: 'themeOrange.light',
          }}
        >
          <Box sx={{ marginLeft: '0.8rem' }}>
            <Typography
              sx={{
                justfySelf: 'left',
                fontWeight: 700,
                fontSize: '0.9rem',
              }}
            >
              {announcementTitle}
            </Typography>
            <Typography
              sx={{
                mt: 0.5,
                fontSize: '0.8rem',
                display: {
                  xs: 'block',
                  sm: 'block',
                  md: 'block',
                  lg: 'inline',
                  xl: 'inline',
                },
                maxWidth: '78%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                fontWeight: 400,
              }}
            >
              {announcementBody}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

Alert.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  announcementTitle: PropTypes.string,
  announcementBody: PropTypes.string,
};

Alert.defaultProps = {
  open: true,
  onClose: undefined,
  announcementTitle: '',
  announcementBody: '',
};
