import { Chip, Tooltip } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import tagColors from './tagColors';
import { useUser } from '../../library/contexts/AuthContext';

/**
 * Tag display that notes use for sorting
 * 
 * OnDelete - function with tag parameter. displays a delete button.
 }}
 */
function Tag(props) {
  const { user } = useUser();
  const userID = user?.username;

  const { tag, onDelete, deletePersistant, onClick, marginAuto, space, icon, style } = props;
  const [hoverDelete, setHoverDelete] = useState(false);
  const handleMouseEnter = () => setHoverDelete(true);

  const handleMouseLeave = () => setHoverDelete(false);

  return (
    <Tooltip
      title={userID === tag.userID ? tag.title : 'Only tag creator can remove this tag'}
      placement="top"
    >
      <Chip
        key={tag.tagID}
        color="primary"
        size="small"
        variant="filled"
        label={tag.title}
        sx={{
          backgroundColor: tagColors[tag.color] || tag.color,
          color: 'white',
          borderColor: tag.color,
          margin: marginAuto ? 'auto' : '',
          marginRight: space ? '4px' : 0,
          marginTop: 1.5,
          fontSize: '0.8rem',
          padding: '0px',
          ...style,
        }}
        onDelete={
          (hoverDelete || deletePersistant) && onDelete && userID === tag.userID
            ? () => onDelete(tag)
            : null
        }
        deleteIcon={icon}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClick ? () => onClick(tag) : null}
      />
    </Tooltip>
  );
}

Tag.propTypes = {
  tag: PropTypes.shape({
    tagID: PropTypes.string,
    title: PropTypes.string,
    color: PropTypes.string,
  }),
  className: PropTypes.string,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  marginAuto: PropTypes.bool,
  space: PropTypes.bool,
  icon: PropTypes.node,
};

export default Tag;
