import { ButtonBase, ButtonProps } from '@mui/material';
import React from 'react';

type Props = ButtonProps;

function ErrorScreenButtonBase({ children, ...buttonProps }: React.PropsWithChildren<Props>) {
  return (
    <ButtonBase
      {...buttonProps}
      sx={{
        paddingX: 2.25,
        paddingY: 1.25,
        minHeight: 0,
        fontFamily: 'inherit',
        // pxToRem produces not accurate result in this case
        lineHeight: '24px',
        fontSize: '16px',
        fontWeight: 600,
        color: 'buttonError.main',
        borderRadius: 1,
        ...buttonProps.sx,
      }}
    >
      {children}
    </ButtonBase>
  );
}

export default ErrorScreenButtonBase;
