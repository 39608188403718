import {
  Box,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  Typography,
  Button,
} from '@mui/material';
import { useState } from 'react';
import DialogModal from '../../components/common/DialogModal';

export default function AdjustHeadersModal(props) {
  const { headers, notes, onSubmit, onClose, defaultColumns } = props;
  const [updatedHeaders, setUpdatedHeaders] = useState(headers);

  const submitHeaders = () => {
    onSubmit(notes, updatedHeaders);
    onClose();
  };

  const onUpdate = (value, index) => {
    const newHeaders = [...updatedHeaders];
    newHeaders[index] = value;
    setUpdatedHeaders(newHeaders);
  };

  const submitDisabled = !updatedHeaders.every(
    (header) => header === 'None' || defaultColumns.includes(header),
  );

  return (
    <DialogModal
      size="md"
      fullWidth
      open
      onClose={onClose}
      header={
        <>
          <Typography>Select Data Fields</Typography>
          <FormHelperText>One or more columns could not be matched</FormHelperText>
        </>
      }
      content={
        <>
          <Box display="flex" justifyContent="space-around">
            <Box sx={{ width: '250px' }}>
              <Typography color="primary.light" variant="caption">
                Column Name
              </Typography>
            </Box>
            <Box width="200px">
              <Typography color="primary.light" variant="caption">
                Associated Data Field
              </Typography>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column">
            {headers.map((header, index) => (
              <FormControl
                fullWidth
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  mt: 1,
                  justifyContent: 'space-around',
                }}
              >
                <Box width="250px">
                  <Typography
                    variant="subtitle2"
                    color={
                      !defaultColumns.includes(updatedHeaders[index]) &&
                      updatedHeaders[index] !== 'None'
                        ? 'error'
                        : 'primary'
                    }
                    sx={{
                      lineHeight: '40.8px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      '&:hover': {
                        height: 'auto',
                        overflow: 'visible',
                        whiteSpace: 'normal',
                      },
                    }}
                  >
                    {header}
                  </Typography>
                </Box>
                <Box>
                  <Select
                    size="small"
                    sx={{ width: '200px' }}
                    value={updatedHeaders[index]}
                    onChange={(e) => onUpdate(e.target.value, index)}
                  >
                    {defaultColumns.map((column) => (
                      <MenuItem value={column}>{column}</MenuItem>
                    ))}
                    <MenuItem value="None">No Value</MenuItem>
                  </Select>
                </Box>
              </FormControl>
            ))}
          </Box>
        </>
      }
      actions={
        <Button
          variant="contained"
          color="primary"
          disabled={submitDisabled}
          onClick={submitHeaders}
        >
          Insert Notes
        </Button>
      }
    />
  );
}
