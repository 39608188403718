import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getDocumentSimilarities,
  getDocumentComparison,
  markDocumentAsDuplicateOf,
  markDocumentAsNotDuplicateOf,
  createAllDocumentSimilaritiesForCase,
} from '../../api';
import { useAsync } from '../../hooks/useAsync';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import useCaseFiles from '../Files/useCaseFiles';

const DEFAULT_ZOOM = 0.8;

function useDuplicatesByDocumentState() {
  const { caseID, firstDocID, secondDocID } = useParams();
  const firstDocumentID = firstDocID && BigInt(firstDocID);
  const secondDocumentID = secondDocID && BigInt(secondDocID);
  const navigate = useNavigate();
  const similarityThreshold = 0.9;
  const isFileProcessor = useIsFileProcessor();
  const { data: caseFiles } = useCaseFiles(caseID ?? '');
  const [hideResolved, setHideResolved] = useState(isFileProcessor);
  const [hideUnresolved, setHideUnresolved] = useState(!isFileProcessor);
  const [page, setPage] = useState<number>(0);
  const pageSize = 20;
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);

  const [documentSimilaritiesResult, refreshDocumentSimilarities] = useAsync(
    () =>
      fetchDocumentSimilarities(
        caseID,
        similarityThreshold,
        hideResolved,
        hideUnresolved,
        page,
        pageSize,
      ),
    [caseID, similarityThreshold, hideResolved, hideUnresolved, page, pageSize],
  );

  const [documentCompare, refreshDocumentCompare] = useAsync(
    () => fetchDocumentComparison(caseID, firstDocumentID, secondDocumentID),
    [caseID, firstDocumentID, secondDocumentID],
  );

  const documentSimilarity =
    documentCompare.status === 'resolved' ? documentCompare.data.data : null;

  const documentSimilaritiesList = useMemo(() => {
    const list =
      documentSimilaritiesResult.status === 'resolved'
        ? documentSimilaritiesResult.data.data?.documentSimilarities ?? []
        : [];

    if (!isFileProcessor) {
      return list.filter((document) => document.status !== 'NotDuplicate');
    }

    return list;
  }, [documentSimilaritiesResult, isFileProcessor]);

  const documentSimilaritiesListLoading = documentSimilaritiesResult?.status === 'pending';

  useEffect(() => {
    if ((!firstDocumentID || !secondDocumentID) && documentSimilaritiesList.length > 0) {
      navigate(
        `${documentSimilaritiesList[0].duplicate_of}..${documentSimilaritiesList[0].document_id}`,
      );
      setZoom(DEFAULT_ZOOM);
    } else if (
      documentSimilaritiesList.length > 0 &&
      documentSimilaritiesList.findIndex(
        (sim) =>
          (sim.duplicate_of === firstDocID && sim.document_id === secondDocID) ||
          (sim.document_id === firstDocID && sim.duplicate_of === secondDocID),
      ) < 0
    ) {
      navigate(
        `${documentSimilaritiesList[0].duplicate_of}..${documentSimilaritiesList[0].document_id}`,
      );
      setZoom(DEFAULT_ZOOM);
    }
  }, [documentSimilaritiesList]);

  const count =
    documentSimilaritiesResult.status === 'resolved'
      ? isFileProcessor
        ? documentSimilaritiesResult.data.data?.count ?? null
        : documentSimilaritiesList?.length
      : null;

  const markAsDuplicate = async () => {
    const response = await markDocumentAsDuplicateOf(
      caseID,
      documentSimilarity?.document.id,
      documentSimilarity?.compare_with.id,
    );
    if (response.status >= 400) {
      toast.error('Failed to mark as duplicate. Please refresh and try again.');
      return { status: 'rejected' };
    }
    navigateNextPage();
    refreshDocumentCompare();
    refreshDocumentSimilarities();
    return { status: 'resolved', data: response.data };
  };

  const setNewOriginal = async () => {
    const response = await markDocumentAsDuplicateOf(
      caseID,
      documentSimilarity?.compare_with.id, // swapped these to change the original
      documentSimilarity?.document.id,
    );
    if (response.status >= 400) {
      toast.error('Failed to change original document. Please refresh and try again.');
      return { status: 'rejected' };
    }
    navigateNextPage();
    refreshDocumentCompare();
    refreshDocumentSimilarities();
    return { status: 'resolved', data: response.data };
  };

  const markAsNotDuplicate = async () => {
    const response = await markDocumentAsNotDuplicateOf(
      caseID,
      documentSimilarity?.document.id,
      documentSimilarity?.compare_with.id,
    );
    if (response.status >= 400) {
      toast.error('Failed to mark as not duplicate. Please refresh and try again.');
      return { status: 'rejected' };
    }
    navigateNextPage();
    refreshDocumentCompare();
    refreshDocumentSimilarities();
    return { status: 'resolved', data: response.data };
  };

  const navigateToSelectedSet = (original: string, compare: string) => {
    const similarity = documentSimilaritiesList.find(
      (duplicate) => duplicate.duplicate_of === original && duplicate.document_id === compare,
    );
    if (similarity) {
      navigate(`${original}..${compare}`);
      setZoom(DEFAULT_ZOOM);
    }
  };

  const navigateNextPage = () => {
    const lastDuplicate = documentSimilaritiesList.findIndex(
      (sim) =>
        (sim.duplicate_of === firstDocID && sim.document_id === secondDocID) ||
        (sim.document_id === firstDocID && sim.duplicate_of === secondDocID),
    );
    if (lastDuplicate + 1 < documentSimilaritiesList.length) {
      navigate(
        `${documentSimilaritiesList[lastDuplicate + 1].duplicate_of}..${
          documentSimilaritiesList[lastDuplicate + 1].document_id
        }`,
      );
      setZoom(DEFAULT_ZOOM);
    } else {
      nextPage();
    }
  };

  // pagination
  const previousPage = () => {
    setPage(page - pageSize >= 0 ? page - pageSize : page);
  };

  const nextPage = () => {
    setPage(page + pageSize < count ? page + pageSize : page);
  };

  const handleShowResolved = () => {
    setHideResolved(false);
    setHideUnresolved(true);
    setPage(0);
  };

  const handleShowUnresolved = () => {
    setHideResolved(true);
    setHideUnresolved(false);
    setPage(0);
  };

  const handleCreateAllDocumentSimilarities = useCallback(async () => {
    setIsRequestInProgress(true);
    const response = await createAllDocumentSimilaritiesForCase(caseID as string);
    if (response.status >= 400) {
      toast.error('Failed to create document similarities. Please refresh and try again.');
      setIsRequestInProgress(false);
      return { status: 'rejected' };
    }
    refreshDocumentSimilarities();
    toast.success('Document similarities created successfully.');
    setIsRequestInProgress(false);
    return { status: 'resolved', data: response.data };
  }, [caseID]);

  const duplicatesProcessing = useMemo(() => {
    if (!caseFiles) {
      return false;
    }
    return caseFiles.some((document) => document.duplicateStatus !== 'READY');
  }, [caseFiles]);

  return {
    caseID,
    documentSimilaritiesList,
    documentSimilarity,
    setNewOriginal,
    markAsDuplicate,
    markAsNotDuplicate,
    navigateToSelectedSet,
    count,
    nextPage,
    previousPage,
    currentPage: page / pageSize + 1,
    pageSize,
    showResolved: handleShowResolved,
    showUnresolved: handleShowUnresolved,
    hideResolved,
    zoom,
    setZoom,
    createAllDocumentSimilarities: handleCreateAllDocumentSimilarities,
    isRequestInProgress,
    duplicatesProcessing,
    documentSimilaritiesListLoading,
  };
}

export type DuplicatesByDocumentState = ReturnType<typeof useDuplicatesByDocumentState>;

const DuplicatesByDocumentContext = React.createContext<DuplicatesByDocumentState>({} as any);

export type DuplicatesByDocumentProps = {};

export function DuplicatesByDocument({
  children,
}: React.PropsWithChildren<DuplicatesByDocumentProps>) {
  const state = useDuplicatesByDocumentState();
  return (
    <DuplicatesByDocumentContext.Provider value={state}>
      {children}
    </DuplicatesByDocumentContext.Provider>
  );
}

export function useDuplicatesByDocument() {
  return React.useContext(DuplicatesByDocumentContext);
}

// need to handle all the query params besides caseID
const fetchDocumentSimilarities = async (
  caseID: string,
  similarityThreshold: number,
  hideResolved: boolean,
  hideUnresolved: boolean,
  page: number,
  pageSize: number,
) => {
  const response = await getDocumentSimilarities(
    caseID,
    similarityThreshold,
    hideResolved,
    hideUnresolved,
    page,
    pageSize,
  );

  if (response.status >= 400) {
    toast.error('Failed to fetch document similarities. Please refresh and try again.');
    return { status: 'rejected' };
  }
  return { status: 'resolved', data: response.data };
};

const fetchDocumentComparison = async (caseID: string, documentID: string, compareWith: string) => {
  const response = await getDocumentComparison(caseID, documentID, compareWith);
  if (response.status >= 400) {
    toast.error('Failed to compare documents. Please refresh and try again.');
    return { status: 'rejected' };
  }
  return { status: 'resolved', data: response.data };
};
