import { useMemo } from 'react';
import { usePeopleQuery } from '../../../__generated__/graphql';
import formatEntities from '../../../library/utilities/useEntities';

const usePeopleEntities = (caseID: string, entryID?: number, skip?: boolean) => {
  const query = usePeopleQuery({
    variables: {
      where: {
        case_id: caseID,
        entry_id: entryID,
      },
    },
    fetchPolicy: 'network-only',
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    skip,
  });

  const formattedPeopleForCase = query.data?.people?.peopleForCase?.map((author) => {
    if (author.origin === 'ML') {
      return {
        ...author,
        value: formatEntities(author.value),
      };
    }
    return author;
  });

  const formattedPeopleForTimelineEntry = query.data?.people?.peopleForTimelineEntry?.map(
    (author) => {
      if (author.origin === 'ML') {
        return {
          ...author,
          value: formatEntities(author.value),
        };
      }
      return author;
    },
  );

  const uniquePeople = new Set();
  const filteredPeopleForTimelineEntry = formattedPeopleForTimelineEntry?.filter((auth) => {
    if (!uniquePeople.has(auth.value)) {
      uniquePeople.add(auth.value);
      return true;
    }
    return false;
  });
  const filteredPeopleForCase = formattedPeopleForCase?.filter((auth) => {
    if (!uniquePeople.has(auth.value)) {
      uniquePeople.add(auth.value);
      return true;
    }
    return false;
  });

  const sortedPeopleForEntry = filteredPeopleForTimelineEntry
    ? filteredPeopleForTimelineEntry.sort((a, b) =>
        a.value?.toLowerCase() > b.value?.toLowerCase() ? 1 : -1,
      )
    : undefined;
  const sortedPeopleForCase = filteredPeopleForCase
    ? filteredPeopleForCase.sort((a, b) =>
        a.value?.toLowerCase() > b.value?.toLowerCase() ? 1 : -1,
      )
    : [];

  return useMemo(
    () => ({
      ...query,
      data: {
        sortedPeopleForCase,
        sortedPeopleForEntry,
        allPeople: formattedPeopleForCase,
      },
    }),
    [query],
  );
};

export default usePeopleEntities;
