import { FormControl, MenuItem, Select, Box, InputLabel } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import FilterControl from '../FilterControl';
import useDocumentNames from '../../../Case/gql/useDocumentNames';
import useNotes from '../../../Notes/gql/useNotes';

export default function DocumentFilter() {
  const { caseID } = useParams();
  const { data: notes } = useNotes({ case_id: caseID });
  const documentNames = useDocumentNames(caseID);
  const [searchParams, setSearchParams] = useSearchParams();
  const setDocBeingFiltered = (docId) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      notes__docId: docId,
    });
  };
  const docBeingFiltered = searchParams.get('notes__docId') || '';

  const documentIDsWithNotes = {};
  notes?.forEach((note) => {
    if (!documentIDsWithNotes[note.documentID]) {
      documentIDsWithNotes[note.documentID] = [note];
    } else {
      documentIDsWithNotes[note.documentID].push(note);
    }
  });

  return (
    <FilterControl
      control={
        <FormControl variant="standard" sx={{ width: '100%', maxWidth: '420px' }}>
          <InputLabel
            id="mutiple-select-label"
            sx={{
              fontSize: '0.875rem',
              paddingLeft: 1.5,
              paddingTop: 1,
              '&.Mui-focused': {
                display: 'none',
              },
              '&.MuiInputLabel-shrink': {
                display: 'none',
              },
            }}
          >
            All Documents
          </InputLabel>

          <Select
            variant="outlined"
            value={docBeingFiltered}
            onChange={(e) => setDocBeingFiltered(e.target.value)}
            sx={{
              mt: 2,
              width: '97%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
            inputProps={{
              sx: {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              },
            }}
          >
            <MenuItem key="all" value="">
              <Box sx={{ fontSize: '0.875rem' }}>All Documents</Box>
            </MenuItem>
            {Object.keys(documentIDsWithNotes ?? []).map((docID) => (
              <MenuItem key={docID} value={docID}>
                <Box sx={{ fontSize: '0.875rem' }}>{documentNames[docID]}</Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
    />
  );
}
