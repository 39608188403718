export function computeColour(number: number, setColor: 1 | 2) {
  const baseIncrement = 137.508; // Golden angle approximation
  const increments = [baseIncrement, baseIncrement * 0.618]; // Different increments for each set
  const adjustments = [
    { saturation: '50%', lightness: '75%' },
    { saturation: '60%', lightness: '65%' }, // Different saturation and lightness
  ];

  let hue = (number * increments[setColor - 1]) % 360;
  let colorProperties = adjustments[setColor - 1];

  return `hsl(${hue}, ${colorProperties.saturation}, ${colorProperties.lightness})`;
}
