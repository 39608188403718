/* eslint-disable camelcase */
import FindInPageIcon from '@mui/icons-material/FindInPage';
import Box from '@mui/material/Box';
import { useMemo, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PDFDocumentList from '../../components/DocumentScrolling/PDFDocumentList';
import Loading from '../../components/common/Loading';
import useDisplayStore from '../Timeline/useDisplayStore';
import useFileChunks from './useFilesChunks';
import usePDFViewerStore from './usePDFViewerStore';
import { useResetTimelineFilters } from '../Timeline/Components/useResetTimelineFilters';
import { DocumentSearch } from '../../components/icons/DocumentSearch';
import { getQueryParams } from '../Timeline/LinkWithQuery';

function DocumentScroller(props) {
  const {
    searchResults,
    pages,
    currentMode,
    dismissDocumentEdge,
    pageDepthByPageID,
    groupIDByPageID,
    attachmentIDByPageID,
    isGrouping,
    groupingActionOnClicks,
    isUpdateRequestInProgress,
    getGroupingButtonDisabledStatuses,
  } = props;
  const documentZoom = usePDFViewerStore((state) => state.documentZoom);
  const documentRotation = usePDFViewerStore((state) => state.documentRotation);
  const navigate = useNavigate();
  const params = useParams();
  const { caseID, timelineID: currentTimeline } = params;
  const currentPageID = +params.pageID;
  const [searchParams] = useSearchParams();
  const { documentWidth, documentHeight } = useDisplayStore((state) => state.PDFDimensions);
  const resetFilters = useResetTimelineFilters();

  const onScrollToDifferentPage = useCallback(
    (pageIndex) => {
      const nextPage = pages[pageIndex];
      const searchParamsObj = Object.fromEntries(searchParams.entries());
      const searchParamString = getQueryParams(searchParamsObj);
      if (nextPage) {
        if (currentMode === 'timeline' && currentTimeline) {
          navigate(
            `../timeline/${currentTimeline}/${nextPage.entryID}/${nextPage.id}${searchParamString}`,
          );
        } else if (currentMode === 'documents') {
          navigate(`../documents/${nextPage.documentID}/${nextPage.id}${searchParamString}`);
        } else {
          navigate(`../files/${nextPage.documentID}/grouping/${nextPage.id}`);
        }
      }
    },
    [pages, currentTimeline, searchParams, navigate, currentMode],
  );

  const currentPageIndex = useMemo(() => {
    const pageIndex = pages.findIndex((page) => Number(page.id) === currentPageID);
    if (pageIndex !== -1) {
      return pageIndex;
    }

    // Handle the documentID/pageNumber exception
    const pageIndexByPageNumber = pages.findIndex(
      (page) => page.pageNumber === currentPageID && page.documentID === params.documentID,
    );
    if (pageIndexByPageNumber !== -1) {
      window.location.href = `../${params.documentID}/${pages[pageIndexByPageNumber].id}`;
    }
    return pageIndex;
  }, [pages, currentPageID]);

  if (!pages) {
    return <Loading />;
  }
  if (currentPageID && currentPageIndex === -1) {
    return (
      <Box
        sx={{
          padding: '1rem',
          margin: 'auto',
          textAlign: 'center',
          width: documentWidth * documentZoom,
          height: documentHeight * documentZoom,
          backgroundColor: 'white',
        }}
      >
        <br />
        <br />
        <DocumentSearch />
        <br />
        <br />
        <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>
          No Documents Found
        </h2>
        <p style={{ maxWidth: '25rem', margin: '0 auto' }}>
          The document you are looking for is hidden by the current filter selection. Please adjust
          your filters to view relevant documents.
        </p>
        <br />
        <button className="sm-button" type="button" onClick={() => resetFilters()}>
          Reset Filters
        </button>
      </Box>
    );
  }
  if (currentPageIndex === -1) {
    return (
      <Box
        sx={{
          padding: '1rem',
          margin: 'auto',
          textAlign: 'center',
          width: documentWidth * documentZoom,
          height: documentHeight * documentZoom,
          backgroundColor: 'white',
        }}
      >
        <FindInPageIcon color="primary" sx={{ fontSize: '64px' }} />
        <Box>No Document Selected</Box>
      </Box>
    );
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <PDFDocumentList
        numberOfPages={pages.length}
        currentPageIndex={currentPageIndex}
        onScrollToDifferentPage={onScrollToDifferentPage}
        pages={pages}
        documentZoom={documentZoom}
        documentRotation={documentRotation}
        fetchFile={useFileChunks}
        caseID={caseID}
        searchResults={searchResults}
        dismissDocumentEdge={dismissDocumentEdge}
        pageDepthByPageID={pageDepthByPageID}
        groupIDByPageID={groupIDByPageID}
        attachmentIDByPageID={attachmentIDByPageID}
        isGrouping={isGrouping}
        groupingActionOnClicks={groupingActionOnClicks}
        isUpdateRequestInProgress={isUpdateRequestInProgress}
        getGroupingButtonDisabledStatuses={getGroupingButtonDisabledStatuses}
      />
    </Box>
  );
}

export default DocumentScroller;
