/* eslint-disable react/jsx-props-no-spreading */
import Stack from '@mui/material/Stack';
import React from 'react';
import { ComponentOverrideProp } from '../types/commonPropTypes';

export type CenterProps<C extends React.ComponentType> = ComponentOverrideProp<C> &
  Omit<React.ComponentProps<typeof Stack>, 'component'>;

function Center<C extends React.ComponentType>(props: React.PropsWithChildren<CenterProps<C>>) {
  const { children, ...restProps } = props;

  return (
    <Stack justifyContent="center" alignItems="center" textAlign="center" {...restProps}>
      {children}
    </Stack>
  );
}

export default Center;
