/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState } from 'react';
import {
  AppBar,
  Avatar,
  Drawer,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Popper,
  Paper,
  Grow,
  Button,
  Box,
} from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ExpandMore, Settings, ExitToApp } from '@mui/icons-material';
import PropTypes from 'prop-types';
import SiftMedWhite from '../../resources/images/SiftMedWhite.png';
import Cases from '../icons/Cases';
import Templates from '../icons/Templates';
import SideBarButton from './SideBarButton';
import AccountSettings from '../../containers/AccountSettings';
import { AuthContext } from '../../library/contexts/AuthContext';
import SidebarHelpButton from './SidebarHelpButton';

export default function NewSidebar() {
  const { logout, givenName, familyName } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const location = useLocation();
  const { caseID } = useParams();

  const handleSignOut = () => {
    setOpen((prevOpen) => !prevOpen);
    logout(location, caseID);
  };

  const handleSettingsOpen = () => {
    setOpen((prevOpen) => !prevOpen);
    setSettingsOpen(true);
  };

  const handleSettingsClose = () => {
    setSettingsOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function stringAvatar(name) {
    return {
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      PaperProps={{
        sx: {
          borderRadius: 0,
          background: 'linear-gradient(170deg, #00214A 44.6%, #213975 95.99%)',
        },
      }}
      sx={{
        width: '5rem',
        flexShrink: 0,
        overflowX: 'hidden',
        paper: {
          width: '4rem',
          overflowX: 'hidden',
        },
      }}
    >
      <AppBar
        position="relative"
        sx={{
          boxShadow: 'none',
          height: '100%',
          width: '5rem',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Box sx={{ textAlign: 'center', width: '100%', marginTop: 2.5 }}>
            <Link textDecoration="none" underline="none" to="/cases">
              <img
                src={SiftMedWhite}
                alt="SiftMed Inc. Logo"
                style={{
                  width: 'auto',
                  height: '3.5rem',
                  padding: '0.5rem',
                  marginBottom: 18,
                }}
              />
            </Link>
            <Link to="/cases">
              <SideBarButton
                tooltiptext="Cases"
                sx={{ marginTop: 1 }}
                Icon={Cases}
                selected={location.pathname.indexOf('/case') === 0}
              />
            </Link>
            <Link to="/templates">
              <SideBarButton
                tooltiptext="Templates"
                sx={{ marginTop: 1, marginBottom: 4 }}
                Icon={Templates}
                selected={location.pathname.indexOf('/templates') === 0}
              />
            </Link>
          </Box>
          <Box sx={{ textAlign: 'center', width: '100%', bottom: 7 }} position="absolute">
            <SidebarHelpButton />
            <Button
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              ref={anchorRef}
              onClick={handleToggle}
            >
              <Avatar
                display="inline"
                sx={{
                  width: 47,
                  height: 47,
                  fontSize: '1.26rem',
                  color: '#102449',
                  fontWeight: 700,
                  background: 'linear-gradient(159.64deg, #b3f5e0 36.96%, #00DFA2 133.97%)',
                }}
                {...stringAvatar(`${givenName} ${familyName}`)}
              />
              <Box display="inline" sx={{ lineHeight: '11px' }}>
                <ExpandMore sx={{ color: '#FFFFFF', fontSize: '16px' }} />
              </Box>
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              sx={{ zIndex: 2000 }}
              disablePortal={false}
              placement="left"
              modifiers={[
                {
                  name: 'flip',
                  enabled: true,
                },
                {
                  name: 'preventOverflow',
                  enabled: true,
                  options: {
                    boundariesElement: 'viewport',
                  },
                },
              ]}
            >
              {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={open} id="menu-list-grow">
                        <MenuItem onClick={handleSettingsOpen}>
                          <Settings className="mr-2" fontSize="small" />
                          Settings
                        </MenuItem>
                        <Link to="/login" style={{ textDecoration: 'none' }}>
                          <MenuItem onClick={handleSignOut} sx={{ color: '#002147' }}>
                            <ExitToApp
                              style={{ color: '#002147' }}
                              className="mr-2"
                              fontSize="small"
                            />
                            Log out
                          </MenuItem>
                        </Link>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </Box>
        {settingsOpen ? (
          <AccountSettings handleSettingsClose={handleSettingsClose} settingsOpen={settingsOpen} />
        ) : (
          ''
        )}
      </AppBar>
    </Drawer>
  );
}

NewSidebar.propTypes = {
  open: PropTypes.bool,
  givenName: PropTypes.string,
  familyName: PropTypes.string,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
};

NewSidebar.defaultProps = {
  open: true,
  givenName: '',
  familyName: '',
  sx: undefined,
};
