import { create } from 'zustand';
import { Page } from './types/timelineTypes';

const useTimelineStore = create<TimelineStoreTypes>((set, get) => ({
  showCheckboxes: false,
  hiddenEntities: [],
  splitInProgress: false,
  mergeInProgress: false,
  pages: {},
  entryDate: '',
  source: '',
  author: { id: null, name: null },
  organization: { id: null, name: null },
  subject: { id: null, name: null },
  currentEntry: null,
  timelineIDForSplitCheckboxes: -1,
  tooManyError: false,
  marked_important: false,
  setShowCheckboxes: (showCheckboxes: boolean) => set({ showCheckboxes }),
  setHiddenEntities: (hiddenEntities: (string | number)[]) => set({ hiddenEntities }),
  startTimelineEntrySplit: (
    timelineEntryID: number,
    date: string,
    source: string,
    authorID: number | null,
    orgID: number | null,
    subjectID: number | null,
  ) =>
    set(() => ({
      pages: {},
      entryDate: date,
      timelineIDForSplitCheckboxes: timelineEntryID,
      source,
      splitInProgress: true,
      author: { id: authorID, name: null },
      organization: { id: orgID, name: null },
      subject: { id: subjectID, name: null },
      currentEntry: timelineEntryID,
      tooManyError: false,
    })),

  startTimelineMerge: (
    date: string,
    pages: Page[],
    source: string,
    authorID: number | null,
    orgID: number | null,
    subjectID: number | null,
    currentEntry: number | null,
  ) =>
    set((state) => ({
      pages: {
        ...state.pages,
        ...Object.fromEntries(pages.map((page) => [page.id, { ...page, selected: true }])),
      },
      entryDate: date,
      showCheckboxes: true,
      mergeInProgress: true,
      source,
      author: { id: authorID, name: null },
      organization: { id: orgID, name: null },
      subject: { id: subjectID, name: null },
      currentEntry,
    })),

  toggleShowCheckboxes: () => set((state) => ({ showCheckboxes: !state.showCheckboxes })),

  toggleTooManyError: () => set((state) => ({ tooManyError: !state.tooManyError })),

  setDate: (date: string) => set(() => ({ entryDate: date })),

  setSource: (sourceID: string) => set(() => ({ source: sourceID })),

  setAuthor: (author: EntityObject) => set(() => ({ author: author })),

  setOrganization: (organization: EntityObject) => set(() => ({ organization: organization })),

  setSubject: (subject: EntityObject) => set(() => ({ subject: subject })),

  toggleSelectPage: (page: Page) =>
    set((state: TimelineStoreTypes) => ({
      pages: {
        ...state.pages,
        [page.id]: { ...page, selected: !state.pages[page.id]?.selected },
      },
    })),

  toggleSelectTimelineEntry: (timelineEntryId: number, pages: Page[]) => {
    get().updatePageSelection(pages, !get().isTimelineEntrySelected(timelineEntryId, pages));
  },

  updatePageSelection: (pages: Page[], selected: boolean) =>
    set((state) => ({
      pages: {
        ...state.pages,
        ...Object.fromEntries(pages.map((page) => [page.id, { ...page, selected }])),
      },
    })),

  clearSelection: () =>
    set(() => ({
      pages: {},
      timelineIDForSplitCheckboxes: -1,
      currentEntry: null,
      splitInProgress: false,
      mergeInProgress: false,
    })),

  // Compute Functions for Merge/Split
  isPageSelected: (pageId: number) => Boolean(get().pages[pageId]?.selected),

  isTimelineEntrySelected: (timelineEntryId: number, pages: Page[]) =>
    Object.entries(get().pages).filter(
      ([, page]) => page.selected && page.entryID === timelineEntryId,
    ).length === pages.length,

  isTimelineEntryPartiallySelected: (timelineEntryId: number, pages: Page[]) => {
    const selectedPages = Object.entries(get().pages).filter(
      ([, page]) => page.selected && page.entryID === timelineEntryId,
    );
    return selectedPages.length > 0 && selectedPages.length < pages.length;
  },

  areMultipleTimelineEntriesSelected: () => {
    const uniqueTimelineEntries = new Set();
    Object.values(get().pages).forEach((page) => {
      if (page.selected) {
        uniqueTimelineEntries.add(page.entryID);
      }
    });
    return uniqueTimelineEntries.size >= 2;
  },

  areMaximumPagesForSplitSelected: (pages: MergePageType[]) => {
    const selectedPages = Object.entries(get().pages).filter(([, page]) => page.selected);
    if (selectedPages.length >= pages.length - 1) {
      return true;
    }
    return false;
  },

  isAtLeastOneTimelineEntryPageSelected: () => {
    const uniqueTimelineEntries = new Set();
    Object.values(get().pages).forEach((page) => {
      if (page.selected) {
        uniqueTimelineEntries.add(page.entryID);
      }
    });
    return uniqueTimelineEntries.size >= 1;
  },

  selectedPages: () =>
    Object.fromEntries(Object.entries(get().pages).filter(([, page]) => page.selected)),
}));

export default useTimelineStore;

interface TimelineStoreTypes {
  showCheckboxes: boolean;
  hiddenEntities: (string | number)[];
  splitInProgress: boolean;
  mergeInProgress: boolean;
  pages: PageObject;
  entryDate: string;
  source: string;
  author: object;
  organization: object;
  subject: object;
  currentEntry: number | null;
  timelineIDForSplitCheckboxes: number;
  tooManyError: boolean;
  setHiddenEntities: (hiddenEntities: (string | number)[]) => void;
  setShowCheckboxes: (showCheckboxes: boolean) => void;
  startTimelineEntrySplit: (
    timelineEntryID: number,
    date: string,
    source: string,
    authorID: number | null,
    orgID: number | null,
    subjectID: number | null,
  ) => void;
  startTimelineMerge: (
    date: string,
    pages: Page[],
    source: string,
    authorID: number | null,
    orgID: number | null,
    subjectID: number | null,
    currentEntry: number | null,
  ) => void;
  toggleShowCheckboxes: () => void;
  toggleTooManyError: () => void;
  setDate: (date: string) => void;
  setSource: (sourceID: string) => void;
  setAuthor: (author: EntityObject) => void;
  setOrganization: (org: EntityObject) => void;
  setSubject: (subject: EntityObject) => void;
  toggleSelectPage: (page: Page) => void;
  toggleSelectTimelineEntry: (timelineEntryId: number, pages: Page[]) => void;
  updatePageSelection: (pages: Page[], selected: boolean) => void;
  clearSelection: () => void;
  isPageSelected: (pageId: number) => boolean;
  isTimelineEntrySelected: (timelineEntryId: number, pages: Page[]) => boolean;
  isTimelineEntryPartiallySelected: (timelineEntryId: number, pages: Page[]) => boolean;
  areMultipleTimelineEntriesSelected: () => boolean;
  areMaximumPagesForSplitSelected: (pages: MergePageType[]) => boolean;
  isAtLeastOneTimelineEntryPageSelected: () => boolean;
  selectedPages: () => PageObject;
}

type PageObject = {
  [pageID: number]: MergePageType;
};

type EntityObject = {
  id: number | null;
  name: string | null;
};

interface MergePageType extends Page {
  selected: boolean;
}
