import { Button, Switch, Tooltip } from '@mui/material';
import { Remove, Add } from '@mui/icons-material';

import NavigationButton from '../../components/common/PdfNavigation/Components/NavigationButton';
import Theme from '../../theme';
import IconMouseScroll from '../../components/icons/IconMouseScroll';
import IconLocked from '../../components/icons/IconLocked';

type DocumentCompareHeaderProps = {
  duplicate: () => void;
  notDuplicate: () => void;
  setOriginal: () => void;
  zoom: number;
  setZoom: (newZoom: number) => void;
  areDocumentScrollsSynced: boolean;
  setAreDocumentScrollsSynced: (synced: boolean) => void;
  currentSimilarityStatus: 'Duplicate' | 'NotDuplicate' | 'Unset';
};

export default function DocumentCompareHeader({
  duplicate,
  notDuplicate,
  setOriginal,
  zoom,
  setZoom,
  areDocumentScrollsSynced,
  setAreDocumentScrollsSynced,
  currentSimilarityStatus,
}: DocumentCompareHeaderProps) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <div
        style={{
          width: '50%',
          display: 'flex',
          flexDirection: 'row',
          marginRight: '10px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            fontWeight: 600,
            fontSize: '15px',
            paddingRight: '5px',
            paddingLeft: '5px',
            marginLeft: '20px',
          }}
        >
          Original Document
        </div>
        <div>
          <ToolbarZoom zoom={zoom} setZoom={setZoom} />
        </div>
      </div>

      <div
        style={{
          width: '50%',
          display: 'flex',
          flexDirection: 'row',
          marginLeft: '10px',
        }}
      >
        <div style={{ width: '75%' }}>
          <Button
            sx={{
              backgroundColor: 'primary.dark',
              color: 'white',
              border: '1px solid',
              borderColor: 'caseStatus.processorActionButtonBackground',
              fontFamily: 'Inter',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '18px',
              margin: '8px',
            }}
            onClick={duplicate}
            disabled={currentSimilarityStatus === 'Duplicate'}
          >
            Duplicate
          </Button>
          <Button
            sx={{
              backgroundColor: 'caseStatus.processorActionButtonBackground',
              color: 'primary.dark',
              border: '1px solid',
              borderColor: 'caseStatus.processorActionButtonBackground',
              fontFamily: 'Inter',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '18px',
              margin: '8px',
            }}
            onClick={notDuplicate}
            disabled={currentSimilarityStatus === 'NotDuplicate'}
          >
            Not Duplicate
          </Button>
          <Button
            sx={{
              backgroundColor: 'white',
              border: '1px solid',
              borderColor: 'caseStatus.processorActionButtonBackground',
              fontFamily: 'Inter',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '18px',
              margin: '8px',
            }}
            onClick={() => {
              setOriginal();
            }}
          >
            Set as Original
          </Button>
        </div>
        <Tooltip title="Sync Document Scrolling">
          <span
            style={{
              marginTop: 'auto',
              marginBottom: 'auto',
              lineHeight: 1,
            }}
          >
            <IconMouseScroll />

            <IconLocked />
            <Switch
              checked={areDocumentScrollsSynced}
              onChange={() => setAreDocumentScrollsSynced(!areDocumentScrollsSynced)}
              sx={{
                mr: '0.5rem',
              }}
            />
          </span>
        </Tooltip>
      </div>
    </div>
  );
}

type ToolbarZoomProps = {
  zoom: number;
  setZoom: (newZoom: number) => void;
  zoomFactor?: number;
  zoomMax?: number;
  zoomMin?: number;
  style?: {};
};

export function ToolbarZoom({
  zoom,
  setZoom,
  zoomFactor = 0.1,
  zoomMax = 2,
  zoomMin = 0.5,
  style,
}: ToolbarZoomProps) {
  return (
    <div style={{ alignItems: 'center', justifyContent: 'center' }}>
      <div
        style={{
          display: 'flex',
          whiteSpace: 'nowrap',
          ...style,
        }}
      >
        <NavigationButton
          id="navbar-zoom-out"
          onClick={() => setZoom(zoom - zoomFactor)}
          icon={
            <Remove
              sx={{
                fontSize: '1rem',
                color: zoom <= zoomMin ? 'inherit' : '#344054',
              }}
            />
          }
          disabled={zoom <= zoomMin}
          position="left"
        />
        <div
          style={{
            border: '1px solid',
            borderColor: Theme.palette.borderGrey.main,
            borderRight: 0,
            fontSize: '0.68rem',
            fontWeight: 600,
            paddingLeft: '8px',
            paddingRight: '8px',
            lineHeight: '1.6rem',
            height: '1.6rem',
          }}
        >
          {Math.round(zoom * 100) + 20}%
        </div>
        <NavigationButton
          id="navbar-zoom-in"
          onClick={() => setZoom(zoom + zoomFactor)}
          icon={
            <Add
              sx={{
                fontSize: '1rem',
                color: zoom >= zoomMax ? 'inherit' : '#344054',
              }}
            />
          }
          disabled={zoom >= zoomMax}
          position="right"
        />
      </div>
    </div>
  );
}
