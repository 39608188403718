import { useCallback, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { gql, useApolloClient } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useUpdatePageViewedMutation,
  UpdatePageViewedMutationVariables,
} from '../../../__generated__/graphql';
import { useUser } from '../../../library/contexts/AuthContext';
import { PageViewedType } from '../types/pageTypes';

export default function useUpdatePageViewed() {
  const [mutation, state] = useUpdatePageViewedMutation();
  const client = useApolloClient();
  const { userId } = useUser();
  const { caseID } = useParams();

  const wrappedMutation = useCallback(
    (params: UpdatePageViewedMutationVariables) => {
      const { pageID, viewed } = params.data;

      const pageObject = client.readFragment({
        id: client.cache.identify({ id: pageID, __typename: 'PageObject' }),
        fragment: gql`
          fragment PageDetailsFragment on PageObject {
            pages_viewed {
              user_id
              page_id
              viewed_at
            }
          }
        `,
      });

      try {
        return mutation({
          variables: {
            data: params.data,
          },
          optimisticResponse: {
            updatePageViewedStatus: {
              __typename: 'PageObject',
              id: +params.data.pageID,
              pages_viewed:
                viewed === 1
                  ? [{ page_id: pageID, user_id: userId, viewed_at: new Date().toISOString() }]
                  : [],
            },
          },
          update: (cache) => {
            cache.modify({
              id: client.cache.identify({ id: caseID, __typename: 'CasesObject' }),
              fields: {
                number_of_pages_viewed(existingValue) {
                  if (
                    viewed === 0 &&
                    pageObject?.pages_viewed?.find((p: PageViewedType) => p.user_id === userId)
                  ) {
                    return existingValue - 1;
                  }
                  if (
                    viewed === 1 &&
                    !pageObject?.pages_viewed?.find((p: PageViewedType) => p.user_id === userId)
                  ) {
                    return existingValue + 1;
                  }
                  return existingValue;
                },
              },
            });
          },
        });
      } catch (error) {
        toast.error(
          'A problem occurred while updating page to viewed. If issues persist, please try refreshing the page.',
        );
        Sentry.captureException(error);
        throw error;
      }
    },
    [mutation, userId, client, caseID],
  );

  return useMemo(() => [wrappedMutation, state], [wrappedMutation, state]);
}
