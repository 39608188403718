import { useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  CaseFragmentFragmentDoc,
  CreateCaseInput,
  useCreateCaseMutation,
} from '../../../__generated__/graphql';
import { normalizeCase } from './case-gql-utils';

const useCreateCase = () => {
  const [createCaseMutation, mutationStateInfo] = useCreateCaseMutation();

  const createCaseCallback = useCallback(
    async (variables: CreateCaseInput) => {
      try {
        const mutationResponse = await createCaseMutation({
          variables: { createCaseInput: variables },
          update: (cache, { data }) => {
            cache.modify({
              fields: {
                cases(existingCases = []) {
                  const newCaseRef = cache.writeFragment({
                    data: data?.createCase,
                    fragment: CaseFragmentFragmentDoc,
                  });
                  return [...existingCases, newCaseRef];
                },
              },
            });
          },
        });
        return normalizeCase(mutationResponse.data?.createCase);
      } catch (err) {
        toast.error(
          'There was an issue creating your case. Please try again, and contact support if the issue persists.',
        );
      }
    },
    [createCaseMutation],
  );

  return [createCaseCallback, mutationStateInfo];
};

export default useCreateCase;
