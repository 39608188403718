import apiConfig from './apiConfig';

const commonGqlConfig = {
  defaultSkip: 0,
  defaultTake: 100,
};

const config = {
  api: apiConfig,
  timeline: {
    gql: commonGqlConfig,
  },
  documents: {
    gql: commonGqlConfig,
  },
};

export default config;
