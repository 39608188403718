import { AxiosResponse } from 'axios';
import { authenticatedRequest } from '../../../api';

function updateDocumentName(entryID: bigint, documentName: string): Promise<AxiosResponse> {
  const res = authenticatedRequest({
    method: 'POST',
    url: `/timeline-entry/updateDocumentName/${entryID}`,
    data: { documentName },
  });
  return res;
}
export function useUpdateDocumentName(): (entryID: bigint, documentName: string) => Promise<void> {
  return async (entryID, documentName) => {
    await updateDocumentName(entryID, documentName);
  };
}
