import { useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  DeleteNoteMutationVariables,
  NoteFragmentFragmentDoc,
  NotesDocument,
  useDeleteNoteMutation,
} from '../../../__generated__/graphql';

const useDeleteNote = () => {
  const [deleteNoteMutation, mutationStateInfo] = useDeleteNoteMutation();
  const deleteNote = useCallback(
    async ({ variables }: { variables: DeleteNoteMutationVariables }) => {
      try {
        await deleteNoteMutation({
          variables,
          update: (cache, { data }) => {
            const deletedNoteId = data?.deleteNote;

            if (deletedNoteId) {
              cache.modify({
                fields: {
                  notes(existingNotes = []) {
                    // Filter out the deleted note from the cached list
                    return existingNotes.filter((noteRef: { __ref: string }) => {
                      // Extract the ID of each note from its reference
                      const foundNote = cache.readFragment({
                        id: noteRef.__ref,
                        fragment: NoteFragmentFragmentDoc,
                        fragmentName: 'NoteFragment',
                      }) as NotesDocument | null;

                      return foundNote !== null && foundNote.id !== deletedNoteId;
                    });
                  },
                },
              });
            }
          },
        });
      } catch (err) {
        toast.error('Failed to delete note');
        throw new Error();
      }
    },
    [deleteNoteMutation],
  );

  return [deleteNote, mutationStateInfo];
};

export default useDeleteNote;
