import { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Checkbox,
  Popover,
  ClickAwayListener,
  Typography,
  TextField,
} from '@mui/material';
import moment from 'moment';
import FilterControl from './FilterControl';

function CaseFilterPopover({
  handleFilterPopperClose,
  filtersOpen,
  anchorRef,
  setFiltersOpen,
  showAll,
  setShowAll,
  setDueDateFilter,
  showFilesRequired,
  setShowFilesRequired,
  showBeingProcessed,
  setShowBeingProcessed,
  showReviewInProgress,
  showClosed,
  setShowReviewInProgress,
  setShowClosed,
  showReady,
  setShowReady,
  dueDate,
}) {
  function setDefaults() {
    window.localStorage.setItem('showFilesRequired', true);
    window.localStorage.setItem('showBeingProcessed', true);
    window.localStorage.setItem('showReviewInProgress', true);
    window.localStorage.setItem('showReady', true);
    window.localStorage.setItem('showClosed', true);
    window.localStorage.setItem('dueDateFilter', '');
    setShowFilesRequired(true);
    setShowBeingProcessed(true);
    setShowReviewInProgress(true);
    setShowReady(true);
    setShowClosed(true);
    setDateBefore('');
    setDueDateFilter('');
    setShowAll(true);
  }

  const [dateBefore, setDateBefore] = useState(dueDate || '');

  const handleFilterSwitch = (event) => {
    const { name } = event.target;
    if (name === 'files required') {
      window.localStorage.setItem('showFilesRequired', !showFilesRequired);
      setShowFilesRequired(!showFilesRequired);
    } else if (name === 'processing') {
      window.localStorage.setItem('showBeingProcessed', !showBeingProcessed);
      setShowBeingProcessed(!showBeingProcessed);
    } else if (name === 'ready') {
      window.localStorage.setItem('showReady', !showReady);
      setShowReady(!showReady);
    } else if (name === 'in-progress') {
      window.localStorage.setItem('showReviewInProgress', !showReviewInProgress);
      setShowReviewInProgress(!showReviewInProgress);
    } else if (name === 'closed') {
      window.localStorage.setItem('showClosed', !showClosed);
      setShowClosed(!showClosed);
    }
    if (name === 'all') {
      setShowFilesRequired(!showAll);
      setShowBeingProcessed(!showAll);
      setShowReviewInProgress(!showAll);
      setShowReady(!showAll);
      setShowClosed(!showAll);
      window.localStorage.setItem('showFilesRequired', !showAll);
      window.localStorage.setItem('showBeingProcessed', !showAll);
      window.localStorage.setItem('showReviewInProgress', !showAll);
      window.localStorage.setItem('showReady', !showAll);
      window.localStorage.setItem('showClosed', !showAll);
    }
  };

  useEffect(() => {
    if (
      showReady &&
      showFilesRequired &&
      showBeingProcessed &&
      showReviewInProgress &&
      showClosed
    ) {
      setShowAll(true);
    } else if (
      !(showReady && showFilesRequired && showBeingProcessed && showReviewInProgress && showClosed)
    ) {
      setShowAll(false);
    }
  }, [showReady, showFilesRequired, showBeingProcessed, showReviewInProgress, showClosed]);

  const handleDueDateFilter = (event) => {
    const date = moment(event).format('YYYY-MM-DD');
    setDateBefore(date);
    setDueDateFilter(date);
    window.localStorage.setItem('dueDateFilter', date);
  };

  return (
    <ClickAwayListener onClickAway={(e) => handleFilterPopperClose(e)}>
      <Popover
        width={280}
        height={330}
        open={filtersOpen}
        anchorEl={anchorRef.current}
        onClose={() => {
          setFiltersOpen(false);
        }}
        PaperProps={{ sx: { width: 280 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div style={{ width: '100%', padding: '1rem' }}>
          <div>
            <p
              style={{
                width: '50%',
                fontSize: '0.875rem',
                fontWeight: 'bold',
                float: 'left',
              }}
            >
              Filters
            </p>
            <Button
              variant="text"
              onClick={setDefaults}
              style={{
                height: 'fit-content',
                width: 'fit-content',
                float: 'right',
                fontSize: '0.875rem',
                fontWeight: '500',
                display: 'inline-block',
                marginTop: '-0.55rem',
                color: 'primary.light',
              }}
            >
              Clear All
            </Button>
          </div>
          <p
            style={{
              width: '100%',
              fontSize: '0.875rem',
              fontWeight: 500,
              float: 'left',
            }}
          >
            Status
          </p>
          <FilterControl
            control={
              <Checkbox checked={showAll} size="small" onChange={handleFilterSwitch} name="all" />
            }
            label="All"
            controlStyle={{ textAlign: 'end' }}
          />
          <FilterControl
            control={
              <Checkbox
                checked={showFilesRequired}
                size="small"
                onChange={handleFilterSwitch}
                name="files required"
              />
            }
            label="Files Required"
            controlStyle={{
              textAlign: 'start',
            }}
          />
          <FilterControl
            control={
              <Checkbox
                checked={showBeingProcessed}
                size="small"
                onChange={handleFilterSwitch}
                name="processing"
              />
            }
            label="Being Processed"
            controlStyle={{ textAlign: 'end' }}
          />
          <FilterControl
            control={
              <Checkbox
                checked={showReady}
                size="small"
                onChange={handleFilterSwitch}
                name="ready"
              />
            }
            label="Ready for Review"
            controlStyle={{ textAlign: 'end' }}
          />
          <FilterControl
            control={
              <Checkbox
                checked={showReviewInProgress}
                size="small"
                onChange={handleFilterSwitch}
                name="in-progress"
              />
            }
            label="Review in Progress"
            controlStyle={{ textAlign: 'end' }}
          />
          <FilterControl
            control={
              <Checkbox
                checked={showClosed}
                size="small"
                onChange={handleFilterSwitch}
                name="closed"
              />
            }
            label="Closed"
            controlStyle={{ textAlign: 'end' }}
          />
          <Box
            style={{
              justifycontent: 'space-between',
              alignItems: 'baseline',
              display: 'flex',
              marginTop: '1rem',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.85rem',
                fontWeight: 400,
                display: 'inline flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                marginRight: '0.7rem',
              }}
            >
              Due Before
            </Typography>
            <TextField
              type="date"
              name="afterDate"
              value={dateBefore}
              sx={{ width: '10rem' }}
              onChange={(e) => handleDueDateFilter(e.target.value)}
              inputProps={{ min: '1800-01-01', max: '2099-12-31' }}
            />
          </Box>
        </div>
      </Popover>
    </ClickAwayListener>
  );
}

export default CaseFilterPopover;
