import { useRef, useState } from 'react';

import { HelpOutline } from '@mui/icons-material';
import { Grow, Paper, ClickAwayListener, MenuList, Box, Popper } from '@mui/material';

import SideBarButton from './SideBarButton';

export default function SidebarHelpButton() {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box>
      <SideBarButton
        sx={(theme) => ({
          color: theme.palette.secondary.main,
        })}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        ref={anchorRef}
        onClick={handleToggle}
        tooltiptext="Help"
        Icon={HelpOutline}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        sx={{ zIndex: 2000 }}
        disablePortal={false}
        placement="left"
        modifiers={[
          {
            name: 'flip',
            enabled: true,
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              boundariesElement: 'viewport',
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper sx={{ fontSize: 14, marginLeft: 1, paddingX: 3, paddingY: 2 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  <Box sx={{ fontWeight: 'bold' }}>Need some help?</Box>
                  <Box>
                    Email us at&nbsp;
                    <a href="mailto:support@siftmed.ca">support@siftmed.ca</a>
                  </Box>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}
