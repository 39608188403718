import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import axios from 'axios';
import APIURL from '../../library/utilities/apiUrl';

async function fetchReports(caseID) {
  return (
    await axios.post(`${APIURL}getCaseReports`, {
      caseID,
    })
  ).data;
}

export default function useReports(caseID) {
  return useQuery(
    ['reports', caseID],
    async () => {
      const reports = await fetchReports(caseID);
      return reports;
    },
    { refetchOnWindowFocus: false },
  );
}

async function saveFile(uploadObject, caseID, file = '') {
  const uploadObj = {
    caseID,
    ...uploadObject,
  };
  const presigned = await axios.post(`${APIURL}saveReport`, uploadObj);
  const { fields, url } = presigned.data;
  const form = new FormData();

  Object.keys(fields).forEach((key) => form.append(key, fields[key]));
  form.append('file', file);
  await fetch(url, {
    method: 'POST',
    body: form,
  });
}

export function useCreateNewReport() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ uploadObj, caseID, file }) => {
      await saveFile(uploadObj, caseID, file);
      await queryClient.invalidateQueries(['reports', caseID]);
    },
    {
      onError: (error) => {
        console.log(error);
        toast.error('Error creating new report');
      },
    },
  );
}
