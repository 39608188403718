import React from 'react';
import './input-with-icon.css';

type Props = {
  Icon: React.ReactNode;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  containerProps: React.HTMLAttributes<HTMLDivElement>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
};

export default function InputWithIcon({
  Icon,
  inputProps = {},
  containerProps = {},
  onChange,
  value,
}: Props) {
  return (
    <div className="input-container" {...containerProps}>
      <span className="input-icon">{Icon}</span>
      <input className="input" {...inputProps} onChange={onChange} value={value} />
    </div>
  );
}
