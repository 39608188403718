import Box from '@mui/material/Box';
import FeedbackPopup from '../../../components/common/FeedbackPopup';
import CreateMergeSplitAction from '../CreateMergeSplitAction';

export default function ActionFooter({
  currentDocumentID,
  mergeTimeline,
  setShowTimelineUpdated,
  showMergeSplitAction,
  showTimelineUpdated,
}: ActionFooterProps) {
  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        bottom: 16,
        marginBottom: 0,
      }}
    >
      {showMergeSplitAction && (
        <CreateMergeSplitAction
          mergeTimeline={mergeTimeline}
          currentDocumentID={currentDocumentID}
        />
      )}
      <FeedbackPopup
        text="Timeline successfully updated"
        open={showTimelineUpdated}
        onClose={() => setShowTimelineUpdated(false)}
      />
    </Box>
  );
}

type ActionFooterProps = {
  currentDocumentID: string | undefined;
  mergeTimeline: any; // Adjust the type signature if mergeTimeline takes parameters
  setShowTimelineUpdated: (value: boolean) => void;
  showMergeSplitAction: boolean;
  showTimelineUpdated: boolean;
};
