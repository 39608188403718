/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Divider } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import NewSearchBar from '../../components/Searchbar/NewSearchBar';
import {
  documentSelector,
  useDebounceInput,
  useSearchQuery,
} from '../../library/utilities/useSearch';
import ConfigurableViewFilter from '../Timeline/Components/ConfigurableViewFilter';
import TimelineDocumentViewSelector from '../Timeline/Components/TimelineDocumentViewSelector';
import DocumentListContainer from './DocumentListContainer';
import TimelineSettings from '../Timeline/TimelineSettings';
import useDocumentDescriptors from './gql/useDocumentDescriptors';
import { ListPagesSortByEnum } from '../Timeline/types/timelineTypes';
import useDocumentSearchStore from '../Timeline/useDocumentSearchStore';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';

export default function DocumentView() {
  const { caseID, pageID } = useParams();

  const [filters, setFilters, searchStr, setSearchStr] = useDocumentSearchStore(
    (state) => [state.filters, state.setFilters, state.searchStr, state.setSearchStr],
    shallow,
  );
  const isFileProcessor = useIsFileProcessor() ?? false;

  const debounceInputHandler = useDebounceInput({
    initialValue: searchStr,
    delay: 500,
    onChange: setSearchStr,
  });

  const { isLoading: searchingInProgress, data: rawSearchResult } = useSearchQuery(
    caseID,
    searchStr,
    (value) => value,
    {
      onSuccess: (keywordSearchConstraints, searchKeyword) => {
        documentDescriptorQuery.refetch({
          caseId: caseID,
          filters,
          keywordSearchConstraints,
          searchKeyword,
        });
      },
    },
  );

  const documentDescriptorQuery = useDocumentDescriptors({
    caseId: caseID,
    filters,
    keywordSearchConstraints: rawSearchResult,
    searchKeyword: searchStr,
  });

  const handleFiltersChange = useCallback(
    (formFilters) => {
      documentDescriptorQuery.refetch({
        caseId: caseID,
        filters: formFilters,
        keywordSearchConstraints: rawSearchResult,
        searchKeyword: searchStr,
      });
      setFilters(formFilters);
    },
    [documentDescriptorQuery, caseID, rawSearchResult, searchStr, setFilters],
  );
  const searchResultCounts = useMemo(() => {
    if (!rawSearchResult || !documentDescriptorQuery?.data) {
      return null;
    }
    const results = documentSelector.filterByVisibleDocumentsAndPages(
      documentDescriptorQuery.data.documentListDescriptor ?? [],
    )(rawSearchResult);
    return results;
  }, [rawSearchResult, documentDescriptorQuery.data]);

  const navigate = useNavigate();

  // Navigate to first page if none selected
  useEffect(() => {
    if (!pageID && documentDescriptorQuery?.data?.documentListDescriptor) {
      const firstDocument = documentDescriptorQuery.data.documentListDescriptor[0];
      if (firstDocument && firstDocument.documentID && firstDocument.pages.length > 0) {
        navigate(`${firstDocument.documentID}/${firstDocument.pages[0].id}`);
      }
    }
  }, [pageID, documentDescriptorQuery]);

  useEffect(() => {
    if (searchResultCounts?.totalIncludingHiddenDocumentsAndPages >= 5000) {
      toast.warn('Search results limited to 5000 results. Consider refining your search.');
    }
  }, [searchResultCounts?.totalIncludingHiddenDocumentsAndPages]);

  return (
    <Box sx={{ height: '100%', backgroundColor: 'selectedGrey.main' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '.5rem',
          paddingTop: '1rem',
          paddingBottom: '0.2rem',
        }}
      >
        <Box display="flex">
          <Box paddingX=".5rem" flex={1}>
            <TimelineDocumentViewSelector caseID={caseID} />
          </Box>
          <Box>
            <TimelineSettings />
          </Box>
        </Box>
        <Divider sx={{ marginBottom: '0.5rem', marginTop: '0.8rem' }} />
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              padding: '.5rem',
              paddingBottom: '1rem',
            }}
          >
            <NewSearchBar
              label="Search Documents"
              totalSearchOccurances={searchResultCounts?.totalCount}
              searchingInProgress={searchStr.length > 0 && searchingInProgress}
              searchStr={debounceInputHandler.value}
              handleChange={debounceInputHandler.onChange}
            />{' '}
            {!isFileProcessor && (
              <ConfigurableViewFilter
                onSubmit={handleFiltersChange}
                sortingOptions={[
                  {
                    value: ListPagesSortByEnum.DateAscending,
                    label: 'Date Ascending',
                  },
                  {
                    value: ListPagesSortByEnum.DateDescending,
                    label: 'Date Descending',
                  },
                  { value: ListPagesSortByEnum.Document, label: 'Document' },
                ]}
              />
            )}
          </Box>
          <Divider />
        </>
      </Box>

      <Box
        sx={{
          overflowY: 'auto',
          paddingBottom: '6.6rem',
        }}
        id="timeline-document-view-container"
      >
        <DocumentListContainer
          documentDescriptorQuery={documentDescriptorQuery}
          searchResults={searchResultCounts}
          caseID={caseID}
        />
      </Box>
    </Box>
  );
}
