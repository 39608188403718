import { useState } from 'react';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/system';

type Props = {
  boundingBox: { sx: number; sy: number; ex: number; ey: number };
  onClick?: () => void | null;
  rotation: number;
  selected?: boolean;
  sx?: SxProps<Theme>;
};

export default function ImageHighlight({ boundingBox, onClick, rotation, selected, sx }: Props) {
  const { top, left, width, height } = highlightArea(boundingBox, rotation);

  return (
    <Box
      id="screenshot-area"
      className={`react-rectangle-selection ${selected && 'note-screenshot-rotating-border'}`}
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: top,
        left: left,
        width: width,
        height: height,
        zIndex: 100,
        cursor: onClick ? 'pointer' : 'default',
        ...sx,
      }}
    />
  );
}

function highlightArea({ sx, sy, ex, ey }: Props['boundingBox'], rotation: number) {
  const top = sy;
  const left = sx;
  const width = ex - sx;
  const height = ey - sy;

  const overlayText = {
    top:
      rotation === 0
        ? `${top * 100}%`
        : rotation === 90
        ? `${left * 100}%`
        : rotation === 180
        ? `${(1 - top - height) * 100}%`
        : `${(1 - left - width) * 100}%`,
    left:
      rotation === 0
        ? `${left * 100}%`
        : rotation === 90
        ? `${(1 - top - height) * 100}%`
        : rotation === 180
        ? `${(1 - left - width) * 100}%`
        : `${top * 100}%`,
    width:
      rotation === 0
        ? `${width * 100}%`
        : rotation === 90
        ? `${height * 100}%`
        : rotation === 180
        ? `${width * 100}%`
        : `${height * 100}%`,
    height:
      rotation === 0
        ? `${height * 100}%`
        : rotation === 90
        ? `${width * 100}%`
        : rotation === 180
        ? `${height * 100}%`
        : `${width * 100}%`,
  };

  return overlayText;
}
