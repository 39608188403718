import { create } from 'zustand';

const useNavigationStore = create((set) => ({
  timeline: null,
  currentView: 'Timeline',

  setTimeline: (timeline) => set({ timelines: timeline }),

  setCurrentView: (view) => set({ currentView: view }),
}));

export default useNavigationStore;
