import React from 'react';

export default function DownArrowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      viewBox="0 0 15 16"
      fill={props.color ?? '#344054'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon points="7.5,11 11,7.5 4,7.5" />
    </svg>
  );
}
