import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import React from 'react';

export default function TimelineEntrySkeleton({
  style,
  index,
  entryID,
  countOfPages,
}: TimelineEntrySkeletonTypes) {
  return (
    <Box sx={{ ...style }} key={entryID}>
      <Card
        id={`skeleton-${index}`}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '95%',
          px: '0.75rem',
          py: '0.25rem',
          marginTop: '0.5rem',
          width: '98%',
          border: 'none',
          backgroundColor: 'white',
          height: '95%',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Skeleton variant="rounded" animation="wave" width={65} />
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Skeleton variant="rounded" animation="wave" width={45} sx={{ mr: '8px' }} />
            <Skeleton variant="rounded" animation="wave" width={10} />
          </Box>
        </Box>

        {Array.from(Array(Math.min(countOfPages, 5)).keys()).map((page) => (
          <Skeleton variant="rounded" animation="wave" width="90%" sx={{ mt: '8px' }} key={page} />
        ))}
      </Card>
    </Box>
  );
}

interface TimelineEntrySkeletonTypes {
  style: React.CSSProperties;
  index: number;
  entryID: string;
  countOfPages: number;
}
