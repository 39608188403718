import { useMemo } from 'react';
import { useOrganizationsQuery } from '../../../__generated__/graphql';
import formatEntities from '../../../library/utilities/useEntities';

const useOrgs = (caseID: string, entryID?: number, skip?: boolean) => {
  const query = useOrganizationsQuery({
    variables: {
      where: {
        case_id: caseID,
        entry_id: entryID,
      },
    },
    fetchPolicy: 'network-only',
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    skip,
  });
  const formattedOrgsForCase = query.data?.organizations?.organizationsForCase?.map((org) => {
    if (org.origin === 'ML') {
      return {
        ...org,
        value: formatEntities(org.value),
      };
    }
    return org;
  });

  const formattedOrgsForTimelineEntry =
    query.data?.organizations?.organizationsForTimelineEntry?.map((org) => {
      if (org.origin === 'ML') {
        return {
          ...org,
          value: formatEntities(org.value),
        };
      }
      return org;
    });

  const uniqueOrgs = new Set();
  const filteredOrgsForTimelineEntry = formattedOrgsForTimelineEntry?.filter((org) => {
    if (!uniqueOrgs.has(org.value)) {
      uniqueOrgs.add(org.value);
      return true;
    }
    return false;
  });
  const filteredOrgsForCase = formattedOrgsForCase?.filter((org) => {
    if (!uniqueOrgs.has(org.value)) {
      uniqueOrgs.add(org.value);
      return true;
    }
    return false;
  });

  const sortedOrgsForEntry = filteredOrgsForTimelineEntry
    ? filteredOrgsForTimelineEntry.sort((a, b) =>
        a.value?.toLowerCase() > b.value?.toLowerCase() ? 1 : -1,
      )
    : undefined;
  const sortedOrgsForCase = filteredOrgsForCase
    ? filteredOrgsForCase.sort((a, b) => (a.value?.toLowerCase() > b.value?.toLowerCase() ? 1 : -1))
    : [];

  return useMemo(
    () => ({
      ...query,
      data: {
        sortedOrgsForCase,
        sortedOrgsForEntry,
        allOrgs: formattedOrgsForCase,
      },
    }),
    [query],
  );
};

export default useOrgs;
