import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  PdfBitmap,
  PdfDocument,
  PdfPageOrientation,
  PdfPageSettings,
  SizeF,
} from '@syncfusion/ej2-pdf-export';
import apiUrl from '../../library/utilities/apiUrl';
import Loading from '../../components/common/Loading';
import DocumentEditor from '../../components/DocumentEditor';
import convertRemToPixels from '../../library/utilities/convertRemToPx';
import TemplateLockedModal from './TemplateLockedModal';
import { useUser } from '../../library/contexts/AuthContext';
import { useActivityLog } from '../../components/ActivityTracker/ActivityTracker';

function TemplateEditor() {
  const { templateId, templateName } = useParams();
  const { user } = useUser();
  const editorcontainer = useRef(null);

  const [loading, setLoading] = useState(true);
  const [templateLocked, setTemplateLocked] = useState(null);
  const [lockCheckError, setLockCheckError] = useState(false);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const logUserActivity = useActivityLog();

  const checkTemplateLocked = async () => {
    try {
      setLockCheckError(false);
      setTemplateLocked(
        !(
          await axios.post(`${apiUrl}checkTemplateLock`, {
            templateID: parseInt(templateId, 10),
          })
        )?.data?.access,
      );
    } catch (e) {
      setLockCheckError(true);
      setTemplateLocked(true);
    }
  };

  const removeTemplateLock = () =>
    axios.post(`${apiUrl}removeTemplateLock`, {
      templateID: parseInt(templateId, 10),
    });

  useEffect(() => {
    checkTemplateLocked();

    window.onbeforeunload = () => {
      removeTemplateLock();
    };

    return () => removeTemplateLock();
  }, []);

  useEffect(() => {
    function adjustWidth() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', adjustWidth);

    return () => {
      window.removeEventListener('resize', adjustWidth);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    logUserActivity({
      activity: 'report-template',
      report_template_id: templateId,
    });
  }, [templateId]);

  const uploadObject = {
    templateId: parseInt(templateId, 10),
    templateName,
    username: user.username,
  };

  async function exportPDF() {
    const editor = editorcontainer.current.documentEditor;
    const count = editor.pageCount;
    const pdfdocument = new PdfDocument();
    editor.documentEditorSettings.printDevicePixelRatio = 2;
    let loadedPage = 0;

    const exportPdf = (page, pageCount) => {
      const format = 'image/jpeg';
      // Getting pages as image
      const image = editor.exportAsImage(page, format);
      image.onload = () => {
        const imageHeight = parseInt(image.style.height.toString().replace('px', ''), 10);
        const imageWidth = parseInt(image.style.width.toString().replace('px', ''), 10);
        const section = pdfdocument.sections.add();
        const settings = new PdfPageSettings(0);
        if (imageWidth > imageHeight) {
          settings.orientation = PdfPageOrientation.Landscape;
        }
        settings.size = new SizeF(imageWidth, imageHeight);
        section.setPageSettings(settings);
        const page = section.pages.add();
        const { graphics } = page;
        const imageStr = image.src.replace('data:image/jpeg;base64,', '');
        const pdfImage = new PdfBitmap(imageStr);
        graphics.drawImage(pdfImage, 0, 0, imageWidth, imageHeight);
        loadedPage++;
        // Exporting the document as pdf
        if (loadedPage === pageCount) {
          pdfdocument.save(`${templateName}.pdf`);
        }
      };
    };

    for (let i = 1; i <= count; i++) {
      setTimeout(exportPdf(i, count), 500);
    }
  }

  async function exportWord() {
    editorcontainer.current.documentEditor.enableWordExport = true;
    editorcontainer.current.documentEditor.save(templateName, 'Docx');
  }

  useEffect(() => {
    // I deserve a wallop
    if (!loading) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [loading]);

  const toolbarClick = (args) => {
    switch (args.item.id) {
      case '_exportDocx':
        exportWord();
        break;
      case '_exportPDF':
        exportPDF();
        break;
      default:
        break;
    }
  };

  return (
    <>
      {templateLocked === null ||
        (loading && <Loading text="Loading the word processor..." sx={{ marginTop: '4px' }} />)}
      {templateLocked === false && (
        <div
          style={{
            width: windowWidth - convertRemToPixels(5),
            height: '100%',
          }}
        >
          <DocumentEditor
            ref={editorcontainer}
            loading={loading}
            setLoading={setLoading}
            uploadObject={uploadObject}
            isTemplate={true}
            toolbarClick={toolbarClick}
          />
        </div>
      )}

      {templateLocked && (
        <TemplateLockedModal
          open={templateLocked}
          problem={lockCheckError}
          checkIfTemplateIsLocked={checkTemplateLocked}
          setTemplateLocked={setTemplateLocked}
        />
      )}
    </>
  );
}

export default TemplateEditor;
