/* eslint-disable no-restricted-syntax */

import { DocumentEdge, PageObject } from '../../../api';

export type Edge = {
  type: DocumentEdge['type'];
  page_id: PageObject['id'];
  page_number: PageObject['page_number'];
};

export type EdgeDiff = {
  dismissing: Edge[];
  confirming: Edge[];
};

export function diffEdges(previous: Edge[], next: Edge[]): EdgeDiff {
  const oldEdgeCountsByPageID: Record<
    string,
    { starts: number; ends: number; page_number: Edge['page_number'] }
  > = {};
  const newEdgeCountsByPageID: Record<
    string,
    { starts: number; ends: number; page_number: Edge['page_number'] }
  > = {};
  for (const edge of previous) {
    oldEdgeCountsByPageID[edge.page_id] ??= { starts: 0, ends: 0, page_number: edge.page_number };
    if (edge.type === 'Start') {
      oldEdgeCountsByPageID[edge.page_id].starts += 1;
    } else {
      oldEdgeCountsByPageID[edge.page_id].ends += 1;
    }
  }
  for (const edge of next) {
    newEdgeCountsByPageID[edge.page_id] ??= { starts: 0, ends: 0, page_number: edge.page_number };
    if (edge.type === 'Start') {
      newEdgeCountsByPageID[edge.page_id].starts += 1;
    } else {
      newEdgeCountsByPageID[edge.page_id].ends += 1;
    }
  }

  const dismissing: Edge[] = [];
  const confirming: Edge[] = [];

  for (const pageId of Object.keys(oldEdgeCountsByPageID)) {
    const oldCounts = oldEdgeCountsByPageID[pageId] ?? { starts: 0, ends: 0 };
    const newCounts = newEdgeCountsByPageID[pageId] ?? { starts: 0, ends: 0 };

    const startDiff = oldCounts.starts - newCounts.starts;
    const endDiff = oldCounts.ends - newCounts.ends;

    for (let i = 0; i < startDiff; i += 1) {
      dismissing.push({
        type: 'Start',
        page_id: pageId,
        page_number: oldCounts.page_number,
      });
    }
    for (let i = 0; i < endDiff; i += 1) {
      dismissing.push({
        type: 'End',
        page_id: pageId,
        page_number: oldCounts.page_number,
      });
    }
  }

  for (const pageId of Object.keys(newEdgeCountsByPageID)) {
    const oldCounts = oldEdgeCountsByPageID[pageId] ?? { starts: 0, ends: 0 };
    const newCounts = newEdgeCountsByPageID[pageId] ?? { starts: 0, ends: 0 };

    const startDiff = newCounts.starts - oldCounts.starts;
    const endDiff = newCounts.ends - oldCounts.ends;

    for (let i = 0; i < startDiff; i += 1) {
      confirming.push({
        type: 'Start',
        page_id: pageId,
        page_number: newCounts.page_number,
      });
    }
    for (let i = 0; i < endDiff; i += 1) {
      confirming.push({
        type: 'End',
        page_id: pageId,
        page_number: newCounts.page_number,
      });
    }
  }

  return { dismissing, confirming };
}

export function compareEdges(a: DocumentEdge, b: DocumentEdge) {
  if (a.type === 'Start' && b.type === 'End') {
    return -1;
  }
  if (a.type === 'End' && b.type === 'Start') {
    return 1;
  }
  return 0;
}

export function removeDismissed(edge: DocumentEdge) {
  return edge.status !== 'Dismissed';
}
