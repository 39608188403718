import { blue } from '@mui/material/colors';
import Theme from '../../../theme';
import Loading from '../../common/Loading';

export default function PDFViewerPage({
  url,
  width,
  height,
  scale,
  rotate,
  onLoadSuccess = null,
  currentPage,
}) {
  if (!url) {
    return <Loading />;
  }
  return (
    <img
      src={url}
      alt="Document Page"
      id={url}
      onLoad={onLoadSuccess}
      loading="eager"
      crossOrigin="anonymous"
      style={{
        transform: `scale(${scale}) rotate(${rotate}deg)`,
        Drag: 'none',
        userSelect: 'none',
        MozUserSelect: 'none',
        WebkitUserDrag: 'none',
        WebkitUserSelect: 'none',
        MsUserSelect: 'none',
        pointerEvents: 'none',
        aspectRatio: 'auto 794 / 1064',
        height: height,
        width: width,
        border: currentPage ? `2px solid ${blue[900]}` : `2px solid #f2f5fc`,
        boxShadow: currentPage && '8px 8px 8px rgba(0, 0, 0, 0.1)',
        borderColor: currentPage ? blue[900] : Theme.palette.pageBackground,
        borderRadius: '5px',
      }}
    />
  );
}
