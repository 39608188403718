import React from 'react';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

const getStylesByPosition = (position) => {
  switch (position) {
    case 'right':
      return { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 };
    case 'left':
      return { borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: 0 };
    case 'middle':
      return { borderRadius: 0, borderRightWidth: 0, borderLeft: 0, borderRight: 0 };
    default:
      return {};
  }
};

const NavigationButton = React.forwardRef(
  ({ onClick, icon, disabled, position, sx, ...props }, ref) => {
    return (
      <IconButton
        ref={ref}
        onClick={onClick}
        variant="contained"
        size="small"
        disabled={disabled}
        sx={{
          backgroundColor: '#FFFFFF',
          color: '#344054',
          opacity: disabled && '60%',
          minWidth: 'fit-content',
          borderRadius: '6px',
          borderRadiusTopLeft: 0,
          border: '1px solid #D0D5DD',
          ...getStylesByPosition(position),
          width: '1.6rem',
          height: '1.6rem',
          '&:hover': { backgroundColor: '#dae1f5', boxShadow: 'none' },
          ...sx,
        }}
        {...props}
      >
        {icon}
      </IconButton>
    );
  },
);

export default NavigationButton;

NavigationButton.defaultProps = {
  onClick: null,
  icon: null,
  disabled: false,
};

NavigationButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
};
