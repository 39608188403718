import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const AppContext = React.createContext();

function AppContextProvider(props) {
  const [reportEditorOpen, setReportEditorOpen] = useState(false);
  const [notesSidebarOpen, setNotesSidebarOpen] = useState(true);
  const [templatePopupOpen, setTemplatePopupOpen] = useState(false);
  //Opens the duplicate comparison modal
  const [duplicatecomparisonOpen, setDuplicatecomparisonOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setReportEditorOpen(false);
    setDuplicatecomparisonOpen(false);
    setNotesSidebarOpen(true);
    setTemplatePopupOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    const notesOpen = JSON.parse(window.localStorage.getItem('notesSidebarOpen'));
    if (notesOpen === null || notesOpen === undefined) {
      window.localStorage.setItem('notesSidebarOpen', notesSidebarOpen);
    } else if (notesOpen !== notesSidebarOpen) {
      setNotesSidebarOpen(notesOpen);
    }
  }, [notesSidebarOpen, setNotesSidebarOpen]);

  useEffect(() => {
    const templatesOpen = JSON.parse(window.localStorage.getItem('templatePopupOpen'));
    if (templatesOpen === null || templatesOpen === undefined) {
      window.localStorage.setItem('templatePopupOpen', templatePopupOpen);
    } else if (templatesOpen !== templatePopupOpen) {
      setTemplatePopupOpen(templatePopupOpen);
    }
  }, [templatePopupOpen, setTemplatePopupOpen]);

  const state = {
    reportEditorOpen,
    setReportEditorOpen,
    notesSidebarOpen,
    setNotesSidebarOpen,
    duplicatecomparisonOpen,
    setDuplicatecomparisonOpen,
    templatePopupOpen,
    setTemplatePopupOpen,
  };
  return <AppContext.Provider value={state}>{props.children}</AppContext.Provider>;
}

export { AppContextProvider, AppContext };
