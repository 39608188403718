import { create } from 'zustand';

interface ScreenCaptureStore {
  screenCaptures: ScreenCaptureType[];
  addScreenCapture: (screenCapture: ScreenCaptureType) => void;
  setScreenCaptures: (screenCaptures: ScreenCaptureType[]) => void;
}

const useScreenCaptureStore = create<ScreenCaptureStore>((set) => ({
  screenCaptures: [],
  addScreenCapture: (screenCapture: ScreenCaptureType) =>
    set(({ screenCaptures }: ScreenCaptureStore) => ({
      screenCaptures: [...screenCaptures, screenCapture],
    })),
  setScreenCaptures: (screenCaptures: ScreenCaptureType[]) =>
    set(() => ({ screenCaptures: [...screenCaptures] })),
}));

export type ScreenCaptureType = {
  pageID: string;
  capturedText: string;
  coordinates: {
    sx: number;
    sy: number;
    ex: number;
    ey: number;
  };
};

export default useScreenCaptureStore;
