import { Box } from '@mui/material';
import PropTypes from 'prop-types';

export default function Truncate({ maxWidth, children }) {
  return (
    <Box
      sx={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '& > *': {
          display: 'inline',
        },
        display: 'flex',
        alignItems: 'center',
      }}
      maxWidth={maxWidth}
    >
      {children}
    </Box>
  );
}

Truncate.propTypes = {
  maxWidth: PropTypes.string,
  children: PropTypes.node,
};

Truncate.defaultProps = {
  maxWidth: '',
  children: undefined,
};
