import { useMemo } from 'react';
import { convertObjectToWhereInput } from '../../Notes/gql/gqlUtils';
import { useGetTimelineReportsQuery } from '../../../__generated__/graphql';
import { normalizeTimelineReport } from './timelineReportGqlUtils';

const useTimelineReports = (getTimelineReportParams: {
  id?: string;
  case_id?: string;
  timeline_id?: bigint;
}) => {
  const whereInput = useMemo(
    () => convertObjectToWhereInput(getTimelineReportParams),
    [getTimelineReportParams],
  );

  const { data, ...rest } = useGetTimelineReportsQuery({
    variables: { where: whereInput },
  });

  const index_report_enabled = process.env.REACT_APP_INDEX_REPORT_ENABLED === 'true';

  return useMemo(
    () => ({
      data: index_report_enabled ? data?.timeline_reports?.map(normalizeTimelineReport) ?? [] : [],
      ...rest,
    }),
    [data, index_report_enabled],
  );
};

export default useTimelineReports;
