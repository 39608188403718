/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Typography,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material/';
import TemplatePage from './TemplatePage';
import FileUploadBox from './FileUploadBox';
import HelpIcon from './HelpIcon';
import { handleFileUpload, handleBlankTemplateUpload } from './utils';
import { useUser } from '../../library/contexts/AuthContext';

type NewTemplateModalProps = {
  onModalClose: () => void;
  onCreateNewFile: () => Promise<void>;
  isOpen: boolean;
  templates: any[];
};

export default function NewTemplateModal({
  onModalClose,
  onCreateNewFile,
  isOpen,
  templates,
}: NewTemplateModalProps) {
  const [pageNum, setPageNum] = useState(0);
  const [isPrivate, setIsPrivate] = useState(false);
  const [Modal, setModal] = useState(() => <></>);
  const [templateName, setTemplateName] = useState('');
  const { user } = useUser();
  const [uploadDoc, setUploadDoc] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<{
    [key: string]: number;
  }>({});
  const theme = useTheme();

  const onModalCloseReset = () => {
    setPageNum(0);
    setIsPrivate(false);
    setModal(() => <></>);
    setTemplateName('');
    setUploadProgress({});
    setUploadDoc(false);
    onModalClose();
  };

  // Mostly useful in condition in the return statement below, but used instead of
  // .length in averageUploadProgress to take advantage of the memoization
  const uploadProgressLen = useMemo(() => Object.keys(uploadProgress).length, [uploadProgress]);

  const averageUploadProgress = useMemo(
    () =>
      uploadProgressLen &&
      Object.values(uploadProgress).reduce((a, b) => a + b) / uploadProgressLen,
    [uploadProgress],
  );

  const templateNames = useMemo(
    () => templates.map((template) => template.templateName),
    [templates],
  );

  const doesTemplateNameExist = useMemo(
    () => templateNames.includes(templateName),
    [templateNames, templateName],
  );

  const updateModal = useCallback(() => {
    if (pageNum === 1) {
      setModal(() => (
        <TemplatePage
          title="New Template"
          subtitle="Please select one of the options below."
          leftButtonText="Cancel"
          rightButtonText={uploadDoc ? 'Next' : 'Create Template'}
          isOpen={isOpen}
          onModalClose={onModalCloseReset}
          paperSx={{
            width: '25rem',
            height: '18rem',
          }}
          rightOnButtonClick={
            uploadDoc
              ? () => setPageNum(2)
              : async () => {
                  await handleBlankTemplateUpload(user, isPrivate, templateName);
                  await onCreateNewFile();
                  onModalCloseReset();
                }
          }
          rightButtonDisabled={false}
          Content={
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                paddingBottom: '1rem',
              }}
            >
              <RadioGroup defaultValue={false}>
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  onClick={() => setUploadDoc(false)}
                  label="Create blank template"
                />
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  onClick={() => setUploadDoc(true)}
                  label="Upload template"
                />
              </RadioGroup>
            </FormControl>
          }
        />
      ));
    } else if (pageNum === 2) {
      setModal(() => (
        <TemplatePage
          title="Upload Template"
          subtitle="Please upload a template below."
          leftButtonText="Cancel"
          rightButtonText="Create Template"
          isOpen={isOpen}
          onModalClose={onModalCloseReset}
          rightButtonDisabled={averageUploadProgress !== 100}
          rightOnButtonClick={async () => {
            onCreateNewFile();
            onModalCloseReset();
          }}
          paperSx={{
            width: '60rem',
            height: '20rem',
          }}
          Content={
            <FileUploadBox
              allowMultipleUploads={false}
              acceptedFormats={[
                'DOTX',
                'DOCX',
                'DOCM',
                'DOTM',
                'DOT',
                'DOC',
                'RTF',
                'HTML',
                'TXT',
                'XML',
                'ODT',
              ]}
              handleFileUpload={(files: FileList) => {
                handleFileUpload(user, files, setUploadProgress, isPrivate, templateName);
              }}
              setUploadProgress={setUploadProgress}
              averageUploadProgress={averageUploadProgress}
              uploadProgressLen={uploadProgressLen}
            />
          }
        />
      ));
    } else {
      const visibilitySx = {
        color: theme.palette.primary.light,
        '&:hover': {
          color: theme.palette.primary.main,
          cursor: 'pointer',
        },
        '&:active': {
          color: theme.palette.primary.main,
          cursor: 'pointer',
        },
      };
      setModal(() => (
        <TemplatePage
          title="Create a Template"
          subtitle={`Please fill out the required fields below and click "Create Template."`}
          leftButtonText="Cancel"
          rightButtonText="Create Template"
          isOpen={isOpen}
          onModalClose={onModalCloseReset}
          rightButtonDisabled={templateName === '' || doesTemplateNameExist}
          rightOnButtonClick={() => setPageNum(1)}
          paperSx={{ width: '45rem', height: '18rem' }}
          Content={
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '0.75fr 1.25fr',
                gridTemplateRows: '1fr 1fr',
                rowGap: '1rem',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h6"
                style={{
                  gridRowStart: '1',
                  gridColumnStart: '1',
                }}
              >
                Template Name*
              </Typography>
              <TextField
                placeholder="My Template"
                sx={{
                  height: '2rem',
                  fontSize: '1rem',
                  padding: 0,
                  gridRowStart: '1',
                  gridColumnStart: '2',
                }}
                error={doesTemplateNameExist}
                onChange={(e) => setTemplateName(e.target.value)}
              />
              <div
                style={{
                  gridRowStart: '2',
                  gridColumnStart: '1',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6">Visibility</Typography>
                <HelpIcon helpText="Public templates are visible to all users in your organization. Private templates are only visible to you." />
              </div>
              <div
                style={{
                  gridRowStart: '2',
                  gridColumnStart: '2',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {isPrivate ? (
                  <VisibilityOffOutlined onClick={() => setIsPrivate(false)} sx={visibilitySx} />
                ) : (
                  <VisibilityOutlined onClick={() => setIsPrivate(true)} sx={visibilitySx} />
                )}
                <Typography
                  variant="h6"
                  style={{
                    marginLeft: '0.25rem',
                  }}
                >
                  {isPrivate ? 'Private' : 'Public'}
                </Typography>
              </div>
            </div>
          }
        />
      ));
    }
  }, [
    pageNum,
    isPrivate,
    isOpen,
    onModalClose,
    onCreateNewFile,
    templateName,
    uploadDoc,
    uploadProgress,
  ]);

  useEffect(() => {
    updateModal();
  }, [updateModal]);

  return Modal;
}
