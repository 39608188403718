import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Box,
  Backdrop,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { BulkUpdateTimelineEntriesDocument } from '../../__generated__/graphql';

type BulkApplyAllProps = {
  bulkDialog: boolean;
  setbulkDialog: React.Dispatch<React.SetStateAction<boolean>>;
  currentTimelineEntry: any;
  timelineEntries: any[];
};

const sanitizeTags = (tags: { id: any }[]) => {
  if (!tags) {
    return [];
  }
  return tags.map(({ id }) => ({ id }));
};

function BulkApplyAll(props: BulkApplyAllProps) {
  const { bulkDialog, setbulkDialog, currentTimelineEntry, timelineEntries } = props;
  const [bulkUpdateTimelineEntries, { loading }] = useMutation(BulkUpdateTimelineEntriesDocument);

  const [checkedAttributes, setCheckedAttributes] = useState({
    author: false,
    org: false,
    entryDate: false,
    sourceID: false,
    contentTags: false,
    specialityTags: false,
  });

  const handleCheckboxChange = (attribute: string) => {
    setCheckedAttributes({
      ...checkedAttributes,
      [attribute]: !checkedAttributes[attribute],
    });
  };

  const handleBulkApply = async () => {
    try {
      const { author_id, org_id, entryDate, sourceID, pages } = currentTimelineEntry;
      const timelineId = currentTimelineEntry.id;

      // Check if the attributes to be applied are not null
      if (
        (checkedAttributes.author && author_id === null) ||
        (checkedAttributes.org && org_id === null) ||
        (checkedAttributes.entryDate && entryDate === null) ||
        (checkedAttributes.sourceID && sourceID === null)
      ) {
        toast.error('Cannot apply null attributes');
        return;
      }

      const sanitizedCurrentTags = sanitizeTags(pages.flatMap((page: any) => page.tags));
      const sanitizedSpecialityTags = sanitizeTags(
        pages.flatMap((page: any) => page.specialityTags || []),
      );

      const updates = timelineEntries.map((entry) => {
        const update: any = {
          entryId: entry.id,
          pages: entry.pages.map((page: any) => ({
            pageId: page.id,
            tags: [],
          })),
        };

        if (checkedAttributes.author) {
          update.authorId = author_id ?? null;
        }
        if (checkedAttributes.org) {
          update.orgId = org_id ?? null;
        }
        if (checkedAttributes.entryDate) {
          update.entryDate = entryDate ?? null;
        }
        if (checkedAttributes.sourceID) {
          update.sourceID = sourceID !== null ? String(sourceID) : null;
        }
        if (checkedAttributes.contentTags) {
          update.pages.forEach((page: any) => {
            page.tags = sanitizedCurrentTags;
          });
        }
        if (checkedAttributes.specialityTags) {
          update.pages.forEach((page: any) => {
            page.tags = page.tags.concat(sanitizedSpecialityTags);
          });
        }
        return update;
      });

      const response = await bulkUpdateTimelineEntries({
        variables: {
          input: { timelineId, updates },
        },
      });

      if (response.data.bulkUpdateTimelineEntries.success) {
        toast.success('Bulk apply of all attributes successful');
        setTimeout(() => {
          location.reload();
        }, 1000);
      } else {
        toast.error('Bulk update failed');
        console.error('Bulk update failed:', response.data.bulkUpdateTimelineEntries.message);
      }
      setbulkDialog(false);
    } catch (error) {
      console.error('Error applying tags:', error);
      toast.error('Error applying tags');
    }
  };

  return (
    <>
      {loading && (
        <Backdrop open={true} style={{ zIndex: 9999, color: '#fff' }}>
          <Box position="relative" display="flex" flexDirection="column" alignItems="center">
            <CircularProgress color="inherit" />
            <Typography
              variant="h6"
              component="div"
              color="textPrimary"
              sx={{ mt: 1, fontWeight: 'bold' }}
            >
              Updating all entries...
            </Typography>
          </Box>
        </Backdrop>
      )}
      <Button onClick={() => setbulkDialog(true)} sx={{ fontSize: '0.8rem', padding: '0' }}>
        Bulk Apply All
      </Button>
      {!loading && (
        <Dialog open={bulkDialog} onClose={() => setbulkDialog(false)}>
          <DialogTitle>Confirm Bulk Apply</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Select the attributes you want to apply from the current timeline entry to all other
              timeline entries within the file.
            </DialogContentText>
            <Box display="flex" flexDirection="column">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedAttributes.author}
                    onChange={() => handleCheckboxChange('author')}
                  />
                }
                label="Author"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedAttributes.org}
                    onChange={() => handleCheckboxChange('org')}
                  />
                }
                label="Organization"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedAttributes.entryDate}
                    onChange={() => handleCheckboxChange('entryDate')}
                  />
                }
                label="Entry Date"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedAttributes.sourceID}
                    onChange={() => handleCheckboxChange('sourceID')}
                  />
                }
                label="Source"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setbulkDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleBulkApply} color="primary" disabled={loading}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default BulkApplyAll;
