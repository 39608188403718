import React, { useEffect, useState } from 'react';
import {
  Chip,
  ClickAwayListener,
  Grow,
  Box,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  Divider,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import { AddCircleOutline, MoreVert } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PropTypes from 'prop-types';
import Tag from './Tag';
import tagColors from './tagColors';
import Loading from '../../components/common/Loading';
import {
  useAddTagToNoteMutation,
  useCustomTagsQuery,
  useUpdateCustomTagMutation,
} from '../../__generated__/graphql';
import useCreateCustomTag from '../Notes/gql/useCreateCustomTag';
import useDeleteCustomTag from '../Notes/gql/useDeleteCustomTag';
import { useUser } from '../../library/contexts/AuthContext';

function AddTagButton({
  text,
  noteID,
  tags = [],
  // these manipulate the tags in the parent component
  onClick = null,
  onNewTag = null,
  onDelete = null,
  onUpdate = null,
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [customizingTagsOpen, setCustomizingTagsOpen] = useState(false);
  const [tagBeingCustomized, setTagBeingCustomized] = useState(null);
  const anchorRef = React.useRef(null);
  const customizeAnchorRef = React.useRef(null);
  const { userId } = useUser();
  const [addTagToNote] = useAddTagToNoteMutation();
  const [createTag] = useCreateCustomTag();
  const { data, loading: isLoading } = useCustomTagsQuery({
    variables: {
      where: {
        userID: { equals: userId },
      },
    },
  });
  const allTags = data?.custom_tags;

  //Toggle the menu open/closed
  const handleToggle = () => {
    if (!menuOpen) {
      setSearchValue('');
    }
    setMenuOpen((prevOpen) => !prevOpen);
  };
  //Close the menu and drop the location ref
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (customizingTagsOpen) {
      return;
    }
    setCustomizingTagsOpen(false);
    setMenuOpen(false);
  };

  const handleClick = (tag) => {
    if (onClick) {
      onClick(tag);
    } else {
      addTagToNote({ variables: { tag_id: tag.tagID, note_id: noteID } });
    }
    setCustomizingTagsOpen(false);
    setMenuOpen(false);
  };

  const handleNewTag = async (tag) => {
    setMenuOpen(false);
    setCustomizingTagsOpen(false);
    setSearchValue('');
    const { data: createdTag } = await createTag({
      variables: { data: { ...tag, userID: userId } },
    });

    if (onNewTag) {
      onNewTag(createdTag.createCustomTag);
    } else {
      await addTagToNote({
        variables: {
          tag_id: createdTag.createCustomTag.tagID,
          note_id: noteID,
        },
      });
    }
  };

  const tagsToRender =
    allTags
      ?.filter(
        (t) =>
          tags.findIndex((tag) => tag.tagID === t.tagID) === -1 &&
          t.title.toUpperCase().trim().includes(searchValue.toUpperCase().trim()),
      )
      .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)) || [];

  const disabledTags =
    allTags
      ?.filter(
        (t) =>
          tags.findIndex((tag) => tag.tagID === t.tagID) !== -1 &&
          t.title.toUpperCase().trim().includes(searchValue.toUpperCase().trim()),
      )
      .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)) || [];

  const handleEditTag = (tag) => {
    if (tagBeingCustomized?.tagID === tag?.tagID) {
      setCustomizingTagsOpen(!customizingTagsOpen);
    } else {
      setTagBeingCustomized(tag);
      setCustomizingTagsOpen(true);
    }
  };

  const handleCustomizeClose = (event) => {
    if (customizeAnchorRef.current && customizeAnchorRef.current.contains(event.target)) {
      return;
    }
    setCustomizingTagsOpen(false);
  };

  return (
    <>
      <div
        ref={anchorRef}
        aria-controls={menuOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        style={{ display: 'inline', marginTop: -3 }}
      >
        {text ? (
          <Chip
            onClick={handleToggle}
            variant="filled"
            label="Add Tag"
            onDelete={handleToggle}
            deleteIcon={<AddIcon onClick={handleToggle} sx={{ color: 'white !important' }} />}
            size="small"
            sx={{
              marginTop: 1.5,
              fontSize: '0.8rem',
              padding: '0px',
              cursor: 'pointer',
              color: 'white',
              backgroundColor: '#254580',
              '&:hover': {
                backgroundColor: '#254580',
                opacity: '90%',
              },
            }}
          />
        ) : (
          <IconButton onClick={handleToggle} size="small">
            <AddCircleOutline
              fontSize="small"
              sx={{
                cursor: 'pointer',
                color: '#254580',
                '& :hover': {
                  color: '#254580',
                },
              }}
            />
          </IconButton>
        )}
      </div>

      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        ref={customizeAnchorRef}
        role={undefined}
        transition
        style={{ zIndex: 1400 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper variant="outlined">
              <ClickAwayListener onClickAway={handleClose}>
                {isLoading ? (
                  <Loading />
                ) : (
                  <Box>
                    <TextField
                      name="tag-search"
                      placeholder="Create Tag or Search"
                      onChange={(e) => setSearchValue(e.target.value)}
                      focused={false}
                      value={searchValue}
                      sx={{
                        height: 32,
                        margin: '8px',
                        mt: '12px',
                        marginBottom: '0px',
                      }}
                      inputProps={{
                        style: {
                          height: 32,
                          padding: '0 14px',
                          fontSize: '12px',
                        },
                      }}
                    />
                    <MenuList id="menu-list-grow" sx={{ maxHeight: '300px', overflow: 'auto' }}>
                      {tagsToRender.map((tag) => (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            backgroundColor:
                              customizingTagsOpen && tagBeingCustomized?.tagID === tag.tagID
                                ? '#e3e3e3'
                                : 'inherit',
                          }}
                          key={tag.tagID}
                        >
                          <MenuItem
                            key={tag.tagID}
                            onClick={() => handleClick(tag)}
                            sx={{
                              flex: 90,
                            }}
                          >
                            <Tag tag={tag} style={{ cursor: 'pointer' }} />
                          </MenuItem>
                          <IconButton size="small" onClick={() => handleEditTag(tag)}>
                            <MoreVert sx={{ fontSize: '18px' }} />
                          </IconButton>
                        </Box>
                      ))}
                      {disabledTags.map((tag) => (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            backgroundColor:
                              customizingTagsOpen && tagBeingCustomized?.tagID === tag.tagID
                                ? '#e3e3e3'
                                : 'inherit',
                          }}
                          key={tag.tagID}
                        >
                          <MenuItem
                            key={tag.tagID}
                            onClick={() => handleClick(tag)}
                            sx={{
                              flex: 90,
                            }}
                            disabled
                          >
                            <Tag tag={tag} style={{ cursor: 'pointer' }} />
                          </MenuItem>
                          <IconButton size="small" onClick={() => handleEditTag(tag)}>
                            <MoreVert sx={{ fontSize: '18px' }} />
                          </IconButton>
                        </Box>
                      ))}
                      {searchValue.length > 0 && (
                        <MenuItem
                          key="new"
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingRight: '0',
                          }}
                          onClick={() =>
                            handleNewTag({
                              title: searchValue.trim(),
                              color: 'blue',
                            })
                          }
                        >
                          <Tag
                            style={{ cursor: 'pointer' }}
                            tag={{
                              tagID: 'new',
                              title: searchValue,
                              color: 'blue',
                            }}
                          />
                          <IconButton size="small">
                            <AddIcon sx={{ fontSize: '18px' }} />
                          </IconButton>
                        </MenuItem>
                      )}
                    </MenuList>
                  </Box>
                )}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <CustomizationMenu
        open={customizingTagsOpen}
        tag={tagBeingCustomized}
        onClose={handleCustomizeClose}
        onUpdate={onUpdate}
        customizeAnchorRef={customizeAnchorRef}
        onDelete={onDelete}
        manualClose={() => setCustomizingTagsOpen(false)}
      />
    </>
  );
}

function CustomizationMenu({
  open,
  tag,
  onClose,
  onUpdate,
  onDelete,
  manualClose,
  customizeAnchorRef,
}) {
  const [label, setLabel] = useState(tag?.title || '');
  const [selectedColor, setSelectedColor] = useState(tag?.color || 'grey');
  const [updateTag] = useUpdateCustomTagMutation();
  const [deleteTag] = useDeleteCustomTag();

  useEffect(() => {
    setLabel(tag?.title || '');
    setSelectedColor(tag?.color || 'grey');
  }, [tag]);

  const onUpdateTag = (tag) => {
    updateTag({
      variables: {
        data: {
          title: tag.title,
          color: tag.color,
        },
        tag_id: tag.tagID,
      },
    });
    if (onUpdate) {
      onUpdate(tag);
    }
  };
  const updateColor = (color) => {
    setSelectedColor(color);
    onUpdateTag({ ...tag, title: label, color });
  };

  const updateTitle = (e) => {
    setLabel(e.target.value);
    onUpdateTag({ ...tag, color: selectedColor, title: e.target.value.trim() });
  };

  const onDeleteTag = () => {
    deleteTag({
      variables: {
        tag_id: tag.tagID,
      },
    });
    manualClose();
    if (onDelete) {
      onDelete(tag);
    }
  };

  return (
    <Popper
      open={open}
      anchorEl={customizeAnchorRef.current}
      role={undefined}
      transition
      style={{ zIndex: 1400 }}
      placement="right-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'right start' : 'center bottom',
          }}
        >
          <Paper variant="outlined">
            <ClickAwayListener onClickAway={onClose}>
              <Box>
                <TextField
                  name="tag-search"
                  placeholder="Tag Label"
                  onChange={updateTitle}
                  focused={false}
                  value={label}
                  sx={{
                    height: 32,
                    margin: '8px',
                    marginBottom: '0px',
                    mt: '12px',
                  }}
                  InputLabelProps={{
                    style: {
                      height: 32,
                      fontSize: '12px',
                    },
                  }}
                  inputProps={{
                    style: {
                      height: 32,
                      padding: '0 14px',
                      fontSize: '12px',
                    },
                  }}
                />

                <MenuList id="menu-list-grow-colors" sx={{ maxHeight: '300px', overflow: 'auto' }}>
                  {Object.keys(tagColors).map((color) => (
                    <MenuItem
                      key={color}
                      sx={{
                        height: '28px',
                        padding: '0px',
                        paddingLeft: '4px',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      onClick={() => {
                        updateColor(color);
                      }}
                    >
                      <Box sx={{ display: 'flex' }}>
                        <svg height="28" width="28" style={{ marginRight: '.75rem' }}>
                          <circle cx="14" cy="14" r="10" fill={tagColors[color]} />
                        </svg>
                        <Box
                          sx={{
                            fontSize: '14px',
                            lineHeight: '28px',
                            textTransform: 'capitalize',
                          }}
                        >
                          {color}
                        </Box>
                      </Box>
                      {selectedColor === color && (
                        <CheckIcon sx={{ fontSize: '20px', mr: '8px' }} />
                      )}
                    </MenuItem>
                  ))}
                </MenuList>
                <Divider sx={{ marginTop: '4px', marginBottom: '4px' }} />

                <Box
                  sx={{
                    display: 'flex',
                    margin: '4px',
                    height: '32px',
                    cursor: 'pointer',
                    width: 'fit-content',
                  }}
                  onClick={onDeleteTag}
                >
                  <DeleteOutlineIcon
                    sx={{
                      fontSize: '18px',
                      margin: 'auto 0',
                      color: tagColors.blue,
                    }}
                  />
                  <Box
                    sx={{
                      fontSize: '14px',
                      lineHeight: '32px',
                      color: tagColors.blue,
                    }}
                  >
                    Delete Tag
                  </Box>
                </Box>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

AddTagButton.propTypes = {
  text: PropTypes.bool,
  noteID: PropTypes.string,
  tags: PropTypes.array,
  onClick: PropTypes.func,
  handleNewTag: PropTypes.func,
};
export default AddTagButton;
