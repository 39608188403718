import { useIsFetching, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiUrl from '../../library/utilities/apiUrl';
import { createNormalizedSelectorWithFallback } from '../../library/utilities/reactQueryUtils';
import { tiemlineListSchema, timelineSchema } from './stateSchemas';

const queryDefaults = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

/**
 * @deprecated substituted by GQL powered hook - `useTimelineEntries()`
 */
async function fetchTimeline(caseID, timelineID) {
  return (await axios.get(`${apiUrl}case/${caseID}/timeline/${timelineID}`)).data;
}

/**
 * @deprecated - use GQL API instead!
 */
export default function useTimeline(caseID, timelineID, select) {
  const queryClient = useQueryClient();

  return useQuery(['timeline', caseID], () => fetchTimeline(caseID, timelineID), {
    ...queryDefaults,
    enabled: !!timelineID,
    staleTime: Infinity,
    meta: {
      schema: timelineSchema,
    },
    select: createNormalizedSelectorWithFallback({
      queryKey: ['timeline', caseID],
      queryClient,
      select,
    }),
  });
}

export function useIsTimelineLoading() {
  return Boolean(
    useIsFetching({
      predicate: (query) => query.state === 'loading',
      queryKey: ['timelineList'],
    }) +
      useIsFetching({
        predicate: (query) => query.state === 'loading',
        queryKey: ['timeline'],
      }),
  );
}

//TIMELINE LIST

async function fetchTimelineList(caseID) {
  return (await axios.post(`${apiUrl}getOneCase`, { caseID })).data?.timelines;
}

export function useTimelineList(caseID) {
  return useQuery(['timelineList', caseID], () => fetchTimelineList(caseID), {
    ...queryDefaults,
    schema: tiemlineListSchema,
  });
}

export function deleteTimeline(caseID, timelineID) {
  return axios.delete(`${apiUrl}case/${caseID}/timeline/${timelineID}`);
}

export function useDeleteTimeline() {
  const queryClient = useQueryClient();

  return useMutation(({ caseID, timelineID }) => deleteTimeline(caseID, timelineID), {
    mutationKey: 'timelineList__deleteTimeline',
    onSuccess: (data, { caseID }) => {
      queryClient.invalidateQueries(['timelineList', caseID]);
    },
    onError: (error) => {
      toast.error('Failed to delete timeline.', {
        toastId: 'timeline-delete',
      });
      throw error;
    },
  });
}

export function createTimeline(caseID, name) {
  return axios.post(`${apiUrl}case/${caseID}/timeline`, { name });
}

export function useCreateTimeline() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(({ caseID, name }) => createTimeline(caseID, name), {
    mutationKey: 'timelineList__createTimeline',
    onSuccess: (data, { caseID }) => {
      queryClient.invalidateQueries(['timelineList', caseID]);
      navigate(`/case/${caseID}/timeline/${data.data[0].id}`);
    },
    onError: (error) => {
      toast.error('Error creating new timeline.', {
        toastId: 'timeline-create',
      });
      throw error;
    },
  });
}

export function updateTimeline(caseID, timelineID, name) {
  return axios.post(`${apiUrl}case/${caseID}/timeline/${timelineID}/update`, {
    name,
  });
}

export function useUpdateTimeline() {
  const queryClient = useQueryClient();

  return useMutation(({ caseID, timelineID, name }) => updateTimeline(caseID, timelineID, name), {
    mutationKey: 'timelineList__updateTimeline',
    onSuccess: (data, { caseID }) => {
      queryClient.invalidateQueries(['timelineList', caseID]);
    },
    onError: (error) => {
      toast.error('Error updating timeline.', {
        toastId: 'timeline-update',
      });
      throw error;
    },
  });
}

//TIMELINE ENTRIES

export function deleteCase({ userID, caseID }) {
  return axios.post(`${apiUrl}deleteCase`, { caseID: caseID, userID: userID });
}
