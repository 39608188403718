import { Box, MenuItem, Select } from '@mui/material';
import useNotesStore from '../../../Notes/useNotesStore';
import FilterControl from '../FilterControl';
import { FilterInputLabel } from '../ConfigurableViewFilter';
import { NotesListOrderEnum } from '../../../Notes/types/noteTypes';

export default function OrderFilter() {
  const notesOrder = useNotesStore((state) => state.notesOrder);
  const setNotesOrder = useNotesStore((state) => state.setNotesOrder);

  const handleOrderChange = (e) => {
    window.localStorage.setItem('notesOrder', e.target.value);
    setNotesOrder(e.target.value);
  };

  return (
    <>
      <Box ml={1}>
        <FilterInputLabel>Order By</FilterInputLabel>
      </Box>
      <FilterControl
        control={
          <Select
            // disableUnderline Why this here? React warns tha this in an invalid prop
            value={notesOrder}
            onChange={handleOrderChange}
            sx={{
              width: 160,
              paddingRight: 1,
            }}
            MenuProps={{
              getcontentanchorel: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'menu',
            }}
          >
            {Object.values(NotesListOrderEnum).map((option) => (
              <MenuItem key={option} value={option} style={{ height: 36 }}>
                <div style={{ fontSize: '0.875rem' }}>{option}</div>
              </MenuItem>
            ))}
          </Select>
        }
        toolTipTitle="Select the order in which notes are rendered."
      />
    </>
  );
}
