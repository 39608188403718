import { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import NavigateNext from '@mui/icons-material/NavigateNext';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { gql, useApolloClient } from '@apollo/client';

function FileViewer({ caseDocuments, caseID, timelineID }: any) {
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const navigate = useNavigate();
  const apolloClient = useApolloClient();
  const { pageID } = useParams();
  const [searchParams] = useSearchParams();
  const documentID = searchParams.get('documentID');
  const currentFile = caseDocuments[currentFileIndex];

  useEffect(() => {
    const currentIndex = caseDocuments.findIndex(
      (doc: { documentID: string }) => doc.documentID === documentID,
    );
    if (currentIndex !== -1) {
      setCurrentFileIndex(currentIndex);
    }
  }, [documentID, caseDocuments]);

  const handleNext = () => {
    if (currentFileIndex < caseDocuments.length - 1) {
      const nextFileIndex = currentFileIndex + 1;
      setCurrentFileIndex(nextFileIndex);
      navigateToFile(nextFileIndex);
    }
  };

  const handlePrevious = () => {
    if (currentFileIndex > 0) {
      const previousFileIndex = currentFileIndex - 1;
      setCurrentFileIndex(previousFileIndex);
      navigateToFile(previousFileIndex);
    }
  };

  const fetchPageDetails = (pageID: string | undefined) => {
    return apolloClient.cache.readFragment({
      id: apolloClient.cache.identify({ id: pageID, __typename: 'PageObject' }),
      fragment: gql`
        fragment SelectorPageDetails on PageObject {
          documentID: document_id
          entryID: timeline_entry_id
        }
      `,
    });
  };

  const navigateToFile = (index: number) => {
    const file = caseDocuments[index];
    const pageDetails = fetchPageDetails(pageID);

    const url = pageDetails.entryID
      ? `/case/${caseID}/timeline/${timelineID}/${pageDetails.entryID}/${pageID}?documentID=${file.documentID}`
      : `/case/${caseID}/timeline/${timelineID}`;

    navigate(url, { replace: true });
  };

  const formatFileStatus = (status: string) => {
    if (!status) {
      return '';
    }
    if (status === 'PENDING') {
      status = 'NOT STARTED';
    }
    return status
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0.15rem',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        marginLeft: '0.25rem',
        fontSize: '0.5rem',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '0.1rem' }}>
        <Button
          variant="outlined"
          onClick={handlePrevious}
          disabled={currentFileIndex === 0}
          sx={{ minWidth: '6px', padding: '0.02rem', marginRight: '0.05rem', fontSize: '0.5rem' }}
        >
          <NavigateBefore fontSize="small" />
        </Button>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body1" sx={{ fontSize: '0.5rem' }}>
            {currentFileIndex + 1}/{caseDocuments.length} - {currentFile?.docFileName}
          </Typography>
          <Box
            sx={{
              backgroundColor: 'red',
              padding: '0.12rem',
              borderRadius: '4px',
              display: 'inline-block',
              marginTop: '0.05rem',
            }}
          >
            <Typography variant="body1" sx={{ fontSize: '0.7rem', color: 'white' }}>
              {formatFileStatus(currentFile?.fileStatus)}
            </Typography>
          </Box>
        </Box>
        <Button
          variant="outlined"
          onClick={handleNext}
          disabled={currentFileIndex === caseDocuments.length - 1}
          sx={{ minWidth: '6px', padding: '0.02rem', marginLeft: '0.05rem', fontSize: '0.5rem' }}
        >
          <NavigateNext fontSize="small" />
        </Button>
      </Box>
    </Box>
  );
}

export default FileViewer;
