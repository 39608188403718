import Theme from '../../theme';

export default function CloseIcon(props) {
  const { color = Theme.palette.mediumGrey.main, size = '24', ...restProps } = props;
  return (
    <svg
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      viewBox="0 0 24 24"
      fill="none"
      width={size}
      height={size}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      cursor="pointer"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18 6L6 18" />
      <path d="M6 6L18 18" />
    </svg>
  );
}
