export default function formatEntities(entity) {
  if (entity && entity.length > 0) {
    // List of words that should not be capitalized
    const exceptions = ['and', 'is', 'the', 'of', 'in', 'on', 'at', 'to', 'for', 'with', 'a', 'an'];

    // Split the entity into words and process each word
    return entity
      .toLowerCase()
      .split(' ')
      .map((word, index) => {
        if (index === 0 || !exceptions.includes(word)) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }
        return word;
      })
      .join(' ');
  }
  return '';
}
