import { Routes, Route } from 'react-router-dom';
import TemplateEditor from './TemplateEditor';
import TemplateSelection from './TemplateSelection';

function TemplateContainer() {
  return (
    <Routes>
      <Route path="*" element={<TemplateSelection />} />
      <Route
        path="/:templateId/:templateName"
        name="Document Display"
        element={<TemplateEditor />}
      />
    </Routes>
  );
}

export default TemplateContainer;
