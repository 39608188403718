import { HelpOutline } from '@mui/icons-material';
import { Tooltip, useTheme } from '@mui/material';
import { useState } from 'react';
import { SxProps, Theme } from '@mui/system';

type HelpIconProps = {
  helpText: string;
  sx?: SxProps<Theme>
};

export default function HelpIcon({ helpText, sx }: HelpIconProps) {
  const theme = useTheme();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  return (
    <Tooltip open={tooltipOpen} title={helpText} placement="right" arrow>
      <HelpOutline
        sx={{
          fontSize: '1rem',
          verticalAlign: 'text-bottom',
          marginLeft: '0.5rem',
          color: theme.palette.primary.main,
          '&:hover': {
            color: theme.palette.modernGrey.default,
            cursor: 'pointer',
          },
          '&:active': {
            color: theme.palette.modernGrey.default,
            cursor: 'pointer',
          },
          ...sx
        }}
        onClick={() => setTooltipOpen(!tooltipOpen)}
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}
      />
    </Tooltip>
  );
}
