import { memo, useMemo, useCallback, useEffect, useRef } from 'react';
import { areEqual, FixedSizeGrid as Grid } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import useDisplayStore from '../useDisplayStore';
import SmallPageCard from './SmallPageCard';
import useTimelineStore from '../useTimelineStore';

const gap = 16;

const calculateRowHeight = (thumbnailHeight) => thumbnailHeight + 10;

export default function PageGridView(props) {
  const {
    pages,
    count,
    searchMatches,
    currentPageID,
    isInTimelineView,
    onLoadMore,
    pageControls,
    notes,
    currentDocumentID,
    isFileProcessor,
    sources,
  } = props;

  const gridRef = useRef(null);
  const gridWidth = useDisplayStore((state) => state.getGridWidth());
  const thumbnailHeight = useDisplayStore((state) => state.getThumbnailHeight());
  const thumbnailWidth = useDisplayStore((state) => state.getThumbnailWidth());
  const thumbnailsPerRow = useDisplayStore((state) => state.getThumbnailsPerRow());
  const listHeight = useDisplayStore((state) => state.getThumbnailGridListHeight(pages.length));

  // eslint-disable-next-line
  const renderThumbnailPage = memo(({ columnIndex, rowIndex, style }) => {
    const index = thumbnailsPerRow * rowIndex + columnIndex;
    const page = pages[index];
    const isCurrentPage = useMemo(() => page?.id === Number(currentPageID), [page, currentPageID]);
    const notesInPage = useMemo(
      () =>
        notes?.filter(
          (note) => note.pageNumber === page?.pageNumber && note.documentID === page?.documentID,
        ),
      [],
    );
    const isPageSelected = useTimelineStore((state) => state.isPageSelected(page?.id));
    const areMaximumPagesForSplitSelected = useTimelineStore(
      (state) => pages && state.areMaximumPagesForSplitSelected(pages),
    );
    if (!page) {
      return null;
    }
    return (
      <div style={style} id={`page-${page.id}`}>
        <SmallPageCard
          key={page.id}
          page={page}
          pages={pages}
          customStyle={style}
          isInTimelineView={isInTimelineView}
          searchMatches={searchMatches?.[page.pageNumber.toString()]}
          pageControls={pageControls}
          isCurrentPage={isCurrentPage}
          noteCount={notesInPage.length}
          currentDocumentID={currentDocumentID}
          showThumbnails
          isFileProcessor={isFileProcessor}
          sources={sources}
          isPageSelected={isPageSelected}
          areMaximumPagesForSplitSelected={areMaximumPagesForSplitSelected}
        />
      </div>
    );
  }, areEqual);

  const isPageLoaded = useCallback(
    (rowIndex) => {
      const startOfRowPageIndex = rowIndex * thumbnailsPerRow;
      const endOfRowPageIndex = startOfRowPageIndex + thumbnailsPerRow - 1;

      for (let i = startOfRowPageIndex; i <= endOfRowPageIndex; i++) {
        if (!pages?.[i]) {
          return false;
        }
      }

      return true;
    },
    [pages],
  );

  const loadMorePages = useCallback(
    (from, to) => {
      from *= thumbnailsPerRow;
      to *= thumbnailsPerRow;

      console.log('[PageGridView] Load more pages: ', { from, to });

      if (typeof onLoadMore === 'function') {
        onLoadMore(from, to);
      }
    },
    [onLoadMore],
  );

  const numberOfRows = Math.ceil(count / thumbnailsPerRow);

  useEffect(() => {
    const index = pages.find((page) => page.id === currentPageID);
    if (index === -1) {
      return;
    }

    if (index && gridRef.current) {
      const row = Math.floor(index / thumbnailsPerRow);

      gridRef.current.scrollTo({
        top: row * calculateRowHeight(thumbnailHeight),
        behavior: 'smooth',
      });
    }
  }, [currentPageID]);

  return (
    <InfiniteLoader
      isItemLoaded={isPageLoaded}
      loadMoreItems={loadMorePages}
      itemCount={numberOfRows}
    >
      {({ onItemsRendered, ref }) => (
        <Grid
          columnCount={thumbnailsPerRow}
          columnWidth={thumbnailWidth + gap}
          height={isInTimelineView ? listHeight : 300}
          rowCount={numberOfRows}
          rowHeight={calculateRowHeight(thumbnailHeight)}
          width={gridWidth}
          outerRef={gridRef}
          ref={ref}
          onItemsRendered={({
            visibleRowStartIndex,
            visibleRowStopIndex,
            overscanRowStartIndex,
            overscanRowStopIndex,
          }) => {
            onItemsRendered({
              visibleStartIndex: visibleRowStartIndex,
              visibleStopIndex: visibleRowStopIndex,
              overscanStartIndex: overscanRowStartIndex,
              overscanStopIndex: overscanRowStopIndex,
            });
          }}
        >
          {renderThumbnailPage}
        </Grid>
      )}
    </InfiniteLoader>
  );
}
