import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import FilterMenuAutoComplete from './FilterMenuAutoComplete';

export default function MultipleSelectList({ items, control, name }) {
  const { setValue, getValues } = useFormContext();
  const itemNames = useMemo(() => {
    const result = [];

    items.forEach((item) => {
      result.push(`${name}.items.${item.value}`);
      if (item.subItems) {
        item.subItems.forEach((subItem) =>
          result.push(`${name}.subItems.${item.value}.${subItem.value}`),
        );
      }
    });

    return result;
  }, [items]);

  useEffect(() => {
    items.forEach((item) => {
      if (item.subItems?.length) {
        const parentName = `${name}.items.${item.value}`;
        const subItemStatus = getSubItemStatuses(item);
        setValue(
          parentName,
          subItemStatus.every((subItem) => subItem),
        );
      }
    });
  }, []);

  const getSubItemStatuses = (item) => {
    const subItemNames = item.subItems?.map(
      (subItem) => `${name}.subItems.${item.value}.${subItem.value}`,
    );
    return subItemNames?.map((subItemName) => getValues(subItemName));
  };

  const getItemStatus = (itemName) => {
    const formattedName = `${name}.items.${itemName}`;
    return getValues(formattedName);
  };

  return (
    <FilterMenuAutoComplete
      type="Content"
      isList={true}
      autoCompleteOptions={Object.values(items ?? {})}
      control={control}
      name={name}
      itemNames={itemNames}
      getSubItemStatuses={getSubItemStatuses}
      getItemStatus={getItemStatus}
    />
  );
}
