import { Box, Button, CircularProgress, TextField } from '@mui/material';
import { useState } from 'react';

export default function SetNewPassword({ onSubmit }) {
  const [newPass, setNewPass] = useState('');
  const [newPass2, setNewPass2] = useState('');
  const [page, setPage] = useState('display');
  const [passRestrictions, setPassRestrictions] = useState([false, false, false]);
  async function submitNewPassword() {
    if ((newPass2 !== '' && newPass2 !== newPass) || passRestrictions.includes(false)) {
      return;
    }
    setPage('Loading');
    await onSubmit(newPass);
  }

  const enterNewPassword = (pass) => {
    const restrictions = [false, false, false];
    let uppercase = false;
    let lowercase = false;
    for (let i = 0; i < pass.length; i++) {
      const character = pass.charAt(i);
      if (!isNaN(character)) {
        restrictions[1] = true;
      } else if (character === character.toUpperCase() && character !== character.toLowerCase()) {
        uppercase = true;
      } else if (character === character.toLowerCase() && character !== character.toUpperCase()) {
        lowercase = true;
      }
    }
    if (pass.length > 7) {
      restrictions[0] = true;
    }
    if (uppercase && lowercase) {
      restrictions[2] = true;
    }
    setPassRestrictions(restrictions);
    setNewPass(pass);
  };
  if (page === 'Loading') {
    return <CircularProgress />;
  }
  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <form
        noValidate
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flex: '90%',
        }}
      >
        <TextField
          value={newPass}
          sx={{ marginBottom: '16px' }}
          autoComplete="new-password"
          onChange={(event) => enterNewPassword(event.target.value)}
          label="New Password"
          type="password"
          className="fs-exclude"
        />
        <TextField
          value={newPass2}
          type="password"
          sx={{ marginBottom: '12px' }}
          autoComplete="new-password"
          onChange={(event) => setNewPass2(event.target.value)}
          label="Confirm New Password"
          className="fs-exclude"
        />
      </form>
      <Button
        onClick={submitNewPassword}
        style={{ float: 'right', flex: '10%' }}
        size="large"
        variant="contained"
        disabled={(newPass2 !== '' && newPass2 !== newPass) || passRestrictions.includes(false)}
      >
        Submit New Password
      </Button>

      <Box style={{ fontSize: 12, marginTop: 4 }}>
        <Box sx={changePasswordStyles.passwordText}>Your new password must be&nbsp;</Box>
        <Box
          sx={[
            changePasswordStyles.passwordText,
            passRestrictions[0] ? changePasswordStyles.success : changePasswordStyles.error,
          ]}
        >
          at least 8 characters long
        </Box>
        <Box sx={changePasswordStyles.passwordText}>,&nbsp;</Box>
        <Box
          sx={[
            changePasswordStyles.passwordText,
            passRestrictions[1] ? changePasswordStyles.success : changePasswordStyles.error,
          ]}
        >
          include numbers
        </Box>
        <Box sx={changePasswordStyles.passwordText}>, and&nbsp;</Box>
        <Box
          sx={[
            changePasswordStyles.passwordText,
            passRestrictions[2] ? changePasswordStyles.success : changePasswordStyles.error,
          ]}
        >
          include both uppercase and lowercase letters
        </Box>
        <Box sx={changePasswordStyles.passwordText}>.</Box>
      </Box>
      <Box>
        {newPass2 !== '' && newPass2 !== newPass ? (
          <Box sx={changePasswordStyles.error} style={{ fontSize: 12, marginTop: 4 }}>
            New passwords must match
          </Box>
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
}

const changePasswordStyles = {
  passwordText: {
    display: 'inline',
  },
  success: {
    color: 'green',
  },
  error: {
    color: 'red',
  },
  resize: {
    fontSize: 12,
  },
};
