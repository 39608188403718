import { gql, useApolloClient } from '@apollo/client';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { uniqBy } from 'lodash';
import { CloseOutlined } from '@mui/icons-material';
import { useIsFileProcessor } from '../../containers/AccountSettings/useFileProcessing';
import { useUserGroup } from '../../containers/MyCases/useCases';
import EditContentButton from '../../containers/Timeline/Components/EditContentButton';
import useBulkUpdatePageTags from '../../containers/Timeline/gql/useBulkUpdatePageTags';
import useContentTypesAndSpecialities from '../../containers/Timeline/gql/useContentTypesAndSpecialities';
import useTimelineEntryTags from './useTimelineEntryTags';

export default function PageContentToolbar({ page }) {
  const pageTags = useContentTypesAndSpecialities();
  const [bulkUpdatePageTags] = useBulkUpdatePageTags();
  const { data: timelineEntryTags, isLoading: entryTagsLoading } = useTimelineEntryTags(
    page.entryID,
  );
  const client = useApolloClient();
  const isFileProcessor = useIsFileProcessor();
  const { data: userGroup } = useUserGroup();
  const processorOrLabeller = isFileProcessor || userGroup === 'Labeller';

  const contentAndSpecialitiesLabels = pageTags?.map;
  const sortedTagsOfPage = useMemo(() => {
    if (!page.tags || entryTagsLoading) {
      return [];
    }
    let tagList = page.tags;
    if (processorOrLabeller) {
      tagList = page.tags.filter(
        (tag) =>
          tag.id !== timelineEntryTags.documentTypeId && tag.id !== timelineEntryTags.specialityId,
      );
    }
    return [...tagList].sort((a, b) => (a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1));
  }, [page.tags, timelineEntryTags]);

  const timelineEntry = client.readFragment({
    id: client.cache.identify({
      id: page.entryID,
      __typename: 'TimelineEntryObject',
    }),
    fragment: gql`
      fragment TimelineEntryWithTaggedPages on TimelineEntryObject {
        id
        author_id
        org_id
        pages {
          id
          tags {
            id
            name
            type
            parent_tag_id
            origin
          }
        }
      }
    `,
  });

  const handleChangeLabel = useCallback(
    (typeID, add) => {
      let currentTags = [...page.tags];

      const newTag = contentAndSpecialitiesLabels[typeID];

      if (add) {
        currentTags.push(newTag);
      } else {
        currentTags = currentTags.filter((option) => option.id !== typeID);
      }
      const validTags = currentTags.filter(Boolean);

      bulkUpdatePageTags({
        where: { id: page.id },
        data: {
          tagsIds: validTags.map((option) => option.id),
          shouldInvalidateUnspecifiedTags: !add,
        },
        newPagesState: {
          __typename: 'PageObject',
          id: page.id,
          tags: validTags,
        },
      });
    },
    [contentAndSpecialitiesLabels, page, bulkUpdatePageTags],
  );

  const filteredPageTags = useMemo(
    () =>
      pageTags.list.filter(
        (tag) =>
          tag.origin !== 'deprecated' &&
          tag.id !== timelineEntryTags?.documentTypeId &&
          tag.id !== timelineEntryTags?.specialityId,
      ),
    [pageTags, timelineEntryTags],
  );

  const currentlySelectedOptions = useMemo(() => {
    if (sortedTagsOfPage == null || contentAndSpecialitiesLabels == null) {
      return [];
    }

    return sortedTagsOfPage.map((item) => contentAndSpecialitiesLabels?.[item.id]);
  }, [sortedTagsOfPage, contentAndSpecialitiesLabels]);

  const clearAllTags = useCallback(() => {
    const entryTags = [timelineEntryTags.documentTypeId, timelineEntryTags.specialityId].filter(
      Boolean,
    );
    bulkUpdatePageTags({
      where: { timeline_entry_id: timelineEntry.id },
      data: {
        tagsIds: entryTags,
      },
      newPagesState: timelineEntry.pages.map((page) => ({
        __typename: 'PageObject',
        id: page.id,
        tags: [],
      })),
      onCompleted: () => {
        toast.info('Content labels cleared.');
      },
      onError: () => {
        toast.error('Error clearing content labels.');
      },
    });
  }, [timelineEntryTags, timelineEntry, bulkUpdatePageTags]);

  const applyTagsToAllEntryPages = useCallback(() => {
    const entryTags = [timelineEntryTags.documentTypeId, timelineEntryTags.specialityId].filter(
      Boolean,
    );
    bulkUpdatePageTags({
      where: { timeline_entry_id: timelineEntry.id },
      data: {
        tagsIds: [currentlySelectedOptions.map((option) => option.id), entryTags].flat(),
        shouldInvalidateUnspecifiedTags: false,
      },
      newPagesState: timelineEntry.pages.map((page) => ({
        __typename: 'PageObject',
        id: page.id,
        tags: uniqBy(
          [...page.tags, ...currentlySelectedOptions.map((option) => pageTags.map[option.id])],
          'id',
        ),
      })),
      onCompleted: () => {
        toast.info('Content labels saved.');
      },
      onError: () => {
        toast.error('Error saving content labels.');
      },
    });
  }, [currentlySelectedOptions, timelineEntry, pageTags, bulkUpdatePageTags]);

  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          maxWidth: isFileProcessor ? '70%' : '100%',
        }}
      >
        <EditContentButton
          type="Content"
          autoCompleteOptions={filteredPageTags}
          currentlySelectedOptions={currentlySelectedOptions}
          handleChangeLabel={handleChangeLabel}
          page={page}
          timelineEntryTags={timelineEntryTags}
        />
        <Box
          id="pageContentToolbarContainer"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            justifyContent: 'left',
            height: '28px',
            backgroundColor: 'selectedGrey.main',
            marginLeft: '.3rem',
            lineHeight: '28px',
            position: 'relative',
          }}
        >
          {sortedTagsOfPage.map((item) => (
            <Chip
              label={item.label}
              key={item.id}
              variant="outlined"
              classes="pdf-viewer"
              className="unmask"
              sx={
                isFileProcessor
                  ? {
                      color:
                        (item.type === 'speciality' && 'purple') || (!item.parent ? '' : 'blue'),
                      borderColor:
                        (item.type === 'speciality' && 'purple') || (!item.parent ? '' : 'blue'),
                    }
                  : {}
              }
              deleteIcon={<CloseOutlined sx={labelStyle.deleteContentIcon} />}
              onDelete={() => handleChangeLabel(item.id, false)}
            />
          ))}
          {processorOrLabeller && !entryTagsLoading && (
            <>
              <Typography
                onClick={() => applyTagsToAllEntryPages()}
                variant="h5"
                color="primary.light"
                sx={{ cursor: 'pointer', display: 'inline', fontSize: '0.75rem', ml: 1 }}
              >
                Apply All
              </Typography>
              <Typography
                onClick={() => clearAllTags()}
                variant="h5"
                color="primary.light"
                sx={{ cursor: 'pointer', display: 'inline', ml: 2, fontSize: '0.75rem' }}
              >
                Clear All
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Stack>
  );
}

const labelStyle = {
  deleteContentIcon: {
    '&&': {
      color: '#667085 !important',
      fontSize: '0.75rem !important',
      marginLeft: '-0.2rem',
    },
  },
};
