import { Box, Card, Typography } from '@mui/material';
import SiftMedCarousel from './SiftMedCarousel';
import Logo from '../../resources/images/SiftMedFC.png';

const loginStyles = {
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  form: (theme) => ({
    maxWidth: 500,
    margin: 'auto',
    marginTop: '2%',
    width: '65%',
    justifyContent: 'center',
    border: 'none',
    '&:hover': {
      border: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      height: '100%',
      maxWidth: 'unset',
    },
  }),
  logoContainer: {
    margin: 'auto',
    flex: '10%',
    marginBottom: '5%',
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    width: 'auto',
    height: 'auto',
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    maxWidth: '140px',
    maxHeight: '140px',
  },
  split: {
    height: '100%',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    overflowX: 'hidden',
    paddingTop: '20px',
  },
  left: {
    left: 0,
    background: 'linear-gradient(180deg, #385D9F 0%, #09152C 100%)',
    width: { xs: '0%', md: '60', lg: '65%' },
  },
  right: {
    right: 0,
    width: { xs: '100%', md: '40', lg: '35%' },
    background: '#FFFFFF',
  },
  footer: {
    color: '#BBD2E7',
    fontSize: '0.75rem',
    display: 'inline',
  },
};

function LoginContainer(props) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
      }}
    >
      <Box
        id="left-side-home"
        sx={{
          ...loginStyles.split,
          ...loginStyles.left,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <SiftMedCarousel />
        <Box sx={{ marginBottom: '2rem' }}>
          <Typography sx={{ ...loginStyles.footer }}>© SiftMed, St. John's, NL, Canada</Typography>
          <a href="http://www.siftmed.ca/privacy" style={{ textDecoration: 'none' }}>
            <Typography
              sx={{
                ...loginStyles.footer,
                marginLeft: '2rem',
                '&:hover': {
                  color: 'secondary.main',
                },
              }}
            >
              Privacy Policy
            </Typography>
          </a>
        </Box>
      </Box>
      <Box id="right-side-home" sx={{ ...loginStyles.split, ...loginStyles.right }}>
        <Card sx={loginStyles.form}>
          <Box sx={loginStyles.logoContainer}>
            <img src={Logo} alt="SiftMed Inc. Logo" style={loginStyles.logo} />
          </Box>
          <Box sx={loginStyles.formContainer}>{props.children}</Box>
        </Card>
      </Box>
    </Box>
  );
}

export default LoginContainer;
