import React from 'react';

export default function UpArrowWithTail(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 15.8333V4.16667M10 4.16667L15.8333 10M10 4.16667L4.16667 10"
        stroke={props?.style?.color ?? '#1E407D'}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
