import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import { TextField, CircularProgress, Skeleton, InputAdornment, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { toast } from 'react-toastify';
import MultiSelectDropdown from '../../../../components/common/HTML_components/MultiSelectDropdown/MultiSelectDropdown';
import useContentTypesAndSpecialities from '../../../Timeline/gql/useContentTypesAndSpecialities';
import useBulkUpdatePageTags from '../../../Timeline/gql/useBulkUpdatePageTags';
import EntitySelect from '../../../Timeline/EntitySelect';
import usePeople from '../../../Timeline/gql/usePeopleEntities';
import { isValidDate } from '../../../../library/utilities/useDates';
import { PreviewEntryDetails } from '../IndexReportTable';
import useTimelineStore from '../../../Timeline/useTimelineStore';
import useOrgs from '../../../Timeline/gql/useOrgEntities';
import CustomSelectMenu from '../../../../components/common/HTML_components/DropdownSelect/DropdownSelect';
import useTimelineEntryTags, {
  useUpdateTimelineEntryTag,
} from '../../../../components/DocumentScrolling/useTimelineEntryTags';
import useSources from '../../../Timeline/gql/useSources';
import { TimelineDetailsProps } from './DocumentPreviewer';
import { useGetPageByIdQuery } from '../../../../__generated__/graphql';
import { useUpdateMonetaryTotal } from '../../api-queries/useUpdateMonetaryTotal';

type Option = {
  value: string;
  label: string;
};

type EditDocumentDetailsProps = {
  entryID: bigint;
  pageID: string;
  caseID: string;
  currentEntryDetails: PreviewEntryDetails;
  setCurrentEntryDetails: (details: PreviewEntryDetails) => void;
  onUpdate: (
    caseID: string | undefined,
    entryID: bigint,
    sectionID: number,
    valuesToUpdate: TimelineDetailsProps,
  ) => void;
  showSource: boolean;
  isFileProcessor: boolean;
  isFetchingEntryDetails?: boolean;
  setSelectedDate: (date: string) => void;
  isInTaggingTab?: boolean;
  refreshIndexReport?: () => void;
  updateIndexRowCache?: (sectionId: string, rowId: string, updatedValues: any) => void;
};

export function EditDocumentDetails({
  entryID,
  pageID,
  caseID,
  currentEntryDetails,
  setCurrentEntryDetails,
  onUpdate,
  showSource = false,
  isFileProcessor = false,
  isFetchingEntryDetails = false,
  setSelectedDate,
  isInTaggingTab,
  refreshIndexReport,
  updateIndexRowCache,
}: EditDocumentDetailsProps) {
  const editMonetaryTotal = useUpdateMonetaryTotal();

  const [updatedEntryDetails, setUpdatedEntryDetails] =
    useState<PreviewEntryDetails>(currentEntryDetails);

  const [unknownDate, setUnknownDate] = useState(false);

  const [pageSelectedTags, setPageSelectedTags] = useState<Option[]>([]);
  const [previousType, setPreviousType] = useState<number | null>(null);

  const { data: timelineEntryTags } = useTimelineEntryTags(entryID);

  const { loading, data: pageObject } = useGetPageByIdQuery({
    variables: {
      id: pageID,
    },
    skip: !pageID,
  });

  useEffect(() => {
    const pageTags = pageObject?.page?.tags;
    if (pageTags && timelineEntryTags) {
      setPageSelectedTags(
        pageTags
          ?.filter(
            (tag) =>
              tag.id !== timelineEntryTags?.documentTypeId &&
              tag.id !== previousType &&
              tag.id !== timelineEntryTags?.specialityId,
          )
          .map((tag) => ({ value: tag.id.toString(), label: tag.label })) ?? [],
      );
    }
    setPreviousType(null);
  }, [pageObject, timelineEntryTags]);

  const allContentTags = useContentTypesAndSpecialities();
  const contentTagOptions = useMemo(() => {
    const tags = allContentTags.list.filter((tag) => tag.origin !== 'deprecated');
    return tags.map((tag) => ({
      value: String(tag.id),
      label: tag.label,
    }));
  }, [allContentTags.list]);

  const [bulkUpdatePageTags] = useBulkUpdatePageTags();

  const handleChangeLabel = useCallback(
    async (currentTags: Option[]) => {
      const isAdding = currentTags.length > (pageSelectedTags?.length || 0);
      const mappedTagIDs = currentTags.map((option) => +option.value);

      if (timelineEntryTags?.documentTypeId) {
        mappedTagIDs.push(timelineEntryTags.documentTypeId);
      }

      const validTags = allContentTags.content
        .filter((tag) => mappedTagIDs.includes(tag.id))
        .filter(Boolean);

      setPageSelectedTags(currentTags.filter(Boolean));

      await bulkUpdatePageTags({
        where: { id: +pageID },
        data: {
          tagsIds: mappedTagIDs,
          shouldInvalidateUnspecifiedTags: !isAdding,
        },
        newPagesState: {
          __typename: 'PageObject',
          id: +pageID,
          tags: validTags,
        },
      });
      if (refreshIndexReport) {
        refreshIndexReport();
      }
    },
    [
      allContentTags,
      pageID,
      bulkUpdatePageTags,
      pageSelectedTags,
      timelineEntryTags,
      refreshIndexReport,
    ],
  );

  const { hiddenEntities } = useTimelineStore(
    (state) => ({
      hiddenEntities: state.hiddenEntities,
    }),
    shallow,
  );

  const {
    data: {
      sortedPeopleForCase: sortedAuthorsForCase,
      sortedPeopleForEntry: sortedAuthorsForEntry,
      allPeople: allAuthors,
    },
  } = usePeople(caseID, entryID);

  const {
    data: { sortedOrgsForCase, sortedOrgsForEntry, allOrgs },
  } = useOrgs(caseID, entryID);

  const filteredAuthors = useMemo(() => {
    return sortedAuthorsForCase.filter((author) => {
      return !hiddenEntities.includes(author.value) && !author.hidden;
    });
  }, [sortedAuthorsForCase, hiddenEntities]);

  const filteredOrgs = useMemo(() => {
    return sortedOrgsForCase.filter((org) => {
      return !hiddenEntities.includes(org.value) && !org.hidden;
    });
  }, [sortedOrgsForCase, hiddenEntities]);

  const handleUpdateDate = () => {
    if (updatedEntryDetails.entryDate !== currentEntryDetails.entryDate) {
      onUpdate(caseID, entryID, currentEntryDetails.sectionId, {
        date: updatedEntryDetails.entryDate,
      });
      setCurrentEntryDetails(updatedEntryDetails);
      setSelectedDate(updatedEntryDetails.entryDate);

      if (updateIndexRowCache) {
        updateIndexRowCache(currentEntryDetails.sectionId, String(entryID), {
          entry_date: updatedEntryDetails.entryDate,
        });
      }
    }
  };

  useEffect(() => {
    if (unknownDate) {
      handleUpdateDate();
      setUnknownDate(false);
    }
  }, [updatedEntryDetails.entryDate, unknownDate]);

  const handleSetUnknownDate = () => {
    setUpdatedEntryDetails((prevDetails) => ({
      ...prevDetails,
      entryDate: '1900-01-01',
    }));
    setUnknownDate(true);
    if (updateIndexRowCache) {
      updateIndexRowCache(currentEntryDetails.sectionId, String(entryID), {
        entry_date: '1900-01-01',
      });
    }
  };

  const handleBlurMonetaryTotal = async () => {
    if (updatedEntryDetails.monetary_total !== currentEntryDetails.monetary_total) {
      try {
        await editMonetaryTotal(entryID, updatedEntryDetails.monetary_total ?? '');
        setCurrentEntryDetails(updatedEntryDetails);
        onUpdate(caseID, entryID, currentEntryDetails.sectionId, {
          monetary_total: updatedEntryDetails.monetary_total,
        });
        if (updateIndexRowCache) {
          updateIndexRowCache(currentEntryDetails.sectionId, String(entryID), {
            monetary_total: updatedEntryDetails.monetary_total,
          });
        }
      } catch (error) {
        toast.error(`Failed to update monetary total: ${error.message}`);
      }
    }
  };

  const handleUpdateAuthor = () => {
    if (!isEqual(updatedEntryDetails.author, currentEntryDetails.author)) {
      onUpdate(caseID, entryID, currentEntryDetails.sectionId, {
        author: updatedEntryDetails.author,
      });
      setCurrentEntryDetails(updatedEntryDetails);
    }
  };

  const handleUpdateOrganization = () => {
    if (!isEqual(updatedEntryDetails.organization, currentEntryDetails.organization)) {
      onUpdate(caseID, entryID, currentEntryDetails.sectionId, {
        organization: updatedEntryDetails.organization,
      });
      setCurrentEntryDetails(updatedEntryDetails);
    }
  };

  useEffect(() => {
    if (!isEqual(currentEntryDetails, updatedEntryDetails)) {
      setUpdatedEntryDetails(currentEntryDetails);
    }
  }, [currentEntryDetails]);

  const singlePageTagOptions = contentTagOptions.filter((tag) =>
    ['52', '141', '142', '143', '41', '173', '10001'].includes(tag.value),
  ); //Kept for when multi-document types is done to be re-introduced for file processors

  const [selectedTags, setSelectedTags] = useState<Option[]>([]);

  useEffect(() => {
    const mappedTags = currentEntryDetails.contentTags.map((tag) => {
      const matchingOption = contentTagOptions.find((option) => option.value === tag.value);
      return {
        value: tag.value,
        label: matchingOption?.label ?? '',
      };
    });
    setSelectedTags(mappedTags);
  }, [currentEntryDetails.contentTags, contentTagOptions]);

  const handleUpdateContentTags = async (tags) => {
    setSelectedTags(tags);
    await bulkUpdatePageTags({
      where: {
        timeline_entry_id: entryID,
      },
      data: {
        tagsIds: tags.map((tag) => tag.value),
      },
    });
    if (refreshIndexReport) {
      refreshIndexReport();
    }
  };

  const isOCFTagPresent = currentEntryDetails.contentTags.find((tag) =>
    tag.label.toLowerCase().includes('ocf'),
  );

  return (
    <div
      style={{
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'column',
        height: '72%',
        overflowY: 'scroll',
        paddingBottom: isFileProcessor ? (isInTaggingTab ? '0' : '14rem') : '',
      }}
      id="details-box"
    >
      <h2 style={{ textAlign: 'left', fontSize: '0.95rem', fontWeight: 500 }}>
        {isFileProcessor ? 'Page Details' : 'Details'}
      </h2>
      {!isFileProcessor && (
        <h3
          style={{
            textAlign: 'left',
            fontSize: '0.85rem',
            fontWeight: 400,
            marginBottom: '2.5px',
          }}
        >
          Tags
        </h3>
      )}
      {loading ? (
        <CircularProgress size="1.4rem" sx={{ marginBottom: '0.7rem' }} />
      ) : (
        <MultiSelectDropdown
          options={contentTagOptions}
          selectedValues={isFileProcessor ? pageSelectedTags ?? [] : selectedTags ?? []}
          onChange={isFileProcessor ? handleChangeLabel : handleUpdateContentTags}
          shouldOpenByDefault={false}
          style={{
            width: '70%',
            fontSize: 'small',
            maxWidth: '300px',
          }}
          dropdownButton={true}
          searchBar={!isFileProcessor}
        />
      )}
      {isFileProcessor && (
        <h2
          style={{
            textAlign: 'left',
            fontSize: '0.95rem',
            fontWeight: 500,
            marginTop: '0.8rem',
          }}
        >
          Document Details
        </h2>
      )}
      {isOCFTagPresent && (
        <div style={{ width: '85%' }}>
          <h3
            style={{
              textAlign: 'left',
              fontSize: '0.75rem',
              fontWeight: 400,
              marginBottom: '2.5px',
            }}
          >
            Amount
          </h3>
          {isFetchingEntryDetails ? (
            <Skeleton
              variant="rectangular"
              width={320}
              height={38}
              sx={{ borderRadius: '10px', mb: '1.1rem' }}
            />
          ) : (
            <TextField
              value={updatedEntryDetails.monetary_total}
              onChange={(e) =>
                setUpdatedEntryDetails({ ...updatedEntryDetails, monetary_total: e.target.value })
              }
              onBlur={handleBlurMonetaryTotal}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ '& .MuiTypography-root': { fontSize: 'small' } }}
                  >
                    $
                  </InputAdornment>
                ),
                style: { fontSize: '0.75rem' },
              }}
              sx={{
                width: '85%',
                minHeight: 'fit-content',
                overflowY: 'visible',
                marginBottom: '1rem',
                alignContent: 'center',
                border: '0.5px solid rgb(208, 213, 221)',
                borderRadius: '10px',
                '& .MuiAutocomplete-inputRoot': { border: 'none', paddingY: 0, paddingX: 0 },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { border: 'none' },
                  '&:hover fieldset': { border: 'none' },
                  '&.Mui-focused fieldset': { border: 'none' },
                },
              }}
            />
          )}
        </div>
      )}

      {isFileProcessor && (
        <SplitTagsDetails
          caseID={caseID}
          entryID={entryID}
          currentEntryDetails={currentEntryDetails}
          setCurrentEntryDetails={setCurrentEntryDetails}
          onUpdate={onUpdate}
          timelineEntryTags={timelineEntryTags}
          showSource={showSource}
          isFetchingEntryDetails={isFetchingEntryDetails}
          setPreviousType={setPreviousType}
          refreshIndexReport={refreshIndexReport}
        />
      )}
      <div style={{ width: '85%' }} onBlur={handleUpdateAuthor}>
        <h3
          style={{
            textAlign: 'left',
            fontSize: isFileProcessor ? '0.75rem' : '0.85rem',
            fontWeight: 400,
          }}
        >
          Author
        </h3>
        {isFetchingEntryDetails ? (
          <Skeleton
            variant="rectangular"
            width={320}
            height={38}
            sx={{ borderRadius: '10px', mb: '1.1rem' }}
          />
        ) : (
          <EntitySelect
            entityName="author"
            valueID={updatedEntryDetails?.author.id ?? 0}
            optionsForCase={filteredAuthors}
            optionsForEntry={sortedAuthorsForEntry}
            allOptions={allAuthors}
            setValues={(e: { id: number | null; name: string | null; label: string | null }) =>
              setUpdatedEntryDetails({ ...updatedEntryDetails, author: e })
            }
            inputProps={undefined}
          />
        )}
      </div>
      <div style={{ width: '85%' }} onBlur={handleUpdateOrganization}>
        <h3
          style={{
            textAlign: 'left',
            fontSize: isFileProcessor ? '0.75rem' : '0.85rem',
            fontWeight: 400,
          }}
        >
          Organization
        </h3>
        {isFetchingEntryDetails ? (
          <Skeleton
            variant="rectangular"
            width={320}
            height={38}
            sx={{ borderRadius: '10px', mb: '1.1rem' }}
          />
        ) : (
          <EntitySelect
            entityName="organization"
            valueID={updatedEntryDetails?.organization.id ?? 0}
            optionsForCase={filteredOrgs}
            optionsForEntry={sortedOrgsForEntry}
            allOptions={allOrgs}
            setValues={(e: { id: number | null; name: string | null; label: string | null }) =>
              setUpdatedEntryDetails({ ...updatedEntryDetails, organization: e })
            }
            inputProps={undefined}
          />
        )}
      </div>
      <div style={{ width: '85%' }} onBlur={handleUpdateDate}>
        <h3
          style={{
            textAlign: 'left',
            fontSize: isFileProcessor ? '0.75rem' : '0.85rem',
            fontWeight: 400,
            marginBottom: '2.5px',
          }}
        >
          Date
        </h3>
        {isFetchingEntryDetails ? (
          <Skeleton
            variant="rectangular"
            width={320}
            height={38}
            sx={{ borderRadius: '10px', mb: '1.1rem' }}
          />
        ) : (
          <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{
                  border: '0.5px solid rgb(208, 213, 221)',
                  width: '100%',
                  '& .MuiAutocomplete-inputRoot': {
                    border: 'none',
                    paddingY: 0,
                    paddingX: 0,
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none', // Removes border
                    },
                    '&:hover fieldset': {
                      border: 'none', // Removes border on hover
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none', // Removes border when focused
                    },
                  },
                }}
                format="YYYY-MM-DD"
                value={
                  currentEntryDetails.entryDate !== '1900-01-01'
                    ? dayjs(currentEntryDetails.entryDate)
                    : ''
                }
                minDate={dayjs('1000-01-01')}
                maxDate={dayjs('2099-12-31')}
                onChange={(e) => {
                  const newDate = dayjs(e).format('YYYY-MM-DD');
                  if (isValidDate(newDate)) {
                    setUpdatedEntryDetails({
                      ...updatedEntryDetails,
                      entryDate: newDate,
                    });
                  }
                }}
              />
            </LocalizationProvider>
            <Typography
              sx={{
                marginLeft: '-65%',
                paddingBottom: '.5rem',
                fontSize: '12px',
                color: 'text.disabled',
                textAlign: 'center',
                cursor: 'pointer',
                '&:hover': {
                  color: 'primary.light',
                },
              }}
              onClick={handleSetUnknownDate}
            >
              Set as Unknown
            </Typography>
          </>
        )}
      </div>
    </div>
  );
}

type SplitTagsDetailsProps = {
  caseID: string;
  entryID: bigint;
  currentEntryDetails: PreviewEntryDetails;
  setCurrentEntryDetails: (details: PreviewEntryDetails) => void;
  timelineEntryTags?: {
    documentTypeId: number | null;
    specialityId: number | null;
    suggestedDocumentTypeIds: number[];
    suggestedSpecialityIds: number[];
  };
  isFetchingEntryDetails: boolean;
  onUpdate: (
    caseID: string | undefined,
    entryID: bigint,
    sectionID: number,
    valuesToUpdate: TimelineDetailsProps,
  ) => void;
  showSource: boolean;
  setPreviousType: (docType: number) => void;
  refreshIndexReport?: () => void;
};

function SplitTagsDetails({
  caseID,
  entryID,
  currentEntryDetails,
  setCurrentEntryDetails,
  onUpdate,
  timelineEntryTags,
  showSource,
  isFetchingEntryDetails,
  setPreviousType,
  refreshIndexReport,
}: SplitTagsDetailsProps) {
  const allContentTags = useContentTypesAndSpecialities();
  const { mutateAsync: updateEntryTag } = useUpdateTimelineEntryTag();
  const tagMap = allContentTags?.map;
  const contentTags = allContentTags?.content.map((tag) => {
    return { ...tag, value: tag.label };
  });
  const specialityTags = allContentTags?.specialities.map((tag) => {
    return { ...tag, value: tag.label };
  });

  const [isInputFocused, setIsInputFocused] = useState(false);

  const contentTagsForCase = useMemo(() => {
    if (!timelineEntryTags) {
      return [];
    }
    return contentTags
      .filter(
        (tag) =>
          !timelineEntryTags.suggestedDocumentTypeIds.includes(tag.id) &&
          tag.origin !== 'deprecated',
      )
      .sort((a, b) => a.value.localeCompare(b.value));
  }, [timelineEntryTags, contentTags]);

  const contentTagsForEntry = useMemo(() => {
    let suggestedTags: {
      id: string | number | null;
      value?: string;
      source?: string;
      [key: string]: any;
    }[] = [];
    if (timelineEntryTags && timelineEntryTags.suggestedDocumentTypeIds.length > 0) {
      suggestedTags = timelineEntryTags.suggestedDocumentTypeIds
        .map((id) => tagMap[id])
        .map((tag) => {
          return { ...tag, value: tag?.label ?? '' };
        });
    }
    if (!isInputFocused) {
      suggestedTags.push({ id: null, value: 'Unknown' });
    }
    return suggestedTags.sort((a, b) => (a.value && b.value ? a.value.localeCompare(b.value) : 0));
  }, [timelineEntryTags, contentTags, isInputFocused]);

  const specialityTagsForEntry = useMemo(() => {
    let suggestedTags: {
      id: string | number | null;
      value?: string;
      source?: string;
      [key: string]: any;
    }[] = [];
    if (timelineEntryTags && timelineEntryTags.suggestedSpecialityIds.length > 0) {
      suggestedTags = timelineEntryTags.suggestedSpecialityIds
        .map((id) => tagMap[id])
        .map((tag) => {
          return { ...tag, value: tag?.label ?? '' };
        });
    }
    if (!isInputFocused) {
      suggestedTags.push({ id: null, value: 'Unknown' });
    }
    return suggestedTags.sort((a, b) => (a.value && b.value ? a.value.localeCompare(b.value) : 0));
  }, [timelineEntryTags, specialityTags, isInputFocused]);

  const specialityTagsForCase = useMemo(() => {
    if (!timelineEntryTags) {
      return [];
    }
    return specialityTags
      .filter(
        (tag) =>
          !timelineEntryTags.suggestedSpecialityIds.includes(tag.id) && tag.origin !== 'deprecated',
      )
      .sort((a, b) => a.value.localeCompare(b.value));
  }, [timelineEntryTags, specialityTags]);

  const [documentType, setDocumentType] = useState<number | null>(
    timelineEntryTags?.documentTypeId ?? null,
  );
  const [speciality, setSpeciality] = useState<number | null>(
    timelineEntryTags?.specialityId ?? null,
  );

  useEffect(() => {
    if (timelineEntryTags) {
      setDocumentType(timelineEntryTags?.documentTypeId ?? null);
      setSpeciality(timelineEntryTags?.specialityId ?? null);
    }
  }, [currentEntryDetails.contentTags, timelineEntryTags]);

  const { data: sources } = useSources();
  const sourceOptions = sources
    ?.filter((source) => source.origin !== 'deprecated')
    .map((sourceName) => ({
      value: sourceName.id,
      label: sourceName.label,
    }));
  const handleUpdateSource = (newSource) => {
    if (newSource.value !== currentEntryDetails.sourceID) {
      onUpdate(caseID, entryID, currentEntryDetails.sectionId, {
        source: newSource.value,
      });
      setCurrentEntryDetails({ ...currentEntryDetails, sourceID: newSource.value });
    }
  };

  const handleUpdateDocumentType = async () => {
    if (timelineEntryTags?.documentTypeId !== documentType) {
      setPreviousType(timelineEntryTags?.documentTypeId ?? null);
    }
    const newDocumentType = documentType ?? null;
    const currentDocumentType = timelineEntryTags?.documentTypeId ?? null;

    if (newDocumentType === currentDocumentType) {
      return;
    }

    const tagType = 'content_type';
    await updateEntryTag({ entryId: entryID, tagId: newDocumentType, tagType });
    if (refreshIndexReport) {
      refreshIndexReport();
    }
  };

  const handleUpdateSpeciality = async () => {
    const newSpeciality = speciality ?? null;
    const currentSpeciality = timelineEntryTags?.specialityId ?? null;

    if (newSpeciality === currentSpeciality) {
      return;
    }

    const tagType = 'speciality';
    await updateEntryTag({ entryId: entryID, tagId: newSpeciality, tagType });
    if (refreshIndexReport) {
      refreshIndexReport();
    }
  };

  return (
    <>
      {showSource && (
        <div id="source">
          <h3
            style={{
              textAlign: 'left',
              fontSize: '0.75rem',
              fontWeight: 400,
              marginBottom: '2.5px',
            }}
          >
            Source
          </h3>
          {isFetchingEntryDetails ? (
            <Skeleton
              variant="rectangular"
              width={320}
              height={38}
              sx={{ borderRadius: '10px', mb: '1.1rem' }}
            />
          ) : (
            <CustomSelectMenu
              options={sourceOptions}
              onChange={(e) => {
                handleUpdateSource(e);
              }}
              currentOption={{
                value: currentEntryDetails.sourceID,
                label:
                  sourceOptions?.find((option) => option.value === currentEntryDetails.sourceID)
                    ?.label ?? null,
              }}
              onCommit={() => {}}
            />
          )}
        </div>
      )}
      <div style={{ width: '85%' }} onBlur={handleUpdateDocumentType}>
        <h3 style={{ textAlign: 'left', fontSize: '0.75rem', fontWeight: 400 }}>Document Type</h3>
        {isFetchingEntryDetails ? (
          <Skeleton
            variant="rectangular"
            width={320}
            height={38}
            sx={{ borderRadius: '10px', mb: '1.1rem' }}
          />
        ) : (
          <EntitySelect
            entityName="document type"
            valueID={documentType}
            optionsForCase={contentTagsForCase}
            optionsForEntry={contentTagsForEntry}
            allOptions={[...contentTags, { id: null, value: 'Unknown' }]}
            setValues={(value) => setDocumentType(value.id)}
            allowNewOptions={false}
            inputProps={{
              onFocus: () => setIsInputFocused(true),
              onBlur: () => setIsInputFocused(false),
            }}
          />
        )}
      </div>
      <div style={{ width: '85%' }} onBlur={handleUpdateSpeciality}>
        <h3 style={{ textAlign: 'left', fontSize: '0.8rem', fontWeight: 400 }}>Speciality</h3>
        {isFetchingEntryDetails ? (
          <Skeleton
            variant="rectangular"
            width={320}
            height={38}
            sx={{ borderRadius: '10px', mb: '1.1rem' }}
          />
        ) : (
          <EntitySelect
            entityName="speciality"
            valueID={speciality}
            optionsForCase={specialityTagsForCase}
            optionsForEntry={specialityTagsForEntry}
            allOptions={[...specialityTags, { id: null, value: 'Unknown' }]}
            setValues={(value) => setSpeciality(value.id)}
            allowNewOptions={false}
            inputProps={{
              onFocus: () => setIsInputFocused(true),
              onBlur: () => setIsInputFocused(false),
            }}
          />
        )}
      </div>
    </>
  );
}
