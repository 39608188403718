import { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Chip,
  Backdrop,
  Button,
  Divider,
  AppBar,
  Stack,
  Checkbox,
  Radio,
  CircularProgress,
  Switch,
} from '@mui/material';
import { Add, Remove, CheckCircle, CircleOutlined } from '@mui/icons-material';
import UpArrowWithTail from '../../components/icons/UpArrowWithTail';
import './document-images-container.css';
import CaseContext from '../Case/CaseContext';
import { useTimelineList } from '../Timeline/useTimeline';
import CaseHeader from '../Timeline/CaseHeader';
import NavigationButton from '../../components/common/PdfNavigation/Components/NavigationButton';
import useDisplayStore from '../Timeline/useDisplayStore';
import { useImagePages } from './api-hooks/useGetImagePages';
import useBulkUpdatePageTags from '../Timeline/gql/useBulkUpdatePageTags';
import { useActivityLog } from '../../components/ActivityTracker/ActivityTracker';

export default function DocumentImagesContainer() {
  const { caseID } = useParams();
  const logUserActivity = useActivityLog();
  const navigate = useNavigate();
  const { caseInstance } = useContext(CaseContext);
  const { data: timelineList } = useTimelineList(caseID);
  const [filterBy, setFilterBy] = useState('ALL');
  const [selectedPages, setSelectedPages] = useState<bigint[]>([]);
  const [blur, setBlur] = useState<boolean>(true);
  const [loadingChanges, setLoadingChanges] = useState<boolean>(false);

  const [bulkUpdatePageTags] = useBulkUpdatePageTags();

  const thumbnailSize = useDisplayStore((state) => state.thumbnailSize);
  const setThumbnailSize = useDisplayStore((state) => state.setThumbnailSize);

  const { data: imagePages, isLoading: isLoadingImagePages } = useImagePages(caseID);
  const timelineID = timelineList?.find((timeline) => timeline.isDefault).id;

  useEffect(() => {
    if (caseID) {
      logUserActivity({
        activity: 'case:image',
        case_id: caseID,
      });
    }
  }, [caseID]);

  const zoomOut = useCallback(() => {
    setThumbnailSize(thumbnailSize - 5);
  }, [thumbnailSize]);

  const zoomIn = useCallback(() => {
    setThumbnailSize(thumbnailSize + 5);
  }, [thumbnailSize]);

  const filteredImageLists = useMemo(() => {
    const initialLists = {
      ALL: [],
      BLANK: [],
      IMAGE: [],
      NEITHER: [],
    };
    return (imagePages ?? [])?.reduce((lists, page) => {
      lists.ALL.push(page);

      if (page.tag_ids?.includes(141)) {
        lists.BLANK.push(page);
      } else if (page.tag_ids?.includes(143)) {
        lists.IMAGE.push(page);
      } else {
        lists.NEITHER.push(page);
      }

      return lists;
    }, initialLists);
  }, [imagePages]);

  const handleAddRemoveSelectPage = useCallback(
    (pageID: bigint) => {
      if (selectedPages.includes(pageID)) {
        setSelectedPages(selectedPages.filter((id) => id !== pageID));
      } else {
        setSelectedPages([...selectedPages, pageID]);
      }
    },
    [selectedPages],
  );

  const toggleSelectAll = useCallback(() => {
    if (selectedPages.length === filteredImageLists[filterBy]?.length) {
      setSelectedPages([]);
    } else {
      setSelectedPages(filteredImageLists[filterBy]?.map((page) => page.id));
    }
  }, [selectedPages, filteredImageLists, filterBy]);

  const handleChangeLabels = useCallback(
    (typeID: number, bulkDismissOnly: boolean) => {
      setLoadingChanges(true);
      bulkUpdatePageTags({
        where: { pageIds: selectedPages },
        data: {
          tagsIds: typeID !== 0 ? [typeID] : [141, 143],
          shouldInvalidateUnspecifiedTags: typeID !== 0,
          onlyDismissEntryTags: typeID === 0,
          bulkDismissOnly,
        },
        caseID,
        imagesUpdated: true,
        onCompleted: () => {
          toast.success('Page tags updated successfully.');
          setSelectedPages([]);
          setLoadingChanges(false);
        },
        onError: () => {
          toast.error('Error updating page tags.');
          setLoadingChanges(false);
        },
      });
      setSelectedPages([]);
    },
    [selectedPages, bulkUpdatePageTags],
  );

  return (
    <>
      <CaseHeader caseInstance={caseInstance} timelineID={timelineID} />
      <AppBar position="static" sx={imageContainerStyles.appBarStyles}>
        <div className="images-container" id="images-display">
          <span className="sm-back-button sm-back-button-image-tab">
            <div
              style={{ marginTop: '1rem' }}
              onClick={() => {
                navigate('../files');
              }}
            >
              <UpArrowWithTail
                style={{
                  color: '#1E407D',
                  marginRight: '0.5rem',
                }}
                transform="rotate(270)"
              />
              <span className="sm-back-button-text sm-button-text">Back to files</span>
            </div>
            <div className="blur-switch-container">
              <Switch size="small" checked={blur} onClick={() => setBlur(!blur)} />
              Blur Images{' '}
            </div>
          </span>

          <div className="header-container">
            <h6>Images and Blanks</h6>
            <Stack direction="row" justifyContent="center">
              <Button
                variant="contained"
                sx={{ ...imageContainerStyles.buttonStyles, backgroundColor: 'primary.light' }}
                onClick={() => {
                  handleChangeLabels(141, false);
                }}
                disabled={selectedPages.length === 0}
              >
                Blank
              </Button>
              <Button
                variant="contained"
                color="navigationGrey"
                sx={{
                  ...imageContainerStyles.buttonStyles,
                }}
                onClick={() => {
                  handleChangeLabels(143, false);
                }}
                disabled={selectedPages.length === 0}
              >
                Image
              </Button>
              <Button
                variant="outlined"
                sx={imageContainerStyles.buttonStyles}
                onClick={() => {
                  handleChangeLabels(0, true);
                }}
                disabled={selectedPages.length === 0}
              >
                Neither
              </Button>
            </Stack>
            <Stack direction="row" justifyContent="center">
              <div
                id="toolbarContainer"
                className="toolbar-container"
                style={{ backgroundColor: 'selectedGrey.main' }}
              >
                <div className="nav-buttons-container">
                  <NavigationButton
                    id="navbar-zoom-out"
                    onClick={zoomOut}
                    icon={
                      <Remove
                        sx={{
                          fontSize: '1rem',
                          color: Number(thumbnailSize.toFixed(1)) <= 15 ? 'inherit' : '#344054',
                        }}
                      />
                    }
                    disabled={Number(thumbnailSize.toFixed(1)) <= 15}
                    position="left"
                  />
                  <div className="zoom-level-styles">{Math.round((thumbnailSize / 50) * 100)}%</div>
                  <NavigationButton
                    id="navbar-zoom-in"
                    onClick={zoomIn}
                    icon={
                      <Add
                        sx={{
                          fontSize: '1rem',
                          color: Number(thumbnailSize.toFixed(1)) >= 100 ? 'inherit' : '#344054',
                        }}
                      />
                    }
                    disabled={Number(thumbnailSize.toFixed(1)) >= 100}
                    position="right"
                  />
                </div>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate('../files');
                  }}
                  sx={{ ...imageContainerStyles.buttonStyles, backgroundColor: 'primary.light' }}
                >
                  Complete
                </Button>
              </div>
            </Stack>
          </div>
        </div>
        <Divider sx={{ marginX: '14px', marginY: '4px' }} />
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ backgroundColor: 'selectedGrey.main', pb: '1rem' }}
        >
          <Stack direction="row" justifyContent="left" sx={{ ml: '1.2rem' }}>
            <Button
              onClick={() => setFilterBy('ALL')}
              sx={{
                ...imageContainerStyles.tabStyles,
                ...(filterBy === 'ALL' && imageContainerStyles.styledUnderline),
              }}
            >
              All{' '}
              <Chip
                label={filteredImageLists?.ALL?.length ?? 0}
                sx={imageContainerStyles.chipStyles}
              />
            </Button>
            <Button
              onClick={() => {
                setFilterBy('BLANK');
                setSelectedPages([]);
              }}
              sx={{
                ...imageContainerStyles.tabStyles,
                ...(filterBy === 'BLANK' && imageContainerStyles.styledUnderline),
              }}
            >
              Blank
              <Chip
                label={filteredImageLists?.BLANK?.length ?? 0}
                sx={imageContainerStyles.chipStyles}
              />
            </Button>
            <Button
              onClick={() => {
                setFilterBy('IMAGE');
                setSelectedPages([]);
              }}
              sx={{
                ...imageContainerStyles.tabStyles,
                ...(filterBy === 'IMAGE' && imageContainerStyles.styledUnderline),
              }}
            >
              Image
              <Chip
                label={filteredImageLists?.IMAGE?.length ?? 0}
                sx={imageContainerStyles.chipStyles}
              />
            </Button>
            <Button
              onClick={() => {
                setFilterBy('NEITHER');
                setSelectedPages([]);
              }}
              sx={{
                ...imageContainerStyles.tabStyles,
                ...(filterBy === 'NEITHER' && imageContainerStyles.styledUnderline),
              }}
            >
              Neither
              <Chip
                label={filteredImageLists?.NEITHER?.length ?? 0}
                sx={imageContainerStyles.chipStyles}
              />
            </Button>
            <Divider />
          </Stack>

          <div className="select-all-button">
            <Radio
              checked={selectedPages.length === filteredImageLists[filterBy]?.length}
              onClick={() => toggleSelectAll()}
              sx={{ marginRight: '0.5rem' }}
            />
            Select All{' '}
          </div>
        </Stack>
      </AppBar>
      <Backdrop
        open={isLoadingImagePages || loadingChanges}
        style={{ zIndex: '1000', position: 'absolute', opacity: 0.9 }}
      >
        <CircularProgress size="15rem" color="secondary" thickness={4} sx={{ padding: '3.5rem' }} />
      </Backdrop>
      <div className="image-container-styles">
        {imagePages &&
          (filteredImageLists[filterBy]?.length > 0 ? (
            filteredImageLists[filterBy]?.map((page: any) => {
              return (
                <div
                  className="image-box"
                  style={{
                    border: selectedPages.includes(page.id)
                      ? '1px solid #375d9f'
                      : '1px solid #f3f5fc',

                    width: thumbnailSize * 4.8,
                    height: thumbnailSize * 6.2,
                    margin: 2,
                  }}
                  onClick={() => handleAddRemoveSelectPage(page.id)}
                >
                  <img
                    style={{
                      maxWidth: thumbnailSize * 4.5,
                      margin: 5,
                      filter: blur ? 'blur(2px)' : 'none',
                    }}
                    draggable="false"
                    src={page.thumbnail_url ?? ''}
                    alt="thumbnail"
                  />
                  <Checkbox
                    icon={<CircleOutlined />}
                    checkedIcon={<CheckCircle />}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      borderRadius: 27,
                    }}
                    checked={selectedPages.includes(page.id)}
                  />
                </div>
              );
            })
          ) : (
            <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
              No results found
            </div>
          ))}
      </div>
    </>
  );
}

const imageContainerStyles = {
  chipStyles: { ml: '0.5rem', height: '1.4rem', fontSize: '0.65rem' },
  tabStyles: {
    fontSize: '0.7rem',
    mr: '0.5rem',
    verticalAlign: 'bottom',
    height: '4px',
    paddingRight: '0.5rem',
    display: 'inline-block !important',
    '&:hover': {
      position: 'relative',
      cursor: 'pointer',
      display: 'inline-block !important',
      '::after': {
        content: '""',
        marginTop: '6px',
        height: '4px',
        background: '#A8FCDC',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        display: 'block',
      },
    },
  },
  styledUnderline: {
    position: 'relative',
    cursor: 'pointer',
    display: 'inline-block !important',
    '::after': {
      content: '""',
      marginTop: '6px',
      height: '4px',
      background: '#6CE9A6',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      display: 'block',
    },
  },
  buttonStyles: {
    height: '1.6rem',
    fontSize: '0.8rem',
    mx: '0.2rem',
    width: '6rem',
  },
  appBarStyles: {
    width: '100%',
    margin: '0 auto',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: 'none',
    height: '120px',
    borderRadius: 0,
    position: 'relative',
    left: '-2px',
    backgroundColor: 'selectedGrey.main',
  },
};
