/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Button, Typography, Box } from '@mui/material';

/**
 * Drag and drop file upload component.
 *
 * Required Props:
 * @param files - The array of files this component uploads to.
 * @param handleFileUpload - The function to add files to the files array.
 * @param handleFileDelete - The function to delete files from the files array.
 */
function FileUploadBox(props) {
  const { uploadFiles } = props;

  return (
    <Box
      style={{
        maxWidth: '100%',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <DragAndDrop {...props} handleDrop={uploadFiles}>
        <Box
          sx={{
            height: 300,
            width: 250,
            display: 'block',
            border: 'thin solid black',
          }}
        />
      </DragAndDrop>
    </Box>
  );
}

/*
    
*/
class DragAndDrop extends React.Component {
  state = {
    drag: false,
  };

  dropRef = React.createRef();

  // eslint-disable-next-line class-methods-use-this
  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isNaN(this.dragCounter)) {
      this.dragCounter = 0;
    }
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  };

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  componentDidMount() {
    const div = this.dropRef.current;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);
  }

  componentWillUnmount() {
    const div = this.dropRef.current;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', this.handleDrag);
    div.removeEventListener('drop', this.handleDrop);
  }

  render() {
    return (
      <Box
        sx={{
          display: 'inline-block',
          position: 'relative',
          width: this.props.boxWidth || 470,
          height: this.props.boxHeight || 180,
        }}
        ref={this.dropRef}
      >
        <Box
          sx={{
            backgroundColor: this.state.drag ? '#DCE4FE' : '#FFFFF',
            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' opacity='50%' fill='none' rx='13' ry='13' stroke='%23333' stroke-width='3' stroke-dasharray='18%2c 18' stroke-dashoffset='1' stroke-linecap='round'/%3e%3c/svg%3e")`,
            borderRadius: '15px',
            textAlign: 'center',
            height: '100%',
            color: 'grey',
            fontSize: 16,
          }}
        >
          <Box sx={{ marginTop: '16px' }}>
            <Typography variant="body2" sx={{ fontSize: '0.8rem', paddingTop: '1rem' }}>
              Drop Files Here
            </Typography>
            <Typography variant="body2" sx={{ paddingTop: '1rem' }}>
              or
            </Typography>
            <label htmlFor="click-file-upload">
              <Button variant="contained" component="span" classes="primary" sx={{ mt: '1rem' }}>
                Browse
              </Button>
            </label>
            <input
              id="click-file-upload"
              type="file"
              style={{ display: 'none' }}
              onChange={(event) => this.props.handleDrop(event.target.files)}
              multiple
            />
            <Typography
              sx={{
                marginTop: 1.5,
                fontSize: '0.7rem',
                fontWeight: '700',
                opacity: '70%',
              }}
            >
              Accepted format(s): PDF
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default FileUploadBox;
