import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

export default function IconAttachment(props: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.5832 4.38017V13.7503C14.5832 16.2816 12.5311 18.3337 9.99984 18.3337C7.46853 18.3337 5.4165 16.2816 5.4165 13.7503V4.72255C5.4165 3.03501 6.78452 1.66699 8.47206 1.66699C10.1596 1.66699 11.5276 3.03501 11.5276 4.72255V13.7051C11.5276 14.5489 10.8436 15.2329 9.99984 15.2329C9.15607 15.2329 8.47206 14.5489 8.47206 13.7051V5.54296"
        stroke={props?.style?.color ?? 'var(--color-icon-grey)'}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
