import {
  Box,
  Typography,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  Card,
  CardContent,
  Popover,
  Divider,
} from '@mui/material';
import { useState } from 'react';
import { shallow } from 'zustand/shallow';
import useReportsStore from './useReportsStore';

function ReportsFilterMenu(props) {
  const { filtersOpen, setFiltersOpen, anchorEl, setAnchorEl } = props;

  const {
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin = {
      horizontal: 'center',
      vertical: 0,
    },
  } = props;

  const { createdDate, setCreatedDate, reportType, setReportType } = useReportsStore(
    (state) => ({
      createdDate: state.createdDate,
      setCreatedDate: state.setCreatedDate,
      reportType: state.reportType,
      setReportType: state.setReportType,
    }),
    shallow,
  );
  const [currentReportType, setCurrentReportType] = useState(reportType ?? '');
  const [currentCreatedDate, setCurrentCreatedDate] = useState(createdDate || '');

  const handleCancel = () => {
    setCurrentCreatedDate('');
    setCurrentReportType('');
    setFiltersOpen(false);
    setAnchorEl(null);
  };

  const handleApplyFilters = () => {
    setReportType(currentReportType);
    setCreatedDate(currentCreatedDate);
    setFiltersOpen(false);
    setAnchorEl(null);
  };

  return (
    <Popover
      id="filter-popover"
      open={filtersOpen}
      anchorEl={anchorEl}
      onClose={() => {
        setFiltersOpen(false);
        setAnchorEl(null);
      }}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      sx={{
        '&:hover': {
          border: 'none',
        },
        '& .MuiPaper-root': {
          borderRadius: 3,
          border: 'none',
        },
        marginTop: '1rem',
      }}
    >
      <Card
        variant="outlined"
        sx={{
          height: '25.75rem',
          width: '26rem',
          '&:hover': {
            border: 'none',
          },
        }}
      >
        <CardContent>
          <Box
            sx={{
              paddingLeft: '1.5rem',
              paddingTop: '1.5rem',
            }}
          >
            <Typography
              onClick={() => {
                setCurrentReportType('');
                setCurrentCreatedDate('');
              }}
              sx={reportDisplayStyles.resetButton}
            >
              Reset
            </Typography>
            <Typography sx={{ fontWeight: 600, fontSize: '1.15rem', color: '#475467' }}>
              Filters
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '0.9rem',
                marginBottom: '0.8rem',
                color: '#475467',
              }}
            >
              Select filters below to apply.
            </Typography>
          </Box>
          <Divider sx={{ width: '50rem', marginLeft: '-20rem' }} />
          <Box
            sx={{
              paddingLeft: '1.5rem',
              paddingTop: '1.5rem',
            }}
          >
            <Typography sx={{ ...reportDisplayStyles.labels, marginTop: '1.8rem' }}>
              Report type
            </Typography>
            <FormControl sx={{ minWidth: '100%' }}>
              <InputLabel
                shrink={false}
                sx={{ fontSize: '1rem', opacity: '70%', marginTop: '-0.4rem' }}
              >
                {currentReportType === '' && 'Type'}
              </InputLabel>
              <Select
                variant="outlined"
                value={currentReportType}
                onChange={(e) => setCurrentReportType(e.target.value)}
                sx={reportDisplayStyles.nameField}
              >
                <MenuItem value="TIMELINE_REPORT" sx={{ height: 36 }}>
                  Index
                </MenuItem>
                <MenuItem value="WRITTEN_REPORT" sx={{ height: 36 }}>
                  Written Report
                </MenuItem>
                <MenuItem value="NOTES_TABLE" sx={{ height: 36 }}>
                  Notes Table
                </MenuItem>
              </Select>
            </FormControl>
            <Typography sx={{ ...reportDisplayStyles.labels, marginTop: '1.8rem' }}>
              Date created
            </Typography>
            <TextField
              type="date"
              width="100%"
              name="createddate"
              sx={reportDisplayStyles.dateField}
              InputProps={{
                inputProps: { min: '1000-01-01', max: '2099-12-31' },
                role: 'textbox',
                sx: { fontSize: '0.8rem' },
              }}
              value={currentCreatedDate}
              onChange={(e) => setCurrentCreatedDate(e.target.value)}
            />
          </Box>
          <Divider sx={{ width: '50rem', marginLeft: '-20rem' }} />
          <Box sx={{ marginY: '1rem', textAlign: 'center' }}>
            <Button
              variant="outlined"
              onClick={() => handleCancel()}
              sx={{
                width: '10.8rem',
                mr: '0.5rem',
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => handleApplyFilters()}
              sx={{
                width: '10.8rem',
                backgroundColor: 'primary.light',
              }}
            >
              Apply
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Popover>
  );
}

export default ReportsFilterMenu;

const reportDisplayStyles = {
  resetButton: {
    position: 'absolute',
    top: '0.7rem',
    right: '0.8rem',
    fontWeight: 600,
    color: '#1E407D',
    fontSize: '0.88rem',
    cursor: 'pointer',
  },
  nameField: {
    width: '92%',
  },
  dateField: {
    marginTop: '0.6rem',
    marginBottom: '1.7rem',
    width: '92%',
  },
  labels: {
    fontWeight: 500,
    fontSize: '0.9rem',
    color: '#475467',
  },
};
