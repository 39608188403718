import moment from 'moment';
import {
  CustomTag,
  NoteImageInput,
  NoteImageObject,
  NotesDocument,
  StringFilter,
  IntFilter,
} from '../../../__generated__/graphql';
import { Note, NoteImage, NoteTag } from '../types/noteTypes';

const buildBaseFilter = (value: string | bigint, filter: keyof StringFilter = 'equals') => ({
  [filter]: value,
});

export const convertObjectToWhereInput = (object: {
  [key: string]: string | bigint;
}): { [key: string]: StringFilter | IntFilter } => {
  const whereInput: { [key: string]: StringFilter | IntFilter } = {};

  Object.entries(object).forEach(([key, value]) => {
    whereInput[key] = buildBaseFilter(value);
  });

  return whereInput;
};

export const formatNoteImageForGQL = (image: NoteImage): NoteImageInput => ({
  start_x: image.coordinates.sx,
  end_x: image.coordinates.ex,
  start_y: image.coordinates.sy,
  end_y: image.coordinates.ey,
  page_id: Number(image.pageID),
});

export const formatNoteDbResult = (note: NotesDocument): Note => ({
  noteID: note.id,
  caseID: note.case_id,
  documentID: note.document_id,
  pageID: note.page_id,
  ownerID: note.owner_id,
  fileName: note.fileName ?? '',

  title: note.title,
  physician: note.physician,
  body: note.body,
  date: note.date != null ? moment.utc(note.date).format('YYYY-MM-DD') : undefined,
  createdDate: note.creation_date,
  lastModified: note.last_modified_date,
  privateNote: note.private,
  pageNumber: note.page?.page_number ?? 0,

  tags: note.note_tags?.map(formatGQLNoteTagForFrontEnd) ?? [],
  images: note.note_images?.map(formatGQLNoteImageForFrontEnd) ?? [],
});

const formatGQLNoteTagForFrontEnd = ({ customTag }: { customTag?: CustomTag | null }): NoteTag => ({
  tagID: customTag?.tagID ?? '',
  title: customTag?.title ?? '',
  color: customTag?.color ?? '',
  userID: customTag?.userID ?? '',
});

const formatGQLNoteImageForFrontEnd = (image: NoteImageObject): NoteImage => ({
  imageID: image.id,
  pageID: image.page_id,
  coordinates: {
    sx: image.start_x,
    sy: image.start_y,
    ex: image.end_x,
    ey: image.end_y,
  },
});
