/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import { Typography, Card, IconButton, Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Close, PictureAsPdf } from '@mui/icons-material';

/**
 *
 * @param {bool} open - Whether the document is in progress and showing
 * @param {String} errorMessage
 * @param {String} fileName
 *  * @param {number} fileProgress
 *  * @param {String} fileSize
 * @param  onClose - Function that is called to close
 *
 */

function DocumentProgressCard(props) {
  const { open, fileName, fileSize, fileProgress = 0, errorMessage, onClose } = props;

  const barColor = () => {
    if (errorMessage) {
      return '#F05353';
    }
    if (fileProgress === 100) {
      return '#33DC9C';
    }
    return '#385D9F';
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: barColor(),
    },
  }));

  return (
    open && (
      <>
        <Card
          open={open}
          key={`document: ${fileName}`}
          onClose={onClose}
          sx={{
            border: 'none',
            width: '29rem',
            height: '2.5rem',
            padding: '0.7rem',
            paddingLeft: '1.2rem',
            display: 'flex',
            marginBottom: '0.1rem',
            flexDirection: 'row',
            '&:hover': {
              border: 'none',
            },
          }}
        >
          <Box
            sx={{
              flex: 2,
              marginRight: !fileSize && !errorMessage && '7rem',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: '0.7rem',
            }}
          >
            <PictureAsPdf
              sx={{
                color: '#385D9F',
                verticalAlign: 'bottom',
                fontSize: '1.1rem',
                marginRight: '1rem',
              }}
            />
            {fileName}
          </Box>
          {errorMessage ? (
            <Typography
              display="inline"
              color="#F05353"
              fontSize="0.65rem"
              sx={{
                textAlign: 'right',
                paddingTop: '0.1rem',
                marginLeft: '1rem',
                marginRight: '1rem',
              }}
            >
              {errorMessage}
            </Typography>
          ) : (
            fileSize && (
              <Typography
                display="inline"
                fontSize="0.65rem"
                sx={{
                  textAlign: 'right',
                  paddingTop: '0.1rem',
                  marginLeft: '1rem',
                  marginRight: '3rem',
                }}
              >
                {fileSize}
              </Typography>
            )
          )}
          <Box
            sx={{
              flex: 2,
              paddingTop: '0.4rem',
              paddingRight: '0.5rem',
            }}
          >
            <BorderLinearProgress
              variant="determinate"
              value={fileProgress}
              fileprogress={fileProgress}
            />
          </Box>
          <Box>
            {onClose && (
              <IconButton
                onClick={() => onClose()}
                sx={{
                  padding: 0,
                  margin: 0,
                  flex: 1,
                  marginRight: '0.5rem',
                  paddingBottom: '0.4rem',
                }}
              >
                <Close sx={{ fontSize: '16px' }} />
              </IconButton>
            )}
          </Box>
        </Card>
        <Divider />
      </>
    )
  );
}

DocumentProgressCard.propTypes = {
  open: PropTypes.bool,
  errorMessage: PropTypes.string,
  fileName: PropTypes.string,
  fileSize: PropTypes.string,
  fileProgress: PropTypes.number,
  onClose: PropTypes.func,
};

export default DocumentProgressCard;
