import axios from 'axios';
import apiUrl from '../../library/utilities/apiUrl';

export function deleteDocument(caseID, documentID) {
  return axios.post(
    `${apiUrl}deleteDocument`,
    {
      caseID,
      documentID,
    },
    { baseURL: apiUrl },
  );
}

export function renameDocument(caseID, fileID, newFileName) {
  return axios.post(
    `${apiUrl}renameDocument`,
    { caseID, fileID, newFileName },
    { baseURL: apiUrl },
  );
}
