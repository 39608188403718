import { useQuery } from '@tanstack/react-query';
import { authenticatedRequest } from '../../api';

async function fetchTimelineExports({ caseID }) {
  const res = await authenticatedRequest({
    method: 'GET',
    url: `/api/v1/cases/${caseID}/exports`,
  });
  return res.data;
}

export default function useTimelineExports({ caseID }) {
  return useQuery(['timelineExports', caseID], () => fetchTimelineExports({ caseID }));
}
