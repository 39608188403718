import React from 'react';
import { useTheme } from '@mui/styles';
import { darken } from '@mui/material/styles';
import { ThemeType } from '../../../theme';
import ErrorScreenButtonBase from './ErrorScreenButtonBase';

type Props = React.PropsWithChildren<{ onClick: () => any }>;

function ErrorScreenOutlinedButton({ children, onClick }: Props) {
  const { transitions }: ThemeType = useTheme();

  return (
    <ErrorScreenButtonBase
      onClick={onClick}
      sx={{
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        backgroundColor: '#F9FAFB',
        border: '1px solid #D0D5DD',
        transition: transitions.create('border-color', {
          easing: transitions.easing.easeIn,
        }),
        willChange: 'border-color',
        '&:hover': {
          borderColor: darken('#D0D5DD', 0.2),
          transition: transitions.create('border-color', {
            easing: transitions.easing.easeIn,
          }),
        },
      }}
    >
      {children}
    </ErrorScreenButtonBase>
  );
}

export default ErrorScreenOutlinedButton;
