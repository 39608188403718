import convertEnumToFormattedString from '../../../../library/utilities/convertEnumToFormattedString';
import { DisplayableTag, Tag } from '../../types/timelineTypes';

export const toDisplayableTag = (tag: Tag): DisplayableTag => ({
  id: tag.id,
  value: tag.name,
  label: convertEnumToFormattedString(tag.name),
  parent: tag.parent_tag_id,
  origin: tag.origin,
});

export const toDisplayableTagSavingOriginalValues = (tag: Tag): DisplayableTag & Tag => ({
  ...tag,
  ...toDisplayableTag(tag),
});
