import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import AddIcon from '@mui/icons-material/Add';
import Chip from '../Chip/Chip';
import { mapCompare, compareAlphaNumeric } from '../../../../utils/compare';
import InputWithIcon from '../InputWithIcon/InputWithIcon';
import SearchIcon from '../../../icons/SearchIcon';
import UpArrowIcon from '../../../icons/UpArrow';
import DownArrowIcon from '../../../icons/DownArrow';
import './multi-select-dropdown.css';
import Theme from '../../../../theme';

type MenuPosition = {
  top: number;
  left: number;
} | null;

type Option = {
  label: string;
  value: string;
};

type SelectableOption = Option & {
  isSelected: boolean;
};

type Props = {
  selectedValues: Option[];
  options: Option[];
  onChange: (newOptions: Option[]) => void;
  shouldOpenByDefault?: boolean;
  style?: React.CSSProperties;
  dropdownButton?: boolean;
  searchBar?: boolean;
};

export default function MultiSelectDropdown({
  selectedValues,
  options,
  onChange,
  shouldOpenByDefault = false,
  style,
  dropdownButton,
  searchBar = true,
}: Props) {
  const [isOpen, setIsOpen] = useState(shouldOpenByDefault);
  const [menuPosition, setMenuPosition] = useState<MenuPosition>(null);
  const [filteredOptions, setFilteredOptions] = useState<SelectableOption[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const anchorRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const rect = anchorRef.current ? anchorRef.current?.getBoundingClientRect() : null;
      if (rect) {
        setMenuPosition({
          top: rect.top + rect.height + window.scrollY,
          left: rect.left + window.scrollX,
        });
      }
    };

    document.getElementById('app-container')?.addEventListener('scroll', handleScroll);
    document
      .getElementById('scrollable-report-container')
      ?.addEventListener('scroll', handleScroll);

    return () => {
      document.getElementById('app-container')?.removeEventListener('scroll', handleScroll);
      document
        .getElementById('scrollable-report-container')
        ?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        anchorRef.current &&
        !anchorRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    const rect = anchorRef.current ? anchorRef.current.getBoundingClientRect() : null;

    if (isOpen && rect) {
      setMenuPosition({
        top: rect.top + rect.height + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    let filteredSelectedOptions = selectedValues.map((option) => ({
      ...option,
      isSelected: true,
    }));

    let filteredUnselectedOptions = options
      .filter((option) => {
        return !selectedValues.some((selectedOption) => selectedOption.value === option.value);
      })
      .map((option) => ({
        ...option,
        isSelected: false,
      }));

    if (searchValue) {
      filteredSelectedOptions = filteredSelectedOptions.filter((selectedOption) =>
        isPartialMatch(searchValue, selectedOption.label),
      );
      filteredUnselectedOptions = filteredUnselectedOptions.filter((option) =>
        isPartialMatch(searchValue, option.label),
      );
    }

    filteredSelectedOptions.sort(
      mapCompare<Option, string>((option) => option.label, compareAlphaNumeric.asc),
    );
    filteredUnselectedOptions.sort(
      mapCompare<Option, string>((option) => option.label, compareAlphaNumeric.asc),
    );

    setFilteredOptions([...filteredSelectedOptions, ...filteredUnselectedOptions]);
  }, [searchValue, selectedValues, options]);

  const removeSelectedOption = (optionToRemove: Option) => {
    const newOptions = selectedValues.filter((option) => option.value !== optionToRemove.value);
    onChange(newOptions);
  };

  const removeChipOnClick = (event: React.MouseEvent, optionToRemove: Option) => {
    event.stopPropagation();
    removeSelectedOption(optionToRemove);
  };

  const addSelectedOption = (optionToAdd: Option) => {
    const newOptions = [...selectedValues, optionToAdd];
    onChange(newOptions);
  };

  const handleDropdownOptionOnClick = (option: SelectableOption) => {
    if (option.isSelected) {
      removeSelectedOption(option);
    } else {
      addSelectedOption(option);
    }
  };

  const handleToggleDropdownOpen = () => {
    setIsOpen(!isOpen);
  };

  const dropdownMenu = menuPosition != null && (
    <div
      className="dropdown-menuList"
      ref={dropdownRef}
      style={{
        ...style,
        transform: `translate3d(${menuPosition.left}px, ${menuPosition.top}px, 0)`,
      }}
    >
      {searchBar && (
        <InputWithIcon
          Icon={<SearchIcon />}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          inputProps={{
            placeholder: 'Search',
          }}
          containerProps={{
            style: {
              margin: '0.5rem',
              paddingLeft: '0.5rem',
              paddingRight: '0.5rem',
            },
          }}
        />
      )}
      {filteredOptions.map((option) => {
        return (
          <div
            key={option.value}
            className="dropdown-menuItem"
            onClick={() => handleDropdownOptionOnClick(option)}
            style={{
              backgroundColor: option.isSelected ? 'var(--color-selected-grey-main)' : 'white',
            }}
          >
            <input type="checkbox" checked={option.isSelected} readOnly />
            <span
              style={{
                paddingLeft: '1rem',
              }}
            >
              {option.label}
            </span>
          </div>
        );
      })}
    </div>
  );

  const iconStyle = {
    width: '20px',
    height: '20px',
  };

  const arrow = isOpen ? <UpArrowIcon style={iconStyle} /> : <DownArrowIcon style={iconStyle} />;

  return (
    <>
      <div className="dropdown" onClick={handleToggleDropdownOpen} ref={anchorRef}>
        <div className="dropdown-selectedValues">
          {selectedValues.map((selectedOption) => {
            return (
              <Chip
                key={selectedOption.value}
                label={selectedOption.label}
                onClick={(event) => removeChipOnClick(event, selectedOption)}
                style={{ borderColor: Theme.palette.borderGrey.main }}
              />
            );
          })}
          {dropdownButton && (
            <Chip
              label="Add Tag"
              onClick={handleToggleDropdownOpen}
              style={{
                backgroundColor: 'white',
                borderColor: 'white',
                color: Theme.palette.primary.light,
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
              chipIcon={
                <AddIcon
                  onClick={handleToggleDropdownOpen}
                  sx={{
                    color: Theme.palette.primary.light,
                    marginLeft: '-4px',
                    fontSize: '17px',
                    marginBottom: '2px',
                  }}
                />
              }
            />
          )}
        </div>
        {!dropdownButton && arrow}
      </div>
      {isOpen && ReactDOM.createPortal(dropdownMenu, document.body)}
    </>
  );
}

function isPartialMatch(query: string, target: string): boolean {
  return target?.toLowerCase().includes(query.toLowerCase());
}
