import { useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  CreateNoteInput,
  CreateNoteMutationVariables,
  NoteFragmentFragmentDoc,
  useCreateNoteMutation,
} from '../../../__generated__/graphql';
import { Note, NoteTag } from '../types/noteTypes';
import { formatNoteImageForGQL } from './gqlUtils';

type PartialNote = Pick<
  Note,
  | 'documentID'
  | 'pageID'
  | 'body'
  | 'title'
  | 'date'
  | 'physician'
  | 'fileName'
  | 'privateNote'
  | 'tags'
  | 'images'
>;
// probably need a useMemo in here
const useCreateNote = () => {
  const [createNoteMutation, mutationStateInfo] = useCreateNoteMutation();

  // change interface
  const createNoteCallback = useCallback(
    async ({
      variables,
    }: {
      variables: {
        case_id: CreateNoteMutationVariables['case_id'];
        data: PartialNote;
      };
    }) => {
      try {
        await createNoteMutation({
          variables: {
            case_id: variables.case_id,
            data: formatCreateNoteData(variables.data),
          },
          update: (cache, { data }) => {
            cache.modify({
              fields: {
                notes(existingNotes = []) {
                  const newNoteRef = cache.writeFragment({
                    data: data?.createNote,
                    fragment: NoteFragmentFragmentDoc,
                    fragmentName: 'NoteFragment',
                  });
                  return [...existingNotes, newNoteRef];
                },
              },
            });
          },
        });
      } catch (err) {
        console.error('error creating note', err);
        toast.error('Failed to create note');
        throw err;
      }
    },
    [createNoteMutation],
  );

  return [createNoteCallback, mutationStateInfo];
};

export default useCreateNote;

const formatCreateNoteData = (note: PartialNote): CreateNoteInput => {
  const formattedData = {
    document_id: note.documentID,
    page_id: note.pageID,
    body: note.body,
    title: note.title,
    date: note.date,
    physician: note.physician,
    fileName: note.fileName,
    private_note: note.privateNote ? 1 : 0,
    tags: note.tags?.map(formatNoteTagForGQL),
    images: note.images?.map((image) => formatNoteImageForGQL(image)),
  };

  return formattedData;
};

const formatNoteTagForGQL = (tag: NoteTag): { tag_id: string } => ({
  tag_id: tag.tagID,
});
