import React from 'react';
import { useTheme } from '@mui/styles';
import ErrorScreenBaseButton from './ErrorScreenButtonBase';
import { ThemeType } from '../../../theme';

function ErrorScreenContainedButton({
  children,
  onClick,
}: React.PropsWithChildren<{ onClick: () => void }>) {
  const { transitions }: ThemeType = useTheme();

  return (
    <ErrorScreenBaseButton
      onClick={onClick}
      sx={{
        border: `1px solid #33DC9C`,
        backgroundColor: '#33DC9C',
        borderColor: '#33DC9C',
        willChange: 'background-color, border-color',
        transition: transitions.create(['background-color', 'border-color'], {
          easing: transitions.easing.easeIn,
        }),
        '&:hover': {
          transition: transitions.create(['background-color', 'border-color'], {
            easing: transitions.easing.easeIn,
          }),
          backgroundColor: '#33DC9C',
          borderColor: '#33DC9C',
        },
      }}
    >
      {children}
    </ErrorScreenBaseButton>
  );
}

export default ErrorScreenContainedButton;
