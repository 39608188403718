import { Button, TextField, Box, Typography, Divider, FormControl, Alert } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Success from '../../resources/images/Success.gif';
import LoginContainer from './LoginContainer';

function ContactRequest() {
  const location = useLocation();
  const [action, setAction] = useState(''); //CONTACT,DEMO,SUCCESS
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [errorVisible, setErrorVisible] = useState(false);

  useEffect(() => {
    if (location.pathname.indexOf('/request-demo') === 0) {
      setAction('DEMO');
      setMessage('Demo Requested');
    } else {
      setAction('CONTACT');
      setMessage('');
    }
  }, [location.pathname]);

  const sendContactEmail = async () => {
    if (
      (action === 'DEMO' && email && name) ||
      (action === 'CONTACT' && email && name && message)
    ) {
      try {
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        await axios
          .post(`https://formsubmit.co/ajax/info@siftmed.ca`, {
            email,
            name,
            message,
          })
          .then(() => {
            setAction('SUCCESS');
            setEmail('');
            setMessage('');
            setName('');
          });
      } catch (e) {
        console.log(e);
        toast.error('Failed to send...', {
          toastId: 'email',
        });
      }
    } else {
      setErrorVisible(true);
    }
  };

  return (
    <LoginContainer>
      <Box sx={{ textAlign: 'center' }}>
        {action === 'CONTACT' && (
          <>
            <Box sx={{ textAlign: 'center', marginBottom: '1rem' }}>
              <Typography sx={{ fontWeight: 700, marginTop: '1.5rem' }}>
                How can we help?
              </Typography>
              <Typography variant="loginScreenBody">
                Wanna chat with a human? Send us a message!
              </Typography>
            </Box>
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <TextField
                value={name}
                onChange={(event) => setName(event.target.value)}
                label="Name"
                sx={styles.textFieldStyles}
                className="fs-exclude"
              />
              <TextField
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                label="Email"
                sx={styles.textFieldStyles}
                className="fs-exclude"
              />
              <TextField
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                label="Message"
                minRows={4}
                maxRows={4}
                multiline
                inputProps={{
                  style: {
                    fontSize: 12,
                    overflowY: 'scroll !important',
                  },
                }}
                sx={{
                  ...styles.textFieldStyles,
                  '& .MuiOutlinedInput-root': {
                    height: 90,
                    paddingRight: 0.8,
                  },
                }}
              />
              <Button
                sx={styles.buttonStyles}
                type="submit"
                variant="contained"
                onClick={() => sendContactEmail()}
              >
                Send
              </Button>
              {errorVisible && (
                <ErrorAlert
                  errorMessage="Please include your name, email and message."
                  onClose={() => setErrorVisible(false)}
                />
              )}
            </FormControl>
          </>
        )}
        {action === 'DEMO' && (
          <>
            <Box sx={{ textAlign: 'center', marginBottom: '1rem' }}>
              <Typography sx={{ fontWeight: 700, marginTop: '1.5rem' }}>
                Start Your SiftMed Experience
              </Typography>
              <Typography variant="loginScreenBody">
                Get a personalized demo that suites your needs!
              </Typography>
            </Box>
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <TextField
                value={name}
                onChange={(event) => setName(event.target.value)}
                label="Name"
                sx={styles.textFieldStyles}
                className="fs-exclude"
              />
              <TextField
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                label="Email"
                sx={styles.textFieldStyles}
                className="fs-exclude"
              />
              <Button
                sx={styles.buttonStyles}
                type="submit"
                variant="contained"
                onClick={() => sendContactEmail()}
              >
                Request Demo
              </Button>
              {errorVisible && (
                <ErrorAlert
                  errorMessage="Please include both your name and email."
                  onClose={() => setErrorVisible(false)}
                />
              )}
            </FormControl>
            <Divider />
            <Box sx={{ width: '100%', textAlign: 'center', marginTop: '2rem' }}>
              <Typography sx={{ fontSize: '0.8rem', display: 'inline' }}>
                Have a question?
              </Typography>
              <Link style={{ textDecoration: 'none' }} to="/contact-us">
                <Typography
                  sx={{
                    color: 'themePurple.main',
                    fontWeight: 700,
                    fontSize: '0.8rem',
                    display: 'inline',
                    marginLeft: '0.4rem',
                  }}
                >
                  Contact Us
                </Typography>
              </Link>
            </Box>
          </>
        )}
      </Box>
      {action === 'SUCCESS' && (
        <Box sx={{ margin: 'auto', textAlign: 'center', marginTop: '5%' }}>
          <img src={Success} style={{ height: '16rem', width: 'auto' }} alt="loading..." />
          <Typography sx={{ fontWeight: 700, marginTop: '-1rem' }}>Hooray!</Typography>
          <Typography sx={{ fontSize: '0.8rem', fontWeight: 600, opacity: '50%' }}>
            We've received your message!
          </Typography>
        </Box>
      )}
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Link to="/login" style={{ textDecoration: 'none' }}>
          <Button variant="outlined" size="small" sx={{ mt: '2rem' }}>
            <ArrowBackIosIcon
              style={{
                fontSize: '0.7rem',
                color: 'primary.light',
                fontWeight: 700,
                '&:hover': {
                  color: 'primary.dark',
                },
              }}
            />
            Back to Login
          </Button>
        </Link>
      </Box>
    </LoginContainer>
  );
}

function ErrorAlert({ errorMessage, onClose }) {
  return (
    <div style={{ textAlign: 'center' }}>
      {errorMessage !== '' && (
        <Alert severity="error" onClose={onClose}>
          {errorMessage}
        </Alert>
      )}
    </div>
  );
}

const styles = {
  textFieldStyles: { marginTop: '1rem' },
  buttonStyles: {
    marginTop: '1rem',
    fontSize: '0.85rem',
    padding: '0.5rem',
    marginBottom: '1.5rem',
    backgroundColor: 'themePurple.main',
    '&:hover': {
      backgroundColor: 'themePurple.dark',
    },
    '&:disabled': {
      backgroundColor: 'themePurple.light',
    },
  },
};

export default ContactRequest;
