import { Box, Button, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Close, ErrorOutline } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import CaseContext from '../Case/CaseContext';
import MergeSplitTimelineMenu from './Components/MergeSplitTimelineMenu';
import useTimelineStore from './useTimelineStore';

export default function CreateMergeSplitAction(props) {
  const { mergeTimeline, currentDocumentID } = props;
  const [mergeSplitMenuVisible, setMergeSplitMenuVisible] = useState(false);
  const { caseInstance } = useContext(CaseContext);
  const { timelineID } = useParams();

  const {
    multipleEntriesSelected,
    clearSelection,
    isAtLeastOneTimelineEntryPageSelected,
    tooManySelectedError,
    splitInProgress,
    setShowCheckboxes,
    toggleTooManyError,
  } = useTimelineStore(
    (state) => ({
      multipleEntriesSelected: state.areMultipleTimelineEntriesSelected(),
      clearSelection: state.clearSelection,
      isAtLeastOneTimelineEntryPageSelected: state.isAtLeastOneTimelineEntryPageSelected(),
      tooManySelectedError: state.tooManyError,
      showCheckboxes: state.showCheckboxes,
      splitInProgress: state.splitInProgress,
      setShowCheckboxes: state.setShowCheckboxes,
      toggleTooManyError: state.toggleTooManyError,
    }),
    shallow,
  );

  // Clears merge and split data when component unmounts
  useEffect(
    () => () => {
      clearSelection();
      setShowCheckboxes(false);
    },
    [timelineID, clearSelection, setShowCheckboxes],
  );

  return (
    <Box
      id="merge-menu-box"
      textAlign="center"
      sx={{
        backgroundColor: 'primary.light',
        height: '3em',
        paddingTop: 0.8,
        marginRight: 3.5,
        marginLeft: 0.5,
        borderRadius: 3,
        maxWidth: '100%',
        display: 'flex',
        position: 'relative',
        justifyContent: 'space-between',
      }}
    >
      {!splitInProgress &&
        (!multipleEntriesSelected ? (
          <Typography
            fontSize="0.8em"
            color="#FFFFFF"
            fontWeight={500}
            marginTop="0.5rem"
            marginLeft="1.5rem"
            display="inline"
          >
            Select Another Timeline Entry To Merge
          </Typography>
        ) : (
          <Button
            disabled={!multipleEntriesSelected}
            variant="contained"
            classes="pre-merge-split"
            onClick={() => setMergeSplitMenuVisible(!mergeSplitMenuVisible)}
          >
            Merge Timeline Entries
          </Button>
        ))}
      {splitInProgress && (
        <>
          {tooManySelectedError && (
            <Typography
              fontSize="0.8rem"
              color="#FFFFFF"
              fontWeight={500}
              marginTop={1}
              sx={{ paddingBottom: 0.8, marginLeft: '1.5rem' }}
            >
              <ErrorOutline
                sx={{
                  fontSize: '1.2rem',
                  marginRight: '0.3rem',
                  marginBottom: '0.2rem',
                }}
              />{' '}
              You cannot select all pages in this entry
            </Typography>
          )}
          {isAtLeastOneTimelineEntryPageSelected && !tooManySelectedError && (
            <Button
              onClick={() => setMergeSplitMenuVisible(!mergeSplitMenuVisible)}
              variant="contained"
              classes="pre-merge-split"
            >
              Split Timeline Entry
            </Button>
          )}
          {!tooManySelectedError && !isAtLeastOneTimelineEntryPageSelected && (
            <Typography
              fontSize="0.8rem"
              color="#FFFFFF"
              fontWeight={500}
              sx={{ paddingBottom: 0.8, marginLeft: '1.5rem' }}
            >
              Please select pages to split to create a new entry
            </Typography>
          )}
        </>
      )}
      <MergeSplitTimelineMenu
        caseID={caseInstance.caseID}
        timelineID={timelineID}
        mergeTimeline={mergeTimeline}
        setMergeSplitMenuVisible={setMergeSplitMenuVisible}
        mergeSplitMenuVisible={mergeSplitMenuVisible}
        currentDocumentID={currentDocumentID}
      />
      <Box>
        <Button
          onClick={() => {
            clearSelection();
            setShowCheckboxes(false);
            if (tooManySelectedError) {
              toggleTooManyError();
            }
          }}
          sx={{
            color: 'white',
            ml: '-1rem',
            display: 'inline',
          }}
        >
          <Close sx={{ fontSize: '1.2rem', marginBottom: '0.4rem' }} />
        </Button>
      </Box>
    </Box>
  );
}
