import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Backdrop from '@mui/material/Backdrop';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as Sentry from '@sentry/react';

import { ClickAwayListener } from '@mui/base';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import FeedbackPopup from '../../components/common/FeedbackPopup';
import APIURL from '../../library/utilities/apiUrl';

function TemplateSetting({ template, refetchTemplates, isMenuOpen, setIsMenuOpen }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeletePromptOpen, setIsDeletePromptOpen] = useState(false);
  const [feedback, setFeedback] = useState('');
  const userID = template.ownerID;
  const { templateId, templateName } = template;

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(!anchorEl ? event.currentTarget : null);
    setIsMenuOpen(!isMenuOpen);
  };

  const openDeletePrompt = () => {
    handleMenuClose();
    setIsDeletePromptOpen(true);
  };

  const handleDeletePromptClose = (e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setIsDeletePromptOpen(false);
  };
  const handleTemplateDelete = async () => {
    setFeedback('deleting');
    await axios
      .post(`${APIURL}deleteTemplate`, {
        userID: userID,
        templateId: templateId,
        templateName: templateName,
      })
      .then(() => {
        refetchTemplates();
      })
      .catch((err) => {
        toast.error('Failed to delete template.', {
          toastId: 'delete_template',
        });
        Sentry.captureException(err);
      });
  };

  const handleVisibilityChange = () => {
    setIsMenuOpen(false);
    setFeedback('visibility');
    axios
      .post(`${APIURL}setTemplateVisibility`, {
        templateId: templateId,
        private: !template.private,
      })
      .then(() => {
        refetchTemplates();
      })
      .catch((err) => {
        toast.error('Failed to change template visibility.', {
          toastId: 'change_template_visibility',
        });
        Sentry.captureException(err);
      });
  };

  return (
    <>
      <IconButton onClick={handleMenuClick} size="small" edge="end" disabled={feedback !== ''}>
        <MoreVertIcon style={{ margin: 'auto', cursor: 'pointer' }} aria-haspopup="true" />
      </IconButton>
      <Popper
        open={isMenuOpen}
        anchorEl={anchorEl}
        placement="left-start"
        disablePortal
        style={{ zIndex: 100 }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleMenuClose}>
            <MenuList id="menu-list-grow">
              <MenuItem onClick={handleVisibilityChange}>
                {template.private ? (
                  <Visibility sx={{ fontSize: 22, marginRight: '0.35rem' }} />
                ) : (
                  <VisibilityOff sx={{ fontSize: 22, marginRight: '0.35rem' }} />
                )}
                {template.private ? 'Share with organization' : 'Make template private'}
              </MenuItem>
              <MenuItem onClick={openDeletePrompt}>
                <DeleteForeverIcon sx={{ fontSize: 22, marginRight: '0.35rem' }} />
                Delete
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
      <Backdrop
        sx={{
          cursor: 'default',
          zIndex: '99',
        }}
        open={isMenuOpen}
        invisible={true}
        onClick={handleMenuClose}
      />
      <Dialog
        id="delete-dialog"
        open={isDeletePromptOpen}
        onClose={handleDeletePromptClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={handleDeletePromptClose}>
          <Typography variant="h3">Permanently Delete This Template?</Typography>
          <IconButton
            aria-label="close"
            sx={(theme) => ({
              position: 'absolute',
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: theme.palette.grey[500],
            })}
            onClick={handleDeletePromptClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will permanently delete this template. This cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTemplateDelete} variant="outlined" className="confirm-delete">
            Confirm Deletion
          </Button>
        </DialogActions>
      </Dialog>
      <FeedbackPopup
        text={feedback === 'deleting' ? 'Deleting Template...' : 'Changing Visibility...'}
        severity="info"
        open={feedback !== ''}
        onClose={() => setFeedback('')}
      />
    </>
  );
}

export default TemplateSetting;
