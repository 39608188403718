import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

export default function IconMerge(props: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.5 10.0003H17.5M10 1.66699V7.08366M10 7.08366L13.3333 3.75033M10 7.08366L6.66667 3.75033M10 18.3337V12.917M10 12.917L13.3333 16.2503M10 12.917L6.66667 16.2503"
        stroke={props?.style?.color ?? 'var(--color-icon-grey)'}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
