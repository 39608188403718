export default function Reticle() {
  return (
    <svg width="14" height="14" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1875 7.24677H11.5625M3.6875 7.24677H1.0625M7.625 3.30927V0.684265M7.625 13.8093V11.1843M12.875 7.24677C12.875 10.1463 10.5245 12.4968 7.625 12.4968C4.72551 12.4968 2.375 10.1463 2.375 7.24677C2.375 4.34727 4.72551 1.99677 7.625 1.99677C10.5245 1.99677 12.875 4.34727 12.875 7.24677Z"
        stroke="#1E407D"
        strokeWidth="1.3125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
