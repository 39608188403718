import { Tooltip } from '@mui/material';

function FilterControl(props) {
  const { label = '', control, controlStyle = {}, toolTipTitle = '' } = props;

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        marginLeft: 8,
        marginTop: '0.5rem',
      }}
    >
      {label !== '' && (
        <div
          style={{
            flex: '50',
            margin: 'auto',
          }}
        >
          <Tooltip title={toolTipTitle} placement="top">
            <div
              style={{
                fontSize: '0.875rem',
                width: 'fit-content',
                cursor: toolTipTitle !== '' ? 'help' : 'default',
              }}
            >
              {label}
            </div>
          </Tooltip>
        </div>
      )}
      <div style={{ ...controlStyle, flex: 50 }}>{control}</div>
    </div>
  );
}

export default FilterControl;
