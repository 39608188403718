import { Box, Menu, Typography, CircularProgress } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { formatSegmentDate, isValidDate } from '../../../library/utilities/useDates';
import useUpdateEntryDetails from '../gql/useUpdateEntryDetails';

export default function DateSelector({ entryID, entryDate }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [date, setDate] = React.useState<string>(entryDate);

  const { updateEntryDate, dateState } = useUpdateEntryDetails();

  const openMenu = (e: any) => {
    if (dateState.loading) {
      return;
    }
    setAnchorEl(e.target);
  };
  const onMenuClose = (d: string) => {
    setAnchorEl(null);

    const newDate = isValidDate(d) ? d : date;

    if (newDate !== entryDate && isValidDate(newDate)) {
      updateEntryDate({ entryID, date: newDate });
    }
  };
  const display = formatSegmentDate(entryDate);

  const handleDateChange = (newDate: Dayjs) => {
    setDate(isValidDate(newDate) ? newDate.format('YYYY-MM-DD') : date);
  };

  return (
    <Box onClick={(e) => e.preventDefault()}>
      <Box
        onClick={openMenu}
        sx={{ cursor: dateState.loading ? 'default' : 'pointer', alignItems: 'center' }}
      >
        <Typography
          // @ts-ignore
          variant="h7"
          sx={{
            color: display !== 'Invalid date' ? 'text.light' : 'text.disabled',
            '&:hover': {
              color: dateState.loading ? 'text.light' : 'primary.light',
            },
          }}
          className="unmask"
        >
          {display === 'Invalid date' ? 'No Date' : display}
        </Typography>
        {dateState.loading && <CircularProgress size={12} sx={{ ml: 0.5 }} />}
      </Box>
      {anchorEl && (
        <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onMenuClose}>
          <Box sx={{ padding: '.5rem', marginTop: '-4px', width: '160px' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="YYYY-MM-DD"
                value={date !== '1900-01-01' ? dayjs(date?.split(' ')[0]) : ''}
                minDate={dayjs('1000-01-01')}
                maxDate={dayjs('2099-12-31')}
                onChange={(e) => handleDateChange(dayjs(e))}
              />
            </LocalizationProvider>
          </Box>
          <Typography
            sx={{
              paddingBottom: '.5rem',
              fontSize: '12px',
              color: 'text.disabled',
              textAlign: 'center',
              cursor: 'pointer',
              '&:hover': {
                color: 'primary.light',
              },
            }}
            onClick={async () => {
              setDate('1900-01-01');
              onMenuClose('1900-01-01');
            }}
          >
            Set as Unknown
          </Typography>
        </Menu>
      )}
    </Box>
  );
}

type Props = {
  entryID: number;
  entryDate: string;
};
