import { DescriptionOutlined, FindInPageOutlined, Image } from '@mui/icons-material';
import { Box, Typography, Tooltip } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import { useParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useUserGroup } from '../MyCases/useCases';
import Folder from '../../components/icons/Folder';
import DuplicateHeaderIcon from '../../components/icons/DuplicateHeader';
import Export from '../../components/icons/Export';
import useNavigationStore from './useNavigationStore';
import useReportsStore from '../ReportEditor/useReportsStore';
import PagesRead from './PagesRead';
import Theme from '../../theme';
import ProcessingFiles from '../../components/icons/ProcessingFiles';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import FileViewer from './FileViewer';
import useCaseFiles from '../Files/useCaseFiles';
import { useImagePages } from '../DocumentGrouping/api-hooks/useGetImagePages';

export default function CaseHeader({ caseInstance, timelineID }) {
  const { data: userGroup } = useUserGroup();
  const location = useLocation();
  const params = useParams();
  const { caseID, fileID: caseFileID } = params;
  const [searchParams] = useSearchParams();
  const currentDocumentID = searchParams.get('documentID') ?? null;

  const isFileProcessor = useIsFileProcessor();
  const navigate = useNavigate();

  const { data: imagePages } = useImagePages(caseID);

  const { setCurrentReport, currentReport, isNotesTableOpen, setIsNotesTableOpen } =
    useReportsStore(
      (state) => ({
        setCurrentReport: state.setCurrentReport,
        currentReport: state.currentReport,
        isNotesTableOpen: state.isNotesTableOpen,
        setIsNotesTableOpen: state.setIsNotesTableOpen,
      }),
      shallow,
    );

  const { data: caseFiles } = useCaseFiles(caseID);
  const currentView = useNavigationStore((state) => state.currentView);
  const setCurrentView = useNavigationStore((state) => state.setCurrentView);

  useEffect(() => {
    if (location.pathname.indexOf('/documents') > -1) {
      setCurrentView('Document');
    } else if (location.pathname.indexOf('/timeline') > -1) {
      setCurrentView('Timeline');
    }
  }, []);

  const sourceUrl = useMemo(
    () => `/case/${caseID}/${currentView === 'Document' ? 'documents' : `timeline/${timelineID}`}`,
    [currentView, caseID, timelineID],
  );

  const openAllTabs =
    caseInstance?.caseStatus === 'READY' ||
    caseInstance?.caseStatus === 'CLOSED' ||
    (caseInstance?.caseStatus === 'REQUIRES_PROCESSOR' && userGroup === 'Processors');
  const noFilesUploaded = caseFiles ? caseFiles.length === 0 : true;
  const viewableFiles = useMemo(() => {
    if (!caseFiles) {
      return false;
    }
    if (noFilesUploaded) {
      return false;
    } // ready for processing
    if (isFileProcessor && caseFiles.filter((doc) => doc.fileStatus === 'UPLOADING').length === 0) {
      return true;
    } // viewable to users
    if (caseFiles.filter((doc) => doc.fileStatus === 'COMPLETE').length > 0) {
      return true;
    }
    return false;
  }, [caseFiles]);

  const processingFiles =
    caseInstance?.caseStatus !== 'READY' &&
    caseInstance?.caseStatus !== 'CLOSED' &&
    !isFileProcessor &&
    !noFilesUploaded;

  const currentFile = caseFiles?.find(
    (file) => file.documentID === caseFileID || file.documentID === currentDocumentID,
  );

  return (
    <Box
      width="100%"
      height="4rem"
      sx={{
        backgroundColor: 'white',
        display: 'flex',
        paddingTop: '1rem',
        paddingBottom: '0.5rem',
        borderBottom: '0.5px solid #00214733',
      }}
    >
      <Box
        sx={{
          marginLeft: '2rem',
        }}
      >
        <Typography
          className="fs-exclude"
          sx={{
            width: '18rem',
            fontSize: '1rem',
            fontWeight: 500,
            overflow: 'auto',
            color: 'primary',
            whiteSpace: 'nowrap',
          }}
        >
          {isFileProcessor
            ? `#${caseInstance?.ref_id} - ${caseInstance?.caseName}${
                location.pathname.includes('/grouping') || location.pathname.includes('/timeline')
                  ? currentFile?.docFileName
                    ? ` - ${currentFile?.docFileName}`
                    : ''
                  : ['/image', '/tagging'].some((path) => location.pathname.includes(path)) &&
                    currentFile?.docFileName
                  ? ` - ${currentFile.docFileName}`
                  : ''
              }`
            : caseInstance?.caseName}
        </Typography>
        <Box sx={{ marginTop: '-0.5rem' }}>
          <PagesRead caseID={caseID} />
        </Box>
      </Box>
      <Box
        name="Files"
        onClick={() => {
          navigate(`/case/${caseID}/files`);
        }}
        sx={{
          ...navStyles.navButtons,
          position:
            location.pathname.indexOf('/files') > -1 && navStyles.styledGreenUnderline.position,
          '::after': location.pathname.indexOf('/files') > -1 && {
            ...navStyles.styledGreenUnderline['::after'],
          },
        }}
      >
        <Folder
          sx={{ ...navStyles.navIcons, paddingRight: 0.8 }}
          color={navStyles.navIcons.color}
        />
        Files
      </Box>

      <Tooltip
        title="Access to this tab is disabled until the file upload process is complete."
        disableHoverListener={userGroup === 'Processors' || openAllTabs || noFilesUploaded}
      >
        <Box
          name="Duplicates"
          onClick={() => {
            if (openAllTabs) {
              navigate(`/case/${caseID}/duplicates`);
            }
          }}
          sx={{
            ...navStyles.navButtons,
            color: openAllTabs ? Theme.typography.color : Theme.palette.mediumGrey.light,
            position:
              location.pathname.indexOf('/duplicates') > -1 &&
              navStyles.styledGreenUnderline.position,
            '::after': location.pathname.indexOf('/duplicates') > -1 && {
              ...navStyles.styledGreenUnderline['::after'],
            },
          }}
        >
          <DuplicateHeaderIcon
            sx={{ ...navStyles.navIcons }}
            color={openAllTabs ? Theme.typography.color : Theme.palette.mediumGrey.light}
          />
          Duplicates
        </Box>
      </Tooltip>
      <Tooltip
        title="Access to this tab is disabled until the file upload process is complete."
        disableHoverListener={userGroup === 'Processors' || viewableFiles || noFilesUploaded}
      >
        <Box
          name="Review"
          onClick={() => {
            if (viewableFiles) {
              navigate(sourceUrl);
            }
          }}
          sx={{
            ...navStyles.navButtons,
            color: viewableFiles ? Theme.typography.color : Theme.palette.mediumGrey.light,
            position:
              (location.pathname.indexOf('/timeline') > -1 ||
                location.pathname.indexOf('/documents') > -1) &&
              navStyles.styledGreenUnderline.position,
            '::after': (location.pathname.indexOf('/timeline') > -1 ||
              location.pathname.indexOf('/documents') > -1) && {
              ...navStyles.styledGreenUnderline['::after'],
            },
          }}
        >
          <FindInPageOutlined
            sx={{
              ...navStyles.navIcons,
              color: viewableFiles ? Theme.typography.color : Theme.palette.mediumGrey.light,
            }}
          />
          Review
        </Box>
      </Tooltip>
      <Box
        name="Reports"
        onClick={() => {
          if (location.pathname.indexOf('/reports') > -1) {
            if (currentReport) {
              setCurrentReport(null);
            } else if (isNotesTableOpen) {
              setIsNotesTableOpen(false);
            }
          }
          if (!noFilesUploaded) {
            navigate(`/case/${caseID}/reports`);
          }
        }}
        sx={{
          ...navStyles.navButtons,
          color: !noFilesUploaded ? Theme.typography.color : Theme.palette.mediumGrey.light,
          position:
            location.pathname.indexOf('/reports') > -1 && navStyles.styledGreenUnderline.position,
          '::after': location.pathname.indexOf('/reports') > -1 && {
            ...navStyles.styledGreenUnderline['::after'],
          },
        }}
      >
        <DescriptionOutlined
          sx={{
            ...navStyles.navIcons,
            color: !noFilesUploaded ? Theme.typography.color : Theme.palette.mediumGrey.light,
          }}
        />
        Reports
      </Box>
      {isFileProcessor && (
        <Box
          name="Images"
          onClick={() => {
            if (imagePages?.length > 0) {
              navigate(`/case/${caseID}/images`);
            }
          }}
          sx={{
            ...navStyles.navButtons,
            position:
              location.pathname.indexOf('/images') > -1 && navStyles.styledGreenUnderline.position,
            '::after': location.pathname.indexOf('/images') > -1 && {
              ...navStyles.styledGreenUnderline['::after'],
            },
            color: imagePages?.length > 0 ? Theme.typography.color : Theme.palette.mediumGrey.light,
          }}
        >
          <Image
            sx={{
              ...navStyles.navIcons,
              paddingRight: 0.8,
              color:
                imagePages?.length > 0
                  ? navStyles.navIcons.color
                  : 'Theme.palette.mediumGrey.light',
            }}
          />
          Images/Blank Pages
        </Box>
      )}
      {!isFileProcessor && (
        <Box
          name="Exports"
          onClick={() => {
            if (!noFilesUploaded) {
              navigate(`/case/${caseID}/exports`);
            }
          }}
          sx={{
            ...navStyles.navButtons,
            color: !noFilesUploaded ? Theme.typography.color : Theme.palette.mediumGrey.light,
            position:
              location.pathname.indexOf('/exports') > -1 && navStyles.styledGreenUnderline.position,
            '::after': location.pathname.indexOf('/exports') > -1 && {
              ...navStyles.styledGreenUnderline['::after'],
            },
          }}
        >
          <Export
            sx={{
              ...navStyles.navIcons,
            }}
            style={{ marginRight: '0.4rem' }}
            color={!noFilesUploaded ? Theme.typography.color : Theme.palette.mediumGrey.light}
          />
          Exports
        </Box>
      )}
      {isFileProcessor &&
        caseFiles &&
        location.pathname.indexOf('/files') === -1 &&
        location.pathname.indexOf('/reports') === -1 &&
        location.pathname.indexOf('/images') === -1 &&
        location.pathname.indexOf('/duplicates') === -1 && (
          <FileViewer
            caseDocuments={caseFiles.filter((file) => file.fileStatus !== 'COMPLETE')}
            timelineID={timelineID}
            caseID={caseID}
          />
        )}
      {processingFiles && (
        <Box
          sx={{
            marginLeft: 'auto',
            marginRight: '2rem',
            textAlign: 'right',
          }}
        >
          <Typography
            sx={{
              width: '18rem',
              fontSize: '1rem',
              fontWeight: 500,
              overflow: 'auto',
              color: Theme.palette.themeOrange.main,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <ProcessingFiles style={{ marginRight: '0.25rem' }} />
            Processing Files
          </Typography>
        </Box>
      )}
    </Box>
  );
}

const navStyles = {
  navIcons: {
    fontSize: '1.3rem',
    marginRight: '0.3rem',
    color: '#002147',
    verticalAlign: 'top',
  },
  navButtons: {
    marginLeft: '1.2rem',
    color: '#002147',
    marginRight: '0.7rem',
    verticalAlign: 'bottom',
    height: '2.5rem',
    fontSize: '0.9rem',
    fontWeight: 600,
    paddingRight: '0.5rem',
    display: 'inline-block !important',
    '&:hover': {
      position: 'relative',
      cursor: 'pointer',
      display: 'inline-block !important',
      '::after': {
        content: '""',
        marginTop: '6px',
        height: '4px',
        background: '#A8FCDC',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        display: 'block',
      },
    },
  },
  styledGreenUnderline: {
    position: 'relative',
    cursor: 'pointer',
    display: 'inline-block !important',
    '::after': {
      content: '""',
      marginTop: '6px',
      height: '4px',
      background: '#6CE9A6',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      display: 'block',
    },
  },
};
