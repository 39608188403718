import React from 'react';
import { Navigate } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
  canAccessResource: boolean;
  redirectPath: string;
};

export default function AccessProtected({ children, canAccessResource, redirectPath }: Props) {
  if (!canAccessResource) {
    return <Navigate to={redirectPath} />;
  }
  return children;
}
