import { Fragment, useMemo } from 'react';
import { VariableSizeList as List } from 'react-window';
import { blue } from '@mui/material/colors';
import {
  Switch,
  Box,
  FormControlLabel,
  Typography,
  FormGroup,
  Card,
  CircularProgress,
  Grid,
  Tooltip,
  Divider,
  Stack,
} from '@mui/material';
import { Filter1, Filter2Outlined } from '@mui/icons-material';
import useDuplicateSets from './hooks/useDuplicateSets';
import LinkWithQuery from '../Timeline/LinkWithQuery';
import Loading from '../../components/common/Loading';
import DuplicateSetViewedControl from './Components/DuplicateViewedControl';
import Theme from '../../theme';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import useCaseFiles from '../Files/useCaseFiles';

function DuplicatesList(props) {
  const { caseID } = props;
  const {
    duplicateSets,
    currentDuplicateSet,
    filters,
    duplicateStats,
    toggleResolved,
    refetch: refetchDuplicatesSets,
    loading: duplicateSetsLoading,
  } = useDuplicateSets();

  const isFileProcessor = useIsFileProcessor();

  const { data: caseFiles } = useCaseFiles(caseID)

  const areAllDuplicatesFound = useMemo(() => {
    if (!caseFiles) {
      return false;
    }
    return caseFiles.some(
      (document) => document.duplicateStatus !== 'READY',
    );
  }, [caseFiles]);

  const handleCheckbox = () => {
    refetchDuplicatesSets({
      hide_resolved: !filters.hideResolved,
      fetchPolicy: 'network-only',
    });
    toggleResolved();
  };

  const checkIfSetResolved = (duplicateSet) => {
    if (duplicateSet.similarPages?.filter((page) => page.isDuplicate === null).length > 0) {
      return false;
    }
    return true;
  };

  const setResolvedStatus = useMemo(
    () => duplicateSets.map((duplicateSet) => checkIfSetResolved(duplicateSet)),
    [duplicateSets],
  );

  const calculateItemHeight = (duplicateSet) => {
    const unresolvedSimilarPages =
      duplicateSet.similarPages?.filter((page) => page.isDuplicate !== false) ?? [];
    const longPageNumber =
      duplicateSet.similarPages?.filter((page) => page.pageNumber >= 10000) ?? [];

    const pageCount = unresolvedSimilarPages.length + 1;
    if (pageCount >= 2 && longPageNumber.length > 0) {
      return 140;
    }
    if (pageCount >= 3) {
      return 140;
    }
    return 110;
  };

  const itemSizes = useMemo(
    () => duplicateSets.map((duplicateSet) => calculateItemHeight(duplicateSet)),
    [duplicateSets],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: 0,
        backgroundColor: 'background.default',
        marginRight: '.5rem',
        padding: '.25rem',
        paddingTop: '0.5em',
      }}
    >
      {duplicateSetsLoading ? (
        <Loading />
      ) : (
        <>
          <Box>
            <Box
              display="flex"
              flexDirection="row"
              sx={{
                alignItems: 'flex-start',
              }}
            >
              <Stack
                direction="column"
                spacing={2.5}
                sx={{ my: '0.5rem', mx: '1rem', alignItems: 'flex-start' }}
              >
                <Typography
                  sx={{
                    fontSize: '0.8em',
                  }}
                >
                  Hide Resolved
                </Typography>
                <Box display="flex">
                  <Typography
                    sx={{
                      fontSize: '0.8em',
                      mr: '0.5rem',
                    }}
                  >
                    {duplicateStats?.unresolvedCount} Duplicates
                  </Typography>

                  {areAllDuplicatesFound && isFileProcessor && (
                    <Tooltip title="Duplicate identification in progress">
                      <CircularProgress size={14} />
                    </Tooltip>
                  )}
                </Box>
              </Stack>
              <Stack
                direction="column"
                spacing={1}
                sx={{ mb: '0.5rem', width: '8rem', alignItems: 'flex-start' }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={filters.hideResolved}
                        onChange={handleCheckbox}
                        sx={{ mt: '0.2rem', mb: '-0.3rem' }}
                        name="checkedA"
                        size="small"
                        color="primary"
                      />
                    }
                  />
                </FormGroup>
              </Stack>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              flex: 1,
              padding: '.5rem',
            }}
          >
            {duplicateSets?.length === 0 &&
              (duplicateSets?.length > 0 ? (
                <div style={{ textAlign: 'center' }}>All Duplicates Resolved</div>
              ) : (
                <div style={{ textAlign: 'center' }}>No Duplicates Found</div>
              ))}
            {duplicateSets.length > 0 && (
              <List
                key={`${filters.hideResolved}`}
                height={window.innerHeight - 155}
                itemCount={duplicateSets.length}
                itemSize={(index) => itemSizes[index]}
                width="100%"
                style={{ paddingBottom: '3rem' }}
                className="side-nav-list"
              >
                {({ index, style }) => {
                  const duplicateSet = duplicateSets[index];

                  return (
                    <LinkWithQuery
                      key={`${duplicateSets[index].setID}dupID`}
                      style={style}
                      to={`/case/${caseID}/duplicates/${duplicateSets[index].setID}`}
                    >
                      <Card
                        variant="outlined"
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          flex: '95%',
                          padding: '0.75rem',
                          marginTop: '0.5rem',
                          borderBottom: 'thin solid #e3e3e3',
                          color: currentDuplicateSet === duplicateSet && blue[900],
                          borderColor: currentDuplicateSet === duplicateSet ? blue[900] : '#FFFFFF',
                          '&:hover': {
                            cursor: 'pointer',
                            textDecoration: 'none',
                            borderColor: currentDuplicateSet === duplicateSet && blue[900],
                            color: currentDuplicateSet === duplicateSet && blue[900],
                          },
                        }}
                      >
                        <Typography
                          display="inline"
                          sx={{ display: 'inline' }}
                          fontWeight="700"
                          fontSize="0.6em"
                        >
                          <DuplicateSetViewedControl duplicateSet={duplicateSet} />
                          {duplicateSet.similarPages.filter((page) => page.isDuplicate !== false)
                            .length + 1}{' '}
                          Pages in Duplicate Set
                          {setResolvedStatus[index] && (
                            <>
                              {' '}
                              |{' '}
                              <Typography
                                display="inline"
                                sx={{
                                  display: 'inline',
                                  color: `${Theme.palette.newSuccess.light} !important`,
                                }}
                                fontWeight="800"
                                fontSize="inherit"
                              >
                                RESOLVED
                              </Typography>
                            </>
                          )}
                        </Typography>

                        <Card
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: '95%',
                            padding: '0.2rem',
                            marginTop: '0.5rem',
                            boxShadow: 'none',
                            maxHeight: '85px',
                            overflowY: 'auto',
                          }}
                        >
                          <Grid container direction="row" marginLeft={0.5}>
                            <Fragment key={duplicateSet.originalPage?.pageID}>
                              <Grid
                                item
                                xs={7}
                                sx={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                              >
                                <Typography display="inline" noWrap fontSize="0.6em">
                                  {duplicateSet.originalPage?.documentFileName}
                                </Typography>
                              </Grid>
                              <Grid item xs={3} marginLeft={1} sx={{ alignSelf: 'end' }}>
                                <Typography display="inline" fontSize="0.6em">
                                  Page {duplicateSet.originalPage?.pageNumber}
                                </Typography>
                              </Grid>
                              <Grid item xs={1} marginLeft={1}>
                                <Tooltip title="Original" placement="top">
                                  <Filter1
                                    sx={{
                                      fontSize: '0.8em',
                                      marginLeft: 0.5,
                                      color: 'primary',
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                            </Fragment>
                            {duplicateSet.similarPages?.map(
                              (dup) =>
                                dup.isDuplicate !== false && (
                                  <Fragment key={dup.pageID}>
                                    <Grid
                                      item
                                      xs={7}
                                      sx={{
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      <Typography display="inline" noWrap fontSize="0.6em">
                                        {dup.documentFileName}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3} marginLeft={1}>
                                      <Typography display="inline" fontSize="0.6em">
                                        Page {dup.pageNumber}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={1} marginLeft={1}>
                                      {dup.isDuplicate === true && (
                                        <Tooltip title="Confirmed Duplicate" placement="top">
                                          <Filter2Outlined
                                            sx={{
                                              fontSize: '0.8em',
                                              marginLeft: 0.5,
                                              color: 'black',
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                    </Grid>
                                  </Fragment>
                                ),
                            )}
                          </Grid>
                        </Card>
                      </Card>
                    </LinkWithQuery>
                  );
                }}
              </List>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

export default DuplicatesList;
