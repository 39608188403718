import { FullStory, init } from '@fullstory/browser';
import { useContext } from 'react';
import { AuthContext } from './library/contexts/AuthContext';

export const identifyUser = async (user, givenName, familyName, email) => {
  FullStory('setIdentity', {
    uid: user.username,
    properties: {
      displayName: `${givenName ?? 'Unknown'} ${familyName ?? 'Unknown'}`,
      email: email,
    },
  });
};

export const initializeFullStory = () => {
  console.log('initializing fullstory');
  init({
    orgId: '165C8J',
  });
};

export const SetupFullStory = ({ children }) => {
  const { user } = useContext(AuthContext);
  initializeFullStory();

  const attributes = user.attributes ?? user.signInUserSession.idToken.payload;
  identifyUser(user, attributes.given_name, attributes.family_name, attributes.email);

  return children;
};
