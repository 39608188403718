import { useQuery } from '@tanstack/react-query';
import { authenticatedRequest } from '../../api';

async function getEntryPages({ entryID }) {
  const res = await authenticatedRequest({
    method: 'GET',
    url: `/api/v1/pages/getEntryPages?entryID=${entryID}`,
  });
  return res.data;
}

export default function useEntryPages({ entryID }) {
  return useQuery(
    ['pages', entryID],
    () => getEntryPages({ entryID }),
    {
      enabled: !!entryID,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 10,
    },
  );
}
