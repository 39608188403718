import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { authenticatedRequest } from '../../../api';
import { getUser } from '../../Case/CaseDisplays/useCaseLocks';
import { useUser } from '../../../library/contexts/AuthContext';

async function checkAllGroupingLocks(caseID: string) {
  const res = await authenticatedRequest({
    method: 'GET',
    url: `/case/${caseID}/lock/grouping`,
  });
  return res.data;
}

async function removeGroupingLock({ fileID, caseID }: { fileID: string; caseID?: string }) {
  const res = await authenticatedRequest({
    method: 'DELETE',
    url: `/case/${caseID}/lock/grouping`,
    params: { file_id: fileID },
  });
  return res.data;
}

function useInsertGroupingLockMutation() {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const currentUserName = user.attributes;

  return useMutation(insertGroupingLock, {
    onMutate: async ({ fileID, caseID }: { fileID: string; caseID: string }): Promise<void> => {
      await queryClient.cancelQueries(['checkGroupingLocks', caseID]);
      const lockData = queryClient.getQueryData(['checkGroupingLocks', caseID]);
      const newLockData = [
        ...lockData,
        {
          fileID: fileID,
          access: false,
          currentUser: currentUserName
            ? `${currentUserName.given_name} ${currentUserName.family_name}`
            : '',
        },
      ];
      queryClient.setQueryData(['checkGroupingLocks', caseID], newLockData);
    },
  });
}

function useRemoveGroupingLockMutation() {
  const queryClient = useQueryClient();

  return useMutation(removeGroupingLock, {
    onMutate: async ({ fileID, caseID }: { fileID: string; caseID: string }): Promise<void> => {
      await queryClient.cancelQueries(['checkGroupingLocks', caseID]);
      const lockData = queryClient.getQueryData(['checkGroupingLocks', caseID]);
      const newLockData = lockData.filter((lock: GroupingLockStatus) => lock.fileID !== fileID);
      queryClient.setQueryData(['checkGroupingLocks', caseID], newLockData);
    },
  });
}

async function insertGroupingLock({ fileID, caseID }: { fileID: string; caseID?: string }) {
  const res = await authenticatedRequest({
    method: 'PUT',
    url: `/case/${caseID}/lock/grouping`,
    params: { file_id: fileID },
  });
  return res.data;
}

export function useGroupingLock(caseID: string) {
  const { user } = useUser();
  const { data, isLoading } = useQuery(
    ['checkGroupingLocks', caseID],
    async (): Promise<GroupingLockStatus[]> => {
      const groupingLocks = (await checkAllGroupingLocks(caseID)) as {
        fileID: string;
        access: boolean;
        currentUser: string;
      }[];

      const updatedLocks = await Promise.all(
        groupingLocks.map(async (lock) => {
          const userName = (await getUser(user, caseID, lock.currentUser)) ?? undefined;
          return {
            ...lock,
            currentUser: userName ? `${userName.given_name} ${userName.family_name}` : '',
          };
        }),
      );
      return updatedLocks ?? [];
    },
    {
      refetchInterval: 60 * 1000, //1 minute
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: true,
      enabled: !!caseID,
    },
  );
  const { mutateAsync: insertGroupingLockMutate } = useInsertGroupingLockMutation();
  const { mutateAsync: removeGroupingLockMutate } = useRemoveGroupingLockMutation();

  return {
    data,
    isLoading,
    removeGroupingLock: removeGroupingLockMutate,
    insertGroupingLock: insertGroupingLockMutate,
  };
}

type GroupingLockStatus = {
  fileID: string;
  access: boolean;
  currentUser: string | undefined;
};
