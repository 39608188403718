import { useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { AuthContext } from '../../library/contexts/AuthContext';
import DialogModal from '../common/DialogModal';

export default function IdleTimer() {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [state, setState] = useState('Active');
  const [remaining, setRemaining] = useState(900);
  const location = useLocation();
  const { caseID } = useParams();

  const onIdle = () => {
    setState('Idle');
    endSession();
  };
  const endSession = async () => {
    try {
      await logout(location, caseID);
      const redirectLink =
        location.pathname !== `/` ? `/login?redirect=${location.pathname}` : '/login';
      navigate(redirectLink);
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const onActive = () => {
    setState('Active');
  };

  const onPrompt = () => {
    setState('Prompted');
  };

  const { getRemainingTime, activate, isLeader } = useIdleTimer({
    onIdle: () => {
      if (isLeader()) {
        onIdle();
      }
    },
    onActive,
    onPrompt: () => {
      if (isLeader()) {
        onPrompt();
      }
    },
    promptBeforeIdle: 60000,
    timeout: 900000,
    throttle: 500,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    // return mui dialog showing that they will be logged out in {remaining} seconds and a button to keep them logged in
    state === 'Prompted' && (
      <DialogModal
        open
        onClose={activate}
        maxWidth="md"
        header={
          <Typography variant="subtitle1">
            <b>Your session is about to expire.</b>
          </Typography>
        }
        content={
          <Typography variant="caption">
            Your session is about to expire due to inactivity. You will be logged out in{' '}
            <b>{remaining}</b> seconds.
          </Typography>
        }
        actions={
          <>
            <Button
              variant="outlined"
              onClick={endSession}
              classes="primary"
              sx={{
                width: '50%',
                m: 1,
              }}
            >
              Logout
            </Button>
            <Button
              variant="contained"
              onClick={activate}
              classes="primary"
              sx={{ width: '50%', m: 1 }}
            >
              Continue Session
            </Button>
          </>
        }
      />
    )
  );
}
