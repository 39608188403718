import { schema } from 'normalizr';

export const contentTypeSchema = new schema.Entity('contentType', {}, { idAttribute: 'id' });

export const contentTypesListSchema = new schema.Array(contentTypeSchema);

export const specialitySchema = new schema.Entity('speciality', {}, { idAttribute: 'id' });

export const specialitiesListSchema = new schema.Array(specialitySchema);

export const pageSchema = new schema.Entity(
  'page',
  {
    specialities: specialitiesListSchema,
    contentTypes: contentTypesListSchema,
  },
  { idAttribute: 'id' },
);

export const pagesListSchema = new schema.Array(pageSchema);

export const timelineEntrySchema = new schema.Entity(
  'timelineEntry',
  {
    pages: pagesListSchema,
  },
  { idAttribute: 'id' },
);

export const timelineSchema = new schema.Entity(
  'timeline',
  {
    entries: new schema.Array(timelineEntrySchema),
  },
  { idAttribute: 'id' },
);

export const tiemlineListSchema = new schema.Array(timelineSchema);

export const documentSchema = new schema.Entity(
  'document',
  {
    pages: pagesListSchema,
  },
  { idAttribute: 'documentID' },
);

export const documentsListSchema = new schema.Array(documentSchema);
